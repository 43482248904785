import { Modal } from 'assets/components/modal';
import { TextField } from 'assets/components/text-field';
import { ToggleSwitch } from 'assets/components/toggle-switch';
import { useForm } from 'assets/form';
import { Form } from 'assets/layout/form/Form';
import { getText } from 'assets/localization/localization';
import _isEqual from 'lodash/isEqual';
import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { useAppStateStore } from '../../../../../store/app-store';
import {
  setShowRefillErrorModal,
  updateRefillErrors,
} from '../../refills-settings-actions';
import { useRefillsSettingsState } from '../../refills-settings-store';
import { makeStyles, useTheme } from 'assets/theme';
import {
  RefillRemoveBehavior,
  RxStatus,
} from '@digitalpharmacist/prescription-service-client-axios';
import { Text } from 'assets/components/text';
import { View } from 'react-native';
import { InfoIcon } from 'assets/icons';
import { Icon } from 'assets/components/icon/Icon';
import { Tooltip } from 'assets/components/tooltip/components/tooltip';
import { RxErrorText } from './RefillErrorsItemRender';
import { PharmacyTooltipWrapper } from '../../../../../common/PharmacyTooltipWrapper';

export const RefillErrorsModal: FunctionComponent<
  PropsWithChildren<any>
> = () => {
  const { showRefillErrorModal, editingRefillErrors } =
    useRefillsSettingsState();
  const [title, setTitle] = useState<string>();
  const [refillErrorStatus, setRefillErrorStatus] = useState<RxStatus>();
  const [refillErrorBehavior, setRefillErrorBehavior] =
    useState<RefillRemoveBehavior>();
  const [refillErrorMessage, setRefillErrorMessage] = useState('');
  const { locationId } = useAppStateStore();
  const [toggle, setToggle] = useState<boolean>();
  const theme = useTheme();
  const styles = useStyles();

  let toggleSwitch = toggle
    ? RefillRemoveBehavior.AllowRemove
    : RefillRemoveBehavior.ForceRemove;

  useEffect(() => {
    if (editingRefillErrors) {
      const { remove_behavior, rx_status, message_patient } =
        editingRefillErrors;
      const title = displayText(rx_status);
      setTitle(title);
      setToggle(
        refillErrorBehavior === RefillRemoveBehavior.ForceRemove ? false : true,
      );
      setRefillErrorStatus(rx_status);
      setRefillErrorBehavior(remove_behavior);
      setRefillErrorMessage(message_patient);
      methods.reset({ title, rx_status, remove_behavior, message_patient });
    }
  }, [editingRefillErrors, refillErrorBehavior]);

  const methods = useForm<any>({
    defaultValues: {
      title: title,
      rx_status: refillErrorStatus,
      remove_behavior: refillErrorBehavior,
      message_patient: refillErrorMessage,
    },
  });

  const handleSubmit = async () => {
    if (!refillErrorStatus || !refillErrorMessage) return;
    updateRefillErrors(
      locationId,
      refillErrorStatus,
      toggleSwitch,
      methods.getValues().message_patient,
    );
    setShowRefillErrorModal(false);
  };

  const toggleActive = () => {
    setToggle(!toggle);
  };

  const displayText = (rx_status: string) => {
    switch (rx_status) {
      case (rx_status = 'no_refills'):
        return RxErrorText.NoRefills;
      case (rx_status = 'expired'):
        return RxErrorText.Expired;
      case (rx_status = 'too_soon'):
        return RxErrorText.TooSoon;
      case (rx_status = 'not_found'):
        return RxErrorText.NotFound;
      case (rx_status = 'controlled'):
        return RxErrorText.Controlled;
      case (rx_status = 'narcotic'):
        return RxErrorText.Narcotic;
      case (rx_status = 'transferred'):
        return RxErrorText.Transferred;
      case (rx_status = 'discontinued'):
        return RxErrorText.Discontinued;
      case (rx_status = 'on_hold'):
        return RxErrorText.OnHold;
      case (rx_status = 'deleted'):
        return RxErrorText.Deleted;
      case (rx_status = 'reassigned'):
        return RxErrorText.Reassigned;
      case (rx_status = 'partially_filled'):
        return RxErrorText.PartiallyFilled;
      case (rx_status = 'fillable'):
        return RxErrorText.Fillable;
      case (rx_status = 'out_of_stock'):
        return RxErrorText.OutOfStock;
      case (rx_status = 'special_order'):
        return RxErrorText.SpecialOrder;
      case (rx_status = 'deceased'):
        return RxErrorText.Deceased;
      default:
        return undefined;
    }
  };
  const charCount = methods.watch('message_patient').length;

  return (
    <Modal
      title={getText('edit-refill-errors', { refillError: title })}
      titleSize="sm"
      showDismissButton={true}
      handleDismissModal={() => setShowRefillErrorModal(false)}
      show={showRefillErrorModal}
      isScrollable={false}
      cancelButtonProps={{
        onPress: () => setShowRefillErrorModal(false),
        hierarchy: 'tertiary-gray',
        logger: { id: 'location-pickup-method-form-cancel-button-modal' },
      }}
      okButtonProps={{
        onPress: methods.handleSubmit(handleSubmit),
        logger: { id: 'location-pickup-method-form-ok-button-modal' },
        hierarchy: 'primary',
        text: getText('ok'),
      }}
    >
      <Form methods={methods}>
        <Form.Row>
          <View style={styles.rowView}>
            <ToggleSwitch
              logger={{
                id: `refill-errors-toggle-${`${
                  methods.getValues().rx_status
                }`}`,
              }}
              value={toggle}
              onPress={() => toggleActive()}
            />
            <View style={styles.toggleText}>
              <Text style={styles.toggleTitle}>
                {getText('refill-error-toggle-name')}
              </Text>
              <Text style={styles.toggleHelperTitle}>
                {getText('refill-error-toggle-description')}
              </Text>
            </View>
          </View>
        </Form.Row>

        <View style={styles.rowViewCenter}>
          <Text style={styles.toggleTitle}>{getText('error-name')}</Text>
        </View>
        <Form.Row>
          <Form.Column>
            <TextField
              name="title"
              disabled
              backgroundColor={theme.palette.gray[50]}
              disabledBorderColor={theme.palette.gray[300]}
            />
          </Form.Column>
        </Form.Row>
        <View style={styles.rowViewCenter}>
          <Text style={styles.toggleTitle}>{getText('error-description')}</Text>
          <View style={styles.tooltip}>
            <PharmacyTooltipWrapper tooltipId="refill-reminder-error-modal-tooltip">
              <Icon icon={InfoIcon} size={16} color={theme.palette.gray[500]} />
            </PharmacyTooltipWrapper>
            <Tooltip
              anchorId="refill-reminder-error-modal-tooltip"
              text={getText('refill-reminder-error-modal-tooltip')}
            />
          </View>
        </View>
        <Form.Row>
          <Form.Column>
            <TextField
              name="message_patient"
              type="text"
              numberOfLines={1}
              maxLength={50}
              hintMessage={getText('character-count-of-total-refill-error', {
                charCount: charCount,
                count: 50,
              })}
              rules={{
                required: getText('field-required'),
              }}
            />
          </Form.Column>
        </Form.Row>
      </Form>
    </Modal>
  );
};

interface GeneralInfoFormProps {
  name: string;
  active: boolean;
  confirmation: string;
}

const useStyles = makeStyles((theme) => ({
  rowView: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  rowViewCenter: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  toggleText: {
    marginTop: theme.getSpacing(0.5),
    marginLeft: theme.getSpacing(1),
    alignContent: 'center',
    flexDirection: 'column',
  },
  toggleTitle: {
    ...theme.lumistryFonts.text.medium.semiBold,
  },
  toggleHelperTitle: {
    ...theme.lumistryFonts.text.medium.regular,
  },
  tooltip: {
    marginLeft: theme.getSpacing(1),
    marginTop: theme.getSpacing(0.5),
  },
}));
