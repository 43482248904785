import { ITemplate } from 'assets/types/messageTypes';

export const defaultTemplates: ITemplate[] = [
  {
    id: 1,
    name: 'Prescription is Ready',
    subject: 'Refill Order',
    body: 'Hi ${first_name}, you have a prescription order that has been filled and is now ready to be picked up. Please message us if you have any questions.',
  },
  {
    id: 2,
    name: 'Refill Reminder',
    subject: 'Refill Order',
    body: 'Hi ${first_name}, you have prescriptions that are eligible to be refilled. If you would like to refill your prescriptions, please submit your refill order from the Medications tab!',
  },
  {
    id: 3,
    name: 'Prescription Delivery',
    subject: 'Refill Order',
    body: 'Hi ${first_name}, we are delivering your prescription today. Please message us if you have any questions.',
  },
  {
    id: 4,
    name: 'Insurance Card Needed',
    subject: 'Insurance Card',
    body: 'Hi ${first_name}, we need an updated copy of your insurance card. Please go to Account settings to upload your most recent insurance information. Thank you!',
  },
  {
    id: 5,
    name: 'Prior Authorization',
    subject: 'Refill Order',
    body: "Hi ${first_name}, we received a prescription for you but it requires prior authorization. We have contacted the provider's office to inform them. The approval process may take several days. Please message us if you have any questions.",
  },
  {
    id: 6,
    name: 'No Refills Remaining',
    subject: 'Refill Order',
    body: 'Hi ${first_name}, we received your refill request but there are no more refills remaining on your prescription. A request has been sent to your prescriber. Please message us if you have any questions or concerns.',
  },
  {
    id: 7,
    name: 'Medication Out of Stock',
    subject: 'Refill Order',
    body: 'Hi ${first_name}, we apologize, but the prescription you requested is out of stock. We have reordered it and will let you know when it is ready. Please message us with any questions.',
  },
  {
    id: 8,
    name: 'Return to Stock Warning',
    subject: 'Refill Order',
    body: 'Hi ${first_name}, you have a prescription order that has been ready for pickup and is due to be returned to stock. Please let us know if you would still like to pick up your order. Thank you!',
  },
  {
    id: 9,
    name: 'Flu Vaccination',
    subject: 'Vaccinations',
    body: "Hi ${first_name}, flu season is upon us and we're starting to schedule flu vaccinations. Use our app to book your appointments today!",
  },
  {
    id: 10,
    name: 'Update Insurance Card',
    subject: 'Insurance Card',
    body: "Hi ${first_name}, our records indicate that it may be time to update your insurance information. If your insurance has recently changed or if you've received a new insurance card, please visit your Account settings in our app to upload your most recent insurance information. Thank you!",
  },
];
