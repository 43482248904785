import { DataGrid } from 'assets/components/data-grid';
import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';
import { View } from 'react-native';
import { useContextMenu, TriggerEvent } from 'react-contexify';
import {
  GridReadyEvent,
  GridApi,
  ColDef,
  ColGroupDef,
} from '@ag-grid-community/core';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-material.css';
import {
  FormAccessType,
  FormStatus,
  ListFormDto,
  ScheduleServiceDto,
} from '@digitalpharmacist/forms-service-client-axios';

import { ThreedotsVerticalIcon, AlertTriangleIcon } from 'assets/icons';
import { Tag } from 'assets/components/tag';
import { ToggleSwitch } from 'assets/components/toggle-switch';
import { useFormsDataTableState } from './forms-data-table-store';
import {
  changeFormsStatus,
  deleteForm,
  persistGridApi,
  setContextMenuForm,
} from './forms-data-table-actions';
import { makeStyles, useTheme } from 'assets/theme';
import { PharmacyConfirmationModal } from '../../components/PharmacyConfirmationModal';
import { Text } from 'assets/components/text';
import { formatDate } from '../../common/datetime-utils';
import FormsContextMenu from './FormsContextMenu';
import { Icon } from 'assets/components/icon';
import { EllipsisTextRenderer } from '../../components/EllipsisTextRenderer';
import { getText } from 'assets/localization/localization';
import EmptyStateMessage from '../../components/EmptyStateMessage/EmptyStateMessage';
import { Tooltip } from 'assets/components/tooltip/components/tooltip';
import { PharmacyTooltipWrapper } from '../../common/PharmacyTooltipWrapper';
import { FormTestIDs } from '../../screens/forms/FormTestIDs';

const MENU_ID = 'forms-row-options';

const getServicesList = (services: ScheduleServiceDto[]) => {
  return services.map((service) => service.title).join(', ');
};

const StatusBadgesRenderer = (props: { data: ListFormDto }) => {
  const styles = useStyles();
  const theme = useTheme();
  const rowData = props.data;

  return (
    <View style={styles.cellContainer}>
      {!rowData.editable && (
        <View style={styles.statusBadge}>
          <Tag
            label={getText('no-edit')}
            labelColor={theme.palette.black}
            style={styles.noEditTag}
            textProps={{
              style: styles.badgeTextStyle,
            }}
          />
        </View>
      )}
      {rowData.imported && (
        <View style={styles.statusBadge}>
          <PharmacyTooltipWrapper
            tooltipId={`forms-list-tooltip-imported-${props.data.id}`}
          >
            <Tag
              label={getText('imported')}
              labelColor={theme.palette.white}
              style={styles.importedTag}
              textProps={{
                style: styles.badgeTextStyle,
              }}
            />
          </PharmacyTooltipWrapper>
        </View>
      )}
      {rowData.formAccessType === FormAccessType.Private ? (
        <View style={styles.statusBadge}>
          <PharmacyTooltipWrapper
            tooltipId={`forms-list-tooltip-access-private-${props.data.id}`}
          >
            <Tag
              label={getText('private')}
              labelColor={theme.palette.white}
              style={styles.privateTag}
              textProps={{
                style: styles.badgeTextStyle,
              }}
            />
          </PharmacyTooltipWrapper>
        </View>
      ) : null}
    </View>
  );
};

const ServicesRenderer = (props: { data: ListFormDto }) => {
  if (props.data.services?.length) {
    const servicesList = getServicesList(props.data.services);
    return (
      <PharmacyTooltipWrapper
        tooltipId={`forms-list-tooltip-services-${props.data.id}`}
        value={servicesList}
      />
    );
  }

  return <Text>-</Text>;
};

const FormsDataTable: FunctionComponent<
  PropsWithChildren<FormsDataTableProps>
> = (props) => {
  const styles = useStyles();
  const theme = useTheme();
  const displayActiveForms = props.filterByStatus == FormStatus.Enabled;
  const [forms, setForms] = useState<ListFormDto[] | undefined>([]);
  const [formToBeDeleted, setFormToBeDeleted] = useState<ListFormDto | null>(
    null,
  );

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const { activeForms, inactiveForms } = useFormsDataTableState();

  const [gridApi, _setGridApi] = useState<GridApi>();

  const gridApiRef = useRef(gridApi);
  const setGridApi = (api: GridApi) => {
    gridApiRef.current = api;
    _setGridApi(api);
    persistGridApi(api);
  };

  useEffect(() => {
    setForms(displayActiveForms ? activeForms : inactiveForms);
  }, [activeForms, inactiveForms]);

  const deleteFormCloseModal = () => {
    if (formToBeDeleted) {
      deleteForm(formToBeDeleted.id);
    }
    setShowDeleteConfirmation(false);
  };

  const ActionButtonsRenderer = (props: { data: ListFormDto }) => {
    const rowData = props.data;

    const { show } = useContextMenu({
      id: MENU_ID,
    });

    const handleContextMenu = (event: TriggerEvent) => {
      setContextMenuForm(rowData);

      show({
        event: event,
      });
    };

    return (
      <View style={styles.cellContainer}>
        <PharmacyTooltipWrapper
          tooltipId={`forms-list-tooltip-enable-form-${props.data.id}`}
          isContentIcon={true}
        >
          <ToggleSwitch
            logger={{ id: `change-status-form--${rowData.id}` }}
            value={rowData.status == FormStatus.Enabled}
            onPress={(newValue) => {
              if (
                (newValue && rowData.status == FormStatus.Disabled) ||
                (!newValue && rowData.status == FormStatus.Enabled)
              ) {
                changeFormsStatus(
                  rowData.id,
                  rowData.status == FormStatus.Enabled
                    ? FormStatus.Disabled
                    : FormStatus.Enabled,
                );
              }
            }}
          ></ToggleSwitch>
        </PharmacyTooltipWrapper>
        <div style={styles.contextMenu} onClick={handleContextMenu}>
          <View>
            <Icon
              icon={ThreedotsVerticalIcon}
              color={theme.palette.gray[900]}
            />
          </View>
        </div>
      </View>
    );
  };

  // Each Column Definition results in one Column.
  const [columnDefs, setColumnDefs] = useState([
    {
      maxWidth: 250,
      field: 'title',
      headerName: getText('form-name'),
      cellRenderer: (props: { data: ListFormDto }) => (
        <PharmacyTooltipWrapper
          tooltipId={`forms-list-tooltip-title-${props.data.id}`}
          value={props.data.title}
        />
      ),
    },
    {
      maxWidth: 200,
      field: 'submissionCount',
      headerName: getText('submissions'),
      headerClass: 'data-grid-header-centered',
      cellStyle: {
        textAlign: 'center',
      },
      cellRenderer: (props: { data: ListFormDto }) => (
        <EllipsisTextRenderer value={`${props.data.submissionCount}`} />
      ),
    },
    {
      cellRenderer: ServicesRenderer,
      headerName: getText('forms-services'),
    },
    {
      cellRenderer: StatusBadgesRenderer,
      maxWidth: 300,
      cellStyle: {
        padding: 0,
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'flex-end',
      },
    },
    {
      maxWidth: 170,
      field: 'updated_at',
      headerName: getText('last-modified'),
      cellRenderer: (props: { data: ListFormDto }) => (
        <EllipsisTextRenderer value={formatDate(props.data.updated_at)} />
      ),
      sortable: true,
      headerClass: 'data-grid-header-right-aligned',
      cellStyle: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'flex-end',
      },
    },
    {
      maxWidth: 110,
      headerName: '',
      cellRenderer: ActionButtonsRenderer,
      cellStyle: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'flex-end',
      },
    },
  ] as (ColDef | ColGroupDef)[]);

  const handleGridReady = (event: GridReadyEvent) => {
    setGridApi(event.api);
  };

  return forms ? (
    <View>
      <PharmacyConfirmationModal
        show={showDeleteConfirmation}
        onAccepted={() => deleteFormCloseModal()}
        onDismiss={() => setShowDeleteConfirmation(false)}
        message={`${getText(
          'confirm-to-delete-name',
        )} "${formToBeDeleted?.title}"?`}
      />
      {!displayActiveForms ? (
        <Text
          style={styles.subTitle}
          selectable
          testID={FormTestIDs.inactiveFormsHeader}
        >
          {getText('inactive')}
        </Text>
      ) : null}

      <FormsContextMenu
        menuId={MENU_ID}
        displayActiveForms={displayActiveForms}
      />
      <div
        className="forms-grid-wrapper"
        data-testid={FormTestIDs.formsGridWrapper}
      >
        <View style={{ flex: 1 }}>
          <DataGrid
            className="forms-grid-wrapper"
            gridOptions={{
              onGridReady: handleGridReady,
              rowData: forms,
              columnDefs: columnDefs,
              enableCellTextSelection: true,
              suppressMovableColumns: true,
              suppressContextMenu: true,
              defaultColDef: { sortable: false, menuTabs: [] },
              pagination: false,
              onGridSizeChanged() {
                gridApiRef.current?.sizeColumnsToFit();
              },
              noRowsOverlayComponent: () => (
                <View style={styles.emptyStateMessageContainer}>
                  <EmptyStateMessage
                    icon={AlertTriangleIcon}
                    message={getText('all-forms-are-inactive')}
                  />
                </View>
              ),
            }}
            gridToolbarProps={{}}
          />
        </View>
      </div>
      {forms.map((form) => (
        <>
          {form.title ? (
            <Tooltip
              text={form.title}
              id={`forms-list-tooltip-title-${form.id}`}
            />
          ) : null}
          {form.services?.length ? (
            <Tooltip
              text={getServicesList(form.services)}
              id={`forms-list-tooltip-services-${form.id}`}
            />
          ) : null}
          {!form.editable ? (
            <Tooltip
              text={getText('no-edit')}
              id={`forms-list-tooltip-editable-${form.id}`}
            />
          ) : null}
          {form.imported ? (
            <Tooltip
              text={getText('imported')}
              id={`forms-list-tooltip-imported-${form.id}`}
            />
          ) : null}
          {form.status !== undefined ? (
            <Tooltip
              text={
                form.status == FormStatus.Enabled
                  ? getText('disable-form')
                  : getText('enable-form')
              }
              id={`forms-list-tooltip-enable-form-${form.id}`}
            />
          ) : null}
        </>
      ))}
    </View>
  ) : null;
};

interface FormsDataTableProps {
  filterByStatus: FormStatus;
}

const useStyles = makeStyles((theme) => ({
  subTitle: {
    marginTop: theme.getSpacing(4),
    fontSize: 20,
    paddingBottom: theme.getSpacing(4),
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.gray[300],
  },
  cellContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },
  textEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  statusBadge: {
    paddingHorizontal: theme.getSpacing(0.5),
  },
  badgeTextStyle: {
    marginLeft: 0,
  },
  emptyStateMessageContainer: {
    marginTop: theme.getSpacing(2),
    paddingLeft: theme.getSpacing(3),
  },
  noEditTag: {
    backgroundColor: theme.palette.warning[300],
  },
  importedTag: {
    backgroundColor: theme.palette.gray[400],
  },
  privateTag: {
    backgroundColor: theme.palette.primary[400],
  },
  contextMenu: {
    marginLeft: theme.getSpacing(1),
  },
}));

export default FormsDataTable;
