import { IconButton } from 'assets/components/icon-button';
import {
  InputColor,
  SearchInput,
} from 'assets/components/search-input/SearchInput';
import { ChevronLeftIcon, ChevronRightIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import React from 'react';
import { Text, View } from 'react-native';
import { useDebounceEffect } from '../../../../common/hooks/useDebounceEffect';
import { useAppStateStore } from '../../../../store/app-store';
import {
  getSettingsLocations,
  setPage,
  setSearch,
  setStatus,
} from '../location-settings-actions';
import { useLocationSettingsState } from '../location-settings-store';

const LocationsSearchFilter: React.FC = () => {
  const styles = useStyles();
  const theme = useTheme();
  const PAGE_SIZE = 25;
  const { allLocations, search, page } = useLocationSettingsState();
  const { pharmacyId } = useAppStateStore();
  const totalCount = allLocations.total_count;

  useDebounceEffect(() => {
    setStatus('loading');
    getSettingsLocations(0, PAGE_SIZE, search).finally(() => {
      setPage(0);
      setStatus('success');
    });
  }, [pharmacyId, search]);

  const goToPreviousPage = () => {
    setStatus('loading');
    getSettingsLocations(PAGE_SIZE * page - 1, PAGE_SIZE, search)
      .then(() => setPage(page - 1))
      .catch(() => setPage(0))
      .finally(() => setStatus('success'));
  };

  const goToNextPage = () => {
    setStatus('loading');
    getSettingsLocations(PAGE_SIZE * page + 1, PAGE_SIZE, search)
      .then(() => setPage(page + 1))
      .catch(() => setPage(0))
      .finally(() => setStatus('success'));
  };

  const PaginationDisplay = () => {
    return (
      <View style={styles.paginationContainer}>
        <Text style={{ marginRight: theme.getSpacing(1) }}>
          {`${PAGE_SIZE * page + 1} - ${Math.min(
            totalCount || 0,
            PAGE_SIZE * (page + 1),
          )} ${getText('of')} ${totalCount}`}
        </Text>
        <IconButton
          disabled={page === 0}
          icon={ChevronLeftIcon}
          logger={{ id: 'grid-toolbar-icon-action-paginate-back' }}
          onPress={goToPreviousPage}
          size={18}
          color={theme.palette.gray[700]}
          style={styles.iconButtonStyle}
        />
        <IconButton
          disabled={PAGE_SIZE * (page + 1) >= (totalCount || 0)}
          icon={ChevronRightIcon}
          logger={{ id: 'grid-toolbar-icon-action-paginate-forward' }}
          onPress={goToNextPage}
          size={18}
          color={theme.palette.gray[700]}
          style={styles.iconButtonStyle}
        />
      </View>
    );
  };

  return (
    <View style={styles.titleContainer}>
      <View>
        <SearchInput
          placeholder={getText('name-ncpdp-street-city')}
          inputColor={InputColor.Dark}
          title={getText('locations-all')}
          inputValue={search}
          onChange={setSearch}
        />
      </View>
      <View style={styles.pagination}>
        {totalCount ? <PaginationDisplay /> : <View />}
      </View>
    </View>
  );
};

export default LocationsSearchFilter;

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    flexDirection: 'row',
    paddingVertical: theme.getSpacing(2),
  },
  title: {
    fontSize: 25,
    marginRight: theme.getSpacing(3),
    alignItems: 'center',
    display: 'flex',
  },
  pagination: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginLeft: 'auto',
  },
  paginationContainer: {
    alignSelf: 'center',
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonStyle: {
    alignItems: 'center',
    display: 'flex',
    marginLeft: theme.getSpacing(2),
    marginTop: theme.getSpacing(1),
    marginRight: theme.getSpacing(2),
  },
  iconButtonStyle: {
    padding: 3,
    height: null,
    width: null,
    borderRadius: 50,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.gray[700],
  },
}));
