import React, { FunctionComponent, PropsWithChildren, useState } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { Text } from 'assets/components/text';
import theme, { makeStyles } from 'assets/theme';
import { Icon } from 'assets/components/icon';
import { EyeIcon, EyeOffIcon, RefreshCWIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';

export const RowItemInternalAPIKey: FunctionComponent<
  PropsWithChildren<RowItemRenderProps>
> = ({ title, value, isSecretValue, onRefresh }) => {
  const [isHidden, setIsHidden] = useState(true);
  const styles = useStyles();
  const toggleHidden = () => {
    setIsHidden(!isHidden);
  };

  return (
    <View style={styles.container}>
      <View style={styles.firstColumnContent}>
        <Text style={styles.title}>{title}</Text>
      </View>

      <View style={styles.content}>
        {isHidden ? (
          <Text>**********</Text>
        ) : (
          <View>{value && <Text style={styles.value}>{value}</Text>}</View>
        )}
        <View style={styles.options}>
          <TouchableOpacity style={styles.option} onPress={onRefresh}>
            <Icon
              icon={RefreshCWIcon}
              color={theme.palette.primary[400]}
              size={16}
            />
            <Text style={styles.refreshOptionText}>{getText('refresh')}</Text>
          </TouchableOpacity>
          <TouchableOpacity style={styles.option} onPress={toggleHidden}>
            <Icon
              icon={isHidden ? EyeIcon : EyeOffIcon}
              color={theme.palette.gray[400]}
              size={16}
            />
            <Text style={styles.optionText}>
              {isHidden ? getText('show') : getText('hide')}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'row',
    gap: 15,
    alignItems: 'flex-start',
    borderBottomColor: theme.palette.gray[200],
    borderBottomWidth: 1,
    padding: theme.getSpacing(1),
    marginBottom: theme.getSpacing(1),
  },
  firstColumnContent: {
    flex: 1,
    flexDirection: 'row',
  },
  title: {
    ...theme.lumistryFonts.label.small.semiBold,
    color: theme.palette.gray[900],
  },
  value: {
    ...theme.fonts.regular,
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[700],
  },
  secretValue: {
    display: 'flex',
    justifyContent: 'space-between',
    ...theme.fonts.regular,
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[700],
  },
  content: {
    flex: 4,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  options: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    marginLeft: theme.getSpacing(2),
  },
  optionText: {
    marginLeft: theme.getSpacing(1),
  },
  refreshOptionText: {
    marginLeft: theme.getSpacing(1),
    color: theme.palette.primary[700],
  },
}));

interface RowItemRenderProps {
  title: string;
  value?: string;
  isSecretValue?: boolean;
  onRefresh: () => void;
}
