import React, { useEffect } from 'react';
import {
  getDirectMessageSettings,
  setIsLumistryUser,
} from './message-settings-actions';
import { useAppStateStore } from '../../../store/app-store';
import MessageSettingsFormModal from './components/MessageSettingsAutoFormModal';
import { useMessageSettingsState } from './message-settings-store';
import SettingsSection from '../../../components/Settings/SettingsSection';
import { MessageSettingsItemRenderer } from './components/MessageSettingsItemRenderer';
import { getText } from 'assets/localization/localization';
import SettingsPageLayout from '../../../components/Settings/SettingsPageLayout';
import { GeneralSettingsRenderer } from './components/GeneralSettingsRenderer';

export default function MessageSettings() {
  const { locationId, pharmacyId } = useAppStateStore();
  const { messageSettingsConfig } = useMessageSettingsState();
  useEffect(() => {
    void getDirectMessageSettings(pharmacyId, locationId);
    void setIsLumistryUser();
  }, [pharmacyId, locationId]);

  return (
    <SettingsPageLayout title={getText('messages')}>
      <SettingsSection
        title={getText('general')}
        contentHeader={{
          title: getText('details'),
        }}
        contentData={[messageSettingsConfig]}
        contentItem={GeneralSettingsRenderer}
      />
      <SettingsSection
        title={getText('patient-notifications')}
        contentHeader={{
          title: getText('details'),
        }}
        contentData={[messageSettingsConfig]}
        contentItem={MessageSettingsItemRenderer}
        customTypeModal={MessageSettingsFormModal}
      />
    </SettingsPageLayout>
  );
}
