import { getText } from 'assets/localization/localization';
import { PatientWebsiteOption, PatientWebsiteTemplate } from './types';
import {
  WebsiteCompoundingDetailSlug,
  WebsitePharmacyServiceOptionSlug,
  WebsiteRequired,
  WebsiteServiceOptionSlug,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { ChecklistModalIds } from '../../store/checklist-store';

export const ChecklistSetupTitle: Record<string, string> = {
  'basic-setup': getText('basic-setup'),
  'media-settings': getText('media-settings'),
  'patient-agreements': getText('patient-agreements'),
  'patient-website': getText('patient-website'),
  'patient-app': getText('patient-app'),
  'scanner-config': getText('scanner-config'),
  'clinical-services': getText('clinical-services'),
  'refill-settings': getText('refill-settings'),
  'message-settings': getText('message-settings'),
};

export const ChecklistSetupDesc: Record<string, string> = {
  'basic-setup': getText('basic-setup-desc'),
  'media-settings': getText('media-settings-desc'),
  'patient-agreements': getText('patient-agreements-desc'),
  'patient-website': getText('patient-website-desc'),
  'patient-app': getText('patient-app-desc'),
  'scanner-config': getText('scanner-config-desc'),
  'clinical-services': getText('clinical-services-desc'),
  'refill-settings': getText('refill-settings-desc'),
  'message-settings': getText('message-settings-desc'),
};

export const SpecsList: string[] = [
  getText('specs-first-item'),
  getText('specs-second-item'),
  getText('specs-third-item'),
];

export const PatientWebsiteOptions: PatientWebsiteOption[] = [
  {
    text: getText('build-replace-website'),
    value: 'build-to-replace',
  },
  {
    text: getText('build-new-website'),
    value: 'build-do-not-have',
  },
  {
    text: getText('no-build-existing-website'),
    value: 'do-not-build-already-have',
  },
  {
    text: getText('no-build-no-existing-website'),
    value: 'do-not-build-do-not-need',
  },
];

export const PatientWebsiteTemplates: PatientWebsiteTemplate[] = [
  {
    type: 'Stagecoach',
    source: require('../../../assets/website-templates/stagecoach.png'),
    url: 'https://stagecoachrebuild.spacecrafted.com/',
  },
  {
    type: 'Alamo',
    source: require('../../../assets/website-templates/alamo.png'),
    url: 'https://alamorebuild.spacecrafted.com/',
  },
  {
    type: 'Monarch',
    source: require('../../../assets/website-templates/monarch.png'),
    url: 'https://monarchrebuild.spacecrafted.com/',
  },
  {
    type: 'Darly',
    source: require('../../../assets/website-templates/darly.png'),
    url: 'https://darlyrebuild.spacecrafted.com/',
  },
  {
    type: 'Longhorn',
    source: require('../../../assets/website-templates/longhorn.png'),
    url: 'https://longhornrebuild.spacecrafted.com/',
  },
  {
    type: 'Lonestar',
    source: require('../../../assets/website-templates/lonestar.png'),
    url: 'https://lonestarrebuild.spacecrafted.com/',
  },
  {
    type: 'Ladybird',
    source: require('../../../assets/website-templates/ladybird.png'),
    url: 'https://ladybirdrebuild.spacecrafted.com/',
  },
  {
    type: 'Armadillo',
    source: require('../../../assets/website-templates/armadillo.png'),
    url: 'https://armadillorebuild.spacecrafted.com/',
  },
];

export const PatientWebsiteService: Record<WebsiteServiceOptionSlug, string> = {
  'about-us': getText('about-us'),
  'medication-adherence': getText('medication-adherence'),
  'pharmacy-services': getText('pharmacy-services'),
  'compliance-packaging': getText('compliance-packaging'),
  immunizations: getText('immunizations'),
  'medication-synchronization': getText('medication-synchronization'),
  delivery: getText('delivery'),
  'contact-us': getText('contact-us'),
  compounding: getText('compounding'),
};

export const PatientWebsiteServiceDetail: Record<
  WebsitePharmacyServiceOptionSlug,
  string
> = {
  'pet-care': getText('pet-care'),
  'gift-shop': getText('gift-shop'),
  DurableMedicalEquipment: getText('durable-medical-equipment'),
  'hormone-testing': getText('hormone-testing'),
  'vitamins-and-supplements': getText('vitamins-and-supplements'),
  'diabetes-education': getText('diabetes-education'),
  'free-children-vitamin-program': getText('free-children-vitamin-program'),
  'pharmacogenomic-testing': getText('pharmacogenomic-testing'),
  'specialty-medication': getText('specialty-medication'),
  'health-screenings': getText('health-screenings'),
  'long-term-care': getText('long-term-care'),
  'nutrient-depletion-counseling': getText('nutrient-depletion-counseling'),
  'medication-adherence': getText('medication-adherence'),
  'medicare-open-=enrollment': getText('medicare-open-enrollment'),
  'erectile-dysfunction': getText('erectile-dysfunction'),
  'mens-health-program': getText('mens-health-program'),
  'women-health-program': getText('women-health-program'),
  'medication-therapy-management': getText('medication-therapy-management'),
  'flu-and-strep-testing': getText('flu-and-strep-testing'),
};

export const PatientWebsiteCompounding: Record<
  WebsiteCompoundingDetailSlug,
  string
> = {
  sterile: getText('sterile'),
  hazardous: getText('hazardous'),
  'non-hazardous': getText('non-hazardous'),
  'non-sterile': getText('non-sterile'),
  dermatology: getText('dermatology'),
  bhrt: 'BHRT',
  iv: 'IV',
  veterinary: getText('veterinary'),
  opthalmology: getText('opthalmology'),
  infusions: getText('infusions'),
  podiatry: getText('podiatry'),
  injections: getText('injections'),
  pediatric: getText('pediatric'),
  urology: getText('urology'),
  ldn: 'LDN',
  'wound-care': getText('wound-care'),
  pain: getText('pain'),
  'ent-sinus': getText('ent-sinus'),
  hormone: getText('hormone'),
};

export const MODAL_MAPPINGS: Record<string, ChecklistModalIds> = {
  [WebsiteServiceOptionSlug.PharmacyServices]:
    'patient-website-service-details',
  [WebsiteServiceOptionSlug.Compounding]: 'patient-website-compounding-details',
  [WebsiteServiceOptionSlug.Delivery]: 'patient-website-delivery-details',
  [WebsiteServiceOptionSlug.AboutUs]: 'patient-website-about-us-details',
};

export const getAccessButtonText = (service: WebsiteRequired): string => {
  switch (service) {
    case 'build-do-not-have':
    case 'build-to-replace':
      return getText('next');
    case 'do-not-build-already-have':
    case 'do-not-build-do-not-need':
      return getText('ok');
    default:
      return getText('next');
  }
};
