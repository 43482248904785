import ReactPlayerLoader from '@brightcove/react-player-loader';
import { useIsFocused } from '@react-navigation/native';
import { CopyToClipboard } from 'assets/components/copy-to-clipboard/CopyToClipboard';
import { Icon } from 'assets/components/icon';
import { LoadingIndicator } from 'assets/components/loading-indicator/LoadingIndicator';
import {
  ArrowLeftIcon,
  ClockIcon,
  EmptyCircleIcon,
  EyeIcon,
  FileIcon,
  MedicationIcon,
  ShareIcon,
} from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { logError } from 'assets/logging/logger';
import { getSpacing, makeStyles, useTheme } from 'assets/theme';
import { format, intervalToDuration } from 'date-fns';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Separator } from 'react-contexify';
import { Text, TouchableOpacity, View } from 'react-native';
import VideoService, { VideoResponseModel } from '../../api/VideoService';
import { VUCA_API_BASE_URL } from '../../common/constants';
import { useToast } from '../../common/hooks/useToast';
import { VideoDetailScreenRouteProp } from '../../layout/VideosDrawer';
import { useAppStateStore } from '../../store/app-store';
import { useVideosState } from '../../store/videos-store';

const VideoDetails: FunctionComponent<VideoDetailScreenRouteProp> = ({
  route,
  navigation,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const pharmacySlug = useAppStateStore((x) => x.pharmacySlug);
  const { toast } = useToast();
  const [videoItem, setVideoItem] = useState<VideoResponseModel>();
  const [videoDuration, setVideoDuration] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const locale = route.params.locale;
  const { setBCPlayer, bcPlayer, setShowVideoShareModal } = useVideosState();
  const isFocused = useIsFocused();

  if (!isFocused) {
    try {
      bcPlayer.pause();
    } catch (e) {
      logError(e as Error);
    }
  }

  const onSuccess = (success: any) => {
    const myPlayer = success.ref;
    myPlayer.one('loadstart', () => {
      const duration = intervalToDuration({
        start: 0,
        end: myPlayer.mediainfo.duration * 1000,
      });
      const { hours, minutes, seconds: secs } = duration;
      const formattedTime = format(
        new Date(0, 0, 0, hours, minutes, secs),
        `${hours ? 'H:' : ''}m:ss`,
      );
      setVideoDuration(formattedTime);
      myPlayer.on('play', () => {
        setBCPlayer(myPlayer);
      });
    });
  };

  useEffect(() => {
    if (route.params.video_id) {
      fetchVideoItem();
    }
  }, [route.params.video_id, locale]);

  const fetchVideoItem = () => {
    setIsLoading(true);
    setVideoItem(undefined);
    if (route.params.video_id) {
      void VideoService.getVideoInfo(
        route.params.video_id,
        locale,
        pharmacySlug,
      )
        .then((video) => {
          setIsLoading(false);
          setVideoItem(video);
        })
        .catch((error: Error) => logError(error));
    } else {
      backToVideos();
    }
  };

  const backToVideos = () => {
    navigation.navigate(route.params.collection_page || 'browse');
  };

  const changeLanguage = () => {
    navigation.navigate('detail', {
      video_id: route.params.video_id,
      collection_page: route.params.collection_page,
      locale: locale === 'es-US' ? 'en-US' : 'es-US',
    });
  };

  const onFailure = (data: any) => {
    console.log('Brightcove player ERROR', data);
  };

  return (
    <>
      {videoItem ? (
        <View style={styles.videoDetailsView}>
          <View style={styles.rowView}>
            <TouchableOpacity
              style={[styles.topBarButton, styles.borderRight]}
              onPress={backToVideos}
            >
              <Icon
                icon={ArrowLeftIcon}
                color={theme.palette.gray[700]}
                size={16}
              />
              <Text style={styles.topBarText}>{getText('videos')}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.topBarButton}
              onPress={() => {
                setShowVideoShareModal(true, videoItem.short_url);
              }}
            >
              <Icon
                icon={ShareIcon}
                color={theme.palette.gray[700]}
                size={16}
                strokeWidth={1.5}
              />
              <Text style={styles.topBarText}>{getText('videos-share')}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.topBarButton}
              onPress={changeLanguage}
              disabled={isLoading}
            >
              <Icon icon={EyeIcon} color={theme.palette.gray[700]} size={16} />
              <Text style={styles.topBarText}>
                {locale === 'es-US'
                  ? getText('videos-language-english')
                  : getText('videos-language-spanish')}
              </Text>
            </TouchableOpacity>
          </View>

          <View style={styles.videoTitle}>
            <Text style={styles.videoTitleText}>{videoItem.name}</Text>
          </View>

          <Separator />

          <View style={styles.videoPlayer}>
            {!isLoading && videoItem.value.video_id && (
              <ReactPlayerLoader
                accountId={videoItem.value.account_id}
                videoId={videoItem.value.video_id}
                playerId={videoItem.value.player_id}
                onSuccess={onSuccess}
                onFailure={onFailure}
                embedOptions={{ responsive: true }}
                manualReloadFromPropChanges="true"
                options={{
                  autoplay: false,
                  controls: false,
                  preload: 'metadata',
                  fluid: true,
                  playsinline: true,
                }}
              />
            )}
          </View>

          <View style={[styles.rowView, styles.videoDetails]}>
            <View style={{ width: 167 }}>
              <Text style={styles.videoDetailsTitle}>
                {getText('videos-category')}
              </Text>
              <View style={styles.rowView}>
                <Icon
                  icon={MedicationIcon}
                  size={16}
                  color={theme.palette.gray[500]}
                />
                <Text
                  style={[
                    styles.videoDetailsValue,
                    { marginLeft: getSpacing(1) },
                  ]}
                >
                  {videoItem.category.label}
                </Text>
              </View>
            </View>

            <View style={{ width: 118 }}>
              <Text style={styles.videoDetailsTitle}>
                {getText('videos-duration')}
              </Text>
              <View style={styles.rowView}>
                <Icon
                  icon={ClockIcon}
                  size={16}
                  color={theme.palette.gray[500]}
                />
                <Text
                  style={[
                    styles.videoDetailsValue,
                    { marginLeft: getSpacing(1) },
                  ]}
                >
                  {videoDuration}
                </Text>
              </View>
            </View>

            <View style={{ paddingLeft: getSpacing(4) }}>
              <Text style={styles.videoDetailsTitle}>
                {getText('videos-url')}
              </Text>
              <View style={styles.rowView}>
                <Text style={styles.videoDetailsValue}>
                  {videoItem.short_url}
                </Text>
                <View style={styles.clipboard}>
                  <CopyToClipboard
                    color={theme.palette.gray[500]}
                    fetchFromClipboard={() =>
                      toast(getText('copied-to-clipboard'))
                    }
                    logger={{
                      id: 'copy-to-clipboard-video-url',
                    }}
                    stringToCopy={videoItem.short_url}
                    size={16}
                  />
                </View>
              </View>
            </View>
          </View>

          {videoItem.medication_guides.length > 0 && (
            <View style={{ marginTop: getSpacing(3) }}>
              <Text
                style={[
                  styles.videoDetailsTitle,
                  { paddingBottom: getSpacing(1) },
                ]}
              >
                {getText('videos-med-guides')}
              </Text>
              {/* TODO PDFs need to be opened in a browser view instead of being downloaded */}
              {Object.entries(videoItem.medication_guides_pdf_download_uri).map(
                ([code, uri]) => (
                  <View style={styles.rowView}>
                    <a href={`${VUCA_API_BASE_URL}${uri}`}>
                      <Icon
                        icon={FileIcon}
                        size={16}
                        color={theme.palette.primary[600]}
                      />
                      <Text
                        style={styles.medGuidesText}
                      >{`${videoItem.medication_guides_title} (${code})`}</Text>
                    </a>
                  </View>
                ),
              )}
            </View>
          )}

          <View style={{ marginTop: getSpacing(3) }}>
            <Text
              style={[
                styles.videoDetailsTitle,
                { paddingBottom: getSpacing(1) },
              ]}
            >
              {getText('videos-transcript')}
            </Text>
            <Text style={styles.transcriptValue}>
              {videoItem.value.transcript}
            </Text>
          </View>
        </View>
      ) : isLoading ? (
        <View style={styles.center}>
          <LoadingIndicator size={75} />
        </View>
      ) : (
        <View style={styles.center}>
          <Icon icon={EmptyCircleIcon} size={75} />
        </View>
      )}
    </>
  );
};

export default VideoDetails;

const useStyles = makeStyles((theme) => ({
  videoDetailsView: {
    marginLeft: getSpacing(3),
    marginTop: getSpacing(2),
  },
  rowView: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  topBarButton: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    paddingRight: getSpacing(3),
    marginRight: getSpacing(3),
  },
  topBarText: {
    color: theme.palette.gray[700],
    fontWeight: '400',
    fontSize: 16,
    lineHeight: 26,
    marginLeft: getSpacing(1),
  },
  borderRight: {
    borderRightColor: theme.palette.gray[300],
  },
  videoTitle: {
    marginTop: getSpacing(2),
  },
  videoTitleText: {
    fontWeight: '500',
    fontSize: 24,
    lineHeight: 26,
    color: theme.palette.gray[900],
  },
  videoPlayer: {
    width: '80%',
  },
  videoDetailsTitle: {
    fontSize: 16,
    fontWeight: '700',
    color: theme.palette.gray[900],
    lineHeight: 18,
  },
  videoDetails: {
    marginTop: getSpacing(1),
  },
  videoDetailsValue: {
    fontSize: 16,
    fontWeight: '400',
    color: theme.palette.gray[700],
    lineHeight: 24,
  },
  medGuidesText: {
    fontSize: 16,
    fontWeight: '400',
    color: theme.palette.primary[600],
    lineHeight: 24,
    marginLeft: getSpacing(1),
  },
  transcriptValue: {
    fontSize: 16,
    fontWeight: '400',
    color: theme.palette.gray[700],
    lineHeight: 24,
  },
  center: {
    paddingTop: '30%',
  },
  clipboard: {
    height: 16,
    marginTop: -16,
  },
}));
