import {
  View,
  Text,
  Pressable,
  GestureResponderEvent,
  TextStyle,
  ViewStyle,
} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useForm } from 'react-hook-form';
import { ImageIcon, PlusCircleIcon } from 'assets/icons';
import { PharmacySidebar } from '../PharmacySidebar';
import { PrescriptionDto } from '@digitalpharmacist/prescription-service-client-axios';
import EditPatientModal from '../EditPatientModal/EditPatientModal';
import { Avatar } from 'assets/components/avatar';
import { getText } from 'assets/localization/localization';
import { Button } from 'assets/components/button';
import { prettyFormat } from '@digitalpharmacist/validation-dp';
import NewChatButton from '../../screens/messages/components/new-chat-button/NewChatButton';
import NotesEditModal from '../NotesEditModal/NotesEditModal';
import InsuranceDetailsSection, {
  InfoRowEnd,
} from '../InsuranceDetailsModal/InsuranceDetailsSection';
import PhotoIdModal, { PhotoCard } from '../PhotoIDModal/PhotoIDModal';
import {
  allergiesAreExplicitlyEmpty,
  findAllergiesById,
  findConditionsById,
} from '../../screens/refill-submissions/medication-actions';

import { useStyles } from './styles';
import { AllergiesAlert } from './AllergiesAlert';
import { InfoRow } from './InfoRow';
import { calculatePatientAge, formatDate } from '../../common/datetime-utils';
import { Icon } from 'assets/components/icon';
import { SectionHeader } from './SectionHeader';
import { LocationPatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';
import { FC, useEffect, useState } from 'react';
import PatientMedsModal from '../PatientMedsModal/PatientMedsModal';
import { CSSObject, useProSidebar } from 'react-pro-sidebar';
import PatientService from '../../api/PatientService';
import { getFullName } from 'assets/utils/messageUtils';
import { ResourceItem } from '../../api/MedicationService';
import { Notifications } from './Notifications';
import { Address } from './Address';
import { useTheme } from 'assets/theme';
import { setLocationPatientRecordDto as setAppointmentLocationPatientRecord } from '../../schedule/appointment-form/appointment-form-actions';
import { updateLocationPatientRecord } from '../EditPatientModal/editPatientActions';

interface PatientDetailSidebarViewProps {
  locationPatientRecordDto: LocationPatientRecordDto;
  onCollapse?: () => void;
  backLinkComponent?: JSX.Element;
  rootStyle?: CSSObject;
  containerStyles?: CSSObject;
  parentContainerStyle?: ViewStyle;
  patientPrescriptions: PrescriptionDto[];
  updateLPRCallback: (lpr: LocationPatientRecordDto) => void;
}

export const PatientDetailSidebarView: FC<PatientDetailSidebarViewProps> = ({
  locationPatientRecordDto,
  patientPrescriptions,
  onCollapse,
  updateLPRCallback,
  backLinkComponent,
  rootStyle = {},
  containerStyles = {},
  parentContainerStyle = {},
}) => {
  const MAX_MEDS_ITEM_TO_SHOW = 10;
  const navigation = useNavigation<any>();
  const [medicalConditions, setMedicalConditions] = useState<ResourceItem[]>();
  const [showPhotoIdModal, setShowPhotoIdModal] = useState(false);
  const [showEditNoteModal, setShowEditNoteModal] = useState(false);
  const [showMedsModal, setShowMedsModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [allergies, setAllergies] = useState<ResourceItem[]>();

  const { collapseSidebar } = useProSidebar();

  const theme = useTheme();
  const styles = useStyles();

  const full_name = getFullName(locationPatientRecordDto);

  const capitalizeStyles: TextStyle = { textTransform: 'capitalize' };

  useEffect(() => {
    if (locationPatientRecordDto.allergies === undefined) return;
    void (async () => {
      if (
        locationPatientRecordDto.allergies !== undefined &&
        locationPatientRecordDto.allergies.length > 0
      ) {
        const loadedAllergies = await findAllergiesById(
          locationPatientRecordDto.allergies,
        );
        setAllergies(loadedAllergies);
      } else {
        setAllergies([]);
      }
    })();
  }, [locationPatientRecordDto.allergies]);

  const methods = useForm({
    defaultValues: {
      notes: locationPatientRecordDto.notes,
    },
  });

  useEffect(() => {
    if (!locationPatientRecordDto.medical_conditions) return;
    void (async () => {
      if (
        locationPatientRecordDto.medical_conditions !== undefined &&
        locationPatientRecordDto.medical_conditions.length > 0
      ) {
        const loadedMedicalConditions = await findConditionsById(
          locationPatientRecordDto.medical_conditions,
        );
        setMedicalConditions(loadedMedicalConditions);
      } else {
        setMedicalConditions([]);
      }
    })();
  }, [locationPatientRecordDto.medical_conditions]);

  function toggleShowMedsModal(): void {
    setShowMedsModal((prev) => !prev);
  }

  async function verifyPatient() {
    try {
      if (!locationPatientRecordDto) return;

      const verifiedPatient: LocationPatientRecordDto =
        await PatientService.updateLocationPatientRecord(
          locationPatientRecordDto.location_id,
          locationPatientRecordDto.id,
          {
            is_verified: true,
          },
        );

      updateLPRCallback(verifiedPatient);
    } catch (error) {
      console.error('Error verifying a patient: ', error);
    }
  }

  const age = locationPatientRecordDto.date_of_birth
    ? calculatePatientAge(locationPatientRecordDto.date_of_birth)
    : undefined;

  const handleCreateAppointmentPress = () => {
    setAppointmentLocationPatientRecord(locationPatientRecordDto);
    navigation.navigate('schedule', {
      screen: 'new-appointment',
      params: {
        isPrefilled: true,
      },
    });
    collapseSidebar(true);
  };

  const handleNotesModalSavePress = async () => {
    if (!locationPatientRecordDto) return;

    const locationPatientRecordToUpdate = {
      notes: methods.getValues('notes'),
    };
    const updatedLocationPatientRecord = await updateLocationPatientRecord(
      locationPatientRecordDto.location_id,
      locationPatientRecordDto.id,
      locationPatientRecordToUpdate,
    );
    if (updatedLocationPatientRecord) {
      updateLPRCallback(updatedLocationPatientRecord);
    }
    setShowEditNoteModal(false);
  };

  function toggleShowEditModal(event: GestureResponderEvent): void {
    if (locationPatientRecordDto) {
      setShowEditModal((prev) => !prev);
    } else {
      alert(
        'Location Patient Record does not exist. This is usually caused by bad test data',
      );
    }
  }

  return (
    <PharmacySidebar
      title={getText('patient-details')}
      dataExists={!!locationPatientRecordDto}
      onCollapse={onCollapse}
      backLinkComponent={backLinkComponent}
      containerStyles={containerStyles}
      rootStyle={rootStyle}
      parentContainerStyle={parentContainerStyle}
    >
      <View>
        <View style={styles.row}>
          {/*
          {locationPatientRecord.user_id && (
            <ColoredBadge
              label={getText('account-holder')}
              color={theme.palette.gray[900]}
              backgroundColor={theme.palette.gray[100]}
              textStyle={styles.badgeText}
            />
          )} */}

          {/*
          // In accordance with Jira issue BLUES-4662, we need to hide functionalities that have not yet been implemented.
          <View style={styles.buttonsRow}>
            <IconButton
              onPress={() => {}}
              icon={TrashIcon}
              logger={{ id: 'delete-patient' }}
              color={theme.palette.gray[500]}
              size={15}
            />
            <Pressable onPress={toggleShowEditModal}>
              <View style={styles.row}>
                <IconButton
                  onPress={() => {}}
                  icon={PencilIcon}
                  logger={{ id: 'bookmark-user' }}
                  color={theme.palette.gray[500]}
                  size={15}
                />
                <Text style={styles.editText}>{getText('edit')}</Text>
              </View>
            </Pressable>
          </View> */}
        </View>

        <View style={[styles.nameBar]}>
          <Text style={styles.patientName}>{full_name}</Text>
          <Avatar
            firstName={locationPatientRecordDto.first_name}
            lastName={locationPatientRecordDto.last_name}
            size={40}
          />
        </View>
        {!allergiesAreExplicitlyEmpty(
          locationPatientRecordDto.allergies || [],
        ) ? (
          <AllergiesAlert
            locationPatientRecordDto={locationPatientRecordDto}
            allergies={allergies}
          />
        ) : (
          <Text>{getText('no-known-allergies')}</Text>
        )}
        <View style={styles.chatButton}>
          <NewChatButton
            patient={locationPatientRecordDto}
            verifyPatient={verifyPatient}
            buttonHierarchy="secondary-gray"
            buttonText={getText('chat')}
            buttonSize="large"
          />
        </View>

        <View style={styles.info}>
          <InfoRow
            label={getText('date-of-birth')}
            value={`${formatDate(
              locationPatientRecordDto.date_of_birth,
            )} (age ${age})`}
          />
          <InfoRow
            label={getText('cell-phone')}
            value={
              !locationPatientRecordDto.cell_phone
                ? getText('info-not-available')
                : prettyFormat(locationPatientRecordDto.cell_phone)
            }
          />
          <InfoRow
            label={getText('home-phone')}
            value={
              !locationPatientRecordDto.home_phone
                ? getText('info-not-available')
                : prettyFormat(locationPatientRecordDto.home_phone)
            }
          />
          <InfoRow
            label={getText('other-phone')}
            value={
              !locationPatientRecordDto.other_phone
                ? getText('info-not-available')
                : prettyFormat(locationPatientRecordDto.other_phone)
            }
          />
          <InfoRow
            label={getText('email-text')}
            value={locationPatientRecordDto.email || getText('not-entered')}
            valueStyles={
              !locationPatientRecordDto.email
                ? capitalizeStyles
                : { textTransform: 'none' }
            }
          />
          <InfoRow
            label={getText('gender-text')}
            value={locationPatientRecordDto.gender || getText('not-entered')}
            valueStyles={capitalizeStyles}
          />

          <InfoRow
            label={getText('conditions')}
            value={
              <Text>
                {medicalConditions && medicalConditions.length > 0
                  ? medicalConditions.map((v) => v.text).join(', ')
                  : getText('not-entered')}
              </Text>
            }
            valueStyles={
              !medicalConditions || medicalConditions.length == 0
                ? capitalizeStyles
                : { textTransform: 'none' }
            }
          />
          <InfoRow
            label={getText('bottle-cap-preference')}
            value={getBottleCapPrefString(
              locationPatientRecordDto.prefers_easy_open_bottle_caps,
            )}
          />
          <InfoRow
            label={getText('med-sync')}
            value={
              locationPatientRecordDto.prefers_med_sync
                ? getText('yes')
                : getText('no')
            }
            valueStyles={capitalizeStyles}
          />

          <InfoRow
            label={getText('photo-id-text')}
            value={
              locationPatientRecordDto.photo_id_url ? (
                <Pressable
                  style={[styles.photoId]}
                  onPress={() => {
                    locationPatientRecordDto.photo_id_url
                      ? setShowPhotoIdModal(true)
                      : {};
                  }}
                >
                  <Icon
                    icon={ImageIcon}
                    size={14}
                    color={theme.palette.primary[600]}
                  />
                  <Text style={styles.primaryText}>{getText('image')}</Text>
                </Pressable>
              ) : (
                getText('no-id-card')
              )
            }
          />
          <Notifications patientDetails={locationPatientRecordDto} />
          <Address dto={locationPatientRecordDto.address} />

          <View style={styles.appointmentButtonContainer}>
            {locationPatientRecordDto.patient_record_id && (
              <Button
                hierarchy="secondary"
                onPress={handleCreateAppointmentPress}
                logger={{ id: 'patient-detail-sidebar-create-appointment' }}
              >
                {getText('create-appointment')}
              </Button>
            )}
          </View>

          <SectionHeader
            label={getText('medications')}
            buttonText={getText('list')}
            showButton={
              patientPrescriptions && patientPrescriptions.length > 0
                ? true
                : false
            }
            showPlusIcon={false}
            onPressText={() => toggleShowMedsModal()}
          />

          {patientPrescriptions && patientPrescriptions.length > 0 ? (
            <>
              <>
                {patientPrescriptions
                  .slice(0, MAX_MEDS_ITEM_TO_SHOW)
                  .sort((a, b) => {
                    if (!a.last_refill_date) return 1;
                    if (!b.last_refill_date) return -1;
                    return a.last_refill_date > b.last_refill_date ? 1 : -1;
                  })
                  .sort((a, b) => {
                    if (!a.rx_status) return 1;
                    if (!b.rx_status) return -1;
                    return a.rx_status > b.rx_status ? 1 : -1;
                  })
                  .map((prescription: PrescriptionDto) => {
                    return (
                      <InfoRowEnd
                        key={prescription.prescription_id}
                        label={prescription.drug_name}
                        value={`Rx ${prescription.rx_number}`}
                      />
                    );
                  })}
              </>
              {patientPrescriptions &&
                patientPrescriptions.length > MAX_MEDS_ITEM_TO_SHOW && (
                  <Pressable onPress={() => toggleShowMedsModal()}>
                    <Text style={styles.moreMedsText}>
                      +
                      {patientPrescriptions.slice(MAX_MEDS_ITEM_TO_SHOW).length}{' '}
                      {getText('more')}
                    </Text>
                  </Pressable>
                )}
            </>
          ) : (
            <Text style={styles.noneText}>{getText('none')}</Text>
          )}
          <PhotoIdModal
            show={showPhotoIdModal}
            setShowModal={setShowPhotoIdModal}
            locationPatientRecord={locationPatientRecordDto}
            cardType={PhotoCard.PhotoId}
          ></PhotoIdModal>
          <SectionHeader label={getText('insurance')} showPlusIcon={false} />
          <InsuranceDetailsSection
            locationPatientRecord={locationPatientRecordDto}
            hasPrimary={
              !!locationPatientRecordDto.insurance_card_primary_front_url ||
              !!locationPatientRecordDto.insurance_card_primary_back_url
            }
            hasSecondary={
              !!locationPatientRecordDto.insurance_card_secondary_front_url ||
              !!locationPatientRecordDto.insurance_card_secondary_back_url
            }
          />

          <View style={[styles.row, styles.sectionHeader]}>
            <Text style={styles.sectionLabelText}>
              {getText('notes').toUpperCase()}
            </Text>
            <Pressable
              style={[styles.editNotes]}
              onPress={() => setShowEditNoteModal(true)}
            >
              <Text style={styles.internalUseOnlyText}>
                {getText('internal-use-only')}
              </Text>
              <Text style={[styles.editNotesText]}>{getText('edit')}</Text>
            </Pressable>
          </View>

          <View style={styles.notesContainer}>
            <Text style={styles.notesText}>
              {locationPatientRecordDto.notes
                ? locationPatientRecordDto.notes
                : getText('no-notes-attached-to-patient')}
            </Text>
          </View>
        </View>
        <NotesEditModal
          show={showEditNoteModal}
          onDismiss={() => setShowEditNoteModal(false)}
          onSave={handleNotesModalSavePress}
          form={methods}
          notes={locationPatientRecordDto.notes || ''}
        />
        <EditPatientModal
          show={showEditModal}
          locationPatientRecord={locationPatientRecordDto}
          setShowEditModal={setShowEditModal}
          patientDetails={locationPatientRecordDto}
        />
        <PatientMedsModal
          show={showMedsModal}
          close={() => toggleShowMedsModal()}
          locationPatientRecord={locationPatientRecordDto}
          patientPrescriptions={patientPrescriptions}
        />
      </View>
    </PharmacySidebar>
  );
};

function getBottleCapPrefString(value: boolean | null | undefined): string {
  if (value === null || value === undefined) {
    return getText('not-entered');
  }

  return value
    ? getText('prefers-easy-open-caps')
    : getText('prefers-safety-caps');
}
