import { useNavigation } from '@react-navigation/native';
import { Button } from 'assets/components/button';
import { Text } from 'assets/components/text';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import React, { FunctionComponent } from 'react';
import { FlatList, Image, View } from 'react-native';
import { RootStackNavigationProp } from '../../../navigation/RootNavigation';
import OBDivider from './divider/OBDivider';
import { OBRocketIllustrationIcon } from './icons/OBRocketIllustrationIcon';
import {
  loadLocationsAndSelectLastCreated,
  resetLocationSelection,
} from '../../../actions/app-actions';
import { useOnboardingStore } from '../../../store/onboarding-store';

export const OBSuccess: FunctionComponent = () => {
  const styles = useStyles();
  const { data } = useOnboardingStore();
  const navigation = useNavigation<RootStackNavigationProp>();
  const stepsList = [
    getText('complete-new-account'),
    getText('employess-to-be-notified'),
    getText('monitor-progress'),
  ];

  const handleGoOnDashboardPress = async () => {
    if (data.locations.length > 1) {
      await resetLocationSelection();
      navigation.navigate('store-location');
    } else {
      await loadLocationsAndSelectLastCreated();
      navigation.navigate('dashboard');
    }
  };

  return (
    <View style={styles.root}>
      <View style={styles.imageContainer}>
        <OBRocketIllustrationIcon />
      </View>
      <Text style={styles.title}>{getText('pharmacy-taking-off')}</Text>
      <View style={styles.container}>
        <Text style={styles.subtitle}>{getText('next-steps')}</Text>
        <FlatList
          data={stepsList}
          renderItem={({ item }) => (
            <View style={styles.itemContainer}>
              <Text style={styles.bullet}>•</Text>
              <Text style={styles.item}>{item}</Text>
            </View>
          )}
          keyExtractor={(item, index) => index.toString()}
        />
      </View>
      <View style={styles.container}>
        <Text style={styles.subtitle}>{getText('check-progress')}</Text>
        <Image
          source={require('../../../../assets/dashboard.png')}
          style={styles.dashboardImage}
        />
      </View>
      <OBDivider />
      <View style={styles.buttonContainer}>
        <Button
          hierarchy={'primary'}
          logger={{
            id: 'go-to-dashboard',
          }}
          onPress={handleGoOnDashboardPress}
          style={styles.button}
        >
          {getText('go-to-dashboard')}
        </Button>
      </View>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    alignContent: 'center',
    gap: theme.getSpacing(4),
    paddingBottom: theme.getSpacing(4),
  },
  container: {
    gap: theme.getSpacing(1),
  },
  title: {
    ...theme.lumistryFonts.label.xxLarge.regular,
    fontSize: 32,
    color: theme.palette.gray[900],
    textAlign: 'center',
  },
  subtitle: {
    ...theme.lumistryFonts.text.medium.semiBold,
    color: theme.palette.gray[900],
  },
  imageContainer: {
    alignItems: 'center',
  },
  image: {
    height: 290,
    width: 370,
  },
  dashboardImage: {
    height: 310,
    width: 485,
  },
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: theme.getSpacing(1),
  },
  bullet: {
    fontSize: theme.getSpacing(2),
    color: theme.palette.gray[700],
  },
  item: {
    ...theme.lumistryFonts.text.medium.regular,
    marginLeft: theme.getSpacing(1),
    color: theme.palette.gray[700],
  },
  buttonContainer: {
    alignItems: 'center',
  },
  button: {
    width: 230,
  },
}));
