import { PharmacistLocationDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { Role } from '@digitalpharmacist/role-service-client-axios';
import { create } from './store-creator';

export interface AppState {
  pharmacySlug: string;
  pharmacyId: string;
  locationId: string;
  pharmacyName: string;
  userMostRecentLocations: PharmacistLocationDto[];
  hasMoreLocations: boolean;
  availableRoles: Role[];
}

export const useAppStateStore = create<AppState>((set) => ({
  pharmacyId: '',
  locationId: '',
  pharmacySlug: '',
  pharmacyName: '',
  userMostRecentLocations: [],
  hasMoreLocations: false,
  availableRoles: [],
}));
