import { AxiosRequestConfig } from 'axios';
import { BaseApiConfiguration, BaseApiService } from 'assets/core/api';
import { SearchablePaginatedRemoteDatasource } from 'assets/components/data-grid/data-grid-toolkit/datasource/SearchablePaginatedRemoteDatasource';

export interface ISearchableDatasourceService {
  getSearchableDatasource: (
    datasourceUrl: string,
  ) => SearchablePaginatedRemoteDatasource;
}

export class SearchableDatasourceService
  extends BaseApiService
  implements ISearchableDatasourceService
{
  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = false,
    baseConfig?: BaseApiConfiguration,
  ) {
    super(baseUrl, config, enableAuth, baseConfig);
  }

  getSearchableDatasource(datasourceUrl: string) {
    return new SearchablePaginatedRemoteDatasource(
      datasourceUrl,
      this.axiosInstance,
    );
  }
}
