import { Text, View } from 'react-native';
import { getText } from 'assets/localization/localization';
import {
  calculatePatientAge,
  formatDate,
} from '../../../../../../common/datetime-utils';
import { RecipientsRenderersProps } from './types';
import { makeStyles } from 'assets/theme';
import { FC } from 'react';

export const DOBRenderer: FC<RecipientsRenderersProps> = ({ data }) => {
  if (!data) {
    return <></>;
  }

  const { date_of_birth, gender } = data ?? {};
  const age = calculatePatientAge(date_of_birth);
  const styles = useStyles();

  if (!date_of_birth && !gender) {
    <View style={styles.container}>
      return <Text style={[styles.dateOfBirth]}>{getText('no-data')}</Text>;
    </View>;
  }

  return (
    <View style={styles.container}>
      <Text style={[styles.dateOfBirth]}>
        {formatDate(date_of_birth)}
        {', '}
        {age} {gender === 'male' ? ' M' : gender === 'female' ? ' F' : ''}
      </Text>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },
  dateOfBirth: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.gray[700],
  },
}));
