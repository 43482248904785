import {
  TaskPriority,
  TaskStatus,
  TaskVisibility,
} from '@digitalpharmacist/tasks-service-client-axios';
import moment from 'moment';

import {
  TrayIcon,
  LockIcon,
  BookmarkIcon,
  AlertCircleIcon,
  CheckCircleIcon,
  RepeatIcon,
  TrashWithStripesIcon,
} from 'assets/icons';
import {
  setCheckboxFilter,
  setTodayCheckboxFilter,
  setCreatedByMeCheckboxFilter,
  setOverdueCheckboxFilter,
} from './tasks-filters-actions';

import { TasksFilterButtonProps } from './TasksFilterButton';
import { TasksFilterCheckboxProps } from './TasksFilterCheckbox';
import { TaskFilters } from './tasks-filters-store';
import { getText } from 'assets/localization/localization';

interface DropdownItem {
  title: string;
  id: string;
}

export const TASKS_BASE_FILTERS: Omit<
  TasksFilterButtonProps,
  'onPress' | 'active'
>[] = [
  {
    id: 'all_tasks',
    name: getText('all-tasks'),
    value: { non_resolved_only: true },
    icon: TrayIcon,
  },
  {
    id: 'personal',
    name: getText('personal'),
    value: { visibility: 'personal' },
    icon: LockIcon,
  },
  {
    id: 'flagged',
    name: getText('flagged'),
    value: { flagged: true, non_resolved_only: true },
    icon: BookmarkIcon,
  },
  {
    id: 'unresolved',
    name: getText('unresolved'),
    value: { status: TaskStatus.Unresolved },
    icon: AlertCircleIcon,
  },
  {
    id: 'resolved',
    name: getText('resolved'),
    value: { status: TaskStatus.Resolved },
    icon: CheckCircleIcon,
  },
  {
    id: 'recurring',
    name: getText('recurring'),
    value: { recurring: true, non_resolved_only: true },
    icon: RepeatIcon,
  },
  {
    id: 'deleted',
    name: getText('trash'),
    value: { deleted_only: true },
    icon: TrashWithStripesIcon,
  },
];

export const TASKS_MULTI_FILTERS: Omit<TasksFilterCheckboxProps, 'active'>[] = [
  {
    name: getText('created-by-me'),
    value: { created_by_user_id: 'userId' },
    field: 'created_by_user_id',
    onPress: setCreatedByMeCheckboxFilter,
  },
  {
    name: getText('high-priority'),
    value: { priority: TaskPriority.High },
    field: 'priority',
    onPress: setCheckboxFilter,
  },
  {
    name: getText('today'),
    value: {
      min_due_date: moment
        .utc(moment().startOf('day'))
        .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      max_due_date: moment
        .utc(moment().endOf('day'))
        .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
    },
    field: 'min_due_date',
    onPress: setTodayCheckboxFilter,
  },
  {
    name: getText('overdue'),
    value: {
      due_date: moment()
        .startOf('minute')
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
    },
    field: 'due_date',
    onPress: setOverdueCheckboxFilter,
  },
];

const taskSpecificFilters = ['unresolved', 'resolved', 'deleted'];

// Sorting out the filter options that are not present on the Grid View
export const GRID_BASE_FILTERS = TASKS_BASE_FILTERS.filter(
  (filter) => !taskSpecificFilters.includes(filter.id),
);

export const GRID_MULTI_FILTERS = TASKS_MULTI_FILTERS.filter(
  (filter) => filter.field !== 'min_due_date',
);

export const DEFAULT_ADVANCED_FILTERS_DROPDOWN_DATA: Record<
  string,
  DropdownItem[]
> = {
  status: [
    { title: getText('all-statuses'), id: 'default' },
    { title: getText('unresolved'), id: TaskStatus.Unresolved },
    { title: getText('resolved'), id: TaskStatus.Resolved },
    { title: getText('in_progress'), id: TaskStatus.InProgress },
    { title: getText('on-hold'), id: TaskStatus.OnHold },
  ],
  priority: [
    { title: getText('all-priorities'), id: 'default' },
    { title: getText('low'), id: TaskPriority.Low },
    { title: getText('medium'), id: TaskPriority.Medium },
    { title: getText('high'), id: TaskPriority.High },
  ],
  visibility: [
    { title: getText('all-visibilities'), id: 'default' },
    { title: getText('team'), id: TaskVisibility.Location },
    { title: getText('personal'), id: TaskVisibility.Personal },
  ],
};

export const initialAdvancedFiltersFormValue: Record<string, any> = {
  task_type_id: { title: getText('all-types'), id: 'default' },
  status: { title: getText('all-statuses'), id: 'default' },
  assigned_user_id: {
    firstName: 'All',
    lastName: 'users',
    id: 'default',
  },
  priority: { title: getText('all-priorities'), id: 'default' },
  created_by_user_id: {
    firstName: 'All',
    lastName: 'users',
    id: 'default',
  },
  visibility: { title: getText('all-visibilities'), id: 'default' },
  due_by: { startDate: null, endDate: null },
};

export const advancedFiltersKeys: Array<keyof TaskFilters> = [
  'task_type_id',
  'status',
  'assigned_user_id',
  'priority',
  'created_by_user_id',
  'min_due_date',
  'max_due_date',
  'visibility',
];

export const getDefaultStaticDropdownData = (
  filters: Record<string, string>,
) => {
  // Using the filters state to prepare the Modal default/selected options
  const defaultStaticOptions = Object.keys(
    DEFAULT_ADVANCED_FILTERS_DROPDOWN_DATA,
  );

  return defaultStaticOptions.reduce<Record<string, DropdownItem[]>>(
    (defaultOptions, key) => {
      defaultOptions[key] =
        DEFAULT_ADVANCED_FILTERS_DROPDOWN_DATA[key].find(
          (f) => f.id === filters[key],
        ) ?? initialAdvancedFiltersFormValue[key];

      return defaultOptions;
    },
    {},
  );
};
