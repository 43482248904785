import { Icon } from 'assets/components/icon';
import * as Clipboard from 'expo-clipboard';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { StyleProp, Text, TextStyle, TouchableOpacity } from 'react-native';
import { CopyToClipboardIcon } from '../../icons/CopyToClipboardIcon';
import { logEvent } from '../../logging/logger';
import { makeStyles } from '../../theme';

export const CopyToClipboard: FunctionComponent<
  PropsWithChildren<CopyToClipboardProps>
> = ({
  stringToCopy,
  logger,
  fetchFromClipboard,
  color,
  size,
  text,
  textStyle,
  style,
}) => {
  const styles = useStyles();

  const copyToClipboard = async () => {
    await Clipboard.setStringAsync(stringToCopy);
    fetchFromClipboard && fetchFromClipboard();
    if (logger) {
      logEvent(logger.id, {
        data: typeof logger.data === 'function' ? logger.data() : logger.data,
      });
    }
  };

  return (
    <TouchableOpacity
      onPress={copyToClipboard}
      style={[styles.viewStyle, style]}
      testID={CopyToClipboardTestIDs.copyToClipboard}
    >
      <Icon icon={CopyToClipboardIcon} color={color} size={size} />
      {text && (
        <Text style={[styles.valueStyle, { color: color }, textStyle]}>
          {text}
        </Text>
      )}
    </TouchableOpacity>
  );
};

export interface CopyToClipboardProps {
  stringToCopy: string;
  logger: { id: string; data?: Object | (() => Object) };
  fetchFromClipboard?: () => void;
  color?: string;
  size?: number;
  text?: string;
  textStyle?: StyleProp<TextStyle>;
  style?: StyleProp<TextStyle>;
}

export const CopyToClipboardTestIDs = {
  copyToClipboard: 'copy-to-clipboard',
};

const useStyles = makeStyles((theme) => ({
  viewStyle: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  valueStyle: {
    ...theme.lumistryFonts.text.small.regular,
    paddingLeft: theme.getSpacing(0.5),
  },
}));
