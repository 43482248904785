import {
  NavigationProp,
  useNavigation,
  useNavigationState,
} from '@react-navigation/native';
import {
  ClipboardIcon,
  HeartIcon,
  MedicationIcon,
  YoutubeIcon,
} from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import {
  VideoDetailParamsProp,
  VideosDrawerNavigationParamList,
} from '../../layout/VideosDrawer';
import { useVideosState } from '../../store/videos-store';
import VideosFilterButton from './VideosFilterButton';

type VideoFilterButton = {
  video_type: number;
  name: string;
  icon: React.FunctionComponent;
};

const CategoryFilter: React.FC = () => {
  const styles = useStyles();
  const navigation =
    useNavigation<NavigationProp<VideosDrawerNavigationParamList>>();
  const navigationState = useNavigationState((state) => state);
  const focusedRouteName = navigationState.routes[navigationState.index];

  const { category, setCategory } = useVideosState();
  const VideosFilterButtonList: VideoFilterButton[] = [
    { video_type: 0, icon: YoutubeIcon, name: getText('videos-all') },
    { video_type: 1, icon: MedicationIcon, name: getText('videos-medication') },
    {
      video_type: 3,
      icon: ClipboardIcon,
      name: getText('videos-administration'),
    },
    { video_type: 2, icon: HeartIcon, name: getText('videos-general-health') },
  ];

  const handleCharSelection = (video_type: number) => {
    setCategory(video_type);
    const focusedRouteMap: VideoDetailParamsProp | undefined =
      focusedRouteName.state?.routes[focusedRouteName.state.index || 0]?.params;
    if (focusedRouteMap?.collection_page) {
      navigation.navigate(focusedRouteMap.collection_page);
    }
  };

  return (
    <View style={styles.sidebar}>
      {VideosFilterButtonList.map((button) => (
        <TouchableOpacity
          key={button.video_type}
          onPress={() => handleCharSelection(button.video_type)}
        >
          <VideosFilterButton
            icon={button.icon}
            name={button.name}
            active={button.video_type == category}
          />
        </TouchableOpacity>
      ))}
    </View>
  );
};

export default CategoryFilter;

const useStyles = makeStyles((theme) => ({
  sidebar: {
    backgroundColor: theme.palette.gray[50],
    margin: 0,
  },
  avatar: {},
}));
