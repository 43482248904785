import moment from 'moment-timezone';
import { DirectMessageConversationFiltersDto } from '@digitalpharmacist/unified-communications-service-client-axios';
import { useUserState } from '../../../store/user-store';
import { useConversationsManipulationState } from '../stores/data-manipulation-store/data-manipulation-store';
import { BaseFilterValues, MultiFilterValues } from 'assets/types/messageTypes';

export function useFilterCriteria(): DirectMessageConversationFiltersDto {
  const baseFilter = useConversationsManipulationState(
    (state) => state.baseFilter,
  );
  const multiFilters = useConversationsManipulationState(
    (state) => state.multiFilters,
  );
  const textFilters = useConversationsManipulationState(
    (state) => state.textFilters,
  );
  const pagination = useConversationsManipulationState(
    (state) => state.pagination,
  );
  const sorting = useConversationsManipulationState((state) => state.sorting);
  const user = useUserState((state) => state.data);

  const is_most_recent_message_less_than_one_day = multiFilters.includes(
    MultiFilterValues.TODAY,
  );
  const is_most_recent_message_less_than_seven_days = multiFilters.includes(
    MultiFilterValues.LAST_SEVEN_DAYS,
  );

  const is_date_filter =
    is_most_recent_message_less_than_one_day ||
    is_most_recent_message_less_than_seven_days;

  const timezone = is_date_filter ? moment.tz.guess() : undefined;

  return {
    unread_by_pharmacy: baseFilter === BaseFilterValues.UNREAD,
    unread_by_patient: baseFilter === BaseFilterValues.UNREAD_BY_PATIENT,
    includes_current_pharmacy_user: multiFilters.includes(
      MultiFilterValues.INCLUDES_ME,
    )
      ? user?.id
      : undefined,
    created_by_current_pharmacy_user: multiFilters.includes(
      MultiFilterValues.CREATED_BY_ME,
    )
      ? user?.id
      : undefined,
    is_most_recent_message_less_than_one_day,
    is_most_recent_message_less_than_seven_days,
    timezone,
    full_name: textFilters.full_name,
    date_of_birth: textFilters.date_of_birth,
    limit: pagination.limit,
    sort: sorting.apiValue,
  };
}
