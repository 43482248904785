import * as React from 'react';
import Svg, { Path, Mask, SvgProps } from 'react-native-svg';

const NoRefillsImage = () => (
  <Svg width="331" height="297" viewBox="0 0 331 297" fill="none">
    <Path
      d="M27.0452 118.591C19.9273 118.591 13.1009 115.765 8.06745 110.732C3.03451 105.696 0.206299 98.8701 0.206299 91.7526C0.206299 84.6351 3.03451 77.8087 8.06745 72.7734L68.228 12.6152C73.2617 7.58227 80.0879 4.75358 87.2058 4.75358C94.324 4.75358 101.151 7.58227 106.184 12.6152C111.217 17.6482 114.045 24.4745 114.045 31.592C114.045 38.7095 111.217 45.5383 106.184 50.5712L46.0239 110.732C40.9903 115.765 34.1639 118.591 27.0452 118.591Z"
      fill="#EAF1F4"
    />
    <Path
      d="M48.3421 172.478C41.2241 172.478 34.3978 169.651 29.3643 164.617C24.3306 159.584 21.5032 152.757 21.5032 145.639C21.5032 138.521 24.3306 131.693 29.3643 126.662L148.165 7.86185C153.198 2.82817 160.024 0 167.141 0C174.262 0 181.087 2.82817 186.121 7.86185C191.155 12.8931 193.981 19.7212 193.981 26.8391C193.981 33.9571 191.155 40.7828 186.121 45.8164L67.3199 164.617C62.2871 169.651 55.4601 172.478 48.3421 172.478Z"
      fill="#EAF1F4"
    />
    <Path
      d="M56.7981 239.205C49.68 239.205 42.8532 236.377 37.8203 231.344C32.7866 226.31 29.9592 219.484 29.9592 212.365C29.9592 205.247 32.7866 198.421 37.8203 193.388L214.891 16.3161C219.924 11.2854 226.75 8.45621 233.868 8.45621C240.986 8.45621 247.812 11.2854 252.847 16.3161C257.88 21.3515 260.707 28.177 260.707 35.2953C260.707 42.4137 257.88 49.2391 252.847 54.2745L75.7759 231.344C70.7431 236.377 63.916 239.205 56.7981 239.205Z"
      fill="#EAF1F4"
    />
    <Path
      d="M74.491 296.694C67.3731 296.694 60.5466 293.867 55.5131 288.833C50.4796 283.8 47.6521 276.974 47.6521 269.855C47.6521 262.737 50.4796 255.911 55.5131 250.878L246.331 60.061C251.363 55.0266 258.19 52.1998 265.309 52.1998C272.428 52.1998 279.252 55.0266 284.287 60.061C289.319 65.0931 292.148 71.9201 292.148 79.0387C292.148 86.155 289.319 92.982 284.287 98.0164L93.4689 288.833C88.4359 293.867 81.6089 296.694 74.491 296.694Z"
      fill="#EAF1F4"
    />
    <Path
      d="M151.861 294.507C144.743 294.507 137.917 291.679 132.883 286.646C127.85 281.613 125.022 274.786 125.022 267.668C125.022 260.55 127.85 253.724 132.883 248.69L270.194 111.381C275.227 106.347 282.052 103.52 289.172 103.52C296.289 103.52 303.117 106.347 308.15 111.381C313.183 116.414 316.01 123.239 316.01 130.359C316.01 137.476 313.183 144.304 308.15 149.336L170.839 286.646C165.806 291.679 158.98 294.507 151.861 294.507Z"
      fill="#EAF1F4"
    />
    <Path
      d="M243.794 277.757C236.675 277.757 229.849 274.929 224.816 269.896C219.782 264.862 216.955 258.036 216.955 250.918C216.955 243.799 219.782 236.973 224.816 231.94L284.977 171.78C290.01 166.746 296.837 163.919 303.954 163.919C311.073 163.919 317.899 166.746 322.932 171.78C327.966 176.813 330.793 183.64 330.793 190.758C330.793 197.876 327.966 204.702 322.932 209.736L262.771 269.896C257.738 274.929 250.911 277.757 243.794 277.757Z"
      fill="#EAF1F4"
    />
    <Path
      d="M39.1241 71.9558L40.9811 70.0989C41.7064 69.373 41.7064 68.1966 40.9811 67.4708C40.2552 66.7449 39.0785 66.7449 38.3524 67.4708L36.4955 69.3277L34.6385 67.4708C33.9124 66.7449 32.7357 66.7449 32.0104 67.4708C31.2843 68.1966 31.2843 69.373 32.0104 70.0989L33.8666 71.9558L32.0104 73.8128C31.2843 74.5387 31.2843 75.715 32.0104 76.4409C32.3729 76.8048 32.8484 76.9858 33.3244 76.9858C33.7997 76.9858 34.2757 76.8048 34.6385 76.4409L36.4955 74.5839L38.3524 76.4409C38.7152 76.8048 39.191 76.9858 39.6665 76.9858C40.1425 76.9858 40.6178 76.8048 40.9811 76.4409C41.7064 75.715 41.7064 74.5387 40.9811 73.8128L39.1241 71.9558Z"
      fill="#FF6A6A"
    />
    <Path
      d="M240.562 21.004L242.42 19.146C243.145 18.421 243.145 17.2447 242.42 16.5175C241.695 15.7925 240.516 15.7925 239.791 16.5175L237.936 18.3755L236.078 16.5175C235.353 15.7925 234.174 15.7925 233.449 16.5175C232.722 17.2447 232.722 18.421 233.449 19.146L235.307 21.004L233.449 22.8597C232.722 23.5869 232.722 24.7633 233.449 25.4882C233.812 25.853 234.288 26.0331 234.762 26.0331C235.239 26.0331 235.715 25.853 236.078 25.4882L237.936 23.6325L239.791 25.4882C240.154 25.853 240.63 26.0331 241.107 26.0331C241.581 26.0331 242.057 25.853 242.42 25.4882C243.145 24.7633 243.145 23.5869 242.42 22.8597L240.562 21.004Z"
      fill="#FF6A6A"
    />
    <Path
      d="M312.759 189.499L314.617 187.642C315.343 186.916 315.343 185.739 314.617 185.013C313.89 184.287 312.714 184.287 311.988 185.013L310.132 186.87L308.274 185.013C307.548 184.287 306.372 184.287 305.647 185.013C304.919 185.739 304.919 186.916 305.647 187.642L307.503 189.499L305.647 191.356C304.919 192.082 304.919 193.258 305.647 193.984C306.009 194.348 306.484 194.529 306.959 194.529C307.436 194.529 307.911 194.348 308.274 193.984L310.132 192.127L311.988 193.984C312.351 194.348 312.826 194.529 313.303 194.529C313.778 194.529 314.255 194.348 314.617 193.984C315.343 193.258 315.343 192.082 314.617 191.356L312.759 189.499Z"
      fill="#FF6A6A"
    />
    <Path
      d="M86.1349 285.153L87.9919 283.296C88.7169 282.57 88.7169 281.394 87.9919 280.668C87.2656 279.942 86.0883 279.942 85.3632 280.668L83.5063 282.525L81.6494 280.668C80.9231 279.942 79.7458 279.942 79.0208 280.668C78.2945 281.394 78.2945 282.57 79.0208 283.296L80.8765 285.153L79.0208 287.01C78.2945 287.736 78.2945 288.913 79.0208 289.639C79.3833 290.002 79.8595 290.183 80.3345 290.183C80.8107 290.183 81.2857 290.002 81.6494 289.639L83.5063 287.782L85.3632 289.639C85.7258 290.002 86.2019 290.183 86.6769 290.183C87.1531 290.183 87.6281 290.002 87.9919 289.639C88.7169 288.913 88.7169 287.736 87.9919 287.01L86.1349 285.153Z"
      fill="#FF6A6A"
    />
    <Path
      d="M124.494 10.0326C123.551 10.0326 122.782 10.7994 122.782 11.7439C122.782 12.6884 123.551 13.4576 124.494 13.4576C125.438 13.4576 126.207 12.6884 126.207 11.7439C126.207 10.7994 125.438 10.0326 124.494 10.0326ZM124.494 17.1748C121.5 17.1748 119.065 14.7387 119.065 11.7439C119.065 8.75145 121.5 6.31538 124.494 6.31538C127.488 6.31538 129.923 8.75145 129.923 11.7439C129.923 14.7387 127.488 17.1748 124.494 17.1748Z"
      fill="#6AC0CC"
    />
    <Path
      d="M21.5029 198.188C20.559 198.188 19.7908 198.956 19.7908 199.9C19.7908 200.844 20.559 201.612 21.5029 201.612C22.4468 201.612 23.2151 200.844 23.2151 199.9C23.2151 198.956 22.4468 198.188 21.5029 198.188ZM21.5029 205.329C18.5095 205.329 16.0732 202.894 16.0732 199.9C16.0732 196.906 18.5095 194.47 21.5029 194.47C24.4964 194.47 26.9325 196.906 26.9325 199.9C26.9325 202.894 24.4964 205.329 21.5029 205.329Z"
      fill="#6AC0CC"
    />
    <Path
      d="M255.278 265.254C254.335 265.254 253.568 266.022 253.568 266.966C253.568 267.91 254.335 268.678 255.278 268.678C256.223 268.678 256.99 267.91 256.99 266.966C256.99 266.022 256.223 265.254 255.278 265.254ZM255.278 272.395C252.284 272.395 249.85 269.96 249.85 266.966C249.85 263.972 252.284 261.537 255.278 261.537C258.272 261.537 260.708 263.972 260.708 266.966C260.708 269.96 258.272 272.395 255.278 272.395Z"
      fill="#6AC0CC"
    />
    <Path
      d="M302.331 93.5807C301.386 93.5807 300.618 94.3503 300.618 95.2931C300.618 96.2381 301.386 97.0054 302.331 97.0054C303.274 97.0054 304.043 96.2381 304.043 95.2931C304.043 94.3503 303.274 93.5807 302.331 93.5807ZM302.331 100.723C299.337 100.723 296.901 98.2873 296.901 95.2931C296.901 92.3011 299.337 89.8636 302.331 89.8636C305.325 89.8636 307.76 92.3011 307.76 95.2931C307.76 98.2873 305.325 100.723 302.331 100.723Z"
      fill="#6AC0CC"
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M113.094 116.296V216.794C113.094 224.485 118.187 232.193 128.339 238.061C138.49 243.931 151.774 246.865 165.063 246.865C178.354 246.865 191.689 243.927 201.833 238.053C211.978 232.18 217.035 224.485 217.035 216.794V116.318C214.4 119.101 211.047 121.715 206.974 124.072C200.405 127.878 192.662 130.602 184.422 132.241C181.46 132.834 178.434 133.286 175.375 133.597C171.964 133.943 168.513 134.116 165.064 134.116C161.613 134.116 158.163 133.943 154.754 133.596C151.694 133.284 148.668 132.832 145.706 132.241C142.986 131.697 140.32 131.037 137.734 130.257C135.318 129.528 132.969 128.696 130.709 127.762C128.569 126.879 126.509 125.902 124.544 124.834C124.092 124.588 123.646 124.337 123.205 124.081C121.78 123.259 120.443 122.405 119.193 121.522C117.525 120.343 116.014 119.116 114.659 117.847C114.111 117.338 113.589 116.819 113.094 116.296Z"
      fill="#5CC4FF"
    />
    <Path
      d="M165.063 248.492C150.92 248.492 137.591 245.288 127.529 239.471C117.174 233.484 111.472 225.43 111.472 216.795V116.296C111.472 115.398 112.197 114.669 113.094 114.669C113.989 114.669 114.715 115.398 114.715 116.296V216.795C114.715 224.218 119.841 231.271 129.149 236.652C138.725 242.188 151.48 245.239 165.063 245.239C178.68 245.239 191.45 242.187 201.022 236.644C210.303 231.271 215.414 224.222 215.414 216.795V116.318C215.414 115.42 216.139 114.692 217.035 114.692C217.93 114.692 218.657 115.42 218.657 116.318V216.795C218.657 225.434 212.969 233.483 202.643 239.461C192.587 245.284 179.241 248.492 165.063 248.492Z"
      fill="#0063B2"
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M206.991 58.7165C218.562 65.4114 224.301 74.1905 224.301 82.9556V99.8437C224.301 108.608 218.537 117.382 206.974 124.072C195.411 130.771 180.213 134.116 165.065 134.116C149.915 134.116 134.767 130.771 123.205 124.082C111.626 117.392 105.828 108.608 105.828 99.8437V82.9556C105.828 74.1905 111.626 65.4158 123.205 58.7165C134.767 52.0259 149.915 48.6719 165.065 48.6719C180.213 48.6719 195.42 52.0215 206.991 58.7165Z"
      fill="#FFC854"
    />
    <Path
      d="M165.065 50.2985C149.545 50.2985 134.967 53.788 124.016 60.1251C113.333 66.3061 107.449 74.4139 107.449 82.9556V99.8437C107.449 108.394 113.332 116.501 124.015 122.673C134.957 129.004 149.535 132.49 165.065 132.49C180.63 132.49 195.227 129 206.164 122.663C216.814 116.501 222.679 108.397 222.679 99.8437V82.9556C222.679 74.389 216.821 66.2811 206.181 60.1251C195.228 53.788 180.626 50.2985 165.065 50.2985ZM165.065 135.743C148.976 135.743 133.822 132.102 122.394 125.491C110.666 118.715 104.206 109.606 104.206 99.8437V82.9556C104.206 73.2027 110.666 64.0939 122.394 57.3079C133.832 50.6899 148.986 47.0453 165.065 47.0453C181.186 47.0453 196.364 50.6899 207.801 57.3079C219.487 64.069 225.922 73.1778 225.922 82.9556V99.8437C225.922 109.608 219.481 118.714 207.785 125.482C196.364 132.098 181.191 135.743 165.065 135.743Z"
      fill="#0063B2"
    />
    <Path
      d="M107.456 83.4608C107.604 88.7319 110.053 93.9543 114.564 98.5954C117.107 101.212 120.286 103.631 124.015 105.784C134.957 112.115 149.535 115.603 165.065 115.603C180.63 115.603 195.227 112.112 206.164 105.776C209.876 103.627 213.041 101.217 215.571 98.6117C220.075 93.9749 222.523 88.7504 222.673 83.4705C221.797 87.6584 219.522 91.7172 215.963 95.3813C213.252 98.1725 209.814 100.726 205.742 102.97C194.865 108.975 180.42 112.282 165.065 112.282C149.747 112.282 135.317 108.978 124.434 102.98C120.355 100.734 116.906 98.1768 114.187 95.3813C110.616 91.7107 108.332 87.6497 107.456 83.4608ZM165.065 118.856C148.975 118.856 133.822 115.215 122.394 108.603C118.41 106.301 114.993 103.697 112.241 100.865C106.985 95.4561 104.206 89.2633 104.206 82.9555C104.206 73.2026 110.666 64.0938 122.394 57.3078C122.754 57.0996 123.119 56.8935 123.487 56.6908C124.268 56.2592 125.245 56.5411 125.682 57.3175C126.117 58.095 125.848 59.0796 125.077 59.5253C115.543 65.0339 110.293 72.1518 110.293 79.5679C110.293 84.248 112.441 88.9304 116.506 93.1085C119.003 95.6752 122.195 98.0359 125.995 100.128C136.408 105.868 150.283 109.029 165.065 109.029C179.881 109.029 193.774 105.865 204.18 100.12C207.97 98.0305 211.153 95.673 213.642 93.1106C217.694 88.9369 219.837 84.2545 219.837 79.5679C219.837 72.1128 214.586 64.983 205.053 59.4906C204.283 59.046 204.012 58.0614 204.447 57.2828C204.883 56.5064 205.861 56.2245 206.641 56.6539C207.032 56.8675 207.419 57.0855 207.801 57.3078C219.487 64.0689 225.922 73.1776 225.922 82.9555C225.922 89.2763 223.146 95.4746 217.894 100.881C215.156 103.702 211.754 106.296 207.785 108.593C196.364 115.21 181.191 118.856 165.065 118.856Z"
      fill="#0063B2"
    />
    <Path
      d="M105.843 102.207C104.948 102.207 104.221 101.479 104.221 100.581V83.6917C104.221 82.7938 104.948 82.0651 105.843 82.0651C106.739 82.0651 107.464 82.7938 107.464 83.6917V100.581C107.464 101.479 106.739 102.207 105.843 102.207Z"
      fill="#0063B2"
    />
    <Path
      d="M108.239 111.161C107.344 111.161 106.617 110.433 106.617 109.535V92.6465C106.617 91.7475 107.344 91.0199 108.239 91.0199C109.135 91.0199 109.86 91.7475 109.86 92.6465V109.535C109.86 110.433 109.135 111.161 108.239 111.161Z"
      fill="#0063B2"
    />
    <Path
      d="M110.983 115.46C110.086 115.46 109.361 114.732 109.361 113.833V96.9462C109.361 96.0473 110.086 95.3197 110.983 95.3197C111.878 95.3197 112.604 96.0473 112.604 96.9462V113.833C112.604 114.732 111.878 115.46 110.983 115.46Z"
      fill="#0063B2"
    />
    <Path
      d="M114.659 119.474C113.762 119.474 113.037 118.747 113.037 117.848V100.961C113.037 100.062 113.762 99.3341 114.659 99.3341C115.554 99.3341 116.28 100.062 116.28 100.961V117.848C116.28 118.747 115.554 119.474 114.659 119.474Z"
      fill="#0063B2"
    />
    <Path
      d="M119.194 123.149C118.298 123.149 117.573 122.421 117.573 121.522V104.634C117.573 103.737 118.298 103.008 119.194 103.008C120.089 103.008 120.816 103.737 120.816 104.634V121.522C120.816 122.421 120.089 123.149 119.194 123.149Z"
      fill="#0063B2"
    />
    <Path
      d="M124.545 126.46C123.65 126.46 122.924 125.732 122.924 124.833V107.945C122.924 107.047 123.65 106.319 124.545 106.319C125.441 106.319 126.167 107.047 126.167 107.945V124.833C126.167 125.732 125.441 126.46 124.545 126.46Z"
      fill="#0063B2"
    />
    <Path
      d="M130.709 129.388C129.814 129.388 129.088 128.66 129.088 127.762V110.874C129.088 109.975 129.814 109.247 130.709 109.247C131.605 109.247 132.33 109.975 132.33 110.874V127.762C132.33 128.66 131.605 129.388 130.709 129.388Z"
      fill="#0063B2"
    />
    <Path
      d="M137.734 131.884C136.839 131.884 136.113 131.156 136.113 130.257V113.369C136.113 112.47 136.839 111.742 137.734 111.742C138.63 111.742 139.355 112.47 139.355 113.369V130.257C139.355 131.156 138.63 131.884 137.734 131.884Z"
      fill="#0063B2"
    />
    <Path
      d="M145.706 133.868C144.811 133.868 144.084 133.14 144.084 132.241V115.353C144.084 114.455 144.811 113.727 145.706 113.727C146.602 113.727 147.327 114.455 147.327 115.353V132.241C147.327 133.14 146.602 133.868 145.706 133.868Z"
      fill="#0063B2"
    />
    <Path
      d="M154.755 135.222C153.859 135.222 153.134 134.495 153.134 133.596V116.708C153.134 115.81 153.859 115.081 154.755 115.081C155.65 115.081 156.377 115.81 156.377 116.708V133.596C156.377 134.495 155.65 135.222 154.755 135.222Z"
      fill="#0063B2"
    />
    <Path
      d="M165.065 135.745C164.169 135.745 163.443 135.016 163.443 134.118V117.23C163.443 116.331 164.169 115.604 165.065 115.604C165.96 115.604 166.686 116.331 166.686 117.23V134.118C166.686 135.016 165.96 135.745 165.065 135.745Z"
      fill="#0063B2"
    />
    <Path
      d="M221.889 111.161C220.994 111.161 220.268 110.433 220.268 109.535V92.6465C220.268 91.7475 220.994 91.0199 221.889 91.0199C222.786 91.0199 223.511 91.7475 223.511 92.6465V109.535C223.511 110.433 222.786 111.161 221.889 111.161Z"
      fill="#0063B2"
    />
    <Path
      d="M219.146 115.46C218.251 115.46 217.524 114.732 217.524 113.833V96.9462C217.524 96.0473 218.251 95.3197 219.146 95.3197C220.042 95.3197 220.767 96.0473 220.767 96.9462V113.833C220.767 114.732 220.042 115.46 219.146 115.46Z"
      fill="#0063B2"
    />
    <Path
      d="M215.47 119.474C214.574 119.474 213.849 118.747 213.849 117.848V100.961C213.849 100.062 214.574 99.3341 215.47 99.3341C216.366 99.3341 217.092 100.062 217.092 100.961V117.848C217.092 118.747 216.366 119.474 215.47 119.474Z"
      fill="#0063B2"
    />
    <Path
      d="M210.936 123.149C210.04 123.149 209.314 122.421 209.314 121.522V104.634C209.314 103.737 210.04 103.008 210.936 103.008C211.831 103.008 212.557 103.737 212.557 104.634V121.522C212.557 122.421 211.831 123.149 210.936 123.149Z"
      fill="#0063B2"
    />
    <Path
      d="M205.584 126.46C204.688 126.46 203.963 125.732 203.963 124.833V107.945C203.963 107.047 204.688 106.319 205.584 106.319C206.48 106.319 207.205 107.047 207.205 107.945V124.833C207.205 125.732 206.48 126.46 205.584 126.46Z"
      fill="#0063B2"
    />
    <Path
      d="M199.419 129.388C198.524 129.388 197.798 128.66 197.798 127.762V110.874C197.798 109.975 198.524 109.247 199.419 109.247C200.315 109.247 201.041 109.975 201.041 110.874V127.762C201.041 128.66 200.315 129.388 199.419 129.388Z"
      fill="#0063B2"
    />
    <Path
      d="M192.394 131.884C191.499 131.884 190.772 131.156 190.772 130.257V113.369C190.772 112.47 191.499 111.742 192.394 111.742C193.29 111.742 194.015 112.47 194.015 113.369V130.257C194.015 131.156 193.29 131.884 192.394 131.884Z"
      fill="#0063B2"
    />
    <Path
      d="M184.422 133.868C183.526 133.868 182.801 133.14 182.801 132.241V115.353C182.801 114.455 183.526 113.727 184.422 113.727C185.317 113.727 186.044 114.455 186.044 115.353V132.241C186.044 133.14 185.317 133.868 184.422 133.868Z"
      fill="#0063B2"
    />
    <Path
      d="M175.374 135.222C174.478 135.222 173.753 134.495 173.753 133.596V116.708C173.753 115.81 174.478 115.081 175.374 115.081C176.269 115.081 176.996 115.81 176.996 116.708V133.596C176.996 134.495 176.269 135.222 175.374 135.222Z"
      fill="#0063B2"
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M165.065 146.964C152.478 146.964 139.892 144.332 129.978 139.069C128.778 138.431 127.44 138.469 126.278 139.172C125.115 139.873 124.457 141.042 124.457 142.403V218.427C124.457 221.19 125.817 223.563 128.2 224.951C138.928 231.249 152.748 233.836 165.065 233.836C178.356 233.836 191.645 230.899 201.79 225.032C204.257 223.595 205.673 221.318 205.673 218.427V142.403C205.673 141.042 205.013 139.873 203.851 139.172C202.688 138.469 201.35 138.431 200.151 139.069C190.237 144.332 177.651 146.964 165.065 146.964Z"
      fill="white"
    />
    <Path
      d="M128.213 140.243C127.838 140.243 127.467 140.35 127.113 140.565C126.436 140.974 126.078 141.611 126.078 142.403V218.427C126.078 220.625 127.121 222.442 129.014 223.545C138.396 229.053 151.534 232.209 165.064 232.209C178.651 232.209 191.406 229.16 200.98 223.622C203.015 222.437 204.051 220.688 204.051 218.427V142.403C204.051 141.611 203.692 140.974 203.014 140.565C202.343 140.157 201.602 140.138 200.91 140.506C191.089 145.719 178.359 148.591 165.064 148.591C151.769 148.591 139.038 145.719 129.219 140.506C128.89 140.331 128.552 140.243 128.213 140.243ZM165.064 235.462C156.784 235.462 140.88 234.28 127.38 226.356C124.493 224.673 122.835 221.782 122.835 218.427V142.403C122.835 140.463 123.785 138.778 125.441 137.777C127.072 136.794 129.055 136.738 130.736 137.631C140.097 142.601 152.288 145.338 165.064 145.338C177.84 145.338 190.032 142.601 199.392 137.631C201.073 136.738 203.058 136.794 204.687 137.777C206.344 138.778 207.294 140.464 207.294 142.403V218.427C207.294 221.832 205.628 224.677 202.605 226.438C192.541 232.258 179.211 235.462 165.064 235.462Z"
      fill="#0063B2"
    />
    <Path
      d="M138.65 168.588C141.476 169.571 143.932 170.361 146.397 170.97C148.14 171.4 149.804 171.087 151.078 170.087C152.474 168.99 153.274 167.17 153.274 165.094C153.265 160.751 149.899 156.472 145.612 155.356C143.27 154.748 140.972 153.969 138.659 153.13C138.656 155.614 138.655 158.102 138.655 160.587L138.652 166.901C138.652 167.463 138.65 168.026 138.65 168.588ZM137.007 184.598H137.004C136.109 184.596 135.385 183.866 135.386 182.969C135.395 178.614 135.401 174.257 135.405 169.909C135.394 169.797 135.394 169.681 135.407 169.566C135.408 168.677 135.408 167.787 135.409 166.898L135.412 160.587C135.412 157.322 135.413 154.057 135.42 150.797C135.421 150.265 135.681 149.767 136.117 149.464C136.553 149.159 137.109 149.09 137.606 149.276C140.583 150.386 143.497 151.446 146.426 152.207C152.072 153.676 156.503 159.336 156.516 165.09C156.516 168.177 155.264 170.93 153.078 172.647C151.002 174.277 148.354 174.802 145.62 174.129C143.357 173.569 141.125 172.873 138.646 172.026C138.642 175.673 138.636 179.324 138.629 182.975C138.627 183.873 137.901 184.598 137.007 184.598Z"
      fill="#0063B2"
    />
    <Path
      d="M164.623 197.89C164.169 197.89 163.718 197.699 163.397 197.328C156.916 189.823 150.293 181.737 143.71 173.293C143.158 172.585 143.283 171.563 143.988 171.009C144.692 170.456 145.711 170.581 146.264 171.289C152.813 179.691 159.402 187.734 165.848 195.198C166.434 195.876 166.361 196.904 165.684 197.493C165.377 197.76 165 197.89 164.623 197.89Z"
      fill="#0063B2"
    />
    <Path
      d="M149.05 195.652C148.595 195.652 148.144 195.462 147.823 195.089C147.238 194.408 147.313 193.382 147.991 192.794C153.273 188.22 158.471 183.471 163.442 178.675C164.085 178.051 165.113 178.071 165.734 178.719C166.354 179.366 166.335 180.397 165.69 181.019C160.677 185.855 155.436 190.645 150.11 195.257C149.803 195.521 149.426 195.652 149.05 195.652Z"
      fill="#0063B2"
    />
    <Path
      d="M165.065 225.796C154.376 225.796 143.678 223.869 134.94 220.369C134.109 220.037 133.703 219.09 134.036 218.257C134.367 217.422 135.309 217.014 136.143 217.349C144.507 220.699 154.778 222.543 165.065 222.543C175.484 222.543 185.485 220.743 193.986 217.337C194.817 217.001 195.76 217.409 196.093 218.242C196.425 219.077 196.02 220.024 195.189 220.357C186.309 223.916 175.893 225.796 165.065 225.796Z"
      fill="#0063B2"
    />
    <Path
      d="M165.065 216.986C154.376 216.986 143.678 215.059 134.94 211.558C134.109 211.226 133.703 210.28 134.036 209.446C134.367 208.611 135.309 208.204 136.143 208.538C144.507 211.888 154.778 213.732 165.065 213.732C175.484 213.732 185.485 211.932 193.986 208.526C194.817 208.19 195.76 208.598 196.093 209.432C196.425 210.267 196.02 211.213 195.189 211.546C186.309 215.105 175.893 216.986 165.065 216.986Z"
      fill="#0063B2"
    />
    <Path
      d="M165.065 208.176C154.376 208.176 143.677 206.249 134.94 202.748C134.109 202.416 133.703 201.469 134.036 200.634C134.367 199.8 135.309 199.391 136.143 199.728C144.507 203.078 154.778 204.923 165.065 204.923C175.484 204.923 185.485 203.123 193.986 199.716C194.817 199.38 195.76 199.788 196.093 200.622C196.425 201.457 196.02 202.404 195.189 202.737C186.309 206.296 175.893 208.176 165.065 208.176Z"
      fill="#0063B2"
    />
  </Svg>
);

export default NoRefillsImage;
