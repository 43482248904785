import { Icon } from 'assets/components/icon';
import { Text } from 'assets/components/text';
import { AlertTriangleIcon } from 'assets/icons';
import { makeStyles, useTheme } from 'assets/theme';
import { getNestedObjectValue } from 'assets/utils/common';
import { FunctionComponent } from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { View } from 'react-native';
import { PatientAgreementRadioButtonProps } from '../../types';
import { PatientAgreementRadioButton } from './PatientAgreementRadioButton';

export const PatientAgreementRadioButtonField: FunctionComponent<
  PatientAgreementRadioButtonFieldProps
> = ({
  name,
  rules,
  label,
  hideRequiredAsterisk,
  ...patientAgreementRadioButtonProps
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const formContext = useFormContext();
  if (!formContext) {
    throw new Error(
      'PatientAgreementRadioButtonField must have a parent form context',
    );
  }

  const { control, formState } = formContext;
  const error = getNestedObjectValue(formState.errors, name);

  return (
    <>
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <PatientAgreementRadioButton
            {...patientAgreementRadioButtonProps}
            onValueChange={(e) => {
              onChange(e);
            }}
            selectedValue={value}
            label={
              label && rules?.required && !hideRequiredAsterisk
                ? `${label} *`
                : label
            }
          />
        )}
        name={name}
        rules={rules}
      />
      {!!error && (
        <>
          <View style={styles.container}>
            <View style={styles.icon}>
              <Icon
                icon={AlertTriangleIcon}
                color={theme.palette.error[600]}
                size={16}
              />
            </View>
            <Text style={styles.errorMessage}>{error.message}</Text>
          </View>
        </>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.error[600],
  },
  icon: {
    marginRight: theme.getSpacing(0.5),
  },
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.getSpacing(1),
    marginBottom: theme.getSpacing(1),
  },
}));

export interface PatientAgreementRadioButtonFieldProps
  extends Omit<PatientAgreementRadioButtonProps, 'onValueChange'> {
  name: string;
  rules?: RegisterOptions;
  label?: string;
  hideRequiredAsterisk?: boolean;
}
