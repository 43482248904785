import { logError } from 'assets/logging/logger';
import TaskService from '../../api/TaskService';
import { useToast } from '../../common/hooks/useToast';
import { useAutomationsState } from './automations-store';
import { getText } from 'assets/localization/localization';
import {
  AutomationDto,
  AutomationType,
} from '@digitalpharmacist/tasks-service-client-axios';

const errorOccurred = (error: Error, errorMessage?: string) => {
  const { toast } = useToast();
  const message = errorMessage
    ? errorMessage
    : getText('get-automated-tasks-error');

  logError(error);
  useAutomationsState.setState({
    status: 'error',
  });

  toast('Error', { type: 'error', content: message });
};

export const getAutomations = async () => {
  useAutomationsState.setState({
    status: 'loading',
  });

  try {
    const response = await TaskService.getAutomations();
    const automationsData: AutomationDto[] = response;
    const automations: Record<string, AutomationDto> = {};
    automationsData.forEach((automation: AutomationDto) => {
      if (automation.type === AutomationType.FailedRefills) {
        return;
      }
      automations[automation.id] = automation;
    });

    useAutomationsState.setState({
      status: 'success',
      automations: automations,
    });
  } catch (error) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    errorOccurred(error as Error);
  }
};

export const updateAutomation = async (
  id: string,
  updatedFields: Record<string, unknown>,
) => {
  useAutomationsState.setState({
    status: 'loading',
  });

  try {
    const response = await TaskService.updateAutomation(id, updatedFields);
    const updatedAutomation: AutomationDto = response;
    useAutomationsState.setState({
      status: 'success',
      automations: {
        ...useAutomationsState.getState().automations,
        [id]: updatedAutomation,
      },
    });
  } catch (error) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    errorOccurred(error as Error);
  }
};
