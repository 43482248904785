import React, { FunctionComponent, PropsWithChildren, useState } from 'react';
import { Modal } from 'assets/components/modal';
import { Submission } from '../../forms/submission/Submission';
import { getText } from 'assets/localization/localization';
import { DownloadIcon } from 'assets/icons';
import { useViewSubmissionStore } from '../../screens/forms/view-submission-store';
import { useToast } from '../../common/hooks/useToast';
import { downloadFile, getDateTimeFilename } from '../../common/file-utils';
import { getSubmissionPDF } from '../../screens/forms/view-submission-actions';
import { View } from 'react-native';
import { useTheme } from 'assets/theme';

export const SubmissionModal: FunctionComponent<
  PropsWithChildren<NewFormModalProps>
> = ({ show, handleClose, formId, submissionId }) => {
  const theme = useTheme();
  const { toast } = useToast();
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);

  const submission = useViewSubmissionStore((state) => state.submission);
  const isLoading = useViewSubmissionStore(
    (state) => state.status === 'loading',
  );

  const downloadSubmission = async () => {
    toast(getText('preparing-submission'), {
      type: 'info',
      content: getText('download-will-begin'),
    });
    setIsDownloadLoading(true);
    const data = await getSubmissionPDF(formId, submissionId);

    data &&
      downloadFile(
        data,
        getDateTimeFilename(
          `${submission?.form_name ?? ''} ${submission?.name ?? ''}`,
          'pdf',
        ),
      );

    setIsDownloadLoading(false);
  };

  return (
    <Modal
      title={`${getText('submission')}: ${
        isLoading ? `${getText('loading')}...` : submission?.form_name ?? ''
      }`}
      size="lg"
      okButtonProps={{
        onPress: handleClose,
        logger: { id: 'submissions-modal-close-button-modal' },
        hierarchy: 'primary',
        text: getText('close'),
      }}
      dismissButtonProps={{
        onPress: handleClose,
        logger: { id: 'submissions-modal-dismiss-button-modal' },
      }}
      deleteButtonProps={{
        onPress: () => void downloadSubmission(),
        icon: DownloadIcon,
        logger: { id: 'submissions-modal-close-button-modal' },
        hierarchy: 'secondary-gray',
        text: getText('download-pdf'),
        pinLeft: true,
        loading: isDownloadLoading,
      }}
      show={show}
      contentContainerStyle={{ minHeight: 200 }}
      isScrollable={true}
    >
      <View style={{ paddingRight: theme.getSpacing(1) }}>
        <Submission
          formId={formId}
          submissionId={submissionId}
          withDelete={false}
          isInsideModal={true}
        />
      </View>
    </Modal>
  );
};

interface NewFormModalProps {
  show: boolean;
  handleClose: () => void;
  submissionId: string;
  formId: string;
}

export default SubmissionModal;
