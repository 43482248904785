import { ColDef, ColGroupDef } from '@ag-grid-community/core';
import { getText } from 'assets/localization/localization';
import { DynamicInputValue } from '../common/types/MessageForm';
import { DynamicInputsOption } from './components/BulkFilterInput';
import { RecipientsRenderer } from './components/RecipientsRenderer';
import { SendDateRenderer } from './components/SendDateRenderer';
import { SenderRenderer } from './components/SenderRenderer';
import { StatusRenderer } from './components/StatusRenderer';
import { SubjectRenderer } from './components/SubjectRenderer';
import { RenderSendDateHeader } from './components/RenderSendDateHeader';
import { RecipientsHeaderRenderer } from './components/RecipientsHeaderRenderer';
import { RecipientRenderer } from './components/RecipientRenderer';
import { DateOfBirthRenderer } from './components/DateOfBirthRenderer';
import { getRecipientsListActionRenderer } from './components/RecipientsListActionRenderer';
import { RecipientsListActionHeaderRenderer } from './components/RecipientsListActionHeaderRenderer';
import { LocationPatientRecordSearchDto } from '@digitalpharmacist/patient-service-client-axios';

export const filteringOptions: DynamicInputsOption[] = [
  {
    label: `${getText('age-is-older-than')}...`,
    value: DynamicInputValue.AGE_IS_OLDER_THAN,
    isBooleanValue: false,
  },
  {
    label: `${getText('age-is-younger-than')}...`,
    value: DynamicInputValue.AGE_IS_YOUNGER_THAN,
    isBooleanValue: false,
  },
  {
    label: `${getText('age-is')}...`,
    value: DynamicInputValue.AGE_IS,
    isBooleanValue: false,
  },
  {
    label: `${getText('age-is-not')}...`,
    value: DynamicInputValue.AGE_IS_NOT,
    isBooleanValue: false,
  },
  {
    label: `${getText('gender-is-male')}`,
    value: DynamicInputValue.GENDER_IS_MALE,
    isBooleanValue: true,
  },
  {
    label: `${getText('gender-is-female')}`,
    value: DynamicInputValue.GENDER_IS_FEMALE,
    isBooleanValue: true,
  },
  {
    label: `${getText('zip-code-is-one-of')}...`,
    value: DynamicInputValue.ZIP_CODE_IS_ONE_OF,
    isBooleanValue: false,
  },
  {
    label: `${getText('zip-code-is-not-one-of')}...`,
    value: DynamicInputValue.ZIP_CODE_IS_NOT_ONE_OF,
    isBooleanValue: false,
  },
];

export const bulksTableColumns: Array<ColDef | ColGroupDef> = [
  {
    field: 'author.name',
    headerName: getText('sender'),
    cellRenderer: SenderRenderer,
    sortable: true,
    comparator: (valueA, valueB) => {
      return (valueA ?? '').localeCompare(valueB ?? '');
    },
  },
  {
    field: 'subject',
    headerName: getText('subject'),
    cellRenderer: SubjectRenderer,
    sortable: true,
    comparator: (valueA, valueB) => {
      return (valueA ?? '').localeCompare(valueB ?? '');
    },
  },
  {
    field: 'recipients_number',
    headerComponent: RecipientsHeaderRenderer,
    headerName: getText('recipients'),
    cellRenderer: RecipientsRenderer,
    sortable: true,
  },
  {
    // added to create additional space like on figma
    sortable: false,
    maxWidth: 100,
  },
  {
    field: 'status',
    headerName: getText('status'),
    cellRenderer: StatusRenderer,
    sortable: false,
  },
  {
    field: 'scheduled_time',
    headerComponent: RenderSendDateHeader,
    headerName: getText('send-date'),
    cellRenderer: SendDateRenderer,
    sort: 'desc',
    sortable: true,
  },
];

export const getRecipientsTableColumns = (
  removeHandler: (recipient: LocationPatientRecordSearchDto) => void,
): Array<ColDef | ColGroupDef> => {
  return [
    {
      field: 'first_name',
      headerName: getText('name'),
      cellRenderer: RecipientRenderer,
      sortable: true,
      maxWidth: 200,
      cellStyle: {
        display: 'flex',
        justifyContent: 'flex-start',
      },
      sort: 'asc',
    },
    {
      field: 'date_of_birth',
      headerName: getText('date-of-birth-short'),
      cellRenderer: DateOfBirthRenderer,
      sortable: true,
      cellStyle: {
        display: 'flex',
        justifyContent: 'flex-start',
      },
    },
    {
      field: '',
      headerComponent: RecipientsListActionHeaderRenderer,
      headerName: getText('action'),
      // `any` as it specified in ColDef
      cellRenderer: (props: any) =>
        getRecipientsListActionRenderer({
          data: props.data,
          removeHandler: removeHandler,
        }),
      sortable: false,
      cellStyle: {
        display: 'flex',
        justifyContent: 'flex-end',
      },
    },
  ];
};
