import theme from 'assets/theme';
import { ReactNode } from 'react';
import { GestureResponderEvent, TouchableOpacity, View } from 'react-native';
import { Avatar } from 'react-native-gifted-chat';
import { makeStyles, useTheme } from 'assets/theme';
type OptionSelectContainerType = {
  children?: ReactNode;
  isSelected: boolean;
};

export const OptionSelectContainer = ({
  children,
  isSelected,
}: OptionSelectContainerType) => {
  const styles = useStyles();

  return (
    <View style={[styles.container, isSelected ? styles.selectedUser : {}]}>
      {children}
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'column',
    marginBottom: theme.getSpacing(2),
    borderColor: theme.palette.gray[300],
    borderWidth: 1,
    borderRadius: 7,
    width: '90%',
    paddingVertical: 8,
    paddingHorizontal: 10,
  },
  selectedUser: {
    backgroundColor: theme.palette.primary[50],
    borderColor: theme.palette.primary[500],
  },
}));
