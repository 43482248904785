import { PharmacyUserDto } from '@digitalpharmacist/users-service-client-axios';
import { UserRoleDto } from '@digitalpharmacist/role-service-client-axios';
import { create } from './store-creator';
import { PharmacistLocationDto } from '@digitalpharmacist/pharmacy-service-client-axios';

export interface PharmacyUserExtended extends PharmacyUserDto {
  selectedLocation?: PharmacistLocationDto;
  roles?: Array<UserRoleDto>;
}

export interface UserState {
  data?: PharmacyUserExtended;
  updateData: (data: UserState) => void;
  logout: () => void;
}

export const useUserState = create<UserState>((set) => ({
  data: undefined,
  updateData: (data: UserState) => {
    set({ ...data });
  },
  logout: () => {
    set({ data: undefined });
  },
}));

export const isUserAuthorized = (user: PharmacyUserDto | undefined) => {
  return user?.id &&
    user.email &&
    user.privacyPolicyPromptAcceptanceTimestamp &&
    user.termsOfServicePromptAcceptanceTimestamp
    ? true
    : false;
};

export const isUserItAdmin = (user: PharmacyUserExtended | undefined) => {
  return user?.id &&
    user.email &&
    user.roles?.some((x) => x.role === 'IT_ADMIN')
    ? true
    : false;
};
