import React, { FunctionComponent } from 'react';
import { View } from 'react-native';

import { makeStyles } from 'assets/theme';
import { CalendarAppointments } from '../../../schedule/appointments-calendar/CalendarAppointments';
import { PharmacyScreenContainer } from 'assets/layout';

export const AppointmentsCalendar: FunctionComponent = () => {
  const styles = useStyles();

  return (
    <PharmacyScreenContainer>
      <View style={styles.container}>
        <CalendarAppointments />
      </View>
    </PharmacyScreenContainer>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
  },
}));
