import { FC, useEffect, useState } from 'react';
import {
  LocationPatientRecordDto,
  PatientRecordDto,
} from '@digitalpharmacist/patient-service-client-axios';
import { getText } from 'assets/localization/localization';
import { useAppStateStore } from '../../store/app-store';
import { PharmacyCategory } from '@digitalpharmacist/file-storage-service-client-axios';
import FileStorageService from '../../api/FileStorageService';
import { PhotoInfo } from '../InsuranceDetailsModal/PhotoButton';
import { ImageViewer } from '../ImageViewer';
import { logError } from 'assets/logging/logger';

interface PhotoIDModalProps {
  show: boolean;
  setShowModal: (arg: boolean) => void;
  onSubmit?: (patientRecord: PatientRecordDto) => void;
  locationPatientRecord: LocationPatientRecordDto;
  cardType: PhotoCard;
}

const PhotoIdModal: FC<PhotoIDModalProps> = ({
  show,
  setShowModal,
  locationPatientRecord: locationPatientRecord,
}) => {
  const { pharmacyId } = useAppStateStore();
  const [frontPhoto, setFrontPhoto] = useState<PhotoInfo>();
  function onDismiss(): void {
    setShowModal(false);
  }

  const loadImages = async () => {
    try {
      const frontFilename = locationPatientRecord['photo_id_url'];

      if (frontFilename) {
        const responseReadUrl =
          await FileStorageService.readUrlWithPharmacyCategory(
            PharmacyCategory.PhotoId,
            frontFilename,
            pharmacyId,
          );
        setFrontPhoto({ uri: responseReadUrl.url });
      }
    } catch (error) {
      logError(error as Error);
    }
  };

  useEffect(() => {
    loadImages();
  }, []);

  return (
    <ImageViewer
      frontImageUrl={frontPhoto?.uri ?? undefined}
      title={getText('photo-id-text')}
      show={show}
      okButtonPress={onDismiss}
    ></ImageViewer>
  );
};

export enum PhotoCard {
  PhotoId = 'photo_id',
}

export default PhotoIdModal;
