import UsersService from '../../../api/UsersService';
import { UpdatePharmacyUserRequestDto } from '@digitalpharmacist/users-service-client-axios';

export const updatePharmacyUser = async (
  userId: string,
  userForm: UpdatePharmacyUserRequestDto,
): Promise<UpdatePharmacyUserRequestDto> => {
  return await UsersService.updatePharmacyUser(userId, userForm);
};

export const resendInvite = async (email: string): Promise<void> => {
  return await UsersService.resendPharmacistInviteEmail(email);
};
