import { IconProps } from 'assets/icons/types';
import { useTheme } from 'assets/theme';
import React, { FunctionComponent } from 'react';
import Svg, { Path } from 'react-native-svg';

export const OBEmployeesIcon: FunctionComponent<IconProps> = ({
  color,
  size = 100,
  testID,
}) => {
  const theme = useTheme();
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      testID={testID}
      viewBox="0 0 100 100"
    >
      <Path
        d="M66.667 87.5V79.1667C66.667 74.7464 64.9111 70.5072 61.7854 67.3816C58.6598 64.2559 54.4206 62.5 50.0003 62.5H20.8337C16.4134 62.5 12.1742 64.2559 9.04855 67.3816C5.92294 70.5072 4.16699 74.7464 4.16699 79.1667V87.5M83.3337 33.3333V58.3333M95.8337 45.8333H70.8337M52.0837 29.1667C52.0837 38.3714 44.6217 45.8333 35.417 45.8333C26.2122 45.8333 18.7503 38.3714 18.7503 29.1667C18.7503 19.9619 26.2122 12.5 35.417 12.5C44.6217 12.5 52.0837 19.9619 52.0837 29.1667Z"
        stroke={color ?? theme.colors.brandedPrimary}
        strokeWidth={5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
