import React, { FunctionComponent } from 'react';
import { makeStyles, useTheme } from 'assets/theme';
import { TouchableOpacity, View } from 'react-native';
import { LocationPatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';
import { prettyFormat } from '@digitalpharmacist/validation-dp';
import { Avatar } from 'assets/components/avatar';
import { Text } from 'assets/components/text';
import { getText } from 'assets/localization/localization';
import { Button } from 'assets/components/button';
import { formatDate } from '../../../common/datetime-utils';
import { getFullName } from 'assets/utils/messageUtils';

export const PatientInfo: FunctionComponent<PatientInfoProps> = ({
  selectedPatient,
  openNewChatModal,
  setIsSidebarOpen,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  return (
    <View style={styles.wrapper}>
      <View style={{ flexDirection: 'row' }}>
        <Avatar
          size={40}
          firstName={selectedPatient.first_name}
          lastName={selectedPatient.last_name}
          style={{ backgroundColor: theme.palette.gray[100] }}
        />
        <View style={styles.userInfo}>
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ marginRight: theme.getSpacing(1) }}>
              {getFullName(selectedPatient)}
            </Text>
            <TouchableOpacity
              onPress={() => setIsSidebarOpen((prev: boolean) => !prev)}
            >
              <Text
                style={{
                  marginRight: theme.getSpacing(1),
                  color: theme.colors.brandedPrimary,
                }}
              >
                {getText('details')}
              </Text>
            </TouchableOpacity>
            {/*
              In accordance with Jira issue BLUES-5168, we need to hide functionalities that have not yet been implemented.
              <TouchableOpacity onPress={() => setShowEditModal(false)}>
                <PencilIcon size={16} />
              </TouchableOpacity>
            */}
          </View>
          <View style={{ flexDirection: 'row' }}>
            {selectedPatient.date_of_birth ? (
              <Text
                style={{
                  marginRight: theme.getSpacing(1.5),
                }}
              >
                {formatDate(selectedPatient.date_of_birth)}
              </Text>
            ) : null}
            {selectedPatient.cell_phone ? (
              <Text>{prettyFormat(selectedPatient.cell_phone)}</Text>
            ) : null}
          </View>
        </View>
      </View>
      <Button
        hierarchy="primary"
        size="small"
        logger={{ id: 'new-subject-button' }}
        onPress={() => openNewChatModal()}
      >
        {getText('new-subject')}
      </Button>
    </View>
  );
};

interface PatientInfoProps {
  selectedPatient: LocationPatientRecordDto;
  openNewChatModal: () => void;
  setShowEditModal: (show: boolean) => void;
  setIsSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 72,
  },
  userInfo: {
    justifyContent: 'space-between',
    marginLeft: theme.getSpacing(1.5),
  },
}));
