import { CopyToClipboard } from 'assets/components/copy-to-clipboard';
import { Icon } from 'assets/components/icon';
import { Text } from 'assets/components/text';
import { ToggleSwitch } from 'assets/components/toggle-switch';
import { CopyToClipboardIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import { FunctionComponent, PropsWithChildren, useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { boolToString } from '../../../../../utils';
import { canFactory } from '../../../../common/guards/permission-guard';
import { useToast } from '../../../../common/hooks/useToast';
import { RowItemRender } from '../../../../components/Settings/RowItemRender';
import { SettingsTooltipsComponents } from '../../../../components/Settings/SettingsTooltipsComponents';
import { useAppStateStore } from '../../../../store/app-store';
import { updatePharmacyApp } from '../app-settings-actions';

export const AppItemRenderer: FunctionComponent<
  PropsWithChildren<AppItemRendererProps>
> = ({
  display_name,
  marketplace_name,
  android_identifier,
  android_store_link,
  apple_identifier,
  apple_developer_account,
  apple_store_link,
  ios_app_id,
  ios_team_id,
  ios_bundle_id,
  app_is_active,
}) => {
  const { pharmacyId } = useAppStateStore();
  const { toast } = useToast();
  const styles = useStyles();
  const theme = useTheme();
  const canLumistryUserUpdateSettings = canFactory('update')(
    'settingsLumistryUser',
  );
  const canUpdate = canFactory('update')('settingsLumistryUser');
  const [toggle, setToggle] = useState(app_is_active);

  const toggleService = () => {
    updatePharmacyApp(pharmacyId, { app_is_active: !toggle });
    setToggle(!toggle);
  };

  return (
    <>
      <RowItemRender
        title={getText('app-name')}
        value={display_name}
        icon={
          canLumistryUserUpdateSettings ? (
            <></>
          ) : (
            <SettingsTooltipsComponents
              tooltipId="app-name-settings"
              tooltipText={getText('app-name-tooltip-text')}
            />
          )
        }
      />

      <RowItemRender
        title={getText('app-marketplace-name')}
        value={marketplace_name}
        icon={
          <SettingsTooltipsComponents
            tooltipId="app-store-name-settings"
            tooltipText={getText('app-store-name-tooltip-text')}
          />
        }
      />

      <RowItemRender
        title={getText('android-identifier')}
        value={android_identifier}
      />
      <RowItemRender title={getText('android-store-link')}>
        <View style={styles.copyViewStyle}>
          <Text style={styles.valueStyle}>{android_store_link}</Text>
          {android_store_link && (
            <CopyToClipboard
              logger={{ id: `copy-spacecraft-id` }}
              stringToCopy={android_store_link}
              fetchFromClipboard={() => toast('Copied to clipboard')}
              color={theme.palette.primary[600]}
              size={16}
              text={getText('copy-link')}
              style={{ marginLeft: theme.getSpacing(1) }}
            />
          )}
        </View>
      </RowItemRender>
      <RowItemRender
        title={getText('apple-identifier')}
        value={apple_identifier}
      />
      <RowItemRender
        title={getText('apple-developer-account')}
        value={apple_developer_account}
        icon={
          <SettingsTooltipsComponents
            tooltipId="apple-developer-account-settings"
            tooltipText={getText('apple-developer-account-tooltip')}
          />
        }
      />
      <RowItemRender title={getText('apple-store-link')}>
        <View style={styles.copyViewStyle}>
          <Text style={styles.valueStyle}>{apple_store_link}</Text>
          {apple_store_link && (
            <CopyToClipboard
              logger={{ id: `copy-spacecraft-id` }}
              stringToCopy={apple_store_link}
              fetchFromClipboard={() => toast('Copied to clipboard')}
              color={theme.palette.primary[600]}
              size={16}
              text={getText('copy-link')}
              style={{ marginLeft: theme.getSpacing(1) }}
            />
          )}
        </View>
      </RowItemRender>
      <RowItemRender title={getText('ios-app-id')} value={ios_app_id} />
      <RowItemRender title={getText('ios-team-id')} value={ios_team_id} />
      <RowItemRender title={getText('ios-bundle-id')} value={ios_bundle_id} />
      <RowItemRender title={getText('app-is-live')}>
        <View style={styles.copyViewStyle}>
          <View style={{ flex: 3 }}>
            <Text style={styles.valueStyle}>{boolToString(toggle)}</Text>
          </View>
          <View style={{ alignContent: 'flex-end' }}>
            <ToggleSwitch
              logger={{
                id: `toggle-app-active`,
              }}
              value={toggle}
              onPress={toggleService}
              disabled={!canUpdate}
            />
          </View>
        </View>
      </RowItemRender>
      <RowItemRender title={getText('qr-code')}>
        <View style={styles.copyViewStyle}>
          <View style={{ flex: 3 }}>
            <TouchableOpacity
              onPress={() => {}}
              style={styles.viewStyle}
              testID="open-view-image"
              disabled={!toggle}
            >
              <Icon
                icon={CopyToClipboardIcon}
                color={
                  toggle ? theme.palette.primary[600] : theme.palette.gray[700]
                }
                size={15}
              />
              <Text
                style={[
                  styles.imageValueStyle,
                  {
                    color: toggle
                      ? theme.palette.primary[600]
                      : theme.palette.gray[700],
                  },
                ]}
              >
                {getText('view-image')}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </RowItemRender>
    </>
  );
};

interface AppItemRendererProps {
  display_name: string;
  marketplace_name: string;
  android_identifier: string;
  android_store_link: string;
  apple_identifier: string;
  apple_developer_account: string;
  apple_store_link: string;
  ios_app_id: string;
  ios_team_id: string;
  ios_bundle_id: string;
  app_is_active: boolean;
}

const useStyles = makeStyles((theme) => ({
  copyViewStyle: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  valueStyle: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.gray[700],
  },
  viewStyle: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  imageValueStyle: {
    ...theme.lumistryFonts.text.small.regular,
    paddingLeft: theme.getSpacing(0.5),
  },
}));
