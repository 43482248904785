import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import React, { FunctionComponent, useRef } from 'react';
import { View } from 'react-native';
import { useWizard } from 'react-use-wizard';
import { save } from '../../../../actions/onboarding-actions';
import { zIndexAuto } from '../../../../common/theme';
import {
  LocationHolidayType,
  LocationHourType,
  useOnboardingStore,
} from '../../../../store/onboarding-store';
import { OBContentHeader } from '../content-header/OBContentHeader';
import OBDivider from '../divider/OBDivider';
import { OBHourIcon } from '../icons/OBHourIcon';
import { OBHoursForm } from '../forms/OBHoursForm';
import { OBFormHandler } from '../forms';

export type HoursFormType = {
  hours: LocationHourType[];
  holidays: LocationHolidayType[];
  location_use_default_hours?: boolean;
  location_use_default_holidays?: boolean;
};

export const OBSingleLocationHours: FunctionComponent = () => {
  const styles = useStyles();
  const { data } = useOnboardingStore();
  const { handleStep } = useWizard();
  const obHoursFormRef = useRef<OBFormHandler>(null);

  handleStep(async () => {
    await obHoursFormRef.current?.handleSubmit();
    await save();
  });

  return (
    <View style={styles.root}>
      <OBContentHeader
        icon={OBHourIcon}
        title={getText('store-hours')}
        description={getText('store-general-hours')}
      />
      <OBHoursForm location={data.locations[0]} ref={obHoursFormRef} />
      <OBDivider />
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    alignContent: 'center',
  },
  timeContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 150,
    zIndex: zIndexAuto,
  },
  checkboxContainer: { width: 30 },
  actionContainer: {
    flex: 1,
    zIndex: zIndexAuto,
    flexDirection: 'row',
    gap: theme.getSpacing(2),
    alignItems: 'center',
  },
  timeRange: {
    zIndex: zIndexAuto,
    width: 300,
  },
  text: {
    flexDirection: 'row',
    gap: theme.getSpacing(1),
    alignItems: 'center',
  },
  breakText: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.primary[600],
  },
  closedText: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.gray[500],
  },
  holidays: {
    marginTop: theme.getSpacing(4),
  },
  holidayTitle: {
    ...theme.lumistryFonts.text.medium.semiBold,
    color: theme.palette.gray[900],
  },
  holidaySubtitle: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.gray[700],
  },
}));
