/* tslint:disable */
/* eslint-disable eslint-comments/no-unlimited-disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull frontend'
 *
 * Required dependencies: @amplitude/analytics-react-native@^0.4.0, @react-native-async-storage/async-storage@^1.17.9
 * Tracking Plan Version: 3
 * Build: 1.0.0
 * Runtime: react-native:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/digital-pharmacist/Lumistry%20Pharmacist/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/digital-pharmacist/Lumistry%20Pharmacist/implementation/frontend)
 */

import * as amplitude from '@amplitude/analytics-react-native';

export type ReactNativeClient = amplitude.Types.ReactNativeClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
export type ReactNativeOptions = amplitude.Types.ReactNativeOptions;

export type Environment = 'production' | 'development' | 'staging';

export const ApiKey: Record<Environment, string> = {
  production: 'd4341537b88ea33aea6da1eae70fab23',
  development: 'a798881857ab5def879e60af9d368994',
  staging: '764c88e9dcbb6b9ff724c13e29b867b5',
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: ReactNativeOptions = {
  plan: {
    version: '3',
    branch: 'main',
    source: 'frontend',
    versionId: '444ba85b-2f24-488f-bcee-c74559183d65',
  },
  ...{
    ingestionMetadata: {
      sourceName: 'react-native-typescript-ampli',
      sourceVersion: '2.0.0',
    },
  },
};

export interface LoadOptionsBase {
  disabled?: boolean;
}

export type LoadOptionsWithEnvironment = LoadOptionsBase & {
  environment: Environment;
  client?: { configuration?: ReactNativeOptions };
};
export type LoadOptionsWithApiKey = LoadOptionsBase & {
  client: { apiKey: string; configuration?: ReactNativeOptions };
};
export type LoadOptionsWithClientInstance = LoadOptionsBase & {
  client: { instance: ReactNativeClient };
};

export type LoadOptions =
  | LoadOptionsWithEnvironment
  | LoadOptionsWithApiKey
  | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  /**
   * The email address of a pharmacy
   */
  email?: string;
  /**
   * pharmacy fax number
   */
  fax?: string;
  /**
   * The group ID of a pharmacy
   */
  group_id?: string;
  /**
   * Loation-level primary key
   */
  location_id?: string;
  /**
   * Location Name
   */
  location_name?: string;
  locationId?: string;
  /**
   * The NCPDP ID of a pharmacy
   */
  ncpdp?: string;
  /**
   * The ID of pharmacy
   */
  pharmacy_id?: string;
  /**
   * The name of pharmacy
   */
  pharmacy_name?: string;
  /**
   * Pharmacy phone number
   */
  phone?: string;
  /**
   * The PMS of a pharmacy
   */
  PMS?: string;
  /**
   * The salesforce ID of a pharmacy
   */
  salesforce_account_id?: string;
  /**
   * The pharmacy status type ID
   */
  status_type_id?: string;
}

export interface BulkMessageSentProperties {
  /**
   * The number of bulk message recipients
   */
  bulkMessageRecipientNumber: string;
  /**
   * The time when a new bulk message is sent
   */
  bulkMessageSentTime: string;
  /**
   * The subject of a bulk message
   */
  bulkMessageSubject: string;
  /**
   * The selected template of a sent bulk message
   */
  bulkMessageTemplateSelected: string;
  /**
   * The ID of a conversation.
   */
  conversationID: string;
  /**
   * The ID of a sent message
   */
  messageID: string;
}

export interface FormCreatedProperties {
  /**
   * Whether the form's Private button is turned on or off: Private or public
   */
  formAccess: string;
  /**
   * The time when the save button is clicked
   */
  formSavedTime: string;
  /**
   * Whether the Active button is turned on or not
   */
  formStatus: string;
  /**
   * Form is created from a template or blank
   */
  formType: string;
}

export interface FormDownloadedProperties {
  formDownloadedTime: string;
  /**
   * The name of the form
   */
  formName: string;
  /**
   * Form is created from a template or blank
   */
  formType: string;
}

export interface FormEditedProperties {
  /**
   * The time when the Rdit button is clicked
   */
  formEditedTime: string;
  formEditedType: string;
  /**
   * The name of the form
   */
  formName: string;
  /**
   * Form is created from a template or blank
   */
  formType: string;
  userid: string;
}

export interface FormNotificationProperties {
  formNotificationMethod: string;
  formNotificationStatus: string;
}

export interface FormSubmissionViewedProperties {
  /**
   * Active or inactive
   */
  activeStatus: boolean;
  /**
   * The time when a form is created.
   */
  createTime: string;
  formId: string;
  /**
   * The name of the form
   */
  formName: string;
  /**
   * The time when a submitted form is viewed by the pharmacy user
   */
  formSubmissionViewedTime: string;
  /**
   * Form is created from a template or blank
   */
  formType: string;
  /**
   * Read or unread
   */
  readStatus: boolean;
  /**
   * The time when a form is updated.
   */
  updateTime: string;
}

export interface MessageReadProperties {
  /**
   * The ID of a conversation.
   */
  conversationID: string;
  /**
   * The ID of a sent message
   */
  messageID: string;
  /**
   * The time when a message is read
   */
  messageReadTime: string;
  /**
   * The subject of a sent message, General, Billing, Med info, Side effects, Appointments
   */
  messageSubject: string;
}

export interface MessageSentProperties {
  /**
   * The ID of a conversation.
   */
  conversationID: string;
  /**
   * The ID of a sent message
   */
  messageID: string;
  messageSentTime: string;
  /**
   * The subject of a sent message, General, Billing, Med info, Side effects, Appointments
   */
  messageSubject: string;
  /**
   * The message template being selected
   */
  messageTemplateSelected: string;
}

export interface TaskAdvancedFilteredProperties {
  /**
   * The advanced filter used to search tasks
   */
  taskAdvancedFilter: string;
  /**
   * The time when a advanced filter is selected
   */
  taskAdvancedFilterAppliedTime: string;
}

export interface TaskAssignedToProperties {
  /**
   * The time whrn a task is assigned
   */
  taksAssignedTime: string;
  /**
   * The person that the task is assigned to
   */
  taskAssignedTo: string;
  /**
   * The role of the task assignee
   */
  taskAssigneeRole: string;
}

export interface TaskCreatedProperties {
  /**
   * The assignee of the task
   */
  taskAssignee: string;
  /**
   * The time when the Save button is clicked
   */
  taskCreatedTime: string;
  /**
   * The task status
   */
  taskStatus: string;
  /**
   * Task created from a template or blank.
   */
  taskType: string;
  /**
   * Who can view the task
   */
  taskVisibility: string;
}

export interface TaskSearchedProperties {
  /**
   * The time when the pharmacy searches a task
   */
  taskSearchedTime: string;
  /**
   * The value that a pharmacy user uses ti search a task.
   */
  taskSearchedValue: string;
}

export interface TaskTypeCreatedProperties {
  /**
   * The new task type that is created
   */
  taskTypeCreated: string;
  /**
   * The time when a new task type is created
   */
  taskTypeCreatedTime: string;
}

export interface UserCreatedProperties {
  /**
   * The role selected when a user is created
   */
  roleSelected: string;
  /**
   * The time when a user role is created
   */
  roleSelectedTime: string;
}

export interface UserLoggedInProperties {
  /**
   * Method used to log in
   */
  loginMethod: string;
  /**
   * Whether the login is successful or not
   */
  loginStatus: string;
  /**
   * The login time
   */
  loginTime: string;
  /**
   * The product version
   */
  productVersion: string;
}

export interface UserLoggedOutProperties {
  /**
   * How logout is happened? Self logout by clicking the logout button or auto logout due to inactivity.
   */
  logoutMethod: string;
  /**
   * The logout time
   */
  logoutTime: string;
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(public event_properties?: IdentifyProperties) {
    this.event_properties = event_properties;
  }
}

export class BulkMessageSent implements BaseEvent {
  event_type = 'bulkMessageSent';

  constructor(public event_properties: BulkMessageSentProperties) {
    this.event_properties = event_properties;
  }
}

export class FormCreated implements BaseEvent {
  event_type = 'formCreated';

  constructor(public event_properties: FormCreatedProperties) {
    this.event_properties = event_properties;
  }
}

export class FormDownloaded implements BaseEvent {
  event_type = 'formDownloaded';

  constructor(public event_properties: FormDownloadedProperties) {
    this.event_properties = event_properties;
  }
}

export class FormEdited implements BaseEvent {
  event_type = 'formEdited';

  constructor(public event_properties: FormEditedProperties) {
    this.event_properties = event_properties;
  }
}

export class FormNotification implements BaseEvent {
  event_type = 'formNotification';

  constructor(public event_properties: FormNotificationProperties) {
    this.event_properties = event_properties;
  }
}

export class FormSubmissionViewed implements BaseEvent {
  event_type = 'formSubmissionViewed';

  constructor(public event_properties: FormSubmissionViewedProperties) {
    this.event_properties = event_properties;
  }
}

export class MessageRead implements BaseEvent {
  event_type = 'messageRead';

  constructor(public event_properties: MessageReadProperties) {
    this.event_properties = event_properties;
  }
}

export class MessageSent implements BaseEvent {
  event_type = 'messageSent';

  constructor(public event_properties: MessageSentProperties) {
    this.event_properties = event_properties;
  }
}

export class TaskAdvancedFiltered implements BaseEvent {
  event_type = 'taskAdvancedFiltered';

  constructor(public event_properties: TaskAdvancedFilteredProperties) {
    this.event_properties = event_properties;
  }
}

export class TaskAssignedTo implements BaseEvent {
  event_type = 'taskAssignedTo';

  constructor(public event_properties: TaskAssignedToProperties) {
    this.event_properties = event_properties;
  }
}

export class TaskCreated implements BaseEvent {
  event_type = 'taskCreated';

  constructor(public event_properties: TaskCreatedProperties) {
    this.event_properties = event_properties;
  }
}

export class TaskSearched implements BaseEvent {
  event_type = 'taskSearched';

  constructor(public event_properties: TaskSearchedProperties) {
    this.event_properties = event_properties;
  }
}

export class TaskTypeCreated implements BaseEvent {
  event_type = 'taskTypeCreated';

  constructor(public event_properties: TaskTypeCreatedProperties) {
    this.event_properties = event_properties;
  }
}

export class UserCreated implements BaseEvent {
  event_type = 'userCreated';

  constructor(public event_properties: UserCreatedProperties) {
    this.event_properties = event_properties;
  }
}

export class UserLoggedIn implements BaseEvent {
  event_type = 'userLoggedIn';

  constructor(public event_properties: UserLoggedInProperties) {
    this.event_properties = event_properties;
  }
}

export class UserLoggedOut implements BaseEvent {
  event_type = 'userLoggedOut';

  constructor(public event_properties: UserLoggedOutProperties) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: ReactNativeClient;

  get client(): ReactNativeClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already initialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }

    return this.amplitude!.identify(amplitudeIdentify, options);
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * Flush pending events.
   */
  flush(): PromiseResult<void> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * bulkMessageSent
   *
   * [View in Tracking Plan](https://data.amplitude.com/digital-pharmacist/Lumistry%20Pharmacist/events/main/latest/bulkMessageSent)
   *
   * Event to track when a bulk message is sent to multiple recipients.
   *
   * @param properties The event's properties (e.g. bulkMessageRecipientNumber)
   * @param options Amplitude event options.
   */
  bulkMessageSent(
    properties: BulkMessageSentProperties,
    options?: EventOptions,
  ) {
    return this.track(new BulkMessageSent(properties), options);
  }

  /**
   * formCreated
   *
   * [View in Tracking Plan](https://data.amplitude.com/digital-pharmacist/Lumistry%20Pharmacist/events/main/latest/formCreated)
   *
   * Owner: Priti Jagger
   *
   * @param properties The event's properties (e.g. formAccess)
   * @param options Amplitude event options.
   */
  formCreated(
    properties: FormCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FormCreated(properties), options);
  }

  /**
   * formDownloaded
   *
   * [View in Tracking Plan](https://data.amplitude.com/digital-pharmacist/Lumistry%20Pharmacist/events/main/latest/formDownloaded)
   *
   * Owner: Priti Jagger
   *
   * @param properties The event's properties (e.g. formDownloadedTime)
   * @param options Amplitude event options.
   */
  formDownloaded(
    properties: FormDownloadedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FormDownloaded(properties), options);
  }

  /**
   * formEdited
   *
   * [View in Tracking Plan](https://data.amplitude.com/digital-pharmacist/Lumistry%20Pharmacist/events/main/latest/formEdited)
   *
   * Owner: Priti Jagger
   *
   * @param properties The event's properties (e.g. formEditedTime)
   * @param options Amplitude event options.
   */
  formEdited(
    properties: FormEditedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FormEdited(properties), options);
  }

  /**
   * formNotification
   *
   * [View in Tracking Plan](https://data.amplitude.com/digital-pharmacist/Lumistry%20Pharmacist/events/main/latest/formNotification)
   *
   * Owner: Priti Jagger
   *
   * @param properties The event's properties (e.g. formNotificationMethod)
   * @param options Amplitude event options.
   */
  formNotification(
    properties: FormNotificationProperties,
    options?: EventOptions,
  ) {
    return this.track(new FormNotification(properties), options);
  }

  /**
   * formSubmissionViewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/digital-pharmacist/Lumistry%20Pharmacist/events/main/latest/formSubmissionViewed)
   *
   * Event to track when a user views a form submission on the pharmacy platform
   *
   * Owner: Priti Jagger
   *
   * @param properties The event's properties (e.g. activeStatus)
   * @param options Amplitude event options.
   */
  formSubmissionViewed(
    properties: FormSubmissionViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FormSubmissionViewed(properties), options);
  }

  /**
   * messageRead
   *
   * [View in Tracking Plan](https://data.amplitude.com/digital-pharmacist/Lumistry%20Pharmacist/events/main/latest/messageRead)
   *
   * Event to track when a message has been read by the user.
   *
   * @param properties The event's properties (e.g. conversationID)
   * @param options Amplitude event options.
   */
  messageRead(
    properties: MessageReadProperties,
    options?: EventOptions,
  ) {
    return this.track(new MessageRead(properties), options);
  }

  /**
   * messageSent
   *
   * [View in Tracking Plan](https://data.amplitude.com/digital-pharmacist/Lumistry%20Pharmacist/events/main/latest/messageSent)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. conversationID)
   * @param options Amplitude event options.
   */
  messageSent(
    properties: MessageSentProperties,
    options?: EventOptions,
  ) {
    return this.track(new MessageSent(properties), options);
  }

  /**
   * taskAdvancedFiltered
   *
   * [View in Tracking Plan](https://data.amplitude.com/digital-pharmacist/Lumistry%20Pharmacist/events/main/latest/taskAdvancedFiltered)
   *
   * This event is triggered when a user applies advanced filters to refine their task search
   *
   * @param properties The event's properties (e.g. taskAdvancedFilter)
   * @param options Amplitude event options.
   */
  taskAdvancedFiltered(
    properties: TaskAdvancedFilteredProperties,
    options?: EventOptions,
  ) {
    return this.track(new TaskAdvancedFiltered(properties), options);
  }

  /**
   * taskAssignedTo
   *
   * [View in Tracking Plan](https://data.amplitude.com/digital-pharmacist/Lumistry%20Pharmacist/events/main/latest/taskAssignedTo)
   *
   * This event tracks when a task is assigned to a user within the system
   *
   * @param properties The event's properties (e.g. taksAssignedTime)
   * @param options Amplitude event options.
   */
  taskAssignedTo(
    properties: TaskAssignedToProperties,
    options?: EventOptions,
  ) {
    return this.track(new TaskAssignedTo(properties), options);
  }

  /**
   * taskCreated
   *
   * [View in Tracking Plan](https://data.amplitude.com/digital-pharmacist/Lumistry%20Pharmacist/events/main/latest/taskCreated)
   *
   * This event is triggered when a new task is created in the system. It captures the relevant details of the task, such as the task ID, title, and any associated metadata. This event is useful for tracking task creation patterns and analyzing task management efficiency
   *
   * @param properties The event's properties (e.g. taskAssignee)
   * @param options Amplitude event options.
   */
  taskCreated(
    properties: TaskCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TaskCreated(properties), options);
  }

  /**
   * taskSearched
   *
   * [View in Tracking Plan](https://data.amplitude.com/digital-pharmacist/Lumistry%20Pharmacist/events/main/latest/taskSearched)
   *
   * This event tracks when a user performs a search for a specific task within the application
   *
   * @param properties The event's properties (e.g. taskSearchedTime)
   * @param options Amplitude event options.
   */
  taskSearched(
    properties: TaskSearchedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TaskSearched(properties), options);
  }

  /**
   * taskTypeCreated
   *
   * [View in Tracking Plan](https://data.amplitude.com/digital-pharmacist/Lumistry%20Pharmacist/events/main/latest/taskTypeCreated)
   *
   * This event tracks the creation of a new task type in the system
   *
   * @param properties The event's properties (e.g. taskTypeCreated)
   * @param options Amplitude event options.
   */
  taskTypeCreated(
    properties: TaskTypeCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TaskTypeCreated(properties), options);
  }

  /**
   * userCreated
   *
   * [View in Tracking Plan](https://data.amplitude.com/digital-pharmacist/Lumistry%20Pharmacist/events/main/latest/userCreated)
   *
   * This event captures the creation of a new user in our system. It includes information such as the user's role, date and time of creation, and any other additional details provided during the creation process
   *
   * @param properties The event's properties (e.g. roleSelected)
   * @param options Amplitude event options.
   */
  userCreated(
    properties: UserCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserCreated(properties), options);
  }

  /**
   * userLoggedIn
   *
   * [View in Tracking Plan](https://data.amplitude.com/digital-pharmacist/Lumistry%20Pharmacist/events/main/latest/userLoggedIn)
   *
   * When the user logs into their account
   *
   * @param properties The event's properties (e.g. loginMethod)
   * @param options Amplitude event options.
   */
  userLoggedIn(
    properties: UserLoggedInProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserLoggedIn(properties), options);
  }

  /**
   * userLoggedOut
   *
   * [View in Tracking Plan](https://data.amplitude.com/digital-pharmacist/Lumistry%20Pharmacist/events/main/latest/userLoggedOut)
   *
   * This event captures when a user logs out of the application
   *
   * @param properties The event's properties (e.g. logoutMethod)
   * @param options Amplitude event options.
   */
  userLoggedOut(
    properties: UserLoggedOutProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserLoggedOut(properties), options);
  }
}

export const ampli = new Ampli();
