import { IconProps } from 'assets/icons/types';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path, Circle, G, Defs, ClipPath } from 'react-native-svg';

export const ScheduleActionIcon: FunctionComponent<
  PropsWithChildren<IconProps>
> = ({ color, size, testID, strokeWidth }) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 28 26"
      fill="none"
      stroke="currentColor"
      color={color}
      testID={testID}
    >
      <Path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M17 4v4M9 4v4m-5 4h18M6 6h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2Z"
      />
      <Circle
        cx={21.5}
        cy={6.5}
        r={5.5}
        fill={color}
        stroke="#fff"
        strokeWidth={strokeWidth}
      />
      <G clipPath="url(#a)">
        <Path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M21.5 4.458v4.084M19.458 6.5h4.084"
        />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path fill="#fff" d="M18 3h7v7h-7z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};
