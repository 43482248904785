import { Text, View } from 'react-native';
import { Form } from 'assets/layout';
import InfoGroup from './InfoGroup';
import { TextField } from 'assets/components/text-field';
import { getText } from 'assets/localization/localization';
import { CheckboxField, CheckboxInputMode } from 'assets/components/checkbox';
import { RadioButtonGroupField } from 'assets/components/radio-button-group';
import { AdvancedDropDownField } from '../advanced-dropdown';
import { getSpacing, makeStyles } from 'assets/theme';
import { RadioButtonInputMode } from 'assets/components/radio-button-group/RadioButton';
import { FC, useEffect } from 'react';
import { UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { IPatientForm, ModalType } from '../PatientFormModal/types';
import { PREFERRED_LANGUAGE_VALUES } from './languages';
import states from 'assets/utils/states';
import PhotoId from './PhotoId';
import { rules } from './validation.rules';

interface IShowMoreProps {
  setValue: UseFormSetValue<IPatientForm>;
  watch: UseFormWatch<IPatientForm>;
  setPhoto: (prop: File | undefined) => void;
  modalType: ModalType;
}

const ShowMoreSection: FC<IShowMoreProps> = ({
  setValue,
  watch,
  setPhoto,
  modalType,
}) => {
  const styles = useStyles();
  const hasNoAllergies = watch('no_allergies');
  const country = watch('country');

  useEffect(() => {
    if (hasNoAllergies) {
      setValue('allergies', '');
    }
  }, [hasNoAllergies, setValue]);

  const validStringArray = /^[\wa-яА-ЯіІїЇёЁ]+(,[\wa-яА-ЯіІїЇёЁ]+)*$/;

  return (
    <>
      <InfoGroup title={getText('address')}>
        <Form.Row>
          <RadioButtonGroupField
            name="country"
            isHorizontal
            mode={RadioButtonInputMode.FLAT}
            values={[
              {
                text: 'US',
                value: 'US',
              },
              {
                text: 'CA',
                value: 'CA',
              },
            ]}
          />
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              name={'street1'}
              label={getText('street1')}
              rules={rules[modalType]['street1']}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              name={'street2'}
              label={getText('street2')}
              rules={rules[modalType]['street2']}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row style={styles.row}>
          <Form.Column style={styles.city}>
            <TextField name={'city'} label={getText('city')} />
          </Form.Column>
          <Form.Column style={styles.zip}>
            <TextField
              name="postal_code"
              label={getText('zip-code')}
              rules={rules[modalType]['postal_code']}
            />
          </Form.Column>
          <Form.Column style={styles.state}>
            <AdvancedDropDownField
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  ...styles.dropDownControlStyles,
                }),
              }}
              options={states[country == 'CA' ? 'CA' : 'US']}
              name="state"
              getOptionValue={(option) => option.value}
              placeholderTemplate={getPlaceholderTemplate('state')}
              singleValueTemplate={getSingleValueTemplate('state')}
            />
          </Form.Column>
        </Form.Row>
      </InfoGroup>
      <InfoGroup title={getText('additional-information')}>
        <Text style={styles.subTitle}>
          Gender (as it appears on your insurance)
        </Text>
        <Form.Row>
          <RadioButtonGroupField
            name="gender"
            isHorizontal
            mode={RadioButtonInputMode.FLAT}
            values={[
              {
                text: getText('male'),
                value: 'male',
              },
              {
                text: getText('female'),
                value: 'female',
              },
            ]}
          />
        </Form.Row>
        <Form.Row style={styles.selectStyles}>
          <Form.Column>
            <AdvancedDropDownField
              name="preferred_language"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  ...styles.dropDownControlStyles,
                }),
              }}
              options={PREFERRED_LANGUAGE_VALUES}
              getOptionValue={(option) => option.value}
              placeholderTemplate={getPlaceholderTemplate('preferred-language')}
              singleValueTemplate={getSingleValueTemplate('preferred-language')}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              name={'email'}
              label={getText('email')}
              rules={rules[modalType]['email']}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column>
            <CheckboxField
              name="no_allergies"
              label={getText('no-allergies')}
              mode={CheckboxInputMode.FLAT}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              name={'allergies'}
              label={getText('allergies-colon')}
              disabled={hasNoAllergies}
              rules={{
                validate: (value) => {
                  return !value || validStringArray.test(value)
                    ? true
                    : getText('allergies-are-not-valid');
                },
              }}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              name={'conditions'}
              label={getText('conditions')}
              rules={{
                validate: (value) => {
                  return value === undefined || validStringArray.test(value)
                    ? true
                    : getText('conditions-are-not-valid');
                },
              }}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <CheckboxField
              name="prefers_easy_open_bottle_caps"
              label={getText('easy-open-bottle')}
              mode={CheckboxInputMode.FLAT}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <CheckboxField
              name="prefers_med_sync"
              label={getText('med-sync')}
              mode={CheckboxInputMode.FLAT}
            />
          </Form.Column>
        </Form.Row>
        <Text style={styles.subTitle}>
          {getText('notification-preferences')}
        </Text>
        <Form.Row>
          <Form.Column>
            <CheckboxField
              name="notify_by_sms"
              label={getText('text-message')}
              mode={CheckboxInputMode.FLAT}
            />
          </Form.Column>
          <Form.Column>
            <CheckboxField
              name="notify_by_voice"
              label={getText('automated-voice-call')}
              mode={CheckboxInputMode.FLAT}
            />
          </Form.Column>
          <Form.Column>
            <CheckboxField
              name="notify_by_email"
              label={getText('email-short')}
              mode={CheckboxInputMode.FLAT}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <PhotoId setPhoto={setPhoto} modalType={modalType} />
        </Form.Row>
      </InfoGroup>
    </>
  );
};

type TranslationKey = 'preferred-language' | 'state';

function getPlaceholderTemplate(name: TranslationKey) {
  const styles = useStyles();
  return (props: any) => {
    return (
      <View style={styles.placeholderWrapper}>
        <Text style={styles.placeholderText}>{getText(name)}</Text>
      </View>
    );
  };
}

function getSingleValueTemplate(name: TranslationKey) {
  const styles = useStyles();
  return (props: any) => {
    return (
      <View style={styles.dropDownSingleValueWrapper}>
        <Text style={styles.dropDownSingleValueName}>{getText(name)}</Text>
        <Text style={styles.dropDownSingleValueLabel}>{props.data?.label}</Text>
      </View>
    );
  };
}

const useStyles = makeStyles((theme) => ({
  city: {
    flexGrow: 3,
  },
  zip: {
    flexGrow: 2,
  },
  state: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'stretch',
  },
  subTitle: {
    alignSelf: 'flex-start',
    marginBottom: 8,
    fontSize: 16,
  },
  row: {
    alignContent: 'stretch',
  },
  selectStyles: {
    display: 'flex',
    alignItems: 'stretch',
    width: '50%',
  },
  placeholderWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    margin: 0,
    paddingLeft: getSpacing(0.5),
  },
  placeholderText: {
    color: theme.palette.gray[500],
    fontSize: 16,
  },
  dropDownControlStyles: {
    height: 64,
    borderRadius: theme.roundness,
  },
  dropDownSingleValueWrapper: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  dropDownSingleValueName: {
    margin: 0,
    fontSize: 13,
    color: theme.palette.gray[500],
  },
  dropDownSingleValueLabel: {
    margin: 0,
    fontSize: 16,
  },
}));

export default ShowMoreSection;
