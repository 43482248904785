import { IconButton } from 'assets/components/icon-button';
import { ChevronLeftIcon, ChevronRightIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import React, { useState } from 'react';
import { Text, View } from 'react-native';
import { useDebounceEffect } from '../../common/hooks/useDebounceEffect';
import { useVideosState } from '../../store/videos-store';
import {
  InputColor,
  SearchInput,
} from 'assets/components/search-input/SearchInput';

const SearchFilter: React.FC = () => {
  const styles = useStyles();
  const PAGE_SIZE = 25;
  const { totalCount, page, setPage, setSearch } = useVideosState();
  const theme = useTheme();

  const [search, setSearchInput] = useState('');

  useDebounceEffect(() => {
    setSearch(search);
  }, [search]);

  const goToPreviousPage = () => {
    setPage((page || 0) - 1);
  };

  const goToNextPage = () => {
    setPage((page || 0) + 1);
  };

  const PaginationDisplay = () => {
    return (
      <View style={styles.paginationContainer}>
        <Text style={{ marginRight: theme.getSpacing(1) }}>
          {`${PAGE_SIZE * ((page || 0) - 1) + 1} - ${Math.min(
            totalCount || 0,
            PAGE_SIZE * (page || 0),
          )} of ${totalCount}`}
        </Text>
        <IconButton
          disabled={page === 1}
          icon={ChevronLeftIcon}
          logger={{ id: 'grid-toolbar-icon-action-paginate-back' }}
          onPress={goToPreviousPage}
          size={18}
          color={theme.palette.gray[700]}
          style={{
            padding: 3,
            height: null,
            width: null,
            borderRadius: 50,
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: theme.palette.gray[700],
          }}
        />
        <IconButton
          disabled={PAGE_SIZE * (page || 0) >= (totalCount || 0)}
          icon={ChevronRightIcon}
          logger={{ id: 'grid-toolbar-icon-action-paginate-forward' }}
          onPress={goToNextPage}
          size={18}
          color={theme.palette.gray[700]}
          style={{
            padding: 3,
            height: null,
            width: null,
            borderRadius: 50,
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: theme.palette.gray[700],
          }}
        />
      </View>
    );
  };

  return (
    <View style={styles.titleContainer}>
      <SearchInput
        placeholder={getText('title')}
        inputColor={InputColor.Dark}
        inputValue={search ?? ''}
        onChange={setSearchInput}
        title={getText('videos')}
      />
      <View style={styles.pagination}>
        {page && totalCount ? <PaginationDisplay /> : <View />}
      </View>
    </View>
  );
};

export default SearchFilter;

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    flexDirection: 'row',
    marginBottom: theme.getSpacing(1),
    marginTop: theme.getSpacing(2),
    marginLeft: theme.getSpacing(3),
    width: '90%',
  },
  title: {
    fontSize: 25,
    marginRight: theme.getSpacing(3),
    alignItems: 'center',
    display: 'flex',
  },
  pagination: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginLeft: 'auto',
  },
  paginationContainer: {
    alignSelf: 'center',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));
