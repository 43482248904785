import create from 'zustand';
import { ApiError, AsyncStatus } from '../../store/types';

export const useUserAuthenticationModalState =
  create<UserAuthenticationModalState>(() => ({
    showModal: false,
    status: 'idle',
    resetAuthData: false,
  }));

interface UserAuthenticationModalState {
  showModal: boolean;
  status: AsyncStatus;
  error?: ApiError;
  resetAuthData?: boolean;
}
