import { View, Text } from 'react-native';
import WritingBar from 'assets/components/writing-bar/WritingBar';
import { makeStyles } from 'assets/theme';
import { FC } from 'react';
import { BIG_MESSAGE_LIMIT } from '../data';
import { AuthorType } from '@digitalpharmacist/unified-communications-service-client-axios';
import { UserTyping } from 'assets/types/messageTypes';

interface FooterProps {
  typingMember?: UserTyping | null;
  text?: string;
  conversationId: string;
}

const Footer: FC<FooterProps> = ({ typingMember, text, conversationId }) => {
  const styles = useStyles();

  return (
    <View style={styles.container}>
      <WritingBar
        typingMember={typingMember}
        conversationId={conversationId}
        appSource={AuthorType.Pharmacy}
      />
      <View style={styles.countBar}>
        <Text style={styles.countText}>{`${
          text ? text.length : 0
        }/${BIG_MESSAGE_LIMIT}`}</Text>
      </View>
    </View>
  );
};

export default Footer;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
  },
  countBar: {
    paddingBottom: 7,
    paddingRight: 10,
  },
  countText: {
    alignSelf: 'flex-end',
  },
}));
