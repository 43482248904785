import { WebsiteTemplate } from '@digitalpharmacist/pharmacy-service-client-axios';
import { Icon } from 'assets/components/icon';
import { Modal } from 'assets/components/modal';
import { Text } from 'assets/components/text';
import { ExternalLinkIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import { chunk } from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import { Image, Pressable, View } from 'react-native';
import {
  savePatientWebsite,
  setOpenedModal,
} from '../../../actions/checklist-actions';
import { ErrorMessage } from '../../../components/checklist/ErrorMessage';
import { useChecklistStore } from '../../../store/checklist-store';
import { ChecklistWebsiteModalProps } from '../types';
import { PatientWebsiteTemplates } from '../utils';
import { PharmacyTooltipWrapper } from '../../../common/PharmacyTooltipWrapper';
import { Tooltip } from 'react-tooltip';

export const PatientWebsiteTemplateModal: FunctionComponent<
  ChecklistWebsiteModalProps
> = ({ id, data }) => {
  const template = data?.website_details?.website_template;
  const currentOpenedModalId = useChecklistStore((x) => x.currentOpenedModalId);
  const [selectedTemplate, setSelectedTemplate] = useState<
    WebsiteTemplate | undefined
  >(template !== 'Unknown' ? template : undefined);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const styles = useStyles();
  const theme = useTheme();

  const handleSelectTemplate = async () => {
    if (!selectedTemplate) {
      setError(true);
      return;
    }

    try {
      setLoading(true);
      const dto = {
        ...data,
        website_details: {
          ...data.website_details,
          website_template: selectedTemplate,
        },
      };
      await savePatientWebsite(dto);
      setOpenedModal('patient-website-services');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={getText('website-setup')}
      show={currentOpenedModalId === id}
      handleDismissModal={() => setOpenedModal('checklist-setup')}
      showDismissButton
      isScrollable
      size="lg"
      buttons={[
        {
          hierarchy: 'primary',
          text: getText('next'),
          onPress: () => handleSelectTemplate(),
          logger: { id: 'next-website-service-details' },
          loading,
        },
        {
          text: getText('cancel'),
          hierarchy: 'tertiary-gray',
          onPress: () => setOpenedModal('checklist-setup'),
          logger: { id: 'back-website-service-details' },
        },
      ]}
    >
      <Text style={styles.title}>{getText('select-template') + '*'}</Text>
      {error && !selectedTemplate && (
        <ErrorMessage message={getText('template-required')} />
      )}
      <View style={styles.templateContainer}>
        {chunk(PatientWebsiteTemplates, 2).map((group, index) => (
          <View key={index} style={styles.row}>
            {group.map((template) => (
              <View key={template.type} style={styles.imageContainer}>
                <Pressable
                  onPress={() =>
                    setSelectedTemplate(WebsiteTemplate[template.type])
                  }
                >
                  <PharmacyTooltipWrapper
                    tooltipId={template.type}
                    containerStyles={styles.shadow}
                  >
                    <View style={styles.shadow}>
                      <Image
                        source={template.source}
                        style={
                          selectedTemplate === WebsiteTemplate[template.type]
                            ? styles.selectedTemplate
                            : styles.template
                        }
                      />
                    </View>
                  </PharmacyTooltipWrapper>
                  <Tooltip anchorId={template.type} style={styles.base}>
                    <Text style={styles.tooltipText}>
                      {getText('click-to-select')}
                    </Text>
                  </Tooltip>
                </Pressable>
                <Pressable
                  onPress={() =>
                    window.open(template.url, '_blank', 'noopener,noreferrer')
                  }
                  style={styles.linkContainer}
                >
                  <Icon
                    icon={ExternalLinkIcon}
                    size={20}
                    color={theme.palette.primary[600]}
                  />
                  <Text style={styles.text}>{template.type}</Text>
                </Pressable>
              </View>
            ))}
          </View>
        ))}
      </View>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  base: {
    borderRadius: theme.roundness,
    paddingVertical: theme.getSpacing(2),
    paddingHorizontal: theme.getSpacing(1),
    opacity: 1,
    maxWidth: 400,
    backgroundColor: theme.palette.gray[900],
  },
  title: {
    ...theme.lumistryFonts.text.medium.regular,
    color: theme.palette.gray[700],
  },
  templateContainer: {
    paddingTop: theme.getSpacing(2),
  },
  template: {
    height: 210,
    width: 360,
    borderWidth: 1,
    borderColor: theme.palette.gray[200],
    borderRadius: 6,
  },
  shadow: {
    shadowColor: theme.palette.gray[900],
    shadowOffset: {
      width: 0,
      height: 10,
    },
    shadowOpacity: 0.08,
    shadowRadius: 24,
    borderRadius: 6,
  },
  selectedTemplate: {
    height: 210,
    width: 360,
    borderWidth: theme.getSpacing(0.5),
    borderColor: theme.palette.primary[600],
    borderRadius: 6,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: theme.getSpacing(4),
  },
  text: {
    ...theme.lumistryFonts.text.small.semiBold,
    color: theme.palette.primary[600],
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.getSpacing(2),
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.getSpacing(1),
    alignItems: 'center',
  },
  tooltipText: {
    fontSize: theme.getSpacing(1.5),
    color: theme.palette.white,
  },
}));
