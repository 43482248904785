import { LoadingIndicator } from 'assets/components/loading-indicator';
import { ImageIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import {
  DimensionValue,
  ImageBackground,
  ImageResizeMode,
  View,
} from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { Surface } from 'react-native-paper';
import { handleError } from '../../common/errors/commonErrorHandler';

export interface PhotoInfo {
  uri?: string;
  width?: string | number;
  imageHeight?: number;
  height?: DimensionValue;
  assets?: Array<any>;
  resize?: ImageResizeMode;
  backgroundColor?: string;
  testID?: string;
}

export const PhotoButton: FunctionComponent<
  PropsWithChildren<
    PhotoInfo & {
      onPress?: () => Promise<void>;
    }
  >
> = ({
  uri,
  width = 100,
  height = 100,
  imageHeight,
  onPress,
  children,
  resize = 'cover',
  backgroundColor,
  testID,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [errorWhileLoading, setErrorWhileLoading] = useState(false);

  useEffect(() => {
    if (!uri) {
      setIsLoading(false);
    }
  }, [uri]);

  const onErrorHandler = () => {
    setErrorWhileLoading(true);
    setIsLoading(false);
    handleError({
      userMessage: getText('image-reading-error'),
      internalError: 'could not load the image',
      showToast: true,
    });
  };

  const onPressHandler = async () => {
    if (onPress) {
      try {
        await onPress();
      } catch (error) {
        handleError({
          userMessage: getText('server-error'),
          internalError: 'could not upload the image',
          showToast: true,
          error,
        });
      }
    }
  };

  return (
    <Surface
      style={[
        styles.button,
        {
          height: height,
          backgroundColor: uri ? backgroundColor : theme.palette.gray[100],
          width: width,
          minHeight: height,
        },
      ]}
      testID={testID}
    >
      <TouchableOpacity
        style={{
          justifyContent: 'center',
          height: height,
        }}
        onPress={onPressHandler}
      >
        {isLoading ? (
          <View style={styles.loading}>
            <LoadingIndicator />
          </View>
        ) : uri ? (
          errorWhileLoading ? (
            <View style={styles.placeholderWrapper}>
              <View>
                <ImageIcon size={32} color={theme.palette.gray[300]} />
              </View>
            </View>
          ) : (
            <ImageBackground
              source={{ uri: uri }}
              resizeMode={resize}
              style={{ height: imageHeight ? imageHeight : height }}
              onError={() => onErrorHandler()}
              onLoadEnd={() => setIsLoading(false)}
            />
          )
        ) : (
          children
        )}
      </TouchableOpacity>
    </Surface>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: 64,
    borderStyle: 'solid',
    elevation: 0,
    borderRadius: theme.roundness,
    justifyContent: 'center',
    overflow: 'hidden',
  },
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.getSpacing(1),
    marginBottom: theme.getSpacing(1),
  },
  icon: {
    marginRight: theme.getSpacing(0.5),
  },
  errorMessage: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.error[600],
  },
  loading: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  placeholderWrapper: {
    padding: theme.getSpacing(1),
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
