import { ClosureHoursDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { ToggleSwitch } from 'assets/components/toggle-switch';
import { FunctionComponent, PropsWithChildren } from 'react';
import { convertClosuresDateToString } from '../../../../common/datetime-utils';
import { RowItemRender } from '../../../../components/Settings/RowItemRender';
import {
  setLoadingAdditionalClosures,
  toggleLocationClosuresById,
} from '../location-settings-actions';
import { useLocationSettingsState } from '../location-settings-store';

export const LocationAdditionalClosuresItemRenderer: FunctionComponent<
  PropsWithChildren<ClosureHoursDto>
> = ({ id, name, timeRange, enabled }) => {
  const { locationId, loadingAdditionalClosures } = useLocationSettingsState();

  const handleToggleSwitch = () => {
    if (locationId) {
      setLoadingAdditionalClosures(true);
      toggleLocationClosuresById(locationId, id).finally(() =>
        setLoadingAdditionalClosures(false),
      );
    }
  };

  return (
    <RowItemRender
      title={name || ''}
      value={convertClosuresDateToString(timeRange)}
      style={{
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
      }}
    >
      {locationId && (
        <ToggleSwitch
          logger={{ id }}
          value={enabled}
          disabled={loadingAdditionalClosures}
          onPress={handleToggleSwitch}
        />
      )}
    </RowItemRender>
  );
};
