import { DirectMessagePatientDto } from '@digitalpharmacist/unified-communications-service-client-axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { Icon } from 'assets/components/icon';
import { ChatIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { useTheme } from 'assets/theme';
import { BaseFilterValues } from 'assets/types/messageTypes';
import {
  compare,
  groupConversationsByPatient,
} from 'assets/utils/messageUtils';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { StorageKeys } from '../../../../../enums/storage-keys';
import UnifiedCommsService from '../../../../api/UnifiedCommsService';
import { RootStackNavigationProp } from '../../../../navigation/RootNavigation';
import { useAppStateStore } from '../../../../store/app-store';
import { setBaseFilter } from '../../../messages/stores/data-manipulation-store/data-manipulation-actions';
import { useConversationsManipulationState } from '../../../messages/stores/data-manipulation-store/data-manipulation-store';
import { MessageList } from '../unread-messages/MessageList';
import { WidgetContainer } from '../widget-container/WidgetContainer';
import { useChatState } from '../../../messages/stores/chat-store/chat-store';
import { EmptyState } from '../empty-state/EmptyState';
import { UnreadMessagesEmptyStateIcon } from '../icons/UnreadMessagesEmptyStateIcon';

export const UnreadMessagesWidget: FunctionComponent<
  UnreadMessagesWidgetProps
> = () => {
  const MAX_ROW_NUMBERS = 5;
  const navigation = useNavigation<RootStackNavigationProp>();
  const theme = useTheme();
  const { sorting } = useConversationsManipulationState();
  const { count } = useChatState();
  const { locationId } = useAppStateStore();

  const [messageList, setMessageList] = useState<DirectMessagePatientDto[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useFocusEffect(
    useCallback(() => {
      void fetchUnreadMessages();
    }, [locationId]),
  );

  const fetchUnreadMessages = async () => {
    try {
      const userId = await AsyncStorage.getItem(StorageKeys.UserId);
      if (!userId) return;

      const filteredConversations =
        await UnifiedCommsService.getAllFilteredConversations(locationId, {
          unread_by_pharmacy: true,
        });
      const groupedConversations = groupConversationsByPatient(
        filteredConversations,
      ).sort((a, b) =>
        compare(a, b, sorting.field, sorting.order, sorting.isDate),
      );

      setMessageList(groupedConversations.slice(0, MAX_ROW_NUMBERS));
    } catch (error) {
      console.error('Failed to fetch unread messages:', error);
    } finally {
      setLoading(false);
    }
  };

  const getMoreItems = () => {
    const moreItemValue = (count.unread ?? 0) - MAX_ROW_NUMBERS;
    return moreItemValue > 0 ? moreItemValue : undefined;
  };

  const onUnreadMessagesNavigate = () => {
    setBaseFilter(BaseFilterValues.UNREAD);
    navigation.navigate('messages');
  };

  return (
    <WidgetContainer
      icon={<Icon icon={ChatIcon} size={24} color={theme.palette.gray[700]} />}
      title={getText('unread-messages')}
      onNavigate={onUnreadMessagesNavigate}
      moreItems={getMoreItems()}
      loading={loading}
    >
      {messageList.length === 0 ? (
        <EmptyState
          Icon={UnreadMessagesEmptyStateIcon}
          iconProps={{ size: 75 }}
          text={getText('unread-messages')}
        />
      ) : (
        <MessageList messageList={messageList} />
      )}
    </WidgetContainer>
  );
};

export type UnreadMessagesWidgetProps = {};
