import create from 'zustand';
import { AsyncStatus } from '../../../../store/types';
import {
  AutoMessageConfigDto,
  MessageConfigType,
} from '@digitalpharmacist/unified-communications-service-client-axios';

export const useAutoMessageState = create<AutoMessageState>(() => ({
  status: 'idle',
  automations: {},
}));

interface AutoMessageState {
  status: AsyncStatus;
  automations: Record<string, AutoMessageConfigDto>;
}
