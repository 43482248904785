import { AxiosRequestConfig } from 'axios';
import { useAppStateStore } from '../store/app-store';

export const AXIOS_DEFAULT_REQUEST_CONFIG: AxiosRequestConfig = {
  headers: {
    get 'x-pharmacy-id'() {
      return useAppStateStore.getState().pharmacyId;
    },
  },
};
