import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import shallow from 'zustand/shallow';
import { useFocusEffect } from '@react-navigation/native';
import { makeStyles } from 'assets/theme';
import { useForm } from 'assets/form';
import { Modal } from 'assets/components/modal';
import theme from 'assets/theme';
import { Form } from 'assets/layout/form/Form';
import { Text } from 'assets/components/text';

import { AdvancedDropDownField } from '../../components/advanced-dropdown';
import { LoadingOverlay } from '../../components/LoadingOverlay';
import {
  GenericOptionTemplate,
  GenericSingleValueTemplate,
  GenericPlaceholderTemplate,
  AppointmentGroupSingleValueTemplate,
  AppointmentGroupOptionTemplate,
} from '../../components/advanced-dropdown/templates/advanced-drop-down-field.templates';

import { getText } from '../../../../../packages/assets/localization/localization';
import { useServicesListState } from '../services-list/services-list-store';
import {
  AppointmentFilters,
  useAppointmentFiltersState,
} from './appointment-filters-store';
import { useServiceFormState } from '../service-form/service-form-store';
import {
  setAppointmentFilters,
  updateAppointmentFiltersShowModalState,
} from './appointment-filters-actions';
import { getServices } from '../services-list/services-list-actions';
import {
  getAppointmentGroupCategories,
  getAppointmentGroups,
} from '../service-form/service-form-actions';
import {
  InitialAppointmentFilterValueProps,
  initialAppointmentsFiltersFormValue,
} from './appointment-filters.utils';
import {
  AppointmentGroupCategoryDto,
  AppointmentGroupDto,
} from '@digitalpharmacist/appointment-service-client-axios';

export interface AppointmentGroup extends AppointmentGroupDto {
  categoryName?: string;
}

export const AppointmentFiltersModal: FunctionComponent<
  AppointmentFiltersModalProps
> = () => {
  const styles = useStyles();

  const [appointmentGroupsByCategory, setAppointmentGroupsByCategory] =
    useState<AppointmentGroup[] | InitialAppointmentFilterValueProps[]>([]);

  const { showModal, filters } = useAppointmentFiltersState(
    (state) => ({
      showModal: state.showModal,
      filters: state.filters,
    }),
    shallow,
  );

  const {
    services,
    status: servicesStatus,
    defaultAppointmentType,
  } = useServicesListState(
    (state) => ({
      ...state,
      services: state.services?.filter((service) => service.enabled) || [],
      status: state.status,
      defaultAppointmentType:
        state.services?.find((f) => f.id === filters.appointment_type_id) ??
        initialAppointmentsFiltersFormValue.appointment_type_id,
    }),
    shallow,
  );

  const {
    appointmentGroups,
    appointmentGroupCategories,
    status: groupsStatus,
    defaultAppointmentGroup,
    defaultAppointmentGroupCategory,
  } = useServiceFormState(
    (state) => ({
      ...state,
      appointmentGroups: state.appointmentGroups || [],
      appointmentGroupCategories: state.appointmentGroupCategories || [],
      status: state.status,
      defaultAppointmentGroup:
        state.appointmentGroups?.find(
          (f) => f.id === filters.appointment_group_id,
        ) ?? initialAppointmentsFiltersFormValue.appointment_group_id,
      defaultAppointmentGroupCategory:
        state.appointmentGroupCategories?.find(
          (f) => f.id === filters.appointment_group_category_id,
        ) ?? initialAppointmentsFiltersFormValue.appointment_group_category_id,
    }),
    shallow,
  );

  const isLoading = servicesStatus === 'loading' || groupsStatus === 'loading';

  const methods = useForm({
    defaultValues: initialAppointmentsFiltersFormValue,
  });

  const formValues = methods.watch();

  useFocusEffect(
    useCallback(() => {
      if (!services.length) {
        void getServices({ withoutNextAvailableSlot: true });
      }
      if (!appointmentGroups.length) {
        void getAppointmentGroups();
      } else {
        populateAppointmentGroups();
      }
      if (!appointmentGroupCategories.length) {
        void getAppointmentGroupCategories();
      }
    }, [
      services.length,
      appointmentGroups.length,
      appointmentGroupCategories.length,
    ]),
  );

  useEffect(() => {
    const subscription = methods.watch((value) => {
      const inputValues = Object.entries(value);

      for (const [key, value] of inputValues) {
        // If the input field is being cleared we are setting the default value for that field for clarity for the user
        if (value === null || value === undefined) {
          methods.setValue(key, initialAppointmentsFiltersFormValue[key]);
          return;
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [methods.watch]);

  useEffect(() => {
    if (!isLoading && showModal) {
      const appointmentFiltersForm = {
        appointment_type_id: defaultAppointmentType,
        appointment_group_id: defaultAppointmentGroup,
        appointment_group_category_id: defaultAppointmentGroupCategory,
      };

      methods.reset({
        ...appointmentFiltersForm,
      });
    }
  }, [filters, isLoading, showModal]);

  const handleSubmit = () => {
    const formValue = methods.getValues();
    // Filtering out all of the untouched/default filters instead of setting incorrect data since
    const filteredValues = Object.entries(formValue)
      .filter(([, value]) => value?.id !== 'default')
      .reduce<Record<string, unknown>>((obj, [key, value]) => {
        obj[key] = value?.id;

        return obj;
      }, {}) as AppointmentFilters;
    setAppointmentFilters(filteredValues);
  };

  const handleReset = () => {
    methods.reset({ ...initialAppointmentsFiltersFormValue });
    populateAppointmentGroups();
  };

  const closeModal = () => {
    updateAppointmentFiltersShowModalState(false);
    handleReset();
  };

  const setAppointmentGroupsDataAndSort = (
    appointmentGroups: AppointmentGroup[],
  ) =>
    appointmentGroups
      .map((appointmentGroup) => ({
        ...appointmentGroup,
        categoryName: appointmentGroupCategories.find(
          (appointmentGroupCategory) =>
            appointmentGroupCategory.id ===
            appointmentGroup.appointment_group_category_id,
        )?.title,
      }))
      .sort((a, b) => {
        if (
          a.categoryName &&
          b.categoryName &&
          a.categoryName != b.categoryName
        ) {
          return a.categoryName.localeCompare(b.categoryName);
        }

        return a.title.localeCompare(b.title);
      });

  const populateAppointmentGroups = (
    appointmentGroupCategory?: AppointmentGroupCategoryDto,
  ) => {
    if (appointmentGroups) {
      if (
        appointmentGroupCategory &&
        appointmentGroupCategory.id !== 'default'
      ) {
        const appointmentGroupsByCat = appointmentGroups.filter(
          (appointmentGroup) =>
            appointmentGroup.appointment_group_category_id ===
            appointmentGroupCategory.id,
        );
        setAppointmentGroupsByCategory(
          setAppointmentGroupsDataAndSort(appointmentGroupsByCat),
        );
      } else {
        setAppointmentGroupsByCategory([
          ...(initialAppointmentsFiltersFormValue.appointment_group_id
            ? [initialAppointmentsFiltersFormValue.appointment_group_id]
            : []),
          ...setAppointmentGroupsDataAndSort(appointmentGroups),
        ]);
      }
    }
  };

  return (
    <Modal
      size="lg"
      title={getText('appointment-filters')}
      titleSize="sm"
      dismissButtonProps={{
        onPress: closeModal,
        logger: {
          id: 'appointment-advanced-filters-form-dismiss-button-modal',
        },
      }}
      cancelButtonProps={{
        onPress: closeModal,
        logger: { id: 'appointment-advanced-filters-form-cancel-button-modal' },
      }}
      resetButtonProps={{
        onPress: handleReset,
        logger: { id: 'appointment-advanced-filters-form-reset-button-modal' },
      }}
      okButtonProps={{
        onPress: methods.handleSubmit(handleSubmit),
        logger: { id: 'appointment-advanced-filters-form-ok-button-modal' },
        hierarchy: 'primary',
        text: getText('ok'),
      }}
      show={showModal}
      contentContainerStyle={{
        marginTop: theme.getSpacing(0.5),
      }}
    >
      {isLoading && <LoadingOverlay />}
      <Form methods={methods}>
        <Form.Row style={styles.fieldWrapper}>
          <Form.Column style={styles.labelWrapper}>
            <Text style={styles.label}>
              {getText('service-category-filter')}
            </Text>
          </Form.Column>
          <Form.Column style={styles.inputWrapper}>
            <AdvancedDropDownField
              name="appointment_group_category_id"
              label=""
              options={[
                ...(initialAppointmentsFiltersFormValue.appointment_group_category_id
                  ? [
                      initialAppointmentsFiltersFormValue.appointment_group_category_id,
                    ]
                  : []),
                ...appointmentGroupCategories,
              ]}
              menuPortalTarget={document.body}
              getOptionValue={(optionValue) => optionValue.id}
              getOptionLabel={(optionValue) => optionValue.title}
              optionTemplate={GenericOptionTemplate}
              singleValueTemplate={GenericSingleValueTemplate}
              placeholderTemplate={GenericPlaceholderTemplate}
              isMulti={false}
              isClearable={
                formValues.appointment_group_category_id?.id !== 'default'
              }
              className="advanced-dropdown-horizontal-label"
              styles={{
                container: (base) => ({ ...base }),
              }}
              onChange={(appointmentGroupCategory) => {
                if (
                  appointmentGroupCategory?.id &&
                  appointmentGroups.length > 0
                ) {
                  methods.setValue('appointment_group_id', undefined);
                  populateAppointmentGroups(
                    appointmentGroupCategory as AppointmentGroupCategoryDto,
                  );
                }
              }}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row style={styles.fieldWrapper}>
          <Form.Column style={styles.labelWrapper}>
            <Text style={styles.label}>{getText('service-type')}</Text>
          </Form.Column>
          <Form.Column style={styles.inputWrapper}>
            <AdvancedDropDownField
              name="appointment_group_id"
              label=""
              options={appointmentGroupsByCategory as AppointmentGroup[]}
              menuPortalTarget={document.body}
              getOptionValue={(optionValue) => optionValue.id}
              getOptionLabel={(optionValue) => optionValue.title}
              optionTemplate={AppointmentGroupOptionTemplate}
              singleValueTemplate={AppointmentGroupSingleValueTemplate}
              isMulti={false}
              isClearable={formValues.appointment_group_id?.id !== 'default'}
              className="advanced-dropdown-horizontal-label"
              styles={{
                container: (base) => ({ ...base }),
              }}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row style={styles.fieldWrapper}>
          <Form.Column style={styles.labelWrapper}>
            <Text style={styles.label}>{`${getText('service')} ${getText(
              'name',
            )}`}</Text>
          </Form.Column>
          <Form.Column style={styles.inputWrapper}>
            <AdvancedDropDownField
              name="appointment_type_id"
              label=""
              options={[
                ...(initialAppointmentsFiltersFormValue.appointment_type_id
                  ? [initialAppointmentsFiltersFormValue.appointment_type_id]
                  : []),
                ...services,
              ]}
              menuPortalTarget={document.body}
              getOptionValue={(optionValue) => optionValue.id}
              getOptionLabel={(optionValue) => optionValue.title}
              optionTemplate={GenericOptionTemplate}
              singleValueTemplate={GenericSingleValueTemplate}
              placeholderTemplate={GenericPlaceholderTemplate}
              isMulti={false}
              isClearable={formValues.appointment_type_id?.id !== 'default'}
              className="advanced-dropdown-horizontal-label"
              styles={{
                container: (base) => ({ ...base }),
              }}
            />
          </Form.Column>
        </Form.Row>
      </Form>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  fieldWrapper: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  labelWrapper: {
    flex: 1,
  },
  inputWrapper: {
    flex: 3,
  },
  label: {
    color: theme.palette.black,
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 16,
  },
  datePickerWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));
interface AppointmentFiltersModalProps {}

export default AppointmentFiltersModal;
