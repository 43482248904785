import { UpdatePickupMethodDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { Modal } from 'assets/components/modal';
import { TextField } from 'assets/components/text-field';
import { ToggleSwitch } from 'assets/components/toggle-switch';
import { useForm } from 'assets/form';
import { Form } from 'assets/layout/form/Form';
import { getText } from 'assets/localization/localization';
import _isEqual from 'lodash/isEqual';
import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { useAppStateStore } from '../../../../store/app-store';
import {
  setShowModalPickup,
  updatePickupMethod,
} from '../refills-settings-actions';
import { useRefillsSettingsState } from '../refills-settings-store';
import { Text } from 'assets/components/text';
import { useToast } from '../../../../common/hooks/useToast';
import { useTheme } from 'assets/theme';
import { zIndexAuto } from '../../../../common/theme';

const DEFAULT_FORM_VALUE = { name: '', active: false, confirmation: '' };

export const PickupMethodModal: FunctionComponent<
  PropsWithChildren<any>
> = () => {
  const { showModalPickup, editingPickupMethod } = useRefillsSettingsState();
  const [pickupId, setPickupId] = useState('');
  const { locationId } = useAppStateStore();
  const [toggle, setToggle] = useState<boolean>();
  const { allPickupMethods } = useRefillsSettingsState();
  const { toast } = useToast();
  const theme = useTheme();

  const hasAtLeastOneActiveFlagTrue = (): boolean => {
    if (!allPickupMethods || allPickupMethods.length === 0) return false;
    let activeCount = 0;
    for (const method of allPickupMethods) {
      if (method.active) {
        activeCount += 1;
      }
    }
    return activeCount === 1;
  };

  useEffect(() => {
    if (editingPickupMethod) {
      const { id, name, active, confirmation } = editingPickupMethod;
      setToggle(active);
      setPickupId(id);
      methods.reset({ name, active, confirmation });
    }
  }, [editingPickupMethod]);

  const methods = useForm<GeneralInfoFormProps>({
    defaultValues: editingPickupMethod
      ? { ...editingPickupMethod }
      : DEFAULT_FORM_VALUE,
    mode: 'onChange',
  });
  const active = methods.getValues().active;
  const charCount = methods.watch('confirmation').length;
  const handleSubmit = async () => {
    const formValue = methods.getValues();
    const updatedPickupMethod: UpdatePickupMethodDto = {
      ...editingPickupMethod,
      ...formValue,
    };
    if (!_isEqual(updatedPickupMethod, editingPickupMethod)) {
      updatePickupMethod(locationId, pickupId, {
        active: formValue.active,
        confirmation: formValue.confirmation,
      });
    }
    setShowModalPickup(false);
  };

  const closeModal = () => {
    methods.reset({ ...editingPickupMethod });
    setShowModalPickup(false);
  };

  const toggleActive = () => {
    const newActive = !active;
    setToggle(newActive);

    // Check if toggling off the only active pickup method
    if (newActive === false && hasAtLeastOneActiveFlagTrue()) {
      toast(getText('one-pickup-method-must-be-enabled'), {
        type: 'warning',
      });
      setTimeout(() => setToggle(true), 1000); // Revert to previous state
    } else {
      methods.setValue('active', newActive);
    }
  };

  return (
    <Modal
      title={getText('locations-edit-pick-up-method')}
      titleSize="sm"
      dismissButtonProps={{
        onPress: closeModal,
        logger: { id: 'location-pickup-method-form-cancel-button-modal' },
      }}
      cancelButtonProps={{
        onPress: closeModal,
        hierarchy: 'tertiary-gray',
        logger: { id: 'location-pickup-method-form-cancel-button-modal' },
      }}
      okButtonProps={{
        onPress: methods.handleSubmit(handleSubmit),
        logger: { id: 'location-pickup-method-form-ok-button-modal' },
        hierarchy: 'primary',
        text: getText('ok'),
      }}
      show={showModalPickup}
      isScrollable={true}
    >
      <Form methods={methods}>
        {active && hasAtLeastOneActiveFlagTrue() && (
          <Form.Alert
            visible={hasAtLeastOneActiveFlagTrue()}
            intent="warning"
            title={getText('one-pickup-method-must-be-enabled')}
          />
        )}
        <Form.Row style={{ flexDirection: 'row', alignItems: 'center' }}>
          <ToggleSwitch
            logger={{
              id: `locations-pickup-method-${`${pickupId}`}`,
            }}
            value={toggle}
            onPress={() => toggleActive()}
          />
          <Text style={{ marginHorizontal: theme.getSpacing(1) }}>
            {toggle ? getText('enabled') : getText('disabled')}
          </Text>
        </Form.Row>

        <Form.Row>
          <Form.Column>
            <TextField name="name" type="text" disabled />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column>
            <TextField
              name="confirmation"
              type="text"
              multiline
              numberOfLines={4}
              maxLength={140}
              hintMessage={getText('character-count-of-total', {
                count: charCount,
                total: 140,
              })}
              rules={{
                required: getText('locations-required'),
              }}
            />
          </Form.Column>
        </Form.Row>
      </Form>
    </Modal>
  );
};

interface GeneralInfoFormProps {
  name: string;
  active: boolean;
  confirmation: string;
}
