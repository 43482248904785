import {
  ColDef,
  ColGroupDef,
  GridApi,
  RowClickedEvent,
  IServerSideGetRowsParams,
  IServerSideDatasource,
} from '@ag-grid-community/core';
import { LocationPatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';
import { useIsFocused } from '@react-navigation/native';
import { Avatar } from 'assets/components/avatar';
import { DataGrid } from 'assets/components/data-grid';
import { GLOBAL_SEARCH_FIELD } from 'assets/components/data-grid/constant';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { PharmacyScreenContainer } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import React, { useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { useProSidebar } from 'react-pro-sidebar';
import patientServiceInstance from '../../api/PatientService';
import prescriptionService from '../../api/PrescriptionService';
import { AG_GRID_PAGINATION_PAGE_SIZE } from '../../common/constants';
import { calculatePatientAge, formatDate } from '../../common/datetime-utils';
import AddPatientModal from '../../components/AddPatientModal/AddPatientModal';
import { LoadingOverlay } from '../../components/LoadingOverlay';
import { PatientsScreenProps } from '../../navigation/RootNavigation';
import { useAppStateStore } from '../../store/app-store';
import PatientDetailSidebar from './PatientDetailSidebar';
import usePatientStore from './patient-store';
import { GridSearch, SelectedRow } from './patient-types';
import { getPMSConfig } from '../settings/pms_integrations/pms-integrations-settings-actions';
import { usePMSIntegrationsState } from '../settings/pms_integrations/pms-integration-settings-store';
import { Icon } from 'assets/components/icon';
import { EmptyPmsStateIcon } from 'assets/icons';
import { Text } from 'assets/components/text';

const Patients: React.FC<PatientsScreenProps> = ({ route, navigation }) => {
  const newPatentModal = route.params?.newPatentModal;
  const theme = useTheme();
  const { locationId, pharmacyId } = useAppStateStore();
  const {
    sidebarDetails,
    setSidebarDetails,
    createdPatientCount,
    updatedPatientCount,
    removeSidebarDetails,
  } = usePatientStore();
  const { collapseSidebar } = useProSidebar();
  const [showAddPatientModal, setShowAddPatientModal] = useState(false);
  const [gridApi, setGridApi] = useState<GridApi | undefined>();
  const [loading, setLoading] = useState(true);
  const isFocused = useIsFocused();
  const { pmsConfigurationExists, status } = usePMSIntegrationsState();
  const styles = useStyles();

  const newDataSource: IServerSideDatasource = useMemo(() => {
    return {
      getRows: async (params: IServerSideGetRowsParams) => {
        const { request, success } = params;
        const gridSearch: GridSearch | undefined =
          request.filterModel && request.filterModel[GLOBAL_SEARCH_FIELD];
        let sort = undefined;
        if (request.sortModel.length > 0) {
          sort = request.sortModel[0].colId + ':' + request.sortModel[0].sort;
        }
        setLoading(true);
        removeSidebarDetails();
        const data = await patientServiceInstance
          .findAllLocationsRecordsById(locationId, {
            offset: request.startRow,
            limit: AG_GRID_PAGINATION_PAGE_SIZE,
            search: gridSearch?.filter,
            orderBy: sort,
          })
          .finally(() => {
            setLoading(false);
          });
        success({ rowData: data.items, rowCount: data.count });
      },
    };
  }, [locationId, createdPatientCount]);

  useEffect(() => {
    void getPMSConfig(locationId);
  }, [locationId]);

  useEffect(() => {
    if (!isFocused) {
      gridApi?.setFilterModel(null);
      gridApi?.clearFocusedCell();
      if (sidebarDetails) {
        setSidebarDetails(undefined);
      }
    }
  }, [gridApi, isFocused]);

  useEffect(() => {
    if (gridApi && sidebarDetails?.patientNumberInList !== undefined) {
      const gridModel = gridApi.getModel();
      const row = gridModel.getRow(sidebarDetails.patientNumberInList);
      row?.setData(sidebarDetails.locationPatientRecord);
    }
  }, [updatedPatientCount]);

  useEffect(() => {
    if (newPatentModal === 'open') setShowAddPatientModal(true);
  }, [newPatentModal]);

  const fillSideBarDetails = async (selectedRow: SelectedRow) => {
    const patientPrescriptions = await prescriptionService.findPrescriptions(
      locationId,
      selectedRow.patient.id,
    );

    setSidebarDetails({
      patientNumberInList:
        selectedRow.rowIndex !== null ? selectedRow.rowIndex : undefined,
      locationPatientRecord: selectedRow.patient,
      patientPrescriptions: patientPrescriptions,
    });
  };

  const onCollapseSidebar = () => {
    removeSidebarDetails();
  };

  const handleAddPatientModalVisibility = (show: boolean) => {
    if (show === true) {
      setShowAddPatientModal(true);
      navigation.setParams({ newPatentModal: 'open' });
    } else {
      setShowAddPatientModal(false);
      navigation.setParams({ newPatentModal: undefined });
    }
  };

  return (
    <PharmacyScreenContainer>
      {status !== 'success' ? (
        <LoadingOverlay />
      ) : pmsConfigurationExists === true ? (
        <>
          {loading && <LoadingOverlay />}
          <View style={{ flex: 1 }}>
            <DataGrid
              gridOptions={{
                columnDefs: columnDefs,
                enableCellTextSelection: true,
                suppressMovableColumns: true,
                suppressContextMenu: true,
                defaultColDef: { sortable: true, menuTabs: [] },
                loadingOverlayComponent: 'loadingIndicator',
                loadingOverlayComponentParams: {
                  color: theme.colors.brandedPrimary,
                },
                components: {
                  loadingIndicator: LoadingIndicator,
                },
                rowSelection: 'single',
                suppressCellFocus: true,
                colResizeDefault: 'shift',
                rowModelType: 'serverSide',
                serverSideStoreType: 'partial',
                serverSideDatasource: newDataSource,
                paginationPageSize: AG_GRID_PAGINATION_PAGE_SIZE,
                cacheBlockSize: AG_GRID_PAGINATION_PAGE_SIZE,
                maxBlocksInCache: 2,
                pagination: true,
                onRowClicked(event: RowClickedEvent) {
                  collapseSidebar(false);
                  void fillSideBarDetails({
                    rowIndex: event.node.rowIndex,
                    patient: event.node.data,
                  });
                },
                onGridReady(event) {
                  if (!gridApi) {
                    setGridApi(event.api);
                  }
                },
              }}
              gridToolbarProps={{
                titleProps: {
                  title: getText('patients'),
                },
                inputSearchProps: {
                  size: 'lg',
                  placeholder: getText('first-name-last-name-dob'),
                },
                // In accordance with Jira issue BLUES-4662, we need to hide functionalities that have not yet been implemented.
                // actionButtonsProps: {
                //   maxActionToShow: 2,
                //   actionButtons: [
                //     {
                //       text: getText('upload-csv'),
                //       hierarchy: 'secondary-gray',
                //       logger: { id: 'new-patient-csv-button' },
                //       onPress: () => alert('Not implemented yet'),
                //     },
                //     {
                //       text: getText('new-patient'),
                //       hierarchy: 'primary',
                //       logger: { id: 'new-patient-button' },
                //       onPress: () => handleAddPatientModalVisibility(true),
                //       style: { marginRight: theme.getSpacing(1) },
                //     },
                //   ],
                // },
              }}
            />
          </View>
          {sidebarDetails && (
            <PatientDetailSidebar onCollapse={onCollapseSidebar} />
          )}
          <AddPatientModal
            show={showAddPatientModal}
            setShowAddPatientModal={handleAddPatientModalVisibility}
            locationId={locationId}
            pharmacyId={pharmacyId}
          />
        </>
      ) : (
        <View style={styles.emptyCellContainer}>
          <Icon icon={EmptyPmsStateIcon} size={263} />
          <Text style={styles.titleText}>{getText('no-patients-yet')}</Text>
          <Text>{getText('integrate-pms-to-see-patients')}</Text>
        </View>
      )}
    </PharmacyScreenContainer>
  );
};

const FirstNameRenderer = (props: { data: LocationPatientRecordDto }) => {
  const styles = useStyles();
  const theme = useTheme();
  const patientRecord = props.data ?? {};

  return (
    <View style={styles.cellContainer}>
      <View
        style={{ justifyContent: 'center', marginRight: theme.getSpacing(1) }}
      >
        <Avatar
          firstName={patientRecord.first_name}
          lastName={patientRecord.last_name}
          size={32}
        />
      </View>
      <div>{patientRecord.first_name}</div>
    </View>
  );
};

const BirthDateRenderer = (props: { data: LocationPatientRecordDto }) => {
  const patientRecord = props.data ?? {};

  return (
    <div>
      {patientRecord.date_of_birth &&
        `${formatDate(patientRecord.date_of_birth)} (${calculatePatientAge(
          patientRecord.date_of_birth,
        )})`}
    </div>
  );
};

const LastNameRenderer = (props: { data: LocationPatientRecordDto }) => {
  const patientRecord = props.data ?? {};

  return <div>{patientRecord.last_name}</div>;
};

const columnDefs: Array<ColDef | ColGroupDef> = [
  {
    field: 'first_name',
    headerName: getText('first-name-edit-user'),
    cellRenderer: FirstNameRenderer,
    colId: 'first_name',
  },
  {
    field: 'last_name',
    headerName: getText('last-name-edit-user'),
    cellRenderer: LastNameRenderer,
    colId: 'last_name',
  },
  {
    field: 'date_of_birth',
    headerName: getText('dob-age'),
    cellRenderer: BirthDateRenderer,
  },
];

const useStyles = makeStyles((theme) => ({
  cellContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },
  emptyCellContainer: {
    flex: 1,
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
  },
  titleText: {
    ...theme.lumistryFonts.text.xLarge.semiBold,
    color: theme.palette.gray[900],
    marginBottom: theme.getSpacing(0.5),
  },
  subtitleText: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.gray[700],
  },
}));

export default Patients;
