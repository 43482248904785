import { getText } from 'assets/localization/localization';
import { useToast } from '../hooks/useToast';
import { logError } from 'assets/logging/logger';

type HandleErrorParams = {
  userMessage: string; // User facing message
  error?: unknown; // the original error. Is returned for catch and rethrow
  errorTitle?: string; // a short error title, defaults to "Error"
  internalError?: string; // internal message for debugging.
  context?: object; // additional debugging context
  rethrow?: boolean;
  showToast?: boolean;
  logToSentry?: boolean;
  type?: 'error' | 'warning';
};

export const handleError = ({
  userMessage,
  errorTitle = getText('error'),
  internalError = userMessage,
  error = new Error(internalError),
  context,
  rethrow = false,
  showToast = true,
  logToSentry = true,
  type = 'error',
}: HandleErrorParams) => {
  const consoleMethod = type === 'error' ? 'error' : 'warn';
  console[consoleMethod](internalError, context, error);

  let assertedError: Error;

  if (!error) {
    assertedError = new Error('Unknown error');
  }

  if (typeof error === 'string') {
    assertedError = new Error(error);
  }

  if (error instanceof Error) {
    assertedError = error;
  } else {
    assertedError = new Error(JSON.stringify(error));
  }

  logToSentry &&
    logError(
      new Error(
        `${internalError} : ${JSON.stringify(context) || 'no context'}`,
      ),
    );

  if (showToast) {
    const { toast } = useToast();
    toast(errorTitle || getText('error'), {
      type,
      content: userMessage,
    });
  }

  if (rethrow) {
    throw assertedError;
  }

  return assertedError;
};
