import create from 'zustand';
import {
  RefillSidebarDetails,
  ViewSidebarState as RefillsState,
} from './refills-types';

// create state store
const useRefillsStore = create<RefillsState>((set) => ({
  failedRefillsCount: undefined,
  setFailedRefillsCount: (count: number | undefined) => {
    set((state) => ({
      ...state,
      failedRefillsCount: count,
    }));
  },
  showSidebar: false,
  setShowSidebar: (showSidebar) =>
    set((state) => ({
      ...state,
      showSidebar: showSidebar ? true : false,
    })),
  sidebarDetails: undefined,
  setSidebarDetails: (sidebarDetails: RefillSidebarDetails) =>
    set((state) => ({
      ...state,
      sidebarDetails: sidebarDetails,
    })),
}));

export default useRefillsStore;
