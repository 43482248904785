import { useAutomationModalState } from './automation-modal-store';

export const updateShowModalState = (
  showModal: boolean,
  automationId?: string,
) => {
  useAutomationModalState.setState({
    showModal: showModal,
    automationId: automationId ? automationId : '',
  });
};
