import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path, Polyline, Rect, Line } from 'react-native-svg';
import { IconProps } from './types';

export const StarIcon: FunctionComponent<PropsWithChildren<IconProps>> = ({
  color,
  size,
  testID,
  strokeWidth,
}) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
      testID={testID}
    >
      <Path d="m12 2 3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z" />
    </Svg>
  );
};
