import { getText } from 'assets/localization/localization';
import PharmacyService from '../../../api/PharmacyService';
import { useInternalUseState as internalUseState } from './internal-use-store';
import { logError } from 'assets/logging/logger';
import UnifiedCommsService from '../../../api/UnifiedCommsService';

export const setInternalUseBillableUsage = (locationId: string) => {
  void PharmacyService.pricingFind(locationId).then((internalUse) => {
    internalUseState.setState({
      internalUseBillableUsage: [
        {
          name: getText('outbound-sms-cap'),
          shortName: 'smsCap',
          value: internalUse.location_sms_cap,
        },
        {
          name: getText('outbound-email-cap'),
          shortName: 'emailCap',
          value: internalUse.location_email_cap,
        },
      ],
    });
  });
};

export const setInternalUseNonBillableEmailUsage = (locationId: string) => {
  void UnifiedCommsService.getEmailRecordFindCurrentMonthUsagePlatform(
    locationId,
  ).then((nonBillableEmailInternalUse) => {
    internalUseState.setState((prevState) => ({
      internalUseNonBillableUsage: [
        ...prevState.internalUseNonBillableUsage.filter(
          (usage) => usage.shortName !== 'emailCap',
        ),
        {
          name: getText('outbound-email-used'),
          shortName: 'emailCap',
          value: nonBillableEmailInternalUse.email_quantity,
          nonBillable: true,
        },
      ],
    }));
  });
};

export const setInternalUseNonBillableSmsUsage = (locationId: string) => {
  void UnifiedCommsService.getSmsRecordFindCurrentMonthUsagePlatform(
    locationId,
  ).then((nonBillableInternalUse) => {
    internalUseState.setState((prevState) => ({
      internalUseNonBillableUsage: [
        ...prevState.internalUseNonBillableUsage.filter(
          (usage) => usage.shortName !== 'smsCap',
        ),
        {
          name: getText('outbound-sms-used'),
          shortName: 'smsCap',
          value: nonBillableInternalUse.sms_quantity,
          nonBillable: true,
        },
      ],
    }));
  });
};

export const updateLocationUsageCaps = (
  locationId: string,
  smsCap: number,
  emailCap: number,
) => {
  void PharmacyService.pricingUpdate(locationId, {
    location_sms_cap: smsCap,
    location_email_cap: emailCap,
  })
    .then((response: any) => {
      internalUseState.setState({
        internalUseBillableUsage: [
          {
            name: getText('outbound-sms-cap'),
            shortName: 'smsCap',
            value: response.location_sms_cap,
          },
          {
            name: getText('outbound-email-cap'),
            shortName: 'emailCap',
            value: response.location_email_cap,
          },
        ],
      });
    })
    .catch((error: Error) => logError(error));
};
