import create from 'zustand';

export interface BillingOptionsSettingsState {
  billingPlans: any[];
  generalSettings: GeneralSettingsType[];
  smsUsageData?: UsageType;
  emailUsageData?: UsageType;
  loading?: boolean;
}

export const useBillingOptionsSettingsState =
  create<BillingOptionsSettingsState>(() => ({
    billingPlans: [],
    generalSettings: [],
    smsUsageData: undefined,
    emailUsageData: undefined,
    loading: false,
  }));

type GeneralSettingsType = {
  name: string;
  value: string;
};

type UsageType = {
  start_date: string;
  end_date: string;
  quantity: number;
  quantity_cap: number;
  usedTitle: string;
  capTitle: string;
  percentage: number;
};
