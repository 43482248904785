import { FunctionComponent } from 'react';
import { View } from 'react-native';
import { ToolbarSortingDropDown } from 'assets/components/data-grid/data-grid-toolbar';
import { makeStyles, useTheme } from 'assets/theme';
import { CONVERSATIONS_TABS_WIDTH, sortingOptions } from '../data';
import { getText } from 'assets/localization/localization';
import { IConversationsSorting, TextFilters } from 'assets/types/messageTypes';
import {
  InputColor,
  SearchInput,
} from 'assets/components/search-input/SearchInput';

export const ActionsBar: FunctionComponent<ActionsBarProps> = ({
  sorting,
  textFilters,
  setConversationsSorting,
  onPatientFilter,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  return (
    <View style={styles.container}>
      <View style={styles.actions}>
        <ToolbarSortingDropDown
          value={sorting.value}
          onChange={(option) =>
            setConversationsSorting(option as IConversationsSorting)
          }
          options={sortingOptions}
          size="min"
          styles={{
            control: styles.dropDownControl,
            singleValue: styles.dropDownSingleValue,
            dropdownIndicator: styles.dropDownIndicator,
          }}
        />
        {/*
         In accordance with Jira issue BLUES-5168, we need to hide functionalities that have not yet been implemented.
        <View style={styles.rightActions}>
          <TouchableOpacity onPress={notImplementedAlert} disabled={false}>
            <Icon size={14} icon={ArrowUpFromLineIcon}/>
          </TouchableOpacity>
          <TouchableOpacity onPress={onAddPatientClick} disabled={false}>
            <Icon size={16} icon={PlusInCircleIcon}/>
          </TouchableOpacity>
        </View>
        */}
      </View>
      <View style={styles.searchSection}>
        <SearchInput
          onChange={onPatientFilter}
          placeholder={getText('name-dob')}
          inputValue={textFilters.full_name || textFilters.date_of_birth}
          inputColor={InputColor.Bright}
          width={250}
        />
      </View>
    </View>
  );
};

interface ActionsBarProps {
  sorting: IConversationsSorting;
  textFilters: TextFilters;
  isPatientSelected: boolean;
  setConversationsSorting: (
    conversationsSorting: IConversationsSorting,
  ) => void;
  onAddPatientClick: () => void;
  onPatientFilter: (text: string) => void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'column',
  },
  actions: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  rightActions: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 46,
  },
  searchSection: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 8,
    marginBottom: 8,
    width: 250,
  },
  patientSearchIcon: {
    zIndex: 1,
    marginTop: theme.getSpacing(1),
    marginLeft: theme.getSpacing(3),
  },
  patientSearch: {
    height: 36,
    width: CONVERSATIONS_TABS_WIDTH,
    marginLeft: -(theme.getSpacing(4) + theme.getSpacing(1)),
    paddingLeft: theme.getSpacing(4) + theme.getSpacing(1),
  },
  dropDownControl: {
    fontSize: 13,
  },
  dropDownSingleValue: {
    color: theme.palette.gray[900],
  },
  dropDownIndicator: {
    color: theme.palette.gray[900],
  },
}));
