import { IconProps } from 'assets/icons/types';
import React, { FunctionComponent } from 'react';
import Svg, { Path } from 'react-native-svg';

export const OBHourIcon: FunctionComponent<IconProps> = ({
  color,
  size = 100,
  testID,
}) => {
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      testID={testID}
      viewBox="0 0 100 100"
    >
      <Path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={5}
        d="M50 25v25l16.666 8.334m25-8.334c0 23.012-18.654 41.667-41.666 41.667C26.988 91.667 8.333 73.012 8.333 50S26.988 8.334 50 8.334C73.01 8.334 91.666 26.988 91.666 50Z"
      />
    </Svg>
  );
};
