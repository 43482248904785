import {
  AutoSaveWebsiteWizardDto,
  FinalizeWebsiteServiceOptionDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { CheckboxField, CheckboxInputMode } from 'assets/components/checkbox';
import { Modal } from 'assets/components/modal';
import { Text } from 'assets/components/text';
import { Form } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import { chunk } from 'lodash';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  openNextModalId,
  savePatientWebsite,
  setOpenedModal,
} from '../../../actions/checklist-actions';
import { ErrorMessage } from '../../../components/checklist/ErrorMessage';
import { useChecklistStore } from '../../../store/checklist-store';
import { InfoTooltip } from '../../onboarding/components/common/InfoTooltip';
import { ChecklistWebsiteModalProps } from '../types';
import { PatientWebsiteService } from '../utils';

export interface WebsiteService {
  services: FinalizeWebsiteServiceOptionDto[] | undefined;
}

export const PatientWebsiteServicesModal: FunctionComponent<
  ChecklistWebsiteModalProps
> = ({ id, data }) => {
  const currentOpenedModalId = useChecklistStore((x) => x.currentOpenedModalId);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const styles = useStyles();
  const services = data?.website_details?.website_service_options?.sort(
    (a, b) => a.order - b.order,
  );

  const methods = useForm<WebsiteService>({
    defaultValues: {
      services,
    },
  });
  const serviceFields = methods.watch('services');
  const selectedServices = serviceFields?.filter((x) => x.selected);

  useEffect(() => {
    methods.reset({ services });
  }, [services]);

  const handleSubmit = async () => {
    if (!selectedServices || selectedServices.length === 0) {
      setErrorMessage(getText('at-least-one-service'));
      return;
    } else if (selectedServices.length > 6) {
      setErrorMessage(getText('up-to-6-should-select'));
      return;
    }

    try {
      setLoading(true);
      setErrorMessage(undefined);
      const dto: AutoSaveWebsiteWizardDto = {
        ...data,
        website_details: {
          ...data.website_details,
          website_service_options: serviceFields,
        },
      };
      const services = dto.website_details?.website_service_options;

      await savePatientWebsite(dto);
      services && openNextModalId(services, 0);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={getText('website-featured-services')}
      show={currentOpenedModalId === id}
      handleDismissModal={() => setOpenedModal('checklist-setup')}
      showDismissButton
      isScrollable
      size="sm"
      buttons={[
        {
          hierarchy: 'primary',
          text: getText('next'),
          onPress: methods.handleSubmit(handleSubmit),
          logger: { id: 'next-website-services' },
          loading,
        },
        {
          text: getText('back'),
          hierarchy: 'tertiary-gray',
          onPress: () => setOpenedModal('patient-website-template'),
          logger: { id: 'back-website-services' },
        },
      ]}
    >
      <Form methods={methods}>
        <Form.Row>
          <Form.Column>
            <Text style={styles.title}>
              {getText('select-6-services') + '*'}
              <InfoTooltip
                style={styles.tootlip}
                size={20}
                tooltipId="select-6-services"
                text={getText('selected-services-tooltip-text')}
              />
            </Text>
            {errorMessage && <ErrorMessage message={errorMessage} />}
          </Form.Column>
        </Form.Row>

        {chunk(serviceFields, 2).map((group, index) => (
          <Form.Row key={index}>
            {group.map((service, i) => (
              <Form.Column key={service.id}>
                <CheckboxField
                  name={`services.${index * 2 + i}.selected`}
                  label={PatientWebsiteService[service.slug]}
                  mode={CheckboxInputMode.FLAT}
                />
              </Form.Column>
            ))}
          </Form.Row>
        ))}
      </Form>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.lumistryFonts.text.medium.regular,
    color: theme.palette.gray[700],
  },
  tootlip: {
    left: theme.getSpacing(1),
    top: theme.getSpacing(0.5),
  },
}));
