import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Svg, Path } from 'react-native-svg';
import { IconProps } from './types';

export const CornerUpLeftIcon: FunctionComponent<
  PropsWithChildren<IconProps>
> = ({ color, size, testID, strokeWidth }) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
      testID={testID}
    >
      <Path
        d="M9 14L4 9M4 9L9 4M4 9H16C17.0609 9 18.0783 9.42143 18.8284 10.1716C19.5786 10.9217 20 11.9391 20 13V20"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
