import { ColorPair } from '@digitalpharmacist/pharmacy-service-client-axios';
import { Modal } from 'assets/components/modal';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Pressable, View } from 'react-native';
import {
  fetchMediaColors,
  setBrandColor,
  setOpenedModal,
} from '../../../actions/checklist-actions';
import { useChecklistStore } from '../../../store/checklist-store';
import { ChecklistModalProps } from '../types';

export const ColorPickerModal: FunctionComponent<ChecklistModalProps> = ({
  id,
}) => {
  const [colorList, setColorList] = useState<ColorPair[]>([]);
  const { currentOpenedModalId, brandColor: defaultSelectedColor } =
    useChecklistStore();
  const [selectedColor, setSelectedColor] = useState<string>();
  const styles = useStyles();

  useEffect(() => {
    void (async () => {
      const data = await fetchMediaColors();
      if (data) {
        const sortedData = data.sort((a, b) => a.order - b.order);
        setColorList(sortedData);
      }
    })();
  }, []);

  useEffect(() => {
    setSelectedColor(defaultSelectedColor);
  }, [defaultSelectedColor]);

  const handleSave = () => {
    setBrandColor(selectedColor);
    setOpenedModal('media-settings');
  };

  const handleCloseModal = () => setOpenedModal('media-settings');

  return (
    <Modal
      title={getText('select-brand-color')}
      handleDismissModal={handleCloseModal}
      show={currentOpenedModalId === id}
      size="lg"
      buttons={[
        {
          hierarchy: 'primary',
          text: getText('ok'),
          onPress: handleSave,
          logger: { id: 'close-checklist-media-settings' },
        },
        {
          text: getText('cancel'),
          hierarchy: 'tertiary-gray',
          onPress: handleCloseModal,
          logger: { id: 'close-media-settings-modal' },
        },
      ]}
    >
      <View style={styles.container}>
        {colorList.map((color) => (
          <View key={color.primaryColor} style={[styles.colorContainer]}>
            <Pressable
              onPress={() => setSelectedColor(color.primaryColor)}
              style={[styles.color, { backgroundColor: color.primaryColor }]}
            />
            {selectedColor === color.primaryColor && (
              <View style={styles.selectedContainer} />
            )}
          </View>
        ))}
      </View>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    gap: theme.getSpacing(1.5),
    paddingBottom: theme.getSpacing(1.5),
    paddingLeft: theme.getSpacing(1.5),
    paddingTop: theme.getSpacing(0.5),
  },
  colorContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  selectedContainer: {
    position: 'absolute',
    width: 60,
    height: 60,
    borderWidth: 5,
    borderRadius: theme.roundness,
    borderColor: theme.palette.primary[600],
  },
  color: {
    width: 42,
    height: 42,
    borderRadius: 6,
  },
}));
