import React, { useEffect } from 'react';
import { Modal } from 'assets/components/modal';
import shallow from 'zustand/shallow';
import {
  BulkActionType,
  useTasksDataTableState,
} from './tasks-data-table-store';
import {
  executeBulkAction,
  removeBulkAction,
} from './tasks-data-table-actions';
import { View } from 'react-native';
import { Form } from 'assets/layout';
import { AdvancedDropDownField } from '../../components/advanced-dropdown';
import { useForm } from 'assets/form';
import { useTaskModalState } from '../task-modal/task-modal-store';
import {
  UserOptionTemplate,
  UserSingleValueTemplate,
  AssigneePlaceholderTemplate,
} from '../../components/advanced-dropdown/templates/advanced-drop-down-field.templates';
import { LoadingOverlay } from '../../components/LoadingOverlay';
import { getText } from '../../../../../packages/assets/localization/localization';
import { DetailedUser } from '@digitalpharmacist/role-service-client-axios';

interface SelectAssigneeForm {
  assignee: DetailedUser | undefined;
}

export default function BulkAssigneeDialog() {
  const { bulkAction, gridApi } = useTasksDataTableState(
    (state) => ({
      bulkAction: state.bulkAction,
      gridApi: state.gridApi,
    }),
    shallow,
  );

  const affectedItemsCount =
    bulkAction?.affectedIds?.length ?? gridApi?.paginationGetRowCount();

  const { status: assigneeOptionsStatus, assigneeOptions } =
    useTaskModalState();

  const methods = useForm<SelectAssigneeForm>({
    defaultValues: {
      assignee: undefined,
    },
  });

  const handleSubmit = async () => {
    const selectedAssignee = methods.getValues().assignee;
    bulkAction!.modifier = selectedAssignee!.userId;
    bulkAction!.modifierName = `${selectedAssignee?.firstName} ${selectedAssignee?.lastName}`;
    executeBulkAction(bulkAction!);
  };

  const isLoadingInProgress = assigneeOptionsStatus === 'loading';

  useEffect(() => {
    methods.setValue('assignee', undefined);
  }, [bulkAction]);

  return (
    <Modal
      title={
        bulkAction &&
        getText('bulk-assignee-confirmation-modal', {
          count: affectedItemsCount,
          type:
            affectedItemsCount && affectedItemsCount > 1
              ? getText('tasks')
              : getText('task'),
        })
      }
      size="sm"
      cancelButtonProps={{
        onPress: () => removeBulkAction(),
        logger: { id: 'task-bulk-assignee-cancel-button-modal' },
      }}
      okButtonProps={{
        onPress: methods.handleSubmit(handleSubmit),
        disabled: isLoadingInProgress,
        hierarchy: 'primary',
        logger: { id: 'task-bulk-assignee-ok-button-modal' },
      }}
      show={!!bulkAction && bulkAction.type === BulkActionType.ASSIGNEE}
    >
      <View>
        {isLoadingInProgress && <LoadingOverlay />}
        <Form methods={methods}>
          <Form.Row>
            <Form.Column>
              <View style={{ width: '100%' }}>
                <AdvancedDropDownField
                  name="assignee"
                  label={getText('assignee')}
                  isMulti={false}
                  options={assigneeOptions}
                  menuPortalTarget={document.body}
                  getOptionValue={(optionValue) => optionValue.userId}
                  getOptionLabel={(optionValue) =>
                    `${optionValue.firstName} ${optionValue.lastName}`
                  }
                  isSearchable
                  optionTemplate={UserOptionTemplate}
                  singleValueTemplate={UserSingleValueTemplate}
                  placeholderTemplate={AssigneePlaceholderTemplate}
                  rules={{
                    required: getText('assignee-required'),
                  }}
                />
              </View>
            </Form.Column>
          </Form.Row>
        </Form>
      </View>
    </Modal>
  );
}
