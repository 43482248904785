import { makeStyles } from 'assets/theme';

export const useBulkTableStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.gray[700],
  },
  iconAndTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
    gap: theme.getSpacing(1),
  },
}));
