import { Icon } from 'assets/components/icon';
import { AlertTriangleIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import React, { FunctionComponent } from 'react';
import { Text, View } from 'react-native';

export const OBGenericError: FunctionComponent = () => {
  const theme = useTheme();
  const styles = useStyles();

  return (
    <View style={styles.root}>
      <Icon icon={AlertTriangleIcon} size={70} color={theme.colors.error} />
      <Text style={styles.text}>{getText('ob-something-went-wrong')}</Text>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.getSpacing(3),
  },
  text: {
    ...theme.lumistryFonts.display,
    color: theme.colors.error,
  },
}));
