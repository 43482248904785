import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Text } from 'assets/components/text';
import { TextField } from 'assets/components/text-field';
import { useForm } from 'assets/form';
import { TouchableOpacity, View } from 'react-native';
import { makeStyles, useTheme } from 'assets/theme';
import { Button } from 'assets/components/button';
import { useUpdatePasswordState } from './update-password-store';
import { UpdatePasswordForm } from './update-password-actions';
import { useNavigation } from '@react-navigation/native';
import { RootStackNavigationProp } from '../../navigation/RootNavigation';
import { updatePassword } from './update-password-actions';
import { UpdatePasswordTestIDs } from './UpdatePasswordTestIDs';
import { PasswordRules } from 'assets/common/PasswordRules';
import { passwordRegex } from '../../common/form-utils';
import { Form, PharmacyScreenContainer } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { useUserState } from '../../store/user-store';

export const UpdatePassword: FunctionComponent<
  PropsWithChildren<UpdatePasswordProps>
> = () => {
  const theme = useTheme();
  const styles = useStyles();
  const { data } = useUserState();

  const methods = useForm<UpdatePasswordForm>({
    defaultValues: {
      email: data?.email,
      password: '',
      passwordConfirm: '',
    },
  });

  const passwordValue = methods.watch('password');
  const { status, error } = useUpdatePasswordState();
  const navigation = useNavigation<RootStackNavigationProp>();

  const handleSubmit = async () => {
    updatePassword(methods.getValues(), navigation);
  };

  return (
    <PharmacyScreenContainer small>
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
        }}
      >
        <View>
          <Text
            style={{
              color: theme.palette.gray[900],
              fontSize: 30,
              paddingTop: theme.getSpacing(2),
              paddingBottom: theme.getSpacing(1),
              textAlign: 'left',
            }}
            selectable
          >
            {getText('change-password')}
          </Text>
          <Text
            style={{
              color: theme.palette.gray[600],
              fontSize: 16,
              paddingBottom: theme.getSpacing(2),
              paddingTop: theme.getSpacing(2),
              textAlign: 'left',
            }}
            selectable
          >
            {getText('for-security-reasons')}
          </Text>
        </View>
        <Form methods={methods}>
          <Form.Alert title={error?.message} intent="error" visible={!!error} />
          <Form.Row>
            <Form.Column>
              <TextField
                testID={UpdatePasswordTestIDs.passwordInput}
                label={getText('new-password')}
                name="password"
                rules={{
                  required: getText('password-is-required'),
                  pattern: {
                    value: passwordRegex,
                    message: getText('password-does-not-meet-criteria'),
                  },
                }}
                onSubmit={methods.handleSubmit(handleSubmit)}
                disabled={status === 'loading'}
                type="password"
              />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column>
              <TextField
                testID={UpdatePasswordTestIDs.passwordConfirmInput}
                label={getText('confirm-update-password')}
                name="passwordConfirm"
                rules={{
                  required: getText('password-is-required'),
                  validate: {
                    value: () => {
                      return methods.getValues().password ===
                        methods.getValues().passwordConfirm
                        ? true
                        : getText('passwords-do-not-match');
                    },
                  },
                }}
                onSubmit={methods.handleSubmit(handleSubmit)}
                disabled={status === 'loading'}
                type="password"
              />
            </Form.Column>
          </Form.Row>
          <Form.Content>
            <PasswordRules passwordValue={passwordValue}></PasswordRules>
          </Form.Content>
          <Form.Actions>
            <View style={{ marginBottom: theme.getSpacing(2) }}>
              <Button
                onPress={methods.handleSubmit(handleSubmit)}
                hierarchy="primary"
                loading={status === 'loading'}
                logger={{ id: 'pharmacy-create-password-submit-button' }}
                style={styles.submitButton}
                testID={UpdatePasswordTestIDs.submitButton}
              >
                {getText('submit')}
              </Button>
            </View>
            <View
              style={{
                marginBottom: theme.getSpacing(2),
                alignItems: 'flex-start',
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  navigation.navigate('login');
                }}
              >
                <Text
                  style={{
                    color: theme.palette.primary[700],
                    fontSize: 16,
                    marginTop: theme.getSpacing(1),
                    marginBottom: theme.getSpacing(3),
                    alignSelf: 'center',
                  }}
                  selectable
                >
                  {getText('go-to-login')}
                </Text>
              </TouchableOpacity>
            </View>
          </Form.Actions>
        </Form>
      </View>
    </PharmacyScreenContainer>
  );
};

const useStyles = makeStyles((theme) => ({
  submitButton: {
    backgroundColor: theme.palette.primary[600],
    borderColor: theme.palette.primary[600],
    width: 120,
  },
}));

interface UpdatePasswordProps {}
