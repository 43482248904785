import { IconProps } from 'assets/icons/types';
import { useTheme } from 'assets/theme';
import React, { FunctionComponent } from 'react';
import Svg, { Path, G, Defs, Mask, ClipPath, Rect } from 'react-native-svg';

export const OBRocketIllustrationIcon: FunctionComponent<IconProps> = ({
  testID,
}) => {
  const theme = useTheme();
  return (
    <Svg
      width={370}
      height={290}
      viewBox="0 0 370 290"
      testID={testID}
      fill="none"
    >
      <G clipPath="url(#clip0_33597_36063)">
        <Path
          d="M244.968 65.0808C239.139 65.0808 234.411 70.035 234.411 76.1431C234.411 70.035 229.684 65.0808 223.854 65.0808C229.684 65.0808 234.411 60.1267 234.411 54.0186C234.411 60.1267 239.139 65.0808 244.968 65.0808Z"
          fill="#6AC0CC"
        />
        <Path
          d="M104.362 146.233C100.084 146.233 96.6133 149.87 96.6133 154.353C96.6133 149.87 93.1424 146.233 88.8643 146.233C93.1424 146.233 96.6133 142.596 96.6133 138.113C96.6133 142.596 100.084 146.233 104.362 146.233Z"
          fill="#6AC0CC"
        />
        <Path
          d="M129.662 87.5257C123.833 87.5257 119.105 92.4798 119.105 98.588C119.105 92.4798 114.377 87.5257 108.548 87.5257C114.377 87.5257 119.105 82.5715 119.105 76.4634C119.105 82.5715 123.833 87.5257 129.662 87.5257Z"
          fill="#FFC854"
        />
        <Path
          d="M249.915 123.528C245.637 123.528 242.166 127.165 242.166 131.648C242.166 127.165 238.695 123.528 234.417 123.528C238.695 123.528 242.166 119.891 242.166 115.408C242.166 119.891 245.637 123.528 249.915 123.528Z"
          fill="#FFC854"
        />
        <Path
          d="M225.775 37.6155C222.57 37.6155 219.975 40.3343 219.975 43.6934C219.975 40.3343 217.38 37.6155 214.175 37.6155C217.38 37.6155 219.975 34.8968 219.975 31.5376C219.975 34.8968 222.57 37.6155 225.775 37.6155Z"
          fill="#FFC854"
        />
        <Path
          d="M165.922 190.083C167.868 190.083 169.445 188.43 169.445 186.391C169.445 184.352 167.868 182.7 165.922 182.7C163.977 182.7 162.399 184.352 162.399 186.391C162.399 188.43 163.977 190.083 165.922 190.083Z"
          stroke="#FF878B"
          strokeWidth={1.13}
          strokeMiterlimit={10}
        />
        <Path
          d="M223.497 210.516C225.443 210.516 227.02 208.863 227.02 206.824C227.02 204.786 225.443 203.133 223.497 203.133C221.552 203.133 219.975 204.786 219.975 206.824C219.975 208.863 221.552 210.516 223.497 210.516Z"
          stroke="#FF878B"
          strokeWidth={1.13}
          strokeMiterlimit={10}
        />
        <Path
          d="M338.937 289.408C336.452 277.131 326.056 267.918 313.597 267.918C311.758 267.918 309.959 268.117 308.229 268.504C306.701 260.347 299.304 254.161 290.419 254.161C290.35 254.161 290.281 254.161 290.212 254.167C288.372 238.332 274.46 226.013 257.561 226.013C248.405 226.013 240.12 229.632 234.164 235.474C231.137 234.737 227.966 234.344 224.702 234.344C221.439 234.344 218.562 234.701 215.668 235.377C215.674 235.033 215.679 234.694 215.679 234.344C215.679 223.578 210.45 214.092 202.493 208.528C203.22 206.516 203.606 204.347 203.606 202.076C203.606 198.378 202.563 194.934 200.769 192.052C199.801 190.5 199.512 188.615 199.882 186.796C200.083 185.799 200.187 184.766 200.187 183.709C200.187 175.226 193.378 168.417 185.168 168.955C178.249 169.408 172.564 175.232 172.005 182.476C171.889 183.975 171.987 185.425 172.27 186.808C172.645 188.639 172.322 190.53 171.353 192.101C169.577 194.971 168.545 198.402 168.545 202.076C168.545 203.048 168.615 204.003 168.759 204.933C169.099 207.211 168.222 209.495 166.568 211.011C161.915 215.283 158.554 221.064 157.17 227.626C150.781 223.107 142.906 220.442 134.384 220.442C113.051 220.442 95.7658 237.147 95.7658 257.749C95.7658 258.239 95.7716 258.728 95.8004 259.212C92.6812 258.263 89.366 257.749 85.9239 257.749C67.8141 257.749 53.129 271.911 53.0771 289.402H338.925L338.937 289.408Z"
          fill="#CFB3AE"
        />
        <Path
          d="M340.897 290C337.553 276.992 327.515 267.924 313.597 267.924C313.597 267.924 310.178 268.111 308.229 268.51C313.614 261.586 296.75 246.959 290.419 254.167C287.352 240.646 272.938 230.949 258.42 230.949C245.649 230.949 231.506 244.186 238.626 262.1C238.626 262.1 228.917 251.249 218.303 251.974C204.517 252.916 199.881 258.589 193.389 270.256C185.98 258.342 173.279 255.393 158.721 256.306C145.984 257.109 134.044 268.468 134.044 268.468C149.288 250.5 157.539 230.713 125.222 226.925C109.021 225.022 95.6504 240.507 95.1488 257.46C92.439 256.777 89.6196 256.414 86.708 256.414C68.183 256.414 52.9387 271.12 51.0303 290H340.891H340.897Z"
          fill="#FF878B"
        />
        <Path
          d="M223.07 255.2C223.07 262.794 217.195 268.951 209.936 268.951C206.229 268.951 202.879 267.338 200.492 264.746C197.327 268.437 192.732 270.763 187.606 270.763C178.035 270.763 170.286 262.643 170.286 252.614C170.286 242.585 178.035 234.459 187.606 234.459C194.092 234.459 199.737 238.193 202.706 243.715C204.782 242.277 207.267 241.443 209.936 241.443C217.189 241.443 223.07 247.6 223.07 255.194V255.2Z"
          stroke="#FF878B"
          strokeWidth={1.13}
          strokeMiterlimit={10}
        />
        <Path
          d="M289.064 233.758C293.21 241.932 290.258 252.076 282.457 256.42C274.656 260.764 264.976 257.659 260.83 249.484C259.579 247.013 258.974 244.367 258.962 241.745C255.751 241.189 252.822 239.129 251.179 235.885C248.376 230.363 250.371 223.505 255.641 220.569C260.911 217.633 267.455 219.729 270.257 225.251C270.303 225.348 270.361 225.451 270.401 225.547C277.545 223.318 285.426 226.593 289.059 233.746L289.064 233.758Z"
          stroke="#FF878B"
          strokeWidth={1.13}
          strokeMiterlimit={10}
        />
        <Path
          d="M89.7002 242.694C94.2123 242.694 97.8701 238.861 97.8701 234.133C97.8701 229.405 94.2123 225.572 89.7002 225.572C85.1881 225.572 81.5303 229.405 81.5303 234.133C81.5303 238.861 85.1881 242.694 89.7002 242.694Z"
          stroke="#FF878B"
          strokeWidth={0.98}
          strokeMiterlimit={10}
        />
        <Path
          d="M159.989 222.949C163.266 222.949 165.922 220.166 165.922 216.733C165.922 213.299 163.266 210.516 159.989 210.516C156.713 210.516 154.057 213.299 154.057 216.733C154.057 220.166 156.713 222.949 159.989 222.949Z"
          stroke="#FF878B"
          strokeWidth={0.98}
          strokeMiterlimit={10}
        />
        <Path
          d="M208.2 164.406H162.393V76.9046C162.393 72.2041 171.427 36.2441 184.435 36.2441C197.442 36.2441 208.2 72.4096 208.2 76.9046V164.406Z"
          fill="#0063B2"
        />
        <Path
          d="M194.738 173.445H175.862C172.806 173.445 170.719 170.992 171.866 168.744L174.08 164.406H196.26L198.665 168.617C199.956 170.877 197.869 173.445 194.738 173.445Z"
          fill="#5CC4FF"
        />
        <Path
          d="M141.983 164.406H162.393V97.2285L136.54 157.748C135.156 160.986 137.952 164.412 141.977 164.412L141.983 164.406Z"
          fill="#5CC4FF"
        />
        <Path
          d="M228.617 164.406H208.207V97.2285L234.06 157.748C235.444 160.986 232.648 164.412 228.623 164.412L228.617 164.406Z"
          fill="#5CC4FF"
        />
        <Mask
          id="mask0_33597_36063"
          maskUnits="userSpaceOnUse"
          x={162}
          y={36}
          width={47}
          height={129}
        >
          <Path
            d="M208.2 164.406H162.393V76.9046C162.393 72.2041 171.427 36.2441 184.435 36.2441C197.442 36.2441 208.2 72.4096 208.2 76.9046V164.406Z"
            fill="white"
          />
        </Mask>
        <G mask="url(#mask0_33597_36063)">
          <Path
            d="M162.087 76.6084H176.801"
            stroke="#C9DBE6"
            strokeWidth={1.13}
            strokeMiterlimit={10}
          />
          <Path
            d="M193.734 76.6084H208.2"
            stroke="#C9DBE6"
            strokeWidth={1.13}
            strokeMiterlimit={10}
          />
          <Path
            d="M162.398 152.54H208.201"
            stroke="#C9DBE6"
            strokeWidth={1.13}
            strokeMiterlimit={10}
          />
          <Path
            d="M208.2 106.327H200.319C198.52 106.327 197.055 107.856 197.055 109.747V119.172C197.055 121.057 195.597 122.591 193.792 122.591H188.413C186.602 122.591 185.138 124.138 185.149 126.035L185.271 141.308C185.288 143.187 186.741 144.698 188.534 144.698H208.2"
            stroke="#C9DBE6"
            strokeWidth={1.13}
            strokeMiterlimit={10}
            strokeLinecap="round"
          />
          <Path
            d="M162.398 106.327H183.708C185.507 106.327 186.971 107.856 186.971 109.747V114.103C186.971 115.988 185.513 117.522 183.708 117.522H177.942C176.144 117.522 174.679 119.051 174.679 120.942V131.436C174.679 133.321 173.22 134.856 171.416 134.856H162.393"
            stroke="#C9DBE6"
            strokeWidth={1.13}
            strokeMiterlimit={10}
            strokeLinecap="round"
          />
        </G>
        <Path
          d="M185.3 88.1298C191.372 88.1298 196.295 82.9715 196.295 76.6084C196.295 70.2452 191.372 65.0869 185.3 65.0869C179.227 65.0869 174.305 70.2452 174.305 76.6084C174.305 82.9715 179.227 88.1298 185.3 88.1298Z"
          stroke="#C9DBE6"
          strokeWidth={1.44}
          strokeMiterlimit={10}
        />
        <Path
          d="M185.3 86.4441C190.484 86.4441 194.686 82.0405 194.686 76.6083C194.686 71.1761 190.484 66.7725 185.3 66.7725C180.116 66.7725 175.913 71.1761 175.913 76.6083C175.913 82.0405 180.116 86.4441 185.3 86.4441Z"
          fill="#6AC0CC"
        />
        <Path
          d="M181.61 83.6168C177.886 81.5082 176.496 76.6326 178.508 72.7297C180.521 68.8268 185.173 67.3707 188.898 69.4793"
          fill="white"
        />
        <Path
          d="M319.42 272.244C312.536 272.244 306.222 274.787 301.281 279.016C298.289 271.555 291.249 266.317 283.033 266.317C275.78 266.317 269.432 270.407 265.99 276.503C260.674 273.778 254.689 272.244 248.37 272.244C239.445 272.244 231.206 275.295 224.541 280.454C222.056 274.014 216.036 269.482 208.996 269.482C201.357 269.482 194.911 274.823 192.882 282.134C186.88 273.325 177.043 267.579 165.921 267.579C154.8 267.579 145.211 273.18 139.192 281.795C135.277 278.889 130.503 277.18 125.343 277.18C119.001 277.18 113.235 279.765 108.951 283.976C104.12 271.706 92.5827 263.072 79.1257 263.072C69.6586 263.072 61.1485 267.35 55.2445 274.153C53.8838 273.863 52.4828 273.712 51.0414 273.712C41.3321 273.712 33.1679 280.617 30.7637 290H345.584C341.104 279.536 331.078 272.244 319.414 272.244H319.42Z"
          fill="#804447"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_33597_36063">
          <Rect
            width={369}
            height={290}
            fill="white"
            transform="translate(0.5)"
          />
        </ClipPath>
      </Defs>
    </Svg>
  );
};
