import { CopyToClipboard } from 'assets/components/copy-to-clipboard';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import { FunctionComponent, PropsWithChildren } from 'react';
import { Text, View } from 'react-native';
import { PATIENT_APP_BASE_URL } from '../../../../../modules/common/constants';
import { canFactory } from '../../../../common/guards/permission-guard';
import { useToast } from '../../../../common/hooks/useToast';
import { RowItemRender } from '../../../../components/Settings/RowItemRender';
import { SettingsTooltipsComponents } from '../../../../components/Settings/SettingsTooltipsComponents';

export const WebsiteItemRenderer: FunctionComponent<
  PropsWithChildren<WebsiteItemRendererProps>
> = ({
  url,
  company,
  space_craft_id,
  pid,
  default_location_id,
  ga_key,
  account_url,
  refill_url,
  transfer_url,
  pharmacy_slug,
}) => {
  const { toast } = useToast();
  const theme = useTheme();
  const styles = useStyles();
  const canLumistryUserUpdateSettings = canFactory('update')(
    'settingsLumistryUser',
  );

  return (
    <>
      <RowItemRender
        title={getText('url')}
        value={url}
        icon={
          canLumistryUserUpdateSettings ? (
            <></>
          ) : (
            <SettingsTooltipsComponents
              tooltipId="website-settings-url"
              tooltipText={getText('website-settings-url-tooltip')}
            />
          )
        }
      />
      <RowItemRender title={getText('company')} value={company} />
      <RowItemRender title={getText('space-craft-id')}>
        <View style={styles.copyViewStyle}>
          <Text style={styles.valueStyle}>{space_craft_id}</Text>
          {space_craft_id && (
            <CopyToClipboard
              logger={{ id: `copy-spacecraft-id` }}
              stringToCopy={space_craft_id}
              fetchFromClipboard={() => toast('Copied to clipboard')}
              color={theme.palette.primary[600]}
              size={16}
              text={getText('copy-link')}
              style={{ marginLeft: theme.getSpacing(1) }}
            />
          )}
        </View>
      </RowItemRender>
      <RowItemRender title={getText('pid')} value={pid} />
      <RowItemRender
        title={getText('default-location-id')}
        value={default_location_id}
      />
      <RowItemRender title={getText('g-analytics-key')} value={ga_key} />
      <RowItemRender
        title={getText('patient-portal-url')}
        value={`${PATIENT_APP_BASE_URL}/${pharmacy_slug}`}
      />
      <RowItemRender title={getText('account-url')} value={account_url} />
      <RowItemRender title={getText('refill-url')} value={refill_url} />
      <RowItemRender title={getText('transfer-url')} value={transfer_url} />
    </>
  );
};

interface WebsiteItemRendererProps {
  url: string;
  company: string;
  space_craft_id: string;
  pid: string;
  default_location_id: string;
  ga_key: string;
  account_url: string;
  refill_url: string;
  transfer_url: string;
  pharmacy_slug: string;
}

const useStyles = makeStyles((theme) => ({
  copyViewStyle: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  valueStyle: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.gray[700],
  },
}));
