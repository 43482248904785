import { ParentCompanyDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { getText } from 'assets/localization/localization';
import { FunctionComponent, PropsWithChildren } from 'react';
import { RowItemRender } from '../../../../components/Settings/RowItemRender';
import { SettingsTooltipsComponents } from '../../../../components/Settings/SettingsTooltipsComponents';

export const GeneralInfoItemRenderer: FunctionComponent<
  PropsWithChildren<GeneralInfoItemRendererProps>
> = ({
  name,
  full_name,
  parent_company,
  ncpdp_id,
  npi,
  pms,
  facebook_url,
  instagram_handle,
  twitter_handle,
}) => {
  return (
    <>
      <RowItemRender
        title={getText('pharmacy-brand-full-name')}
        value={full_name ?? ''}
      />

      <RowItemRender
        title={getText('pharmacy-company-name')}
        icon={
          <SettingsTooltipsComponents
            tooltipId="pharmacy-general-info-limited-brand-name"
            tooltipText={getText('pharmacy-general-info-limited-brand-name')}
          />
        }
        value={name}
      />

      <RowItemRender
        title={getText('pharmacy-parent-company')}
        value={parent_company?.name ?? ''}
      />
      <RowItemRender title={getText('pharmacy-ncpdp')} value={ncpdp_id ?? ''} />
      <RowItemRender title={getText('pharmacy-npi')} value={npi ?? ''} />
      <RowItemRender title={getText('pharmacy-pms')} value={pms ?? ''} />
      <RowItemRender
        title={getText('pharmacy-facebook')}
        value={facebook_url ?? ''}
      />
      <RowItemRender
        title={getText('pharmacy-instagram')}
        value={instagram_handle ?? ''}
      />
      <RowItemRender
        title={getText('pharmacy-twitter')}
        value={twitter_handle ?? ''}
      />
    </>
  );
};

interface GeneralInfoItemRendererProps {
  name: string;
  full_name?: string;
  parent_company?: ParentCompanyDto;
  ncpdp_id?: string;
  npi?: string;
  pms?: string;
  facebook_url?: string;
  instagram_handle?: string;
  twitter_handle?: string;
}
