import React from 'react';
import { View } from 'react-native';
import {
  ColDef,
  ColGroupDef,
  ICellRendererParams,
} from '@ag-grid-community/core';
import { makeStyles } from 'assets/theme';
import { DataGrid } from 'assets/components/data-grid';
import { Button } from 'assets/components/button';
import { Text } from 'assets/components/text';
import PharmacyService from '../../api/PharmacyService';
import { PharmacistLocationDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { getText } from 'assets/localization/localization';
import {
  DataGridPaginatedRemoteResponse,
  DataGridSearchablePaginatedRemoteRequest,
} from 'assets/components/data-grid/types';
import { useUserState } from '../../store/user-store';
import { AG_GRID_PAGINATION_PAGE_SIZE } from '../../common/constants';
import PharmacistLocationService from '../../api/PharmacistLocationService';

export const PharmacyStoreSelectorGrid: React.FC<
  PharmacyStoreSelectorGridProps
> = ({ onSelect }) => {
  const { data: user } = useUserState();
  const pharmacyStoreSelectorGridCellRendererParams: Partial<PharmacyStoreSelectorGridCellRendererProps> =
    {
      onSelect: onSelect,
    };

  const columnDefs: (ColDef | ColGroupDef)[] = [
    {
      sortable: false,
      suppressMenu: true,
      suppressAutoSize: true,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
      cellRenderer: PharmacyStoreSelectorGridCellRenderer,
      cellRendererParams: pharmacyStoreSelectorGridCellRendererParams,
      width: 750,
    },
  ];

  return (
    <DataGrid
      gridToolbarProps={{ inputSearchProps: { size: 'lg' } }}
      gridOptions={{
        columnDefs: columnDefs, // Column Defs for Columns
        headerHeight: 0,
        rowStyle: { paddingTop: 8 },
        rowHeight: 60,
        // setting pagination and cache block size
        // ag grid default block size is 100
        pagination: true,
        paginationPageSize: AG_GRID_PAGINATION_PAGE_SIZE,
        cacheBlockSize: AG_GRID_PAGINATION_PAGE_SIZE, // default 100
        maxBlocksInCache: 1, // one page, remove to let ag grid to handle itself
        rowModelType: 'serverSide',
        getRowId: (props) => props.data.location_id,
        serverSideDatasource:
          PharmacistLocationService.getPharmacistsLocationsDatasource(
            user?.id!,
          ),
        // optional advanced operations
        context: {
          transformRequest(request: DataGridSearchablePaginatedRemoteRequest) {
            const transformedRequest: DataGridSearchablePaginatedRemoteRequest =
              {
                ...request,
                order_by: 'location_full_display_name:asc',
              };
            return transformedRequest;
          },
          transformResponse(params: { locations: []; total_count: number }) {
            const transformedResponse: DataGridPaginatedRemoteResponse = {
              results: params.locations,
              total: params.total_count,
            };
            return transformedResponse;
          },
        },
        serverSideStoreType: 'partial',
      }}
    />
  );
};

export interface PharmacyStoreSelectorGridProps {
  onSelect?: (data: PharmacistLocationDto) => void;
}

interface PharmacyStoreSelectorGridCellRendererProps
  extends ICellRendererParams {
  onSelect?: (data: PharmacistLocationDto) => void;
}

const PharmacyStoreSelectorGridCellRenderer = (
  props: PharmacyStoreSelectorGridCellRendererProps,
) => {
  const { data, onSelect } = props;
  const model: PharmacistLocationDto = data;
  const styles = useStyles();

  return (
    <View style={styles.cellRendererContainer}>
      <View style={styles.cellRendererAddressContainer}>
        <Text style={styles.cellRendererAddressTextBold}>
          {model.location_full_display_name}
        </Text>
        <Text style={styles.cellRendererAddressTextRegular}>
          {`${model.location_address1}, ${model.location_city}, ${model.location_country} ${model.location_postal_code}`}
        </Text>
      </View>
      <View style={styles.cellRendererActionContainer}>
        <Button
          hierarchy={'secondary-gray'}
          logger={{
            id: 'pharmacy-store-selector-modal-select-action',
            data,
          }}
          onPress={() => onSelect?.(model)}
        >
          {getText('select')}
        </Button>
      </View>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  cellRendererContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 40,
  },
  cellRendererAddressContainer: {
    flexDirection: 'column',
  },
  cellRendererActionContainer: {
    marginLeft: 'auto',
  },
  cellRendererAddressTextBold: {
    color: theme.palette.gray[700],
    fontFamily: 'Lato_700Bold',
    fontSize: 16,
    lineHeight: 24,
  },
  cellRendererAddressTextRegular: {
    color: theme.palette.gray[500],
    fontFamily: 'Lato_400Regular',
    fontSize: 14,
    lineHeight: 20,
  },
}));
