import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { makeStyles } from 'assets/theme';
import { Divider } from 'react-native-paper';

const OBDivider: FunctionComponent = () => {
  const styles = useStyles();
  return (
    <View style={styles.root}>
      <Divider style={styles.divider} />
    </View>
  );
};

export default OBDivider;

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.getSpacing(1),
    paddingBottom: theme.getSpacing(1),
  },
  divider: {
    height: 1,
    backgroundColor: theme.palette.gray[300],
  },
}));
