import React, { FunctionComponent } from 'react';
import { View } from 'react-native';

import { makeStyles } from 'assets/theme';
import { ServicesList } from '../../../schedule/services-list/ServicesList';
import { PharmacyScreenContainer } from 'assets/layout';

export const Services: FunctionComponent<ServicesProps> = (props) => {
  const styles = useStyles();

  return (
    <PharmacyScreenContainer>
      <View style={{ flex: 1 }}>
        <ServicesList />
      </View>
    </PharmacyScreenContainer>
  );
};

interface ServicesProps {}

const useStyles = makeStyles((theme) => ({
  cellContainer: {
    marginTop: theme.getSpacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },
}));
