import {
  DirectMessagePatientDto,
  DirectMessageConversationFiltersDto,
} from '@digitalpharmacist/unified-communications-service-client-axios';
import { initialState, useChatState } from './chat-store';
import {
  MessagesPagination,
  EmittedUpdatedUserStatus,
  ITemplate,
  IMessageExtended,
  Order,
  MessageStatus,
} from 'assets/types/messageTypes';
import { LocationPatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';
import {
  compare,
  getMessages,
  groupConversationsByPatient,
} from 'assets/utils/messageUtils';
import { useConversationsManipulationState } from '../data-manipulation-store/data-manipulation-store';
import UnifiedCommsService from '../../../../api/UnifiedCommsService';
import FileStorageService from '../../../../api/FileStorageService';
import { TAKE } from '../../data';

export const setConversationsGroupedByPatient = (
  conversationsGroupedByPatient: DirectMessagePatientDto[],
) => {
  useChatState.setState({
    conversationsGroupedByPatient: conversationsGroupedByPatient,
  });
};

export const setSelectedPatient = (
  selectedPatient: LocationPatientRecordDto,
) => {
  useChatState.setState({ selectedPatient: selectedPatient });
};

export const removeSelectedPatient = () => {
  useChatState.setState({ selectedPatient: null });
};

export const setSelectedConversation = (
  selectedConversation: DirectMessagePatientDto,
) => {
  useChatState.setState({ selectedConversation: selectedConversation });
};

export const removeSelectedConversation = () => {
  useChatState.setState({ selectedConversation: null });
};

export const setSelectedPatientConversations = (
  selectedPatientConversations: DirectMessagePatientDto[],
) => {
  useChatState.setState({
    selectedPatientConversations: selectedPatientConversations,
  });
};

export const removeSelectedPatientConversations = () => {
  useChatState.setState({ selectedPatientConversations: [] });
};

export const addViewedConversationsToSet = (viewedConversations: string[]) => {
  useChatState.setState((state) => {
    const newState = { ...state };
    const viewedConversationsSet = state.viewedConversationsSet;
    const newViewedConversationsSet = new Set([
      ...viewedConversationsSet,
      ...viewedConversations,
    ]);
    newState.viewedConversationsSet = newViewedConversationsSet;
    return newState;
  });
};

export const removeViewedConversationsFromSet = (
  viewedConversations: string[],
) => {
  useChatState.setState((state) => {
    const newState = { ...state };
    const newViewedConversationsSet = newState.viewedConversationsSet;
    for (const conversationId of viewedConversations) {
      newViewedConversationsSet.delete(conversationId);
    }
    newState.viewedConversationsSet = newViewedConversationsSet;
    return newState;
  });
};

export const setSelectedMessages = (selectedMessages: IMessageExtended[]) => {
  useChatState.setState({ selectedMessages: selectedMessages });
};

export const removeSelectedMessages = () => {
  useChatState.setState({ selectedMessages: [] });
};

export const setRawConversations = (
  rawConversations: DirectMessagePatientDto[],
) => {
  useChatState.setState({ rawConversations: rawConversations });
};

export const openNewChatModal = () => {
  useChatState.setState({ isOpenNewChatModal: true });
};

export const closeNewChatModal = () => {
  useChatState.setState({ isOpenNewChatModal: false });
};

export const openSidebarNewChatModal = () => {
  useChatState.setState({ isOpenSidebarNewChatModal: true });
};

export const closeSidebarNewChatModal = () => {
  useChatState.setState({ isOpenSidebarNewChatModal: false });
};

export const setIncomeMessage = async (
  pharmacyId: string,
  locationId: string,
  locationPatientId: string,
  conversationId: string,
  filterCriteria: DirectMessageConversationFiltersDto,
) => {
  const { sorting } = useConversationsManipulationState.getState();
  const rawConversationsData: DirectMessagePatientDto[] =
    await UnifiedCommsService.getAllFilteredConversations(
      locationId,
      filterCriteria,
    );

  const { failedMessagesInConversation } = useChatState.getState();

  const messagesResult = await getMessages({
    pharmacyId: pharmacyId,
    locationId: locationId,
    locationPatientId: locationPatientId,
    conversationId: conversationId,
    UnifiedCommsService: UnifiedCommsService,
    FileStorageService: FileStorageService,
    failedMessagesInConversation,
    skip: 0,
    take: TAKE,
  });

  const isMessageNew = false;

  useChatState.setState((state) => {
    const newState = {
      ...state,
    };

    const isInSameConversation =
      newState.selectedConversation?.conversation_id === conversationId;
    if (messagesResult.messages.length && isInSameConversation) {
      newState.selectedMessages = [...messagesResult.messages];
    }

    const patientsData = groupConversationsByPatient(rawConversationsData).sort(
      (currentConversation, nextConversation) =>
        compare(
          currentConversation,
          nextConversation,
          sorting.field,
          sorting.order,
          sorting.isDate,
        ),
    );

    const newViewedConversationsSet = new Set(
      rawConversationsData
        .filter((conversation) => conversation.pharmacy_viewed_all_messages)
        .map((conversation) => conversation.conversation_id),
    );

    newState.rawConversations = rawConversationsData;
    newState.viewedConversationsSet = newViewedConversationsSet;
    newState.conversationsGroupedByPatient = patientsData;

    if (newState.selectedPatient) {
      const newSelectedPatientConversations = rawConversationsData
        .filter(
          (conversation) =>
            conversation.location_id === locationId &&
            conversation.location_patient_id === newState.selectedPatient?.id,
        )
        .sort((currentConversation, nextConversation) =>
          compare(
            currentConversation,
            nextConversation,
            'most_recent_qualifying_message_date',
            Order.DESC,
            true,
          ),
        );
      newState.selectedPatientConversations = newSelectedPatientConversations;
    }

    return newState;
  });

  return { isMessageNew };
};

export const setUpdatedUserStatus = (
  updatedUserStatus: EmittedUpdatedUserStatus,
) => {
  const { sorting } = useConversationsManipulationState.getState();
  const {
    conversation_id,
    pharmacy_viewed_all_messages,
    patient_viewed_all_messages,
  } = updatedUserStatus;
  useChatState.setState((state) => {
    const newState = { ...state };

    const viewedConversationsSetCopy = new Set(newState.viewedConversationsSet);
    const isViewedConversation = (id: string) =>
      viewedConversationsSetCopy.has(id);

    if (
      pharmacy_viewed_all_messages &&
      !isViewedConversation(conversation_id)
    ) {
      viewedConversationsSetCopy.add(conversation_id);
      newState.viewedConversationsSet = viewedConversationsSetCopy;
    } else if (
      !pharmacy_viewed_all_messages &&
      isViewedConversation(conversation_id)
    ) {
      viewedConversationsSetCopy.delete(conversation_id);
      newState.viewedConversationsSet = viewedConversationsSetCopy;
    }

    const rawConversationsData = [...newState.rawConversations];

    if (!rawConversationsData.length) return newState;

    const currentConversationIndex = rawConversationsData.findIndex(
      (conversation) => {
        return conversation.conversation_id === conversation_id;
      },
    );

    if (currentConversationIndex === -1) return newState;

    const currentConversation = rawConversationsData[currentConversationIndex];
    currentConversation.pharmacy_viewed_all_messages =
      pharmacy_viewed_all_messages;
    currentConversation.patient_viewed_all_messages =
      patient_viewed_all_messages;

    const patientsData = groupConversationsByPatient(rawConversationsData).sort(
      (currentConversation, nextConversation) =>
        compare(
          currentConversation,
          nextConversation,
          sorting.field,
          sorting.order,
          sorting.isDate,
        ),
    );

    newState.rawConversations = rawConversationsData;
    newState.conversationsGroupedByPatient = patientsData;

    return newState;
  });
};

export const disableFilters = () => {
  useChatState.setState({ disabled: true });
};

export const enableFilters = () => {
  useChatState.setState({ disabled: false });
};

export const setCounts = (count: { unread: number }) => {
  useChatState.setState((state) => {
    const newState = { ...state };
    if (count.unread > 0) {
      return { ...newState, count };
    }
    return { ...state, count: {} };
  });
};

export const setMessagesPagination = (pagination: MessagesPagination) => {
  useChatState.setState({ messagesPagination: pagination });
};

export const setShowAddPatientModal = (showAddPatientModal: boolean) => {
  useChatState.setState({ showAddPatientModal: showAddPatientModal });
};

export const setShowEditModal = (showEditModal: boolean) => {
  useChatState.setState({ showEditModal: showEditModal });
};

export const setRawConversationsCount = (rawConversationsCount: number) => {
  useChatState.setState({ rawConversationsCount: rawConversationsCount });
};

export const setPatientsWithoutConversationsSet = (
  patientsWithoutConversationsSet: Set<string>,
) => {
  useChatState.setState({ patientsWithoutConversationsSet });
};

export const setShowTemplatesModal = (showTemplatesModal: boolean) => {
  useChatState.setState({ showTemplatesModal: showTemplatesModal });
};

export const setSelectedTemplate = (selectedTemplate: ITemplate | null) => {
  useChatState.setState({ selectedTemplate: selectedTemplate });
};

export const resetChatState = () => {
  useChatState.setState(initialState);
};

export const setChatBoxText = (text: string) => {
  useChatState.setState({ chatBoxText: text });
};

export const setStatusInConversation = (
  statusInConversation: Record<string, MessageStatus>,
) => {
  useChatState.setState({ statusInConversation: statusInConversation });
};

export const removeFailedMessage = (
  conversationId: string,
  messageId: string,
) => {
  useChatState.setState((state) => {
    const newState = { ...state };

    if (newState.failedMessagesInConversation[conversationId]) {
      const newFailedMessages = newState.failedMessagesInConversation[
        conversationId
      ].filter((item) => item._id !== messageId);

      newState.failedMessagesInConversation[conversationId] = newFailedMessages;
    }

    return newState;
  });
};

export const addFailedMessage = (
  conversationId: string,
  failedMessage: IMessageExtended,
) => {
  useChatState.setState((state) => {
    const newState = { ...state };

    if (newState.failedMessagesInConversation[conversationId]) {
      newState.failedMessagesInConversation[conversationId].push(failedMessage);
    } else {
      newState.failedMessagesInConversation[conversationId] = [failedMessage];
    }

    return newState;
  });
};
