import { IconButton } from 'assets/components/icon-button';
import { Text } from 'assets/components/text';
import { ToggleSwitch } from 'assets/components/toggle-switch';
import { PencilIcon } from 'assets/icons';
import { makeStyles, useTheme } from 'assets/theme';
import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { View } from 'react-native';
import {
  setEditingPickupMethod,
  updatePickupMethod,
  setShowModalPickup,
} from '../refills-settings-actions';
import { useAppStateStore } from '../../../../store/app-store';
import { useToast } from '../../../../common/hooks/useToast';
import { getText } from 'assets/localization/localization';
import { useRefillsSettingsState } from '../refills-settings-store';

export const PickupMethodItemRenderer: FunctionComponent<
  PropsWithChildren<PickupMethodItemRendererProps>
> = ({ id, name, active, confirmation }) => {
  const [toggle, setToggle] = useState<boolean>(active);
  const { locationId } = useAppStateStore();
  const styles = useStyles();
  const theme = useTheme();
  const { toast } = useToast();
  const { allPickupMethods } = useRefillsSettingsState();

  const hasAtLeastOneActiveFlagTrue = (): boolean => {
    if (!allPickupMethods || allPickupMethods.length === 0) return false;
    let activeCount = 0;
    for (const method of allPickupMethods) {
      if (method.active) {
        activeCount += 1;
      }
    }
    return activeCount === 1;
  };

  const toggleActive = () => {
    if (active && hasAtLeastOneActiveFlagTrue()) {
      toast(getText('one-pickup-method-must-be-enabled'), {
        type: 'warning',
      });
    } else {
      updatePickupMethod(locationId, id, { active: !active });
      setToggle(!active);
    }
  };

  useEffect(() => {
    setToggle(active);
  }, [active]);

  return (
    <View style={styles.container}>
      <View style={styles.firstColumnContent}>
        <Text style={styles.title}>{name}</Text>
      </View>
      <View style={{ flex: 3 }}>
        <Text style={styles.value}>{confirmation}</Text>
      </View>
      <View style={styles.actionViewStyle}>
        <ToggleSwitch
          logger={{
            id: `locations-pickup-method-${`${id}`}`,
          }}
          value={toggle}
          onPress={() => toggleActive()}
        />
        <IconButton
          icon={PencilIcon}
          onPress={() => {
            setEditingPickupMethod(id);
            setShowModalPickup(true);
          }}
          size={16}
          color={theme.palette.gray[700]}
          logger={{ id: 'task-type-renderer-action-button-edit' }}
        />
      </View>
    </View>
  );
};

interface PickupMethodItemRendererProps {
  id: string;
  name: string;
  active: boolean;
  confirmation: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'row',
    gap: theme.getSpacing(2),
    alignItems: 'center',
    borderBottomColor: theme.palette.gray[200],
    borderBottomWidth: 1,
    padding: theme.getSpacing(1),
    marginBottom: theme.getSpacing(1),
  },
  firstColumnContent: {
    flex: 1,
    flexDirection: 'row',
  },
  title: {
    ...theme.fonts.regular,
    fontSize: 14,
    lineHeight: 16,
    color: theme.palette.gray[900],
    fontWeight: '600',
  },
  value: {
    ...theme.fonts.regular,
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[700],
  },
  actionViewStyle: {
    alignContent: 'flex-end',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
