import {
  AmPmEnum,
  ObEquipmentResponseDto,
  WeekdaysEnum,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import * as validate from '@digitalpharmacist/validation-dp';
import { CheckboxInput } from 'assets/components/checkbox';
import {
  CheckboxGroupField,
  CheckboxGroupItem,
} from 'assets/components/checkbox-group';
import { RadioButtonGroupField } from 'assets/components/radio-button-group';
import { RadioButtonInputMode } from 'assets/components/radio-button-group/RadioButton';
import { Text } from 'assets/components/text';
import { TextField } from 'assets/components/text-field';
import { Form } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { Image, View } from 'react-native';
import {
  sameAsPharmacyAddress,
  mapToDaysForInstall,
  mapToShippingAddress,
  save,
  updatePharmacyEquipment,
} from '../../../../actions/onboarding-actions';
import {
  PreferredDaysForInstall,
  YesNoOption,
} from '../../../../store/onboarding-store';
import { AddressField } from '../address/AddressField';
import { FORM_NOT_VALID } from '../../utils';
import { OBFormHandler, OBFormProps } from '.';

export interface PreferredDaysForInstallType {
  label: PreferredDaysForInstall;
  enabled: boolean;
}

export interface EquipmentType extends ObEquipmentResponseDto {
  preferred_days_for_install_form?: CheckboxGroupItem<WeekdaysEnum>[];
}

export const OBEquipmentForm = forwardRef<OBFormHandler, OBFormProps>(
  ({ location }: OBFormProps, ref: ForwardedRef<OBFormHandler>) => {
    const styles = useStyles();
    const [shipToPharmacyAddress, setShipToPharmacyAddress] =
      useState<boolean>(false);
    const { location_store_address, location_onsite_required, equipment } =
      location;

    const yesValue = YesNoOption.Yes as any;

    const methods = useForm<EquipmentType>({
      defaultValues: location_onsite_required
        ? {
            it_person_available: equipment?.it_person_available ?? yesValue,
            firewall_provider: equipment?.firewall_provider,
            technician_name: equipment?.technician_name,
            technician_email: equipment?.technician_email,
            technician_phone: equipment?.technician_phone,
            preferred_days_for_install_form: mapToDaysForInstall(
              equipment?.preferred_days_for_install,
            ),
            recipient_name: equipment?.recipient_name,
            install_time:
              equipment?.install_time !== AmPmEnum.Unknown
                ? equipment?.install_time
                : undefined,
            ship_to_address: mapToShippingAddress(equipment?.ship_to_address),
          }
        : undefined,
    });

    const isItAvailable = methods.watch('it_person_available') === yesValue;

    useEffect(() => {
      if (shipToPharmacyAddress) {
        methods.reset({
          ...methods.getValues(),
          recipient_name: '',
          ship_to_address: {
            address1: '',
            address2: '',
            city: '',
            country: '',
            postal_code: '',
            state: '',
          },
        });
      }
    }, [shipToPharmacyAddress]);

    useEffect(() => {
      if (!isItAvailable) {
        methods.reset({
          ...methods.getValues(),
          firewall_provider: '',
          technician_email: '',
          technician_name: '',
          technician_phone: '',
        });
      }
    }, [isItAvailable]);

    useEffect(() => {
      const equipment = location.equipment;
      if (equipment) {
        const equipmentAddress = equipment.ship_to_address;
        const isSameAddress = sameAsPharmacyAddress(
          equipmentAddress,
          location_store_address,
        );
        setShipToPharmacyAddress(isSameAddress);
      }
    }, [location, location_store_address]);

    const handleOnSubmit = async () => {
      if (!location_onsite_required) {
        return;
      }
      await methods.handleSubmit(
        async (values) => {
          const newValues: ObEquipmentResponseDto = {
            ...values,
            ship_to_address: shipToPharmacyAddress
              ? location_store_address
              : methods.getValues('ship_to_address'),
          };
          updatePharmacyEquipment(location.id, newValues);
        },
        () => {
          // throw error to prevent go next
          throw FORM_NOT_VALID;
        },
      )();
    };

    useImperativeHandle(ref, () => ({
      handleSubmit: handleOnSubmit,
    }));

    return (
      <View style={styles.body}>
        <Image
          source={require('../../../../../assets/battery.png')}
          style={styles.image}
        />
        <Form methods={methods}>
          <Form.Row>
            <Form.Column>
              <Text style={styles.title}>{getText('have-it-person')}</Text>
              <RadioButtonGroupField
                name="it_person_available"
                isHorizontal
                mode={RadioButtonInputMode.FLAT}
                values={[
                  {
                    text: getText('yes'),
                    value: YesNoOption.Yes,
                  },
                  {
                    text: getText('no'),
                    value: YesNoOption.No,
                  },
                  {
                    text: getText('do-not-know'),
                    value: YesNoOption.Unknown,
                  },
                ]}
              />
              <TextField
                disabled={!isItAvailable}
                name="firewall_provider"
                type="text"
                label={getText('firewall-provider')}
                rules={{
                  required:
                    isItAvailable && getText('firewall-provider-is-required'),
                  validate: {
                    value: (value) => {
                      return !value || validate.isName(value)
                        ? true
                        : getText('firewall-provider-is-not-valid');
                    },
                  },
                }}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column>
              <TextField
                disabled={!isItAvailable}
                name="technician_name"
                type="text"
                label={getText('technician-name')}
                rules={{
                  required:
                    isItAvailable && getText('technician-name-is-required'),
                  validate: {
                    value: (value) => {
                      return !value || validate.isName(value)
                        ? true
                        : getText('technician-name-is-not-valid');
                    },
                  },
                }}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column>
              <TextField
                disabled={!isItAvailable}
                name="technician_email"
                type="emailAddress"
                label={getText('technician-email')}
                rules={{
                  required:
                    isItAvailable && getText('technician-email-is-required'),
                  validate: {
                    value: (value) => {
                      return !value || validate.isEmail(value)
                        ? true
                        : getText('technician-email-is-not-valid');
                    },
                  },
                }}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column>
              <TextField
                disabled={!isItAvailable}
                name="technician_phone"
                type="telephoneNumber"
                label={getText('technician-phone')}
                rules={{
                  required:
                    isItAvailable && getText('technician-phone-is-required'),
                  validate: {
                    value: (value) => {
                      return !value ||
                        validate.isPhoneNumber(value ? '+1' + value : '')
                        ? true
                        : getText('technician-phone-is-not-valid');
                    },
                  },
                }}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column>
              <Text style={styles.title}>
                {getText('address-device-shipped')}
              </Text>
              <Text style={styles.subtitle}>
                {getText('po-boxes-not-accepted')}
              </Text>
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <View style={styles.address}>
              <CheckboxInput
                checked={shipToPharmacyAddress}
                onPress={setShipToPharmacyAddress}
              />
              {location_store_address && (
                <View style={{}}>
                  <Text style={styles.mediumText}>
                    {getText('ship-to-pharmacy')}
                  </Text>
                  <Text style={styles.smallLabel}>
                    {location_store_address.address1}
                  </Text>
                  {location_store_address.address2 && (
                    <Text style={styles.smallLabel}>
                      {location_store_address.address2}
                    </Text>
                  )}
                  <Text
                    style={styles.smallLabel}
                  >{`${location_store_address.city} ${location_store_address.state}, ${location_store_address.postal_code}`}</Text>
                </View>
              )}
            </View>
          </Form.Row>
          <Form.Row>
            <Form.Column>
              <TextField
                name={`recipient_name`}
                label={getText('recipient_name')}
                testID={`recipient_name`}
                disabled={shipToPharmacyAddress}
                rules={{
                  required:
                    !shipToPharmacyAddress &&
                    getText('recipient-name-is-required'),
                  validate: {
                    value: (value) => {
                      return !value || validate.isName(value)
                        ? true
                        : getText('recipient-name-is-not-valid');
                    },
                  },
                }}
              />
            </Form.Column>
          </Form.Row>
          <AddressField
            name="ship_to_address"
            disabled={shipToPharmacyAddress}
            rules={{ required: !shipToPharmacyAddress }}
          />
          <Form.Row>
            <Form.Column>
              <Text style={styles.title}>
                {getText('select-preferred-weekdays')}
              </Text>
              <CheckboxGroupField
                name="preferred_days_for_install_form"
                rules={{
                  required: getText('at-least-one-selected'),
                }}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column>
              <Text style={styles.title}>
                {getText('am-or-pm-installations')}
              </Text>
              <RadioButtonGroupField
                name="install_time"
                isHorizontal
                mode={RadioButtonInputMode.FLAT}
                values={[
                  {
                    text: 'Morning',
                    value: AmPmEnum.Morning,
                  },
                  {
                    text: 'Afternoon',
                    value: AmPmEnum.Afternoon,
                  },
                ]}
                rules={{
                  required: getText('preferred-time-is-required'),
                }}
              />
            </Form.Column>
          </Form.Row>
        </Form>
      </View>
    );
  },
);

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary[500],
  },
  image: {
    height: 270,
  },
  body: {
    gap: theme.getSpacing(3),
  },
  title: {
    ...theme.lumistryFonts.text.medium.semiBold,
    color: theme.palette.gray[900],
  },
  subtitle: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.gray[700],
  },
  mediumText: {
    ...theme.lumistryFonts.text.medium.regular,
    color: theme.palette.gray[700],
  },
  smallLabel: {
    ...theme.lumistryFonts.label.small.regular,
    color: theme.palette.gray[700],
  },
  address: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: theme.getSpacing(2),
  },
  dropDownControlStyle: {
    height: 65.5,
    width: 350,
  },
  multiselectCheckboxes: {
    gap: theme.getSpacing(2),
    paddingTop: theme.getSpacing(2),
  },
}));
