import { Text } from 'assets/components/text';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { OBRocketIllustrationIcon } from '../onboarding/components/icons/OBRocketIllustrationIcon';

export const NoLocationFound: FunctionComponent = () => {
  const styles = useStyles();
  const theme = useTheme();

  return (
    <View style={styles.root}>
      <View style={styles.imageContainer}>
        <OBRocketIllustrationIcon />
      </View>
      <View>
        <Text style={styles.title}>{getText('return-to-email')}</Text>
        <Text style={styles.subtitle}>{getText('click-onboarding-link')}</Text>
      </View>
    </View>
  );
};
const useStyles = makeStyles((theme) => ({
  root: {
    alignContent: 'center',
    width: 370,
    gap: 48,
  },
  title: {
    ...theme.lumistryFonts.text.xLarge.semiBold,
    color: theme.palette.gray[900],
    textAlign: 'center',
  },
  subtitle: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.gray[500],
    textAlign: 'center',
  },
  imageContainer: {
    alignItems: 'center',
  },
}));
