import { ICellRendererParams } from '@ag-grid-community/core';
import { RefillSubmittedDto } from '@digitalpharmacist/prescription-service-client-axios';
import { Icon } from 'assets/components/icon';
import { ThreedotsVerticalIcon } from 'assets/icons/ThreedotsVerticalIcon';
import { useTheme } from 'assets/theme';
import React from 'react';
import { TriggerEvent, useContextMenu } from 'react-contexify';
import { View } from 'react-native';

export const ContextMenuRenderer: React.FC<ContextMenuRendererProps> = (
  props,
) => {
  const theme = useTheme();
  const { show: showContextMenu } = useContextMenu<RefillSubmittedDto>({
    id: props.contextMenuId,
  });

  function handleShowContextMenu(event: TriggerEvent) {
    event.preventDefault();
    showContextMenu({ event, props });
  }

  return (
    <>
      <div style={{ cursor: 'pointer' }} onClick={handleShowContextMenu}>
        <View>
          <Icon icon={ThreedotsVerticalIcon} color={theme.palette.gray[900]} />
        </View>
      </div>
    </>
  );
};

export interface ContextMenuRendererProps extends ICellRendererParams {
  contextMenuId: string;
  data: RefillSubmittedDto;
}
