import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { View } from 'react-native';
import { makeStyles } from 'assets/theme';
import { Text } from 'assets/components/text';
import { useForm } from 'react-hook-form';
import { Form } from 'assets/layout';
import { AddressField } from '../address/AddressField';
import { CheckboxInput, CheckboxInputMode } from 'assets/components/checkbox';
import {
  sameAsPharmacyAddress,
  save,
  updatePharmacyLocationAddresses,
} from '../../../../actions/onboarding-actions';
import { getText } from 'assets/localization/localization';
import { FinalizeObAddressDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { InfoTooltip } from '../common/InfoTooltip';
import { FORM_NOT_VALID } from '../../utils';
import { OBFormHandler, OBFormProps } from '.';

export type FormAddressType = FinalizeObAddressDto;

export type AddressesFormType = {
  storeAddress: FormAddressType;
  billingAddress: FormAddressType;
  shippingAddress: FormAddressType;
};

export const OBAddressForm = forwardRef<OBFormHandler, OBFormProps>(
  ({ location }: OBFormProps, ref: ForwardedRef<OBFormHandler>) => {
    const styles = useStyles();
    const [sameAddress, setSameAddress] = useState<boolean>(false);
    const [billingAddressIsRequired, setBillingAddressIsRequired] =
      useState(false);

    const methods = useForm<AddressesFormType>({
      defaultValues: {
        storeAddress: location.location_store_address ?? {},
        billingAddress: location.location_billing_address ?? {},
        shippingAddress: location.location_shipping_address ?? {},
      },
    });

    const shippingAddress = methods.watch('shippingAddress');

    const handleOnSameStoreAndBillingAddress = (checked: boolean) => {
      setSameAddress(checked);
      if (!checked) return;
      const storeAddress = methods.getValues('storeAddress');
      Object.keys(storeAddress).forEach((key) => {
        if (key !== 'id')
          methods.setValue(
            `billingAddress.${key}` as any,
            storeAddress[key as keyof FormAddressType] as any,
          );
      });
    };

    useEffect(() => {
      const { location_store_address, location_billing_address } = location;
      const isSameAddress = sameAsPharmacyAddress(
        location_store_address,
        location_billing_address,
      );
      setSameAddress(isSameAddress);
    }, [location]);

    useEffect(() => {
      setBillingAddressIsRequired(
        Boolean(
          shippingAddress.address1 ||
            shippingAddress.address2 ||
            shippingAddress.city ||
            shippingAddress.postal_code ||
            shippingAddress.state,
        ),
      );
    }, [
      shippingAddress.address1,
      shippingAddress.address2,
      shippingAddress.city,
      shippingAddress.postal_code,
      shippingAddress.state,
    ]);

    const handleOnSubmit = async () => {
      await methods.handleSubmit(
        async (values) => {
          // update the state
          updatePharmacyLocationAddresses(location.id, values);
        },
        () => {
          // throw error to prevent go next
          throw FORM_NOT_VALID;
        },
      )();
    };

    useImperativeHandle(ref, () => ({
      handleSubmit: handleOnSubmit,
    }));

    return (
      <Form methods={methods}>
        <View style={styles.addressContainer}>
          <View style={styles.addressLabelContainer}>
            <Text style={styles.addressTitle}>
              {`${getText('pharmacy-store-address')}*`}
            </Text>
            <InfoTooltip
              style={styles.tooltipContainer}
              tooltipId="pharmacy-store-address"
              text={getText('pharmacy-store-address-tooltip')}
              size={16}
            />
          </View>
          <AddressField name={'storeAddress'} rules={{ required: true }} />
        </View>

        <View style={styles.addressContainer}>
          <View style={styles.addressLabelContainer}>
            <Text style={styles.addressTitle}>
              {`${getText('account-billing-address')}*`}
            </Text>
            <InfoTooltip
              style={styles.tooltipContainer}
              tooltipId="account-billing-address"
              text={getText('account-billing-address-tooltip')}
              size={16}
            />
          </View>
          <View style={styles.checkBoxContainer}>
            <CheckboxInput
              label={getText('same-as-pharmacy-store-address')}
              mode={CheckboxInputMode.FLAT}
              checked={sameAddress}
              onPress={handleOnSameStoreAndBillingAddress}
            />
          </View>
          <AddressField name={'billingAddress'} rules={{ required: true }} />
        </View>

        <View style={styles.addressContainer}>
          <View style={styles.addressLabelContainer}>
            <Text style={styles.addressTitle}>
              {getText('account-shipping-address')}
              {billingAddressIsRequired ? '*' : ''}
            </Text>
            <InfoTooltip
              style={styles.tooltipContainer}
              tooltipId="account-shipping-address"
              text={getText('account-shipping-address-tooltip')}
              size={16}
            />
          </View>
          <AddressField
            name={'shippingAddress'}
            rules={{ required: billingAddressIsRequired }}
          />
        </View>
      </Form>
    );
  },
);

const useStyles = makeStyles((theme) => ({
  addressContainer: {
    // gap: theme.getSpacing(2),
    marginBottom: theme.getSpacing(3),
  },
  addressLabelContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.getSpacing(1),
  },
  addressTitle: {
    ...theme.lumistryFonts.text.medium.semiBold,
    color: theme.palette.gray[900],
    marginBottom: theme.getSpacing(2),
  },
  checkBoxContainer: {
    marginBottom: theme.getSpacing(2),
  },
  tooltipContainer: {
    marginBottom: theme.getSpacing(1.5),
  },
}));
