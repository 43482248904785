import {
  VaccinationDto,
  VaccinationLocation,
  VaccinationRoute,
} from '@digitalpharmacist/forms-service-client-axios';
import { Text } from 'assets/components/text';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { View } from 'react-native';
import UsersService from '../../api/UsersService';
import { makeStyles } from 'assets/theme';
import { getText } from 'assets/localization/localization';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_PHARMACY_TIMEZONE,
  convertDateTimeFromUtcToPharmacyTimezone,
} from '../../common/datetime-utils';
import {
  VaccinationLocationLocalization,
  VaccinationRouteLocalization,
  VaccinationSideLocalization,
} from './utils';
import { useLocationSettingsState } from '../../screens/settings/location/location-settings-store';

const VaccinationInfoSection: FunctionComponent<VaccinationInfoSectionType> = ({
  vaccination,
}) => {
  const [immunizer, setImmunizer] = useState<string>('');
  const styles = useStyles();
  const { location } = useLocationSettingsState();
  const pharmacyTimezone = useMemo(
    () =>
      location && location.timezone.id
        ? location.timezone.id
        : DEFAULT_PHARMACY_TIMEZONE,
    [location],
  );

  const handleGetImmunizer = useCallback(async () => {
    const immunizerData = await UsersService.getPharmacistUser(
      vaccination.created_by,
    );

    if (!immunizerData.firstName || !immunizerData.lastName) {
      return;
    }

    setImmunizer(`${immunizerData.firstName} ${immunizerData.lastName}`);
  }, [vaccination]);

  useEffect(() => {
    void handleGetImmunizer();
  }, [handleGetImmunizer]);

  const displayVaccinationInfoRow = useCallback(
    (label: string, value: string) => {
      return (
        <View style={styles.field}>
          <Text style={styles.label} selectable>
            {label}
          </Text>
          <Text selectable={true} style={styles.value}>
            {value}
          </Text>
        </View>
      );
    },
    [],
  );

  const vaccinationLocation = useMemo(() => {
    if (vaccination.location !== VaccinationLocation.Other) {
      return VaccinationLocationLocalization[vaccination.location];
    }

    return vaccination.other_location ? vaccination.other_location : '';
  }, [vaccination.location]);

  const vaccinationRoute = useMemo(() => {
    if (vaccination.route !== VaccinationRoute.Other) {
      return VaccinationRouteLocalization[vaccination.route];
    }

    return vaccination.other_route ? vaccination.other_route : '';
  }, [vaccination.route]);

  return (
    <View>
      <Text style={styles.sectionTitle}>
        {getText('vaccination-info-pharmacy-use-only')}
      </Text>
      {displayVaccinationInfoRow(getText('drug-name'), vaccination.drug_name)}
      {displayVaccinationInfoRow(
        getText('amt-administered'),
        vaccination.amt_administered,
      )}
      {displayVaccinationInfoRow(
        getText('manufacturer'),
        vaccination.manufacturer,
      )}
      {displayVaccinationInfoRow(getText('lot-nr'), vaccination.lot)}
      {displayVaccinationInfoRow(
        getText('exp-date'),
        convertDateTimeFromUtcToPharmacyTimezone(
          vaccination.exp_date,
          pharmacyTimezone,
          DEFAULT_DATE_FORMAT,
        ),
      )}
      {displayVaccinationInfoRow(
        getText('date-of-vis'),
        convertDateTimeFromUtcToPharmacyTimezone(
          vaccination.vis_date,
          pharmacyTimezone,
          DEFAULT_DATE_FORMAT,
        ),
      )}
      {displayVaccinationInfoRow(
        getText('date-administered'),
        convertDateTimeFromUtcToPharmacyTimezone(
          vaccination.administered_date,
          pharmacyTimezone,
          DEFAULT_DATE_FORMAT,
        ),
      )}
      {displayVaccinationInfoRow(getText('location'), vaccinationLocation)}
      {displayVaccinationInfoRow(getText('route'), vaccinationRoute)}
      {displayVaccinationInfoRow(
        getText('side'),
        VaccinationSideLocalization[vaccination.side],
      )}
      {displayVaccinationInfoRow(getText('immunizer'), immunizer)}
    </View>
  );
};

type VaccinationInfoSectionType = {
  vaccination: VaccinationDto;
};

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    ...theme.lumistryFonts.text.xLarge.bold,
    borderTopWidth: 1,
    borderColor: theme.palette.gray[400],
    paddingTop: theme.getSpacing(3),
    paddingBottom: theme.getSpacing(3),
  },
  field: {
    marginBottom: theme.getSpacing(3),
  },
  label: {
    ...theme.lumistryFonts.label.medium.semiBold,
    marginBottom: theme.getSpacing(1),
  },
  value: {
    ...theme.lumistryFonts.text.medium.regular,
  },
}));

export default VaccinationInfoSection;
