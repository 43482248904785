import React, { FunctionComponent, PropsWithChildren } from 'react';
import { View } from 'react-native';
import { makeStyles } from 'assets/theme';
import { WizardProgress } from 'assets/components/wizard-progress';

export const StepperProgress: FunctionComponent<
  PropsWithChildren<WizardProgressProps>
> = (props) => {
  const styles = useStyles();
  return (
    <View style={styles.root}>
      <WizardProgress
        currentStep={props.currentStep}
        numberOfSteps={props.numberOfSteps}
        type="withoutTitle"
      />
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    flex: 1,
  },
  marker: {
    width: 16,
    height: 16,
    borderRadius: 8,
  },
  markerComplete: {
    backgroundColor: theme.colors.brandedPrimary,
    width: 32,
    height: 32,
    borderRadius: 16,
  },
  markerIncomplete: {
    backgroundColor: theme.palette.gray[200],
    width: 32,
    height: 32,
    borderRadius: 16,
  },
  lineComplete: {
    backgroundColor: theme.colors.brandedPrimary,
  },
  lineIncomplete: {
    backgroundColor: theme.palette.gray[200],
  },
  line: {
    height: 2,
    width: '85%',
  },
  item: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    marginBottom: theme.getSpacing(1),
  },
  dot: {
    backgroundColor: theme.palette.white,
    width: 8,
    height: 8,
    borderRadius: 4,
    margin: 12,
  },
  icon: {
    marginLeft: theme.getSpacing(0.5),
    marginTop: theme.getSpacing(0.5),
  },
  shadow: {
    backgroundColor: theme.colors.brandedPrimaryDisabled,
    width: 40,
    height: 40,
    borderRadius: 20,
    padding: theme.getSpacing(0.5),
  },
  noShadow: {
    backgroundColor: 'transparent',
  },
}));

export interface WizardProgressProps {
  numberOfSteps: number;
  currentStep: number;
}
