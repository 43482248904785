import React, { FunctionComponent, useMemo } from 'react';
import {
  AutoFillRequestStatus,
  PrescriptionDto,
} from '@digitalpharmacist/prescription-service-client-axios';
import { getText } from 'assets/localization/localization';

export const AutoFillInfo: FunctionComponent<MedicationAutoFillInfoProps> = ({
  prescription,
}) => {
  const { auto_fill_enrolled, is_auto_fill_allowed, auto_fill_request_status } =
    prescription;

  const autoFillStatusOptions: Record<AutoFillStatusTypes, string> = {
    [AutoFillStatusTypes.Enrolled]: getText('enrolled'),
    [AutoFillStatusTypes.NotEligible]: getText('auto-refill-not-eligible'),
    [AutoFillStatusTypes.NotEnrolled]: getText('not-enrolled'),
    [AutoFillStatusTypes.Requested]: getText('auto-refill-patient-requested'),
    [AutoFillStatusTypes.Unavailable]: getText('autofill-info-not-available'),
  };

  const getAutoFillStatus = () => {
    if (auto_fill_enrolled) {
      return AutoFillStatusTypes.Enrolled;
    }
    if (is_auto_fill_allowed === false) {
      return AutoFillStatusTypes.NotEligible;
    }
    if (auto_fill_enrolled === false) {
      return AutoFillStatusTypes.NotEnrolled;
    }
    if (auto_fill_request_status === AutoFillRequestStatus.Requested) {
      return AutoFillStatusTypes.Requested;
    }

    return AutoFillStatusTypes.Unavailable;
  };

  const autoFillText = useMemo(() => {
    const autoFillStatus = getAutoFillStatus();
    return autoFillStatusOptions[autoFillStatus];
  }, [prescription]);

  return <>{autoFillText}</>;
};

enum AutoFillStatusTypes {
  Enrolled,
  NotEnrolled,
  NotEligible,
  Requested,
  Unavailable,
}

export type MedicationAutoFillInfoProps = {
  prescription: PrescriptionDto;
};
