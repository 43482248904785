import { PasswordRules } from 'assets/common/PasswordRules';
import { Modal } from 'assets/components/modal';
import { Text } from 'assets/components/text';
import { TextField } from 'assets/components/text-field';
import { useForm } from 'assets/form';
import { Form } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { logError } from 'assets/logging/logger';
import { makeStyles } from 'assets/theme';
import { FunctionComponent, useState } from 'react';
import { TouchableOpacity } from 'react-native';
import UsersService from '../../../../api/UsersService';
import { passwordRegex } from '../../../../common/form-utils';
import { RowItemRender } from '../../../../components/Settings/RowItemRender';

export const InformationItemRenderer: FunctionComponent<
  InformationItemRendererPops
> = ({ firstName, lastName, email }) => {
  const styles = useStyles();
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const methods = useForm<UpdatePasswordForm>({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
  });

  const passwordValue = methods.watch('newPassword');

  const handleSubmit = () => {
    const formValue = methods.getValues();
    setLoading(true);
    UsersService.changePharmacistUserPassword({
      previous_password: formValue.currentPassword,
      proposed_password: formValue.newPassword,
    })
      .then(() => setShowCloseConfirmation(false))
      .catch((err: Error) => {
        logError(err);
        setError(err.message);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <RowItemRender title={getText('profile-first-name')} value={firstName} />
      <RowItemRender title={getText('profile-last-name')} value={lastName} />
      <RowItemRender title={getText('email')} value={email} />
      <RowItemRender title={getText('password')}>
        <TouchableOpacity onPress={() => setShowCloseConfirmation(true)}>
          <Text style={styles.changePasswordStyle}>
            {getText('change-password')}
          </Text>
        </TouchableOpacity>
      </RowItemRender>
      <Modal
        title={getText('change-password')}
        cancelButtonProps={{
          onPress: () => {
            setShowCloseConfirmation(false);
          },
          logger: { id: 'update-password-cancel-button' },
        }}
        okButtonProps={{
          onPress: methods.handleSubmit(handleSubmit),
          logger: { id: 'update-password-accept-button' },
          hierarchy: 'primary',
          loading: status === 'loading',
        }}
        show={showCloseConfirmation}
        size="sm"
      >
        <Form methods={methods}>
          <Form.Alert title={error} intent="error" visible={!!error} />
          <Form.Row>
            <Form.Column>
              <TextField
                testID="current-password-input"
                label={getText('current-password')}
                name="currentPassword"
                rules={{
                  required: getText('password-is-required'),
                  pattern: {
                    value: passwordRegex,
                    message: getText('password-does-not-meet-criteria'),
                  },
                }}
                onSubmit={methods.handleSubmit(handleSubmit)}
                disabled={loading}
                type="password"
              />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column>
              <TextField
                testID="new-password-input"
                label={getText('new-password')}
                name="newPassword"
                rules={{
                  required: getText('password-is-required'),
                  validate: {
                    value: () => {
                      return methods.getValues().newPassword ===
                        methods.getValues().confirmNewPassword
                        ? true
                        : getText('passwords-do-not-match');
                    },
                  },
                }}
                onSubmit={methods.handleSubmit(handleSubmit)}
                disabled={loading}
                type="password"
              />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column>
              <TextField
                testID="confirm-new-password-input"
                label={getText('confirm-update-password')}
                name="confirmNewPassword"
                rules={{
                  required: getText('password-is-required'),
                  validate: {
                    value: () => {
                      return methods.getValues().newPassword ===
                        methods.getValues().confirmNewPassword
                        ? true
                        : getText('passwords-do-not-match');
                    },
                  },
                }}
                onSubmit={methods.handleSubmit(handleSubmit)}
                disabled={loading}
                type="password"
              />
            </Form.Column>
          </Form.Row>
          <Form.Content>
            <PasswordRules passwordValue={passwordValue}></PasswordRules>
          </Form.Content>
        </Form>
      </Modal>
    </>
  );
};

interface InformationItemRendererPops {
  firstName: string;
  lastName: string;
  email: string;
}

interface UpdatePasswordForm {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

const useStyles = makeStyles((theme) => ({
  changePasswordStyle: {
    color: theme.palette.primary[600],
    fontWeight: '400',
    fontSize: 14,
  },
}));
