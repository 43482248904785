import React, { FunctionComponent, PropsWithChildren } from 'react';
import { FormElementOption } from '../form-builder-utils';
import { makeStyles, useTheme } from 'assets/theme';
import { Icon } from 'assets/components/icon';
import { EmptyCircleIcon } from 'assets/icons';
import { FormTestIDs } from '../../../screens/forms/FormTestIDs';

export const InputTableElement: FunctionComponent<
  PropsWithChildren<InputTableProps>
> = ({
  columnPlaceholders,
  rowPlaceholders,
  data: { columnOptions, rowOptions },
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const columns = columnOptions?.length ? columnOptions : columnPlaceholders;
  const rows = rowOptions?.length ? rowOptions : rowPlaceholders;

  const checkboxCells = (rowIndex: number) =>
    columns?.map((column, index) => (
      <td
        style={{
          ...styles.cell,
          ...styles.borderBottom,
          ...styles.borderRight,
          ...(rowIndex === rows!.length - 1 &&
            index === columns.length - 1 &&
            styles.radiusBottomRight),
        }}
      >
        <Icon
          color={theme.palette.gray[700]}
          size={24}
          icon={EmptyCircleIcon}
          strokeWidth={1.5}
        />
      </td>
    ));

  return (
    <table cellSpacing={0} data-test-id={FormTestIDs.inputTableWidgetInput}>
      <tbody>
        <tr>
          <th></th>
          {columns?.map((column, index) => (
            <th
              style={{
                ...styles.header,
                ...styles.borderRight,
                ...styles.borderBottom,
                ...styles.borderTop,
                ...(index === 0 && {
                  ...styles.borderLeft,
                  ...styles.radiusTopLeft,
                }),
                ...(index === columns.length - 1 && styles.radiusTopRight),
              }}
            >
              {column.text}
            </th>
          ))}
        </tr>

        {rows?.map((row, index) => (
          <tr>
            <th
              style={{
                ...styles.header,
                ...styles.borderRight,
                ...styles.borderLeft,
                ...styles.borderBottom,
                ...(index === 0 && {
                  ...styles.borderTop,
                  ...styles.radiusTopLeft,
                }),
                ...(index === rows.length - 1 && styles.radiusBottomLeft),
              }}
            >
              {row.text}
            </th>
            {checkboxCells(index)}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

interface InputTableProps {
  columnPlaceholders?: FormElementOption[];
  rowPlaceholders?: FormElementOption[];
  data: {
    columnOptions?: FormElementOption[];
    rowOptions?: FormElementOption[];
  };
}

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.gray[100],
    fontWeight: '600',
    fontSize: 14,
    padding: theme.getSpacing(2),
    verticalAlign: 'middle',
  },
  cell: {
    padding: theme.getSpacing(1),
    textAlign: 'center',
    verticalAlign: 'middle',
    lineHeight: '12px',
  },
  borderTop: {
    borderTopColor: theme.palette.gray[300],
    borderTopWidth: 1,
    borderStyle: 'solid',
  },
  borderBottom: {
    borderBottomColor: theme.palette.gray[300],
    borderBottomWidth: 1,
    borderStyle: 'solid',
  },
  borderLeft: {
    borderLeftColor: theme.palette.gray[300],
    borderLeftWidth: 1,
    borderStyle: 'solid',
  },
  borderRight: {
    borderRightColor: theme.palette.gray[300],
    borderRightWidth: 1,
    borderStyle: 'solid',
  },
  radiusTopLeft: {
    borderTopLeftRadius: theme.roundness,
  },
  radiusBottomLeft: {
    borderBottomLeftRadius: theme.roundness,
  },
  radiusTopRight: {
    borderTopRightRadius: theme.roundness,
  },
  radiusBottomRight: {
    borderBottomRightRadius: theme.roundness,
  },
}));
