import { Text } from 'assets/components/text';
import { makeStyles } from 'assets/theme';
import { Image, ImageSourcePropType, View } from 'react-native';

import { FC, FunctionComponent, ReactNode } from 'react';
import { Button } from 'assets/components/button';
import BulletPoints from './BulletPoints/BulletPoints';
import { BulletPointStyle } from './BulletPoints/BulletPoint';

const EmptyState: FC<EmptyStateProps> = ({
  image,
  title,
  subtitles,
  buttonText,
  buttonLoggerId,
  onButtonPress,
  secondaryButtonText,
  secondaryButtonLoggerId,
  onSecondaryButtonPress,
  bulletPoints,
  bulletPointStyle = BulletPointStyle.Check,
  bulletPointTitle,
}) => {
  const styles = useStyles();

  return (
    <View style={styles.wrapper}>
      <View style={styles.container}>
        <View>{image}</View>
        <Text style={styles.title}>{title}</Text>
        <View>
          {subtitles.map((subtitle) => (
            <Text style={styles.subtitle}>{subtitle}</Text>
          ))}
        </View>
        {buttonText && buttonLoggerId && (
          <View style={styles.buttonContainer}>
            <Button
              style={styles.button}
              hierarchy="secondary"
              logger={{
                id: buttonLoggerId,
              }}
              onPress={onButtonPress}
            >
              <Text style={styles.buttonText}>{buttonText}</Text>
            </Button>
            {secondaryButtonText && onSecondaryButtonPress && (
              <Button
                style={styles.secondaryButton}
                hierarchy="secondary"
                logger={{
                  id: secondaryButtonLoggerId ?? '',
                }}
                onPress={onSecondaryButtonPress}
              >
                <Text style={styles.buttonText}>{secondaryButtonText}</Text>
              </Button>
            )}
          </View>
        )}
        <View style={styles.bulletPoints}>
          <BulletPoints
            bulletPointTitle={bulletPointTitle}
            bulletPoints={bulletPoints}
            bulletPointStyle={bulletPointStyle}
          />
        </View>
      </View>
    </View>
  );
};

export default EmptyState;

interface EmptyStateProps {
  image: ReactNode;
  title: string;
  subtitles: string[];
  buttonText?: string;
  buttonLoggerId?: string;
  onButtonPress?: () => void;
  secondaryButtonText?: string;
  secondaryButtonLoggerId?: string;
  onSecondaryButtonPress?: () => void;
  bulletPoints: string[];
  bulletPointStyle?: BulletPointStyle;
  bulletPointTitle?: string;
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    flexDirection: 'column',
    height: '100%',
    margin: theme.getSpacing(4),
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.getSpacing(4),
  },
  image: { width: 370, height: 290 },
  title: {
    ...theme.lumistryFonts.text.xLarge.semiBold,
    color: theme.palette.gray[900],
    paddingTop: theme.getSpacing(1),
    textAlign: 'center',
  },
  subtitle: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.gray[700],
    textAlign: 'center',
  },
  buttonContainer: {
    justifyContent: 'center',
    marginBottom: theme.getSpacing(2),
  },
  button: {
    justifyContent: 'center',
    marginTop: theme.getSpacing(2),
    borderColor: theme.palette.primary[600],
    width: 230,
  },
  secondaryButton: {
    marginTop: theme.getSpacing(2),
    borderColor: 'transparent',
  },
  buttonText: {
    fontSize: 16,
    lineHeight: 24,
    fontWeight: '700',
    color: theme.palette.primary[600],
  },
  bulletPoints: {
    marginTop: theme.getSpacing(0.5),
    marginLeft: theme.getSpacing(2),
  },
}));
