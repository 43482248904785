import {
  DrawerContentComponentProps,
  DrawerContentScrollView,
  DrawerNavigationProp,
  DrawerScreenProps,
  createDrawerNavigator,
} from '@react-navigation/drawer';
import {
  getFocusedRouteNameFromRoute,
  useRoute,
} from '@react-navigation/native';
import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';

import { LocationCategory } from '@digitalpharmacist/file-storage-service-client-axios';
import {
  AuthorType,
  CreateConversationDto,
} from '@digitalpharmacist/unified-communications-service-client-axios';
import { CopyToClipboard } from 'assets/components/copy-to-clipboard';
import { Modal } from 'assets/components/modal';
import { Text } from 'assets/components/text';
import { TextInput } from 'assets/components/text-field/TextInput';
import { GridIcon, ListIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import { IUploadFilesResult } from 'assets/types/messageTypes';
import * as Linking from 'expo-linking';
import { View } from 'react-native';
import { v4 } from 'uuid';
import FileStorageService from '../api/FileStorageService';
import unifiedCommsService from '../api/UnifiedCommsService';
import { VideosLocation } from '../api/VideoService';
import { useToast } from '../common/hooks/useToast';
import { Tabs, TabsProps } from '../components/tabs/Tabs';
import VideosFilter from '../components/videos/VideosFilter';
import {
  CreateConversationData,
  NewChatModal,
} from '../screens/messages/components/NewChatModal';
import { ERROR_FILES_SENDING_MODAL } from '../screens/messages/data';
import { setError } from '../screens/messages/stores/error-store/error-actions';
import { ErrorStatus } from '../screens/messages/stores/error-store/error-store';
import VideoDetails from '../screens/videos/VideoDetails';
import VideosBrowse from '../screens/videos/videosBrowse';
import VideosList from '../screens/videos/videosList';
import { useAppStateStore } from '../store/app-store';
import { useUserState } from '../store/user-store';
import { useVideosState } from '../store/videos-store';
import { resetFilters } from '../tasks/tasks-filters/tasks-filters-actions';
import { DocumentPickerAsset } from 'expo-document-picker';

const DrawerNavigator =
  createDrawerNavigator<VideosDrawerNavigationParamList>();

export const VideosDrawer: FunctionComponent<
  PropsWithChildren<VideosDrawerNavigationProp>
> = () => {
  const styles = useStyles();
  const locationId = useAppStateStore((x) => x.locationId);
  const pharmacyId = useAppStateStore((x) => x.pharmacyId);
  const pharmacySlug = useAppStateStore((x) => x.pharmacySlug);
  const [isConversationCreating, setIsConversationCreating] = useState(false);
  const user = useUserState((x) => x.data);
  const theme = useTheme();
  const { toast } = useToast();
  const {
    showVideoShareModal,
    videoShortUrl,
    showNewMessageModal,
    setShowVideoShareModal,
    setShowNewMessageModal,
    setSlug,
  } = useVideosState();
  const closeVideoShareModal = () => {
    setShowVideoShareModal(false);
  };

  useEffect(() => {
    setSlug(pharmacySlug);
  }, [pharmacySlug]);

  const onConversationCreate = async (data: CreateConversationData) => {
    setIsConversationCreating(true);

    const filesUploadResult = await uploadFiles(
      locationId,
      pharmacyId,
      data.attachments,
    );

    if (filesUploadResult.isError) {
      setError(
        ERROR_FILES_SENDING_MODAL,
        ErrorStatus.ERROR,
        getText('files-sending-error-try-again'),
      );
      setIsConversationCreating(false);
      return;
    }

    const createConversationDto: CreateConversationDto = {
      author_id: String(user?.id),
      author_type: AuthorType.Pharmacy,
      subject: data.subject,
      patient_viewed_all_messages: false,
      pharmacy_viewed_all_messages: true,
      content: data.message,
      attachments: filesUploadResult.filesData.length
        ? filesUploadResult.filesData
        : [],
    };
    await unifiedCommsService.createConversation(
      pharmacyId,
      locationId,
      data.patient,
      createConversationDto,
    );
    setIsConversationCreating(false);
    submitBulkMessageModal();
  };

  const submitBulkMessageModal = () => {
    setShowNewMessageModal(false);
    setShowVideoShareModal(false);
  };

  const openBulkMessageModal = () => {
    setShowNewMessageModal(true);
  };

  async function uploadFiles(
    locationId: string,
    pharmacyId: string,
    files: DocumentPickerAsset[] | undefined,
  ): Promise<IUploadFilesResult> {
    const result: IUploadFilesResult = {
      isError: false,
      filesData: [],
    };

    if (!files || !files.length) {
      return result;
    }

    for (const file of files) {
      if (!file.file) {
        console.error(`The file ${file.name} does not have 'file' property`);
        continue;
      }

      try {
        const newName = v4();
        const extension = FileStorageService.getFileExtension(file.name);
        const fileName = `${newName}.${extension}`;

        const responseWriteUrl = await FileStorageService.writeUrl(
          LocationCategory.DirectMessage,
          locationId,
          fileName,
          pharmacyId,
        );

        await FileStorageService.uploadFile(
          file.file,
          responseWriteUrl.data.url,
        );

        result.filesData.push({
          name: file.name,
          stored_filename: fileName,
        });
      } catch (error) {
        console.error(`Error uploading file ${file.name}. Error: `, error);
        result.isError = true;
      }
    }

    return result;
  }

  return (
    <>
      <DrawerNavigator.Navigator
        screenOptions={{
          drawerType: 'permanent',
          headerShown: false,
          sceneContainerStyle: {
            overflow: 'scroll',
            display: 'flex',
            flexDirection: 'column',
          },
        }}
        initialRouteName="browse"
        drawerContent={(props) => <CustomDrawerContent {...props} />}
      >
        <DrawerNavigator.Screen
          name="browse"
          component={VideosBrowse}
          options={{ title: getText('video-header') }}
        />
        <DrawerNavigator.Screen
          name="list"
          component={VideosList}
          options={{ title: getText('video-header') }}
        />
        <DrawerNavigator.Screen
          name="detail"
          component={VideoDetails}
          options={{ title: getText('video-header'), headerTitle: () => null }}
        />
      </DrawerNavigator.Navigator>
      <Modal
        title={getText('share-video')}
        titleSize="sm"
        dismissButtonProps={{
          onPress: closeVideoShareModal,
          logger: {
            id: 'share-vuca-videos-cancel-button-modal',
          },
        }}
        cancelButtonProps={{
          onPress: openBulkMessageModal,
          text: getText('video-send'),
          logger: {
            id: 'share-vuca-videos-cancel-button-modal',
          },
        }}
        okButtonProps={{
          onPress: closeVideoShareModal,
          logger: { id: 'share-vuca-videos-ok-button-modal' },
          text: getText('close'),
        }}
        show={showVideoShareModal}
        size="sm"
      >
        <View>
          <View>
            <Text style={styles.messageTextStyle}>
              {getText('copy-link-to-share-video-message')}
            </Text>
          </View>
          <View style={styles.urlViewStyle}>
            <View style={styles.viewInputStyle}>
              <TextInput
                style={styles.urlInputStyle}
                value={videoShortUrl}
                testID="videoShortUrl"
              />
            </View>
            {videoShortUrl && (
              <View style={styles.viewClipboardStyle}>
                <CopyToClipboard
                  color={theme.palette.primary[600]}
                  fetchFromClipboard={() =>
                    toast(getText('copied-to-clipboard'))
                  }
                  stringToCopy={videoShortUrl}
                  size={16}
                  logger={{ id: 'copy-to-clipboard-video-url' }}
                />
              </View>
            )}
          </View>
        </View>
      </Modal>
      <NewChatModal
        show={showNewMessageModal}
        onConversationCreate={onConversationCreate}
        onCancel={() => setShowNewMessageModal(false)}
        isConversationCreating={isConversationCreating}
      />
    </>
  );
};

export type VideoDetailParamsProp = {
  video_id?: string;
  collection_page?: keyof VideosDrawerNavigationParamList;
  locale?: VideosLocation;
};

export type VideosDrawerNavigationParamList = {
  browse: undefined;
  list: undefined;
  detail: VideoDetailParamsProp;
};

export type VideosDrawerNavigationProp =
  DrawerNavigationProp<VideosDrawerNavigationParamList>;

export type VideoListScreenRouteProp = DrawerScreenProps<
  VideosDrawerNavigationParamList,
  'list'
>;

export type VideoDetailScreenRouteProp = DrawerScreenProps<
  VideosDrawerNavigationParamList,
  'detail'
>;

export type VideoBrowseScreenRouteProp = DrawerScreenProps<
  VideosDrawerNavigationParamList,
  'browse'
>;

const tasksTabs: TabsProps['tabs'] = [
  {
    title: getText('browse'),
    id: 'browse',
    children: (
      <>
        <VideosFilter />
      </>
    ),
    icon: GridIcon,
  },
  {
    title: getText('list'),
    id: 'list',
    children: (
      <>
        <VideosFilter />
      </>
    ),
    icon: ListIcon,
  },
];

export const CustomDrawerContent = (props: DrawerContentComponentProps) => {
  const styles = useStyles();
  const route = useRoute();
  const activeRoute = getFocusedRouteNameFromRoute(route);
  const [activeTab, setActiveTab] = useState('browse');

  useEffect(() => {
    if (!activeRoute) return;
    (async () => {
      if (activeRoute === 'detail') {
        const url = await Linking.getInitialURL();
        if (!url) return;
        const { queryParams, path } = Linking.parse(url);
        const collectionParam = (queryParams as any)?.collection_page;
        if (collectionParam) setActiveTab(collectionParam);
        else setActiveTab(path?.split('/')[1]!);
      } else setActiveTab(activeRoute);
    })();
  }, [activeRoute]);

  const handleTabChange = (activeTab: string) => {
    resetFilters(activeTab);

    props.navigation.navigate(
      activeTab as keyof VideosDrawerNavigationParamList,
    );
  };

  return (
    <>
      <DrawerContentScrollView
        {...props}
        style={styles.sidebar}
        contentContainerStyle={{ paddingTop: 0 }}
      >
        <Tabs
          tabs={tasksTabs}
          onTabChange={handleTabChange}
          defaultTab={activeTab}
        />
      </DrawerContentScrollView>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  sidebar: {
    backgroundColor: theme.palette.gray[50],
    margin: 0,
  },
  messageTextStyle: {
    ...theme.fonts.regular,
    display: 'flex',
    alignItems: 'flex-start',
  },
  urlViewStyle: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    marginTop: theme.getSpacing(2),
  },
  urlInputStyle: { height: 44 },
  viewInputStyle: { display: 'flex', flex: 4 },
  viewClipboardStyle: {
    display: 'flex',
    flex: 1,
    marginLeft: theme.getSpacing(2),
  },
}));
