import React, { FunctionComponent } from 'react';
import { Svg, Path } from 'react-native-svg';
import { MultiColorIconProps } from './types';

export const LumistryHeartIcon: FunctionComponent<MultiColorIconProps> = ({
  color = '#0063B2',
  accentColor = '#5CC4FF',
  size = 30,
  testID,
}) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 30 30"
      fill="none"
      testID={testID}
    >
      <Path
        d="M12.2405 8.18099L10.4247 6.35875C9.02341 4.95253 6.74417 4.95326 5.34291 6.35875C3.94164 7.76498 3.94164 10.0523 5.34291 11.4578L16.7143 22.8695L14.2854 25.1429L3.01404 13.7949C0.329019 11.1004 0.328289 6.71544 3.01404 4.0209C5.69906 1.32637 10.0685 1.32637 12.7535 4.0209L14.5701 5.84387L12.2397 8.17952L12.2405 8.18099Z"
        fill={color}
      />
      <Path
        d="M20.1371 15.1129L22.2185 17.429L25.8659 13.9635C28.5211 11.2957 28.5211 6.95432 25.8659 4.28653C23.2108 1.61873 18.89 1.61873 16.232 4.29015L14.7316 5.80642L12.4287 8.12035L17.0678 8.08772L18.5386 6.60118C19.9242 5.20891 22.1781 5.20891 23.563 6.60118C24.9487 7.99345 24.9479 10.2581 23.563 11.6503L20.1379 15.1143L20.1371 15.1129Z"
        fill={accentColor}
      />
    </Svg>
  );
};
