import React, { useEffect, useState } from 'react';
import { getText } from 'assets/localization/localization';
import { PencilIcon } from 'assets/icons';
import { MessagePlansModal } from './MessagePlansModal';
import { useInternalUseState } from './internal-use-store';
import { useAppStateStore } from '../../../store/app-store';
import {
  setInternalUseBillableUsage,
  setInternalUseNonBillableEmailUsage,
  setInternalUseNonBillableSmsUsage,
} from './internal-use-actions';
import SettingsPageLayout from '../../../components/Settings/SettingsPageLayout';
import SettingsSection from '../../../components/Settings/SettingsSection';
import { MessagePlansItemRender } from './MessagePlansItemRender';

export default function BillingSettings() {
  const { internalUseBillableUsage, internalUseNonBillableUsage } =
    useInternalUseState();
  const { locationId, userMostRecentLocations } = useAppStateStore();

  const selectedLocation = userMostRecentLocations.find(
    (x) => x.location_id === locationId,
  );
  useEffect(() => {
    setInternalUseNonBillableEmailUsage(locationId);
    setInternalUseNonBillableSmsUsage(locationId);
    setInternalUseBillableUsage(locationId);
  }, [locationId]);

  return (
    <SettingsPageLayout title={getText('internal-use')}>
      <SettingsSection
        title={getText('billable-limits')}
        subtitle={`${selectedLocation?.location_full_display_name}`}
        contentHeader={{
          title: getText('details'),
          button: {
            title: getText('edit'),
            onPress: () =>
              useInternalUseState.setState({
                showInternalUseModal: true,
              }),
            icon: PencilIcon,
          },
        }}
        contentData={internalUseBillableUsage}
        contentItem={MessagePlansItemRender}
        customTypeModal={MessagePlansModal}
      />
      <SettingsSection
        title={getText('non-billable-usage')}
        subtitle={`${selectedLocation?.location_full_display_name}`}
        contentHeader={{
          title: getText('details'),
        }}
        contentData={internalUseNonBillableUsage}
        contentItem={MessagePlansItemRender}
      />
    </SettingsPageLayout>
  );
}
