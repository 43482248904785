import { FunctionComponent } from 'react';
import { View } from 'react-native';
import { Button } from 'assets/components/button';
import { Text } from 'assets/components/text';
import { MessageCircleIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { notImplementedAlert } from 'assets/utils/alert';
import { makeStyles, useTheme } from 'assets/theme';
import { useMessagingStyles } from '../common/styles';
import { Icon } from 'assets/components/icon';

export const PharmacyWithoutConversationsView: FunctionComponent = () => {
  const theme = useTheme();
  const messagingStyles = useMessagingStyles();
  const styles = useStyles();

  return (
    <View style={messagingStyles.noPatientsWrapper}>
      <Icon size={45} icon={MessageCircleIcon} />
      <Text style={styles.noPatientsText}>{getText('no-messages')}</Text>
      <Text>{getText('add-mobile-number-send-message')}</Text>
      <Button
        hierarchy="secondary"
        size="small"
        logger={{ id: 'add-mobile-number-button' }}
        style={{ marginTop: theme.getSpacing(1.5) }}
        onPress={notImplementedAlert}
      >
        {getText('add-mobile-number')}
      </Button>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  noPatientsWrapper: {
    alignItems: 'center',
    marginTop: '15%',
  },
  noPatientsText: {
    ...theme.fonts.medium,
    fontSize: 20,
    marginTop: theme.getSpacing(1.5),
  },
}));
