import React, { FunctionComponent, PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';

import { Text } from 'assets/components/text';
import theme, { getSpacing } from 'assets/theme';
import { PharmacyScreenContainer } from 'assets/layout';

const SettingsPageLayout: FunctionComponent<
  PropsWithChildren<SettingsPageLayoutProps>
> = ({ title, children }) => {
  return (
    <PharmacyScreenContainer
      screenHeader={
        <View style={styles.container}>
          <Text style={styles.title} selectable>
            {title}
          </Text>
        </View>
      }
      disableScrollView={true}
    >
      {children}
    </PharmacyScreenContainer>
  );
};

const styles = StyleSheet.create({
  container: {
    marginHorizontal: getSpacing(3),
  },
  title: {
    ...theme.lumistryFonts.label.xLarge.semiBold,
    fontSize: 25,
    paddingVertical: theme.getSpacing(2),
    marginBottom: getSpacing(2),
    borderBottomColor: theme.palette.gray[600],
    borderBottomWidth: 1,
  },
});

interface SettingsPageLayoutProps {
  title: string;
}

export default SettingsPageLayout;
