import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { FILE_STORAGE_SERVICE_BASE_URL } from '../common/constants';
import { BaseApiConfiguration, BaseApiService } from 'assets/core/api';
import {
  DefaultApi as FileStorageClient,
  FileUploadResponseDto,
  LocationCategory,
  PharmacyCategory,
  PublicCategory,
} from '@digitalpharmacist/file-storage-service-client-axios';
import { AXIOS_DEFAULT_REQUEST_CONFIG } from '../common/axios-default-request-config';
import { logError } from 'assets/logging/logger';

export class FileStorageService extends BaseApiService {
  private fileStorageServiceClient: FileStorageClient;
  public imageExtensions: string[] = [
    'apng',
    'avif',
    'gif',
    'jpg',
    'jpeg',
    'jfif',
    'pjpeg',
    'pjp',
    'png',
    'svg',
    'webp',
    'heic',
  ]; // TODO: consider checking mime type or content disposition instead

  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration,
  ) {
    super(baseUrl, config, enableAuth, baseConfig);
    this.fileStorageServiceClient = new FileStorageClient(
      undefined,
      baseUrl,
      this.axiosInstance,
    );
  }

  private async upload(
    file: File | Blob,
    path: string,
  ): Promise<AxiosResponse<unknown, unknown>> {
    return await axios.put(path, file, {
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    });
  }

  private async uploadBase64File(
    base64Content: string,
    path: string,
  ): Promise<AxiosResponse<unknown, unknown>> {
    const blob = await fetch(base64Content).then((r) => r.blob());
    const response = await this.upload(blob, path);
    return response;
  }

  private async uploadNormalFile(
    file: File,
    path: string,
  ): Promise<AxiosResponse<unknown, unknown>> {
    const response = this.upload(file, path);
    return response;
  }

  async uploadFile(
    file: File | string,
    path: string,
  ): Promise<AxiosResponse<unknown, unknown>> {
    if (typeof file === 'string') return this.uploadBase64File(file, path);
    return this.uploadNormalFile(file, path);
  }

  async writeUrl(
    category: LocationCategory,
    locationId: string,
    fileName: string,
    pharmacyId: string,
  ): Promise<any> {
    return await this.fileStorageServiceClient.fileStorageWriteUrl(
      category,
      locationId,
      fileName,
      pharmacyId,
    );
  }

  async publicBrandingWriteUrl(
    category: PublicCategory,
    fileName: string,
    pharmacyId: string,
  ): Promise<FileUploadResponseDto> {
    const { data } =
      await this.fileStorageServiceClient.fileStorageWriteUrlPublicPharmacy(
        category,
        fileName,
        pharmacyId,
      );
    return data;
  }

  async readUrlWithPharmacyCategory(
    category: PharmacyCategory,
    fileName: string,
    pharmacyId: string,
  ): Promise<any> {
    const { data } =
      await this.fileStorageServiceClient.fileStorageReadUrlPharmacy(
        category,
        fileName,
        pharmacyId,
      );
    return data;
  }

  async readUrl(
    category: LocationCategory,
    locationId: string,
    fileName: string,
    pharmacyId: string,
  ): Promise<any> {
    return await this.fileStorageServiceClient.fileStorageReadUrl(
      category,
      locationId,
      fileName,
      pharmacyId,
    );
  }

  getFileExtension(name: string): string {
    const attachmentParts = name.split('.');
    return (attachmentParts[attachmentParts.length - 1] || '').toLowerCase();
  }

  isImage(fileName: string): boolean {
    const extension = this.getFileExtension(fileName);
    return this.imageExtensions.includes(extension);
  }

  async uploadPrivatePharmacyLevelFile(
    category: PharmacyCategory,
    fileName: string,
    pharmacyId: string,
    file: File | string,
  ): Promise<string | undefined> {
    try {
      const { data } =
        await this.fileStorageServiceClient.fileStorageWriteUrlPharmacy(
          category,
          fileName,
          pharmacyId,
        );

      await this.uploadFile(file, data.url);
      return data.url;
    } catch (error) {
      logError(error as Error);
      throw error;
    }
  }

  async getPrivatePharmacyLevelFile(
    category: PharmacyCategory,
    fileName: string,
    pharmacyId: string,
  ): Promise<string> {
    const { data } =
      await this.fileStorageServiceClient.fileStorageReadUrlPharmacy(
        category,
        fileName,
        pharmacyId,
      );
    return data.url;
  }

  async uploadPublicPharmacyLevelFile(
    category: PublicCategory,
    fileName: string,
    pharmacyId: string,
    file: File | string,
  ): Promise<string | undefined> {
    try {
      const { data } =
        await this.fileStorageServiceClient.fileStorageWriteUrlPublicPharmacy(
          category,
          fileName,
          pharmacyId,
        );

      await this.uploadFile(file, data.url);
      return data.url;
    } catch (error) {
      logError(error as Error);
      throw error;
    }
  }

  async getPublicPharmacyLevelFile(
    category: PublicCategory,
    fileName: string,
    pharmacyId: string,
  ): Promise<string> {
    const { data } =
      await this.fileStorageServiceClient.fileStorageReadUrlPublicPharmacy(
        category,
        fileName,
        pharmacyId,
      );
    return data.url;
  }

  async uploadPrivateLocationLevelFile(
    category: LocationCategory,
    locationId: string,
    fileName: string,
    pharmacyId: string,
    file: File | string,
  ): Promise<string | undefined> {
    try {
      const { data } = await this.fileStorageServiceClient.fileStorageWriteUrl(
        category,
        locationId,
        fileName,
        pharmacyId,
      );

      await this.uploadFile(file, data.url);
      return data.url;
    } catch (error) {
      logError(error as Error);
      throw error;
    }
  }

  async getPrivateLocationLevelFile(
    category: LocationCategory,
    locationId: string,
    fileName: string,
    pharmacyId: string,
  ): Promise<string> {
    const { data } = await this.fileStorageServiceClient.fileStorageReadUrl(
      category,
      locationId,
      fileName,
      pharmacyId,
    );
    return data.url;
  }

  async uploadPublicLocationLevelFile(
    category: PublicCategory,
    locationId: string,
    fileName: string,
    pharmacyId: string,
    file: File | string,
  ): Promise<string | undefined> {
    try {
      const { data } =
        await this.fileStorageServiceClient.fileStorageWriteUrlPublic(
          category,
          locationId,
          fileName,
          pharmacyId,
        );

      await this.uploadFile(file, data.url);
      return data.url;
    } catch (error) {
      logError(error as Error);
      throw error;
    }
  }

  async getPublicLocationLevelFile(
    category: PublicCategory,
    locationId: string,
    fileName: string,
    pharmacyId: string,
  ): Promise<string> {
    const { data } =
      await this.fileStorageServiceClient.fileStorageReadUrlPublic(
        category,
        locationId,
        fileName,
        pharmacyId,
      );
    return data.url;
  }
}

export default new FileStorageService(
  FILE_STORAGE_SERVICE_BASE_URL,
  AXIOS_DEFAULT_REQUEST_CONFIG,
  true,
);
