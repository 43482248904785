export const FormTestIDs = {
  formsHeader: 'forms-header',
  inactiveFormsHeader: 'inactive-forms-header',
  formsGridWrapper: 'forms-grid-wrapper',
  formStatusCheckboxContainer: 'form-status-checkbox-container',
  editFormName: 'edit-form-name',
  dropdownWidgetInputBox: 'dropdown-widget-input-box',
  formsGrid: 'forms-grid',
  addressWidgetInput: 'address-widget-input',
  dateWidgetInput: 'date-widget-input',
  fileUploadWidgetInput: 'file-upload-widget-input',
  fullNameWidgetInput: 'full-name-widget-input',
  inputWidgetInput: 'input-widget-input',
  inputTableWidgetInput: 'input-table-widget-input',
  singleCheckboxWidgetInput: 'single-checkbox-widget-input',
  titleWidgetInput: 'title-widget-input',
  formsSidebarWrapper: 'forms-sidebar-wrapper',
  formBuilderWrapper: 'form-builder-Wrapper',
};

export const SubmissionTestIDs = {
  submissionWrapper: 'submission-wrapper',
  submissionsTableWrapper: 'submissions-table-wrapper',
  submissionPatientName: 'submission-patient-name',
  submissionPatientDob: 'submission-patient-dob',
  submissionFormName: 'submission-form-name',
  submissionFormErrorNavigationContainer:
    'submission-form-error-navigation-container',
};
