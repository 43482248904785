import {
  PharmacyLocationDto,
  UpdatePharmacyLocationDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import * as validate from '@digitalpharmacist/validation-dp';
import { CheckboxBase } from 'assets/components/checkbox/CheckboxBase';
import { Modal } from 'assets/components/modal';
import { Text } from 'assets/components/text';
import { TextField } from 'assets/components/text-field';
import { useForm } from 'assets/form';
import { Form } from 'assets/layout/form/Form';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import _isEqual from 'lodash/isEqual';
import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { TouchableOpacity, View } from 'react-native';
import { AddressField } from '../../../onboarding/components/address/AddressField';
import {
  setLocation,
  setShowModalLocationContactInfo,
  updateLocation,
} from '../location-settings-actions';
import { useLocationSettingsState } from '../location-settings-store';

const convertToFormValues = (
  location?: PharmacyLocationDto,
): LocationContactInfoItemFormProps => {
  return {
    phone: location?.phone,
    fax: location?.fax,
    email: location?.email,
    address: location?.address
      ? {
          address1: location.address.address1,
          address2: location.address.address2,
          postal_code: location.address.postal_code,
          city: location.address.city,
          country: location.address.country,
          state: location.address.state,
        }
      : undefined,
    billing_address: location?.billing_address
      ? {
          address1: location.billing_address.address1,
          address2: location.billing_address.address2,
          postal_code: location.billing_address.postal_code,
          city: location.billing_address.city,
          country: location.billing_address.country,
          state: location.billing_address.state,
        }
      : undefined,
  };
};

export const LocationContactInfoModal: FunctionComponent<
  PropsWithChildren<any>
> = () => {
  const { showModalLocationContactInfo, locationId, location } =
    useLocationSettingsState();
  const styles = useStyles();

  const [isChecked, setIsChecked] = useState(false);
  const [loadingMethod, setLoadingMethod] = useState(false);

  useEffect(() => {
    methods.reset(convertToFormValues(location));
  }, [location]);

  const methods = useForm<LocationContactInfoItemFormProps>({
    defaultValues: convertToFormValues(location),
    mode: 'onChange',
  });

  const handleSubmit = async () => {
    setLoadingMethod(true);
    const formValue = methods.getValues();
    if (
      !_isEqual(formValue, convertToFormValues(location)) &&
      locationId &&
      location?.pharmacy_id
    ) {
      const updatedLocation: UpdatePharmacyLocationDto = formValue;
      await Promise.all([
        updateLocation(locationId, updatedLocation),
        setLocation(locationId || ''),
      ]);
    }
    setShowModalLocationContactInfo(false);
    setLoadingMethod(false);
  };

  const closeModal = () => {
    setShowModalLocationContactInfo(false);
    methods.reset(convertToFormValues(location));
  };

  useEffect(() => {
    const formValue = methods.getValues();
    if (isChecked) {
      methods.reset({
        ...formValue,
        billing_address: formValue.address,
      });
    }
  }, [methods.watch().address, isChecked]);

  const setMyTasksCheckboxFilter = () => {
    setIsChecked(!isChecked);
  };

  return (
    <Modal
      title={getText('locations-edit-contact-info')}
      titleSize="sm"
      size="lg"
      dismissButtonProps={{
        onPress: closeModal,
        logger: { id: 'location-contact-info-form-cancel-button-modal' },
      }}
      cancelButtonProps={{
        onPress: closeModal,
        hierarchy: 'tertiary-gray',
        logger: { id: 'location-contact-info-form-cancel-button-modal' },
      }}
      okButtonProps={{
        onPress: methods.handleSubmit(handleSubmit),
        logger: { id: 'location-contact-info-form-ok-button-modal' },
        hierarchy: 'primary',
        text: getText('ok'),
        loading: loadingMethod,
      }}
      show={showModalLocationContactInfo}
      isScrollable={true}
    >
      <Form methods={methods}>
        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>
                {getText('locations-phone-number')}
              </Text>
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="phone"
              type="telephoneNumber2"
              rules={{
                validate: {
                  value: (value: string) => {
                    return !value ||
                      validate.isPhoneNumber(value ? '+1' + value : '')
                      ? true
                      : getText('phone-is-not-valid');
                  },
                },
              }}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>
                {getText('locations-fax-number')}
              </Text>
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="fax"
              type="telephoneNumber2"
              rules={{
                validate: {
                  value: (value: string) => {
                    return !value ||
                      validate.isPhoneNumber(value ? '+1' + value : '')
                      ? true
                      : getText('phone-is-not-valid');
                  },
                },
              }}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>
                {getText('locations-email-address')}
              </Text>
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="email"
              type="emailAddress"
              rules={{
                validate: (value: string) => {
                  return !value || validate.isEmail(value)
                    ? true
                    : getText('email-is-not-valid');
                },
              }}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textViewAddress}>
              <Text style={styles.textFormat}>
                {getText('pharmacy-store-address')}
              </Text>
            </View>
          </Form.Column>
          <Form.Column style={styles.inputWrapper}>
            <AddressField name={'address'} rules={{ required: true }} />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textViewAddress}>
              <Text style={styles.textFormat}>
                {getText('account-billing-address')}
              </Text>
            </View>
          </Form.Column>
          <Form.Column style={styles.inputWrapper}>
            <Form.Row>
              <>
                <Form.Column>
                  <TouchableOpacity onPress={setMyTasksCheckboxFilter}>
                    <View style={styles.checkboxContainer}>
                      <CheckboxBase
                        onPress={setMyTasksCheckboxFilter}
                        isChecked={isChecked}
                      />
                      <Text style={styles.checkboxLabel}>
                        {getText('same-as-pharmacy-store-address')}
                      </Text>
                    </View>
                  </TouchableOpacity>
                </Form.Column>
              </>
            </Form.Row>
            <AddressField
              name={'billing_address'}
              rules={{ required: false }}
              disabled={isChecked}
            />
          </Form.Column>
        </Form.Row>
      </Form>
    </Modal>
  );
};

interface AddressForm {
  address1: string;
  address2?: string;
  postal_code: string;
  city: string;
  state: string;
  country: string;
}

interface LocationContactInfoItemFormProps {
  phone?: string;
  fax?: string;
  email?: string;
  address?: AddressForm;
  billing_address?: AddressForm;
}

const useStyles = makeStyles((theme) => ({
  inputWrapper: {
    display: 'flex',
    gap: theme.getSpacing(1),
    alignSelf: 'stretch',
    flex: 2,
  },
  inputStyle: {
    height: 44,
  },
  textWrapper: {
    flexDirection: 'column',
    flex: 1,
  },
  textView: {
    display: 'flex',
    alignItems: 'flex-start',
    height: 44,
  },
  textFormat: {
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 24,
  },
  dropdownWrapper: {
    display: 'flex',
    flex: 1,
  },
  textInputWrapper: {
    display: 'flex',
    flex: 3,
  },
  row: {
    alignContent: 'stretch',
  },
  textViewAddress: {
    display: 'flex',
    alignItems: 'baseline',
    height: 24,
    textAlignVertical: 'center',
  },
  checkboxContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    lineHeight: 24,
  },
  checkboxLabel: {
    fontSize: 16,
    lineHeight: 24,
    marginLeft: theme.getSpacing(1),
    color: theme.palette.gray[700],
    fontWeight: '500',
  },
}));
