import {
  BrandingDto,
  PharmacyAppDto,
  PharmacyWebsiteDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import create from 'zustand';
import { PatientAgreementType } from '../../../store/checklist-store';
import { AsyncStatus } from '../../../store/types';

export interface BrandingSettingsState {
  showModalBrandingInfo: boolean;
  showModalWebsite: boolean;
  showModalApp: boolean;
  showPatientAgreementModal: boolean;
  branded_colors: BrandingDto;
  pharmacyWebsite?: PharmacyWebsiteDto;
  pharmacyApp?: PharmacyAppDto;
  status: AsyncStatus;
  patientAgreementsDocs?: PatientAgreementType;
}

export const useAppSettingsState = create<BrandingSettingsState>(() => ({
  showModalBrandingInfo: false,
  showModalWebsite: false,
  showModalApp: false,
  showPatientAgreementModal: false,
  status: 'loading',
  branded_colors: {} as BrandingDto,
}));
