import { Actions, Effect, Resources, Role } from './permission-types';

interface Rule {
  actions: Array<Actions | '*'>;
  effect: Effect;
  roles: Role[];
}
export interface rolePolicy {
  rules: Rule[];
}
const defaultStaffActs: Actions[] = ['create', 'read', 'update'];
const defaultManagerActs: Actions[] = [...defaultStaffActs, 'delete'];
const defaultBrandActs: Actions[] = [...defaultManagerActs, 'manageLocations'];
//gte, greater than or equal
const gteBrandRoles: Role[] = [
  'BRAND',
  'CORPORATE',
  'IT_ADMIN',
  'LUMISTRY_USER',
  'LUMISTRY_ADMIN',
]; // all roles inclusive and higher than corporate
const internalRoles: Role[] = ['LUMISTRY_USER', 'LUMISTRY_ADMIN'];
const allRoles: Role[] = [...gteBrandRoles, 'STAFF', 'MANAGER'];

/**
 * The policy interface is designed to be compatible
 * with Cerbos policy to allow for future drop in change
 */
export const policies: { [key in Resources]: rolePolicy } = {
  settings: {
    rules: [
      {
        actions: ['update'],
        effect: 'EFFECT_ALLOW',
        roles: gteBrandRoles,
      },
      {
        actions: ['read'],
        effect: 'EFFECT_ALLOW',
        roles: [...gteBrandRoles, Role.Manager],
      },
    ],
  },
  forms: {
    rules: [
      {
        actions: [...defaultStaffActs],
        effect: 'EFFECT_ALLOW',
        roles: [Role.Staff],
      },
      {
        actions: [...defaultManagerActs],
        effect: 'EFFECT_ALLOW',
        roles: [Role.Manager],
      },
      {
        actions: [...defaultBrandActs], // Not make public!
        effect: 'EFFECT_ALLOW',
        roles: [Role.Brand, Role.Corporate],
      },
      {
        actions: ['*'],
        effect: 'EFFECT_ALLOW',
        roles: [...internalRoles],
      },
    ],
  },
  tasks: {
    rules: [
      {
        actions: ['*'],
        effect: 'EFFECT_ALLOW',
        roles: [Role.Brand, Role.Corporate],
      },
      {
        actions: ['read'],
        effect: 'EFFECT_ALLOW',
        roles: [Role.Staff],
      },
      {
        actions: ['read', 'update', 'delete'],
        effect: 'EFFECT_ALLOW',
        roles: [Role.Manager],
      },
      {
        actions: ['*'],
        effect: 'EFFECT_ALLOW',
        roles: [...internalRoles],
      },
    ],
  },
  // Users list UI
  pharmacyUsers: {
    rules: [
      {
        actions: ['read', 'update', 'delete', 'create'],
        effect: 'EFFECT_ALLOW',
        roles: [...gteBrandRoles, Role.Manager],
      },
    ],
  },
  // Users list UI
  pharmacyUserRoles: {
    rules: [
      {
        actions: ['read'],
        effect: 'EFFECT_ALLOW',
        roles: [Role.Staff],
      },
      {
        actions: ['read', 'manage:role:STAFF', 'manage:role:MANAGER'],
        effect: 'EFFECT_ALLOW',
        roles: [Role.Manager],
      },
      {
        actions: [
          'read',
          'manage:role:STAFF',
          'manage:role:MANAGER',
          'manage:role:BRAND',
        ],
        effect: 'EFFECT_ALLOW',
        roles: [Role.Brand],
      },
      {
        actions: [
          'read',
          'manage:role:STAFF',
          'manage:role:MANAGER',
          'manage:role:BRAND',
          'manage:role:CORPORATE',
        ],
        effect: 'EFFECT_ALLOW',
        roles: [Role.Corporate],
      },
      {
        actions: [
          'read',
          'manage:role:STAFF',
          'manage:role:MANAGER',
          'manage:role:BRAND',
          'manage:role:CORPORATE',
          'manage:role:IT_ADMIN',
        ],
        effect: 'EFFECT_ALLOW',
        roles: [Role.ItAdmin, ...internalRoles],
      },
      {
        actions: ['*'],
        effect: 'EFFECT_ALLOW',
        roles: [Role.LumistryAdmin],
      },
    ],
  },
  settingsLumistryUser: {
    rules: [
      {
        actions: ['update'],
        effect: 'EFFECT_ALLOW',
        roles: [...internalRoles],
      },
      {
        actions: ['read'],
        effect: 'EFFECT_ALLOW',
        roles: internalRoles,
      },
    ],
  },
  settingsPmsIntegration: {
    rules: [
      {
        actions: ['*'],
        effect: 'EFFECT_ALLOW',
        roles: [...internalRoles],
      },
    ],
  },
  settingsItAdmin: {
    rules: [
      {
        actions: ['*'],
        effect: 'EFFECT_ALLOW',
        roles: [Role.ItAdmin, ...internalRoles],
      },
    ],
  },
  appIcon: {
    rules: [
      {
        actions: ['*'],
        effect: 'EFFECT_ALLOW',
        roles: [...internalRoles],
      },
      {
        // Technically not use today, but scemantically correct.
        actions: ['read'],
        effect: 'EFFECT_ALLOW',
        roles: [...allRoles],
      },
    ],
  },
  appAgreements: {
    rules: [
      {
        actions: ['read'],
        effect: 'EFFECT_ALLOW',
        roles: [...gteBrandRoles, Role.Manager],
      },
      {
        actions: ['update'],
        effect: 'EFFECT_ALLOW',
        roles: [...internalRoles, Role.ItAdmin],
      },
    ],
  },
};
