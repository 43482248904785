import Svg, { Path } from 'react-native-svg';
import { IconProps } from './types';
import { FC } from 'react';

export const CancelCircleIcon: FC<IconProps> = ({
  color,
  size,
  testID,
  strokeWidth,
}) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      color={color}
      testID={testID}
    >
      <Path
        d="M4.108 4.108l11.784 11.784M18.333 10a8.333 8.333 0 11-16.666 0 8.333 8.333 0 0116.666 0z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
