import { Text } from 'assets/components/text';
import { makeStyles } from 'assets/theme';
import { View } from 'react-native';

import { Button } from 'assets/components/button';
import { LumistryHeartIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { FunctionComponent, useEffect, useState } from 'react';
import { fetchChecklist } from '../../../actions/checklist-actions';
import { useAppStateStore } from '../../../store/app-store';
import { useChecklistStore } from '../../../store/checklist-store';

const ChecklistCard: FunctionComponent<ChecklistCardProps> = ({
  hierarchy = 'primary',
  onPress,
}) => {
  const styles = useStyles();
  const isPrimary = hierarchy === 'primary';
  const [showCard, setShowCard] = useState<boolean>(false);
  const checklistOptions = useChecklistStore((x) => x.checklistOptions);
  const locationId = useAppStateStore((x) => x.locationId);

  useEffect(() => {
    fetchChecklist();
  }, [locationId]);

  useEffect(() => {
    if (!checklistOptions) return;

    const itemsNotCompleted = checklistOptions.some((x) => !x.completed);
    const basicSetupCompleted = checklistOptions.some(
      (x) => x.slug === 'basic-setup' && x.completed,
    );

    if (itemsNotCompleted && basicSetupCompleted) {
      setShowCard(true);
    }
  }, [checklistOptions]);

  return (
    <>
      {showCard && (
        <View style={[styles.wrapper, isPrimary && styles.primary]}>
          <View style={styles.titleContainer}>
            <Text style={styles.title}>{getText('checklist-card-title')}</Text>
            {isPrimary && <LumistryHeartIcon />}
          </View>
          <View style={styles.subtitleContainer}>
            <Text style={styles.subtitle}>
              {getText('checklist-card-subtitle')}
            </Text>
          </View>
          <View style={styles.buttonContainer}>
            <Button
              hierarchy={'secondary'}
              logger={{
                id: 'checklist-card-open-button',
              }}
              onPress={onPress}
              style={styles.button}
            >
              {getText('checklist-card-button')}
            </Button>
          </View>
        </View>
      )}
    </>
  );
};

export default ChecklistCard;

type ChecklistCardProps = {
  onPress: () => void;
  hierarchy: 'primary' | 'secondary';
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: 140,
    paddingVertical: theme.getSpacing(1),
    paddingHorizontal: theme.getSpacing(1.5),
    borderRadius: theme.roundness,
    gap: theme.getSpacing(1),
  },
  primary: {
    backgroundColor: theme.palette.primary[100],
  },
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 30,
  },
  title: {
    color: theme.palette.gray[900],
    ...theme.lumistryFonts.text.large.bold,
  },
  subtitleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 30,
  },
  subtitle: {
    color: theme.palette.gray[700],
    ...theme.lumistryFonts.text.medium.regular,
  },
  buttonContainer: {
    paddingVertical: theme.getSpacing(1),
    flexDirection: 'row',
  },
  button: {
    minWidth: 120,
  },
}));
