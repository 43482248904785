import { AxiosRequestConfig } from 'axios';
import { BaseApiConfiguration, BaseApiService } from 'assets/core/api';
import { PRESCRIPTION_API_BASE_URL } from '../common/constants';
import {
  PharmacistApi as PrescriptionServiceClient,
  RefillItemDto,
  RefillSubmittedDto,
  UpdateLocationConfigDto,
} from '@digitalpharmacist/prescription-service-client-axios';
import { RefillSidebarDetails } from '../screens/refills/refills-types';
import { AXIOS_DEFAULT_REQUEST_CONFIG } from '../common/axios-default-request-config';
import { SearchableDatasourceService } from './SearchableDatasourceService';
import { RefillStatus } from '@digitalpharmacist/pms-integration-service-client-axios';
import patientService from './PatientService';

export interface IPrescriptionService {
  findRefills(locationId: string): Promise<RefillItemDto[]>;
}

export class PrescriptionService
  extends BaseApiService
  implements IPrescriptionService
{
  private prescriptionServiceClient: PrescriptionServiceClient;
  private searchableDatasourceService: SearchableDatasourceService;

  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration,
  ) {
    super(baseUrl, config, enableAuth, baseConfig);

    this.prescriptionServiceClient = new PrescriptionServiceClient(
      undefined,
      baseUrl,
      this.axiosInstance,
    );
    this.searchableDatasourceService = new SearchableDatasourceService(
      baseUrl,
      config,
      enableAuth,
      baseConfig,
    );
  }

  getRefillSubmissionsDatasource(locationId: string) {
    return this.searchableDatasourceService.getSearchableDatasource(
      `/locations/${locationId}/refills/items`,
    );
  }

  async getRefillsCount(locationId: string) {
    const response = await this.axiosInstance.get(
      `/locations/${locationId}/refills/items`,
      {
        params: {
          limit: 1,
          offset: 0,
          order_by: 'submitted_at:desc',
        },
      },
    );
    return response.data.total;
  }

  getLastNotPassedRefillForDashboard(locationId: string, limit?: number) {
    return this.axiosInstance.get(`/locations/${locationId}/refills/items`, {
      params: {
        limit: limit ?? 5,
        offset: 0,
        order_by: 'submitted_at:desc',
        refill_status: 'rejected',
      },
    });
  }

  async findPrescriptionRefills(
    locationId: string,
  ): Promise<RefillSubmittedDto[]> {
    const prescriptions =
      await this.prescriptionServiceClient.refillsFindByLocation(locationId);

    return prescriptions.data.results;
  }

  async findRefill(locationId: string, patientId: string, refillId: string) {
    const prescriptions = await this.prescriptionServiceClient.refillsFindOne(
      patientId,
      refillId,
      locationId,
    );

    return prescriptions.data;
  }

  fillPatientDetailsSidebar = async (
    locationId: string,
    selectedRefillSubmission: RefillSubmittedDto,
  ): Promise<RefillSidebarDetails> => {
    const patientServiceResponse =
      await patientService.getPatientLocationRecord(
        locationId,
        selectedRefillSubmission.patient_id,
      );

    return {
      patientDetails: patientServiceResponse,
      prescriptionDetails: selectedRefillSubmission,
    };
  };

  async findRefills(locationId: string): Promise<RefillItemDto[]> {
    const response =
      await this.prescriptionServiceClient.refillsFindByLocation(locationId);
    return response.data.results;
  }

  async findFailedRefillsCount(locationId: string): Promise<number> {
    const response = await this.prescriptionServiceClient.refillsFindByLocation(
      locationId,
      1,
      0,
      undefined,
      RefillStatus.Rejected,
    );

    return response.data.total;
  }

  async findPrescription(
    locationId: string,
    locationPatientRecordId: string,
    prescriptionId: string,
  ) {
    const response = await this.prescriptionServiceClient.prescriptionFindOne(
      prescriptionId,
      locationPatientRecordId,
      locationId,
    );
    return response.data;
  }

  async findPrescriptions(locationId: string, locationPatientRecordId: string) {
    const response = await this.prescriptionServiceClient.prescriptionFindAll(
      locationPatientRecordId,
      locationId,
    );
    return response.data;
  }

  async updateRefillRxStatus(
    location_id: string,
    refill_item_id: string,
    refill_status: RefillStatus,
  ) {
    const response =
      await this.prescriptionServiceClient.refillsUpdateRefillStatus(
        refill_item_id,
        location_id,
        {
          refill_status,
        },
      );
    return response.data;
  }

  async getLocationConfig(location_id: string) {
    const response =
      await this.prescriptionServiceClient.locationConfigFind(location_id);

    return response.data;
  }

  async locationConfigCreate(
    location_id: string,
    updateLocationConfig: UpdateLocationConfigDto,
  ) {
    const response = await this.prescriptionServiceClient.locationConfigCreate(
      location_id,
      updateLocationConfig,
    );

    return response.data;
  }
}

export default new PrescriptionService(
  PRESCRIPTION_API_BASE_URL,
  AXIOS_DEFAULT_REQUEST_CONFIG,
  true,
);
