import { ClosureHoursDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { FunctionComponent, PropsWithChildren } from 'react';
import { convertClosuresDateToString } from '../../../../common/datetime-utils';
import { RowItemRender } from '../../../../components/Settings/RowItemRender';

export const AdditionalClosuresItemRenderer: FunctionComponent<
  PropsWithChildren<ClosureHoursDto>
> = ({ name, timeRange }) => {
  return (
    <RowItemRender
      title={name || ''}
      value={convertClosuresDateToString(timeRange)}
    />
  );
};
