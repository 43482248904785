import { useEffect, useRef, useState } from 'react';
import { socket } from '../../../../socket';
import { UserTyping } from 'assets/types/messageTypes';
import { useUserState } from '../../../store/user-store';
import { useChatState } from '../stores/chat-store/chat-store';
import { useAppStateStore } from '../../../store/app-store';
import { AuthorType } from '@digitalpharmacist/unified-communications-service-client-axios';

export const useTypingInfo = () => {
  const locationId = useAppStateStore((state) => state.locationId);
  const user = useUserState((state) => state.data);
  const userId = user?.id;
  const selectedPatient = useChatState((state) => state.selectedPatient);
  const [typingMember, setTypingMember] = useState<UserTyping | null>();
  const typingTimer = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    const onTypingReceived = (userTyping: UserTyping) => {
      if (userTyping.id === userId) {
        return;
      }

      clearTimeout(typingTimer.current);
      setTypingMember(userTyping);

      typingTimer.current = setTimeout(() => {
        setTypingMember(null);
      }, 1000);
    };

    socket.on('typing', onTypingReceived);
    return () => {
      socket.off('typing', onTypingReceived);
    };
  }, []);

  const onType = (conversation_id: string) => {
    if (user && selectedPatient?.id) {
      const userTyping: UserTyping = {
        id: userId!,
        name: `${user.firstName} ${user.lastName}`,
        conversation_id: conversation_id,
        location_id: locationId,
        location_patient_id: selectedPatient.id,
        author_type: AuthorType.Pharmacy,
      };
      socket.emit('typing', userTyping);
    }
  };

  return {
    typingMember,
    onType,
  };
};
