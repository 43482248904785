/** "extends" the manage user modal */
import { DetailedUserRole } from '@digitalpharmacist/role-service-client-axios';
import { useForm } from 'assets/form';
import { getText } from 'assets/localization/localization';
import { logError } from 'assets/logging/logger';
import React, { FunctionComponent, useEffect } from 'react';
import RoleService from '../../../api/RoleService';
import { useToast } from '../../../common/hooks/useToast';
import { useAppStateStore } from '../../../store/app-store';
import { ManageUserModalBase } from './ManageUserModal';
import { updatePharmacyUser } from './UserActions';
import { RoleOption, getHighestRole, getUserRoles } from './UserHelpers';
import { UserForm } from './UserTypes';
import useViewSidebarStore from './view-user-sidebar-store';

export const EditUserModal: FunctionComponent<EditUserProps> = ({
  refreshTable,
  show,
  onHide,
}) => {
  const pharmacyId = useAppStateStore((x) => x.pharmacyId);
  const { toast } = useToast();
  const { userDetails, setShowUserEdit, setUserRoles, setShowSidebar } =
    useViewSidebarStore();

  if (!userDetails) {
    const message = 'user details is not defined at edit user modal';
    const e = new Error(message);
    logError(e);
    throw e;
  }

  const methods = useForm<UserForm>({
    defaultValues: {
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      email: userDetails.email,
      roles: getUserRoles(userDetails.user_roles),
      role: getHighestRole(userDetails.user_roles.map((x) => x.role)),
    },
  });

  useEffect(() => {
    const { firstName, lastName, email, user_roles } = userDetails;
    const roles = getUserRoles(user_roles);
    const highestRole = getHighestRole(user_roles.map((x) => x.role));
    methods.setValue('firstName', firstName);
    methods.setValue('lastName', lastName);
    methods.setValue('email', email);
    methods.setValue('role', highestRole);
    methods.setValue('roles', roles);
    methods.setValue('userId', userDetails.userId);
  }, [userDetails, show]);

  const handleEditUser = async () => {
    const userId = methods.getValues('userId');
    if (!userId) {
      return logError(new Error('user id is not defined'));
    }

    try {
      const response = await updatePharmacyUser(userId, {
        ...methods.getValues(),
      });
      ({
        firstName: response.firstName!,
        lastName: response.lastName!,
        email: response.email!,
      });

      setShowSidebar(false);
      setShowUserEdit(false);
    } catch (e) {
      logError(new Error('Edit user failed with error:', e as Error));
    }
  };

  const getEntityId = (role: RoleOption, roleData: DetailedUserRole) => {
    const { entityType } = role;
    const { entity_id } = roleData;

    if (entityType === 'pharmacy' && pharmacyId) {
      return pharmacyId;
    } else if (entityType === 'global') {
      return '*';
    } else {
      return entity_id;
    }
  };

  // Also handles removal of roles
  const handleEditRole = async (role: RoleOption | undefined) => {
    const userId = methods.getValues('userId');
    if (!userId) {
      return logError(new Error('user id is not defined'));
    }

    try {
      const { user_roles: userRoles } = userDetails;
      const highestRole = getHighestRole(userRoles.map((x) => x.role));
      const roleData = userRoles.find((x) => x.role === highestRole);

      const roles =
        role && roleData?.entity_id
          ? [{ role: role.value, entity_id: getEntityId(role, roleData) }]
          : [];

      const response = await RoleService.userRoleReplaceRoles(userId, {
        roles: roles,
      });

      const newUserRoles = response.map((x) => ({ ...x, user_id: userId }));
      setUserRoles(newUserRoles);
      setShowUserEdit(false);
    } catch (e) {
      logError(new Error('Edit role failed with error:', e as Error));
      toast('Error', {
        type: 'error',
        content: getText('user-has-role-error'),
      });
    }
  };

  return (
    <ManageUserModalBase
      title={getText('edit-user')}
      newUserMode={false}
      onEditRole={handleEditRole}
      onEditUser={handleEditUser}
      methods={methods}
      refreshTable={refreshTable}
      show={show}
      onHide={onHide}
    />
  );
};

type EditUserProps = {
  refreshTable: () => void;
  show: boolean;
  onHide: () => void;
};
