import { HolidayDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { FunctionComponent, PropsWithChildren } from 'react';
import { formatDate } from '../../../../common/datetime-utils';
import { RowItemRender } from '../../../../components/Settings/RowItemRender';

export const HolidayClosuresItemRenderer: FunctionComponent<
  PropsWithChildren<HolidayDto>
> = ({ name, next_occurrence }) => {
  return <RowItemRender title={name} value={formatDate(next_occurrence)} />;
};
