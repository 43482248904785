import { Icon } from 'assets/components/icon';
import { Text } from 'assets/components/text';
import { CheckIcon } from 'assets/icons';
import { makeStyles, useTheme } from 'assets/theme';
import { FC } from 'react';
import { View } from 'react-native';

export enum BulletPointStyle {
  Check = 'check',
  Bullet = 'bullet',
}

interface BulletPointProps {
  text: string;
  type: BulletPointStyle;
}
const BulletPoint: FC<BulletPointProps> = ({ text, type }) => {
  const styles = useStyles();
  const theme = useTheme();
  return (
    <View
      style={[
        styles.bulletPoint,
        type === BulletPointStyle.Bullet && styles.bulletPointClose,
      ]}
    >
      {type === BulletPointStyle.Check && (
        <Text>
          <Icon icon={CheckIcon} color={theme.palette.success[500]} size={20} />
        </Text>
      )}
      {type === BulletPointStyle.Bullet && (
        <Text>{String.fromCodePoint(0x2022)}</Text>
      )}
      <Text style={styles.bulletPointText}>{text}</Text>
    </View>
  );
};

export default BulletPoint;

const useStyles = makeStyles((theme) => ({
  bulletPoint: {
    flexDirection: 'row',
    gap: theme.getSpacing(2),
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  bulletPointClose: {
    gap: theme.getSpacing(1),
  },
  bulletPointText: {
    ...theme.lumistryFonts.text.medium.regular,
    color: theme.palette.gray[700],
  },
}));
