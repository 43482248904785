import {
  PickupMethodResponseDto,
  ServiceDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { LocationRefillConfigDto } from '@digitalpharmacist/prescription-service-client-axios';
import create from 'zustand';

export interface TransferMedications {
  name: string;
  transfer: boolean;
}

export interface RefillsSettingsState {
  showModalTransferMedications: boolean;
  showModalPickup: boolean;
  showRefillDueModal: boolean;
  showRefillErrorModal: boolean;
  allServiceOptions: ServiceDto[];
  integrationType?: string;
  allPickupMethods: PickupMethodResponseDto[];
  editingPickupMethod?: PickupMethodResponseDto;
  editingRefillErrors?: LocationRefillConfigDto;
  refillErrors: LocationRefillConfigDto[];
  refillDueReminders: RefillDueReminders[];
}

export const useRefillsSettingsState = create<RefillsSettingsState>(() => ({
  showModalTransferMedications: false,
  showRefillDueModal: false,
  showModalPickup: false,
  showRefillErrorModal: false,
  integrationType: '',
  allServiceOptions: [],
  allPickupMethods: [],
  refillErrors: [],
  refillDueReminders: [],
}));

type RefillDueReminders = {
  key: string;
  label: string;
  modalLabel: string;
  value: number;
};
