import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';

export const RecipientsHeaderRenderer = (props: any) => {
  const styles = useStyles();

  const onSortRequested = () => {
    const currentSort = props.column.getSort();
    let newSort = '';

    // Cycle through the sort states: no sort -> asc -> desc -> no sort
    if (currentSort === 'asc') {
      newSort = 'desc';
    } else if (currentSort === 'desc') {
      newSort = ''; // No sort
    } else {
      newSort = 'asc';
    }

    // Clear sorting on all columns except the current one
    props.columnApi.getAllColumns().forEach((col: any) => {
      if (col.getColId() !== props.column.getColId()) {
        props.columnApi.applyColumnState({
          state: [{ colId: col.getColId(), sort: null }],
          applyOrder: false, // Prevents changing the column order
        });
      }
    });

    // Apply the new sort state to the selected column
    props.columnApi.applyColumnState({
      state: [{ colId: props.column.getColId(), sort: newSort }],
      applyOrder: false, // Prevents changing the column order
    });

    // Force a refresh to ensure layout is correct
    props.api.redrawRows();
  };

  const getSortIcon = () => {
    if (props.column.isSortAscending()) {
      return <i className="ag-icon ag-icon-small-up"></i>;
    }
    if (props.column.isSortDescending()) {
      return <i className="ag-icon ag-icon-small-down"></i>;
    }
    return null; // No sort icon when not sorted
  };

  return (
    <div style={styles.header} onClick={onSortRequested}>
      <div style={styles.headerContent}>
        {getText('recipients')} {getSortIcon()}
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    width: '100%',
    height: '100%',
  },
  headerContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
}));
