import { AutoSaveWebsiteWizardDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { Modal } from 'assets/components/modal';
import { Text } from 'assets/components/text';
import { TextField } from 'assets/components/text-field';
import { Form } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { logError } from 'assets/logging/logger';
import { makeStyles } from 'assets/theme';
import React, { FunctionComponent, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  openPreviousModalId,
  savePatientWebsite,
  setOpenedModal,
} from '../../../actions/checklist-actions';
import { useChecklistStore } from '../../../store/checklist-store';
import { ChecklistWebsiteModalProps } from '../types';

export interface WebsiteAboutUsDetails {
  aboutUs: string | undefined;
}

export const PatientWebsiteAboutUsDetailsModal: FunctionComponent<
  ChecklistWebsiteModalProps
> = ({ id, data }) => {
  const currentOpenedModalId = useChecklistStore((x) => x.currentOpenedModalId);
  const [loading, setLoading] = useState<boolean>(false);
  const styles = useStyles();

  const methods = useForm<WebsiteAboutUsDetails>({
    defaultValues: {
      aboutUs: data?.website_details?.website_about_us,
    },
  });

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const website_about_us = methods.getValues('aboutUs');
      const dto: AutoSaveWebsiteWizardDto = {
        ...data,
        website_details: {
          ...data.website_details,
          website_about_us,
        },
      };

      await savePatientWebsite(dto);
      setOpenedModal('patient-website-additional-info');
    } finally {
      setLoading(false);
    }
  };

  const handleBackButton = () => {
    const services = data.website_details?.website_service_options;
    services && openPreviousModalId(services, -1);
  };

  return (
    <Modal
      title={getText('about-us-details')}
      show={currentOpenedModalId === id}
      handleDismissModal={() => setOpenedModal('checklist-setup')}
      showDismissButton
      isScrollable
      size="sm"
      buttons={[
        {
          hierarchy: 'primary',
          text: getText('next'),
          onPress: methods.handleSubmit(handleSubmit),
          logger: { id: 'next-website-services' },
          loading,
        },
        {
          text: getText('back'),
          hierarchy: 'tertiary-gray',
          onPress: handleBackButton,
          logger: { id: 'back-website-services' },
        },
      ]}
    >
      <Form methods={methods}>
        <Form.Row>
          <Form.Column>
            <Text style={styles.title}>
              {`${getText('tell-about-community')} (${getText('required')})`}
            </Text>
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              name="aboutUs"
              type="text"
              rules={{ required: getText('field-required') }}
              label={getText('describe-pharmacy-team')}
              hideRequiredAsterisk
              numberOfLines={5}
              multiline
            />
          </Form.Column>
        </Form.Row>
      </Form>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.lumistryFonts.text.medium.regular,
    color: theme.palette.gray[700],
  },
}));
