import { EntityType } from '@digitalpharmacist/role-service-client-axios';
import { getText } from 'assets/localization/localization';
import { logError } from 'assets/logging/logger';
import PharmacyService, {
  PharmacyServiceClass,
} from '../../../api/PharmacyService';
import RoleService from '../../../api/RoleService';
import { PHARMACY_API_BASE_URL } from '../../../common/constants';
import { useProfileSettingsState as profileState } from './profile-settings-store';

export const getRolesByUserId = (userId: string) => {
  RoleService.userRoleGetRolesByUserId(userId)
    .then((userRoles) => profileState.setState({ userRoles }))
    .catch((error: Error) => logError(error));
};
export const getNameById = async (
  pharmacyId: string,
  entityType: EntityType,
  entityId: string,
): Promise<string> => {
  const pharmacyService = new PharmacyServiceClass(
    PHARMACY_API_BASE_URL,
    { headers: { 'x-pharmacy-id': pharmacyId } },
    true,
  );
  return entityType === 'location'
    ? (await pharmacyService.pharmacyLocationFindOne(entityId))
        .full_display_name ?? ''
    : entityType === 'pharmacy'
    ? (await PharmacyService.pharmacyGetById(entityId)).name
    : getText('global');
};
