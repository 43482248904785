import { FunctionComponent, useState } from 'react';
import { CreateConversationData, NewChatModal } from '../NewChatModal';
import { View } from 'react-native';
import { v4 } from 'uuid';
import { setLoading } from '../../stores/loading-store/loading-actions';
import {
  ERROR_FILES_SENDING_MODAL,
  LOADING_CONVERSATION_CREATING,
} from '../../data';
import { useLoadingState } from '../../stores/loading-store/loading-store';
import { setError } from '../../stores/error-store/error-actions';
import { ErrorStatus } from '../../stores/error-store/error-store';
import { getText } from 'assets/localization/localization';
import { useUserState } from '../../../../store/user-store';
import { AuthorType } from '@digitalpharmacist/unified-communications-service-client-axios';
import unifiedCommsService from '../../../../api/UnifiedCommsService';
import { IUploadFilesResult } from 'assets/types/messageTypes';
import FileStorageService from '../../../../api/FileStorageService';
import { LocationCategory } from '@digitalpharmacist/file-storage-service-client-axios';
import { Button, ButtonHierarchy, ButtonSizes } from 'assets/components/button';
import { useAppStateStore } from '../../../../store/app-store';
import { LocationPatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';
import { MessageCircleIcon } from 'assets/icons';
import { DocumentPickerAsset } from 'expo-document-picker';

export interface NewChatButtonProps {
  patient?: LocationPatientRecordDto;
  verifyPatient?: () => Promise<void>;
  isDisabledRecipient?: boolean;
  defaultSubject?: string;
  defaultMessage?: string;
  buttonHierarchy: ButtonHierarchy;
  buttonText: string;
  buttonSize?: ButtonSizes;
}

export const NewChatButton: FunctionComponent<NewChatButtonProps> = ({
  patient,
  verifyPatient,
  isDisabledRecipient,
  defaultSubject,
  defaultMessage,
  buttonHierarchy,
  buttonText,
  buttonSize,
}) => {
  const locationId = useAppStateStore((state) => state.locationId);
  const pharmacyId = useAppStateStore((state) => state.pharmacyId);
  const user = useUserState((state) => state.data);

  const isConversationCreating = useLoadingState((state) =>
    LOADING_CONVERSATION_CREATING in state.loadingObject
      ? state.loadingObject[LOADING_CONVERSATION_CREATING].isLoading
      : false,
  );
  const [isOpenNewChatModal, setIsOpenNewChatModal] = useState<boolean>(false);

  const onCancelModal = () => {
    setIsOpenNewChatModal(false);
  };

  const onConversationCreate = async (data: CreateConversationData) => {
    setLoading(LOADING_CONVERSATION_CREATING, true);

    const filesUploadResult = await uploadFiles(
      locationId,
      pharmacyId,
      data.attachments,
    );

    if (filesUploadResult.isError) {
      setError(
        ERROR_FILES_SENDING_MODAL,
        ErrorStatus.ERROR,
        getText('files-sending-error-try-again'),
      );
      setLoading(LOADING_CONVERSATION_CREATING, false);
      return;
    }

    const createConversationDto = {
      author_id: String(user?.id),
      author_type: AuthorType.Pharmacy,
      subject: data.subject,
      patient_viewed_all_messages: false,
      pharmacy_viewed_all_messages: true,
      content: data.message,
      attachments: filesUploadResult.filesData.length
        ? filesUploadResult.filesData
        : [],
    };

    await unifiedCommsService.createConversation(
      pharmacyId,
      locationId,
      data.patient,
      createConversationDto,
    );

    setLoading(LOADING_CONVERSATION_CREATING, false);
    onCancelModal();
  };

  async function uploadFiles(
    locationId: string,
    pharmacyId: string,
    files: DocumentPickerAsset[] | undefined,
  ): Promise<IUploadFilesResult> {
    const result: IUploadFilesResult = {
      isError: false,
      filesData: [],
    };

    if (!files || !files.length) {
      return result;
    }

    for (const file of files) {
      if (!file.file) {
        console.error(`The file ${file.name} does not have 'file' property`);
        continue;
      }

      try {
        const newName = v4();
        const extension = FileStorageService.getFileExtension(file.name);
        const fileName = `${newName}.${extension}`;

        const responseWriteUrl = await FileStorageService.writeUrl(
          LocationCategory.DirectMessage,
          locationId,
          fileName,
          pharmacyId,
        );

        await FileStorageService.uploadFile(
          file.file,
          responseWriteUrl.data.url,
        );

        result.filesData.push({
          name: file.name,
          stored_filename: fileName,
        });
      } catch (error) {
        //TODO: review console.error
        console.error(`Error uploading file ${file.name}. Error: `, error);
        result.isError = true;
      }
    }

    return result;
  }

  const handleButtonPress = () => {
    setIsOpenNewChatModal(true);
  };

  return (
    <View>
      {/* {isSendVideoButton ? (
        <Button
          hierarchy={'tertiary'}
          logger={{
            id: 'send-video-button',
          }}
          icon={MessageCircleIcon}
          onPress={() => {
            setIsOpenNewChatModal(true);
          }}
        >
          {getText('send-video')}
        </Button>
      ) : ( */}
      <Button
        logger={{ id: NewChatButtonLoggerId }}
        hierarchy={buttonHierarchy}
        size={buttonSize ? buttonSize : undefined}
        style={{ width: 150 }}
        onPress={handleButtonPress}
        icon={MessageCircleIcon}
      >
        {buttonText}
      </Button>
      {/* // <Button
        //   logger={{ id: NewChatButtonLoggerId }}
        //   hierarchy="secondary-gray"
        //   size="large"
        //   style={{ width: 150 }}
        //   onPress={handleButtonPress}
        //   icon={MessageCircleIcon}
        // >
        //   {getText('chat')}
        // </Button>
     // )}*/}
      <NewChatModal
        show={isOpenNewChatModal}
        onConversationCreate={onConversationCreate}
        verifyPatient={verifyPatient}
        onCancel={onCancelModal}
        recipient={patient}
        isConversationCreating={isConversationCreating}
        isDisabledRecipient={isDisabledRecipient}
        defaultSubject={defaultSubject}
        defaultMessage={defaultMessage}
      />
    </View>
  );
};

export const NewChatButtonLoggerId = 'NewChatButton';

export default NewChatButton;
