import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import { makeStyles } from 'assets/theme';
import { Text } from 'assets/components/text';
import { OBContentHeader } from '../content-header/OBContentHeader';
import { useForm } from 'react-hook-form';
import { Form } from 'assets/layout';
import OBDivider from '../divider/OBDivider';
import { AddressField } from '../address/AddressField';
import { useWizard } from 'react-use-wizard';
import { CheckboxInput, CheckboxInputMode } from 'assets/components/checkbox';
import { OBMapPinIcon } from '../icons/OBMapPinIcon';
import {
  sameAsPharmacyAddress,
  save,
  updatePharmacyLocationAddresses,
} from '../../../../actions/onboarding-actions';
import { useOnboardingStore } from '../../../../store/onboarding-store';
import { InfoTooltip } from '../common/InfoTooltip';
import { getText } from 'assets/localization/localization';
import { FinalizeObAddressDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { FORM_NOT_VALID } from '../../utils';
import { OBAddressForm } from '../forms/OBAddressForm';
import { OBFormHandler } from '../forms';

export type FormAddressType = FinalizeObAddressDto;

export type AddressesFormType = {
  storeAddress: FormAddressType;
  billingAddress: FormAddressType;
  shippingAddress: FormAddressType;
};

export const OBSingleLocationAddresses: FunctionComponent = () => {
  const styles = useStyles();
  const { handleStep } = useWizard();
  const { data } = useOnboardingStore();
  const obAddressRef = useRef<OBFormHandler>(null);

  handleStep(async () => {
    await obAddressRef.current?.handleSubmit();
    await save();
  });

  return (
    <View style={styles.root}>
      <OBContentHeader
        icon={OBMapPinIcon}
        title={getText('ob-address-title')}
        description={getText('ob-address-description')}
      />

      <OBAddressForm location={data.locations[0]} ref={obAddressRef} />

      <OBDivider />
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    alignContent: 'center',
    width: 350,
  },
  addressContainer: {
    // gap: theme.getSpacing(2),
    marginBottom: theme.getSpacing(3),
  },
  addressLabelContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.getSpacing(1),
  },
  addressTitle: {
    ...theme.lumistryFonts.text.medium.semiBold,
    color: theme.palette.gray[900],
    marginBottom: theme.getSpacing(2),
  },
  checkBoxContainer: {
    marginBottom: theme.getSpacing(2),
  },
  tooltipContainer: {
    marginBottom: theme.getSpacing(1.5),
  },
}));
