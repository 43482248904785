import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path } from 'react-native-svg';
import { IconProps, MultiColorIconProps } from './types';

export const NoAppReadyIcon: FunctionComponent<
  PropsWithChildren<IconProps>
> = ({ color, testID }) => {
  return (
    <Svg
      width={87}
      height={110}
      viewBox="0 0 89 111"
      fill="none"
      color={color}
      testID={testID}
    >
      <Path
        fill="#374D58"
        fillRule="evenodd"
        d="M71.257 100.108V87.176c-.148-.013 0-5.689 0-5.689h-7.038v8.098c0 2.839-2.347 5.173-5.202 5.173H13.124c-2.855 0-5.202-2.334-5.202-5.173V15.08c0-2.843 2.37-5.177 5.202-5.177h45.893c2.832 0 5.202 2.334 5.202 5.177v24.222c2.476-2.637 5.457-6.482 7.038-8.569 0-2.205-.256-19.417 0-19.637v-.078C71.257 5.19 66.46.431 60.614.431H11.53C5.683.43.886 5.19.886 11.018v89.09c0 5.828 4.784 10.592 10.642 10.592h49.087c5.857 0 10.642-4.76 10.642-10.592ZM30.5 6.901c-1.159 0-1.159-1.732 0-1.732h11.14c1.16 0 1.16 1.732 0 1.732h-11.14Zm0 98.195c-3.644 0-3.644-5.625 0-5.625h11.14c3.645 0 3.645 5.625 0 5.625h-11.14Z"
        clipRule="evenodd"
      />
      <Path
        fill="#36404E"
        d="m77.775 68.907 2.678 6.332a1.327 1.327 0 0 1-1.216 1.838v.005H52.298a1.327 1.327 0 0 1-1.168-1.956l2.63-6.219a16.635 16.635 0 0 1-6.178-3.803c-2.923-2.844-4.78-6.757-4.78-11.059v-8.867c0-.733.594-1.33 1.326-1.33h43.279c.731 0 1.325.597 1.325 1.33v8.867c0 4.302-1.856 8.215-4.78 11.059a16.628 16.628 0 0 1-6.177 3.803Zm-1.751 2.639H55.51l-1.217 2.877h22.947l-1.217-2.877Zm-17.101-4.433h2.392c-.536-.631-.834-1.573-.847-2.88H76.35c4.373 0 6.474-1.94 8.014-3.905a12.465 12.465 0 0 0 1.716-6.068H60.468v-3.544h25.614v-4.209h-40.63v4.209h7.95v3.544h-7.948c.052 2.944 1.16 5.662 2.95 7.833l-.029-.075c1.356 1.283 3.357 2.093 5.027 2.266.333 1.101 1.472 2.164 3.21 2.625.754.135 1.527.204 2.31.204Z"
      />
      <Path
        fill={color}
        fillRule="evenodd"
        d="m83.687 33.123-3.598 8.51H69.608l8.127-11.835a5.323 5.323 0 0 1 .362-4.553 5.29 5.29 0 0 1 7.238-1.945 5.325 5.325 0 0 1 1.94 7.262 5.287 5.287 0 0 1-3.588 2.56Z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
