import { FC, useEffect, useState } from 'react';
import {
  LocationPatientRecordDto,
  PatientRecordDto,
  UpdatePatientRecordDto,
} from '@digitalpharmacist/patient-service-client-axios';
import { getText } from 'assets/localization/localization';
import { useAppStateStore } from '../../store/app-store';
import { PharmacyCategory } from '@digitalpharmacist/file-storage-service-client-axios';
import { PhotoInfo } from './PhotoButton';
import FileStorageService from '../../api/FileStorageService';
import { ImageViewer } from '../ImageViewer';
import { logError } from 'assets/logging/logger';

export type FilenameProperty = keyof Pick<
  UpdatePatientRecordDto,
  | 'insurance_card_primary_front_url'
  | 'insurance_card_primary_back_url'
  | 'insurance_card_secondary_front_url'
  | 'insurance_card_secondary_back_url'
>;
interface InsuranceDetailsModalProps {
  show: boolean;
  setShowModal: (arg: boolean) => void;
  onSubmit?: (patientRecord: PatientRecordDto) => void;
  cardType: Card;
  locationPatientRecord?: LocationPatientRecordDto;
  insuranceCardUrlsObject?:
    | undefined
    | Record<FilenameProperty, string | null | undefined>;
}

const InsuranceDetailsModal: FC<InsuranceDetailsModalProps> = ({
  show,
  setShowModal,
  cardType,
  locationPatientRecord: locationPatientRecord,
  insuranceCardUrlsObject,
}) => {
  const [frontPhoto, setFrontPhoto] = useState<PhotoInfo>();
  const [backPhoto, setBackPhoto] = useState<PhotoInfo>();
  const { pharmacyId } = useAppStateStore();

  function onDismiss(): void {
    setShowModal(false);
  }

  const cardTypeConfig: Record<
    Card,
    {
      frontFilenameProperty: FilenameProperty;
      backFilenameProperty: FilenameProperty;
    }
  > = {
    [Card.Primary]: {
      frontFilenameProperty: 'insurance_card_primary_front_url',
      backFilenameProperty: 'insurance_card_primary_back_url',
    },
    [Card.Secondary]: {
      frontFilenameProperty: 'insurance_card_secondary_front_url',
      backFilenameProperty: 'insurance_card_secondary_back_url',
    },
  };

  const { frontFilenameProperty, backFilenameProperty } =
    cardTypeConfig[cardType];

  const loadImages = async () => {
    try {
      const frontFilename = insuranceCardUrlsObject
        ? insuranceCardUrlsObject[frontFilenameProperty]
        : locationPatientRecord && locationPatientRecord[frontFilenameProperty];

      if (frontFilename) {
        const responseReadUrl =
          await FileStorageService.readUrlWithPharmacyCategory(
            PharmacyCategory.InsuranceCard,
            frontFilename,
            pharmacyId,
          );
        setFrontPhoto({ uri: responseReadUrl.url });
      } else {
        setFrontPhoto(undefined);
      }

      const backFilename = insuranceCardUrlsObject
        ? insuranceCardUrlsObject[backFilenameProperty]
        : locationPatientRecord && locationPatientRecord[backFilenameProperty];

      if (backFilename) {
        const responseReadUrl =
          await FileStorageService.readUrlWithPharmacyCategory(
            PharmacyCategory.InsuranceCard,
            backFilename,
            pharmacyId,
          );
        setBackPhoto({ uri: responseReadUrl.url });
      } else {
        setBackPhoto(undefined);
      }
    } catch (error) {
      logError(error as Error);
    }
  };

  useEffect(() => {
    loadImages();
  }, [cardType, frontFilenameProperty, backFilenameProperty]);

  return (
    <ImageViewer
      hasDouble
      title={getText('insurance')}
      show={show}
      okButtonPress={onDismiss}
      frontImageUrl={frontPhoto?.uri}
      backImageUrl={backPhoto?.uri}
    />
  );
};

export enum Card {
  Primary = 'insurance_primary',
  Secondary = 'insurance_secondary',
}

export default InsuranceDetailsModal;
