import React, { FunctionComponent } from 'react';
import Svg, { Path } from 'react-native-svg';
import { IconProps } from './types';

export const AutoIcon: FunctionComponent<IconProps> = ({
  color,
  size,
  testID,
  strokeWidth,
}) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      color={color}
      testID={testID}
      strokeWidth={strokeWidth}
    >
      <Path
        d="M5.00033 4.99996H5.00866M5.00033 15H5.00866M3.33366 1.66663H16.667C17.5875 1.66663 18.3337 2.41282 18.3337 3.33329V6.66663C18.3337 7.5871 17.5875 8.33329 16.667 8.33329H3.33366C2.41318 8.33329 1.66699 7.5871 1.66699 6.66663V3.33329C1.66699 2.41282 2.41318 1.66663 3.33366 1.66663ZM3.33366 11.6666H16.667C17.5875 11.6666 18.3337 12.4128 18.3337 13.3333V16.6666C18.3337 17.5871 17.5875 18.3333 16.667 18.3333H3.33366C2.41318 18.3333 1.66699 17.5871 1.66699 16.6666V13.3333C1.66699 12.4128 2.41318 11.6666 3.33366 11.6666Z"
        stroke="#0171C2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
