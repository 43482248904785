import {
  ClosureHoursDto,
  CreateClosuresRequestDto,
  CreateDepartmentDto,
  CreateNormalTimeRangeDto,
  CreateParentCompanyDto,
  ParentCompanyResponseDto,
  ToggleHolidayDto,
  UpdateDepartmentDto,
  UpdatePharmacyDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { getText } from 'assets/localization/localization';
import PMSIntegrationService from '../../../api/PMSIntegrationService';
import PharmacyService from '../../../api/PharmacyService';
import { handleError } from '../../../common/errors/commonErrorHandler';
import { AsyncStatus } from '../../../store/types';
import {
  CountryCode,
  DepartmentHours,
  usePharmacySettingsState as pharmacyState,
} from './pharmacy-settings-store';

export const setStatus = (status: AsyncStatus) =>
  pharmacyState.setState({ status });

export const setShowModalGeneralInfo = (showModalGeneralInfo: boolean) =>
  pharmacyState.setState({ showModalGeneralInfo });

export const setShowModalContactInfo = (showModalContactInfo: boolean) =>
  pharmacyState.setState({ showModalContactInfo });

export const setShowModalDepartmentHours = (
  showModalDepartmentHours: boolean,
) => {
  pharmacyState.setState({ showModalDepartmentHours });
};

export const setShowModalHolidayClosures = (
  showModalHolidayClosures: boolean,
) => {
  pharmacyState.setState({ showModalHolidayClosures });
};

export const setShowModalAdditionalClosures = (
  showModalAdditionalClosures: boolean,
) => {
  pharmacyState.setState({ showModalAdditionalClosures });
};

export const setEditingDepartmentId = (editingDepartmentId?: string) => {
  pharmacyState.setState({ editingDepartmentId });
};

export const setPharmacy = async (updatePharmacy: UpdatePharmacyDto) => {
  const { pharmacyId } = pharmacyState.getState();
  if (pharmacyId) {
    try {
      const pharmacy = await PharmacyService.updatePharmacyRecord(
        pharmacyId,
        updatePharmacy,
      );
      pharmacyState.setState({ pharmacy });
    } catch (error) {
      handleError({
        showToast: true,
        userMessage: getText('general-error-message', {
          message: getText('pharmacy-info'),
          error,
        }),
      });
    }
  }
};

export const updateDepartment = async (
  department: UpdateDepartmentDto,
  departmentId?: string,
) => {
  const { pharmacyId, editingDepartmentId } = pharmacyState.getState();
  departmentId = departmentId || editingDepartmentId;
  if (pharmacyId && departmentId) {
    try {
      const editingDepartment = await PharmacyService.updatePharmacyDepartment(
        pharmacyId,
        departmentId,
        department,
      );
      pharmacyState.setState({ editingDepartment });
    } catch (error) {
      handleError({
        showToast: true,
        userMessage: getText('general-error-message', {
          message: getText('locations-holiday-closures'),
          error,
        }),
      });
    }
  }
};

export const createDepartment = async (department: CreateDepartmentDto) => {
  const { pharmacyId } = pharmacyState.getState();
  if (pharmacyId) {
    try {
      await PharmacyService.createPharmacyDepartment(pharmacyId, department);
    } catch (error) {
      handleError({
        showToast: true,
        userMessage: getText('general-error-message', {
          message: getText('locations-holiday-closures'),
          error,
        }),
      });
    }
  }
};

export const removeDepartment = async (departmentId?: string) => {
  const { pharmacyId, editingDepartmentId } = pharmacyState.getState();
  departmentId = departmentId || editingDepartmentId;
  if (pharmacyId && departmentId) {
    try {
      await PharmacyService.removePharmacyDepartment(pharmacyId, departmentId);
      await getDepartments();
      pharmacyState.setState({
        editingDepartmentId: undefined,
        editingDepartment: undefined,
      });
    } catch (error) {
      handleError({
        showToast: true,
        userMessage: getText('general-error-message', {
          message: getText('pharmacy-departments-hours'),
          error,
        }),
      });
    }
  }
};

export const setCountry = (country: CountryCode) =>
  pharmacyState.setState({ country });

export const setPharmacyId = async (pharmacyId: string) => {
  pharmacyState.setState({ pharmacyId });
  try {
    await getPharmacy();
  } catch (error) {
    handleError({
      showToast: true,
      userMessage: getText('general-error-message', {
        message: getText('pharmacy-info'),
        error,
      }),
    });
  }
};

export const getPharmacy = async () => {
  const { pharmacyId } = pharmacyState.getState();
  if (!pharmacyId) {
    pharmacyState.setState({ pharmacy: undefined });
    return;
  }
  try {
    const pharmacy = await PharmacyService.pharmacyGetById(pharmacyId);
    pharmacyState.setState(() => ({ pharmacy }));
  } catch (error) {
    handleError({
      showToast: true,
      userMessage: getText('general-error-message', {
        message: getText('pharmacy-info'),
        error,
      }),
    });
  }
};

export const createDepartmentHours = async (
  timeRange: CreateNormalTimeRangeDto[],
  departmentName?: string,
) => {
  const { editingDepartmentId, pharmacyId } = pharmacyState.getState();
  try {
    if (editingDepartmentId) {
      if (departmentName && pharmacyId) {
        await PharmacyService.updatePharmacyDepartment(
          pharmacyId,
          editingDepartmentId,
          { name: departmentName },
        );
      }
      await PharmacyService.createDepartmentHours(
        editingDepartmentId,
        timeRange,
      );
    } else if (departmentName && pharmacyId) {
      const department = await PharmacyService.createPharmacyDepartment(
        pharmacyId,
        {
          name: departmentName,
        },
      );
      await PharmacyService.createDepartmentHours(department.id, timeRange);
    }
  } catch (error) {
    handleError({
      showToast: true,
      userMessage: getText('general-error-message', {
        message: getText('pharmacy-departments-hours'),
        error,
      }),
    });
  }
};

export const getDepartmentsHours = async () => {
  const { departments } = pharmacyState.getState();
  try {
    const newDepartmentsHours: DepartmentHours[] = await Promise.all(
      departments.map(async (department) => {
        return {
          departmentId: department.id,
          departmentName: department.name,
          departmentHours: await PharmacyService.getHoursByDepartment(
            department.id,
          ),
        };
      }),
    );
    setDepartmentsHours(newDepartmentsHours);
  } catch (error) {
    handleError({
      showToast: true,
      userMessage: getText('general-error-message', {
        message: getText('pharmacy-departments-hours'),
        error,
      }),
    });
  }
};

export const getEditingDepartmentHours = () => {
  const { departmentsHours, editingDepartmentId } = pharmacyState.getState();
  const editingDepartmentHours = editingDepartmentId
    ? departmentsHours.find(
        (department) => department.departmentId === editingDepartmentId,
      )
    : undefined;
  pharmacyState.setState({ editingDepartmentHours });
};

export const setDepartmentsHours = (departmentsHours: DepartmentHours[]) =>
  pharmacyState.setState({ departmentsHours });

export const getDepartments = async () => {
  const { pharmacyId } = pharmacyState.getState();
  if (!pharmacyId) {
    pharmacyState.setState({ departments: [] });
    return;
  }
  try {
    const departments =
      await PharmacyService.departmentGetByPharmacy(pharmacyId);
    pharmacyState.setState({ departments });
  } catch (error) {
    handleError({
      showToast: true,
      userMessage: getText('general-error-message', {
        message: getText('pharmacy-departments'),
        error,
      }),
    });
  }
};

export const getEditingDepartment = () => {
  const { editingDepartmentId, departments } = pharmacyState.getState();
  const editingDepartment = editingDepartmentId
    ? departments.find((dep) => dep.id == editingDepartmentId)
    : undefined;
  pharmacyState.setState({ editingDepartment });
};

export const getAllPMS = async () => {
  try {
    const allPMS = await PMSIntegrationService.pmsFindAll();
    pharmacyState.setState({ allPMS });
  } catch (error) {
    handleError({
      showToast: true,
      userMessage: getText('general-error-message', {
        message: getText('pharmacy-pms'),
        error,
      }),
    });
  }
};

export const getHolidays = async () => {
  const { pharmacyId } = pharmacyState.getState();
  try {
    if (pharmacyId) {
      const holidays =
        await PharmacyService.pharmacyHoursGetHolidays(pharmacyId);
      pharmacyState.setState({ holidays });
    }
  } catch (error) {
    pharmacyState.setState({ holidays: [] });
    handleError({
      showToast: true,
      userMessage: getText('general-error-message', {
        message: getText('pharmacy-holiday-closures'),
        error,
      }),
    });
  }
};

export const getClosures = async () => {
  const { pharmacyId } = pharmacyState.getState();
  try {
    if (pharmacyId) {
      const closures = await PharmacyService.pharmacyHoursFindAll(pharmacyId);
      pharmacyState.setState({ closures });
    }
  } catch (error) {
    pharmacyState.setState({ closures: [] });
    handleError({
      showToast: true,
      userMessage: getText('general-error-message', {
        message: getText('pharmacy-additional-closures'),
        error,
      }),
    });
  }
};

export const updateHolidays = async (updatedHolidays: ToggleHolidayDto[]) => {
  const { pharmacyId } = pharmacyState.getState();
  try {
    if (pharmacyId) {
      const holidays = await PharmacyService.pharmacyHoursToggleHoliday(
        pharmacyId,
        updatedHolidays,
      );
      pharmacyState.setState({ holidays });
    }
  } catch (error) {
    handleError({
      showToast: true,
      userMessage: getText('general-error-message', {
        message: getText('pharmacy-holiday-closures'),
        error,
      }),
    });
  }
};

export const setClosures = async (createClosures: CreateClosuresRequestDto) => {
  const { pharmacyId } = pharmacyState.getState();
  try {
    if (pharmacyId) {
      const closures = await PharmacyService.pharmacyHoursCreateClosures(
        pharmacyId,
        createClosures,
      );
      pharmacyState.setState({ closures });
    }
  } catch (error) {
    handleError({
      showToast: true,
      userMessage: getText('general-error-message', {
        message: getText('pharmacy-additional-closures'),
        error,
      }),
    });
  }
};

export const removeClosures = async (closureId: string) => {
  const { pharmacyId, closures } = pharmacyState.getState();
  try {
    if (pharmacyId) {
      await PharmacyService.pharmacyHoursRemoveClosures(pharmacyId, closureId);
      const filteredClosures: ClosureHoursDto[] = closures.filter(
        (closure) => closure.id !== closureId,
      );
      pharmacyState.setState({ closures: filteredClosures });
    }
  } catch (error) {
    handleError({
      showToast: true,
      userMessage: getText('general-error-message', {
        message: getText('pharmacy-additional-closures'),
        error,
      }),
    });
  }
};

export const setParentCompanies = async (search = '') => {
  try {
    const allParentCompanies =
      await PharmacyService.parentCompanySearch(search);
    pharmacyState.setState({ allParentCompanies });
  } catch (error) {
    handleError({
      showToast: true,
      userMessage: getText('general-error-message', {
        message: getText('pharmacy'),
        error,
      }),
    });
  }
};

export const createParentCompany = async (
  parentCompany: CreateParentCompanyDto,
) => {
  try {
    const newParentCompany =
      await PharmacyService.parentCompanyCreate(parentCompany);
    const { allParentCompanies } = pharmacyState.getState();
    allParentCompanies.push(newParentCompany);
    pharmacyState.setState({ allParentCompanies });
    return newParentCompany;
  } catch (error) {
    handleError({
      showToast: true,
      userMessage: getText('general-error-message', {
        message: getText('pharmacy'),
        error,
      }),
    });
  }
  return undefined;
};

export const findParentCompanyNyName = (
  parentCompanies: ParentCompanyResponseDto[],
  parentCompanyName: string,
) => {
  return parentCompanies.find(
    (parentCompany) => parentCompany.name === parentCompanyName,
  );
};

// TODO: This will be handled by the backend in the future.
export const getDepartmentsLabel = (name: string) => {
  return name === 'Pharmacy' ? 'Pharmacy operating' : name;
};

// TODO: This will be handled by the backend in the future.
export const isPharmacyDepartment = (id: string) => {
  const { departments } = pharmacyState.getState();
  return departments.find((department) => department.id === id)?.immutable;
};
