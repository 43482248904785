import React, { FunctionComponent } from 'react';
import { View } from 'react-native';

import { makeStyles } from 'assets/theme';
import { AppointmentsList } from '../../../schedule/appointments-list/AppointmentsList';
import { DrawerScreenProps } from '@react-navigation/drawer';
import { ScheduleDrawerNavigationParamList } from '../../../layout/ScheduleDrawer';
import { PharmacyScreenContainer } from 'assets/layout';

export const Appointments: FunctionComponent<AppointmentsProps> = ({
  route,
}) => {
  const styles = useStyles();

  return (
    <PharmacyScreenContainer>
      <View style={styles.container}>
        <AppointmentsList route={route} />
      </View>
    </PharmacyScreenContainer>
  );
};

type AppointmentsProps = DrawerScreenProps<
  ScheduleDrawerNavigationParamList,
  'appointments'
>;

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
  },
}));
