import { Text } from 'assets/components/text';
import { makeStyles } from 'assets/theme';
import { FunctionComponent, PropsWithChildren } from 'react';
import { TouchableOpacity, View } from 'react-native';
import React from 'react';
import { getText } from 'assets/localization/localization';
import { RowItemRender } from '../../../components/Settings/RowItemRender';

export const GeneralSettingsItemRender: FunctionComponent<
  PropsWithChildren<GeneralSettingsItemRenderProps>
> = ({ name, value }) => {
  const styles = useStyles();

  return (
    <>
      <RowItemRender title={name}>
        {value.toString().includes('View') ? (
          <TouchableOpacity onPress={() => alert('Not implemented yet')}>
            <Text style={styles.valueLink}>{getText('view')}</Text>
          </TouchableOpacity>
        ) : (
          <Text style={styles.value}>{value}</Text>
        )}
      </RowItemRender>
    </>
  );
};

interface GeneralSettingsItemRenderProps {
  id: string;
  name: string;
  value: number;
}

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'row',
    gap: theme.getSpacing(2),
    alignItems: 'center',
    borderBottomColor: theme.palette.gray[200],
    borderBottomWidth: 1,
    padding: theme.getSpacing(1),
    marginBottom: theme.getSpacing(1),
  },
  firstColumnContent: {
    flex: 1,
    flexDirection: 'row',
  },
  title: {
    ...theme.fonts.regular,
    fontSize: 14,
    lineHeight: 16,
    color: theme.palette.gray[900],
    fontWeight: '600',
  },
  value: {
    ...theme.fonts.regular,
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[700],
  },
  valueLink: {
    color: theme.palette.primary[600],
    fontWeight: '400',
    fontSize: 14,
    marginLeft: theme.getSpacing(0.5),
  },
}));
