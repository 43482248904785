import { useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { AuthorType } from '@digitalpharmacist/unified-communications-service-client-axios';
import { socket } from '../../../../socket';
import { StorageKeys } from '../../../../enums/storage-keys';
import { useAppStateStore } from '../../../store/app-store';

export const useSocketConnectionManager = () => {
  const pharmacyId = useAppStateStore((state) => state.pharmacyId);
  const locationId = useAppStateStore((state) => state.locationId);

  const join = () => {
    socket.emit('join', {
      type: AuthorType.Pharmacy,
      id: locationId,
    });
  };

  const leave = () => {
    socket.emit('leave', {
      type: AuthorType.Pharmacy,
      id: locationId,
    });
  };

  useEffect(() => {
    const init = async () => {
      const token = await AsyncStorage.getItem(StorageKeys.AccessToken);

      socket.io.opts.query = {
        client: AuthorType.Pharmacy,
        'x-pharmacy-id': pharmacyId,
      };
      socket.auth = {
        token,
      };
      socket.io.opts.transports = ['websocket'];

      socket.connect();
    };

    const keepConnecting = () => {
      void init();
      join();
    };

    if (pharmacyId) {
      void init();
      socket.on('disconnect', keepConnecting);
    }

    return () => {
      if (pharmacyId) {
        socket.off('disconnect', keepConnecting);
        leave();
        socket.disconnect();
      }
    };
  }, [pharmacyId]);

  useEffect(() => {
    if (locationId) {
      join();
    }
    return () => {
      if (locationId) {
        leave();
      }
    };
  }, [locationId]);
};
