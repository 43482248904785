import {
  VaccinationLocation,
  VaccinationRoute,
  VaccinationSide,
} from '@digitalpharmacist/forms-service-client-axios';
import { getText } from 'assets/localization/localization';

export const VaccinationLocationLocalization = {
  [VaccinationLocation.Arm]: getText('arm'),
  [VaccinationLocation.Leg]: getText('leg'),
};

export const VaccinationRouteLocalization = {
  [VaccinationRoute.Im]: getText('im'),
  [VaccinationRoute.Sq]: getText('sq'),
};

export const VaccinationSideLocalization = {
  [VaccinationSide.Left]: getText('left'),
  [VaccinationSide.Right]: getText('right'),
};
