import { View, Text } from 'react-native';
import { makeStyles } from 'assets/theme';
import { FC } from 'react';
import { DpEmptyMessageIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { Button } from 'assets/components/button';
import { Icon } from 'assets/components/icon';

export const PatientWithoutConversationsView: FC<
  PatientWithoutConversationsViewProps
> = ({ onClick }) => {
  const styles = useStyles();

  return (
    <View style={styles.wrapper}>
      <Icon size={250} icon={DpEmptyMessageIcon} />
      <Text style={styles.header}>
        {getText('this-patient-has-not-sent-or-received-any-messages')}
      </Text>
      <Text style={styles.text}>
        {getText('have-moment-break-ice-and-ask-them-how-they-are-doing')}
      </Text>
      <Button
        hierarchy="secondary"
        size="small"
        logger={{ id: 'new-message-button' }}
        style={styles.button}
        onPress={() => onClick()}
      >
        {getText('send-message')}
      </Button>
    </View>
  );
};

interface PatientWithoutConversationsViewProps {
  onClick: () => void;
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    height: theme.getSpacing(4) + theme.getSpacing(0.5),
    width: 230,
    marginTop: theme.getSpacing(2),
  },
  header: {
    ...theme.fonts.medium,
    fontSize: 20,
    marginTop: theme.getSpacing(1),
  },
  text: {
    marginTop: theme.getSpacing(0.5),
    color: theme.palette.gray[700],
  },
}));
