import { logError, logEvent } from 'assets/logging/logger';
import { useLoginState } from './login-store';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { StorageKeys } from '../../../enums/storage-keys';
import { PharmacyUser } from '../../api';
import UsersService from '../../api/UsersService';
import { PharmacistUserLoginRequest } from '@digitalpharmacist/users-service-client-axios';
import { resetAllStores } from '../../store/store-creator';
import { loadUserInfoMap, persistUserInfo } from '../../actions/user-actions';
import { ApiError } from 'assets/core/api';
import { setAmplitudeUserProperties } from '../../actions/app-actions';
import { ampli } from '../../src/ampli';
import Constants from 'expo-constants';

export const login = async (
  values: PharmacistUserLoginRequest,
): Promise<void> => {
  useLoginState.setState({ error: undefined, status: 'loading' });

  try {
    const { accessToken, refreshToken, userInfo } =
      await UsersService.logIn(values);
    if (accessToken && refreshToken && userInfo) {
      // we should get all the information needed here
      await persistUserInfo(userInfo);

      await AsyncStorage.setItem(StorageKeys.AccessToken, accessToken);
      await AsyncStorage.setItem(StorageKeys.RefreshToken, refreshToken);

      const pharmacyUsersMap = await loadUserInfoMap();

      const userId = await AsyncStorage.getItem(StorageKeys.UserId);

      if (userId) {
        void setAmplitudeUserProperties(
          userId,
          pharmacyUsersMap[userId].selectedLocation?.pharmacy_name || '',
          pharmacyUsersMap[userId].selectedLocation?.pharmacy_id || '',
          pharmacyUsersMap[userId].selectedLocation?.location_id || '',
        );
      }

      ampli.userLoggedIn({
        loginMethod: 'email',
        loginStatus: 'Logged In',
        loginTime: new Date().toISOString(),
        productVersion: Constants.expoConfig?.version!,
      });

      useLoginState.setState({ status: 'success' });
    }
  } catch (e) {
    // TODO: [ERROR_HANDLING] please review this
    e = ApiError(e as any);
    logEvent('login_failed', { email: values.email });
    useLoginState.setState({
      error: {
        message: e as string,
      },
      status: 'error',
    });
  }
};

export const logout = async (): Promise<void> => {
  try {
    await AsyncStorage.multiRemove([
      '@UserId',
      '@RefreshToken',
      '@AccessToken',
      'AMP_unsent_a798881857',
      'EXPO_CONSTANTS_INSTALLATION_ID',
    ]);
    ampli.userLoggedOut({
      logoutMethod: 'email',
      logoutTime: new Date().toISOString(),
    });
    resetAllStores();
  } catch (error: any) {
    logError(error);
  }
};

export interface LoginForm {
  email: string;
  password: string;
  pharmacyId: string;
}

export interface UserToken {
  accessToken: string;
  idToken: string;
  refreshToken: string;
  userInfo: PharmacyUser;
}
