import { makeStyles } from 'assets/theme';
import { FC } from 'react';
import { View, Text } from 'react-native';

interface TextCardProps {
  title: string;
  text: string;
}

const TextCard: FC<TextCardProps> = ({ title, text }) => {
  const styles = useStyles();
  return (
    <View style={styles.body}>
      <Text style={styles.title}>{title}</Text>
      <Text style={styles.text}>{text}</Text>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  body: {
    display: 'flex',
    marginTop: theme.getSpacing(3),
    overflow: 'hidden',
  },
  title: {
    ...theme.lumistryFonts.label,
    color: theme.palette.black[600],
    fontWeight: '700',
    fontSize: 16,
  },
  text: {
    ...theme.fonts.regular,
    marginTop: theme.getSpacing(1),
    color: theme.palette.gray[700],
    overflow: 'hidden',
    fontSize: 16,
  },
}));

export default TextCard;
