import { Icon } from 'assets/components/icon';
import { Text } from 'assets/components/text';
import { AlertTriangleIcon } from 'assets/icons';
import { makeStyles, useTheme } from 'assets/theme';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { View } from 'react-native';

export const ErrorMessage: FunctionComponent<
  PropsWithChildren<ErrorMessageProps>
> = ({ message }) => {
  const theme = useTheme();
  const styles = useStyles();

  return (
    <View style={styles.container}>
      <View style={styles.icon}>
        <Icon
          icon={AlertTriangleIcon}
          color={theme.palette.error[600]}
          size={16}
        />
      </View>
      <Text style={styles.errorMessage}>{message}</Text>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.getSpacing(0.5),
  },
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: theme.getSpacing(0.5),
  },
  errorMessage: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.error[600],
  },
}));

export interface ErrorMessageProps {
  message: string;
  isDark?: boolean;
}
