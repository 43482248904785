import {
  ClosureHoursDto,
  DepartmentDto,
  HolidayDto,
  NormalTimeRangeDto,
  ParentCompanyResponseDto,
  PharmacyDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { PmsDto } from '@digitalpharmacist/pms-integration-service-client-axios';
import create from 'zustand';
import { AsyncStatus } from '../../../store/types';

export enum CountryCode {
  US = 'US',
  CA = 'CA',
}

export enum DaysOfWeekEnum {
  Sunday = 1,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

export interface DepartmentHours {
  departmentId: string;
  departmentName: string;
  departmentHours: NormalTimeRangeDto[];
}

export interface PharmacySettingsState {
  showModalGeneralInfo: boolean;
  showModalContactInfo: boolean;
  showModalDepartmentHours: boolean;
  showModalHolidayClosures: boolean;
  showModalAdditionalClosures: boolean;
  pharmacy?: PharmacyDto;
  status: AsyncStatus;
  editingGeneralInfo: boolean;
  editGeneralInfoId?: string;
  pharmacyId?: string;
  country: CountryCode;
  departmentsHours: DepartmentHours[];
  editingDepartmentHours?: DepartmentHours;
  departments: DepartmentDto[];
  editingDepartmentId?: string;
  editingDepartment?: DepartmentDto;
  allPMS: PmsDto[];
  holidays: HolidayDto[];
  closures: ClosureHoursDto[];
  allParentCompanies: ParentCompanyResponseDto[];
}

export const usePharmacySettingsState = create<PharmacySettingsState>(() => ({
  showModalGeneralInfo: false,
  showModalContactInfo: false,
  showModalDepartmentHours: false,
  showModalHolidayClosures: false,
  showModalAdditionalClosures: false,
  status: 'loading',
  editingGeneralInfo: false,
  pharmacyId: '',
  country: CountryCode.US,
  departments: [],
  departmentsHours: [],
  allPMS: [],
  holidays: [],
  closures: [],
  allParentCompanies: [],
}));
