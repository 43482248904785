import React, {
  FunctionComponent,
  PropsWithChildren,
  useState,
  useEffect,
} from 'react';
import { View, DimensionValue } from 'react-native';
import { TextInput } from '../text-field/TextInput';
import { makeStyles } from '../../theme';
import { Text } from '../text';
import { useTheme } from '../../theme';
import { getText } from '../../localization/localization';

export const SearchInput: FunctionComponent<
  PropsWithChildren<SearchInputProps>
> = ({ title, inputValue, placeholder, onChange, inputColor, width = 320 }) => {
  const theme = useTheme();
  const defaultSearchInputValue = inputValue ?? '';
  const backgroundColor =
    inputColor === InputColor.Bright ? 'transparent' : theme.palette.gray[100];
  const outlineColor =
    inputColor === InputColor.Bright
      ? theme.palette.gray[400]
      : theme.palette.white;

  const styles = useStyles();

  const [searchInputValue, setSearchInputValue] = useState<string>(
    defaultSearchInputValue,
  );

  const handleOnTextChanged = (text: string) => {
    onChange(text);
  };

  useEffect(() => {
    if (inputValue !== undefined) {
      setSearchInputValue(inputValue);
    }
  }, [inputValue]);

  return (
    <View style={styles.container}>
      <View style={styles.toolbarContainer}>
        <View style={styles.titleContainer}>
          {title && <Text style={styles.title}>{title}</Text>}
        </View>
        <View style={styles.searchContainer}>
          <TextInput
            testID="search-input"
            type="search"
            style={{
              height: 45,
              width: width,
              backgroundColor: backgroundColor ?? 'transparent',
            }}
            placeholderColor={theme.palette.gray[500]}
            placeholder={placeholder ?? getText('search')}
            inactiveBorderColor={outlineColor ?? theme.palette.gray[400]}
            onChange={handleOnTextChanged}
            value={searchInputValue}
          />
        </View>
      </View>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.white,
    flex: 1,
  },
  titleContainer: {
    flexDirection: 'row',
  },
  title: {
    ...theme.lumistryFonts.label.large.semiBold,
    fontSize: 26,
    fontWeight: '600',
    lineHeight: 22,
    color: theme.palette.gray[900],
    marginRight: theme.getSpacing(2),
    alignItems: 'center',
    display: 'flex',
  },
  toolbarContainer: {
    flexDirection: 'row',
    width: '100%',
  },
  searchContainer: {
    borderColor: 'white',
  },
}));

export interface SearchInputProps {
  inputValue?: string;
  placeholder: string;
  title?: string;
  onChange: (text: string) => void;
  inputColor: InputColor;
  width?: DimensionValue;
}

export enum InputColor {
  Dark = 'dark',
  Bright = 'bright',
}
