import {
  AvailableRolesDto,
  BulkCreateRolesDto,
  DetailedUserDto,
  EntityType,
  DefaultApi as RoleServiceClient,
  UserRoleDto,
} from '@digitalpharmacist/role-service-client-axios';
import { BaseApiConfiguration, BaseApiService } from 'assets/core/api';
import { logError } from 'assets/logging/logger';
import { AxiosRequestConfig } from 'axios';
import { AXIOS_DEFAULT_REQUEST_CONFIG } from '../common/axios-default-request-config';
import { ROLE_API_BASE_URL } from '../common/constants';
import { RoleOption } from '../screens/settings/Users/UserHelpers';
import { SearchableDatasourceService } from './SearchableDatasourceService';

export interface IRoleService {
  userRoleReplaceRoles(
    userId: string,
    bulkCreateRolesDto: BulkCreateRolesDto,
  ): Promise<UserRoleDto[]>;
}

export class RoleService extends BaseApiService implements IRoleService {
  private roleServiceClient: RoleServiceClient;
  private searchableDatasourceService: SearchableDatasourceService;

  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration,
  ) {
    super(baseUrl, config, enableAuth, baseConfig);
    this.roleServiceClient = new RoleServiceClient(
      undefined,
      baseUrl,
      this.axiosInstance,
    );

    this.searchableDatasourceService = new SearchableDatasourceService(
      baseUrl,
      config,
      enableAuth,
      baseConfig,
    );
  }

  getUsersSearchableDatasource(entityId: string) {
    return this.searchableDatasourceService.getSearchableDatasource(
      `/user_roles/details/pharmacy/${entityId}`,
    );
  }

  async searchPharmacyUsers(
    pharmacyId: string,
    search?: string,
    limit = 25,
  ): Promise<DetailedUserDto> {
    const { data } =
      await this.roleServiceClient.userRoleGetUsersDetailsByPharmacyId(
        pharmacyId,
        search,
        limit,
      );
    return data;
  }

  async searchLocationUsers(
    locationId: string,
    search?: string,
    limit = 25,
  ): Promise<DetailedUserDto> {
    const res = await this.roleServiceClient
      .userRoleGetUsersDetailsByLocationId(locationId, search, limit)
      .catch((e) => {
        logError(new Error(`Error while searching location users: ${e}`));
        throw e;
      });
    const { data } = res;
    return data;
  }

  async userRoleGetRolesByUserId(userId: string): Promise<UserRoleDto[]> {
    const { data } =
      await this.roleServiceClient.userRoleGetRolesByUserId(userId);
    const users = data;
    return users;
  }

  async userRoleGetRolesByUserIdAndLocation(
    userId: string,
    locationId: string,
  ): Promise<AvailableRolesDto> {
    const { data } =
      await this.roleServiceClient.userRoleGetRolesByUserIdAndLocation(
        userId,
        locationId,
      );

    return data;
  }

  async userPharmacyRoleCreate(
    roleObject: RoleOption,
    userId: string,
    entityId: string, // lumistry users do not have an entity id or type
    pharmacyId: string,
  ): Promise<UserRoleDto> {
    const { entityType } = roleObject;
    /** Adding checks here to prevent any data corruption issues */
    if (
      !entityId ||
      (entityType === 'pharmacy' && entityId !== pharmacyId) ||
      (entityType === 'global' && entityId !== '*')
    ) {
      const msg = `Entity id is not valid given: entityType - ${entityType}, entityId - ${entityId}`;
      const e = new Error(msg);
      logError(e);
      throw e;
    }

    const entity_id = entityId;
    const role = roleObject.value;
    const user_id = userId;
    const pharmacy_id = pharmacyId;

    const { data } = await this.roleServiceClient.userRoleCreateUserRole({
      role,
      entity_id,
      user_id,
      pharmacy_id,
    });

    const user = data;
    return user;
  }

  async removeRolesByUserIdPharmacyId(userId: string) {
    await this.roleServiceClient.userRoleRemoveRolesByUserIdPharmacyId(userId);
  }

  async userRoleReplaceRoles(
    userId: string,
    bulkCreateRolesDto: BulkCreateRolesDto,
  ): Promise<UserRoleDto[]> {
    const { data } = await this.roleServiceClient.userRoleReplaceRoles(
      userId,
      bulkCreateRolesDto,
    );
    return data;
  }
}

export default new RoleService(
  ROLE_API_BASE_URL,
  AXIOS_DEFAULT_REQUEST_CONFIG,
  true,
);
