import {
  AutoSaveWebsiteWizardDto,
  YesNoOption,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { Modal } from 'assets/components/modal';
import { RadioButtonGroupField } from 'assets/components/radio-button-group';
import { RadioButtonInputMode } from 'assets/components/radio-button-group/RadioButton';
import { Text } from 'assets/components/text';
import { TextField } from 'assets/components/text-field';
import { Form } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import React, { FunctionComponent, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  openPreviousModalId,
  savePatientWebsite,
  setOpenedModal,
} from '../../../actions/checklist-actions';
import { useChecklistStore } from '../../../store/checklist-store';
import { InfoTooltip } from '../../onboarding/components/common/InfoTooltip';
import { ChecklistWebsiteModalProps } from '../types';

export interface WebsiteAdditionalInfo {
  domainEmail: YesNoOption | undefined;
  demographics: string | undefined;
  tagline: string | undefined;
}

export const PatientWebsiteAdditionalInfoModal: FunctionComponent<
  ChecklistWebsiteModalProps
> = ({ id, data }) => {
  const { currentOpenedModalId } = useChecklistStore();
  const [loading, setLoading] = useState<boolean>(false);
  const domainEmail = data?.website_details?.website_domain_based_email;
  const styles = useStyles();

  const methods = useForm<WebsiteAdditionalInfo>({
    defaultValues: {
      domainEmail:
        domainEmail !== YesNoOption.Unknown ? domainEmail : undefined,
      demographics: data?.website_details?.website_demographics,
      tagline: data?.website_details?.website_tagline,
    },
  });

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const website_domain_based_email = methods.getValues('domainEmail');
      const website_demographics = methods.getValues('demographics');
      const website_tagline = methods.getValues('tagline');
      const dto: AutoSaveWebsiteWizardDto = {
        ...data,
        website_details: {
          ...data.website_details,
          website_domain_based_email,
          website_demographics,
          website_tagline,
        },
      };
      await savePatientWebsite(dto);
      setOpenedModal('patient-website-locations');
    } finally {
      setLoading(false);
    }
  };

  const handleBackButton = () => {
    const services = data.website_details?.website_service_options;
    services && openPreviousModalId(services, 4);
  };

  return (
    <Modal
      title={getText('additional-info')}
      show={currentOpenedModalId === id}
      handleDismissModal={() => setOpenedModal('checklist-setup')}
      showDismissButton
      isScrollable
      size="sm"
      buttons={[
        {
          hierarchy: 'primary',
          text: getText('next'),
          onPress: methods.handleSubmit(handleSubmit),
          logger: { id: 'next-website-access-setup' },
          loading,
        },
        {
          text: getText('back'),
          hierarchy: 'tertiary-gray',
          onPress: handleBackButton,
          logger: { id: 'back-website-access-setup' },
        },
      ]}
    >
      <Form methods={methods}>
        <Form.Row>
          <Form.Column>
            <Text style={styles.title}>
              {getText('organization-use-domain') + '*'}
              <InfoTooltip
                style={styles.tootlip}
                size={20}
                tooltipId="organization-use-domain"
                text={getText('organization-use-domain-tooltip')}
              />
            </Text>
            <RadioButtonGroupField
              name="domainEmail"
              isHorizontal
              mode={RadioButtonInputMode.FLAT}
              values={[
                {
                  text: getText('yes'),
                  value: 'Yes',
                },
                {
                  text: getText('no'),
                  value: 'No',
                },
              ]}
              rules={{
                required: getText('field-required'),
              }}
            />
            <Text style={styles.title}>
              {`${getText('tell-us-about-demographics')} (${getText(
                'required',
              )})`}
            </Text>
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              name="demographics"
              type="text"
              label={getText('describe-demographics')}
              rules={{
                required: getText('field-required'),
                validate: {
                  value: (value) => {
                    return value.trim().length > 0
                      ? true
                      : getText('field-not-valid');
                  },
                },
              }}
              hideRequiredAsterisk
              numberOfLines={5}
              multiline
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <Text style={styles.title}>
              {getText('do-you-have-tagline')}
              <Text style={styles.subtitle}> ({getText('required')})</Text>
            </Text>
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              name="tagline"
              type="text"
              rules={{
                required: getText('field-required'),
                validate: {
                  value: (value) => {
                    return value.trim().length === 0
                      ? getText('field-not-valid')
                      : value.trim().length <= 255
                      ? true
                      : getText('text-long');
                  },
                },
              }}
              hideRequiredAsterisk
              label={getText('tagline')}
              numberOfLines={5}
              multiline
            />
          </Form.Column>
        </Form.Row>
      </Form>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.lumistryFonts.text.medium.regular,
    color: theme.palette.gray[700],
  },
  subtitle: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.gray[700],
  },
  tootlip: {
    left: theme.getSpacing(1),
    top: theme.getSpacing(0.5),
  },
}));
