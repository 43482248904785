import { Text } from 'assets/components/text';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { useOnboardingStore } from '../../../../store/onboarding-store';
import { OBContentHeader } from '../content-header/OBContentHeader';
import OBDivider from '../divider/OBDivider';
import { OBLoaderIcon } from '../icons/OBLoaderIcon';

export const OBMultiLocationReview: FunctionComponent = () => {
  const styles = useStyles();
  const { data } = useOnboardingStore();
  const { brand_name, locations } = data;
  const { first_name, last_name, email, phone } = locations[0]?.employees?.[0];

  return (
    <View style={styles.root}>
      <OBContentHeader
        icon={OBLoaderIcon}
        title={getText('review')}
        description={getText('click-sections-above')}
      />

      <OBDivider />
      <View style={styles.container}>
        <View style={styles.textContainer}>
          <Text style={styles.title}>{`${getText(
            'ob-multi-review-brand-name',
          )}:`}</Text>
          <Text style={styles.subtitle}>{brand_name}</Text>
        </View>

        <View style={styles.textContainer}>
          <Text style={styles.title}>{`${getText(
            'ob-multi-review-number-locations',
          )}:`}</Text>
          <Text style={styles.subtitle}>{locations.length}</Text>
        </View>
        <View style={styles.textContainer}>
          <Text style={styles.title}>{`${getText('primary-contact')}:`}</Text>
          <Text style={styles.subtitle}>
            {first_name} {last_name}
          </Text>
          <Text style={styles.subtitle}>{phone}</Text>
          <Text style={styles.subtitle}>{email}</Text>
        </View>
      </View>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    alignContent: 'center',
    marginBottom: theme.getSpacing(4),
  },
  container: {
    alignItems: 'center',
    gap: theme.getSpacing(4),
    marginTop: theme.getSpacing(4),
  },
  title: {
    ...theme.lumistryFonts.text.small.bold,
  },
  subtitle: {
    ...theme.lumistryFonts.text.medium.regular,
    color: theme.palette.gray[700],
  },
  textContainer: {
    alignItems: 'center',
  },
}));
