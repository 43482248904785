import { Text } from 'assets/components/text';
import { makeStyles, useTheme } from 'assets/theme';
import { FunctionComponent, PropsWithChildren } from 'react';
import { View } from 'react-native';
import { InfoIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { Icon } from 'assets/components/icon/Icon';
import { Tooltip } from 'assets/components/tooltip/components/tooltip';
import { PharmacyTooltipWrapper } from '../../../../../common/PharmacyTooltipWrapper';

export const RefillDueRemindersItemRender: FunctionComponent<
  PropsWithChildren<RefillDueRemindersItemRenderProps>
> = ({ label, value }) => {
  const styles = useStyles();
  const theme = useTheme();

  return (
    <View style={styles.container}>
      <View style={styles.firstColumnContent}>
        <View style={styles.rowView}>
          <Text style={styles.title}>{label}</Text>
          <View style={styles.tooltip}>
            {label.includes('Past due reminder') ? (
              <>
                <PharmacyTooltipWrapper tooltipId="how-many-days-reminder-tooltip">
                  <Icon
                    icon={InfoIcon}
                    size={16}
                    color={theme.palette.gray[500]}
                  />
                </PharmacyTooltipWrapper>
                <Tooltip
                  id="how-many-days-reminder-tooltip"
                  place="top"
                  variant="dark"
                  text={getText('how-many-days-past-due-reminder-tooltip')}
                />
              </>
            ) : (
              <>
                <PharmacyTooltipWrapper tooltipId="how-many-days-reminder-tooltip-past-due">
                  <Icon
                    icon={InfoIcon}
                    size={16}
                    color={theme.palette.gray[500]}
                  />
                </PharmacyTooltipWrapper>
                <Tooltip
                  id="how-many-days-reminder-tooltip-past-due"
                  place="top"
                  variant="dark"
                  text={getText('how-many-days-reminder-tooltip')}
                />
              </>
            )}
          </View>
        </View>
      </View>
      <View style={{ flex: 3 }}>
        <Text style={styles.value}>
          {value}{' '}
          {label.includes('Past due reminder')
            ? getText('days-after')
            : getText('days-prior')}
        </Text>
      </View>
    </View>
  );
};

interface RefillDueRemindersItemRenderProps {
  label: string;
  value: number;
}

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'row',
    gap: theme.getSpacing(2),
    alignItems: 'center',
    borderBottomColor: theme.palette.gray[200],
    borderBottomWidth: 1,
    padding: theme.getSpacing(1),
    marginBottom: theme.getSpacing(1),
  },
  firstColumnContent: {
    flex: 1,
    flexDirection: 'row',
  },
  title: {
    ...theme.lumistryFonts.label.small.semiBold,
  },
  value: {
    ...theme.lumistryFonts.text.small.regular,
  },
  actionViewStyle: {
    alignContent: 'flex-end',
    flexDirection: 'row',
  },
  rowView: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  tooltip: {
    marginLeft: theme.getSpacing(1),
    marginTop: theme.getSpacing(0.5),
  },
}));
