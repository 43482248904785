import { IconButton } from 'assets/components/icon-button';
import { Modal } from 'assets/components/modal';
import { Text } from 'assets/components/text';
import { PencilIcon, TrashIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Pressable, View } from 'react-native';
import {
  savePatientWebsite,
  setCurrentOpenedLocationId,
  setOpenedModal,
  setWebsiteLocations,
} from '../../../../actions/checklist-actions';
import { useChecklistStore } from '../../../../store/checklist-store';
import { ChecklistWebsiteModalProps } from '../../types';
import { logError } from 'assets/logging/logger';
import { AutoSaveWebsiteWizardDto } from '@digitalpharmacist/pharmacy-service-client-axios';

export const PatientWebsiteLocationsModal: FunctionComponent<
  ChecklistWebsiteModalProps
> = ({ id, data }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { currentOpenedModalId, websiteLocations: locations } =
    useChecklistStore();
  const styles = useStyles();
  const theme = useTheme();

  useEffect(() => {
    setWebsiteLocations(data.website_details?.website_locations);
  }, [data.website_details?.website_locations]);

  const handleNextButton = async () => {
    try {
      setLoading(true);
      const dto: AutoSaveWebsiteWizardDto = {
        ...data,
        website_details: {
          ...data.website_details,
          website_locations: locations,
        },
      };
      await savePatientWebsite(dto);
      setOpenedModal('website-access-setup');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (id: string) => {
    const updatedLocations = locations?.map((location) =>
      location.id === id ? { ...location, selected: false } : location,
    );

    setWebsiteLocations(updatedLocations);
  };

  const handleAddDescription = (id: string) => {
    setCurrentOpenedLocationId(id);
    setOpenedModal('patient-website-location-desc');
  };

  return (
    <Modal
      title={getText('add-locations')}
      show={currentOpenedModalId === id}
      handleDismissModal={() => setOpenedModal('checklist-setup')}
      showDismissButton
      isScrollable
      size="sm"
      buttons={[
        {
          hierarchy: 'primary',
          text: getText('next'),
          onPress: handleNextButton,
          logger: { id: 'next-website-notification' },
          loading,
        },
        {
          text: getText('back'),
          hierarchy: 'tertiary-gray',
          onPress: () => setOpenedModal('patient-website-additional-info'),
          logger: { id: 'back-website-notification' },
        },
      ]}
    >
      <Text style={styles.title}>{getText('add-location-details')}</Text>
      <View style={styles.container}>
        {locations
          ?.filter((x) => x.selected)
          ?.map((location) => (
            <View style={styles.location}>
              <Text style={styles.locationName}>{location.name}</Text>
              <View style={styles.descContainer}>
                {!location.description && (
                  <Pressable onPress={() => handleAddDescription(location.id)}>
                    <Text style={styles.locationDesc}>
                      {getText('add-description')}
                    </Text>
                  </Pressable>
                )}
                <IconButton
                  onPress={() => handleDelete(location.id)}
                  icon={TrashIcon}
                  logger={{ id: 'delete-location-details' }}
                  color={theme.palette.gray[500]}
                  size={16}
                />
              </View>
            </View>
          ))}
      </View>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.getSpacing(2),
  },
  title: {
    ...theme.lumistryFonts.text.medium.regular,
    color: theme.palette.gray[700],
  },
  location: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  locationName: {
    ...theme.lumistryFonts.text.medium.regular,
    color: theme.palette.gray[900],
  },
  descContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  locationDesc: {
    ...theme.lumistryFonts.text.small.semiBold,
    color: theme.palette.primary[600],
    paddingRight: theme.getSpacing(1),
  },
}));
