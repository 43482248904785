import React, { FunctionComponent, useEffect, useState } from 'react';
import { CreateAppSettingsDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { Modal } from 'assets/components/modal';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import { View } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import {
  checkAppLogoRequirement,
  createAppSettings,
  fetchDunsList,
  setOpenedModal,
} from '../../../actions/checklist-actions';
import { useChecklistStore } from '../../../store/checklist-store';
import { ChecklistModalProps } from '../types';
import { Text } from 'assets/components/text';
import { Form } from 'assets/layout';
import { TextField } from 'assets/components/text-field';
import { useForm } from 'react-hook-form';
import * as validate from '@digitalpharmacist/validation-dp';
import { InfoTooltip } from '../../onboarding/components/common/InfoTooltip';
import { DropdownSelectField } from 'assets/components/dropdown-select';
import { UploadImage } from '../../../components/checklist/UploadImage';
import { useAppStateStore } from '../../../store/app-store';
import { PharmacyCategory } from '@digitalpharmacist/file-storage-service-client-axios';
import { useToast } from '../../../common/hooks/useToast';
import { uploadPrivatePharmacyFile } from '../../../actions/file-storage-actions';

type ConfirmSubmissionModalProps = {
  show: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  loading?: boolean;
};

type AppSettingsCardsUriType = {
  [key in keyof Pick<
    CreateAppSettingsDto,
    'id_front_filename' | 'id_back_filename'
  >]: string;
};

const ConfirmSubmissionModal: FunctionComponent<
  ConfirmSubmissionModalProps
> = ({ show, onSubmit, onCancel, loading }) => {
  const styles = useStyles();
  return (
    <Modal
      title={getText('checklist-app-config-submission-title')}
      show={show}
      showDismissButton
      handleDismissModal={onCancel}
      size="sm"
      buttons={[
        {
          text: getText('submit'),
          onPress: onSubmit,
          hierarchy: 'primary',
          logger: { id: 'submit-checklist-app-settings-submission-modal' },
          disabled: loading,
          loading: loading,
        },
        {
          text: getText('cancel'),
          onPress: onCancel,
          hierarchy: 'tertiary-gray',
          logger: { id: 'cancel-checklist-app-settings-submission-modal' },
        },
      ]}
      isScrollable
    >
      <View style={styles.submissionModalContainer}>
        <Text style={styles.submissionModalText}>
          {getText('checklist-app-config-submission-message')}
        </Text>
        <Text style={styles.submissionModalText}>
          {getText('checklist-app-config-submission-description')}
        </Text>
      </View>
    </Modal>
  );
};

export const ChecklistAppSettingsModal: FunctionComponent<
  ChecklistModalProps
> = ({ id }) => {
  const styles = useStyles();
  const { currentOpenedModalId, dunsList } = useChecklistStore();
  const { toast } = useToast();

  const pharmacyId = useAppStateStore((x) => x.pharmacyId);

  const [submissionModalShow, setSubmissionModalShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [idCardsUri, setIdCardsUri] = useState<AppSettingsCardsUriType>({
    id_front_filename: '',
    id_back_filename: '',
  });

  const methods = useForm<CreateAppSettingsDto>({
    defaultValues: {},
  });

  const handleCloseModal = () => setOpenedModal('checklist-setup');

  const uploadImages = async (): Promise<AppSettingsCardsUriType> => {
    const upload = (
      formField: keyof AppSettingsCardsUriType,
    ): Promise<Partial<AppSettingsCardsUriType>> => {
      return new Promise((resolve, reject) => {
        const fileName = methods.getValues(formField);
        uploadPrivatePharmacyFile(
          PharmacyCategory.LegalDocuments,
          fileName,
          idCardsUri[formField],
        )
          .then((url) => resolve({ [formField]: fileName }))
          .catch(reject);
      });
    };

    const uploadPromises = Object.keys(idCardsUri).map(async (key) =>
      upload(key as keyof AppSettingsCardsUriType),
    );

    const allFilesPromises =
      await Promise.allSettled<Partial<AppSettingsCardsUriType>>(
        uploadPromises,
      );

    const filesObject = allFilesPromises.reduce<AppSettingsCardsUriType>(
      (acc, curr) => {
        if (curr.status === 'fulfilled') return { ...acc, ...curr.value };
        return acc ?? {};
      },
      {} as AppSettingsCardsUriType,
    );
    return filesObject;
  };

  const handleSubmissionModalSubmit = async () => {
    try {
      setIsLoading(true);

      const appSettingsCreate = methods.getValues();

      const uploadedCards = await uploadImages();

      await createAppSettings({ ...appSettingsCreate, ...uploadedCards });

      setIsLoading(false);
      handleSubmissionModalClose();
      handleCloseModal();
    } catch (e: any) {
      toast(e.message ?? getText('something-went-wrong-implicit'), {
        type: 'error',
      });
      setIsLoading(false);
      handleSubmissionModalClose();
    }
  };

  const handleSubmissionModalClose = () => {
    setSubmissionModalShow(false);
  };

  const handleSubmissionModalShow = () => {
    setSubmissionModalShow(true);
  };

  const handleFormSubmit = () => {
    void methods.handleSubmit(handleSubmissionModalShow)();
  };

  const choosePhoto =
    (formField: keyof AppSettingsCardsUriType) =>
    (photo: ImagePicker.ImagePickerAsset) => {
      methods.setValue(formField, photo.fileName ?? formField);
      setIdCardsUri({ ...idCardsUri, [formField]: photo.uri });
    };

  useEffect(() => {
    void fetchDunsList();
  }, [pharmacyId]);

  return (
    <Modal
      title={getText('checklist-app-config-title')}
      show={currentOpenedModalId === id}
      showDismissButton
      handleDismissModal={handleCloseModal}
      size="sm"
      buttons={[
        {
          text: getText('ok'),
          onPress: handleFormSubmit,
          hierarchy: 'primary',
          logger: { id: 'ok-checklist-app-settings-modal' },
        },
        {
          text: getText('cancel'),
          onPress: handleCloseModal,
          hierarchy: 'tertiary-gray',
          logger: { id: 'cancel-checklist-app-settings-modal' },
        },
      ]}
      isScrollable
    >
      <View style={styles.container}>
        <Form methods={methods}>
          <Form.Row>
            <Form.Column>
              <TextField
                name="app_name"
                type="text"
                label={getText('checklist-app-config-name')}
                rules={{
                  required: getText('checklist-app-config-name-required'),
                  maxLength: {
                    value: 36,
                    message: getText('checklist-app-config-name-max-character'),
                  },
                }}
                hintMessage={getText('checklist-app-config-name-hint')}
              />
            </Form.Column>
          </Form.Row>

          <Form.Row>
            <Form.Column>
              <Text style={styles.title}>
                {getText('checklist-app-config-app-dev-account')}
              </Text>
              <Text style={styles.secondTitle}>
                {getText('checklist-app-config-app-dev-account-desc')}
              </Text>
            </Form.Column>
          </Form.Row>

          <Form.Row>
            <Form.Column>
              <TextField
                name="first_name"
                type="text"
                label={getText('first-name')}
                rules={{
                  required: getText('first-name-is-required'),
                }}
              />
            </Form.Column>
            <Form.Column>
              <TextField
                name="last_name"
                type="text"
                label={getText('last-name')}
                rules={{
                  required: getText('last-name-is-required'),
                }}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column>
              <TextField
                name="phone_number"
                type="telephoneNumber2"
                label={getText('phone-number')}
                rules={{
                  required: getText('phone-is-required'),
                  validate: {
                    value: (value: string) => {
                      return validate.isPhoneNumber(value ? '+1' + value : '')
                        ? true
                        : getText('phone-is-not-valid');
                    },
                  },
                }}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column>
              <View style={styles.dunsContainer}>
                <View style={{ flex: 1 }}>
                  <DropdownSelectField
                    name="selected_duns"
                    options={
                      dunsList?.map((x) => ({ label: x, value: x })) ?? []
                    }
                    labelInlined
                    label={getText('checklist-app-config-duns-placeholder')}
                    rules={{
                      required: getText('checklist-app-config-duns-required'),
                    }}
                  />
                </View>

                <View style={styles.dunsTooltipContainer}>
                  <InfoTooltip
                    tooltipId="checklist-duns"
                    text={getText('checklist-app-config-duns-tooltip')}
                  />
                </View>
              </View>
            </Form.Column>
          </Form.Row>

          <Form.Row>
            <Form.Column>
              <TextField
                name="website_url"
                type="text"
                label={getText('checklist-app-config-website-url')}
                rules={{
                  required: getText(
                    'checklist-app-config-website-url-required',
                  ),
                  validate: {
                    value: (value: string) => {
                      return validate.isURL(value)
                        ? true
                        : getText('checklist-app-config-website-url-not-valid');
                    },
                  },
                }}
              />
            </Form.Column>
          </Form.Row>

          <Form.Row>
            <Form.Column>
              <TextField
                name="app_marketplace_name"
                type="text"
                label={getText('checklist-app-config-market-name')}
                rules={{
                  required: getText(
                    'checklist-app-config-market-name-required',
                  ),
                  maxLength: {
                    value: 30,
                    message: getText(
                      'checklist-app-config-market-name-max-characters',
                    ),
                  },
                }}
                hintMessage={getText(
                  'checklist-app-config-market-name-description',
                )}
              />
            </Form.Column>
          </Form.Row>

          <Form.Row>
            <Form.Column>
              <View style={styles.idCardContainer}>
                <Text style={styles.title}>
                  {`${getText('checklist-app-config-id-title')}*`}
                </Text>
                <InfoTooltip
                  tooltipId="pharmacy-store-address"
                  text={getText('checklist-app-config-id-tooltip')}
                  size={16}
                />
              </View>
            </Form.Column>
          </Form.Row>

          <Form.Row>
            <Form.Column>
              <View style={styles.uploadContainer}>
                <UploadImage
                  title={getText('front') + ' *'}
                  uri={idCardsUri['id_front_filename']}
                  choosePhoto={choosePhoto('id_front_filename')}
                  required={
                    methods.formState.isSubmitted &&
                    !idCardsUri['id_front_filename']
                  }
                  validateRules={(
                    photo: ImagePicker.ImagePickerSuccessResult,
                  ) => checkAppLogoRequirement(photo)}
                />
                <UploadImage
                  title={getText('back') + ' *'}
                  required={
                    methods.formState.isSubmitted &&
                    !idCardsUri['id_back_filename']
                  }
                  uri={idCardsUri['id_back_filename']}
                  choosePhoto={choosePhoto('id_back_filename')}
                  validateRules={(
                    photo: ImagePicker.ImagePickerSuccessResult,
                  ) => checkAppLogoRequirement(photo)}
                />
              </View>
            </Form.Column>
          </Form.Row>
        </Form>
      </View>
      <ConfirmSubmissionModal
        show={submissionModalShow}
        onSubmit={handleSubmissionModalSubmit}
        onCancel={handleSubmissionModalClose}
        loading={isLoading}
      />
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    flexDirection: 'column',
    gap: theme.getSpacing(3),
  },
  idCardContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.getSpacing(1),
  },
  dunsContainer: {
    flexDirection: 'row',
    gap: theme.getSpacing(1),
  },
  dunsTooltipContainer: {
    paddingTop: theme.getSpacing(2),
  },
  title: {
    ...theme.lumistryFonts.text.medium.semiBold,
    color: theme.palette.gray[900],
  },
  secondTitle: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.gray[700],
  },
  uploadContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: theme.getSpacing(2),
  },
  submissionModalContainer: {
    gap: theme.getSpacing(2),
  },
  submissionModalText: {
    ...theme.lumistryFonts.text.medium.regular,
    color: theme.palette.gray[700],
  },
}));
