import React, { FunctionComponent } from 'react';
import { save } from '../../actions/onboarding-actions';
import { OnboardingHeader } from '../../components/onboarding-header';
import { StackHeaderProps } from '@react-navigation/stack';
import { logout } from '../../screens/login/login-actions';
import { useUserState } from '../../store/user-store';

export const OnboardingNavigationHeader: FunctionComponent<StackHeaderProps> = (
  props,
) => {
  const handleSaveAndLogout = async () => {
    await save();
    await logout();
  };

  const { data: user } = useUserState();

  return (
    <OnboardingHeader
      onSaveAndLogout={handleSaveAndLogout}
      email={user?.email}
    />
  );
};
