import { GridApi, GridReadyEvent } from '@ag-grid-community/core';
import {
  AppointmentTypeDto,
  BookingPageDto,
} from '@digitalpharmacist/appointment-service-client-axios';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { DataGrid } from 'assets/components/data-grid';
import { IconButton } from 'assets/components/icon-button';
import {
  ClockIcon,
  MessageSquareIcon,
  PencilIcon,
  UserXIcon,
} from 'assets/icons';
import { makeStyles, useTheme } from 'assets/theme';
import React, { FunctionComponent, useCallback, useRef, useState } from 'react';
import { View } from 'react-native';

import { ColDef, ColGroupDef } from '@ag-grid-community/core';
import { RouteProp } from '@react-navigation/native';
import moment from 'moment';
import { useProSidebar } from 'react-pro-sidebar';
import { getText } from '../../../../../packages/assets/localization/localization';
import AppointmentService from '../../api/AppointmentService';
import { PharmacyTooltipWrapper } from '../../common/PharmacyTooltipWrapper';
import { AG_GRID_PAGINATION_PAGE_SIZE } from '../../common/constants';
import {
  DEFAULT_DATE_TIME_API_FORMAT,
  DEFAULT_PHARMACY_TIMEZONE,
  calculatePatientAge,
  convertDateTimeFromUtcToPharmacyTimezone,
  formatDate,
  formatDateTimeApi,
  formatUTCToRelative,
} from '../../common/datetime-utils';
import { LoadingOverlay } from '../../components/LoadingOverlay';
import NoResultsOverlay from '../../components/NoResultsOverlay';
import PharmacyConfirmationModal from '../../components/PharmacyConfirmationModal';
import { Tooltip } from 'assets/components/tooltip/components/tooltip';
import {
  ScheduleDrawerNavigationParamList,
  ScheduleDrawerNavigationProp,
} from '../../layout/ScheduleDrawer';
import { useAppointmentFiltersState } from '../appointment-filters/appointment-filters-store';
import { composeAppointmentFiltersMessage } from '../appointment-filters/appointment-filters.utils';
import { AppointmentDetailsSidebar } from './AppointmentDetailsSidebar';
import {
  cancelBooking,
  setAppointmentVisibility,
  showAppointmentDetails,
} from './appointments-list-actions';
import {
  AppointmentsFindParams,
  minutesDifference,
} from './appointments-list.utils';
import { useServicesListState } from '../services-list/services-list-store';
import NoServicesImage from '../services-list/NoServicesImage';
import NoAppointmentsImage from '../appointments-calendar/NoAppointmentsImage';
import { BulletPointStyle } from '../../components/EmptyState/BulletPoints/BulletPoint';
import EmptyState from '../../components/EmptyState/EmptyState';
import usePatientStore from '../../screens/patients/patient-store';
import './appointments-list-grid.css';

const NoLoading: FunctionComponent = () => {
  return <View style={{ height: '100%' }}></View>;
};

const ActionButtonsRenderer = (props: {
  data: AppointmentTypeDto;
  api: GridApi;
}) => {
  const styles = useStyles();
  const navigation = useNavigation<ScheduleDrawerNavigationProp>();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const rowData = props.data;

  const handleCancelBooking = async () => {
    setShowModal(false);
    setLoading(true);
    await cancelBooking(rowData.id);
    props.api.refreshServerSideStore();
    setLoading(false);
  };

  return (
    <View style={styles.cellContainer}>
      <PharmacyTooltipWrapper
        tooltipId="appointments-list-edit-tooltip"
        isContentIcon={true}
      >
        <IconButton
          icon={PencilIcon}
          logger={{ id: `edit-service--${rowData.id}` }}
          onPress={() =>
            navigation.navigate('edit-appointment', {
              appointmentId: rowData.id,
            })
          }
        />
      </PharmacyTooltipWrapper>
      <PharmacyTooltipWrapper
        tooltipId="appointments-list-cancel-tooltip"
        isContentIcon={true}
      >
        <IconButton
          icon={UserXIcon}
          logger={{ id: `cancel-service--${rowData.id}` }}
          onPress={() => setShowModal(true)}
          disabled={loading}
        />
      </PharmacyTooltipWrapper>
      <PharmacyTooltipWrapper
        tooltipId="appointments-list-message-tooltip"
        isContentIcon={true}
      >
        <IconButton
          icon={MessageSquareIcon}
          logger={{ id: `message-service--${rowData.id}` }}
          onPress={() =>
            alert('Under construction. Exciting things coming soon!')
          }
        />
      </PharmacyTooltipWrapper>
      <PharmacyConfirmationModal
        show={showModal}
        message={getText('cancel-appointment-confirmation')}
        onAccepted={() => void handleCancelBooking()}
        onDismiss={() => setShowModal(false)}
      />
    </View>
  );
};

export const AppointmentsList: FunctionComponent<AppointmentsListProp> = ({
  route,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const [paginatedDatasource] = useState(
    AppointmentService.getAppointmentsPaginatedDatasource(
      `?min_end_date=${formatDateTimeApi(moment())}`,
    ),
  );
  const services = useServicesListState((state) => state.services);
  const [appointmentsCount, setAppointmentsCount] = useState<number | null>(
    null,
  );
  const gridApiRef = useRef<GridApi>();
  const navigation = useNavigation<ScheduleDrawerNavigationProp>();

  const filters = useAppointmentFiltersState((state) => state.filters);
  const isCustomFilter = useAppointmentFiltersState(
    (state) => state.isCustomFilter,
  );
  const [tooltipData, setTooltipData] = useState<TooltipProps>();
  const { collapseSidebar } = useProSidebar();

  const [columnDefs] = useState<(ColDef | ColGroupDef)[]>([
    {
      field: 'full_name',
      colId: 'patient_record_first_name',
      sortable: true,
      headerName: getText('name'),
      cellRenderer: ({ value, rowIndex }: CellRendererProps) => (
        <PharmacyTooltipWrapper
          value={value}
          rowIndex={rowIndex}
          setTooltipData={setTooltipData}
        />
      ),
    },
    {
      field: 'patient_record_date_of_birth',
      headerName: getText('birthdate'),
      cellRenderer: ({ value, rowIndex }: CellRendererProps) => (
        <PharmacyTooltipWrapper
          value={
            value && `${formatDate(value)} (${calculatePatientAge(value)})`
          }
          rowIndex={rowIndex}
          setTooltipData={setTooltipData}
        />
      ),
    },
    {
      field: 'title',
      headerName: getText('service'),
      sortable: true,
      cellRenderer: ({ value, rowIndex }: CellRendererProps) => (
        <PharmacyTooltipWrapper
          value={value}
          rowIndex={rowIndex}
          setTooltipData={setTooltipData}
        />
      ),
    },
    {
      field: 'appointment_group_type',
      headerName: getText('service-category'),
      sortable: true,
      cellRenderer: ({ value, rowIndex }: CellRendererProps) => (
        <PharmacyTooltipWrapper
          value={value}
          rowIndex={rowIndex}
          setTooltipData={setTooltipData}
        />
      ),
    },
    {
      maxWidth: 140,
      field: 'duration',
      headerName: getText('duration'),
      cellRenderer: ({ value, rowIndex }: CellRendererProps) => (
        <PharmacyTooltipWrapper
          value={`${value} min`}
          rowIndex={rowIndex}
          setTooltipData={setTooltipData}
        />
      ),
    },
    {
      maxWidth: 200,
      field: 'startTime',
      colId: 'start_time',
      sortable: true,
      sort: 'asc',
      cellRenderer: ({ value, rowIndex }: CellRendererProps) => (
        <PharmacyTooltipWrapper
          value={formatUTCToRelative(
            convertDateTimeFromUtcToPharmacyTimezone(
              value,
              DEFAULT_PHARMACY_TIMEZONE,
              DEFAULT_DATE_TIME_API_FORMAT,
            ),
            undefined,
            true,
            'US',
            undefined,
            undefined,
            true,
          )}
          rowIndex={rowIndex}
          setTooltipData={setTooltipData}
        />
      ),

      headerName: getText('time'),
      headerClass: 'data-grid-header-right-aligned',
      cellClass: 'data-grid-cell-right-aligned',
    },

    {
      maxWidth: 200,
      field: 'actions',
      headerName: getText('actions'),
      headerClass: 'data-grid-header-right-aligned',
      cellRenderer: ActionButtonsRenderer,
      cellStyle: { display: 'flex', justifyContent: 'flex-end' },
    },
  ]);

  const { setSidebarDetails: setPatientSidebarDetails } = usePatientStore();

  useFocusEffect(
    useCallback(() => {
      setAppointmentsCount(null);
    }, []),
  );

  useFocusEffect(
    useCallback(() => {
      setAppointmentsCount(null);
      gridApiRef.current?.dispatchEvent({ type: 'filterChanged' });
    }, [filters]),
  );

  const showAppointmentSidebar = (id: string) => {
    showAppointmentDetails(id);
    collapseSidebar(false);
    gridApiRef.current?.getRowNode(id)?.setSelected(true);
  };

  const handleCancel = () => {
    if (gridApiRef.current) {
      gridApiRef.current.refreshServerSideStore();
      navigation.navigate('appointments');
    }
  };

  const handleCollapse = () => {
    if (gridApiRef.current) {
      gridApiRef.current.deselectAll();
      navigation.navigate('appointments');
    }
  };

  const emptyStateProps =
    services && services.length > 0
      ? {
          title: getText('no-upcoming-appointment'),
          subtitles: [getText('schedule-an-appointment')],
          image: <NoAppointmentsImage />,
          buttonText: getText('new-appointment-button'),
          onButtonPress: () => navigation.navigate('new-appointment', {}),
          buttonLoggerId: 'schedule-an-appointment',
        }
      : {
          title: getText('welcome-to-services'),
          subtitles: [getText('setup-bookable-service')],
          image: <NoServicesImage />,
          buttonText: getText('new-service-button'),
          onButtonPress: () => navigation.navigate('new-service'),
          buttonLoggerId: 'new-service',
        };

  return (
    <>
      <View
        style={[styles.container, appointmentsCount === null && styles.hidden]}
      >
        {appointmentsCount === null || appointmentsCount > 0 ? (
          <>
            <View style={styles.flexOne}>
              <DataGrid
                gridOptions={{
                  onGridReady(event: GridReadyEvent) {
                    gridApiRef.current = event.api;
                  },
                  columnDefs: columnDefs,
                  enableCellTextSelection: true,
                  suppressRowClickSelection: true,
                  suppressMovableColumns: true,
                  suppressContextMenu: true,
                  defaultColDef: { sortable: false, menuTabs: [] },
                  pagination: true,
                  paginationPageSize: AG_GRID_PAGINATION_PAGE_SIZE,
                  cacheBlockSize: AG_GRID_PAGINATION_PAGE_SIZE,
                  rowModelType: 'serverSide',
                  serverSideStoreType: 'partial',
                  serverSideDatasource: paginatedDatasource,
                  getRowNodeId: (data) => data.id,
                  onModelUpdated: () => {
                    route.params?.id && showAppointmentSidebar(route.params.id);
                  },
                  loadingCellRendererSelector: (params) => {
                    // This is a temporary workaround to only show one loading indicator per table row
                    // What we want to do in the future is create a RowLoadingPlaceholder which would indicate the loading state for the row
                    if (
                      params.rowIndex == 0 ||
                      (params.rowIndex % 5 == 0 && params.rowIndex % 10 !== 0)
                    ) {
                      return {
                        component: LoadingOverlay,
                      };
                    }

                    return {
                      component: NoLoading,
                    };
                  },
                  context: {
                    transformResponse(params: BookingPageDto) {
                      const { results, total } = params;

                      setAppointmentsCount(total);

                      const resultsWithUserData = results.map((x) => {
                        return {
                          ...x,
                          full_name: `${x.patient_record_first_name} ${x.patient_record_last_name}`,
                          duration: minutesDifference(x.endTime, x.startTime),
                        };
                      });

                      return {
                        total,
                        results: resultsWithUserData,
                      };
                    },
                    transformRequest(params: AppointmentsFindParams) {
                      return {
                        ...params,
                        ...filters,
                      };
                    },
                  },
                  noRowsOverlayComponent: () => (
                    <NoResultsOverlay
                      title={getText('no-appointments')}
                      subtitle={getText('check-later')}
                      icon={
                        <ClockIcon size={100} color={theme.palette.gray[300]} />
                      }
                      layout="vertical"
                    />
                  ),
                  onCellClicked(event) {
                    // We don't want to open the sidepanel if the user clicked on one of the actions buttons
                    // unfortunately ag-grid propagates this event no matter what (eg. suppressRowClickSelection)
                    // sidepanel won't trigger if the user clicks on the padding surrounding the action buttons @rtud
                    if (event.column && event.column.getColId() !== 'actions') {
                      setAppointmentVisibility(true);
                      setPatientSidebarDetails(undefined);
                      showAppointmentDetails(event.data.id);
                      event.api.selectNode(event.node);
                      collapseSidebar(false);
                      navigation.navigate('appointments', {
                        id: event.data.id,
                      });
                    }
                  },
                }}
                gridToolbarProps={{
                  titleProps: {
                    title: `${getText('upcoming')}  ${getText('appointments')}`,
                  },
                  toolbarContentBoxProps: {
                    isVisible: isCustomFilter,
                    title: getText('appointments-filters-info-title'),
                    description: composeAppointmentFiltersMessage(),
                  },
                }}
                className="appointment-list-grid"
              />
            </View>
            <AppointmentDetailsSidebar
              onCancel={handleCancel}
              onCollapse={handleCollapse}
            />
          </>
        ) : (
          <EmptyState
            {...emptyStateProps}
            bulletPoints={[
              getText('patients-book-via-mobile'),
              getText('pharmacy-staff-can-also-book'),
              getText('use-pharmacy-hours-custom-availability'),
              getText('see-upcoming-appointments'),
            ]}
            bulletPointStyle={BulletPointStyle.Bullet}
          />
        )}

        <Tooltip
          id="appointments-list-edit-tooltip"
          text={getText('edit-reschedule-this-appointment')}
        />
        <Tooltip
          id="appointments-list-cancel-tooltip"
          text={getText('cancel-this-appointment')}
        />
        <Tooltip
          id="appointments-list-message-tooltip"
          text={getText('message-this-patient')}
        />
      </View>
      {tooltipData && <Tooltip text={tooltipData.value} id={tooltipData.id} />}
    </>
  );
};

interface AppointmentsListProp {
  route: RouteProp<ScheduleDrawerNavigationParamList, 'appointments'>;
}

const useStyles = makeStyles((theme) => ({
  container: { flex: 1, flexDirection: 'row' },
  flexOne: { flex: 1 },
  hidden: { display: 'none' },
  subTitle: {
    marginTop: theme.getSpacing(4),
    fontSize: 20,
    marginBottom: theme.getSpacing(2),
  },
  cellContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },
}));

interface CellRendererProps {
  value: string;
  rowIndex: number;
}

interface TooltipProps {
  value: string;
  id: string;
}
