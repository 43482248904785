import {
  AmPmEnum,
  WebsiteRequired,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { Modal } from 'assets/components/modal';
import { RadioButtonGroupField } from 'assets/components/radio-button-group';
import { RadioButtonInputMode } from 'assets/components/radio-button-group/RadioButton';
import { Text } from 'assets/components/text';
import { Form } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { logError } from 'assets/logging/logger';
import { makeStyles } from 'assets/theme';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { View } from 'react-native';
import {
  fetchPatientWebsite,
  savePatientWebsite,
  setOpenedModal,
  setPatientWebsiteData,
} from '../../../actions/checklist-actions';
import { useChecklistStore } from '../../../store/checklist-store';
import { ChecklistModalProps } from '../types';
import { PatientWebsiteOptions } from '../utils';

interface PatientWebsite {
  website_required: WebsiteRequired | undefined;
}

export const PatientWebsiteModal: FunctionComponent<ChecklistModalProps> = ({
  id,
}) => {
  const { currentOpenedModalId, patientWebsiteData } = useChecklistStore();
  const [loading, setLoading] = useState<boolean>(false);
  const styles = useStyles();

  const methods = useForm<PatientWebsite>({
    defaultValues: {
      website_required:
        patientWebsiteData?.website_required !== 'Unknown'
          ? patientWebsiteData?.website_required
          : undefined,
    },
  });

  useEffect(() => {
    void (async () => {
      const data = await fetchPatientWebsite();
      if (data) {
        methods.setValue(
          'website_required',
          data.website_required !== 'Unknown'
            ? data.website_required
            : undefined,
        );
        setPatientWebsiteData(data);
      }
    })();
  }, []);

  const openNextModalId = (website_required?: WebsiteRequired) => {
    switch (website_required) {
      case 'build-to-replace':
      case 'build-do-not-have':
        setOpenedModal('patient-website-template');
        break;
      case 'do-not-build-already-have':
        setOpenedModal('website-access-setup');
        break;
      case 'do-not-build-do-not-need':
        setOpenedModal('patient-website-notification');
        break;
      default:
        break;
    }
  };

  const handleSubmit = async () => {
    if (!patientWebsiteData) {
      return;
    }

    try {
      setLoading(true);
      const website_required = methods.getValues('website_required');
      const dto = {
        ...patientWebsiteData,
        website_required,
      };
      await savePatientWebsite(dto);
      openNextModalId(website_required);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={getText('patient-website')}
      show={currentOpenedModalId === id}
      handleDismissModal={() => setOpenedModal('checklist-setup')}
      showDismissButton
      isScrollable
      size="sm"
      buttons={[
        {
          hierarchy: 'primary',
          text: getText('next'),
          onPress: methods.handleSubmit(handleSubmit),
          loading,
          logger: { id: 'close-patient-website-setup' },
        },
        {
          text: getText('cancel'),
          hierarchy: 'tertiary-gray',
          onPress: () => setOpenedModal('checklist-setup'),
          logger: { id: 'close-media-settings-modal' },
        },
      ]}
    >
      <Form methods={methods}>
        <Form.Row>
          <Form.Column>
            <Text style={styles.title}>
              {getText('answer-following-questions')}
            </Text>
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <RadioButtonGroupField
              name="website_required"
              mode={RadioButtonInputMode.FLAT}
              values={PatientWebsiteOptions}
              rules={{
                required: getText('field-required'),
              }}
            />
          </Form.Column>
        </Form.Row>
      </Form>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.getSpacing(3),
  },
  optionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.getSpacing(2),
  },
  title: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.gray[700],
  },
}));
