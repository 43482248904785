import { UpdateBookingDto } from '@digitalpharmacist/appointment-service-client-axios';
import { logError } from 'assets/logging/logger';
import { getText } from '../../../../../packages/assets/localization/localization';
import AppointmentService from '../../api/AppointmentService';
import PatientService from '../../api/PatientService';
import { useToast } from '../../common/hooks/useToast';
import { useAppStateStore } from '../../store/app-store';
import { useAppointmentsListState } from './appointments-list-store';
import { setRefreshDataTrue } from '../../layout/ScheduleDrawer';

const errorOccurred = (error: any, errorMessage?: string) => {
  const { toast } = useToast();
  const message = errorMessage
    ? errorMessage
    : getText('error-cancel-appointment');

  logError(error);
  useAppointmentsListState.setState({
    error: {
      message: message,
    },
    status: 'error',
  });

  toast('Error', { type: 'error', content: message });
};

export const cancelBooking = async (bookingId: string) => {
  try {
    const { locationId, pharmacyId } = useAppStateStore.getState();

    await AppointmentService.cancelBooking(pharmacyId, locationId, bookingId);
    setRefreshDataTrue();
  } catch (error) {
    errorOccurred(error);
  }
};

export const resetAppointmentDetails = async () => {
  useAppointmentsListState.setState({
    appointmentDetails: undefined,
    patientRecord: undefined,
  });
};

export const setAppointmentVisibility = async (visibility: boolean = true) => {
  useAppointmentsListState.setState({
    appointmentVisible: visibility,
  });
};

export const showAppointmentDetails = async (bookingId: string) => {
  useAppointmentsListState.setState({
    appointmentDetailsStatus: 'loading',
    appointmentDetails: undefined,
    appointmentType: undefined,
    patientRecord: undefined,
  });

  try {
    const { locationId, pharmacyId } = useAppStateStore.getState();

    const bookingResponse = await AppointmentService.getBooking(
      pharmacyId,
      locationId,
      bookingId,
    );

    const appointmentTypeResponse = AppointmentService.findAppointmentTypeById(
      pharmacyId,
      locationId,
      bookingResponse.appointment_type_id,
    );

    const patientRecordResponse = PatientService.getPatientRecord(
      bookingResponse.patient_record_id,
    );

    const { appointmentVisible } = useAppointmentsListState.getState();
    if (appointmentVisible) {
      useAppointmentsListState.setState({
        appointmentDetails: bookingResponse,
        appointmentType: await appointmentTypeResponse,
        patientRecord: await patientRecordResponse,
      });
    }
  } catch (error) {
    errorOccurred(error, getText('error-loading-appointment'));
  } finally {
    useAppointmentsListState.setState({
      appointmentDetailsStatus: 'idle',
    });
  }

  return null;
};

export const updateBookingNotes = async (booking: UpdateBookingDto) => {
  const bookingId = useAppointmentsListState.getState().appointmentDetails?.id;
  useAppointmentsListState.setState({ appointmentNotesStatus: 'loading' });

  if (!bookingId) {
    throw new Error('The edited appointment must have id set');
  }

  try {
    const { locationId, pharmacyId } = useAppStateStore.getState();

    const response = await AppointmentService.updateBooking(
      pharmacyId,
      locationId,
      bookingId,
      booking,
    );

    useAppointmentsListState.setState({
      appointmentNotesStatus: 'success',
      editNotes: false,
      appointmentDetails: response,
    });
  } catch (error) {
    errorOccurred(error, getText('appointment-notes-update-error'));
  }
};

export const updateBookingNotesEdit = (newState: boolean) => {
  useAppointmentsListState.setState({ editNotes: newState });
};
