import React, { FunctionComponent, PropsWithChildren } from 'react';
import { TouchableOpacity } from 'react-native';
import { makeStyles, useTheme } from 'assets/theme';
import { Text } from 'assets/components/text';
import { useRoute } from '@react-navigation/native';
import { RootStackNavigationProp } from '../../navigation/RootNavigation';

export const NavigationItems: FunctionComponent<
  PropsWithChildren<NavigationItemsProps>
> = ({ navigation, screens }) => {
  const theme = useTheme();
  const styles = useStyles();
  const route = useRoute();

  return (
    <>
      {screens.map((screen) => (
        <TouchableOpacity
          key={screen.name}
          style={[
            styles.navItemContainer,
            {
              opacity: 1,
              backgroundColor:
                route.name === screen.name
                  ? theme.palette.primary[600]
                  : 'transparent',
            },
            screen.navIcon ? styles.navContainerWithIcon : null,
          ]}
          onPress={() => navigation.navigate(screen.name as any)}
        >
          <Text
            style={
              route.name === screen.name ? styles.activeNavItem : styles.navItem
            }
          >
            {screen.label}
          </Text>
          {screen.navIcon ? screen.navIcon : null}
        </TouchableOpacity>
      ))}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  activeNavItem: {
    color: theme.palette.white,
    fontWeight: '700',
    fontSize: 16,
    lineHeight: 24,
  },
  navItem: {
    color: theme.palette.primary[100],
    fontWeight: '400',
    fontSize: 16,
    lineHeight: 24,
  },
  navItemContainer: {
    paddingLeft: theme.getSpacing(2),
    paddingRight: theme.getSpacing(2),
    height: 72,
    justifyContent: 'center',
  },
  navContainerWithIcon: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

export interface NavigationItemsProps {
  navigation: RootStackNavigationProp;
  screens: {
    name: string;
    label: string;
    navIcon?: JSX.Element | null;
  }[];
}
