import React, {
  FunctionComponent,
  useMemo,
  Fragment,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { View, TouchableOpacity } from 'react-native';
import { TextInput } from 'react-native-paper';
import { DocumentPickerAsset } from 'expo-document-picker';
import { getText } from 'assets/localization/localization';
import { Text } from 'assets/components/text';
import { makeStyles, useTheme } from 'assets/theme';
import { Modal } from 'assets/components/modal';
import { stripRichTextEditorElements } from 'assets/utils/messageUtils';
import { convertVariablesToPills } from '../Quill/utils';
import patientService from '../../../api/PatientService';
import { useAppStateStore } from '../../../store/app-store';
import { useNewChatModalState } from '../stores/new-chat-modal-store/new-chat-modal-store';
import {
  setTemplatesFilter,
  resetNewChatModalState,
} from '../stores/new-chat-modal-store/new-chat-modal-actions';
import { ErrorsContainer } from './ErrorsContainer';
import { useErrorState } from '../stores/error-store/error-store';
import { BIG_MESSAGE_LIMIT, EXCLUDED_ERRORS_MODAL } from '../data';
import { LocationPatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';
import { defaultTemplates } from '../common/defaultTemplates';
import { ITemplate } from 'assets/types/messageTypes';
import { useMessagingStyles } from '../common/styles';
import ReactQuill from 'react-quill';
import '../styles/messaging-text-editor-style.css';
import { MessageFields } from '../common/types/MessageFields';
import { NewMessageBody, ToolBarOptions } from './NewMessageBody';
import { useForm, useWatch } from 'react-hook-form';
import { MessageForm } from '../common/types/MessageForm';
import VerificationAlert from './VerificationAlert';
import { Form } from 'assets/layout';
import { formatDate } from '../../../common/datetime-utils';
import { PatientLookupUserSelectField } from '../../../components/patient-user-select/patient-lookup-user-select-field';
import { DateTimePickerField } from '../../../components/DateTimePickerField';
import { DropdownSelect } from 'assets/components/dropdown-select';

export const NewChatModal: FunctionComponent<NewChatModalProps> = ({
  title = getText('new-chat-message'),
  show,
  recipient,
  isDisabledRecipient,
  defaultSubject = '',
  defaultMessage = '',
  isConversationCreating,
  onCancel,
  onConversationCreate,
  verifyPatient,
  useDateTimeInputs,
  hasDateAndTimeToSet,
  hasPreSelectedItems,
}) => {
  const locationId = useAppStateStore((state) => state.locationId);
  const templatesFilter = useNewChatModalState(
    (state) => state.templatesFilter,
  );
  const { errorObject } = useErrorState();
  // We can have multiple NewChatModal in one parent component,
  // thus the errorFields should be in the component (not in independent zustand store)
  // to be isolated from another NewChatModal component
  const [errorFields, setErrorFields] = useState<Record<string, string>>({});
  const styles = useStyles();
  const messagingStyles = useMessagingStyles();
  const theme = useTheme();
  const [quillRef, setQuillRef] = useState<ReactQuill | null>(null);
  const [preSelectedContent, setPreSelectedContent] = useState<string>();

  const methods = useForm<MessageForm>({
    defaultValues: {
      template: null,
      patient: recipient || undefined,
      subject: defaultSubject,
      content: defaultMessage,
      attachments: [],
    },
  });

  useEffect(() => {
    if (recipient) {
      methods.setValue('patient', recipient);
    }
  }, [recipient, methods, show]);

  const patient = useWatch({
    control: methods.control,
    name: 'patient',
  });

  const subject = useWatch({
    control: methods.control,
    name: 'subject',
  });

  const content = useWatch({
    control: methods.control,
    name: 'content',
  });

  const attachments = useWatch({
    control: methods.control,
    name: 'attachments',
  });

  const pureContent = useMemo(() => {
    return stripRichTextEditorElements(content);
  }, [content]);

  const resetState = useCallback(() => {
    resetNewChatModalState();
    setErrorFields({});
  }, [resetNewChatModalState, setErrorFields]);

  useEffect(() => {
    if (!show) {
      resetState();
    }
  }, [show, resetState]);

  useEffect(() => {
    if (preSelectedContent) {
      methods.setValue('content', preSelectedContent);
    }
  }, [preSelectedContent]);

  useEffect(() => {
    if (hasPreSelectedItems && quillRef) {
      const content = convertMessageContent(defaultMessage, quillRef);
      if (content) {
        setPreSelectedContent(content);
      }
    }
  }, [quillRef]);

  useEffect(() => {
    if (MessageFields.patient in errorFields && patient) {
      const newErrorState = { ...errorFields };
      delete newErrorState[MessageFields.patient];
      setErrorFields(newErrorState);
    }
  }, [patient, errorFields[MessageFields.patient], setErrorFields]);

  useEffect(() => {
    if (MessageFields.subject in errorFields && subject) {
      const newErrorState = { ...errorFields };
      delete newErrorState[MessageFields.subject];
      setErrorFields(newErrorState);
    }
  }, [subject, errorFields[MessageFields.subject], setErrorFields]);

  useEffect(() => {
    if (
      MessageFields.message in errorFields &&
      (pureContent || attachments.length > 0)
    ) {
      const newErrorState = { ...errorFields };
      delete newErrorState[MessageFields.message];
      setErrorFields(newErrorState);
    }
  }, [
    attachments,
    pureContent,
    errorFields[MessageFields.message],
    setErrorFields,
  ]);

  const filteredTemplates = useMemo(() => {
    const sortedTemplates = defaultTemplates.sort(
      (currentTemplate, nextTemplate) =>
        currentTemplate.name.localeCompare(nextTemplate.name),
    );
    if (!templatesFilter) {
      return sortedTemplates;
    }

    const regExp = new RegExp(templatesFilter, 'i');
    return sortedTemplates.filter((template) => regExp.test(template.name));
  }, [templatesFilter]);

  const onFilter = (text: string) => {
    setTemplatesFilter(text);
  };

  const validateFields = (data: any) => {
    const invalidFields: Record<string, string> = {};
    const strippedMessage = stripRichTextEditorElements(
      data[MessageFields.message],
    );

    if (!data[MessageFields.patient]) {
      invalidFields[MessageFields.patient] = `${getText(
        'patient-is-required',
      )}.`;
    }
    if (!data[MessageFields.subject]) {
      invalidFields[MessageFields.subject] = `${getText(
        'subject-is-required',
      )}.`;
    }
    if (!strippedMessage && !data[MessageFields.attachments].length) {
      invalidFields[MessageFields.message] = `${getText(
        'message-or-attachment-is-required',
      )}.`;
    }
    if (strippedMessage && strippedMessage.length > BIG_MESSAGE_LIMIT) {
      invalidFields[MessageFields.message] = `${getText(
        'message-characters-limit-five-thousand',
      )}.`;
    }

    setErrorFields(invalidFields);
    return Object.keys(invalidFields).length;
  };

  const onChatCreate = async () => {
    const data = {
      patient: patient.id,
      subject: subject,
      message: pureContent,
      attachments: attachments,
    };
    const errorsCount = validateFields(data);
    if (errorsCount === 0 && data.patient) {
      await onConversationCreate(data, onChatComplete);
    }
  };

  const onChatComplete = () => {
    methods.reset();
  };

  const asyncOptions = async (
    search: string,
  ): Promise<LocationPatientRecordDto[]> => {
    const data = await patientService.findAllLocationsRecordsById(locationId, {
      search: search,
      limit: 10,
      offset: 0,
    });

    return data.items;
  };

  const onSelectTemplate = (template: ITemplate) => {
    if (!quillRef) return;
    const content = convertMessageContent(template.body, quillRef);
    hasPreSelectedItems = false;

    if (content) {
      methods.setValue('subject', template.subject);
      methods.setValue('content', content);
    }
  };

  function convertMessageContent(
    text: string,
    quillRef: ReactQuill,
  ): string | undefined {
    quillRef.getEditor().deleteText(0, quillRef.getEditor().getLength());
    quillRef.getEditor().insertText(0, text);

    return convertVariablesToPills(text, quillRef);
  }

  const handleQuillRef = (quillRef: ReactQuill) => {
    setQuillRef(quillRef);
  };

  const getMessageTip = () => {
    if (MessageFields.message in errorFields) {
      return (
        <Text style={styles.errorMessage}>
          {errorFields[MessageFields.message]}
        </Text>
      );
    } else if (pureContent.length) {
      return (
        <Text style={styles.charactersCounter}>
          {getText('character-count', {
            max: BIG_MESSAGE_LIMIT,
            count: pureContent.length,
          })}
        </Text>
      );
    } else {
      return <></>;
    }
  };

  return (
    <Modal
      size="lg"
      title={title}
      show={show}
      cancelButtonProps={{
        onPress: () => {
          methods.reset();
          setPreSelectedContent(undefined);
          onCancel();
        },
        text: getText('cancel'),
        logger: { id: 'cancel-button-modal-new-chat' },
        hierarchy: 'tertiary-gray',
      }}
      okButtonProps={{
        onPress: onChatCreate,
        logger: { id: 'ok-button-modal-new-chat' },
        text: getText('send'),
        hierarchy: 'primary',
        disabled: isConversationCreating,
      }}
      isScrollable
    >
      {patient && verifyPatient ? (
        <VerificationAlert
          selectedPatient={patient}
          verifyPatient={verifyPatient}
        />
      ) : (
        <></>
      )}
      <View
        style={{
          flexDirection: 'row',
          width: '100%',
        }}
      >
        <View
          style={{
            width: 225,
            marginRight: theme.getSpacing(2),
          }}
        >
          <TextInput
            placeholder={getText('find-template')}
            autoComplete="off"
            autoCapitalize="none"
            style={messagingStyles.findTemplate}
            underlineColor="transparent"
            outlineColor={theme.palette.white}
            onChangeText={onFilter}
            value={templatesFilter}
          />
          <View style={messagingStyles.templatesWrapper}>
            {filteredTemplates.length ? (
              filteredTemplates.map((template) => (
                <Fragment key={template.id}>
                  <TouchableOpacity onPress={() => onSelectTemplate(template)}>
                    <Text style={messagingStyles.template}>
                      {template.name}
                    </Text>
                  </TouchableOpacity>
                </Fragment>
              ))
            ) : (
              <Text style={messagingStyles.template}>
                {getText('no-results-found')}
              </Text>
            )}
          </View>
        </View>
        <View style={{ flex: 1 }}>
          <ErrorsContainer
            errorObject={errorObject}
            excludedErrors={EXCLUDED_ERRORS_MODAL}
          />
          <Form methods={methods}>
            {!useDateTimeInputs && (
              <View style={[styles.typeahead]}>
                <PatientLookupUserSelectField<LocationPatientRecordDto>
                  name="patient"
                  asyncOptions={asyncOptions}
                  noOptionsMessage={() => null}
                  isClearable={true}
                  label={getText('to')}
                  isMulti={false}
                  isDisabled={isDisabledRecipient}
                  defaultValue={recipient}
                  getOptionValue={(option: LocationPatientRecordDto) => {
                    return `${option.first_name} ${option.last_name} ${
                      option.id
                    } ${option.patient_record_id} (${formatDate(
                      option.date_of_birth,
                    )})`;
                  }}
                  onChange={(value) => {
                    methods.setValue(
                      'patient',
                      value as LocationPatientRecordDto, // ignores null value, not great
                    );
                  }}
                />
                {MessageFields.patient in errorFields && (
                  <Text style={styles.errorMessage}>
                    {errorFields[MessageFields.patient]}
                  </Text>
                )}
              </View>
            )}
          </Form>
          <View style={{ marginBottom: theme.getSpacing(2) }}>
            <TextInput
              label={getText('subject')}
              autoComplete="off"
              autoCapitalize="none"
              style={styles.input}
              underlineColor="transparent"
              value={subject}
              onChangeText={(subject) => {
                methods.setValue('subject', subject);
              }}
              disabled={useDateTimeInputs}
            />
            {MessageFields.subject in errorFields && (
              <Text style={styles.errorMessage}>
                {errorFields[MessageFields.subject]}
              </Text>
            )}
          </View>
          <View style={{ marginBottom: theme.getSpacing(1) }}>
            <NewMessageBody
              methods={methods}
              setErrorFields={setErrorFields}
              errorFields={errorFields}
              handleQuillRef={handleQuillRef}
              toolbarId="toolbar-main"
              content="content"
              height={108}
              placeholder={getText('message')}
              toolbarOptions={[
                ToolBarOptions.Variables,
                ToolBarOptions.Attachments,
                ToolBarOptions.Emojis,
                ToolBarOptions.Links,
              ]}
              disabled={useDateTimeInputs}
            />
            {getMessageTip()}
          </View>
          {!useDateTimeInputs && (
            <Text style={styles.note}>
              {getText('note-inbox-messages-should-be-clinical-in-nature')}
            </Text>
          )}
          {useDateTimeInputs && (
            <Form methods={methods}>
              <View style={{ marginTop: theme.getSpacing(2) }}>
                <Form.Row>
                  <Text
                    style={{
                      ...theme.lumistryFonts.text.medium.semiBold,
                    }}
                  >
                    {getText('when-to-send-every-year')}
                  </Text>
                </Form.Row>
                {hasDateAndTimeToSet ? (
                  <Form.Row>
                    <Form.Column>
                      <DropdownSelect
                        placeholder="Month"
                        labelInlined={true}
                        disabled={true}
                        options={[]}
                        testID={'month-dropdown-select-option'}
                      />
                    </Form.Column>
                    <Form.Column>
                      <DropdownSelect
                        placeholder="Day"
                        labelInlined={true}
                        disabled={true}
                        options={[]}
                        testID={'day-dropdown-select-option'}
                      />
                    </Form.Column>
                    <Form.Column>
                      <DateTimePickerField
                        name={'time'}
                        type="time"
                        disabled={true}
                      />
                    </Form.Column>
                  </Form.Row>
                ) : (
                  <Form.Row style={{ width: '40%' }}>
                    <Form.Column>
                      <DateTimePickerField
                        name={'time'}
                        type="time"
                        disabled={true}
                      />
                    </Form.Column>
                  </Form.Row>
                )}
              </View>
            </Form>
          )}
        </View>
      </View>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  input: {
    height: theme.getSpacing(4) + theme.getSpacing(3),
    backgroundColor: theme.palette.white,
    borderColor: theme.palette.gray[300],
    borderWidth: 1,
    borderRadius: 6,
    fontSize: 16,
  },
  message: {
    height: 196,
  },
  note: {
    ...theme.fonts.regular,
    fontSize: 12,
    color: theme.palette.gray[500],
    marginTop: theme.getSpacing(2),
  },
  emojiWrapper: {
    position: 'absolute',
    bottom: 32,
    zIndex: 10,
    backgroundColor: theme.palette.black,
    padding: theme.getSpacing(1),
    borderRadius: 16,
  },
  errorMessage: {
    color: theme.palette.error[600],
    fontSize: 12,
  },
  charactersCounter: {
    alignSelf: 'flex-start',
    marginTop: theme.getSpacing(1),
    fontSize: 12,
  },
  typeahead: {
    textAlign: 'left',
    marginBottom: theme.getSpacing(2),
    fontSize: 16,
  },
}));

export interface CreateConversationData {
  subject: string;
  message: string;
  patient: string;
  attachments?: DocumentPickerAsset[];
}

interface NewChatModalProps {
  title?: string;
  show: boolean;
  isDisabledRecipient?: boolean;
  defaultSubject?: string;
  defaultMessage?: string;
  recipient?: LocationPatientRecordDto | null | undefined;
  isConversationCreating: boolean;
  onCancel: () => void;
  onConversationCreate: (
    data: CreateConversationData,
    onComplete: () => void,
  ) => Promise<void>;
  verifyPatient?: () => Promise<void>;
  useDateTimeInputs?: boolean;
  hasDateAndTimeToSet?: boolean;
  hasPreSelectedItems?: boolean;
}
