import { FC, ReactNode, useState } from 'react';
import { makeStyles } from 'assets/theme';
import { Pressable, Text, View } from 'react-native';
import { getText } from 'assets/localization/localization';
import InsuranceDetailsModal, {
  Card,
  FilenameProperty,
} from './InsuranceDetailsModal';
import { LocationPatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';
import { Tooltip } from 'assets/components/tooltip/components/tooltip';
import { PharmacyTooltipWrapper } from '../../common/PharmacyTooltipWrapper';

export const InfoRowEnd = ({
  label,
  value,
}: {
  label: string | undefined;
  value: string | ReactNode;
}) => {
  const styles = useStyles();

  return (
    <View style={[styles.infoRowEnd]}>
      <Text style={styles.labelText} numberOfLines={1}>
        <PharmacyTooltipWrapper tooltipId={label}>
          {label}
        </PharmacyTooltipWrapper>
      </Text>
      <Text style={styles.valueText}>{value}</Text>
      <Tooltip
        style={{ display: 'inline-grid' }}
        text={label!}
        id={label}
        place={'top'}
      />
    </View>
  );
};

const InsuranceDetailsSection: FC<InsuranceDetailsSectionProps> = ({
  locationPatientRecord,
  insuranceCardUrlsObject,
  hasPrimary,
  hasSecondary,
}) => {
  const styles = useStyles();
  const insuranceTypes = [Card.Primary, Card.Secondary];
  const [selectedInsuranceCardType, setSelectedInsuranceCardType] =
    useState<Card>();
  const [showInsuranceModal, setShowInsuranceModal] = useState(false);

  function toggleShowInsuranceModal(insuranceType: Card): void {
    setSelectedInsuranceCardType(insuranceType);
    setShowInsuranceModal((prev) => !prev);
  }

  return (
    <>
      {insuranceTypes.map((insurance, index) => (
        <InfoRowEnd
          key={index}
          label={
            insurance === 'insurance_primary'
              ? getText('primary')
              : getText('secondary')
          }
          value={
            <Pressable
              disabled={
                (insurance === 'insurance_primary' && !hasPrimary) ||
                (insurance === 'insurance_secondary' && !hasSecondary)
              }
              onPress={() => toggleShowInsuranceModal(insurance)}
            >
              <Text
                style={[
                  (insurance === 'insurance_primary' && !hasPrimary) ||
                  (insurance === 'insurance_secondary' && !hasSecondary)
                    ? styles.noInsuranceCard
                    : styles.details,
                ]}
              >
                {(insurance === 'insurance_primary' && !hasPrimary) ||
                (insurance === 'insurance_secondary' && !hasSecondary)
                  ? getText('no-insurance-card')
                  : getText('details')}
              </Text>
            </Pressable>
          }
        />
      ))}

      {(locationPatientRecord || insuranceCardUrlsObject) &&
        selectedInsuranceCardType && (
          <InsuranceDetailsModal
            show={showInsuranceModal}
            setShowModal={setShowInsuranceModal}
            locationPatientRecord={locationPatientRecord}
            insuranceCardUrlsObject={insuranceCardUrlsObject}
            cardType={selectedInsuranceCardType}
          ></InsuranceDetailsModal>
        )}
    </>
  );
};

interface InsuranceDetailsSectionProps {
  locationPatientRecord?: LocationPatientRecordDto;
  insuranceCardUrlsObject?:
    | undefined
    | Record<FilenameProperty, string | null | undefined>;
  hasPrimary: boolean;
  hasSecondary: boolean;
}

const useStyles = makeStyles((theme) => ({
  row: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  noInsuranceCard: {
    marginRight: theme.getSpacing(1),
  },
  details: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.primary[600],
  },
  labelText: {
    ...theme.lumistryFonts.text.small.semiBold,
    color: theme.palette.gray[900],
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: 220,
  },
  infoRowEnd: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: theme.getSpacing(1),
  },
  valueText: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.gray[700],
    textAlign: 'right',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));

export default InsuranceDetailsSection;
