import {
  ChecklistOptionDto,
  PharmacistLocationDto,
  RequiredOptional,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { Modal } from 'assets/components/modal';
import { getText, translations } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import {
  fetchChecklist,
  setOpenedModal,
} from '../../../actions/checklist-actions';
import { useToast } from '../../../common/hooks/useToast';
import { useAppStateStore } from '../../../store/app-store';
import {
  ChecklistModalIds,
  useChecklistStore,
} from '../../../store/checklist-store';
import { ChecklistModalProps } from '../types';
import { ChecklistSetupGroupItem } from './ChecklistSetupGroupItem';

export const ChecklistSetupModal: FunctionComponent<ChecklistModalProps> = ({
  id,
}) => {
  const styles = useStyles();
  const [location, setLocation] = useState<PharmacistLocationDto>();
  const { currentOpenedModalId, checklistOptions, status } =
    useChecklistStore();
  const { toast } = useToast();
  const { pharmacyName } = useAppStateStore();
  const { locationId, userMostRecentLocations } = useAppStateStore();

  const sortedChecklist = useMemo(
    () => checklistOptions.slice().sort((a, b) => a.order - b.order),
    [checklistOptions],
  );

  const [requiredChecklist, optionalChecklist] = useMemo(
    () => [
      sortedChecklist.filter((x) => x.required === RequiredOptional.Required),
      sortedChecklist.filter((x) => x.required === RequiredOptional.Optional),
    ],
    [sortedChecklist],
  );

  const [
    lastRequiredCompletedIndex,
    lastOptionalCompletedIndex,
    areRequiredItemsComplete,
    firstIncompletedOptionalIndex,
  ] = useMemo(
    () => [
      sortedChecklist
        .filter((x) => x.required === RequiredOptional.Required)
        .findLastIndex((x) => x.completed),
      sortedChecklist
        .filter((x) => x.required === RequiredOptional.Optional)
        .findLastIndex((x) => x.completed),
      sortedChecklist.findIndex(
        (x) => x.required === RequiredOptional.Required && !x.completed,
      ) < 0,
      sortedChecklist
        .filter((x) => x.required === RequiredOptional.Optional)
        .findIndex((x) => !x.completed),
    ],
    [sortedChecklist],
  );

  const showToast = (messageKey: translations) => {
    toast(getText(messageKey), { type: 'warning', autoClose: 5000 });
  };

  const handleStep = (
    index: number,
    lastIndex: number,
    ch: ChecklistOptionDto,
  ) => {
    if (ch.completed) {
      showToast('checklist-item-completed');
    } else if (
      ch.required === RequiredOptional.Required &&
      index > lastIndex + 1
    ) {
      showToast('complete-checklist-items-in-order');
    } else if (
      !areRequiredItemsComplete &&
      ch.required === RequiredOptional.Optional
    ) {
      showToast('complete-checklist-items-in-order');
    } else {
      setOpenedModal(ch.slug as ChecklistModalIds);
    }
  };

  const handleCloseModal = () => setOpenedModal(undefined);

  useEffect(() => {
    if (currentOpenedModalId === id) fetchChecklist();
  }, [currentOpenedModalId]);

  useEffect(() => {
    const selectedLocation = userMostRecentLocations.find(
      (x) => x.location_id === locationId,
    );
    setLocation(selectedLocation);
  }, [locationId, userMostRecentLocations]);

  return (
    <Modal
      title={getText('setup-checklist')}
      show={currentOpenedModalId === id}
      handleDismissModal={handleCloseModal}
      showDismissButton
      size="sm"
      buttons={[
        {
          text: getText('close'),
          onPress: handleCloseModal,
          hierarchy: 'primary',
          logger: { id: 'close-checklist-modal' },
        },
      ]}
      isScrollable
    >
      {status === 'loading' ? (
        <LoadingIndicator />
      ) : (
        <View style={styles.container}>
          <ChecklistSetupGroupItem
            title={`${getText('configure')} ${pharmacyName}`}
            subtitle={getText('first-header-desc')}
            items={requiredChecklist}
            lastCompletedIndex={lastRequiredCompletedIndex}
            areRequiredItemsComplete={areRequiredItemsComplete}
            firstIncompletedOptionalIndex={firstIncompletedOptionalIndex}
            handleStep={handleStep}
          />
          <ChecklistSetupGroupItem
            title={`${getText(
              'configure',
            )} ${location?.location_full_display_name}`}
            subtitle={getText('second-header-desc')}
            items={optionalChecklist}
            lastCompletedIndex={lastOptionalCompletedIndex}
            areRequiredItemsComplete={areRequiredItemsComplete}
            firstIncompletedOptionalIndex={firstIncompletedOptionalIndex}
            handleStep={handleStep}
          />
        </View>
      )}
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.getSpacing(3),
  },
}));
