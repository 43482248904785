import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { Icon } from 'assets/components/icon';
import theme, { makeStyles, useTheme } from 'assets/theme';
import { StyleProp, View, ViewStyle } from 'react-native';
import { useTasksDataTableState } from './tasks-data-table-store';
import {
  PencilIcon,
  PlayAltIcon,
  PauseAltIcon,
  CheckCircleIcon,
  BookmarkIcon,
  AlertCircleIcon,
  CheckIcon,
  BookmarkIconFilledIn,
  CheckCircleIconFilledIn,
  TrashWithStripesIcon,
} from 'assets/icons';
import { Text } from 'assets/components/text';
import 'react-contexify/dist/ReactContexify.css';
import { Menu, Item, Separator, useContextMenu } from 'react-contexify';
import { TaskStatus } from '@digitalpharmacist/tasks-service-client-axios';
import { editTask, updateTask } from './tasks-data-table-actions';
import { PharmacyConfirmationModal } from '../../components/PharmacyConfirmationModal';
import { deleteTask } from '../task-modal/task-modal-actions';
import { getText } from 'assets/localization/localization';
import { useIsFocused } from '@react-navigation/native';

const determineColor = (color?: string) => {
  return color ? color : theme.palette.gray[900];
};

export const MenuOptionItem = (props: {
  icon: FunctionComponent;
  label: string;
  color?: string;
  selected?: boolean;
  iconSize?: number;
  containerStyle?: StyleProp<ViewStyle>;
  selectedIconSize?: number;
  iconColorSelected?: string;
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const iconColorSelected = props.iconColorSelected
    ? props.iconColorSelected
    : theme.palette.primary[500];

  return (
    <View style={[styles.menuOptionContainer, props.containerStyle]}>
      <Icon
        icon={props.icon}
        color={props.selected ? iconColorSelected : determineColor(props.color)}
        size={props.iconSize}
      ></Icon>
      <Text
        style={[
          styles.menuOptionLabel,
          {
            color: props.selected
              ? theme.palette.primary[500]
              : theme.palette.gray[900],
          },
        ]}
        selectable
      >
        {props.label}
      </Text>
      {props.selected && (
        <View
          style={{
            alignSelf: 'flex-end',
          }}
        >
          <Icon
            icon={CheckIcon}
            color={theme.palette.primary[500]}
            size={props.selectedIconSize}
          ></Icon>
        </View>
      )}
    </View>
  );
};

export const TaskContextMenu: FunctionComponent<
  PropsWithChildren<TaskContextMenuProps>
> = ({ menuId }) => {
  const theme = useTheme();
  const { hideAll } = useContextMenu();
  const { contextMenuTaskDetails } = useTasksDataTableState();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showResolveConfirmation, setShowResolveConfirmation] = useState(false);

  const isDisabled =
    contextMenuTaskDetails?.status === TaskStatus.Resolved ||
    !!contextMenuTaskDetails?.deleted_at;

  const isFocused = useIsFocused();

  // Used to hide menu when menu is left open before switching between TasksDataTable and TasksGrid.
  useEffect(() => {
    if (isFocused) {
      hideAll();
    }
  }, [isFocused]);

  return (
    <>
      <Menu id={menuId}>
        {contextMenuTaskDetails && (
          <>
            <Item
              disabled={isDisabled}
              onClick={() => {
                editTask(contextMenuTaskDetails.id);
              }}
            >
              <MenuOptionItem icon={PencilIcon} label={getText('edit')} />
            </Item>
            <Item
              disabled={isDisabled}
              onClick={() => {
                updateTask(contextMenuTaskDetails.id, {
                  flagged: !contextMenuTaskDetails.flagged,
                });
              }}
            >
              <MenuOptionItem
                color={
                  contextMenuTaskDetails.flagged
                    ? theme.palette.error['700']
                    : undefined
                }
                icon={
                  contextMenuTaskDetails.flagged
                    ? BookmarkIconFilledIn
                    : BookmarkIcon
                }
                label={
                  contextMenuTaskDetails.flagged
                    ? getText('mark-as-unflagged')
                    : getText('mark-as-flagged')
                }
              />
            </Item>
            <Separator />
            <Item
              disabled={isDisabled}
              onClick={() => {
                updateTask(contextMenuTaskDetails.id, {
                  status: TaskStatus.Unresolved,
                });
              }}
            >
              <MenuOptionItem
                selected={
                  contextMenuTaskDetails.status == TaskStatus.Unresolved
                }
                icon={AlertCircleIcon}
                label={getText('unresolved')}
              />
            </Item>
            <Item
              disabled={isDisabled}
              closeOnClick={false}
              onClick={() => setShowResolveConfirmation(true)}
            >
              <MenuOptionItem
                selected={contextMenuTaskDetails.status == TaskStatus.Resolved}
                icon={
                  contextMenuTaskDetails.status == TaskStatus.Resolved
                    ? CheckCircleIconFilledIn
                    : CheckCircleIcon
                }
                label={getText('resolved')}
              />
            </Item>
            <Item
              disabled={isDisabled}
              onClick={() => {
                updateTask(contextMenuTaskDetails.id, {
                  status: TaskStatus.InProgress,
                });
              }}
            >
              <MenuOptionItem
                selected={
                  contextMenuTaskDetails.status == TaskStatus.InProgress
                }
                icon={PlayAltIcon}
                label={getText('in_progress')}
              />
            </Item>
            <Item
              disabled={isDisabled}
              onClick={() => {
                updateTask(contextMenuTaskDetails.id, {
                  status: TaskStatus.OnHold,
                });
              }}
            >
              <MenuOptionItem
                selected={contextMenuTaskDetails.status == TaskStatus.OnHold}
                icon={PauseAltIcon}
                label={getText('on_hold')}
              />
            </Item>
            <Separator />
            <Item
              disabled={isDisabled}
              closeOnClick={false}
              onClick={() => {
                setShowDeleteConfirmation(true);
              }}
            >
              <MenuOptionItem
                icon={TrashWithStripesIcon}
                label={getText('delete')}
              />
            </Item>
          </>
        )}
      </Menu>
      {contextMenuTaskDetails && (
        <>
          <PharmacyConfirmationModal
            show={showDeleteConfirmation}
            onAccepted={() => {
              setShowDeleteConfirmation(false);
              hideAll();
              deleteTask(contextMenuTaskDetails.id);
            }}
            onDismiss={() => setShowDeleteConfirmation(false)}
            message={`${getText('are-you-sure-to-delete-task')}: "${
              contextMenuTaskDetails.summary
            }"?`}
          />
          <PharmacyConfirmationModal
            show={showResolveConfirmation}
            onAccepted={() => {
              setShowResolveConfirmation(false);
              hideAll();
              updateTask(contextMenuTaskDetails.id, {
                status: TaskStatus.Resolved,
              });
            }}
            onDismiss={() => {
              setShowResolveConfirmation(false);
            }}
            message={`${getText('are-you-sure-to-resolve-task')}: "${
              contextMenuTaskDetails.summary
            }"?`}
          />
        </>
      )}
    </>
  );
};

interface TaskContextMenuProps {
  menuId: string;
}

const useStyles = makeStyles((theme) => ({
  menuOptionContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    paddingHorizontal: theme.getSpacing(1),
    alignItems: 'center',
  },
  menuOptionLabel: {
    color: theme.palette.gray[900],
    marginLeft: theme.getSpacing(1),
    flexGrow: 1,
  },
}));

export default TaskContextMenu;
