import {
  ColDef,
  ColGroupDef,
  GridApi,
  ICellRendererParams,
} from '@ag-grid-community/core';
import { RefillStatus } from '@digitalpharmacist/pms-integration-service-client-axios';
import { RefillSubmittedDto } from '@digitalpharmacist/prescription-service-client-axios';

import { Avatar } from 'assets/components/avatar';
import { DataGrid } from 'assets/components/data-grid';
import { Icon } from 'assets/components/icon';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { Text } from 'assets/components/text';
import { AlertCircleIcon, AlertTriangleIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';

import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { useProSidebar } from 'react-pro-sidebar';

import { getRxStatusText, getWorkflowStatusText } from '../../../utils';
import {
  default as PrescriptionService,
  default as prescriptionService,
} from '../../api/PrescriptionService';
import { AG_GRID_PAGINATION_PAGE_SIZE } from '../../common/constants';
import { formatDate, formatTimeDate } from '../../common/datetime-utils';
import { RefillsScreenProps } from '../../navigation/RootNavigation';
import { useAppStateStore } from '../../store/app-store';
import PatientDetailSidebar from '../patients/PatientDetailSidebar';
import usePatientStore from '../patients/patient-store';
import {
  LocationPatientRecordDtoWithFullName,
  SidebarDetails as SidebarPatientDetails,
} from '../patients/patient-types';
import RefillDetailSidebar from './RefillDetailSidebar';
import { ContextMenuRenderer } from './components/ContextMenuRenderer';
import { RefillsStatusFilter } from './components/RefillsStatusFilter';
import { RefillsContextMenu } from './components/RefillsContextMenu';
import { RefillSidebarDetails } from './refills-types';
import useRefillsStore from './refills-store';
import { PharmacyScreenContainer } from 'assets/layout';
import { BulletPointStyle } from '../../components/EmptyState/BulletPoints/BulletPoint';
import NoRefillsImage from './NoRefillsImage';
import EmptyState from '../../components/EmptyState/EmptyState';

export const REFILLS_CONTEXT_MENU_ID = 'refills-context-menu';

export const Refills: React.FC<RefillsScreenProps> = ({
  route,
  navigation,
}) => {
  const [searchValue, setSearchValue] = useState<string | undefined>();
  const styles = useStyles();
  const theme = useTheme();
  const { locationId } = useAppStateStore();
  const { sidebarDetails, setSidebarDetails } = useRefillsStore();
  const {
    sidebarDetails: patientSidebarDetails,
    setSidebarDetails: setPatientSidebarDetails,
    removeSidebarDetails: removePatientSidebarDetails,
  } = usePatientStore();
  const [refillsCount, setRefillsCount] = useState<number>();
  const [gridApi, setGridApi] = useState<GridApi | undefined>();
  const { collapseSidebar } = useProSidebar();
  const [isPassedFilter, setIsPassedFilter] = useState<RefillStatus | ''>(
    RefillStatus.Rejected,
  );
  const [columnDefs] = useState<Array<ColDef | ColGroupDef>>([
    {
      field: 'first_name',
      headerName: getText('patient'),
      cellRenderer: FullNameRenderer,
    },
    {
      field: 'patient_date_of_birth',
      headerName: getText('date-of-birth-short'),
      cellRenderer: DateOfBirthRenderer,
      cellStyle: { textTransform: 'capitalize' },
    },
    {
      field: 'rx_number',
      headerName: getText('rx-number'),
    },
    {
      field: 'pickup_method_name',
      headerName: getText('method'),
      cellStyle: { textTransform: 'capitalize' },
    },
    {
      field: 'refill_status',
      headerName: getText('status'),
      cellRenderer: StatusFieldRenderer,
    },
    {
      field: 'submitted_at',
      headerName: getText('date-submitted'),
      headerClass: 'date-submitted-header',
      sort: 'desc',
      cellRenderer: DateFieldRenderer,
      cellStyle: {
        display: 'flex',
        justifyContent: 'flex-end',
      },
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      filter: false,
      resizable: false,
      width: 40,
      minWidth: 40,
      maxWidth: 40,
      cellStyle: {
        margin: 0,
        padding: 0,
        paddingTop: theme.getSpacing(1),
        paddingBottom: theme.getSpacing(1),
      },
      cellRenderer: ContextMenuRenderer,
      cellRendererParams: {
        contextMenuId: REFILLS_CONTEXT_MENU_ID,
      },
    },
  ]);

  const fillRefillSidebarDetails = (
    selectedRefillSubmission: RefillSubmittedDto,
  ) => {
    setPatientSidebarDetails(undefined as unknown as SidebarPatientDetails);

    void prescriptionService
      .fillPatientDetailsSidebar(locationId, selectedRefillSubmission)
      .then((response) => {
        setSidebarDetails(response);
      });
  };

  const fillPatientSideBarDetails = async () => {
    const locationPatientRecord = sidebarDetails?.patientDetails;

    if (!locationPatientRecord) return;

    const prescriptions = await prescriptionService.findPrescriptions(
      locationId,
      locationPatientRecord.id,
    );

    const locationPatientRecordWithFullName: LocationPatientRecordDtoWithFullName =
      {
        ...locationPatientRecord,
        full_name: `${locationPatientRecord.first_name} ${locationPatientRecord.last_name}`,
      };

    setPatientSidebarDetails({
      patientNumberInList: -1,
      locationPatientRecord: locationPatientRecordWithFullName,
      patientPrescriptions: prescriptions,
    });
  };

  const handlePatientDetailsPress = () => {
    void fillPatientSideBarDetails();
  };

  const handlePatientDetailsCollapse = () => {
    removePatientSidebarDetails();
  };

  const handleSidebarCollapse = () => {
    // remove selected
    gridApi?.deselectAll();
    // remove query params
    navigation.setParams({ refillItemId: undefined });
    handlePatientDetailsCollapse();
    setSidebarDetails(undefined as unknown as RefillSidebarDetails);
  };

  useEffect(() => {
    PrescriptionService.getRefillsCount(locationId).then(setRefillsCount);
  }, [locationId]);

  useEffect(() => {
    gridApi?.refreshServerSideStore();
  }, [isPassedFilter]);

  const showRefillSideBar = (_gridApi: GridApi) => {
    if (!route.params?.refillItemId) return;

    const node = _gridApi.getRowNode(route.params.refillItemId);
    if (!node) return;
    node.setSelected(true);
    collapseSidebar(false);
    fillRefillSidebarDetails(node.data);
  };

  const memoizedServerSideDatasource = useMemo(() => {
    return PrescriptionService.getRefillSubmissionsDatasource(locationId);
  }, [locationId]);

  return (
    <PharmacyScreenContainer>
      {refillsCount === 0 ? (
        <View style={styles.emptyStateView}>
          <EmptyState
            image={<NoRefillsImage />}
            title={getText('welcome-to-refills')}
            subtitles={[getText('refill-subtitle')]}
            bulletPointTitle={getText('refills-bullet-point-title')}
            bulletPoints={[
              getText('tell-patients-about-app'),
              getText('send-email-patient-portal-account'),
              getText('send-text-for-patients-download-app'),
              getText('announce-ivr-promoting-digital-solutions'),
            ]}
            bulletPointStyle={BulletPointStyle.Check}
          />
        </View>
      ) : (
        <View style={{ flex: 1 }}>
          <DataGrid
            gridOptions={{
              columnDefs: columnDefs,
              enableCellTextSelection: true,
              suppressMovableColumns: true,
              suppressContextMenu: true,
              sortingOrder: ['asc', 'desc'],
              defaultColDef: { sortable: true, menuTabs: [] },
              pagination: true,
              paginationPageSize: AG_GRID_PAGINATION_PAGE_SIZE,
              cacheBlockSize: 50,
              maxBlocksInCache: 1,
              loadingOverlayComponent: 'loadingIndicator',
              loadingOverlayComponentParams: {
                color: theme.colors.brandedPrimary,
              },
              components: {
                loadingIndicator: LoadingIndicator,
              },
              noRowsOverlayComponent: DataGridEmptyState,
              noRowsOverlayComponentParams: {
                filter: isPassedFilter,
                searchValue: searchValue,
              },
              rowSelection: 'single',
              suppressCellFocus: true,
              colResizeDefault: 'shift',
              onRowClicked(params) {
                if (!sidebarDetails && params.event?.defaultPrevented) return;

                collapseSidebar(false);
                fillRefillSidebarDetails(params.data);
                navigation.setParams({
                  refillItemId: params.data.refill_item_id,
                });
              },
              onGridReady(event) {
                setGridApi(event.api);
              },
              onModelUpdated: (event) => {
                showRefillSideBar(event.api);
              },
              rowModelType: 'serverSide',
              serverSideStoreType: 'partial',
              serverSideDatasource: memoizedServerSideDatasource,
              context: {
                transformRequest(params) {
                  setSearchValue(params.search);
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                  return {
                    ...params,
                    refill_status:
                      isPassedFilter !== '' ? isPassedFilter : undefined,
                  };
                },
              },
              getRowId: (param) => param.data.refill_item_id,
            }}
            gridToolbarProps={{
              headerChildren: (
                <RefillsStatusFilter
                  value={isPassedFilter}
                  onChange={setIsPassedFilter}
                />
              ),
              titleProps: {
                title: getText('refill-submissions-header'),
              },
              inputSearchProps: {
                size: 'lg',
                placeholder: getText('name-rxnumber-dob'),
              },
              enableSelectAllBox: false,
            }}
          />
        </View>
      )}
      {sidebarDetails && !patientSidebarDetails && (
        <RefillDetailSidebar
          onPatientDetailsPress={handlePatientDetailsPress}
          onCollapse={handleSidebarCollapse}
        />
      )}
      {sidebarDetails && patientSidebarDetails && (
        <PatientDetailSidebar
          backLinkComponent={
            <Text style={styles.link} onPress={handlePatientDetailsCollapse}>
              {getText('refill-details')}
            </Text>
          }
          onCollapse={handleSidebarCollapse}
        />
      )}
      <RefillsContextMenu id={REFILLS_CONTEXT_MENU_ID} />
    </PharmacyScreenContainer>
  );
};

const DataGridEmptyState: FunctionComponent<{
  filter: RefillStatus;
  searchValue: string;
}> = (props) => {
  const styles = useStyles();
  const theme = useTheme();
  return (
    <View style={styles.rowView}>
      <View style={{ marginRight: theme.getSpacing(0.5) }}>
        <Icon
          icon={AlertTriangleIcon}
          size={15}
          color={theme.palette.gray[500]}
        />
      </View>
      <View>
        <Text style={styles.emptyStateText}>
          {getText('no-items-to-display-for')} "
          {props.filter === RefillStatus.Rejected
            ? getText('refill-status-not-passed')
            : props.filter === RefillStatus.Accepted
            ? getText('refill-status-passed')
            : getText('refill-status-filter-all')}
          " {props.searchValue && `${getText('and')} "${props.searchValue}"`}
        </Text>
      </View>
    </View>
  );
};

export const FullNameRenderer = (params: ICellRendererParams) => {
  const styles = useStyles();
  const theme = useTheme();
  const { patient_first_name, patient_last_name } =
    params.data as RefillSubmittedDto;
  const fullName = `${patient_first_name} ${patient_last_name}`;

  return (
    <View style={styles.iconContainer}>
      <View
        style={{ justifyContent: 'center', marginRight: theme.getSpacing(1) }}
      >
        <Avatar
          firstName={patient_first_name}
          lastName={patient_last_name}
          size={32}
        />
      </View>
      <div>{fullName} </div>
    </View>
  );
};

export const StatusFieldRenderer = (params: ICellRendererParams) => {
  const styles = useStyles();
  const theme = useTheme();
  const { refill_status, rx_status, workflow_status, refill_item_id } =
    params.data as RefillSubmittedDto;

  const workflowStatusText = getWorkflowStatusText(workflow_status);
  const rxStatusText = getRxStatusText(rx_status);

  if (refill_status === RefillStatus.Accepted) {
    return (
      <>
        <Text numberOfLines={1}>{workflowStatusText}</Text>
      </>
    );
  } else {
    return (
      <>
        <View style={styles.iconContainer}>
          <View style={styles.icon}>
            <Icon
              icon={AlertCircleIcon}
              size={16}
              color={theme.palette.error[600]}
            />
          </View>
          <Text numberOfLines={1}>{rxStatusText}</Text>
        </View>
      </>
    );
  }
};

export const DateOfBirthRenderer = (params: ICellRendererParams) => {
  const dob = params.data.patient_date_of_birth;

  return <div>{formatDate(dob)}</div>;
};

export const DateFieldRenderer = (params: ICellRendererParams) => {
  return <div>{formatTimeDate(params.value)}</div>;
};

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 46,
  },
  icon: {
    justifyContent: 'center',
    marginRight: theme.getSpacing(1),
  },
  link: {
    color: theme.palette.primary[500],
    textDecorationLine: 'none',
  },
  emptyStateText: {
    ...theme.lumistryFonts.text.medium.regular,
    color: theme.palette.gray[700],
  },
  emptyStateView: {
    width: '100%',
    overflow: 'hidden',
  },
  rowView: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

export default Refills;
