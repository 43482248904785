import { Text } from 'assets/components/text';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { UnreadMessagesEmptyStateIcon } from '../icons/UnreadMessagesEmptyStateIcon';
import { MultiColorIconProps } from 'assets/icons/types';

export const EmptyState: FunctionComponent<EmptyStateProps> = ({
  Icon,
  text,
  iconProps,
}) => {
  const styles = useStyles();

  return (
    <View style={styles.container}>
      <Icon {...iconProps} />
      <Text style={styles.text}>{text}</Text>
    </View>
  );
};

export type EmptyStateProps = {
  Icon: React.FC<MultiColorIconProps>;
  iconProps?: MultiColorIconProps;
  text: string;
};

const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 150,
  },
  text: {
    fontFamily: 'Lato_400Regular',
    fontSize: 16,
    lineHeight: 24,
  },
}));
