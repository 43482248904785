import * as validate from '@digitalpharmacist/validation-dp';
import { Text } from 'assets/components/text';
import { TextField } from 'assets/components/text-field';
import { Form } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import React, { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { View } from 'react-native';
import { useWizard } from 'react-use-wizard';
import {
  save,
  updateLocationsEmployee,
} from '../../../../actions/onboarding-actions';
import { useOnboardingStore } from '../../../../store/onboarding-store';
import { FORM_NOT_VALID } from '../../utils';
import { InfoTooltip } from '../common/InfoTooltip';
import { OBContentHeader } from '../content-header/OBContentHeader';
import OBDivider from '../divider/OBDivider';
import { OBFileTextIcon } from '../icons/OBFileTextIcon';

export interface MultiLocationAccountFormType {
  contact_name: string;
  contact_phone: string;
  contact_email: string;
  brand_name: string;
  corp_name: string;
}

export const OBMultiLocationAccount: FunctionComponent = () => {
  const styles = useStyles();
  const { data } = useOnboardingStore();
  const { handleStep } = useWizard();
  const accountData = data.locations[0];
  const { first_name, last_name, email, phone } =
    accountData.employees?.[0] ?? {};

  const methods = useForm<MultiLocationAccountFormType>({
    defaultValues: {
      contact_name:
        first_name && last_name ? `${first_name} ${last_name}` : undefined,
      contact_email: email,
      contact_phone: phone,
      brand_name: data.brand_name,
      corp_name: data.corp_name,
    },
  });

  handleStep(async () => {
    await methods.handleSubmit(
      async (values) => {
        updateLocationsEmployee(values);
        await save();
      },
      () => {
        // throw error to prevent go next
        throw FORM_NOT_VALID;
      },
    )();
  });

  return (
    <View style={styles.root}>
      <OBContentHeader
        icon={OBFileTextIcon}
        title={getText('account-information')}
        description={getText('please-confirm-or-fill-in')}
      />

      <View>
        <Form methods={methods}>
          <Form.Row>
            <Form.Column>
              <Text style={styles.title}>{getText('primary-contact')}</Text>
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column>
              <TextField
                name="contact_name"
                type="text"
                label={getText('name')}
                rules={{
                  required: getText('name-is-required'),
                  validate: {
                    value: (value) => {
                      return validate.isName(value)
                        ? true
                        : getText('name-is-not-valid');
                    },
                  },
                }}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column>
              <TextField
                name="contact_phone"
                type="telephoneNumber2"
                label={getText('phone-number')}
                rules={{
                  required: getText('phone-is-required'),
                  validate: {
                    value: (value: string) => {
                      return validate.isPhoneNumber(value ? '+1' + value : '')
                        ? true
                        : getText('phone-is-not-valid');
                    },
                  },
                }}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column>
              <TextField
                name="contact_email"
                type="emailAddress"
                disabled
                label={getText('email-address')}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column>
              <Text style={styles.title}>
                {getText('business-information')}
              </Text>
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column>
              <TextField
                name="brand_name"
                type="text"
                label={getText('brand-name')}
                rules={{
                  required: getText('brand-name-is-required'),
                  validate: {
                    value: (value) => {
                      return validate.isName(value)
                        ? true
                        : getText('brand-name-is-not-valid');
                    },
                  },
                }}
              />
              <InfoTooltip
                style={styles.tootlip}
                tooltipId="brand-name"
                text={getText('brand-name-description')}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column>
              <TextField
                name="corp_name"
                type="text"
                label={getText('pharmacy-parent-company')}
                rules={{
                  required: getText('parent-company-is-required'),
                  validate: {
                    value: (value) => {
                      return validate.isName(value)
                        ? true
                        : getText('parent-company-is-not-valid');
                    },
                  },
                }}
              />
              <InfoTooltip
                style={styles.tootlip}
                tooltipId="parent-company"
                text={getText('for-iternal-use')}
              />
            </Form.Column>
          </Form.Row>
        </Form>
      </View>
      <OBDivider />
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    alignContent: 'center',
  },
  link: {
    color: theme.palette.primary[500],
  },
  title: {
    ...theme.lumistryFonts.text.medium.semiBold,
    color: theme.palette.gray[900],
  },
  tootlip: { position: 'absolute', right: -35, top: 20 },
}));
