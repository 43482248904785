import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Text } from 'assets/components/text';
import { TouchableOpacity, View } from 'react-native';
import { makeStyles, useTheme } from 'assets/theme';
import { ArrowRightIcon } from 'assets/icons';
import { Icon } from 'assets/components/icon';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { getText } from 'assets/localization/localization';

export const WidgetContainer: FunctionComponent<
  PropsWithChildren<WidgetContainerProps>
> = ({ icon, title, onNavigate, moreItems, loading, children }) => {
  const theme = useTheme();
  const styles = useStyles();

  return (
    <View style={styles.root}>
      {
        <TouchableOpacity style={styles.header} onPress={onNavigate}>
          {icon && <View>{icon}</View>}
          {title && <Text style={styles.headerText}>{title}</Text>}
          <Icon
            icon={ArrowRightIcon}
            size={20}
            color={theme.palette.gray[700]}
          />
        </TouchableOpacity>
      }

      {loading ? (
        <View style={styles.loadingContainer}>
          <LoadingIndicator size={40} />
        </View>
      ) : (
        children
      )}

      {moreItems && (
        <View style={styles.footer}>
          <TouchableOpacity style={styles.touchableText} onPress={onNavigate}>
            <Text style={{ color: theme.palette.primary[600] }}>
              +{moreItems} {getText('more')}
            </Text>
            <Icon
              icon={ArrowRightIcon}
              size={20}
              color={theme.palette.primary[600]}
            />
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
};

export interface WidgetContainerProps {
  icon?: React.ReactNode;
  title?: string;
  loading?: boolean;
  onNavigate?: () => void;
  moreItems?: number;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: theme.roundness,
    boxShadow:
      '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);',
    backgroundColor: theme.palette.white,
    padding: theme.getSpacing(2),
  },
  header: {
    flexDirection: 'row',
    gap: theme.getSpacing(1),
    alignItems: 'center',
    marginRight: 'auto',
  },
  footer: {
    alignItems: 'center',
    marginTop: theme.getSpacing(1),
  },
  touchableText: {
    flexDirection: 'row',
    gap: theme.getSpacing(0.5),
    alignItems: 'center',
  },
  loadingContainer: { justifyContent: 'center', flex: 1, minHeight: 200 },
  headerText: {
    fontFamily: 'Lato_700Bold',
    fontSize: 18,
    lineHeight: 28,
    fontWeight: '700',
  },
}));
