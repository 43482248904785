import { FunctionComponent, PropsWithChildren } from 'react';
import { useAppStateStore } from '../../../../store/app-store';
import { useMessageSettingsState } from '../message-settings-store';
import {
  modifyMessageSettings,
  updateShowMessageSettingsMarketingPhoneState,
  updateShowMessageSettingsNonMarketingPhoneState,
} from '../message-settings-actions';
import { ToggleSwitch } from 'assets/components/toggle-switch';
import { View } from 'react-native';
import { RowItemRender } from '../../../../components/Settings/RowItemRender';
import { makeStyles } from 'assets/theme';
import { getText } from 'assets/localization/localization';
import InfoIconWithHint from '../../../../components/InfoIconWithHint';
import { IconButton } from 'assets/components/icon-button';
import { LumistryAuthIcon, PencilIcon } from 'assets/icons';
import { prettyFormat } from '@digitalpharmacist/validation-dp';
import MessageSettingsMarketingPhoneModal from './MessageSettingsMarketingPhoneModal';
import MessageSettingsNonMarketingPhoneModal from './MessageSettingsNonMarketingPhoneModal';
import { Icon } from 'assets/components/icon';

export const GeneralSettingsRenderer: FunctionComponent<
  PropsWithChildren
> = () => {
  const style = useStyles();
  const { pharmacyId, locationId } = useAppStateStore();
  const { messageSettingsConfig, hasAccess } = useMessageSettingsState();

  return (
    <>
      <RowItemRender
        title={getText('patient-can-start-new-chat')}
        value={
          messageSettingsConfig.is_patient_start_chat_available
            ? getText('yes')
            : getText('no')
        }
        style={style.rowStyle}
        icon={
          <InfoIconWithHint text={getText('can-patient-start-chat-info')} />
        }
      >
        <View style={style.actions}>
          <ToggleSwitch
            logger={{ id: `is_patient_start_chat_available` }}
            value={messageSettingsConfig.is_patient_start_chat_available}
            onPress={() => {
              void modifyMessageSettings(pharmacyId, locationId, {
                ...messageSettingsConfig,
                is_patient_start_chat_available:
                  !messageSettingsConfig.is_patient_start_chat_available,
              });
            }}
          ></ToggleSwitch>
        </View>
      </RowItemRender>

      <RowItemRender
        title={getText('marketing-messaging-phone')}
        value={prettyFormat(messageSettingsConfig.marketing_phone || '')}
        style={style.rowStyle}
      >
        {hasAccess ? (
          <>
            <View style={style.actions}>
              <Icon size={16} icon={LumistryAuthIcon} />
              <IconButton
                icon={PencilIcon}
                size={16}
                onPress={() =>
                  updateShowMessageSettingsMarketingPhoneState(true)
                }
                logger={{ id: 'edit-marketing-messaging-phone' }}
              />
            </View>
            <MessageSettingsMarketingPhoneModal />
          </>
        ) : null}
      </RowItemRender>
      <RowItemRender
        title={getText('non-marketing-messaging-phone')}
        value={prettyFormat(messageSettingsConfig.non_marketing_phone || '')}
        style={style.rowStyle}
      >
        {hasAccess ? (
          <View style={style.actions}>
            <Icon size={16} icon={LumistryAuthIcon} />
            <IconButton
              icon={PencilIcon}
              size={16}
              onPress={() =>
                updateShowMessageSettingsNonMarketingPhoneState(true)
              }
              logger={{ id: 'edit-non-marketing-messaging-phone' }}
            />
            <MessageSettingsNonMarketingPhoneModal />
          </View>
        ) : null}
      </RowItemRender>
    </>
  );
};

const useStyles = makeStyles(() => ({
  rowStyle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actions: {
    alignItems: 'center',
    flexDirection: 'row',
  },
}));
