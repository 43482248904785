import { IconProps, MultiColorIconProps } from 'assets/icons/types';
import { useTheme } from 'assets/theme';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path } from 'react-native-svg';

export const NewFormSubmissionsEmptyStateIcon: FunctionComponent<
  MultiColorIconProps
> = ({ color, accentColor, size, testID }) => {
  const theme = useTheme();
  return (
    <Svg width={size} height={size} viewBox="0 0 50 57" testID={testID}>
      <Path
        fill={accentColor ?? theme.palette.gray[700]}
        d="M33.062 34h13.626c.257 0 .493.093.677.248a83.175 83.175 0 0 1-2.901 1.91h-7.087a3.308 3.308 0 0 0-.378 0h-2.875v11.684h3.597c.446.115.906.126 1.348 0h6.557v-5.213c.717-.693 1.422-1.398 2.124-2.108v8.4A1.07 1.07 0 0 1 46.688 50H33.062A1.07 1.07 0 0 1 32 48.921V35.079c0-.595.475-1.079 1.062-1.079Z"
      />
      <Path
        fill={color ?? theme.palette.primary[600]}
        fillRule="evenodd"
        d="M38.374 38.544c-.21-.829-1.874-.639-1.874.252 0 2.756.171 3.792.598 6.24.149.851 1.37 1.907 2.025 1.406 4.616-3.544 6.574-6.112 10.644-10.368.625-.652-.147-1.196-.684-.793-3.94 2.946-5.855 3.916-9.7 6.49-.593-1.242-.733-2.14-1.009-3.227Z"
        clipRule="evenodd"
      />
      <Path
        fill={color ?? theme.palette.primary[600]}
        d="M7.865 31.085h30.273v-1.822H7.865v1.822Zm-.011 5.693h19.634v-1.822H7.854v1.822Zm0 5.693H27.29v-1.822H7.854v1.822Zm0-17.08h30.272v-1.82H7.854v1.82ZM38.126 19.704H7.854v-1.822h30.272v1.822Z"
      />
      <Path
        fill={accentColor ?? theme.palette.gray[700]}
        fillRule="evenodd"
        d="M11.272 11.217H4.804l6.478-6.44-.01 6.44Zm2.805 1.401.012-9.815h29.095v28.491H46V1.402C46 .628 45.371 0 44.592 0H12.688v.005c-.357 0-.716.135-.99.406L.49 11.553c-.3.257-.491.638-.491 1.063v42.983C0 56.372.631 57 1.408 57h43.184C45.37 57 46 56.372 46 55.599v-1.952h-2.816v.55H2.816V14.02h9.858v-.007c.774 0 1.403-.624 1.403-1.395Z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
