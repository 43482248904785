import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
const NoAppointmentsImage = () => (
  <Svg width={321} height={289} fill="none">
    <Path
      fill="#EAF1F4"
      d="M26.06 115.152A26.06 26.06 0 0 1 7.634 70.663L66.05 12.25a26.063 26.063 0 0 1 36.855 0 26.061 26.061 0 0 1 0 36.855l-58.415 58.416a26.061 26.061 0 0 1-18.428 7.631Z"
    />
    <Path
      fill="#EAF1F4"
      d="M46.74 167.477a26.059 26.059 0 0 1-26.06-26.061 26.053 26.053 0 0 1 7.632-18.427L143.667 7.634a26.06 26.06 0 1 1 36.856 36.854L65.167 159.843a26.057 26.057 0 0 1-18.427 7.634Z"
    />
    <Path
      fill="#EAF1F4"
      d="M54.95 232.268a26.062 26.062 0 0 1-18.427-44.489L208.46 15.843a26.067 26.067 0 0 1 18.426-7.632c6.912 0 13.54 2.747 18.429 7.632a26.067 26.067 0 0 1 0 36.857L73.378 224.634a26.062 26.062 0 0 1-18.427 7.634Z"
    />
    <Path
      fill="#EAF1F4"
      d="M72.13 288.09a26.062 26.062 0 0 1-26.06-26.061c0-6.911 2.745-13.54 7.633-18.427L238.987 58.319a26.056 26.056 0 0 1 36.855 0 26.063 26.063 0 0 1 0 36.855L90.558 280.457a26.062 26.062 0 0 1-18.428 7.633Z"
    />
    <Path
      fill="#EAF1F4"
      d="M147.257 285.966a26.063 26.063 0 0 1-26.061-26.061c0-6.911 2.746-13.54 7.633-18.427L262.158 108.15a26.059 26.059 0 0 1 36.855 0 26.062 26.062 0 0 1 0 36.855L165.684 278.333a26.06 26.06 0 0 1-18.427 7.633Z"
    />
    <Path
      fill="#EAF1F4"
      d="M236.524 269.702a26.057 26.057 0 0 1-18.428-44.488l58.416-58.416a26.062 26.062 0 0 1 36.855 0 26.062 26.062 0 0 1 0 36.855l-58.417 58.416a26.056 26.056 0 0 1-18.426 7.633Z"
    />
    <Path
      fill="#FF6A6A"
      d="m37.79 69.869 1.803-1.803a1.805 1.805 0 0 0-2.553-2.552l-1.803 1.803-1.803-1.803a1.804 1.804 0 1 0-2.552 2.552l1.802 1.803-1.802 1.803a1.804 1.804 0 1 0 2.552 2.552l1.803-1.803 1.803 1.803c.352.353.814.529 1.276.529a1.805 1.805 0 0 0 1.276-3.08l-1.803-1.804ZM233.386 20.395l1.804-1.804a1.806 1.806 0 0 0-2.553-2.552l-1.802 1.804-1.804-1.804a1.806 1.806 0 0 0-2.552 2.552l1.804 1.804-1.804 1.802a1.803 1.803 0 0 0 0 2.552c.352.354.815.53 1.275.53.463 0 .925-.176 1.277-.53l1.804-1.802 1.802 1.802c.352.354.815.53 1.278.53a1.806 1.806 0 0 0 1.275-3.081l-1.804-1.803ZM303.488 184.003l1.805-1.803a1.805 1.805 0 1 0-2.553-2.553l-1.802 1.804-1.805-1.804a1.802 1.802 0 0 0-2.55 0 1.802 1.802 0 0 0 0 2.553l1.802 1.803-1.802 1.803a1.8 1.8 0 0 0 0 2.552c.352.353.813.529 1.274.529.463 0 .924-.176 1.276-.529l1.805-1.803 1.802 1.803c.352.353.813.529 1.277.529a1.806 1.806 0 0 0 1.276-3.081l-1.805-1.803ZM115.252 276.174l1.803-1.803a1.807 1.807 0 0 0 0-2.553 1.807 1.807 0 0 0-2.553 0l-1.803 1.804-1.803-1.804a1.806 1.806 0 0 0-2.552 2.553l1.802 1.803-1.802 1.803a1.802 1.802 0 0 0 0 2.552c.352.353.814.529 1.275.529.463 0 .924-.176 1.277-.529l1.803-1.803 1.803 1.803a1.801 1.801 0 0 0 2.553 0 1.806 1.806 0 0 0 0-2.552l-1.803-1.803Z"
    />
    <Path
      fill="#6AC0CC"
      d="M120.684 9.742a1.664 1.664 0 0 0 0 3.325 1.663 1.663 0 0 0 0-3.326Zm0 6.935a5.278 5.278 0 0 1-5.272-5.274 5.277 5.277 0 0 1 5.272-5.27 5.277 5.277 0 0 1 5.271 5.27 5.278 5.278 0 0 1-5.271 5.274ZM20.679 192.441c-.917 0-1.663.745-1.663 1.663 0 .915.746 1.661 1.663 1.661.916 0 1.662-.746 1.662-1.661 0-.918-.746-1.663-1.662-1.663Zm0 6.934a5.278 5.278 0 0 1-5.272-5.271 5.279 5.279 0 0 1 5.272-5.273 5.279 5.279 0 0 1 5.272 5.273 5.278 5.278 0 0 1-5.272 5.271ZM247.674 257.562a1.662 1.662 0 1 0 .004 3.324 1.662 1.662 0 0 0-.004-3.324Zm0 6.934a5.276 5.276 0 0 1-5.27-5.272 5.276 5.276 0 0 1 5.27-5.272 5.279 5.279 0 0 1 5.273 5.272 5.279 5.279 0 0 1-5.273 5.272ZM293.363 90.867a1.663 1.663 0 1 0 1.662 1.663c0-.916-.747-1.663-1.662-1.663Zm0 6.935a5.277 5.277 0 0 1-5.272-5.272 5.278 5.278 0 0 1 5.272-5.272 5.28 5.28 0 0 1 5.272 5.272 5.278 5.278 0 0 1-5.272 5.272Z"
    />
    <Path
      fill="#5CC4FF"
      d="M86.198 239.395a15.927 15.927 0 0 1-11.334-4.695 15.926 15.926 0 0 1-4.695-11.335V74.761a15.92 15.92 0 0 1 4.695-11.335 15.93 15.93 0 0 1 11.334-4.696h148.605c4.281 0 8.306 1.67 11.333 4.696a15.917 15.917 0 0 1 4.696 11.335v148.604c0 4.281-1.667 8.307-4.696 11.335a15.923 15.923 0 0 1-11.333 4.695H86.198Z"
    />
    <Path
      fill="#0063B2"
      d="M234.802 56.742H86.198a17.955 17.955 0 0 0-12.742 5.278 17.926 17.926 0 0 0-3.859 5.727 17.897 17.897 0 0 0-1.418 7.014v148.604c0 2.43.477 4.791 1.418 7.015a17.953 17.953 0 0 0 3.86 5.726 17.961 17.961 0 0 0 12.741 5.278h148.604c2.431 0 4.791-.477 7.014-1.417a17.941 17.941 0 0 0 5.727-3.861 17.98 17.98 0 0 0 3.861-5.726 17.942 17.942 0 0 0 1.417-7.015V74.761a17.953 17.953 0 0 0-5.278-12.74 17.935 17.935 0 0 0-5.727-3.862 17.921 17.921 0 0 0-7.014-1.417Zm0 3.979c7.755 0 14.04 6.285 14.04 14.04v148.604c0 7.754-6.285 14.04-14.04 14.04H86.198c-7.754 0-14.04-6.286-14.04-14.04V74.761c0-7.755 6.286-14.04 14.04-14.04h148.604Z"
    />
    <Path fill="#fff" d="M100.282 203.397H86.103v14.179h14.179v-14.179Z" />
    <Path
      fill="#0063B2"
      d="M88.093 215.587h10.2v-10.2h-10.2v10.2Zm14.18 3.979h-18.16v-18.158h18.16v18.158Z"
    />
    <Path fill="#fff" d="M122.718 203.397H108.54v14.179h14.178v-14.179Z" />
    <Path
      fill="#0063B2"
      d="M110.528 215.587h10.2v-10.2h-10.2v10.2Zm14.179 3.979h-18.158v-18.157h18.158v18.157Z"
    />
    <Path fill="#fff" d="M145.154 203.397h-14.179v14.18h14.179v-14.18Z" />
    <Path
      fill="#0063B2"
      d="M132.964 215.588h10.2v-10.201h-10.2v10.201Zm14.179 3.979h-18.158v-18.159h18.158v18.159Z"
    />
    <Path fill="#fff" d="M167.59 203.397h-14.18v14.18h14.18v-14.18Z" />
    <Path
      fill="#0063B2"
      d="M155.4 215.588h10.201v-10.201H155.4v10.201Zm14.179 3.978h-18.158v-18.158h18.158v18.158Z"
    />
    <Path fill="#fff" d="M190.026 203.397h-14.18v14.18h14.18v-14.18Z" />
    <Path
      fill="#0063B2"
      d="M177.836 215.587h10.201v-10.2h-10.201v10.2Zm14.18 3.979h-18.158v-18.157h18.158v18.157Z"
    />
    <Path fill="#fff" d="M100.281 182.169H86.103v14.179h14.178v-14.179Z" />
    <Path
      fill="#0063B2"
      d="M88.093 194.357h10.2v-10.2h-10.2v10.2Zm14.179 3.98H84.113v-18.159h18.159v18.159Z"
    />
    <Path fill="#fff" d="M122.718 182.168H108.54v14.179h14.178v-14.179Z" />
    <Path
      fill="#0063B2"
      d="M110.528 194.359h10.2v-10.2h-10.2v10.2Zm14.179 3.979h-18.158v-18.159h18.158v18.159Z"
    />
    <Path fill="#fff" d="M145.154 182.168h-14.179v14.18h14.179v-14.18Z" />
    <Path
      fill="#0063B2"
      d="M132.964 194.358h10.2v-10.201h-10.2v10.201Zm14.179 3.979h-18.158v-18.158h18.158v18.158Z"
    />
    <Path fill="#fff" d="M167.59 182.168h-14.18v14.179h14.18v-14.179Z" />
    <Path
      fill="#0063B2"
      d="M155.4 194.357h10.201v-10.199H155.4v10.199Zm14.179 3.979h-18.158v-18.157h18.158v18.157Z"
    />
    <Path fill="#fff" d="M190.026 182.167h-14.18v14.18h14.18v-14.18Z" />
    <Path
      fill="#0063B2"
      d="M177.836 194.357h10.201v-10.199h-10.201v10.199Zm14.18 3.98h-18.158v-18.159h18.158v18.159Z"
    />
    <Path fill="#fff" d="M212.461 182.168h-14.179v14.179h14.179v-14.179Z" />
    <Path
      fill="#0063B2"
      d="M200.273 194.357h10.2v-10.2h-10.2v10.2Zm14.178 3.98h-18.159v-18.159h18.159v18.159Z"
    />
    <Path fill="#fff" d="M234.896 182.168h-14.18v14.18h14.18v-14.18Z" />
    <Path
      fill="#0063B2"
      d="M222.707 194.358h10.202v-10.2h-10.202v10.2Zm14.18 3.979h-18.158v-18.159h18.158v18.159Z"
    />
    <Path fill="#fff" d="M100.283 160.939h-14.18v14.179h14.18v-14.179Z" />
    <Path
      fill="#0063B2"
      d="M88.092 173.129h10.201v-10.2h-10.2v10.2Zm14.18 3.979H84.113V158.95h18.159v18.158Z"
    />
    <Path fill="#fff" d="M122.718 160.939h-14.179v14.179h14.179v-14.179Z" />
    <Path
      fill="#0063B2"
      d="M110.528 173.129h10.201v-10.2h-10.201v10.2Zm14.18 3.979h-18.159V158.95h18.159v18.158Z"
    />
    <Path fill="#fff" d="M145.154 160.939h-14.179v14.179h14.179v-14.179Z" />
    <Path
      fill="#0063B2"
      d="M132.964 173.13h10.2v-10.201h-10.2v10.201Zm14.179 3.979h-18.158V158.95h18.158v18.159Z"
    />
    <Path fill="#fff" d="M167.59 160.939h-14.18v14.179h14.18v-14.179Z" />
    <Path
      fill="#0063B2"
      d="M155.4 173.131h10.201V162.93H155.4v10.201Zm14.179 3.978h-18.158v-18.158h18.158v18.158Z"
    />
    <Path fill="#fff" d="M190.026 160.939h-14.18v14.18h14.18v-14.18Z" />
    <Path
      fill="#0063B2"
      d="M177.836 173.13h10.201v-10.201h-10.201v10.201Zm14.18 3.978h-18.158v-18.157h18.158v18.157Z"
    />
    <Path fill="#fff" d="M212.461 160.939h-14.179v14.179h14.179v-14.179Z" />
    <Path
      fill="#0063B2"
      d="M200.273 173.129h10.2v-10.2h-10.2v10.2Zm14.178 3.978h-18.159v-18.156h18.159v18.156Z"
    />
    <Path fill="#fff" d="M234.896 160.94h-14.18v14.178h14.18V160.94Z" />
    <Path
      fill="#0063B2"
      d="M222.707 173.128h10.202v-10.199h-10.202v10.199Zm14.18 3.98h-18.158v-18.157h18.158v18.157Z"
    />
    <Path fill="#fff" d="M100.283 139.71h-14.18v14.18h14.18v-14.18Z" />
    <Path
      fill="#0063B2"
      d="M88.094 151.899h10.2v-10.2h-10.2v10.2Zm14.178 3.979H84.113V137.72h18.159v18.158Z"
    />
    <Path fill="#fff" d="M122.718 139.71h-14.18v14.18h14.18v-14.18Z" />
    <Path
      fill="#0063B2"
      d="M110.528 151.899h10.2v-10.2h-10.2v10.2Zm14.181 3.979h-18.16V137.72h18.16v18.158Z"
    />
    <Path fill="#fff" d="M145.153 139.71h-14.179v14.18h14.179v-14.18Z" />
    <Path
      fill="#0063B2"
      d="M132.964 151.899h10.2v-10.2h-10.2v10.2Zm14.179 3.979h-18.157V137.72h18.157v18.158Z"
    />
    <Path fill="#fff" d="M167.59 139.709h-14.18v14.181h14.18v-14.181Z" />
    <Path
      fill="#0063B2"
      d="M155.4 151.9h10.201v-10.199H155.4V151.9Zm14.179 3.979h-18.158v-18.157h18.158v18.157Z"
    />
    <Path fill="#fff" d="M190.026 139.71h-14.18v14.18h14.18v-14.18Z" />
    <Path
      fill="#0063B2"
      d="M177.836 151.899h10.201V141.7h-10.201v10.199Zm14.18 3.98h-18.158V137.72h18.158v18.159Z"
    />
    <Path fill="#fff" d="M212.461 139.711h-14.179v14.179h14.179v-14.179Z" />
    <Path
      fill="#0063B2"
      d="M200.273 151.899h10.2v-10.2h-10.2v10.2Zm14.178 3.98h-18.159V137.72h18.159v18.159Z"
    />
    <Path fill="#fff" d="M234.896 139.71h-14.18v14.179h14.18V139.71Z" />
    <Path
      fill="#0063B2"
      d="M222.707 151.899h10.202v-10.2h-10.202v10.2Zm14.18 3.98h-18.158v-18.158h18.158v18.158Z"
    />
    <Path fill="#fff" d="M167.59 118.48h-14.18v14.18h14.18v-14.18Z" />
    <Path
      fill="#0063B2"
      d="M155.4 130.671h10.2v-10.2h-10.2v10.2Zm14.18 3.979h-18.159v-18.159h18.159v18.159Z"
    />
    <Path fill="#fff" d="M190.026 118.481h-14.18v14.18h14.18v-14.18Z" />
    <Path
      fill="#0063B2"
      d="M177.836 130.671h10.201v-10.202h-10.201v10.202Zm14.18 3.978h-18.158V116.49h18.158v18.159Z"
    />
    <Path fill="#fff" d="M212.461 118.482h-14.179v14.179h14.179v-14.179Z" />
    <Path
      fill="#0063B2"
      d="M200.273 130.671h10.2v-10.202h-10.2v10.202Zm14.178 3.978h-18.159v-18.158h18.159v18.158Z"
    />
    <Path fill="#fff" d="M234.896 118.481h-14.18v14.18h14.18v-14.18Z" />
    <Path
      fill="#0063B2"
      d="M222.707 130.671h10.202V120.47h-10.202v10.201Zm14.18 3.978h-18.158v-18.157h18.158v18.157Z"
    />
    <Path
      fill="#FFC854"
      d="M70.169 100.73V74.761a15.92 15.92 0 0 1 4.695-11.335 15.93 15.93 0 0 1 11.334-4.696h148.605c4.281 0 8.306 1.67 11.333 4.696a15.917 15.917 0 0 1 4.696 11.335v25.969H70.169Z"
    />
    <Path
      fill="#0063B2"
      d="M234.802 56.742H86.198a17.955 17.955 0 0 0-12.742 5.279 17.926 17.926 0 0 0-3.859 5.726 17.897 17.897 0 0 0-1.418 7.014v27.959H252.821V74.761a17.953 17.953 0 0 0-5.278-12.74 17.935 17.935 0 0 0-5.727-3.862 17.921 17.921 0 0 0-7.014-1.417Zm0 3.979c7.755 0 14.04 6.286 14.04 14.04v23.98H72.158v-23.98c0-7.754 6.286-14.04 14.04-14.04h148.604Z"
    />
    <Path
      fill="#C9DBE6"
      d="M93.918 72.745c-4.183 0-7.585-3.401-7.585-7.584V56.28c0-4.183 3.402-7.584 7.585-7.584 4.182 0 7.585 3.401 7.585 7.584v8.881c0 4.183-3.403 7.584-7.585 7.584Z"
    />
    <Path
      fill="#0063B2"
      d="M93.918 46.706a9.566 9.566 0 0 0-6.77 2.803 9.552 9.552 0 0 0-2.804 6.771v8.881c0 1.29.253 2.545.754 3.727a9.53 9.53 0 0 0 2.05 3.042 9.535 9.535 0 0 0 3.043 2.051c1.182.5 2.437.754 3.727.754s2.546-.254 3.728-.754a9.544 9.544 0 0 0 3.043-2.051 9.524 9.524 0 0 0 2.049-3.042 9.505 9.505 0 0 0 .754-3.727v-8.88a9.547 9.547 0 0 0-2.803-6.77 9.57 9.57 0 0 0-6.77-2.805Zm0 3.98a5.594 5.594 0 0 1 5.595 5.594v8.881a5.594 5.594 0 0 1-5.595 5.595 5.594 5.594 0 0 1-5.595-5.595v-8.88a5.594 5.594 0 0 1 5.595-5.595Z"
    />
    <Path
      fill="#C9DBE6"
      d="M120.551 72.745c-4.183 0-7.585-3.401-7.585-7.584V56.28c0-4.183 3.402-7.584 7.585-7.584 4.182 0 7.585 3.401 7.585 7.584v8.881c0 4.183-3.403 7.584-7.585 7.584Z"
    />
    <Path
      fill="#0063B2"
      d="M120.551 46.706a9.565 9.565 0 0 0-6.77 2.803 9.55 9.55 0 0 0-2.804 6.771v8.881c0 1.29.253 2.545.754 3.727a9.527 9.527 0 0 0 2.05 3.042 9.539 9.539 0 0 0 6.77 2.805 9.538 9.538 0 0 0 6.77-2.805 9.511 9.511 0 0 0 2.05-3.042 9.505 9.505 0 0 0 .754-3.727v-8.88a9.514 9.514 0 0 0-.754-3.73 9.533 9.533 0 0 0-2.05-3.042 9.565 9.565 0 0 0-6.77-2.803Zm0 3.98a5.594 5.594 0 0 1 5.595 5.594v8.881a5.594 5.594 0 1 1-11.19 0v-8.88a5.594 5.594 0 0 1 5.595-5.595Z"
    />
    <Path
      fill="#C9DBE6"
      d="M147.183 72.745c-4.182 0-7.584-3.401-7.584-7.584V56.28c0-4.183 3.402-7.584 7.584-7.584 4.183 0 7.586 3.401 7.586 7.584v8.881c0 4.183-3.403 7.584-7.586 7.584Z"
    />
    <Path
      fill="#0063B2"
      d="M147.183 46.706a9.554 9.554 0 0 0-6.769 2.803 9.517 9.517 0 0 0-2.05 3.042 9.513 9.513 0 0 0-.753 3.73v8.88c0 1.29.252 2.545.753 3.727a9.495 9.495 0 0 0 2.05 3.042 9.533 9.533 0 0 0 6.769 2.805 9.543 9.543 0 0 0 6.771-2.805 9.492 9.492 0 0 0 2.049-3.042 9.505 9.505 0 0 0 .754-3.727v-8.88a9.514 9.514 0 0 0-.754-3.73 9.514 9.514 0 0 0-2.049-3.042 9.565 9.565 0 0 0-6.771-2.803Zm0 3.98a5.594 5.594 0 0 1 5.596 5.594v8.881a5.594 5.594 0 0 1-5.596 5.595 5.594 5.594 0 0 1-5.595-5.595v-8.88a5.594 5.594 0 0 1 5.595-5.595Z"
    />
    <Path
      fill="#C9DBE6"
      d="M173.816 72.745c-4.181 0-7.584-3.401-7.584-7.584V56.28c0-4.183 3.403-7.584 7.584-7.584 4.182 0 7.585 3.401 7.585 7.584v8.881c0 4.183-3.403 7.584-7.585 7.584Z"
    />
    <Path
      fill="#0063B2"
      d="M173.817 46.706a9.561 9.561 0 0 0-6.77 2.803 9.5 9.5 0 0 0-2.05 3.042 9.514 9.514 0 0 0-.754 3.73v8.88c0 1.29.253 2.545.754 3.727a9.479 9.479 0 0 0 2.05 3.042 9.534 9.534 0 0 0 6.77 2.805 9.533 9.533 0 0 0 6.769-2.805 9.479 9.479 0 0 0 2.05-3.042 9.507 9.507 0 0 0 .756-3.727v-8.88a9.516 9.516 0 0 0-.756-3.73 9.5 9.5 0 0 0-2.05-3.042 9.554 9.554 0 0 0-6.769-2.803Zm0 3.98a5.594 5.594 0 0 1 5.595 5.594v8.881a5.595 5.595 0 0 1-11.191 0v-8.88a5.595 5.595 0 0 1 5.596-5.595Z"
    />
    <Path
      fill="#C9DBE6"
      d="M200.449 72.745c-4.183 0-7.584-3.401-7.584-7.584V56.28c0-4.183 3.401-7.584 7.584-7.584 4.182 0 7.585 3.401 7.585 7.584v8.881c0 4.183-3.403 7.584-7.585 7.584Z"
    />
    <Path
      fill="#0063B2"
      d="M200.45 46.706a9.565 9.565 0 0 0-6.771 2.803 9.514 9.514 0 0 0-2.049 3.042 9.514 9.514 0 0 0-.754 3.73v8.88c0 1.29.253 2.545.754 3.727a9.492 9.492 0 0 0 2.049 3.042 9.539 9.539 0 0 0 6.771 2.805 9.533 9.533 0 0 0 6.769-2.805 9.55 9.55 0 0 0 2.805-6.769v-8.88a9.549 9.549 0 0 0-2.805-6.772 9.554 9.554 0 0 0-6.769-2.803Zm0 3.98a5.594 5.594 0 0 1 5.595 5.594v8.881a5.595 5.595 0 0 1-11.191 0v-8.88a5.595 5.595 0 0 1 5.596-5.595Z"
    />
    <Path
      fill="#C9DBE6"
      d="M227.083 72.745c-4.183 0-7.586-3.401-7.586-7.584V56.28c0-4.183 3.403-7.584 7.586-7.584s7.584 3.401 7.584 7.584v8.881c0 4.183-3.401 7.584-7.584 7.584Z"
    />
    <Path
      fill="#0063B2"
      d="M227.083 46.706a9.55 9.55 0 0 0-6.77 2.803 9.533 9.533 0 0 0-2.05 3.042 9.514 9.514 0 0 0-.754 3.73v8.88c0 1.29.253 2.545.754 3.727a9.511 9.511 0 0 0 2.05 3.042 9.543 9.543 0 0 0 6.77 2.805 9.533 9.533 0 0 0 6.769-2.805 9.533 9.533 0 0 0 2.806-6.769v-8.88a9.55 9.55 0 0 0-2.806-6.772 9.554 9.554 0 0 0-6.769-2.803Zm0 3.98a5.594 5.594 0 0 1 5.595 5.594v8.881a5.595 5.595 0 0 1-11.191 0v-8.88a5.595 5.595 0 0 1 5.596-5.595Z"
    />
    <Path
      fill="#FFC854"
      d="m195.151 215.42 13.971-13.97 16.188 16.189 38.486-38.487 13.969 13.971-52.455 52.456-30.159-30.159Z"
    />
    <Path
      fill="#0063B2"
      d="m263.796 177-2.152 2.153-36.334 36.334-14.037-14.037-2.151-2.152-2.153 2.152-11.818 11.819L193 215.42l2.151 2.151 28.008 28.008 2.151 2.151 2.151-2.151 50.305-50.305 2.151-2.152-2.151-2.151-11.819-11.819-2.151-2.152Zm0 4.304 11.818 11.818-50.304 50.305-28.007-28.007 11.819-11.818 16.188 16.189 38.486-38.487Z"
    />
  </Svg>
);
export default NoAppointmentsImage;
