import { makeStyles } from 'assets/theme';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { FormStatus } from '@digitalpharmacist/forms-service-client-axios';
import { useIsFocused } from '@react-navigation/native';

import { Text } from 'assets/components/text';
import { LoadingOverlay } from '../../components/LoadingOverlay';
import { getForms } from '../../forms/forms-data-table/forms-data-table-actions';
import { useFormsDataTableState } from '../../forms/forms-data-table/forms-data-table-store';
import FormsDataTable from '../../forms/forms-data-table/FormsDataTable';
import { getText } from 'assets/localization/localization';
import EmptyState from '../../components/EmptyState/EmptyState';
import NewFormModal from '../../forms/new-form-modal/NewFormModal';
import { getFormTemplates } from '../../forms/new-form-modal/new-form-modal-actions';
import NoFormsImage from './NoFormsImage';
import { PharmacyScreenContainer } from 'assets/layout';
import { FormTestIDs } from './FormTestIDs';

export default function Forms() {
  const styles = useStyles();
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = async () => {
    await getFormTemplates();
    setShowModal(true);
  };

  const handleHideModal = () => {
    setShowModal(false);
  };

  // isFocused ensures that we fetch the forms after the user navigates back to this page
  const isFocused = useIsFocused();
  useEffect(() => {
    getForms(FormStatus.Enabled);
    getForms(FormStatus.Disabled);
  }, [isFocused]);

  const { activeForms, inactiveForms, status } = useFormsDataTableState();

  const activeFormsExist = activeForms?.length != 0;
  const inactiveFormsExist = inactiveForms?.length != 0;

  return (
    <>
      <NewFormModal
        showButton={false}
        showModal={showModal}
        onHideModal={handleHideModal}
      />
      <PharmacyScreenContainer
        disableScrollView={true}
        screenHeader={
          <Text
            style={styles.title}
            selectable
            testID={FormTestIDs.formsHeader}
          >
            {getText('forms')}
          </Text>
        }
      >
        <View style={styles.content}>
          {(!activeForms || !inactiveForms || status == 'loading') && (
            <LoadingOverlay />
          )}
          {status !== 'loading' && (
            <>
              {!activeFormsExist && !inactiveFormsExist ? (
                <EmptyState
                  image={<NoFormsImage />}
                  title={getText('welcome-to-forms')}
                  subtitles={[getText('create-new-form-or-upload-forms')]}
                  buttonText={getText('new-form')}
                  onButtonPress={handleShowModal}
                  buttonLoggerId="new-form"
                  bulletPoints={[
                    getText('collect-ephi-or-hipaa'),
                    getText('save-time-by-getting-info'),
                    getText('import-your-existing-templates'),
                    getText('save-paper'),
                  ]}
                />
              ) : (
                <>
                  <FormsDataTable filterByStatus={FormStatus.Enabled} />

                  {inactiveFormsExist && (
                    <FormsDataTable filterByStatus={FormStatus.Disabled} />
                  )}
                </>
              )}
            </>
          )}
        </View>
      </PharmacyScreenContainer>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  content: {
    flex: 1,
  },
  title: {
    fontSize: 25,
    paddingHorizontal: theme.getSpacing(3),
    paddingVertical: theme.getSpacing(2),
  },
}));
