import { Text, View } from 'react-native';
import { DayProps, IMessage } from 'react-native-gifted-chat';
import { makeStyles } from 'assets/theme';
import {
  formatTimestampDate,
  timeAugmenter,
} from '../../../common/datetime-utils';

const TimeStampComponent = (props: { args: DayProps<IMessage> }) => {
  const minuteThreshold = 10;
  const styles = useStyles();

  if (!props.args.currentMessage) {
    return null;
  }

  if (!props.args.previousMessage) {
    return null;
  }

  const timeThreshold = timeAugmenter(
    props.args.currentMessage.createdAt,
    'm',
    -minuteThreshold,
  );

  const isFirstMessage = !Object.keys(props.args.previousMessage).length;

  if (isFirstMessage || timeThreshold > props.args.previousMessage.createdAt) {
    return (
      <View style={styles.timestamp}>
        <Text style={styles.timestampText}>
          {formatTimestampDate(props.args.currentMessage.createdAt.toString())}
        </Text>
      </View>
    );
  } else {
    return null;
  }
};

const useStyles = makeStyles((theme) => ({
  timestamp: {
    display: 'flex',
    alignItems: 'center',
  },
  timestampText: {
    ...theme.fonts.regular,
    fontWeight: '600',
    fontSize: 12,
    color: theme.palette.gray[700],
  },
}));

export default TimeStampComponent;
