import { getText } from 'assets/localization/localization';
import { FunctionComponent, PropsWithChildren } from 'react';
import { RowItemRender } from '../../../../components/Settings/RowItemRender';
import { pharmacySoftwares } from '../../../../common/integrations-utils';
import { renewSubscriptionKeys } from '../pms-integrations-settings-actions';
import { RowItemInternalAPIKey } from '../../../../components/Settings/RowItemInternalAPIKey';
import { useAppStateStore } from '../../../../store/app-store';
import { IntegrationType } from '@digitalpharmacist/pms-integration-service-client-axios';
export const PMSConfigDetailsItemRenderer: FunctionComponent<
  PropsWithChildren<PMSConfigDetailsItemRendererProps>
> = ({
  integration_type,
  server_address,
  server_port,
  auth_user,
  auth_secret,
  npi,
  api_key,
  is_subscribed_to_webhooks,
  incoming_webhook_api_key,
  is_datafeed_enabled,
  integration_location_id,
  override_defaults,
}) => {
  const selectedPMS = pharmacySoftwares.find(
    (software) => software.value === integration_type,
  );
  const { pharmacyId, locationId } = useAppStateStore();
  const handleRenewingKeys = async () => {
    await renewSubscriptionKeys(pharmacyId, locationId);
  };

  const shouldRenderUsername = () => {
    if (integration_type == IntegrationType.LibertyV2 && !override_defaults) {
      return false;
    }
    return selectedPMS?.fields.includes('auth_user') && auth_user;
  };
  const shouldRenderPassword = () => {
    if (integration_type == IntegrationType.LibertyV2 && !override_defaults) {
      return false;
    }
    return selectedPMS?.fields.includes('auth_secret') && auth_secret;
  };

  return (
    <>
      <RowItemRender
        title={getText('pharmacy-software')}
        value={selectedPMS?.label ?? ''}
      />
      <RowItemRender
        title={getText('server-address')}
        value={server_address ?? ''}
      />
      {selectedPMS?.fields.includes('npi') && npi && (
        <RowItemRender title={getText('pharmacy-npi-number')} value={npi} />
      )}
      {selectedPMS?.fields.includes('api_key') && api_key && (
        <RowItemRender
          title={getText('api-key')}
          value={api_key}
          isSecretValue={true}
        />
      )}
      {selectedPMS?.fields.includes('server_port') && server_port && (
        <RowItemRender title={getText('server-port')} value={server_port} />
      )}
      {selectedPMS?.fields.includes('is_subscribed_to_webhooks') && (
        <RowItemRender
          title={getText('realtime-updates')}
          value={
            is_subscribed_to_webhooks ? getText('enabled') : getText('disabled')
          }
        />
      )}
      {selectedPMS?.fields.includes('is_datafeed_enabled') && (
        <RowItemRender
          title={getText('datafeed')}
          value={is_datafeed_enabled ? getText('enabled') : getText('disabled')}
        />
      )}
      {shouldRenderUsername() && (
        <RowItemRender title={getText('username')} value={auth_user} />
      )}
      {shouldRenderPassword() && (
        <RowItemRender
          title={getText('password')}
          value={auth_secret}
          isSecretValue={true}
        />
      )}
      {selectedPMS?.fields.includes('is_subscribed_to_webhooks') && (
        <RowItemInternalAPIKey
          title={getText('realtime-updates-api-key')}
          value={incoming_webhook_api_key}
          isSecretValue={true}
          onRefresh={handleRenewingKeys}
        />
      )}
    </>
  );
};

export interface PMSConfigDetailsItemRendererProps {
  integration_type?: string;
  server_address?: string;
  server_port?: string;
  auth_user?: string;
  auth_secret?: string;
  npi?: string;
  api_key?: string;
  is_subscribed_to_webhooks?: boolean;
  incoming_webhook_api_key: string;
  is_datafeed_enabled?: boolean;
  integration_location_id?: string;
  override_defaults?: boolean;
}
