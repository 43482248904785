import React, { FunctionComponent, useCallback, useRef, useState } from 'react';
import { View } from 'react-native';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { ScheduleDrawerNavigationProp } from '../../layout/ScheduleDrawer';
import {
  DatesSetArg,
  EventClickArg,
  EventContentArg,
} from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import shallow from 'zustand/shallow';

import { Text } from 'assets/components/text';
import { makeStyles, useTheme } from 'assets/theme';
import { getText } from '../../../../../packages/assets/localization/localization';
import { LoadingOverlay } from '../../components/LoadingOverlay';
import { useAppointmentsCalendarState } from './appointments-calendar-store';
import {
  cancelCalendarBooking,
  getAppointments,
  groupByUniqueServicesAndCount,
} from './appointments-calendar-actions';
import './calendar.css';
import { ColoredBadge } from '../../components/ColoredBadge';
import {
  showAppointmentDetails,
  resetAppointmentDetails,
  setAppointmentVisibility,
} from '../appointments-list/appointments-list-actions';
import { AppointmentDetailsSidebar } from '../appointments-list/AppointmentDetailsSidebar';
import { useProSidebar } from 'react-pro-sidebar';
import {
  convertDateTimeFromUtcToPharmacyTimezone,
  DEFAULT_PHARMACY_TIMEZONE,
} from '../../common/datetime-utils';
import moment from 'moment';
import { useAppointmentFiltersState } from '../appointment-filters/appointment-filters-store';
import { ToolbarContentBox } from 'assets/components/data-grid/data-grid-toolbar/ToolbarContentBox';
import { composeAppointmentFiltersMessage } from '../appointment-filters/appointment-filters.utils';
import AppointmentService from '../../api/AppointmentService';
import { useServicesListState } from '../services-list/services-list-store';
import EmptyState from '../../components/EmptyState/EmptyState';
import NoServicesImage from '../services-list/NoServicesImage';
import { BulletPointStyle } from '../../components/EmptyState/BulletPoints/BulletPoint';
import NoAppointmentsImage from './NoAppointmentsImage';
import { getServices } from '../services-list/services-list-actions';
import usePatientStore from '../../screens/patients/patient-store';
import { Tooltip } from 'assets/components/tooltip/components/tooltip';
import { EventImpl } from '@fullcalendar/core/internal';
import { useAppointmentsListState } from '../appointments-list/appointments-list-store';

function innerContentRenderer(
  innerProps: EventContentArg,
  handleClickEvent: (data: EventImpl) => void,
  selectedEvent?: string,
) {
  return (
    <div
      className={`fc-event fc-event-start fc-event-end fc-event-past fc-daygrid-event fc-daygrid-dot-event fc-text-ellipsis ${
        innerProps.event.id === selectedEvent ? 'fc-highlighted-event' : ''
      }`}
      id={innerProps.event.id}
      onClick={() => {
        if (handleClickEvent) {
          handleClickEvent(innerProps.event);
        }
      }}
    >
      <div className="fc-daygrid-event-dot" />
      {innerProps.timeText && (
        <div className="fc-event-time">{innerProps.timeText}</div>
      )}
      <div className="fc-event-title fc-text-ellipsis">
        {innerProps.event.title || <>&nbsp;</>}
      </div>
    </div>
  );
}

export const CalendarAppointments: FunctionComponent = () => {
  const styles = useStyles();
  const navigation = useNavigation<ScheduleDrawerNavigationProp>();
  const theme = useTheme();
  const { collapseSidebar } = useProSidebar();
  const calendarRef = useRef<FullCalendar>(null);
  const [selectedEvent, setSelectedEvent] = useState<string | undefined>();

  const services = useServicesListState((state) => state.services);

  const filters = useAppointmentFiltersState((state) => state.filters);
  const isCustomFilter = useAppointmentFiltersState(
    (state) => state.isCustomFilter,
  );

  const isLoading = useAppointmentsCalendarState(
    (state) => state.status === 'loading',
  );

  const {
    sidebarDetails: patientSidebarDetails,
    setSidebarDetails: setPatientSidebarDetails,
  } = usePatientStore();

  const { events, totalCount, appointments } = useAppointmentsCalendarState(
    (state) => ({
      events: state.appointments?.map((booking) => ({
        title: `${booking.title}: ${booking.patient_record_first_name} ${booking.patient_record_last_name}`,
        id: booking.id,
        start: convertDateTimeFromUtcToPharmacyTimezone(
          booking.startTime,
          DEFAULT_PHARMACY_TIMEZONE,
          'YYYY-MM-DDTHH:mm:ss',
        ),
        end: convertDateTimeFromUtcToPharmacyTimezone(
          booking.endTime,
          DEFAULT_PHARMACY_TIMEZONE,
          'YYYY-MM-DDTHH:mm:ss',
        ),
      })),
      appointments: state.appointments,
      totalCount: state.totalCount,
    }),
    shallow,
  );

  const [appointmentCounts, setAppointmentCounts] = useState<number | null>(
    null,
  );

  const loadAppointmentCounts = async () => {
    const counts = await AppointmentService.getAppointmentCounts(true);
    if (counts.total !== undefined) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setAppointmentCounts(counts.total);
    }
  };
  const { appointmentDetails } = useAppointmentsListState();

  useFocusEffect(
    useCallback(() => {
      collapseSidebar(true);
      resetAppointmentDetails();
      void loadAppointmentCounts();
      void getServices({ withDisabled: true, withoutNextAvailableSlot: true });
      calendarRef.current?.requestResize();

      return () => {
        setSelectedEvent(undefined);
        resetAppointmentDetails();
        setAppointmentVisibility(false);
      };
    }, []),
  );

  useFocusEffect(
    useCallback(() => {
      const calendarApi = calendarRef.current?.getApi();

      if (calendarApi) {
        const calendarStartDate = new Date(
          calendarApi.view.activeStart,
        ).toISOString();
        const calendarEndDate = new Date(
          calendarApi.view.activeEnd,
        ).toISOString();
        const currentTime = convertDateTimeFromUtcToPharmacyTimezone(
          moment(),
          DEFAULT_PHARMACY_TIMEZONE, // TODO: Change to pharmacy timezone when it's implemented
          'HH:mm:ss',
        );

        void getAppointments(
          {
            startStr: calendarStartDate,
            endStr: calendarEndDate,
          },
          filters,
        );

        calendarApi.scrollToTime(currentTime);
      }
    }, [filters, navigation]),
  );

  const { totalAppointmentServices, upcomingAppointmentServices } =
    groupByUniqueServicesAndCount(appointments) ?? {};

  const upcomingCount = upcomingAppointmentServices?.reduce((prev, current) => {
    return (prev += current.count);
  }, 0);

  const handleClick = (data: EventClickArg) => {
    handleClickEvent(data.event);
  };

  const handleClickEvent = (event: EventImpl) => {
    setAppointmentVisibility(true);
    setPatientSidebarDetails(undefined);
    collapseSidebar(false);
    showAppointmentDetails(event._def.publicId);
    setSelectedEvent(event._def.publicId);
    setTimeout(() => calendarRef.current?.requestResize(), 300);
  };

  const handleEventCancel = (bookingId: string) => {
    loadAppointmentCounts();
    cancelCalendarBooking(bookingId);
    handleSidebarCollapse();
  };

  const handleSidebarCollapse = () => {
    setSelectedEvent(undefined);
    setTimeout(() => calendarRef.current?.requestResize(), 300);
  };

  const emptyStateProps =
    services && services.length > 0
      ? {
          title: getText('book-first-appointment'),
          subtitles: [getText('schedule-an-appointment')],
          image: <NoAppointmentsImage />,
          buttonText: getText('new-appointment-button'),
          onButtonPress: () => navigation.navigate('new-appointment', {}),
          buttonLoggerId: 'new-appointment',
          secondaryButtonText: getText('change-availability'),
          secondaryButtonLoggerId: 'change-availability',
          onSecondaryButtonPress: () => navigation.navigate('availabilities'),
        }
      : {
          title: getText('welcome-to-services'),
          subtitles: [getText('setup-bookable-service')],
          image: <NoServicesImage />,
          buttonText: getText('new-service-button'),
          onButtonPress: () => navigation.navigate('new-service'),
          buttonLoggerId: 'new-service',
        };

  return (
    <>
      <View style={styles.flexOne}>
        {isLoading && <LoadingOverlay />}
        {appointmentCounts === null || appointmentCounts > 0 ? (
          <View style={{ flexDirection: 'row' }}>
            <View style={{ flex: 1 }}>
              {!!totalCount && (
                <View style={{ position: 'relative' }}>
                  <View
                    style={[
                      styles.appointmentsCount,
                      isCustomFilter && { top: 106 },
                    ]}
                  >
                    <ColoredBadge
                      label={`${totalCount} in total`}
                      color={theme.palette.gray[700]}
                      backgroundColor={theme.palette.gray[100]}
                      nativeID="total-calendar-monthly-events-tooltip"
                      textStyle={styles.badgeText}
                    />
                    <ColoredBadge
                      label={`${upcomingCount ?? 0} remaining`}
                      color={theme.palette.gray[700]}
                      backgroundColor={theme.palette.gray[100]}
                      nativeID="upcoming-calendar-monthly-events-tooltip"
                      textStyle={styles.badgeText}
                    />
                  </View>
                </View>
              )}
              {isCustomFilter && (
                <ToolbarContentBox
                  title={getText('appointments-filters-info-title')}
                  description={composeAppointmentFiltersMessage()}
                  style={{ marginBottom: theme.getSpacing(3), marginTop: 0 }}
                />
              )}
              <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                headerToolbar={{
                  left: 'prev,next today title',
                  right: 'dayGridMonth,timeGridWeek,timeGridDay',
                }}
                buttonText={{
                  today: getText('today'),
                  month: getText('month'),
                  week: getText('week'),
                  day: getText('day'),
                  list: getText('list'),
                }}
                height="78vh"
                initialView="dayGridMonth"
                editable={false}
                events={events}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                timeZone="UTC"
                datesSet={(event: DatesSetArg) =>
                  void getAppointments(event, filters)
                }
                slotDuration="00:15:00"
                nowIndicator={true}
                eventClick={handleClick}
                eventDidMount={(arg) => {
                  arg.el.id = arg.event.id;
                }}
                eventContent={(arg) => {
                  return innerContentRenderer(
                    arg,
                    handleClickEvent,
                    selectedEvent,
                  );
                }}
                ref={calendarRef}
              />
            </View>
            {(!!appointmentDetails || patientSidebarDetails) && (
              <AppointmentDetailsSidebar
                onCancel={handleEventCancel}
                onCollapse={handleSidebarCollapse}
              />
            )}
          </View>
        ) : (
          <EmptyState
            {...emptyStateProps}
            bulletPoints={[
              getText('patients-book-via-mobile'),
              getText('pharmacy-staff-can-also-book'),
              getText('use-pharmacy-hours-custom-availability'),
              getText('see-upcoming-appointments'),
            ]}
            bulletPointStyle={BulletPointStyle.Bullet}
          />
        )}
      </View>
      {totalAppointmentServices && (
        <Tooltip
          anchorId={`total-calendar-monthly-events-tooltip`}
          style={styles.tooltipPosition}
          text={totalAppointmentServices
            .map((service) => `${service.count} ${service.title}`)
            .join('\n')}
        />
      )}
      {upcomingAppointmentServices && (
        <Tooltip
          anchorId={`upcoming-calendar-monthly-events-tooltip`}
          style={styles.tooltipPosition}
          text={upcomingAppointmentServices
            .map((service) => `${service.count} ${service.title}`)
            .join('\n')}
        />
      )}
      {events &&
        events.map((event) => (
          <Tooltip
            anchorId={event.id}
            style={styles.tooltipPosition}
            text={event.title}
          />
        ))}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  appointmentsCount: {
    position: 'absolute',
    gap: theme.getSpacing(1) + theme.getSpacing(0.5),
    zIndex: 10,
    right: 195,
    top: 12,
    display: 'flex',
    flexDirection: 'row',
  },
  cellContainer: {
    marginTop: theme.getSpacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },
  flexOne: {
    flex: 1,
  },
  options: {
    flexDirection: 'row',
  },
  optionsLabel: {
    flex: 9,
  },
  control: {
    borderRadius: theme.roundness,
    fontFamily: theme.fonts.regular.fontFamily,
    width: 255,
    textAlign: 'left',
    zIndex: 10,
  },
  valueContainerPadding: {
    paddingTop: 6,
    paddingBottom: 6,
  },
  badgeText: {
    ...theme.fonts.regular,
    fontSize: 14,
    paddingLeft: theme.getSpacing(1),
    paddingRight: theme.getSpacing(1),
    cursor: 'pointer',
  },
  tooltipPosition: {
    display: 'flex',
    flexDirection: 'column',
    opacity: 1,
    backgroundColor: theme.palette.black,
    zIndex: 10,
    maxWidth: 400,
  },
  textColor: {
    color: theme.palette.white,
  },
  calendarContainer: {
    flexDirection: 'row',
    flex: 1,
  },
  sidebarContainer: {
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    height: '100%',
    zIndex: 1,
  },
}));
