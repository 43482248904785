import React, { FunctionComponent, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { DataGrid } from 'assets/components/data-grid';
import { WidgetContainer } from '../widget-container/WidgetContainer';
import { useAppStateStore } from '../../../../store/app-store';
import { RefillSubmittedDto } from '@digitalpharmacist/prescription-service-client-axios';
import { Icon } from 'assets/components/icon';
import { CalendarIcon } from 'assets/icons';
import { useTheme } from 'assets/theme';
import { getText } from 'assets/localization/localization';
import { DateFieldRenderer } from '../../../refills/Refills';
import { RootStackNavigationProp } from '../../../../navigation/RootNavigation';
import {
  BirthDateRenderer,
  FormNameRenderer,
  PatientRenderer,
} from '../../../../forms/submissions-data-table/SubmissionsDataTable';
import { SubmissionMetadataDto } from '@digitalpharmacist/forms-service-client-axios';
import FormsService from '../../../../api/FormsService';
import { calculateGridHeight } from '../../utils';
import { NewFormSubmissionsEmptyStateIcon } from '../icons/NewFormSubmissionsEmptyStateIcon';
import { EmptyState } from '../empty-state/EmptyState';

export const NewFormSubmissionsWidget: FunctionComponent<
  NewFormSubmissionsWidgetProps
> = () => {
  const MAX_ROW_NUMBERS = 5;
  const navigation = useNavigation<RootStackNavigationProp>();
  const theme = useTheme();
  const locationId = useAppStateStore((x) => x.locationId);
  const [data, setData] = useState<RefillSubmittedDto[] | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const [moreItems, setMoreItem] = useState<number | undefined>();
  const [height, setHeight] = useState<number>(200);

  useEffect(() => {
    void (async () => {
      // resetting state on location change

      try {
        setLoading(true);
        setMoreItem(undefined);
        setData(undefined);
        const { data } = await FormsService.getFormSubmissionsForDashboard(
          locationId,
          MAX_ROW_NUMBERS,
        );
        setData(data.results);
        if (data.total > MAX_ROW_NUMBERS)
          setMoreItem(data.total - MAX_ROW_NUMBERS);
        if (data.results.length > 0)
          setHeight(calculateGridHeight(data.results.length));
        setLoading(false);
      } catch {
        // show empty state in case of errors
        setLoading(false);
      }
    })();
  }, [locationId]);

  return (
    <WidgetContainer
      icon={
        <Icon icon={CalendarIcon} size={24} color={theme.palette.gray[700]} />
      }
      title={getText('form-submissions')}
      onNavigate={() =>
        navigation.navigate('forms', { screen: 'submissions-list' } as any)
      }
      moreItems={moreItems}
      loading={loading}
    >
      {data && data.length > 0 ? (
        <View
          style={{
            height: height,
          }}
        >
          <DataGrid
            gridOptions={{
              columnDefs: [
                {
                  maxWidth: 320,
                  field: 'name',
                  cellRenderer: PatientRenderer,
                  headerName: getText('patient'),
                  cellStyle: {
                    display: 'flex',
                  },
                },
                {
                  maxWidth: 200,
                  field: 'date_of_birth',
                  headerName: getText('dob-age'),
                  cellRenderer: BirthDateRenderer,
                },
                {
                  field: 'form_name',
                  headerName: getText('form-name'),
                  cellRenderer: FormNameRenderer,
                },
                {
                  maxWidth: 220,
                  field: 'created_at',
                  headerName: getText('submitted'),
                  cellRenderer: DateFieldRenderer,
                  headerClass: 'data-grid-header-right-aligned',
                  cellStyle: {
                    display: 'flex',
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  },
                },
              ],
              rowData: data,
              enableCellTextSelection: true,
              suppressMovableColumns: true,
              suppressContextMenu: true,
              defaultColDef: {
                sortable: false,
                menuTabs: [],
              },
              rowModelType: 'clientSide',
              onRowClicked: (row: { data: SubmissionMetadataDto }) => {
                navigation.navigate('forms', {
                  screen: 'submission-view',
                  params: {
                    submissionId: row.data.submission_id,
                    formId: row.data.form_id,
                  },
                } as any);
              },
              onGridSizeChanged(event) {
                event.api.sizeColumnsToFit();
              },
            }}
          />
        </View>
      ) : (
        <EmptyState
          Icon={NewFormSubmissionsEmptyStateIcon}
          iconProps={{
            size: 75,
            color: theme.palette.primary[600],
          }}
          text={getText('no-submission-found')}
        />
      )}
    </WidgetContainer>
  );
};

export interface NewFormSubmissionsWidgetProps {}
