import { useAppStateStore } from '../store/app-store';
import {
  LocationCategory,
  PharmacyCategory,
  PublicCategory,
} from '@digitalpharmacist/file-storage-service-client-axios';
import FileStorageService from '../api/FileStorageService';

export const uploadPublicLocationFile = (
  fileName: string,
  file: File | string,
): Promise<string | undefined> => {
  const { pharmacyId, locationId } = useAppStateStore.getState();
  return FileStorageService.uploadPublicLocationLevelFile(
    PublicCategory.Branding,
    locationId,
    fileName,
    pharmacyId,
    file,
  );
};

export const fetchPublicLocationFileUrl = (
  fileName: string,
): Promise<string | undefined> => {
  const { pharmacyId, locationId } = useAppStateStore.getState();
  return FileStorageService.getPublicLocationLevelFile(
    PublicCategory.Branding,
    locationId,
    fileName,
    pharmacyId,
  );
};

export const uploadPublicPharmacyFile = (
  fileName: string,
  file: File | string,
): Promise<string | undefined> => {
  const { pharmacyId } = useAppStateStore.getState();
  return FileStorageService.uploadPublicPharmacyLevelFile(
    PublicCategory.Branding,
    fileName,
    pharmacyId,
    file,
  );
};

export const fetchPublicPharmacyFile = (
  fileName: string,
): Promise<string | undefined> => {
  const { pharmacyId } = useAppStateStore.getState();
  return FileStorageService.getPublicPharmacyLevelFile(
    PublicCategory.Branding,
    fileName,
    pharmacyId,
  );
};

export const uploadPrivateLocationFile = (
  category: LocationCategory,
  fileName: string,
  file: File | string,
): Promise<string | undefined> => {
  const { pharmacyId, locationId } = useAppStateStore.getState();
  return FileStorageService.uploadPrivateLocationLevelFile(
    category,
    locationId,
    fileName,
    pharmacyId,
    file,
  );
};

export const fetchPrivateLocationFile = (
  category: LocationCategory,
  fileName: string,
): Promise<string | undefined> => {
  const { pharmacyId, locationId } = useAppStateStore.getState();
  return FileStorageService.getPrivateLocationLevelFile(
    category,
    locationId,
    fileName,
    pharmacyId,
  );
};

export const uploadPrivatePharmacyFile = (
  category: PharmacyCategory,
  fileName: string,
  file: File | string,
): Promise<string | undefined> => {
  const { pharmacyId } = useAppStateStore.getState();
  return FileStorageService.uploadPrivatePharmacyLevelFile(
    category,
    fileName,
    pharmacyId,
    file,
  );
};

export const fetchPrivatePharmacyFile = (
  category: PharmacyCategory,
  fileName: string,
): Promise<string | undefined> => {
  const { pharmacyId } = useAppStateStore.getState();
  return FileStorageService.getPrivatePharmacyLevelFile(
    category,
    fileName,
    pharmacyId,
  );
};

export const uploadPatientAgreements = (
  filename: string,
  uri: string,
): Promise<string | undefined> => {
  const { pharmacyId, locationId } = useAppStateStore.getState();
  return FileStorageService.uploadPublicLocationLevelFile(
    'branding',
    locationId,
    filename,
    uri,
    pharmacyId,
  );
};
