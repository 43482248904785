import {
  ChecklistOptionDto,
  AppSettingsDto,
  WebsiteWizardResponseDto,
  FinalizeWebsiteLocationDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { create } from './store-creator';
import { ApiError, AsyncStatus } from './types';

export type PatientAgreementType = {
  terms: BlobPart;
  privacy: BlobPart;
};

export type ChecklistModalIds =
  | 'checklist-setup'
  | 'patient-agreements'
  | 'patient-app'
  | 'media-settings'
  | 'color-picker'
  | 'patient-website'
  | 'website-access-setup'
  | 'website-access-completed'
  | 'website-access-permission'
  | 'patient-website-services'
  | 'patient-website-service-details'
  | 'patient-website-compounding-details'
  | 'patient-website-delivery-details'
  | 'patient-website-about-us-details'
  | 'patient-website-additional-info'
  | 'patient-website-template'
  | 'patient-website-notification'
  | 'patient-website-complete'
  | 'patient-website-locations'
  | 'patient-website-location-desc'
  | 'message-settings'
  | 'patient-website-location-details';

export interface ChecklistState {
  status: AsyncStatus;
  error?: ApiError;
  currentOpenedModalId?: ChecklistModalIds;
  brandColor?: string;
  checklistOptions: ChecklistOptionDto[];
  appSettings?: AppSettingsDto;
  dunsList?: Array<string>;
  patientAgreementsDocs?: PatientAgreementType;
  patientWebsiteData?: WebsiteWizardResponseDto;
  websiteLocations?: FinalizeWebsiteLocationDto[];
  currentOpenedLocationId?: string;
}

export const useChecklistStore = create<ChecklistState>(() => ({
  status: 'idle',
  checklistOptions: [],
}));
