import React, { FunctionComponent, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useFieldArray } from 'react-hook-form';
import { View } from 'react-native';
import { IconButton } from 'assets/components/icon-button';
import { Modal } from 'assets/components/modal';
import { useForm } from 'assets/form';
import { PlusIcon, TrashIcon } from 'assets/icons';

import { Form } from 'assets/layout';
import { makeStyles } from 'assets/theme';
import { zIndexAuto } from '../../common/theme';
import { TimeRangeInput } from '../time-range-input/TimeRangeInput';
import { setOverrideData } from './availability-form-actions';
import { useAvailabilityFormState } from './availability-form-store';
import { AvailabilityHours } from './AvailabilityForm';
import {
  DEFAULT_DATE_TIME_API_FORMAT,
  formatDateTimeWithTimezone,
} from '../../common/datetime-utils';
import { getText } from 'assets/localization/localization';

export const DateOverrideModal: FunctionComponent<DateOverrideModalProps> = ({
  show,
  onClose,
  onSubmit,
}) => {
  const methods = useForm<DateOverrideFormData>({
    defaultValues: {
      overrideTime: [
        {
          start: '',
          end: '',
        },
      ],
    },
  });
  const [startDate, setStartDate] = useState<Date>();
  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'overrideTime',
  });
  const styles = useStyles();
  const { overrideData } = useAvailabilityFormState();

  useEffect(() => {
    if (overrideData) {
      const formData: DateOverrideFormData = {
        overrideTime: overrideData.time.map((time) => ({
          start: formatDateTimeWithTimezone(
            time!.start,
            new Date(time!.start).getTimezoneOffset(),
            DEFAULT_DATE_TIME_API_FORMAT,
          ),
          end: formatDateTimeWithTimezone(
            time!.end,
            new Date(time!.end).getTimezoneOffset(),
            DEFAULT_DATE_TIME_API_FORMAT,
          ),
        })),
      };

      methods.reset(formData);

      setStartDate(new Date(overrideData.date));
    }
  }, [overrideData]);

  const onChange = (date: Date) => {
    setStartDate(date);
  };

  const handleSubmit = () => {
    onSubmit(
      methods.getValues().overrideTime,
      startDate ? startDate.toString() : new Date().toString(),
    );
    remove();
    append({ start: '', end: '' });
  };

  const handleAddTimeRange = () => {
    append({ start: '', end: '' });
  };

  const handleClose = () => {
    setStartDate(undefined);
    setOverrideData(undefined);
    onClose();
    remove();
    append({ start: '', end: '' });
  };

  const handleDeleteTimeRange = (index: number) => {
    const inputs = methods.getValues().overrideTime.map((input, inputIndex) => {
      if (inputIndex > index) {
        return {
          id: inputIndex,
          value: input,
        };
      }
    });

    inputs.map((input) => {
      if (input) {
        // need to set start and end time values explicitly to trigger a change
        methods.setValue(
          `overrideTime.${input.id - 1}.start`,
          input.value?.start ? input.value.start : '',
        );
        methods.setValue(
          `overrideTime.${input.id - 1}.end`,
          input.value?.end ? input.value.end : '',
        );
      }
    });

    remove(index);
  };

  return (
    <Modal
      title={getText('edit-date-override')}
      size="lg"
      cancelButtonProps={{
        onPress: () => handleClose(),
        logger: { id: 'edit-date-override-modal-button-cancel' },
      }}
      okButtonProps={{
        onPress: methods.handleSubmit(handleSubmit),
        logger: { id: 'edit-date-override-modal-button-ok' },
        hierarchy: 'primary',
        text: getText('ok'),
        disabled: status === 'loading',
      }}
      show={show}
      isScrollable={true}
    >
      <Form methods={methods}>
        <Form.Row style={{ alignItems: 'flex-start' }}>
          <Form.Column>
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              inline
              wrapperClassName="datepicker--date"
            />
          </Form.Column>
          <Form.Column style={{ flex: 1 }}>
            {fields.map((field, index) => (
              <View style={styles.timeOverride}>
                <TimeRangeInput
                  name={`overrideTime.${index}`}
                  key={index}
                  startTimeRules={{
                    required: getText('start-time-required'),
                  }}
                  endTimeRules={{
                    required: getText('end-time-required'),
                  }}
                />
                {index === 0 ? (
                  <IconButton
                    icon={PlusIcon}
                    logger={{ id: `add-availability-override-time` }}
                    onPress={() => handleAddTimeRange()}
                  />
                ) : (
                  <IconButton
                    icon={TrashIcon}
                    logger={{ id: `delete-availability-override-time` }}
                    onPress={() => handleDeleteTimeRange(index)}
                    strokeWidth={2}
                  />
                )}
              </View>
            ))}
          </Form.Column>
        </Form.Row>
      </Form>
    </Modal>
  );
};

export interface DateOverrideFormData {
  overrideTime: AvailabilityHours[];
}

export interface DateOverrideModalProps {
  show: boolean;
  onClose: () => void;
  onSubmit: (time: AvailabilityHours[], date: string) => void;
}

const useStyles = makeStyles((theme) => ({
  timeOverride: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    zIndex: zIndexAuto,
    marginBottom: theme.getSpacing(2),
  },
}));
