import { MessageConfigType } from '@digitalpharmacist/unified-communications-service-client-axios';
import { getText } from 'assets/localization/localization';

export enum AutomatedMessageConfigSection {
  General = 'General',
  Seasonal = 'Seasonal',
}

export const AutomationConfig: Record<
  MessageConfigType,
  {
    title: string;
    description: string;
    section: AutomatedMessageConfigSection;
    subject: string;
    messageCopy: string;
  }
> = {
  [MessageConfigType.HappyBirthday]: {
    title: getText('happy-birthday'),
    description: getText('happy-birthday-description'),
    section: AutomatedMessageConfigSection.General,
    subject: getText('happy-birthday-message-subject'),
    messageCopy: getText('happy-birthday-message-body'),
  },
  [MessageConfigType.InsuranceCardUpdate]: {
    title: getText('insurance-card-update'),
    description: getText('insurance-card-update-description'),
    section: AutomatedMessageConfigSection.General,
    subject: getText('insurance-card-update-message-subject'),
    messageCopy: getText('insurance-card-update-message-body'),
  },
  [MessageConfigType.FluShotReminder]: {
    title: getText('flu-shot-reminder'),
    description: getText('flu-shot-reminder-description'),
    section: AutomatedMessageConfigSection.Seasonal,
    subject: getText('flu-shot-reminder-message-subject'),
    messageCopy: getText('flu-shot-reminder-message-body'),
  },
  [MessageConfigType.SpringPromotions]: {
    title: getText('spring-promotions'),
    description: getText('spring-promotions-description'),
    section: AutomatedMessageConfigSection.Seasonal,
    subject: getText('spring-promotions-message-subject'),
    messageCopy: getText('spring-promotions-message-body'),
  },
  [MessageConfigType.SummerPromotions]: {
    title: getText('summer-promotions'),
    description: getText('summer-promotions-description'),
    section: AutomatedMessageConfigSection.Seasonal,
    subject: getText('summer-promotions-message-subject'),
    messageCopy: getText('summer-promotions-message-body'),
  },
  [MessageConfigType.FallPromotions]: {
    title: getText('fall-promotions'),
    description: getText('fall-promotions-description'),
    section: AutomatedMessageConfigSection.Seasonal,
    subject: getText('fall-promotions-message-subject'),
    messageCopy: getText('fall-promotions-message-body'),
  },
  [MessageConfigType.Thanksgiving]: {
    title: getText('thanksgiving'),
    description: getText('thanksgiving-description'),
    section: AutomatedMessageConfigSection.Seasonal,
    subject: getText('thanksgiving-message-subject'),
    messageCopy: getText('thanksgiving-message-subject'),
  },
  [MessageConfigType.SeasonsGreetings]: {
    title: getText('seasons-greeting'),
    description: getText('seasons-greeting-description'),
    section: AutomatedMessageConfigSection.Seasonal,
    subject: getText('seasons-greeting-message-subject'),
    messageCopy: getText('seasons-greeting-message-body'),
  },
  [MessageConfigType.HappyNewYear]: {
    title: getText('happy-new-year'),
    description: getText('happy-new-year-description'),
    section: AutomatedMessageConfigSection.Seasonal,
    subject: getText('happy-new-year-message-subject'),
    messageCopy: getText('happy-new-year-message-body'),
  },
  appointment: {
    title: '',
    description: '',
    section: AutomatedMessageConfigSection.Seasonal,
    subject: '',
    messageCopy: '',
  },
  delivery_in_progress: {
    title: '',
    description: '',
    section: AutomatedMessageConfigSection.Seasonal,
    subject: '',
    messageCopy: '',
  },
  ready_for_shipping: {
    title: '',
    description: '',
    section: AutomatedMessageConfigSection.Seasonal,
    subject: '',
    messageCopy: '',
  },
};
