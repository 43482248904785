import { ScheduleAvailabilityDto } from '@digitalpharmacist/appointment-service-client-axios';
import { getText } from 'assets/localization/localization';
import get from 'lodash.get';
import moment, { Moment } from 'moment-timezone';

export const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY',
  DEFAULT_DATE_TIME_FORMAT = 'MM/DD/YYYY hh:mm a',
  DEFAULT_TIME_DATE_FORMAT = 'hh:mm A MM/DD/YYYY',
  DEFAULT_DATE_TIME_API_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
  DEFAULT_TIME_FORMAT = 'hh:mm A',
  TIME_DATE_FORMAT_WITH_LONGER_SPACE = 'hh:mm A   MM/DD/YYYY',
  // The following date format is used for validation by the isMatch method
  DOBDateFormat = 'MM/dd/yyyy',
  FullCalendarEventDateTimeFormat = 'YYYY-MM-DDThh:mm:ss[Z]',
  AUTO_CONFIG_TIME_FORMAT = 'HH:mm',
  SHORT_DATE_FORMAT = 'l'; // M/D/YYYY

// Setting CST timezone as a default one for Pharmacies
export const DEFAULT_PHARMACY_TIMEZONE = 'America/Chicago';

/**
 * Formats a date string to the preferred format
 */
export const formatDate = (
  valueToFormat: string,
  format: string = DEFAULT_DATE_FORMAT,
) => {
  return moment(valueToFormat).format(format);
};

export const isDateValid = (
  dateString: string,
  dateFormat = DEFAULT_DATE_FORMAT,
) => {
  return moment(dateString, dateFormat, true).isValid();
};

// Formats a date string to the preferred format
export const formatDateTime = (
  valueToFormat: string,
  format: string = DEFAULT_DATE_TIME_FORMAT,
) => {
  /*
  By default moment parses strings in local time
  this means that times without specified timezone are treated as local time
  by using `utc`, if there is no timezone specified time will be considered UTC
  after the parsing, we convert it to local time for the user, before formatting for display
  https://momentjs.com/docs/#/parsing/utc/
  */
  return moment.utc(valueToFormat).local().format(format);
};

// Formats a date string to the preferred format without converting it to the local time of the user
export const formatDateTimeUtc = (
  valueToFormat: string,
  format: string = DEFAULT_DATE_TIME_FORMAT,
) => {
  return moment.utc(valueToFormat).format(format);
};

export const formatTimeDate = (
  valueToFormat: string,
  format: string = DEFAULT_TIME_DATE_FORMAT,
) => {
  /*
  By default moment parses strings in local time
  this means that times without specified timezone are treated as local time
  by using `utc`, if there is no timezone specified time will be considered UTC
  after the parsing, we convert it to local time for the user, before formatting for display
  https://momentjs.com/docs/#/parsing/utc/
  */
  return moment.utc(valueToFormat).local().format(format);
};

// Format date to the format expected by the API
export const formatDateTimeApi = (dateToFormat: Moment): string => {
  return moment.utc(dateToFormat).format(DEFAULT_DATE_TIME_API_FORMAT);
};

/*
These methods for relative time formatting are taken from DP1
https://github.com/digitalpharmacist/pharmacy-experience-front-end/blob/develop/src/utils/formatTime.js

While the core of the logic is here (calculating the diff and manipulating moment dates)
typing is added, formatting is changed and rules are applied differently
*/
export const pluralize = (count?: number): string => {
  return count && count > 1 ? 's' : '';
};

export const isDateInCurrentYear = (inputTimeString: string) => {
  const localInputTime = moment.utc(inputTimeString).local();
  const localBaseTime = moment.utc().local();
  return localBaseTime.year() == localInputTime.year();
};

export const isDateBefore = (firstDate: string, secondDate: string) =>
  moment(firstDate).isBefore(secondDate);

export const calculatePatientAge = (dateOfBirth: string): number => {
  const transformedDateOfBirth = moment(dateOfBirth);
  const currentDate = moment().local();

  return currentDate.diff(transformedDateOfBirth, 'years');
};

const timeFormatByCountryCode = {
  US: {
    sameDay: 'h:mm a',
    withinDays: 'ddd, h:mm a',
    lessThanYear: 'MMM D, h:mm a',
    lessThanYearShort: 'MMM D',
    greaterThanYear: 'MMM D, YYYY h:mm a',
  },
};

// Creating 'start of day' references to avoid weird dayDiff logic, startOf mutates the moment object requiring fresh moment instances
// This prevents formatting to switch on what a user thinks is the same day.
// For example: A message sent 3 days ago at 11:43 local time, will show in one format at 11:42, but show in a different format at 11:44 today
const createStartOfDay = (inputDate: moment.Moment): moment.Moment =>
  moment.utc(inputDate.toISOString()).local().startOf('day');

// Expecting local moment dates
const calculateDifferenceBetweenMomentDates = (
  startDate: moment.Moment,
  endDate: moment.Moment = moment().local(),
): DatesDifference => {
  const startOfDayForStartDate = createStartOfDay(startDate);
  const startOfDayForEndDate = createStartOfDay(endDate);

  return {
    minutesDifference: endDate.diff(startDate, 'minutes'),
    daysDifference: endDate.diff(startDate, 'days'),
    startOfDayDifference: startOfDayForEndDate.diff(
      startOfDayForStartDate,
      'days',
    ),
    hoursDifference: endDate.diff(startDate, 'hours'),
    yearsDifference: endDate.diff(startDate, 'years'),
    isSameYear: endDate.year() == startDate.year(),
    isSameDay: endDate.day() == startDate.day(),
  };
};

// These rules apply if the date is strictly in the past (eg. time of creation)
const formatPastDateBasedOnDifference = (
  inputDate: moment.Moment,
  diff: DatesDifference,
  countryCode: 'US' = 'US',
  useShort?: boolean,
): string => {
  const formats = get(
    timeFormatByCountryCode,
    countryCode,
    timeFormatByCountryCode[countryCode],
  );

  if (diff.minutesDifference < 1) {
    // Less than a minute ago
    // `Now`
    return 'Now';
  } else if (diff.hoursDifference < 1) {
    // Less than 1 hour ago
    // `39 minutes ago`
    return `${diff.minutesDifference} minute${pluralize(
      diff.minutesDifference,
    )} ago`;
  } else if (diff.hoursDifference < 6) {
    // Less than 6 hours ago
    // `5 hours ago`
    return `${diff.hoursDifference} hour${pluralize(diff.hoursDifference)} ago`;
  } else if (diff.daysDifference < 1) {
    // Still on the same day
    // `5:32 pm`
    return inputDate.format(formats.sameDay);
  } else if (diff.startOfDayDifference <= 3) {
    // Within 3 days ago, utilizing start of day to avoid mid-day display switching for the same date
    // `Tue, 5:32 pm`
    return inputDate.format(formats.withinDays);
  } else if (diff.yearsDifference < 1 && useShort) {
    // Less than a year ago short format
    // `Jan 3`
    return inputDate.format(formats.lessThanYearShort);
  } else if (diff.yearsDifference < 1) {
    // Less than a year ago
    // `Jan 3, 5:32 pm`
    return inputDate.format(formats.lessThanYear);
  } else {
    // Over a year
    // `Jan 3, 2023 5:32 pm` (message list)
    return inputDate.format(formats.greaterThanYear);
  }
};

// formatting datetime that can be in the future or the past
const formatDateBasedOnDifference = (
  inputDate: moment.Moment,
  diff: DatesDifference,
  countryCode: 'US' = 'US',
  useShort?: boolean,
): string => {
  const formats = get(
    timeFormatByCountryCode,
    countryCode,
    timeFormatByCountryCode[countryCode],
  );

  // if in the past
  if (diff.minutesDifference > 0) {
    if (diff.daysDifference < 1 && diff.isSameDay) {
      return inputDate.format(formats.sameDay);
    } else if (diff.isSameYear && useShort) {
      return inputDate.format(formats.lessThanYearShort);
    } else if (diff.isSameYear) {
      return inputDate.format(formats.lessThanYear);
    } else {
      return inputDate.format(formats.greaterThanYear);
    }
  } else {
    // if in the future
    if (Math.abs(diff.daysDifference) < 1 && diff.isSameDay) {
      // Still on the same day
      // `5:32 pm`
      return inputDate.format(formats.sameDay);
    } else if (Math.abs(diff.startOfDayDifference) <= 6) {
      // Within 6 days in the future, utilizing start of day to avoid mid-day display switching for the same date
      // `Tue, 5:32 pm`
      return inputDate.format(formats.withinDays);
    } else if (diff.isSameYear && useShort) {
      // in the same year short format
      // `Jan 3`
      return inputDate.format(formats.lessThanYearShort);
    } else if (diff.isSameYear) {
      // in the same year
      // `Jan 3, 5:32 pm`
      return inputDate.format(formats.lessThanYear);
    } else {
      // not the same year
      // `Jan 3, 2023 5:32 pm`
      return inputDate.format(formats.greaterThanYear);
    }
  }
};

export const formatUTCToRelative = (
  inputTimeString: string,
  baseTime = moment(),
  inputDateCanBeInFuture = false,
  countryCode: 'US' = 'US',
  utcOffset?: string,
  useShort?: boolean,
  notLocal?: boolean,
) => {
  const inputTime = utcOffset
    ? moment(inputTimeString).utcOffset(utcOffset)
    : moment.utc(inputTimeString);
  const localInputTime = notLocal ? inputTime : inputTime.local();
  const localBaseTime = baseTime.local();

  const diff = calculateDifferenceBetweenMomentDates(
    localInputTime,
    localBaseTime,
  );

  return inputDateCanBeInFuture
    ? formatDateBasedOnDifference(localInputTime, diff, countryCode, useShort)
    : formatPastDateBasedOnDifference(
        localInputTime,
        diff,
        countryCode,
        useShort,
      );
};

export const formatTimeSpan = (startTime: string, endTime: string) => {
  return `${formatDateTimeUtc(startTime, 'h:mm A')} - ${formatDateTimeUtc(
    endTime,
    'h:mm A',
  )}`;
};

export function scheduleAvailabilityToString(
  availability: ScheduleAvailabilityDto,
) {
  const weekSpan = (availability: ScheduleAvailabilityDto) => {
    const days = availability.days.slice(1).reduce(
      (days, day) => {
        if (
          days[days.length - 1].length === 1 &&
          days[days.length - 1][0] === day - 1
        ) {
          days[days.length - 1].push(day);
        } else if (
          days[days.length - 1][days[days.length - 1].length - 1] ===
          day - 1
        ) {
          days[days.length - 1] = [days[days.length - 1][0], day];
        } else {
          days.push([day]);
        }
        return days;
      },
      [[availability.days[0]]] as number[][],
    );
    return days
      .map((dayRange) =>
        dayRange
          .map((day) => formatDate(moment().day(day).toString(), 'ddd'))
          .join(' - '),
      )
      .join(', ');
  };

  const timeSpan = formatTimeSpan(availability.startTime, availability.endTime);

  return weekSpan(availability) + ' ' + timeSpan;
}

// Format date with timezone as UTC offset
export const formatDateTimeWithTimezone = (
  dateToFormat: string | Moment,
  utcOffset: string | number,
  format: string,
): string => {
  return moment(dateToFormat).utcOffset(utcOffset).format(format);
};

// Convert UTC time to Pharmacy timezone and format
export const convertDateTimeFromUtcToPharmacyTimezone = (
  dateToFormat: string | Moment,
  pharmacyTimezone: string,
  format: string,
): string => {
  return moment.utc(dateToFormat).tz(pharmacyTimezone).format(format);
};

export const convertDateTimeFromPharmacyToUtcTimezone = (
  dateToFormat: string,
  dateFormat: string,
  pharmacyTimezone: string,
  format: string,
): string => {
  return moment
    .tz(dateToFormat, dateFormat, pharmacyTimezone)
    .utc()
    .format(format);
};

function pad2Number(min: number): string {
  return String(min).padStart(2, '0');
}

export function convertTimeRangeToISOString(
  timeRangeDto: NormalTimeRange,
): TimeRangeForm {
  const { start_hour, start_minute, end_hour, end_minute } = timeRangeDto;
  const startDate = new Date(0);
  startDate.setHours(start_hour);
  startDate.setMinutes(start_minute);
  const endDate = new Date(0);
  endDate.setHours(end_hour);
  endDate.setMinutes(end_minute);

  return { start: startDate.toISOString(), end: endDate.toISOString() };
}

export function extractHoursAndMinutesFromISOString(isoDateString: string) {
  const date = new Date(isoDateString);
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return { hours, minutes };
}

export const formatDateWithoutTime = (
  dateString: string,
  format: string = DEFAULT_DATE_FORMAT,
) => {
  const date = moment.utc(dateString);
  return date.format(format);
};

export function convertTimeRangeToStringRange(
  timeRangeDto: NormalTimeRange,
): string {
  const { start_hour, start_minute, end_hour, end_minute } = timeRangeDto;
  if (start_hour === end_hour && start_minute === end_minute) {
    return `${getText('closed')}`;
  } else {
    return `${start_hour % 12 ? start_hour % 12 : 12}:${pad2Number(
      start_minute,
    )} ${start_hour >= 12 ? 'PM' : 'AM'} - ${
      end_hour % 12 ? end_hour % 12 : 12
    }:${pad2Number(end_minute)} ${end_hour >= 12 ? 'PM' : 'AM'}`;
  }
}

export const convertClosuresDateToString = (closureDate: NormalTimeRange) => {
  const dateString: string = closureDate.date
    ? formatDateWithoutTime(closureDate.date)
    : '';
  return `${dateString} ${convertTimeRangeToStringRange(closureDate)}`;
};

export const convertHoursToMinutes = (value: string) => {
  const { hours, minutes } = extractHoursAndMinutesFromISOString(value);
  return hours * 60 + minutes;
};

function toIsoString(dateString: string) {
  const date = new Date(dateString);
  const tzo = -date.getTimezoneOffset();
  const dif = tzo >= 0 ? '+' : '-';
  const pad = (num: number) => {
    return (num < 10 ? '0' : '') + num;
  };

  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    'T' +
    pad(date.getHours()) +
    ':' +
    pad(date.getMinutes()) +
    ':' +
    pad(date.getSeconds()) +
    dif +
    pad(Math.floor(Math.abs(tzo) / 60)) +
    ':' +
    pad(Math.abs(tzo) % 60)
  );
}

export const formatTimestampDate = (valueToFormat: string) => {
  // toIsoString() here to remove
  // Deprecation warning: value provided is not in a recognized ISO format.
  // moment construction falls back to js Date(), which is not reliable across all browsers and versions.
  // Non ISO date formats are discouraged and will be removed in an upcoming major release.
  // Please refer to http://momentjs.com/guides/#/warnings/js-date/ for more info.
  const dateTime = moment(toIsoString(valueToFormat)).calendar(null, {
    sameDay: '[Today] h:mm A',
    lastDay: '[Yesterday] h:mm A',
    lastWeek: 'dddd h:mm A',
    sameElse: 'ddd, MMM D [at] h:mm A',
  });
  return dateTime;
};

export const timeAugmenter = (
  value: Date | number | undefined,
  unit: moment.unitOfTime.DurationConstructor,
  quantity: number,
) => {
  return moment(value).add(quantity, unit).toDate();
};

export const getToday = () => {
  const today = new Date();
  today.setHours(23, 59);

  return today.toISOString();
};

export function timesOverlap(intervals: TimeRangeForm[]): boolean {
  const intervalsInMinutes = intervals.map((interval) => ({
    start:
      extractHoursAndMinutesFromISOString(interval.start).hours * 60 +
      extractHoursAndMinutesFromISOString(interval.start).minutes,
    end:
      extractHoursAndMinutesFromISOString(interval.end).hours * 60 +
      extractHoursAndMinutesFromISOString(interval.end).minutes,
  }));

  intervalsInMinutes.sort((a, b) => a.start - b.start);

  for (let i = 0; i < intervalsInMinutes.length - 1; i++) {
    if (intervalsInMinutes[i].end > intervalsInMinutes[i + 1].start) {
      return true;
    }
  }
  return false;
}

export const monthOptions = [
  { value: '1', label: getText('january') },
  { value: '2', label: getText('february') },
  { value: '3', label: getText('march') },
  { value: '4', label: getText('april') },
  { value: '5', label: getText('may') },
  { value: '6', label: getText('june') },
  { value: '7', label: getText('july') },
  { value: '8', label: getText('august') },
  { value: '9', label: getText('september') },
  { value: '10', label: getText('october') },
  { value: '11', label: getText('november') },
  { value: '12', label: getText('december') },
];

// Generate an array of day options for a dropdown, with values and labels from 1 to 31.
export function createDayOptions(length: number) {
  const options = Array.from(new Array(length), (_value, index) => ({
    value: (index + 1).toString(),
    label: (index + 1).toString(),
  }));
  return options;
}

interface DatesDifference {
  minutesDifference: number;
  daysDifference: number;
  hoursDifference: number;
  yearsDifference: number;
  startOfDayDifference: number;
  isSameYear: boolean;
  isSameDay: boolean;
}
export interface TimeRangeForm {
  start: string;
  end: string;
}

interface NormalTimeRange {
  start_hour: number;
  start_minute: number;
  end_hour: number;
  end_minute: number;
  date?: string;
}
