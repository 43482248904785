import React, { FunctionComponent } from 'react';
import {
  Controller,
  RegisterOptions,
  Validate,
  useFormContext,
} from 'react-hook-form';
import { makeStyles, useTheme } from 'assets/theme';
import { Text } from 'assets/components/text';
import { TextField } from 'assets/components/text-field';
import { getText } from 'assets/localization/localization';
import { getNestedObjectValue } from 'assets/utils/common';
import { View } from 'react-native';
import { Icon } from 'assets/components/icon';
import { AlertTriangleIcon } from 'assets/icons';
import { US_STATES_OPTIONS } from 'assets/utils/states';
import { Form } from 'assets/layout';
import { DropdownSelectField } from 'assets/components/dropdown-select';
import * as validate from '@digitalpharmacist/validation-dp';

const address1ValidationRule = (value: string | undefined) =>
  !value || (value?.trim() && validate.isAddress(value))
    ? true
    : getText('street-1-is-not-valid');

const address2ValidationRule = (value: string | undefined | null) =>
  value === ''
    ? true
    : value && value?.trim() && validate.isAddress(value)
    ? true
    : getText('street-2-is-not-valid');

const cityValidationRule = (
  value: Validate<any> | Record<string, Validate<any>> | undefined,
) => (!value || validate.isName(value) ? true : getText('city-is-not-valid'));

const postalCodeValidationRule = (
  value: Validate<any> | Record<string, Validate<any>> | undefined,
) =>
  !value || validate.isPostalCode(value, 'any')
    ? true
    : getText('zip-code-is-not-valid');

export const AddressField: FunctionComponent<AddressFieldProps> = ({
  name,
  rules,
  disabled,
  // Forcing use only since we are supporting only US ATM
  countries = [
    {
      label: 'US',
      value: 'US',
    },
  ],
  states = US_STATES_OPTIONS,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const formContext = useFormContext();
  if (!formContext) {
    throw new Error('Address field must have a parent form context');
  }

  const { control, formState, getValues } = formContext;
  const error = getNestedObjectValue(formState.errors, name);

  const areFieldsRequired = Boolean(rules?.required);

  return (
    <>
      <Controller
        control={control}
        render={() => (
          <>
            <Form.Row>
              <Form.Column>
                <TextField
                  name={`${name}.address1`}
                  label={getText('street-address-onboarding')}
                  testID={`address1`}
                  disabled={disabled}
                  rules={{
                    required:
                      areFieldsRequired &&
                      getText('street-address-is-required'),
                    validate: address1ValidationRule,
                  }}
                />
              </Form.Column>
            </Form.Row>
            <Form.Row>
              <Form.Column>
                <TextField
                  name={`${name}.address2`}
                  disabled={disabled}
                  label={getText('street-address-line-2-onboarding')}
                  testID={`address2`}
                  rules={{
                    validate: address2ValidationRule,
                  }}
                />
              </Form.Column>
            </Form.Row>
            <Form.Row style={styles.cityStateContainer}>
              <Form.Column style={styles.compactMarginRight} last>
                <TextField
                  name={`${name}.city`}
                  label={getText('city')}
                  disabled={disabled}
                  testID={`city`}
                  rules={{
                    required: areFieldsRequired && getText('city-is-required'),
                    validate: cityValidationRule,
                  }}
                />
              </Form.Column>
              <View style={styles.dropDownContainer}>
                <DropdownSelectField
                  name={`${name}.state`}
                  options={states}
                  label={getText('state')}
                  disabled={disabled}
                  labelInlined
                  rules={{
                    required: areFieldsRequired && getText('state-is-required'),
                  }}
                />
              </View>
            </Form.Row>
            <Form.Row style={styles.postalCountryContainer}>
              <Form.Column style={styles.compactMarginRight} last>
                <TextField
                  name={`${name}.postal_code`}
                  label={getText('zip-code-onboarding')}
                  testID={`postal_code`}
                  disabled={disabled}
                  rules={{
                    required:
                      areFieldsRequired && getText('zip-code-is-required'),
                    validate: postalCodeValidationRule,
                  }}
                />
              </Form.Column>
              <View style={styles.dropDownContainer}>
                <DropdownSelectField
                  name={`${name}.country`}
                  label={getText('country')}
                  disabled={disabled}
                  labelInlined
                  rules={{
                    required:
                      areFieldsRequired && getText('country-is-required'),
                  }}
                  options={countries}
                />
              </View>
            </Form.Row>
          </>
        )}
        name={name}
        rules={rules}
      />
      {rules?.validate && !!error && (
        <View style={styles.container}>
          <View style={styles.icon}>
            <Icon
              icon={AlertTriangleIcon}
              color={theme.palette.error[600]}
              size={16}
            />
          </View>
          <Text
            testID={AddressFieldTestIDs.errorMessage}
            style={styles.errorMessage}
          >
            {error.message}
          </Text>
        </View>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.getSpacing(0.5),
  },
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.getSpacing(1),
    marginBottom: theme.getSpacing(1),
  },
  errorMessage: {
    color: theme.palette.error[600],
    fontSize: 14,
  },
  dropDownContainer: {
    width: 140,
  },
  cityStateContainer: {
    // marginTop: theme.getSpacing(2),
  },
  postalCountryContainer: {
    // marginTop: theme.getSpacing(0.5),
  },
  compactMarginRight: {
    marginRight: theme.getSpacing(1),
  },
}));

export interface AddressFieldProps {
  name: string;
  rules?: Pick<RegisterOptions, 'validate' | 'required'>;
  disabled?: boolean;
  // TODO: refactor this to DropdownOptions
  /**
   * optional parameter if we want to use custom states
   */
  states?: Array<{ label: string; value: string }>;
  /**
   * optional parameter if we want to use custom countries
   */
  countries?: Array<{ label: string; value: string }>;
}

export const AddressFieldTestIDs = {
  errorMessage: 'text-field-error-message',
};
