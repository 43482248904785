import {
  ClosureHoursDto,
  CreateClosureDto,
  CreateLocationClosuresRequestDto,
  ToggleClosureDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { useRoute } from '@react-navigation/native';
import { CheckboxField, CheckboxInputMode } from 'assets/components/checkbox';
import { Icon } from 'assets/components/icon';
import { Modal } from 'assets/components/modal';
import { Text } from 'assets/components/text';
import { TextField } from 'assets/components/text-field';
import { ToggleSwitch } from 'assets/components/toggle-switch';
import { PlusCircleIcon, TrashWithStripesIcon } from 'assets/icons';
import { Form } from 'assets/layout/form/Form';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import moment from 'moment-timezone';
import React, { FunctionComponent, PropsWithChildren, useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { TouchableOpacity, View } from 'react-native';
import {
  convertClosuresDateToString,
  convertTimeRangeToISOString,
  extractHoursAndMinutesFromISOString,
  formatDate,
  isDateBefore,
  isDateValid,
} from '../../../../common/datetime-utils';
import { zIndexAuto } from '../../../../common/theme';
import { TimeRangeInput } from '../../../../schedule/time-range-input/TimeRangeInput';
import { LocationTime } from '../../../../store/onboarding-store';
import { LocationsDrawerRouteProp } from '../LocationsSettingsDrawer';
import {
  setShowModalLocationAdditionalClosures,
  updateLocationClosures,
} from '../location-settings-actions';
import { useLocationSettingsState } from '../location-settings-store';

const emptyClosure: ClosureHours = {
  name: '',
  enabled: false,
  date: '',
  dayHours: {
    start: '',
    end: '',
  },
};

export const LocationAdditionalClosuresModal: FunctionComponent<
  PropsWithChildren<any>
> = () => {
  const route = useRoute<LocationsDrawerRouteProp>();
  const locationId = route.params?.locationId;
  const { showModalLocationAdditionalClosures, locationClosures } =
    useLocationSettingsState();
  const locationClosuresHours = locationClosures.location;
  const pharmacyClosuresHours = locationClosures.pharmacy;
  const styles = useStyles();
  const theme = useTheme();

  const methods = useForm<DepartmentHoursFormProps>({
    defaultValues: { closures: [emptyClosure] },
    mode: 'all',
  });
  const { control } = methods;
  const {
    fields: closuresFields,
    append: appendClosures,
    remove: removeClosures,
  } = useFieldArray({
    control,
    name: 'closures',
  });
  const { fields: pharmacyFields, update: updatePharmacy } = useFieldArray({
    control,
    name: 'pharmacy',
  });

  useEffect(() => {
    methods.setValue(
      'closures',
      locationClosuresHours.length === 0
        ? [emptyClosure]
        : locationClosuresHours.map((closure) => {
            const dateString: string = closure.timeRange.date
              ? formatDate(closure.timeRange.date)
              : '';
            return {
              enabled: closure.enabled || false,
              name: closure.name || '',
              date: dateString,
              dayHours: convertTimeRangeToISOString(closure.timeRange),
            };
          }),
    );

    methods.setValue(
      'pharmacy',
      pharmacyClosuresHours.length > 0 ? pharmacyClosuresHours : [],
    );
  }, [locationClosures]);

  const handleSubmit = async () => {
    const formValue = methods.getValues();
    const createClosures: CreateClosureDto[] = formValue.closures.map(
      (closure) => {
        const startHour = extractHoursAndMinutesFromISOString(
          closure.dayHours.start,
        );
        const endHour = extractHoursAndMinutesFromISOString(
          closure.dayHours.end,
        );
        return {
          name: closure.name,
          enabled: closure.enabled,
          time_range: {
            date: closure.date,
            start_hour: startHour.hours,
            start_minute: startHour.minutes,
            end_hour: endHour.hours,
            end_minute: endHour.minutes,
          },
        };
      },
    );
    const createPharmacyHours: ToggleClosureDto[] = formValue.pharmacy.map(
      (value) => {
        return { id: value.id, enabled: value.enabled === true };
      },
    );
    const closures: CreateLocationClosuresRequestDto = {
      location: createClosures,
      pharmacy: createPharmacyHours,
    };
    updateLocationClosures(locationId || '', closures);
    setShowModalLocationAdditionalClosures(false);
  };

  const closeModal = () => {
    setShowModalLocationAdditionalClosures(false);
  };

  const toggleDefaultClosures = (index: number) => {
    const defaultPharmacyClosure = methods.getValues().pharmacy[index];
    updatePharmacy(index, {
      ...defaultPharmacyClosure,
      enabled: !defaultPharmacyClosure.enabled,
    });
  };

  return (
    <Modal
      title={getText('locations-additional-closures')}
      titleSize="sm"
      dismissButtonProps={{
        onPress: closeModal,
        logger: {
          id: 'locations-additional-closures-form-cancel-button-modal',
        },
      }}
      cancelButtonProps={{
        onPress: closeModal,
        hierarchy: 'tertiary-gray',
        logger: {
          id: 'locations-additional-closures-form-cancel-button-modal',
        },
      }}
      okButtonProps={{
        onPress: methods.handleSubmit(handleSubmit),
        logger: { id: 'locations-additional-closures-form-ok-button-modal' },
        hierarchy: 'primary',
        text: getText('ok'),
      }}
      show={showModalLocationAdditionalClosures}
      isScrollable={true}
      size="lg"
      scrollViewStyle={{ minHeight: 480 }}
    >
      <Form methods={methods}>
        {closuresFields.map((field, index) => (
          <React.Fragment key={field.id}>
            <Form.Row style={styles.row}>
              <Form.Column>
                <CheckboxField
                  key={`${field.id}.closures.enabled`}
                  name={`closures.${index}.enabled`}
                  label={getText('locations-active')}
                  mode={CheckboxInputMode.FLAT}
                />
              </Form.Column>
              <Form.Column style={{ alignItems: 'flex-end' }}>
                <TouchableOpacity
                  style={[styles.topBarButton]}
                  onPress={() => removeClosures(index)}
                >
                  <Icon
                    icon={TrashWithStripesIcon}
                    color={theme.palette.gray[700]}
                    size={20}
                  />
                </TouchableOpacity>
              </Form.Column>
            </Form.Row>
            <Form.Row style={styles.row}>
              <Form.Column>
                <TextField
                  key={`${field.id}.name`}
                  style={styles.inputStyle}
                  name={`closures.${index}.name`}
                  type="text"
                  label={getText('label')}
                  rules={{
                    required: getText('locations-required'),
                  }}
                />
              </Form.Column>
            </Form.Row>
            <Form.Row style={styles.row}>
              <TextField
                key={`${field.id}.date`}
                style={styles.inputStyle}
                name={`closures.${index}.date`}
                type="date"
                rules={{
                  required: getText('locations-required'),
                  validate: (value: string) => {
                    return isDateValid(value) &&
                      !isDateBefore(value, moment().format('YYYY-MM-DD'))
                      ? true
                      : getText('wrong-date-format');
                  },
                }}
              />
              <View style={styles.timeRangesViewStyle}>
                <TimeRangeInput
                  name={`closures.${index}.dayHours`}
                  startTimeRules={{
                    required: getText('start-time-required'),
                  }}
                  endTimeRules={{
                    required: getText('end-time-required'),
                  }}
                />
              </View>
            </Form.Row>
          </React.Fragment>
        ))}
        <Form.Row style={styles.row}>
          <TouchableOpacity
            style={[styles.topBarButton]}
            onPress={() => appendClosures(emptyClosure)}
          >
            <Icon
              icon={PlusCircleIcon}
              color={theme.palette.primary[600]}
              size={20}
            />
            <Text style={styles.topBarText}>{getText('add-another')}</Text>
          </TouchableOpacity>
        </Form.Row>
        {pharmacyFields.map((field, index) => (
          <React.Fragment key={field.id}>
            <Form.Row>
              <Form.Column style={styles.textView}>
                <Text>{field.name} </Text>
              </Form.Column>
              <Form.Column style={styles.weekViewStyle}>
                <Text style={styles.value}>{`${convertClosuresDateToString(
                  field.timeRange,
                )} `}</Text>
              </Form.Column>
              <Form.Column style={styles.toggleStyle}>
                <ToggleSwitch
                  logger={{
                    id: `locations-service-option-${`${field.id}.pharmacy.enabled`}`,
                  }}
                  value={methods.getValues().pharmacy[index].enabled}
                  onPress={() => toggleDefaultClosures(index)}
                />
              </Form.Column>
            </Form.Row>
          </React.Fragment>
        ))}
      </Form>
    </Modal>
  );
};

interface ClosureHours {
  name: string;
  enabled: boolean;
  date: string;
  dayHours: LocationTime;
}

interface DepartmentHoursFormProps {
  closures: ClosureHours[];
  pharmacy: ClosureHoursDto[];
}

const useStyles = makeStyles((theme) => ({
  inputStyle: {
    height: 54,
    width: '100%',
  },
  textView: {
    display: 'flex',
    alignItems: 'flex-start',
    flex: 2,
    paddingTop: theme.getSpacing(1),
    height: 44,
  },
  row: {
    display: 'flex',
    flex: 1,
    zIndex: zIndexAuto,
  },
  topBarButton: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  topBarText: {
    color: theme.palette.primary[600],
    fontWeight: '500',
    fontSize: 16,
    marginLeft: theme.getSpacing(0.5),
  },
  weekViewStyle: {
    alignItems: 'flex-start',
    textAlign: 'left',
    flex: 3,
    zIndex: zIndexAuto,
  },
  value: {
    ...theme.fonts.regular,
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[700],
    zIndex: zIndexAuto,
  },
  toggleStyle: {
    alignItems: 'flex-end',
    flex: 1,
    zIndex: zIndexAuto,
  },
  timeRangesViewStyle: {
    flexDirection: 'row',
    zIndex: zIndexAuto,
    marginLeft: theme.getSpacing(2),
  },
}));
