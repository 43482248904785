import {
  AppointmentGroupCategoryDto,
  BookingDto,
} from '@digitalpharmacist/appointment-service-client-axios';
import {
  NavigationProp,
  ParamListBase,
  useFocusEffect,
  useNavigation,
} from '@react-navigation/native';
import { Icon } from 'assets/components/icon';
import { Text } from 'assets/components/text';
import {
  ArrowRightIcon,
  CalendarAltIcon,
  CalendarIcon,
  CheckIcon,
  FiltersLinesIcon,
} from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { logError } from 'assets/logging/logger';
import { makeStyles, useTheme } from 'assets/theme';
import moment from 'moment-timezone';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Pressable, View } from 'react-native';
import AppointmentService from '../../../../api/AppointmentService';
import { formatDateTimeApi } from '../../../../common/datetime-utils';
import { LoadingOverlay } from '../../../../components/LoadingOverlay';
import { useAppStateStore } from '../../../../store/app-store';
import { EmptyState } from '../empty-state/EmptyState';
import { AppointmentList } from '../upcoming-appointments/AppointmentList';
import { UpcomingAppointmentsTestIDs } from '../../DashboardTestIDs';
import { MenuWrapper } from '../../../../components/pharmacy-header/MenuWrapper';

const SHOW_ALL_OPTION: AppointmentGroupCategoryDto = {
  id: '',
  title: getText('show-all'),
  pharmacy_id: '',
  icon_name: '',
};

export const UpcomingAppointmentsWidget: FunctionComponent = () => {
  const navigation = useNavigation<NavigationProp<ParamListBase>>();
  const [upcomingApp, setUpcomingApp] = useState<BookingDto[]>([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>();
  const [groupCategories, setGroupCategories] = useState<
    AppointmentGroupCategoryDto[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { pharmacyId, locationId } = useAppStateStore();

  const styles = useStyles();
  const theme = useTheme();

  useFocusEffect(
    useCallback(() => {
      void fetchUpcomingAppointments();
    }, [locationId, selectedCategoryId]),
  );

  useEffect(() => {
    void (async () => {
      fetchAppointmentGroupCategories();
    })();
  }, [locationId]);

  const fetchUpcomingAppointments = () => {
    setIsLoading(true);
    AppointmentService.getBookings({
      pharmacyId,
      locationId,
      offset: 0,
      limit: 5,
      orderBy: 'ASC',
      appointmentGroupCategoryId: selectedCategoryId || undefined,
      minEndDate: formatDateTimeApi(moment()),
    })
      .then(({ results }) => {
        setUpcomingApp(results);
      })
      .catch((error: Error) => logError(error))
      .finally(() => setIsLoading(false));
  };

  const fetchAppointmentGroupCategories = async () => {
    try {
      const { data } = await AppointmentService.findAppointmentGroupCategories(
        pharmacyId,
        locationId,
      );
      const sortedData = data.sort((a, b) => {
        if (a.title === 'Other') return 1;
        if (b.title === 'Other') return -1;
        return a.title.localeCompare(b.title);
      });
      setGroupCategories(sortedData);
    } catch (error: any) {
      logError(error);
    }
  };

  const handleUpcomingAppointmentClick = () => {
    navigation.navigate('schedule', {
      screen: 'appointments',
    });
  };

  return (
    <View style={styles.container}>
      {selectedCategoryId && (
        <View style={styles.filters}>
          <Icon
            icon={FiltersLinesIcon}
            size={20}
            color={theme.palette.gray[500]}
          />
          <Text style={styles.filtersTitle}>{getText('filters-applied')}</Text>
          <Pressable onPress={() => setSelectedCategoryId(undefined)}>
            <Text style={styles.clear}>{getText('clear')}</Text>
          </Pressable>
        </View>
      )}
      <View style={styles.header}>
        <Pressable
          style={styles.titleContainer}
          onPress={handleUpcomingAppointmentClick}
          testID={UpcomingAppointmentsTestIDs.title}
        >
          <Icon icon={CalendarIcon} size={24} color={theme.palette.gray[700]} />
          <Text style={styles.titleText}>
            {getText('upcoming')} {getText('appointments')}
          </Text>
          <Icon
            icon={ArrowRightIcon}
            size={20}
            color={theme.palette.gray[700]}
          />
        </Pressable>
        <MenuWrapper
          anchor={
            <Icon
              icon={FiltersLinesIcon}
              size={20}
              color={theme.palette.gray[700]}
            />
          }
          onPress={(item) => setSelectedCategoryId(item.id)}
          items={
            groupCategories.length > 0
              ? [SHOW_ALL_OPTION, ...groupCategories]
              : groupCategories
          }
          optionTemplate={(item) => (
            <View style={styles.optionContainer}>
              <Text
                ellipsizeMode="tail"
                numberOfLines={1}
                style={{
                  ...styles.menuTitle,
                  color:
                    item.id == selectedCategoryId ||
                    (!selectedCategoryId && !item.id)
                      ? theme.palette.primary[600]
                      : theme.palette.gray[700],
                }}
              >
                {item.title}
              </Text>
              {(item.id === selectedCategoryId ||
                (!selectedCategoryId && !item.id)) && (
                <View style={styles.icon}>
                  <Icon
                    icon={CheckIcon}
                    color={theme.palette.primary[600]}
                    size={16}
                  />
                </View>
              )}
            </View>
          )}
          menuStyle={styles.menuStyle}
          menuItemStyle={styles.menuItemStyle}
        />
      </View>
      {isLoading ? (
        <LoadingOverlay size={40} />
      ) : upcomingApp.length === 0 ? (
        <>
          <EmptyState
            Icon={CalendarAltIcon}
            iconProps={{
              color: theme.palette.gray[700],
              size: 75,
              accentColor: theme.colors.brandedText,
            }}
            text={getText('dashboard-no-appointments')}
          />
        </>
      ) : (
        <AppointmentList upcomingApp={upcomingApp} />
      )}
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: theme.roundness,
    minHeight: 300,
    backgroundColor: theme.palette.white,
    padding: theme.getSpacing(2),
    elevation: 3,
    shadowColor: theme.palette.gray[900],
    shadowOpacity: 0.06,
    shadowRadius: 1,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    gap: theme.getSpacing(2),
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.getSpacing(1),
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.getSpacing(1),
    paddingBottom: theme.getSpacing(2.5),
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.gray[300],
  },
  titleText: {
    fontSize: 18,
    lineHeight: 28,
    fontFamily: theme.fonts.medium.fontFamily,
    color: theme.palette.gray[900],
  },
  menuTitle: { ...theme.lumistryFonts.text.small.regular },
  menuItemStyle: { height: 32 },
  divider: {
    borderBottomWidth: 1,
    borderColor: theme.palette.gray[300],
    paddingBottom: theme.getSpacing(2),
  },
  optionContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  menuStyle: { width: 250, marginTop: 45 },
  icon: {
    marginLeft: theme.getSpacing(1),
  },
  filters: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.getSpacing(1),
    padding: theme.getSpacing(2),
    borderRadius: theme.roundness,
    backgroundColor: theme.palette.warning[50],
    borderWidth: 1,
    borderColor: theme.palette.yellow[300],
  },
  filtersTitle: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.gray[900],
  },
  clear: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.primary[600],
  },
}));
