import React, { useEffect, useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { Text } from 'assets/components/text';
import {
  GroupBase,
  OnChangeValue,
  MultiValue,
  components,
  OptionProps,
  SingleValueProps,
  ValueContainerProps,
  ActionMeta,
} from 'react-select';
import { makeStyles, useTheme } from 'assets/theme';
import { CloseIcon } from 'assets/icons';
import { Avatar } from 'assets/components/avatar';
import { PharmacyTooltipWrapper } from '../../common/PharmacyTooltipWrapper';
import { calculatePatientAge, formatDate } from '../../common/datetime-utils';
import { Tooltip } from 'assets/components/tooltip/components/tooltip';
import {
  AdvancedDropDownProps,
  AdvancedDropDownWrapper,
} from '../advanced-dropdown/advanced-drop-down';
import {
  PatientUserSelectOption,
  EmptyPlaceholderTemplate,
} from './patient-user-select-templates';

/**
 * This is just a wrapper of react-select. We have applied common styles and functionalities
 * @docs https://react-select.com/
 * @returns `JSX.Element`
 */
export const PatientLookupSearchDropdown = <
  Option extends PatientUserSelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  label,
  optionTemplate,
  singleValueTemplate,
  getOptionLabel = (option: any) => option && option.label,
  ...selectProps
}: AdvancedDropDownProps<Option, IsMulti, Group>) => {
  const styles = useStyles();
  const theme = useTheme();
  const { styles: selectStyles, ...otherSelectProps } = selectProps;

  const { dropdownIndicator, control, option, ...otherSelectStyles } = {
    ...selectStyles,
  };
  const [values, setValues] = useState<MultiValue<Option>>();
  const [isFocused, setIsFocused] = useState(false);

  const handleSelectChange = (
    newValue: OnChangeValue<Option, IsMulti>,
    actionMeta: ActionMeta<Option>,
  ) => {
    const valuesArray = Array.isArray(newValue) ? newValue : [newValue];
    setValues(valuesArray as MultiValue<Option>);
    setIsFocused(false);
    selectProps.onChange && selectProps.onChange(newValue, actionMeta);
  };

  useEffect(() => {
    if (selectProps.value && selectProps.isMulti) {
      const valuesArray = Array.isArray(selectProps.value)
        ? selectProps.value
        : [selectProps.value];
      setValues(valuesArray);
    }
  }, []);

  useEffect(() => {
    if (selectProps.isMulti) {
      // Multi-select mode
      const valuesArray = Array.isArray(selectProps.value)
        ? selectProps.value
        : [];
      setValues(valuesArray);
    }
  }, [selectProps.value]);

  const handleRemoveItem = (option: Option) => {
    if (!values) {
      return;
    }
    const newValue: MultiValue<Option> = values.filter(
      (item) => item.id !== option.id,
    );
    setValues(newValue);
    const actionMeta: ActionMeta<Option> = {
      action: 'remove-value',
      removedValue: option,
    };
    selectProps.onChange &&
      selectProps.onChange(
        newValue as OnChangeValue<Option, IsMulti>,
        actionMeta,
      );
  };

  return (
    <>
      <AdvancedDropDownWrapper
        menuPlacement="auto"
        menuPortalTarget={document.body}
        aria-label="Select"
        styles={{
          dropdownIndicator: (base, state) => ({
            ...base,
            transition: 'all .2s ease',
            transform: state.selectProps.menuIsOpen
              ? ('rotate(180deg)' as any)
              : null,
            ...dropdownIndicator?.(base, state),
          }),
          control: (base, state) => ({
            ...base,
            borderRadius: theme.roundness,
            fontFamily: theme.fonts.regular.fontFamily,
            boxShadow: state.isFocused
              ? `0px 1px 2px ${theme.palette.primary[100]}, 0px 0px 0px 4px ${theme.palette.gray[50]}`
              : 'none',
            borderColor: state.isFocused
              ? theme.palette.primary[500]
              : state.isDisabled
              ? theme.palette.gray[400]
              : theme.palette.gray[300],
            ':hover': {
              borderColor: state.isFocused
                ? theme.palette.primary[500]
                : theme.palette.gray[300],
            },
            backgroundColor: state.isDisabled
              ? theme.palette.gray[50]
              : undefined,
            ...selectStyles?.control?.(base, state),
            height: 58,
          }),
          option: (base, state) => ({
            ...base,
            backgroundColor: state.isFocused
              ? theme.palette.gray[50]
              : state.isSelected
              ? theme.palette.primary[50]
              : undefined,
            fontFamily: theme.fonts.regular.fontFamily,
            color: state.isSelected ? theme.palette.primary[500] : undefined,
            ...selectStyles?.option?.(base, state),
          }),
          ...otherSelectStyles,
        }}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: () => null,
          Option: ({
            children,
            ...props
          }: React.PropsWithChildren<OptionProps<Option, IsMulti, Group>>) => {
            return (
              <components.Option {...props}>
                {optionTemplate?.(
                  props as OptionProps<Option, IsMulti, Group>,
                ) ?? children}
              </components.Option>
            );
          },
          SingleValue: ({
            children,
            ...props
          }: React.PropsWithChildren<
            SingleValueProps<Option, IsMulti, Group>
          >) => {
            return (
              <components.SingleValue {...props}>
                {(singleValueTemplate &&
                  singleValueTemplate(
                    props as SingleValueProps<Option, IsMulti, Group>,
                  )) ??
                  children}
              </components.SingleValue>
            );
          },
          MultiValue: () => null,
          ValueContainer: ({
            children,
            ...props
          }: React.PropsWithChildren<
            ValueContainerProps<Option, IsMulti, Group>
          >) => {
            const { hasValue } = props;
            let placeHolderTemplate = <></>;

            if (hasValue && !props.isMulti) {
              placeHolderTemplate = <></>;
            } else if (hasValue && props.isMulti) {
              placeHolderTemplate = (
                <EmptyPlaceholderTemplate
                  {...props}
                  focused={isFocused}
                  label={label ?? ''}
                  children={children}
                />
              );
            } else if (!hasValue || !values || values.length === 0) {
              placeHolderTemplate = (
                <EmptyPlaceholderTemplate
                  {...props}
                  focused={isFocused}
                  label={label ?? ''}
                  children={children}
                />
              );
            }
            return (
              <components.ValueContainer<Option, IsMulti, Group> {...props}>
                <View style={styles.inputWrapper}>
                  {placeHolderTemplate}
                  {children}
                </View>
              </components.ValueContainer>
            );
          },
          Placeholder: () => null,
        }}
        {...otherSelectProps}
        onChange={handleSelectChange}
        getOptionLabel={getOptionLabel}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onMenuOpen={() => setIsFocused(true)}
      />
      {selectProps.isMulti && (
        <View style={styles.multiValueContainer}>
          {values &&
            values.map((selectedOption: PatientUserSelectOption, index) => (
              <React.Fragment key={selectedOption.id}>
                <View
                  style={styles.multiValueSelectedItem}
                  key={selectedOption.id}
                >
                  <Avatar
                    firstName={selectedOption.first_name}
                    lastName={selectedOption.last_name}
                    size={24}
                  />
                  <View style={styles.textContainer}>
                    <View
                      style={{
                        flex: 1,
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <PharmacyTooltipWrapper
                        tooltipId={index.toString()}
                        rowIndex={index}
                      >
                        <View
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            flex: 1,
                          }}
                        >
                          <Text
                            style={styles.dropdownDetails}
                            numberOfLines={1}
                            ellipsizeMode="tail"
                          >
                            {`${selectedOption.first_name} ${selectedOption.last_name}`}
                          </Text>
                          <Text
                            style={styles.dropdownDetailsGray}
                          >{`- ${formatDate(
                            selectedOption.date_of_birth,
                          )}, ${calculatePatientAge(
                            selectedOption.date_of_birth,
                          )} ${
                            selectedOption.gender === 'male'
                              ? ' M'
                              : selectedOption.gender === 'female'
                              ? ' F'
                              : ''
                          }`}</Text>
                        </View>
                      </PharmacyTooltipWrapper>
                    </View>
                  </View>
                  <TouchableOpacity
                    style={styles.removeIconContainer}
                    onPress={() => handleRemoveItem(selectedOption as Option)}
                  >
                    <CloseIcon size={16} color={theme.palette.gray[700]} />
                  </TouchableOpacity>
                </View>

                <Tooltip
                  style={{ display: 'inline-grid' }}
                  text={`${selectedOption.first_name} ${
                    selectedOption.last_name
                  }\n${formatDate(selectedOption.date_of_birth)}, ${
                    new Date().getFullYear() -
                    new Date(selectedOption.date_of_birth).getFullYear()
                  } ${selectedOption.gender === 'male' ? 'M' : 'F'}`}
                  anchorId={index.toString()}
                  place={'top'}
                />
              </React.Fragment>
            ))}
        </View>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  multiValueContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: theme.getSpacing(1),
    width: '100%',
  },
  multiValueSelectedItem: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.getSpacing(1),
    marginBottom: theme.getSpacing(0.5),
    marginRight: theme.getSpacing(0.5),
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.gray[100],
    width: '100%',
  },
  dropdownDetailsGray: {
    marginLeft: theme.getSpacing(1),
    color: theme.palette.gray[400],
    fontSize: 16,
    flexShrink: 0,
  },
  dropdownDetails: {
    fontSize: 16,
    color: theme.palette.gray[700],
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '60%',
  },
  inputWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  userIcon: {
    marginLeft: theme.getSpacing(1),
  },
  textContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: theme.getSpacing(1),
    flex: 1,
  },
  removeIconContainer: {
    marginLeft: theme.getSpacing(1),
  },
}));
