import {
  TaskDto,
  TaskStatus,
  TaskVisibility,
} from '@digitalpharmacist/tasks-service-client-axios';

import { AlertTriangleIcon } from 'assets/icons/AlertTriangleIcon';
import { RepeatIcon } from 'assets/icons/RepeatIcon';
import { useTheme } from 'assets/theme';
import moment from 'moment';
import {
  PlayCircleFilledIcon,
  PauseCircleFilledIcon,
  CheckCircleIconFilledIn,
  CircleIcon,
} from '../../../../../packages/assets/icons';
import { getText } from '../../../../../packages/assets/localization/localization';
import { formatDate, formatUTCToRelative } from '../../common/datetime-utils';
import { useTaskSettingsState } from '../../screens/settings/tasks/task-settings-store';
import { useUserState } from '../../store/user-store';
import { useTaskModalState } from '../task-modal/task-modal-store';
import {
  TaskFilters,
  useTasksFiltersState,
} from '../tasks-filters/tasks-filters-store';

const calculateTaskDate = (type: string) => {
  const theme = useTheme();

  if (type === 'recurringOverdue') {
    return {
      color: theme.palette.error[500],
      overdueIcon: AlertTriangleIcon,
      recurringIcon: RepeatIcon,
    };
  } else if (type === 'resolved') {
    return {
      color: theme.palette.success[500],
    };
  } else if (type === 'overdue') {
    return {
      color: theme.palette.error[500],
      overdueIcon: AlertTriangleIcon,
    };
  } else if (type === 'recurring') {
    return {
      recurringIcon: RepeatIcon,
    };
  } else {
    return {
      color: theme.palette.gray[900],
    };
  }
};

export const visibilityFormatter = {
  [TaskVisibility.Location]: getText('team'),
  [TaskVisibility.Personal]: getText('personal'),
};

export const calculateTaskDueDate = (data: TaskDto) => {
  const taskOverdue = moment().isSameOrAfter(data.due_date) && 'overdue';
  const taskRecurring = data.recurring && 'recurring';
  const taskResolved = data.status === TaskStatus.Resolved && 'resolved';
  const taskRecurringOverdue =
    moment().isSameOrAfter(data.due_date) &&
    data.recurring &&
    'recurringOverdue';

  const taskType =
    taskRecurringOverdue || taskResolved || taskOverdue || taskRecurring || '';
  const { color, overdueIcon, recurringIcon } = calculateTaskDate(taskType);

  const formattedDate = formatUTCToRelative(
    data.due_date,
    undefined,
    true,
    'US',
    undefined,
    true,
  );

  return {
    color,
    overdueIcon,
    recurringIcon,
    formattedDate,
  };
};

const extractFilterUserNames = (userId: string) => {
  const currentUserId = useUserState.getState().data?.id;

  const usersData = useTaskModalState
    .getState()
    .assigneeOptions.find((user) => user.userId === userId);

  if (userId === currentUserId) {
    return 'you';
  }

  if (usersData) {
    return `"${usersData.firstName} ${usersData.lastName}"`;
  }
};

const extractFilterTaskType = (taskTypeId: string) => {
  const typeData = useTaskSettingsState
    .getState()
    .taskTypes.find((type) => type.id === taskTypeId);

  if (typeData) {
    return `"${typeData.title}"`;
  }
};

const extractFilterGenericValue = (value: string) => {
  const capitalizedValue =
    value.charAt(0).toUpperCase() + value.slice(1).replace('_', ' ');

  return `"${
    capitalizedValue === getText('location')
      ? getText('team')
      : capitalizedValue
  }"`;
};

const extractFilterDateRange = () => {
  const { min_due_date, max_due_date } =
    useTasksFiltersState.getState().filters;

  if (min_due_date && max_due_date) {
    return `"${formatDate(min_due_date)}" to "${formatDate(max_due_date)}"`;
  }
};

const extractFilterDueDate = (value: string) => {
  return `"${formatDate(value)}"`;
};

// TODO: Add localization for the filter messages
const filtersMessageMapper: Record<string, any> = {
  priority: {
    message: 'Priority set to ',
    extractor: extractFilterGenericValue,
  },
  status: {
    message: 'Status set to ',
    extractor: extractFilterGenericValue,
  },
  assigned_user_id: {
    message: 'Assigned to ',
    extractor: extractFilterUserNames,
  },
  min_due_date: {
    message: 'Due date set in the range of',
    extractor: extractFilterDateRange,
  },
  due_date: {
    message: 'Due date set to',
    extractor: extractFilterDueDate,
  },
  recurring: {
    message: 'Recurring is set to "True"',
    extractor: () => '',
  },
  created_by_user_id: {
    message: 'Created by ',
    extractor: extractFilterUserNames,
  },
  visibility: {
    message: 'Visibility set to ',
    extractor: extractFilterGenericValue,
  },
  task_type_id: {
    message: 'Type set to ',
    extractor: extractFilterTaskType,
  },
  deleted_only: {
    message: 'Deleted is set to "True"',
    extractor: () => '',
  },
  search_term: {
    message: 'Search term set to ',
    extractor: extractFilterGenericValue,
  },
  flagged: {
    message: 'Flagged is set to "True"',
    extractor: () => '',
  },
};

export const composeFiltersMessage = () => {
  const filters = useTasksFiltersState.getState().filters;

  const definedFilters = Object.entries(filters).filter(
    ([key, value]) => value !== undefined && key !== 'search_term',
  );

  if (filters.search_term) {
    // Guaranteeing search is in the 0 index
    definedFilters.unshift(['search_term', filters.search_term]);
  }

  return definedFilters
    .map(([key, value]) => {
      const { message, extractor } = filtersMessageMapper[key] ?? {};
      if (message && extractor) {
        return message + extractor(value);
      }
      return '';
    })
    .filter((message) => !!message)
    .join(',  ');
};

export const getActionButtonIcon = (status: TaskStatus) => {
  switch (status) {
    case TaskStatus.InProgress:
      return PlayCircleFilledIcon;
    case TaskStatus.OnHold:
      return PauseCircleFilledIcon;
    case TaskStatus.Resolved:
      return CheckCircleIconFilledIn;
    default:
      return CircleIcon;
  }
};

export const getActionButtonColor = (status: TaskStatus) => {
  const theme = useTheme();

  switch (status) {
    case TaskStatus.InProgress:
      return theme.palette.warning[400];
    case TaskStatus.OnHold:
      return theme.palette.primary[400];
    case TaskStatus.Resolved:
      return theme.palette.success[500];
    default:
      return theme.palette.gray[300];
  }
};

export const hasCheckboxFiltersOrSearch = (): boolean => {
  const filters = useTasksFiltersState.getState().filters;
  const currentUserId = useUserState.getState().data?.id;

  const keys: Array<keyof TaskFilters> = [
    'search_term',
    'created_by_user_id',
    'priority',
    'max_due_date',
    'min_due_date',
    'task_type_id',
    'assigned_user_id',
    'visibility',
    'status',
    'due_date',
  ];

  for (const key of keys) {
    if (key in filters && filters[key] !== undefined) {
      if (key === 'search_term' && filters[key]) {
        return true;
      }
      if (key === 'assigned_user_id' && filters[key] === currentUserId) {
        return true;
      }
      if (
        key === 'visibility' &&
        filters[key] === 'personal' &&
        filters['assigned_user_id'] === undefined
      ) {
        return false;
      }
      if (key === 'status') {
        if (filters[key] === 'in_progress' || filters[key] === 'on_hold') {
          return true;
        } else {
          return false;
        }
      }
      return true;
    }
  }
  return false;
};
