import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { useForm } from 'assets/form';
import { Modal } from 'assets/components/modal';
import { makeStyles } from 'assets/theme';
import { useMessageSettingsState } from '../message-settings-store';
import { Form } from 'assets/layout/form/Form';
import { TextField } from 'assets/components/text-field';
import { getText } from 'assets/localization/localization';
import {
  modifyMessageSettings,
  updateShowMessageSettingsAutoModalState,
} from '../message-settings-actions';
import { useAppStateStore } from '../../../../store/app-store';
import { ToggleSwitch } from 'assets/components/toggle-switch';
import { Text } from 'assets/components/text';
import { View } from 'react-native';
import { ModifyDirectMessageSettingsDto } from '@digitalpharmacist/unified-communications-service-client-axios';
import { useToast } from '../../../../common/hooks/useToast';
import { logError } from 'assets/logging/logger';

export interface MessageListOptions {
  value: string;
  label: string;
}

export type MessageSettingsForm = ModifyDirectMessageSettingsDto;

const initialFormValue: ModifyDirectMessageSettingsDto = {
  is_auto_response_after_hours_enabled: false,
  auto_response_after_hours_message: '',
};

export const MessageSettingsAutoFormModal: FunctionComponent<
  PropsWithChildren<MessageSettingsAutoFormModalProps>
> = () => {
  const styles = useStyles();
  const { messageSettingsConfig, showMessageSettingsAutoFormModal } =
    useMessageSettingsState();
  const { pharmacyId, locationId } = useAppStateStore();

  const [autoResponseAfterHoursEnabled, setAutoResponseAfterHoursEnabled] =
    useState<boolean>(false);

  const methods = useForm<MessageSettingsForm>({
    defaultValues: initialFormValue,
    mode: 'onChange',
  });

  useEffect(() => {
    if (showMessageSettingsAutoFormModal == true) {
      resetForm();
    }
  }, [messageSettingsConfig, showMessageSettingsAutoFormModal]);

  const resetForm = () => {
    methods.reset({
      is_auto_response_after_hours_enabled:
        messageSettingsConfig.is_auto_response_after_hours_enabled ?? false,
      auto_response_after_hours_message:
        messageSettingsConfig.auto_response_after_hours_message ?? '',
    });

    setAutoResponseAfterHoursEnabled(
      messageSettingsConfig.is_auto_response_after_hours_enabled ?? false,
    );
  };

  const errorOccurred = (error: any, errorMessage?: string) => {
    const { toast } = useToast();
    const message = errorMessage
      ? errorMessage
      : getText('error-occurred-after-hours-load-save');

    logError(error);
    useMessageSettingsState.setState({
      error: {
        message: errorMessage
          ? errorMessage
          : getText('error-occurred-after-hours-load-save'),
      },
      status: 'error',
    });

    toast('Error', { type: 'error', content: message });
  };

  const handleSubmit = async () => {
    try {
      await modifyMessageSettings(pharmacyId, locationId, {
        auto_response_after_hours_message:
          methods.getValues().auto_response_after_hours_message,
        is_auto_response_after_hours_enabled: autoResponseAfterHoursEnabled,
      });
    } catch (error) {
      errorOccurred(error, getText('error-occurred-after-hours-load-save'));
    }

    closeModal();
  };

  const closeModal = () => {
    updateShowMessageSettingsAutoModalState(false);
  };

  return (
    <>
      <Modal
        title={getText('edit-after-hours')}
        titleSize="sm"
        dismissButtonProps={{
          onPress: closeModal,
          logger: { id: 'after-hours-form-cancel-button-modal' },
        }}
        cancelButtonProps={{
          onPress: closeModal,
          hierarchy: 'tertiary-gray',
          logger: { id: 'after-hours-form-cancel-button-modal' },
        }}
        okButtonProps={{
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onPress: handleSubmit,
          logger: { id: 'after-hours-form-ok-button-modal' },
          hierarchy: 'primary',
          text: getText('ok'),
        }}
        show={showMessageSettingsAutoFormModal}
      >
        <Form methods={methods}>
          <Form.Row>
            <Form.Column style={styles.inputWrapper}>
              <View style={styles.enable}>
                <ToggleSwitch
                  logger={{ id: `auto-response-after-hours-enabled` }}
                  value={autoResponseAfterHoursEnabled}
                  onPress={() => {
                    setAutoResponseAfterHoursEnabled(
                      !autoResponseAfterHoursEnabled,
                    );
                  }}
                ></ToggleSwitch>
                <Text style={styles.enableText}>
                  {autoResponseAfterHoursEnabled
                    ? getText('enabled')
                    : getText('disabled')}
                </Text>
              </View>
              <TextField
                name="auto_response_after_hours_message"
                label={'Enter Message'}
                rules={{
                  required: getText('task-type-description-required'),
                }}
                multiline={true}
                numberOfLines={3}
              />
            </Form.Column>
          </Form.Row>
        </Form>
      </Modal>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  inputWrapper: {
    flexDirection: 'column',
    flexGrow: 1,
    gap: 10,
  },
  container: {
    flexDirection: 'column',
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '100%',
    width: '50%',
  },
  enable: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  enableText: {
    marginLeft: theme.getSpacing(2),
  },
}));

interface MessageSettingsAutoFormModalProps {}

export default MessageSettingsAutoFormModal;
