import {
  CreateNormalHoursDto,
  DepartmentTimeRangeDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { useRoute } from '@react-navigation/native';
import { Alert } from 'assets/components/alert';
import { Button } from 'assets/components/button';
import { CheckboxInput, CheckboxInputMode } from 'assets/components/checkbox';
import { Modal } from 'assets/components/modal';
import { Text } from 'assets/components/text';
import { TextField } from 'assets/components/text-field';
import { MinusCircleIcon, PlusCircleIcon } from 'assets/icons';
import { Form } from 'assets/layout/form/Form';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { View } from 'react-native';
import {
  TimeRangeForm,
  convertTimeRangeToISOString,
  extractHoursAndMinutesFromISOString,
  timesOverlap,
} from '../../../../common/datetime-utils';
import { zIndexAuto } from '../../../../common/theme';
import { TimeRangeInput } from '../../../../schedule/time-range-input/TimeRangeInput';
import { LocationsDrawerRouteProp } from '../LocationsSettingsDrawer';
import {
  getDepartmentsLabel,
  isPharmacyDepartment,
  setShowModalLocationNormalHours,
  updateLocationNormalHours,
} from '../location-settings-actions';
import {
  DaysOfWeekEnum,
  useLocationSettingsState,
} from '../location-settings-store';

const emptyHour: TimeRangeForm = { start: '', end: '' };

const returnEmptyForm = (): DepartmentHoursFormProps => {
  const emptyForm: DepartmentHoursFormProps = {
    departmentId: '',
    defaultHours: true,
    departmentName: '',
  };
  for (let day = 1; day <= 7; day++) {
    emptyForm[day] = {
      disabled: true,
      dayHours: [],
    };
  }
  return emptyForm;
};

const resetFormValues = (
  editingLocationNormalHours: DepartmentTimeRangeDto,
): DepartmentHoursFormProps => {
  const form: DepartmentHoursFormProps = {
    ...returnEmptyForm(),
    departmentId: editingLocationNormalHours.department_id,
    defaultHours: editingLocationNormalHours.use_default_hours,
    departmentName: getDepartmentsLabel(
      editingLocationNormalHours.department_name,
    ),
  };
  for (let day = 1; day <= 7; day++) {
    const dayDepartmentHours = editingLocationNormalHours.time_ranges.filter(
      (item) => item.day === day,
    );
    form[day].disabled = dayDepartmentHours.length === 0;
    form[day].dayHours =
      dayDepartmentHours.length !== 0
        ? dayDepartmentHours.map((item) => convertTimeRangeToISOString(item))
        : [];
  }
  return form;
};

export const LocationNormalHoursModal: FunctionComponent<
  PropsWithChildren<any>
> = () => {
  const route = useRoute<LocationsDrawerRouteProp>();
  const locationId = route.params?.locationId;

  const { showModalLocationNormalHours, editingLocationNormalHours, location } =
    useLocationSettingsState();
  const styles = useStyles();
  const [loadingMethod, setLoadingMethod] = useState(false);
  const [defaultHours, setDefaultHours] = useState(
    editingLocationNormalHours?.use_default_hours,
  );

  const methods = useForm<DepartmentHoursFormProps>({
    defaultValues: returnEmptyForm(),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const { control } = methods;

  const dayFieldArrays = Array.from({ length: 7 }).map((_, index) =>
    useFieldArray({
      control,
      // TODO Evaluate the necessity of 'as never' and explore potential alternatives for better type handling.
      name: `${index + 1}.dayHours` as never,
    }),
  );

  useEffect(() => {
    if (editingLocationNormalHours) {
      const resetValues = resetFormValues(editingLocationNormalHours);
      methods.reset(resetValues);
    } else {
      methods.reset(returnEmptyForm());
    }
    setDefaultHours(editingLocationNormalHours?.use_default_hours);
  }, [editingLocationNormalHours, showModalLocationNormalHours]);

  const handleSubmit = async () => {
    setLoadingMethod(true);
    const formValue = methods.getValues();
    const createHours: CreateNormalHoursDto = {
      use_default_hours: formValue.defaultHours,
      time_ranges: [],
    };
    if (!formValue.defaultHours) {
      for (let day = 1; day <= 7; day++) {
        if (!formValue[day].disabled && createHours.time_ranges) {
          createHours.time_ranges.push(
            ...formValue[day].dayHours.map((item) => {
              const startHour = extractHoursAndMinutesFromISOString(item.start);
              const endHour = extractHoursAndMinutesFromISOString(item.end);
              return {
                day,
                start_hour: startHour.hours,
                start_minute: startHour.minutes,
                end_hour: endHour.hours,
                end_minute: endHour.minutes,
              };
            }),
          );
        }
      }
    }
    if (editingLocationNormalHours) {
      await updateLocationNormalHours(
        locationId || '',
        editingLocationNormalHours.department_id,
        createHours,
      );
    }
    setShowModalLocationNormalHours(false);
    setLoadingMethod(false);
  };

  const closeModal = () => {
    setShowModalLocationNormalHours(false);
  };

  const RenderClosedRow: FunctionComponent<{
    dayOfWeek: string;
    disabled: boolean;
    index: number;
  }> = ({ dayOfWeek, disabled, index }) => (
    <Form.Row>
      <Form.Column style={{ ...styles.weekStyle, ...styles.textView }}>
        <CheckboxInput
          mode={CheckboxInputMode.FLAT}
          checked={disabled}
          onPress={(checked) => selectRow(index, !checked)}
          disabled={defaultHours}
        />
        <Text style={styles.textFormat}>{dayOfWeek}</Text>
      </Form.Column>
      <Form.Column style={styles.hoursStyle}>
        <Text style={styles.closedTextStyle}>{getText('closed')}</Text>
      </Form.Column>
    </Form.Row>
  );

  const RenderDateTimePickerRow: FunctionComponent<{
    index: number;
    nestedIndex: number;
    breaks: number;
  }> = ({ index, nestedIndex, breaks }) => {
    return (
      <Form.Row
        key={`[${index}].dayHours[${nestedIndex}].row`}
        style={{ zIndex: zIndexAuto }}
      >
        <Form.Column
          style={styles.hoursStyle}
          key={`${index}.dayHours[${nestedIndex}].col`}
        >
          <TimeRangeInput
            name={`${index}.dayHours[${nestedIndex}]`}
            disabled={defaultHours}
            startTimeRules={{
              required: !defaultHours && getText('start-time-required'),
            }}
            endTimeRules={{
              required: !defaultHours && getText('end-time-required'),
            }}
          />
          <View style={{ width: 141 }}>
            {!(breaks === 5 && nestedIndex === 0) && (
              <Button
                onPress={() => {
                  nestedIndex > 0
                    ? removeHours(index - 1, nestedIndex)
                    : addNewHours(index - 1);
                }}
                hierarchy="tertiary"
                icon={nestedIndex > 0 ? MinusCircleIcon : PlusCircleIcon}
                labelStyle={styles.buttonLabelStyle}
                logger={{
                  id: `add-department-hours-[${
                    editingLocationNormalHours?.department_id || ''
                  }]-[${index}]`,
                }}
              >
                {nestedIndex > 0
                  ? getText('locations-departments-remove-hours')
                  : getText('locations-departments-add-hours')}
              </Button>
            )}
          </View>
        </Form.Column>
      </Form.Row>
    );
  };

  const selectRow = (day: number, disabled: boolean) => {
    const formValue = methods.getValues();
    (formValue[day].disabled = disabled), methods.reset(formValue);
    disabled
      ? dayFieldArrays[day - 1].remove()
      : dayFieldArrays[day - 1].replace([emptyHour]);
  };

  const addNewHours = (day: number) => {
    dayFieldArrays[day].append(emptyHour);
  };

  const removeHours = (day: number, hourIndex: number) => {
    dayFieldArrays[day].remove(hourIndex);
  };

  const returnOverlapDay = () => {
    return Array.from({ length: 7 })
      .map((_, index) => timesOverlap(methods.getValues()[index + 1].dayHours))
      .includes(true);
  };

  const handleSetDefaultHours = () => {
    if (!defaultHours) {
      const resetValues = editingLocationNormalHours
        ? resetFormValues(editingLocationNormalHours)
        : returnEmptyForm();
      methods.reset(resetValues);
    }
    setDefaultHours(!defaultHours);
    methods.setValue('defaultHours', !defaultHours);
  };

  return (
    <Modal
      title={`${getText('locations-edit-department-hours')} - ${
        location?.name || ''
      }`}
      titleSize="sm"
      dismissButtonProps={{
        onPress: closeModal,
        logger: { id: 'location-contact-info-form-cancel-button-modal' },
      }}
      cancelButtonProps={{
        onPress: closeModal,
        hierarchy: 'tertiary-gray',
        logger: { id: 'location-contact-info-form-cancel-button-modal' },
      }}
      okButtonProps={{
        onPress: methods.handleSubmit(handleSubmit),
        logger: { id: 'location-contact-info-form-ok-button-modal' },
        hierarchy: 'primary',
        text: getText('ok'),
        disabled: returnOverlapDay(),
        loading: loadingMethod,
      }}
      show={showModalLocationNormalHours}
      isScrollable={true}
      size="lg"
    >
      <Form methods={methods}>
        <Form.Row>
          <Form.Column style={{ ...styles.textWrapper, ...styles.textView }}>
            <Text style={styles.textFormat}>
              {getText('locations-department')}
            </Text>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="departmentName"
              type="text"
              rules={{
                required: getText('locations-departments-name-required'),
              }}
              disabled={
                editingLocationNormalHours?.department_id !== undefined ||
                (editingLocationNormalHours &&
                  isPharmacyDepartment(
                    editingLocationNormalHours.department_id,
                  ))
              }
            />
          </Form.Column>
        </Form.Row>

        <Form.Row style={styles.bottomBorder}>
          <CheckboxInput
            label={getText('locations-default')}
            checked={defaultHours}
            mode={CheckboxInputMode.FLAT}
            onPress={handleSetDefaultHours}
          />
        </Form.Row>

        {Array.from({ length: 7 }).map((_, index) => {
          const dayData = methods.watch()[index + 1];
          const dayOfWeek = DaysOfWeekEnum[index + 1];
          const overlap = timesOverlap(methods.watch()[index + 1].dayHours);
          return dayData.disabled ? (
            <RenderClosedRow
              dayOfWeek={dayOfWeek}
              disabled={!dayData.disabled}
              index={index + 1}
              key={+index}
            />
          ) : (
            <React.Fragment key={+index}>
              <Form.Row>
                <Form.Column>
                  {overlap ? (
                    <Alert intent="error" title={getText('time-overlapped')} />
                  ) : (
                    <></>
                  )}
                </Form.Column>
              </Form.Row>
              <Form.Row
                key={`[${index + 1}].dayHours.row`}
                style={{ zIndex: zIndexAuto }}
              >
                <Form.Column
                  style={{ ...styles.weekStyle, ...styles.textView }}
                  key={`[${index + 1}].dayHours.colDay`}
                >
                  <CheckboxInput
                    mode={CheckboxInputMode.FLAT}
                    checked={!dayData.disabled}
                    onPress={(checked) => selectRow(index + 1, !checked)}
                    disabled={defaultHours}
                  />
                  <Text style={styles.textFormat}>{dayOfWeek}</Text>
                </Form.Column>
                <Form.Column
                  style={styles.hoursGroupStyle}
                  key={`[${index + 1}].dayHours.colHours`}
                >
                  {dayData.dayHours.map((_, nestedIndex) => (
                    <RenderDateTimePickerRow
                      index={index + 1}
                      nestedIndex={nestedIndex}
                      key={+index + nestedIndex}
                      breaks={dayData.dayHours.length}
                    />
                  ))}
                </Form.Column>
              </Form.Row>
            </React.Fragment>
          );
        })}
      </Form>
    </Modal>
  );
};

interface DayForm {
  disabled: boolean;
  dayHours: TimeRangeForm[];
}

interface DepartmentHoursFormProps {
  departmentId: string;
  departmentName: string;
  defaultHours: boolean;
  [key: number]: DayForm;
}

const useStyles = makeStyles((theme) => ({
  inputWrapper: {
    display: 'flex',
    gap: theme.getSpacing(1),
    alignSelf: 'stretch',
    flex: 2,
  },
  inputStyle: {
    height: 44,
  },
  textWrapper: {
    flexDirection: 'row',
    flex: 1,
  },
  weekStyle: {
    flexDirection: 'row',
    flex: 1,
    zIndex: zIndexAuto,
  },
  hoursGroupStyle: {
    flex: 4,
    zIndex: zIndexAuto,
  },
  hoursStyle: {
    flexDirection: 'row',
    flex: 4,
    zIndex: zIndexAuto,
    alignItems: 'baseline',
  },
  textView: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: 10,
    height: 44,
  },
  textFormat: {
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 24,
    marginLeft: theme.getSpacing(1),
  },
  row: {
    alignContent: 'stretch',
  },
  bottomBorder: {
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.gray[200],
    paddingBottom: theme.getSpacing(3),
  },
  closedTextStyle: {
    fontSize: 18,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.palette.gray[500],
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.getSpacing(1),
  },
  separatorStyle: {
    color: theme.palette.gray[400],
    fontWeight: '600',
    alignItems: 'baseline',
  },
  separatorView: {
    paddingLeft: theme.getSpacing(1),
    paddingRight: theme.getSpacing(1),
  },
  buttonLabelStyle: { marginLeft: 8 },
}));
