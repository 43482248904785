import { Text } from 'assets/components/text';
import { ToggleSwitch } from 'assets/components/toggle-switch';
import { makeStyles, useTheme } from 'assets/theme';
import { FunctionComponent, PropsWithChildren, useState } from 'react';
import { View } from 'react-native';
import { useAppStateStore } from '../../../../../store/app-store';
import {
  setRefillErrorsMethod,
  setShowRefillErrorModal,
  updateRefillErrors,
} from '../../refills-settings-actions';
import {
  RefillRemoveBehavior,
  RxStatus,
} from '@digitalpharmacist/prescription-service-client-axios';
import { snakeCaseToTitleCase } from '../../../../../../utils';
import { IconButton } from 'assets/components/icon-button';
import { PencilIcon } from 'assets/icons';

export const RefillErrorsItemRender: FunctionComponent<
  PropsWithChildren<RefillErrorsItemRenderProps>
> = ({ rx_status, message_patient }) => {
  const theme = useTheme();
  const styles = useStyles();

  const displayText = (rx_status: string) => {
    switch (rx_status) {
      case (rx_status = 'no_refills'):
        return RxErrorText.NoRefills;
      case (rx_status = 'expired'):
        return RxErrorText.Expired;
      case (rx_status = 'too_soon'):
        return RxErrorText.TooSoon;
      case (rx_status = 'not_found'):
        return RxErrorText.NotFound;
      case (rx_status = 'controlled'):
        return RxErrorText.Controlled;
      case (rx_status = 'narcotic'):
        return RxErrorText.Narcotic;
      case (rx_status = 'transferred'):
        return RxErrorText.Transferred;
      case (rx_status = 'discontinued'):
        return RxErrorText.Discontinued;
      case (rx_status = 'on_hold'):
        return RxErrorText.OnHold;
      case (rx_status = 'deleted'):
        return RxErrorText.Deleted;
      case (rx_status = 'reassigned'):
        return RxErrorText.Reassigned;
      case (rx_status = 'partially_filled'):
        return RxErrorText.PartiallyFilled;
      case (rx_status = 'fillable'):
        return RxErrorText.Fillable;
      case (rx_status = 'out_of_stock'):
        return RxErrorText.OutOfStock;
      case (rx_status = 'special_order'):
        return RxErrorText.SpecialOrder;
      case (rx_status = 'deceased'):
        return RxErrorText.Deceased;
      default:
        return undefined;
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.firstColumnContent}>
        <Text style={styles.title}>{displayText(rx_status)}</Text>
      </View>
      <View style={{ flex: 3 }}>
        <Text style={styles.value}>{message_patient}</Text>
      </View>
      <View style={styles.actionViewStyle}>
        <IconButton
          icon={PencilIcon}
          onPress={() => {
            setRefillErrorsMethod(rx_status);
            setShowRefillErrorModal(true);
          }}
          size={16}
          color={theme.palette.gray[700]}
          logger={{ id: 'refill-error-action-button-edit' }}
        />
      </View>
    </View>
  );
};

interface RefillErrorsItemRenderProps {
  id: string;
  rx_status: RxStatus;
  remove_behavior: RefillRemoveBehavior;
  message_patient: string;
}

export enum RxErrorText {
  NoRefills = 'No more refills',
  Expired = 'Rx expired',
  TooSoon = 'Rx too soon',
  NotFound = 'Rx not found',
  Controlled = 'Controlled',
  Narcotic = 'Narcotic',
  Transferred = 'Transferred',
  Discontinued = 'Discontinued',
  OnHold = 'On hold',
  Deleted = 'Rx deleted',
  Reassigned = 'Reassigned',
  Fillable = 'Fillable',
  PartiallyFilled = 'Partial fill',
  OutOfStock = 'Out of stock',
  SpecialOrder = 'Special order',
  Deceased = 'Patient deceased',
}

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'row',
    gap: theme.getSpacing(2),
    alignItems: 'center',
    borderBottomColor: theme.palette.gray[200],
    borderBottomWidth: 1,
    padding: theme.getSpacing(1),
    marginBottom: theme.getSpacing(1),
  },
  firstColumnContent: {
    flex: 1,
    flexDirection: 'row',
  },
  title: {
    ...theme.lumistryFonts.label.small.semiBold,
  },
  value: {
    ...theme.lumistryFonts.text.small.regular,
  },
  actionViewStyle: {
    alignContent: 'flex-end',
    flexDirection: 'row',
  },
}));
