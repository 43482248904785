import {
  AutoSaveWebsiteWizardDto,
  FinalizeWebsiteCompoundingDetailDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { CheckboxField, CheckboxInputMode } from 'assets/components/checkbox';
import { Modal } from 'assets/components/modal';
import { Text } from 'assets/components/text';
import { Form } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import { chunk } from 'lodash';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  openNextModalId,
  openPreviousModalId,
  savePatientWebsite,
  setOpenedModal,
} from '../../../actions/checklist-actions';
import { useChecklistStore } from '../../../store/checklist-store';
import { ChecklistWebsiteModalProps } from '../types';
import { PatientWebsiteCompounding } from '../utils';

export interface WebsiteCompoundingDetails {
  compoundingDetails: FinalizeWebsiteCompoundingDetailDto[] | undefined;
}

export const PatientWebsiteCompoundingDetailsModal: FunctionComponent<
  ChecklistWebsiteModalProps
> = ({ id, data }) => {
  const currentOpenedModalId = useChecklistStore((x) => x.currentOpenedModalId);
  const [loading, setLoading] = useState<boolean>(false);
  const styles = useStyles();
  const compoundingDetails =
    data?.website_details?.website_compounding_details?.sort(
      (a, b) => a.order - b.order,
    );

  const methods = useForm<WebsiteCompoundingDetails>({
    defaultValues: {
      compoundingDetails,
    },
  });
  const compoundingDetailsFields = methods.watch('compoundingDetails');

  useEffect(() => {
    methods.reset({ compoundingDetails });
  }, [compoundingDetails]);

  const [firstPart, secondPart] = useMemo(
    () => [
      compoundingDetailsFields?.slice(0, 4),
      compoundingDetailsFields?.slice(4),
    ],
    [compoundingDetailsFields],
  );

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const dto: AutoSaveWebsiteWizardDto = {
        ...data,
        website_details: {
          ...data.website_details,
          website_compounding_details: compoundingDetailsFields,
        },
      };
      const services = dto.website_details?.website_service_options;

      await savePatientWebsite(dto);
      services && openNextModalId(services, 2);
    } finally {
      setLoading(false);
    }
  };

  const handleBackButton = () => {
    const services = data.website_details?.website_service_options;
    services && openPreviousModalId(services, -3);
  };

  return (
    <Modal
      title={getText('compounding-details')}
      show={currentOpenedModalId === id}
      handleDismissModal={() => setOpenedModal('checklist-setup')}
      showDismissButton
      isScrollable
      size="sm"
      buttons={[
        {
          hierarchy: 'primary',
          text: getText('next'),
          onPress: methods.handleSubmit(handleSubmit),
          logger: { id: 'next-website-services' },
          loading,
        },
        {
          text: getText('back'),
          hierarchy: 'tertiary-gray',
          onPress: handleBackButton,
          logger: { id: 'back-website-services' },
        },
      ]}
    >
      <Form methods={methods}>
        <Form.Row>
          <Form.Column>
            <Text style={styles.title}>{getText('all-apply-compounding')}</Text>
          </Form.Column>
        </Form.Row>

        {chunk(firstPart, 2).map((group, index) => (
          <Form.Row key={index}>
            {group.map((service, i) => (
              <Form.Column key={service.id}>
                <CheckboxField
                  name={`compoundingDetails.${index * 2 + i}.selected`}
                  label={PatientWebsiteCompounding[service.slug]}
                  mode={CheckboxInputMode.FLAT}
                />
              </Form.Column>
            ))}
          </Form.Row>
        ))}
        <Form.Row>
          <Form.Column>
            <Text style={styles.title}>
              {getText('select-compounding-type')}
            </Text>
          </Form.Column>
        </Form.Row>

        {chunk(secondPart, 2).map((group, index) => (
          <Form.Row key={index}>
            {group.map((service, i) => (
              <Form.Column key={service.id}>
                <CheckboxField
                  name={`compoundingDetails.${index * 2 + i + 4}.selected`}
                  label={PatientWebsiteCompounding[service.slug]}
                  mode={CheckboxInputMode.FLAT}
                />
              </Form.Column>
            ))}
          </Form.Row>
        ))}
      </Form>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.lumistryFonts.text.medium.regular,
    color: theme.palette.gray[700],
  },
}));
