import { FunctionComponent } from 'react';
import { View } from 'react-native';
import { Text } from 'assets/components/text';
import { MessagingNoPatients } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import { useMessagingStyles } from '../common/styles';
import { Icon } from 'assets/components/icon';

export const PharmacyWithoutPatientsView: FunctionComponent = () => {
  const messagingStyles = useMessagingStyles();
  const styles = useStyles();

  return (
    <View style={messagingStyles.noPatientsWrapper}>
      <View style={styles.subWrapper}>
        <MessagingNoPatients />
        <Text style={styles.noPatientsText}>
          {getText('patient-list-not-yet-imported')}
        </Text>
        <Text style={styles.noPatientsSecondaryText}>
          {getText('patient-list-not-imported-subtext')}
        </Text>
      </View>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  subWrapper: {
    width: 600,
    alignItems: 'center',
  },
  noPatientsText: {
    color: theme.palette.gray[900],
    fontSize: 20,
    fontFamily: 'Lato_700Bold',
  },
  noPatientsSecondaryText: {
    textAlign: 'center',
    fontFamily: 'Lato_400Regular',
    marginTop: theme.getSpacing(1.5),
    color: theme.palette.gray[700],
  },
}));
