import { FC } from 'react';
import theme, { makeStyles } from 'assets/theme';
import { BulkStatus } from '@digitalpharmacist/unified-communications-service-client-axios';
import { IRendererProps } from '../types';
import { useBulkTableStyles } from '../commonStyles';
import {
  AlertTriangleIcon,
  ArrowUpRightIcon,
  CancelCircleIcon,
  CheckIcon,
  ClockIcon,
} from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { IconProps } from 'assets/icons/types';
import { Icon } from 'assets/components/icon';

export const StatusRenderer: FC<
  IRendererProps & { iconSize?: number; customStyle?: any }
> = ({ data, iconSize = 20, customStyle }) => {
  const bulkTableStyles = useBulkTableStyles();
  const styles = useStyles();

  let StatusIcon: FC<IconProps>;
  let statusText;
  let style;
  switch (data.status) {
    case BulkStatus.Sent:
      StatusIcon = CheckIcon;
      statusText = BulkStatus.Sent;
      style = styles.statusSent;
      break;

    case BulkStatus.InProgress:
      statusText = 'sending' as const;
      StatusIcon = ArrowUpRightIcon;
      style = styles.inProgress;

      break;

    case BulkStatus.Scheduled:
      statusText = BulkStatus.Scheduled;
      StatusIcon = ClockIcon;
      style = bulkTableStyles.text;

      break;

    case BulkStatus.Failed:
      statusText = BulkStatus.Failed;
      StatusIcon = AlertTriangleIcon;
      style = styles.failed;

      break;

    case BulkStatus.Canceled:
      statusText = BulkStatus.Canceled;
      StatusIcon = CancelCircleIcon;
      style = styles.canceled;

      break;

    default:
      statusText = BulkStatus.InProgress;
      StatusIcon = ClockIcon;
      style = bulkTableStyles.text;
  }

  return (
    <div style={bulkTableStyles.iconAndTextContainer}>
      <Icon size={iconSize} {...style} icon={StatusIcon} />
      <div style={{ ...style, ...customStyle }}>{getText(statusText)}</div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  statusSent: {
    color: theme.palette.success[600],
  },
  failed: {
    color: theme.palette.error[600],
  },
  canceled: {
    color: theme.palette.gray[500],
  },
  inProgress: {
    color: theme.palette.orange[400],
  },
}));
