import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Svg, Path, Rect, Circle } from 'react-native-svg';
import { IconProps } from './types';

export const AppointmentsNotificationIcon: FunctionComponent<
  PropsWithChildren<IconProps>
> = ({ color, size, testID, strokeWidth }) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
      testID={testID}
    >
      <Circle cx={24} cy={24} r={24} fill="#EAF1F4" stroke="transparent" />
      <Path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M33 26a2 2 0 0 1-2 2H19l-4 4V16a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v10Z"
      />
      <Circle cx={24} cy={24} r={24} fill="#EAF1F4" stroke="transparent" />
      <Path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M28 13v4m-8-4v4m-5 4h18m-16-6h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H17a2 2 0 0 1-2-2V17a2 2 0 0 1 2-2Z"
      />
    </Svg>
  );
};
