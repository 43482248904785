import React from 'react';
import { View } from 'react-native';
import {
  useFormContext,
  useFieldArray,
  RegisterOptions,
  Controller,
} from 'react-hook-form';
import { Text } from 'assets/components/text';
import { CheckboxField, CheckboxInputMode } from 'assets/components/checkbox';
import { makeStyles } from 'assets/theme';

export interface CheckboxGroupItem<T> {
  id?: string;
  label: T;
  enabled: boolean;
}

export const CheckboxGroupField = ({
  name,
  rules,
  mode = CheckboxInputMode.FLAT,
}: CheckboxGroupFieldProps) => {
  const styles = useStyles();
  const { control, formState, getValues } = useFormContext();
  const { fields } = useFieldArray({ control, name });
  const errors = formState.errors[name];
  const hasErrors = errors && !fields?.some((_, index) => !(index in errors));

  const values: CheckboxGroupItem<string>[] = getValues(name);
  const hasAtLeastOneSelected = values.some((x) => x.enabled);

  return (
    <>
      <Controller
        control={control}
        render={() => (
          <View style={styles.multiselectCheckboxes}>
            {fields.map((field, index) => (
              <CheckboxField
                key={field.id}
                name={`${name}.${index}.enabled`}
                label={values[index].label}
                hideRequiredAsterisk
                mode={mode}
                rules={{
                  required: !hasAtLeastOneSelected && rules?.required,
                }}
              />
            ))}
          </View>
        )}
        name={name}
        rules={rules}
      />

      {hasErrors && (
        <Text
          testID={CheckboxGroupFieldTestIDs.error}
          style={styles.errorMessage}
        >
          {errors[0]?.enabled.message}
        </Text>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    color: theme.palette.error[600],
    fontSize: 14,
    marginTop: theme.getSpacing(1),
  },
  multiselectCheckboxes: {
    gap: theme.getSpacing(2),
    paddingTop: theme.getSpacing(2),
  },
}));

export interface CheckboxGroupFieldProps {
  name: string;
  rules?: RegisterOptions;
  mode?: CheckboxInputMode;
}

export const CheckboxGroupFieldTestIDs = {
  error: 'cgf-error',
};
