import React, { useCallback, useEffect } from 'react';
import { Modal } from 'assets/components/modal';
import { getText } from 'assets/localization/localization';
import { Form } from 'assets/layout';
import { useForm } from 'assets/form';
import { useUserAuthenticationModalState } from './user-authentication-modal-store';
import {
  handleResetAuthData,
  handleSendVaccineInfo,
  login,
  resetError,
  updateShowModalState,
} from './user-authentication-modal-actions';
import { LoginTestIDs } from '../../screens/login/LoginTestIDs';
import * as validate from '@digitalpharmacist/validation-dp';
import { TextField } from 'assets/components/text-field';
import { LoginForm } from '../../screens/login/login-actions';
import {
  handleResetVaccinationData,
  updateShowModalState as updateVaccinationShowModalState,
} from '../vaccination-modal/vaccination-modal-actions';
import { useVaccinationModalState } from '../vaccination-modal/vaccination-modal-store';
import { VaccinationDto } from '@digitalpharmacist/forms-service-client-axios';
import { useViewSubmissionStore } from '../../screens/forms/view-submission-store';
import { LoadingOverlay } from '../LoadingOverlay';

export const UserAuthenticationModal = () => {
  const { submission } = useViewSubmissionStore();
  const { showModal, status, error, resetAuthData } =
    useUserAuthenticationModalState();
  const { vaccination } = useVaccinationModalState();

  const methods = useForm<LoginForm>({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  useEffect(() => {
    if (showModal && resetAuthData) {
      methods.reset();
      handleResetAuthData(false);
    }
  }, [methods, showModal, resetAuthData]);

  const closeModal = useCallback(() => {
    updateShowModalState(false);
  }, []);

  const handleCancelAction = useCallback(() => {
    methods.reset();
    resetError();
    closeModal();
    handleResetVaccinationData(true);
  }, [methods]);

  const handleBackAction = useCallback(() => {
    closeModal();
    updateVaccinationShowModalState(true);
  }, [closeModal]);

  const handleSubmit = useCallback(async () => {
    const userId = await login(methods.getValues());

    if (userId === undefined || !vaccination || !submission) {
      return;
    }

    const vaccinationDto: VaccinationDto = {
      ...vaccination,
      created_by: userId,
    };

    await handleSendVaccineInfo(
      vaccinationDto,
      submission.location_id,
      submission.form_id,
      submission.submission_id,
      closeModal,
    );
  }, [vaccination, submission, closeModal]);

  return (
    <Modal
      title={getText('user-authentication')}
      size="sm"
      show={showModal}
      dismissButtonProps={{
        onPress: handleCancelAction,
        logger: { id: 'user-authentication-modal-cancel-button-modal' },
      }}
      cancelButtonProps={{
        onPress: handleBackAction,
        hierarchy: 'tertiary-gray',
        logger: { id: 'user-authentication-modal-cancel-button-modal' },
        text: getText('back'),
      }}
      okButtonProps={{
        onPress: methods.handleSubmit(handleSubmit),
        logger: { id: 'user-authentication-modal-ok-button-modal' },
        hierarchy: 'primary',
        text: getText('ok'),
      }}
      handleDismissModal={handleCancelAction}
      showDismissButton={true}
    >
      <Form methods={methods}>
        {status === 'loading' && <LoadingOverlay />}
        <Form.Alert title={error?.message} intent="error" visible={!!error} />
        <Form.Row>
          <Form.Column>
            <TextField
              label={getText('email')}
              name="email"
              rules={{
                required: getText('email-is-required'),
                validate: {
                  value: () => {
                    return validate.isEmail(methods.getValues().email)
                      ? true
                      : getText('email-is-not-valid');
                  },
                },
              }}
              disabled={status === 'loading'}
              testID={LoginTestIDs.usernameInput}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              label={getText('password')}
              type="password"
              name="password"
              rules={{
                required: getText('password-is-required'),
              }}
              disabled={status === 'loading'}
              testID={LoginTestIDs.passwordInput}
            />
          </Form.Column>
        </Form.Row>
      </Form>
    </Modal>
  );
};
