import { TaskDto } from '@digitalpharmacist/tasks-service-client-axios';
import create from 'zustand';

import { ApiError, AsyncStatus } from '../../store/types';
import { DetailedUser } from '@digitalpharmacist/role-service-client-axios';

export const useTaskModalState = create<TaskModalState>((set) => ({
  showModal: false,
  assigneeOptions: [],
  status: 'idle',
  editingTask: false,
  taskStatus: 'idle',
}));

interface TaskModalState {
  showModal: boolean;
  assigneeOptions: DetailedUser[];
  status: AsyncStatus;
  error?: ApiError;
  editingTask: boolean;
  editTaskData?: TaskDto;
  taskStatus: AsyncStatus;
}
