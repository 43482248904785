import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path } from 'react-native-svg';
import { IconProps } from './types';

export const FileIcon: FunctionComponent<PropsWithChildren<IconProps>> = ({
  color,
  size,
  testID,
  strokeWidth,
}) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
      testID={testID}
    >
      <Path
        d="M7.66669 1.33331H3.00002C2.6464 1.33331 2.30726 1.47379 2.05721 1.72384C1.80716 1.97389 1.66669 2.31302 1.66669 2.66665V13.3333C1.66669 13.6869 1.80716 14.0261 2.05721 14.2761C2.30726 14.5262 2.6464 14.6666 3.00002 14.6666H11C11.3536 14.6666 11.6928 14.5262 11.9428 14.2761C12.1929 14.0261 12.3334 13.6869 12.3334 13.3333V5.99998M7.66669 1.33331L12.3334 5.99998M7.66669 1.33331V5.99998H12.3334"
        stroke="color"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
