import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import { makeStyles, useTheme } from 'assets/theme';
import {
  ScrollView,
  View,
  StyleProp,
  ViewStyle,
  useWindowDimensions,
} from 'react-native';

export interface PharmacyScreenContainerHandler {
  scrollTo?(
    y?:
      | number
      | {
          x?: number | undefined;
          y?: number | undefined;
          animated?: boolean | undefined;
        },
    x?: number,
    animated?: boolean,
  ): void;
}

export const PharmacyScreenContainer = forwardRef<
  PharmacyScreenContainerHandler,
  PharmacyScreenContainerProps
>(
  (
    {
      screenHeader,
      children,
      backgroundColor,
      style,
      disableScrollView = true,
      small,
    },
    ref,
  ) => {
    const theme = useTheme();
    const scrollViewRef = useRef<ScrollView>(null);
    const styles = useStyles();

    useImperativeHandle(ref, () => ({
      scrollTo: scrollViewRef.current?.scrollTo,
    }));

    const { width } = useWindowDimensions();
    let maxWidth = width;
    if (width > theme.webMaxWidth) {
      maxWidth = theme.webMaxWidth;
    }

    const getPadding = () => {
      if (maxWidth <= 1280) {
        return 64; // Minimum padding for small screens
      } else if (maxWidth >= 1500) {
        return 120; // Maximum padding for large screens
      } else {
        // Interpolating between 1280 and 1500
        const padding = 64 + ((maxWidth - 1280) / (1500 - 1280)) * (120 - 64);
        return padding;
      }
    };

    return (
      <>
        {screenHeader}
        <View
          style={[
            styles.root,
            {
              backgroundColor: backgroundColor ?? theme.palette.white,
              overflow: disableScrollView ? 'scroll' : 'hidden',
              flexGrow: disableScrollView ? undefined : 1, // Only apply flexGrow if scrolling is enabled
              flex: disableScrollView ? 1 : undefined,
            },
          ]}
        >
          <View
            style={[
              small ? styles.smallContainer : styles.container,
              {
                backgroundColor: backgroundColor ?? theme.palette.white,
                width: small ? maxWidth - theme.getSpacing(2) : 'auto',
                paddingBottom: getPadding(),
              },
              style,
            ]}
          >
            {children}
          </View>
        </View>
      </>
    );
  },
);

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  root: {
    padding: theme.getSpacing(3),
  },
  smallContainer: {
    display: 'flex',
    flex: 1,
    height: '100%',
    flexDirection: 'column',
    alignSelf: 'center',
  },
}));

export interface PharmacyScreenContainerProps {
  backgroundColor?: string;
  style?: StyleProp<ViewStyle>;
  testID?: string;
  children?: React.ReactNode;
  screenHeader?: React.ReactNode;
  disableScrollView?: boolean;
  small?: boolean;
}
