import {
  AutoSaveWebsiteAccessDto,
  AutoSaveWebsiteWizardDto,
  YesNoOption,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { Modal } from 'assets/components/modal';
import { RadioButtonGroupField } from 'assets/components/radio-button-group';
import { RadioButtonInputMode } from 'assets/components/radio-button-group/RadioButton';
import { Text } from 'assets/components/text';
import { Form } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  savePatientWebsite,
  setOpenedModal,
} from '../../../../actions/checklist-actions';
import { useChecklistStore } from '../../../../store/checklist-store';
import { InfoTooltip } from '../../../onboarding/components/common/InfoTooltip';
import { ChecklistWebsiteModalProps } from '../../types';

export interface WebsiteAccessSetup {
  existing_domain: YesNoOption | undefined;
  accessible: YesNoOption | undefined;
}

export const WebsiteAccessSetupModal: FunctionComponent<
  ChecklistWebsiteModalProps
> = ({ id, data }) => {
  const { currentOpenedModalId } = useChecklistStore();
  const [loading, setLoading] = useState<boolean>(false);
  const styles = useStyles();
  const { existing_domain, accessible } = data.website_access;

  const methods = useForm<WebsiteAccessSetup>({
    defaultValues: {
      existing_domain:
        existing_domain !== YesNoOption.Unknown ? existing_domain : undefined,
      accessible: accessible !== YesNoOption.Unknown ? accessible : undefined,
    },
  });

  const existingDomain = methods.watch('existing_domain');
  const accessibleDomain = methods.watch('accessible');

  useEffect(() => {
    if ((existingDomain as any) === 'No') {
      methods.setValue('accessible', 'Unknown');
    }
  }, [existingDomain]);

  useEffect(() => {
    const { accessible, existing_domain } = data.website_access;
    methods.setValue(
      'accessible',
      accessible !== YesNoOption.Unknown ? accessible : undefined,
    );
    methods.setValue(
      'existing_domain',
      existing_domain !== YesNoOption.Unknown ? existing_domain : undefined,
    );
  }, [data]);

  const handleBackButton = () => {
    const service = data.website_required;
    switch (service) {
      case 'build-do-not-have':
      case 'build-to-replace':
        setOpenedModal('patient-website-locations');
        break;
      case 'do-not-build-already-have':
        setOpenedModal('patient-website');
        break;
      case 'do-not-build-do-not-need':
        setOpenedModal('patient-website-notification');
        break;
      default:
        setOpenedModal('patient-website');
        break;
    }
  };

  const openNextModalId = (website_access: AutoSaveWebsiteAccessDto) => {
    const hasDomain = website_access.existing_domain === ('Yes' as any);
    const hasAccess = website_access.accessible === ('Yes' as any);

    setOpenedModal(
      hasDomain && hasAccess
        ? 'website-access-permission'
        : 'website-access-completed',
    );
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const dto: AutoSaveWebsiteWizardDto = {
        ...data,
        website_access: {
          ...data.website_access,
          existing_domain: existingDomain,
          accessible: accessibleDomain,
          domain_details:
            accessibleDomain === ('Yes' as any)
              ? data.website_access.domain_details
              : undefined,
        },
      };
      await savePatientWebsite(dto);
      openNextModalId(dto.website_access);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={getText('website-domain')}
      show={currentOpenedModalId === id}
      handleDismissModal={() => setOpenedModal('checklist-setup')}
      showDismissButton
      isScrollable
      size="sm"
      buttons={[
        {
          hierarchy: 'primary',
          text: getText('next'),
          onPress: methods.handleSubmit(handleSubmit),
          logger: { id: 'next-website-access-setup' },
          loading,
        },
        {
          text: getText('back'),
          hierarchy: 'tertiary-gray',
          onPress: handleBackButton,
          logger: { id: 'back-website-access-setup' },
        },
      ]}
    >
      <Form methods={methods}>
        <Form.Row>
          <Form.Column>
            <Text style={styles.title}>
              {getText('website-you-like-to-use') + '*'}
              <InfoTooltip
                style={styles.tootlip}
                size={20}
                tooltipId="website-you-like-to-use"
                text={getText('website-domain-first-tooltip')}
              />
            </Text>
            <RadioButtonGroupField
              name="existing_domain"
              isHorizontal
              mode={RadioButtonInputMode.FLAT}
              values={[
                {
                  text: getText('yes'),
                  value: 'Yes',
                },
                {
                  text: getText('no'),
                  value: 'No',
                },
              ]}
              rules={{
                required:
                  (!existingDomain || existingDomain === YesNoOption.Unknown) &&
                  getText('field-required'),
              }}
            />
            <Text style={styles.title}>
              {getText('website-how-to-access-domain') +
                (existingDomain === ('Yes' as any) ? '*' : '')}
              <InfoTooltip
                style={styles.tootlip}
                size={20}
                tooltipId="website-how-to-access-domain"
                text={getText('website-domain-second-tooltip')}
              />
            </Text>
            <RadioButtonGroupField
              name="accessible"
              isHorizontal
              disabled={existingDomain !== ('Yes' as any)}
              mode={RadioButtonInputMode.FLAT}
              values={[
                {
                  text: getText('yes'),
                  value: 'Yes',
                },
                {
                  text: getText('no'),
                  value: 'No',
                },
              ]}
              rules={{
                required:
                  existingDomain === ('Yes' as any) &&
                  getText('field-required'),
              }}
            />
          </Form.Column>
        </Form.Row>
      </Form>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.gray[700],
  },
  tootlip: {
    left: theme.getSpacing(1),
    top: theme.getSpacing(0.5),
  },
  radioButton: {
    marginLeft: -16,
    marginBottom: -24,
    marginTop: -24,
    display: 'flex',
    flexDirection: 'row',
  },
}));
