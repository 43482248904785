import React, {
  FunctionComponent,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { makeStyles } from 'assets/theme';
import { Pressable, Text, View } from 'react-native';
import { getText } from 'assets/localization/localization';
import {
  findAllergiesById,
  findConditionsById,
} from '../../screens/refill-submissions/medication-actions';
import { ResourceItem } from '../../api/MedicationService';
import InsuranceCardPhotoModal from '../../components/InsuranceDetailsModal/InsuranceCardPhotoModal';
import { TaskMetadata as TaskMetadataDto } from '@digitalpharmacist/tasks-service-client-axios';
import { formatDate } from '../../common/datetime-utils';

export const TaskMetadataForPatientRecordUpdate: FunctionComponent<
  PropsWithChildren<TaskMetadataProps>
> = ({ metadata, patientFullName, patientDateOfBirth }) => {
  const styles = useStyles();
  const [showInsuranceModal, setShowInsuranceModal] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState('');
  const [selectedFileId, setSelectedFileId] = useState('');
  const {
    easy_open_bottle_caps,
    med_sync,
    medical_conditions: medicalConditionsIds,
    insurance_card_primary_front_url,
    insurance_card_primary_back_url,
    insurance_card_secondary_front_url,
    insurance_card_secondary_back_url,
    allergies,
    prefers_paperless,
  } = metadata;
  const [medicalConditionsList, setMedicalConditionsList] =
    useState<ResourceItem[]>();
  const [allergiesList, setAllergiesList] = useState<ResourceItem[]>();

  useEffect(() => {
    if (!medicalConditionsIds) return;

    void (async () => {
      if (medicalConditionsIds.length > 0) {
        const loadedMedicalConditions =
          await findConditionsById(medicalConditionsIds);
        setMedicalConditionsList(loadedMedicalConditions);
      } else {
        setMedicalConditionsList([]);
      }
    })();
  }, [medicalConditionsIds]);

  useEffect(() => {
    if (allergies === undefined) return;

    void (async () => {
      if (allergies.length > 0) {
        const loadedAllergies = await findAllergiesById(allergies);
        setAllergiesList(loadedAllergies);
      } else {
        setAllergiesList([]);
      }
    })();
  }, [allergies]);

  function toggleShowInsuranceModal(title: string, fileId: string): void {
    setSelectedTitle(title);
    setSelectedFileId(fileId);
    setShowInsuranceModal((prev) => !prev);
  }

  const addInsuranceInfoRow = useCallback(
    (
      changedValueColumnValues: ReactNode[],
      insuranceCard: string | null | undefined,
      addText: string,
      removeText: string,
    ) => {
      if (insuranceCard) {
        changedValueColumnValues.push(
          <Pressable
            onPress={() => toggleShowInsuranceModal(addText, insuranceCard)}
          >
            <Text style={[styles.details]}>{addText}</Text>
          </Pressable>,
        );
      } else if (insuranceCard === null) {
        changedValueColumnValues.push(<Text>{removeText}</Text>);
      }
    },
    [],
  );

  const displayInsuranceInfoRows = useCallback(() => {
    if (
      insurance_card_primary_front_url === undefined &&
      insurance_card_primary_back_url === undefined &&
      insurance_card_secondary_front_url === undefined &&
      insurance_card_secondary_back_url === undefined
    ) {
      return;
    }

    const changedValueColumnValues: ReactNode[] = [];
    addInsuranceInfoRow(
      changedValueColumnValues,
      insurance_card_primary_front_url,
      getText('new-insurance-card-primary-front'),
      getText('removed-insurance-card-primary-front'),
    );
    addInsuranceInfoRow(
      changedValueColumnValues,
      insurance_card_primary_back_url,
      getText('new-insurance-card-primary-back'),
      getText('removed-insurance-card-primary-back'),
    );
    addInsuranceInfoRow(
      changedValueColumnValues,
      insurance_card_secondary_front_url,
      getText('new-insurance-card-secondary-front'),
      getText('removed-insurance-card-secondary-front'),
    );
    addInsuranceInfoRow(
      changedValueColumnValues,
      insurance_card_secondary_back_url,
      getText('new-insurance-card-secondary-back'),
      getText('removed-insurance-card-secondary-back'),
    );

    return (
      <View style={styles.metadataTableRow}>
        <View style={styles.metadataCell}>{getText('insurance-changes')}</View>
        <View
          style={[
            styles.metadataCell,
            styles.metadataValueCell,
            styles.insuranceChangesValueRowContainer,
          ]}
        >
          {changedValueColumnValues.map((value: ReactNode, count) => (
            <View
              style={[
                styles.insuranceChangesValueRow,
                count === changedValueColumnValues.length - 1 &&
                  styles.insuranceChangesValueLastRow,
              ]}
            >
              {value}
            </View>
          ))}
        </View>
      </View>
    );
  }, [
    insurance_card_primary_front_url,
    insurance_card_primary_back_url,
    insurance_card_secondary_front_url,
    insurance_card_secondary_back_url,
  ]);

  return (
    <View style={styles.metadataTable}>
      <View style={styles.metadataTableHeader}>
        <View style={styles.metadataHeaderCell}>{getText('data')}</View>
        <View style={[styles.metadataHeaderCell, styles.metadataValueCell]}>
          {getText('changed-value')}
        </View>
      </View>
      <>
        {easy_open_bottle_caps !== undefined && (
          <View style={styles.metadataTableRow}>
            <View style={styles.metadataCell}>
              {getText('easy-open-bottle-caps-field')}
            </View>
            <View style={[styles.metadataCell, styles.metadataValueCell]}>
              {getText(easy_open_bottle_caps ? 'yes' : 'no')}
            </View>
          </View>
        )}
        {med_sync !== undefined && (
          <View style={styles.metadataTableRow}>
            <View style={styles.metadataCell}>{getText('med-sync-field')}</View>
            <View style={[styles.metadataCell, styles.metadataValueCell]}>
              {getText(med_sync ? 'yes' : 'no')}
            </View>
          </View>
        )}
        {prefers_paperless !== undefined && (
          <>
            <View style={styles.metadataTableRow}>
              <View style={styles.metadataCell}>{getText('patient-name')}</View>
              <View style={[styles.metadataCell, styles.metadataValueCell]}>
                {patientFullName}
              </View>
            </View>
            <View style={styles.metadataTableRow}>
              <View style={styles.metadataCell}>
                {getText('date-of-birth-short')}
              </View>
              <View style={[styles.metadataCell, styles.metadataValueCell]}>
                {patientDateOfBirth ? formatDate(patientDateOfBirth) : ''}
              </View>
            </View>
            <View style={styles.metadataTableRow}>
              <View style={styles.metadataCell}>
                {getText('paperless-screen-title')}
              </View>
              <View style={[styles.metadataCell, styles.metadataValueCell]}>
                {getText(prefers_paperless ? 'yes' : 'no')}
              </View>
            </View>
          </>
        )}
        {medicalConditionsList && (
          <View style={styles.metadataTableRow}>
            <View style={styles.metadataCell}>
              {getText('medical-conditions-without-colon')}
            </View>
            <View style={[styles.metadataCell, styles.metadataValueCell]}>
              <Text>
                {medicalConditionsList.length > 0
                  ? medicalConditionsList.map((a) => a.text).join(', ')
                  : getText('no-known-medical-conditions')}{' '}
              </Text>
            </View>
          </View>
        )}
        {allergiesList && (
          <View style={styles.metadataTableRow}>
            <View style={styles.metadataCell}>{getText('allergies')}</View>
            <View style={[styles.metadataCell, styles.metadataValueCell]}>
              <Text>
                {allergiesList.length > 0
                  ? allergiesList.map((a) => a.text).join(', ')
                  : getText('no-known-allergies')}
              </Text>
            </View>
          </View>
        )}
        {displayInsuranceInfoRows()}
        {selectedFileId && selectedTitle && (
          <InsuranceCardPhotoModal
            show={showInsuranceModal}
            setShowModal={setShowInsuranceModal}
            title={selectedTitle}
            fileId={selectedFileId}
          ></InsuranceCardPhotoModal>
        )}
      </>
    </View>
  );
};

export const useStyles = makeStyles((theme) => ({
  metadataTable: {
    flexDirection: 'column',
    borderWidth: 1,
    borderColor: theme.palette.gray[300],
    borderRadius: theme.roundness,
    width: '100%',
    maxWidth: '100%',
  },
  metadataTableHeader: {
    flexDirection: 'row',
  },
  metadataHeaderCell: {
    ...theme.lumistryFonts.text.small.bold,
    flex: 1,
    textAlign: 'left',
    padding: theme.getSpacing(1),
  },
  metadataTableRow: {
    flexDirection: 'row',
    borderTopWidth: 1,
    borderColor: theme.palette.gray[300],
  },
  metadataCell: {
    ...theme.lumistryFonts.text.small.regular,
    flex: 1,
    textAlign: 'left',
    padding: theme.getSpacing(1),
    textTransform: 'capitalize',
  },
  metadataValueCell: {
    borderLeftWidth: 1,
    borderColor: theme.palette.gray[300],
  },
  details: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.primary[600],
  },
  insuranceChangesValueRowContainer: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  insuranceChangesValueRow: {
    borderBottomWidth: 1,
    borderColor: theme.palette.gray[300],
    marginLeft: -theme.getSpacing(1),
    marginRight: -theme.getSpacing(1),
    padding: theme.getSpacing(1),
  },
  insuranceChangesValueLastRow: {
    borderBottomWidth: 0,
  },
  medicationRow: {
    marginBottom: theme.getSpacing(1),
  },
}));

interface TaskMetadataProps {
  metadata: TaskMetadataDto;
  patientFullName: string;
  patientDateOfBirth: string | undefined;
}

export default TaskMetadataForPatientRecordUpdate;
