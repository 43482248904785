import { AxiosRequestConfig } from 'axios';
import { UNIFIED_COMMS_SERVICE_BASE_URL } from '../common/constants';
import { BaseApiConfiguration, BaseApiService } from 'assets/core/api';
import {
  DefaultApi as UnifiedCommsClient,
  DirectMessagePatientDto,
  ConversationDto,
  DirectMessageConversationFiltersDto,
  DirectMessageDto,
  UpdateUserViewedStatusDto,
  CreateConversationDto,
  AddDirectMessageDto,
  ConversationCountDto,
  DirectMessageExtendedResponseDto,
  ModifyDirectMessageSettingsDto,
  DirectMessageSettingsDto,
  GetDirectMessageSettingsDto,
  BulkDto,
  AutoMessageConfigDto,
  BulkMessageType,
  BulkStatus,
  UpdateAutoMessageConfigDto,
  LocationEmailUsageDto,
  LocationSmsUsageDto,
  CreateNonSecureBulkMessagesDto,
  CreateSecureBulkMessagesDto,
  NewsletterResponseDto,
  BulkRecipientsResultDto,
} from '@digitalpharmacist/unified-communications-service-client-axios';
import { AXIOS_DEFAULT_REQUEST_CONFIG } from '../common/axios-default-request-config';
import { ampli } from '../src/ampli';

export interface IUnifiedCommsService {
  getAllFilteredConversations(
    locationId: string,
    directMessageConversationFiltersDto: DirectMessageConversationFiltersDto,
    options?: AxiosRequestConfig<any>,
  ): Promise<DirectMessagePatientDto[]>;
  getAllMessagesByConversation(
    locationId: string,
    locationPatientId: string,
    conversationId: string,
    skip?: number,
    take?: number,
  ): Promise<DirectMessageExtendedResponseDto>;
  createConversation(
    xPharmacyId: string,
    locationId: string,
    locationPatientId: string,
    createConversationDto: CreateConversationDto,
  ): Promise<ConversationDto>;
  getConversation(
    locationId: string,
    locationPatientId: string,
    conversationId: string,
  ): Promise<DirectMessagePatientDto>;
  updateUserViewedStatus(
    locationId: string,
    locationPatientId: string,
    conversationId: string,
    updateUserViewedStatusDto: UpdateUserViewedStatusDto,
  ): Promise<ConversationDto>;
  addMessage(
    xPharmacyId: string,
    locationId: string,
    locationPatientId: string,
    conversationId: string,
    addDirectMessageDto: AddDirectMessageDto,
  ): Promise<DirectMessageDto>;
  getNotViewedChatsCountByPharmacy(
    xPharmacyId: string,
    locationId: string,
  ): Promise<ConversationCountDto>;
  bulkMessagesCreateNonSecure(
    xPharmacyId: string,
    locationId: string,
    createNonSecureBulkMessagesDto: CreateNonSecureBulkMessagesDto,
  ): Promise<BulkDto>;
  bulkMessagesCreateSecure(
    xPharmacyId: string,
    locationId: string,
    createSecureBulkMessagesDto: CreateSecureBulkMessagesDto,
  ): Promise<BulkDto>;
  getBulks(
    locationId: string,
    status?: BulkStatus,
    message_type?: BulkMessageType,
  ): Promise<BulkDto[]>;
  getBulksCount(
    locationId: string,
    status?: BulkStatus,
    message_type?: BulkMessageType,
  ): Promise<number>;
  autoMessageConfigGet(locationId: string): Promise<AutoMessageConfigDto[]>;
  autoMessageConfigCreateOrUpdateAutoMessageConfig(
    locationId: string,
    updateAutoMessageConfigDto: UpdateAutoMessageConfigDto,
  ): Promise<AutoMessageConfigDto>;
  getSmsRecordFindCurrentMonthUsage(
    locationId: string,
  ): Promise<LocationSmsUsageDto>;
  getEmailRecordFindCurrentMonthUsage(
    locationId: string,
  ): Promise<LocationEmailUsageDto>;
  getRecipients(
    locationId: string,
    bulk_id: string,
    skip?: number,
    take?: number,
    search?: string,
  ): Promise<BulkRecipientsResultDto>;
  getEmailRecordFindCurrentMonthUsagePlatform(
    locationId: string,
  ): Promise<LocationEmailUsageDto>;
  getSmsRecordFindCurrentMonthUsagePlatform(
    locationId: string,
  ): Promise<LocationSmsUsageDto>;
  cancelScheduledBulk(bulk_id: string): Promise<BulkDto>;
  getUserSubscriptionForPaymentsAndDeliveryCard(
    pharmacyId: string,
    locationId: string,
    userId: string,
  ): Promise<NewsletterResponseDto>;
}

export class UnifiedCommsService
  extends BaseApiService
  implements IUnifiedCommsService
{
  private unifiedCommsServiceClient: UnifiedCommsClient;

  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration,
  ) {
    super(baseUrl, config, enableAuth, baseConfig);
    this.unifiedCommsServiceClient = new UnifiedCommsClient(
      undefined,
      baseUrl,
      this.axiosInstance,
    );
  }

  async autoMessageConfigCreateOrUpdateAutoMessageConfig(
    locationId: string,
    updateAutoMessageConfigDto: UpdateAutoMessageConfigDto,
  ): Promise<AutoMessageConfigDto> {
    const { data } =
      await this.unifiedCommsServiceClient.autoMessageConfigCreateOrUpdateAutoMessageConfig(
        locationId,
        updateAutoMessageConfigDto,
      );
    return data;
  }

  async getAllFilteredConversations(
    locationId: string,
    directMessageConversationFiltersDto: DirectMessageConversationFiltersDto,
    options?: AxiosRequestConfig<any>,
  ): Promise<DirectMessagePatientDto[]> {
    const { data } =
      await this.unifiedCommsServiceClient.directMessageFilterConversationList(
        locationId,
        directMessageConversationFiltersDto,
        options,
      );

    return data;
  }

  async getAllMessagesByConversation(
    locationId: string,
    locationPatientId: string,
    conversationId: string,
    skip?: number,
    take?: number,
  ): Promise<DirectMessageExtendedResponseDto> {
    const { data } =
      await this.unifiedCommsServiceClient.directMessageGetAllMessagesByConversation(
        locationId,
        locationPatientId,
        conversationId,
        skip,
        take,
      );
    return data;
  }

  async updateUserViewedStatus(
    locationId: string,
    locationPatientId: string,
    conversationId: string,
    updateUserViewedStatusDto: UpdateUserViewedStatusDto,
  ): Promise<ConversationDto> {
    const { data } =
      await this.unifiedCommsServiceClient.directMessageUpdateUserViewedStatus(
        locationId,
        locationPatientId,
        conversationId,
        updateUserViewedStatusDto,
      );
    return data;
  }

  async createConversation(
    xPharmacyId: string,
    locationId: string,
    locationPatientId: string,
    createConversationDto: CreateConversationDto,
  ): Promise<ConversationDto> {
    const { data } =
      await this.unifiedCommsServiceClient.directMessageCreateConversation(
        xPharmacyId,
        locationId,
        locationPatientId,
        createConversationDto,
      );
    ampli.messageSent({
      conversationID: data.id,
      messageID: '',
      messageSentTime: new Date().toISOString(),
      messageSubject: data.subject,
      messageTemplateSelected: data.subject,
    });
    return data;
  }

  async getConversation(
    locationId: string,
    locationPatientId: string,
    conversationId: string,
  ): Promise<DirectMessagePatientDto> {
    const { data } =
      await this.unifiedCommsServiceClient.directMessageGetConversation(
        locationId,
        locationPatientId,
        conversationId,
      );
    return data;
  }

  async getNotViewedChatsCountByPharmacy(
    xPharmacyId: string,
    locationId: string,
  ): Promise<ConversationCountDto> {
    const { data } =
      await this.unifiedCommsServiceClient.directMessageGetNotViewedChatsCountByPharmacy(
        xPharmacyId,
        locationId,
      );
    return data;
  }

  async addMessage(
    xPharmacyId: string,
    locationId: string,
    locationPatientId: string,
    conversationId: string,
    addDirectMessageDto: AddDirectMessageDto,
  ): Promise<DirectMessageDto> {
    const { data } =
      await this.unifiedCommsServiceClient.directMessageAddMessage(
        xPharmacyId,
        locationId,
        locationPatientId,
        conversationId,
        addDirectMessageDto,
      );
    return data;
  }

  async modifyDirectMessageSettings(
    pharmacyId: string,
    locationId: string,
    modifyDirectMessageSettings: ModifyDirectMessageSettingsDto,
  ): Promise<DirectMessageSettingsDto> {
    const { data } =
      await this.unifiedCommsServiceClient.directMessageSettingsModify(
        pharmacyId,
        locationId,
        modifyDirectMessageSettings,
      );
    return data;
  }

  async getDirectMessageSettings(
    pharmacyId: string,
    locationId: string,
  ): Promise<GetDirectMessageSettingsDto> {
    const { data } =
      await this.unifiedCommsServiceClient.directMessageSettingsGet(
        pharmacyId,
        locationId,
      );
    return data;
  }

  async bulkMessagesCreateSecure(
    xPharmacyId: string,
    locationId: string,
    createSecureBulkMessagesDto: CreateSecureBulkMessagesDto,
  ): Promise<BulkDto> {
    const { data } =
      await this.unifiedCommsServiceClient.bulkMessagesCreateSecure(
        xPharmacyId,
        locationId,
        createSecureBulkMessagesDto,
      );
    return data;
  }

  async bulkMessagesCreateNonSecure(
    xPharmacyId: string,
    locationId: string,
    createNonSecureBulkMessagesDto: CreateNonSecureBulkMessagesDto,
  ): Promise<BulkDto> {
    const { data } =
      await this.unifiedCommsServiceClient.bulkMessagesCreateNonSecure(
        xPharmacyId,
        locationId,
        createNonSecureBulkMessagesDto,
      );
    return data;
  }

  async getBulks(
    locationId: string,
    status?: BulkStatus,
    message_type?: BulkMessageType,
  ): Promise<BulkDto[]> {
    const { data } = await this.unifiedCommsServiceClient.bulkMessagesGet(
      locationId,
      status,
      message_type,
    );
    return data;
  }

  async getBulksCount(locationId: string): Promise<number> {
    const { data } =
      await this.unifiedCommsServiceClient.bulkMessagesGetCount(locationId);
    return data;
  }

  async autoMessageConfigGet(
    locationId: string,
  ): Promise<AutoMessageConfigDto[]> {
    const { data } =
      await this.unifiedCommsServiceClient.autoMessageConfigGet(locationId);
    return data;
  }

  async getSmsRecordFindCurrentMonthUsage(
    locationId: string,
  ): Promise<LocationSmsUsageDto> {
    const { data } =
      await this.unifiedCommsServiceClient.smsRecordFindCurrentMonthUsage(
        locationId,
      );
    return data;
  }

  async getEmailRecordFindCurrentMonthUsage(
    locationId: string,
  ): Promise<LocationEmailUsageDto> {
    const { data } =
      await this.unifiedCommsServiceClient.emailRecordFindCurrentMonthUsage(
        locationId,
      );
    return data;
  }

  async getRecipients(
    locationId: string,
    bulk_id: string,
    skip?: number,
    take?: number,
    search?: string,
  ): Promise<BulkRecipientsResultDto> {
    const { data } =
      await this.unifiedCommsServiceClient.bulkMessagesGetRecipients(
        locationId,
        bulk_id,
        skip,
        take,
        search,
      );

    return data;
  }

  async getEmailRecordFindCurrentMonthUsagePlatform(
    locationId: string,
  ): Promise<LocationEmailUsageDto> {
    const { data } =
      await this.unifiedCommsServiceClient.emailRecordFindCurrentMonthUsagePlatform(
        locationId,
      );
    return data;
  }

  async getSmsRecordFindCurrentMonthUsagePlatform(
    locationId: string,
  ): Promise<LocationSmsUsageDto> {
    const { data } =
      await this.unifiedCommsServiceClient.smsRecordFindCurrentMonthUsagePlatform(
        locationId,
      );
    return data;
  }

  async cancelScheduledBulk(bulk_id: string) {
    const { data } =
      await this.unifiedCommsServiceClient.bulkMessagesCancel(bulk_id);
    return data;
  }

  async getUserSubscriptionForPaymentsAndDeliveryCard(
    pharmacyId: string,
    locationId: string,
    userId: string,
  ): Promise<NewsletterResponseDto> {
    const { data } = await this.unifiedCommsServiceClient.newsletterSubscribe({
      pharmacy_id: pharmacyId,
      location_id: locationId,
      campaign_code: 'settings_usage',
      user_id: userId,
    });
    return data;
  }
}

export default new UnifiedCommsService(
  UNIFIED_COMMS_SERVICE_BASE_URL,
  AXIOS_DEFAULT_REQUEST_CONFIG,
  true,
);
