import React, {
  useMemo,
  Fragment,
  useEffect,
  useState,
  ForwardRefRenderFunction,
  ForwardedRef,
  useImperativeHandle,
} from 'react';
import { View, TouchableOpacity } from 'react-native';
import { TextInput } from 'react-native-paper';
import { getText } from 'assets/localization/localization';
import { Text } from 'assets/components/text';
import { stripRichTextEditorElements } from 'assets/utils/messageUtils';
import { DropdownSelectField } from 'assets/components/dropdown-select';
import {
  AutoMessageConfigDto,
  AutoMessageConfigDtoEditableColumnsEnum,
  MessageConfigType,
  UpdateAutoMessageConfigDto,
} from '@digitalpharmacist/unified-communications-service-client-axios';
import { useAppStateStore } from '../../../../store/app-store';
import { useNewChatModalState } from '../../stores/new-chat-modal-store/new-chat-modal-store';
import { useErrorState } from '../../stores/error-store/error-store';
import { useMessagingStyles } from '../../common/styles';
import ReactQuill from 'react-quill';
import { SubmitHandler, useForm } from 'react-hook-form';
import { defaultTemplates } from '../../common/defaultTemplates';
import { MessageFields } from '../../common/types/MessageFields';
import { BIG_MESSAGE_LIMIT, EXCLUDED_ERRORS_MODAL } from '../../data';
import { ITemplate } from 'assets/types/messageTypes';
import { convertVariablesToPills } from '../../Quill/utils';
import { ErrorsContainer } from '../../components/ErrorsContainer';
import { Form } from 'assets/layout';
import { DateTimePickerField } from '../../../../components/DateTimePickerField';
import { AutoConfigMessageBodyEditor } from '../../components/AutoConfigMessageBodyEditor';
import { BaseModalHandler } from 'assets/components/base-modal/BaseModal';
import { makeStyles, useTheme } from 'assets/theme';
import { TextField } from 'assets/components/text-field';
import { FormProvider } from 'assets/form';
import { WebModal } from 'assets/components/base-modal/WebModal';
import {
  AUTO_CONFIG_TIME_FORMAT,
  createDayOptions,
  formatDateTime,
  monthOptions,
} from '../../../../common/datetime-utils';
import { AutoMessageConfig } from '../../common/types/AutoMessageConfig';
import moment from 'moment';
import { AutomationConfig } from '../../utils';
import { ToggleSwitch } from 'assets/components/toggle-switch';

export const EditAutoMessageConfigModal: ForwardRefRenderFunction<
  BaseModalHandler,
  EditAutoMessageConfigModalProps
> = (
  props: EditAutoMessageConfigModalProps,
  ref: ForwardedRef<BaseModalHandler>,
) => {
  const [errorFields, setErrorFields] = useState<Record<string, string>>({});
  const locationId = useAppStateStore((state) => state.locationId);
  const templatesFilter = useNewChatModalState(
    (state) => state.templatesFilter,
  );
  const { errorObject } = useErrorState();
  const messagingStyles = useMessagingStyles();
  const theme = useTheme();
  const [quillRef, setQuillRef] = useState<ReactQuill | null>(null);
  const sheetRef = React.useRef<BaseModalHandler>(null);
  const [messageLength, setMessageLength] = useState<number>(0);

  const [isActive, setIsActive] = useState(props.autoMessageConfig.is_active);

  // Update local state when props change
  useEffect(() => {
    setIsActive(props.autoMessageConfig.is_active);
  }, [props.autoMessageConfig.is_active]);

  const onTogglePressHandler = async (value: boolean) => {
    try {
      await props.togglePressHandler(value);
      // If API call succeeds, update local state to reflect the change
      setIsActive(value);
    } catch (error) {
      console.error('Failed to toggle:', error);
      // Optionally handle the error, e.g., showing a notification
    }
  };

  const styles = useStyles();

  const handleShow = () => {
    setIsActive(props.autoMessageConfig.is_active);
    form.reset({
      subject: subject,
      content: message,
      sendTime: sendTime,
      month: month,
      day: day,
    });
    sheetRef.current?.show();
  };

  const handleHide = () => {
    form.reset({
      subject: subject,
      content: message,
      sendTime: sendTime,
      month: month,
      day: day,
    });
    sheetRef.current?.hide();
  };

  useImperativeHandle(ref, () => ({
    show: handleShow,
    hide: handleHide,
  }));

  if (
    props.autoMessageConfig.message === undefined ||
    props.autoMessageConfig.message === null
  ) {
    return;
  }

  const {
    message_config_type,
    message,
    editable_columns,
    send_date,
    send_time,
  } = props.autoMessageConfig;

  const subject = AutomationConfig[message_config_type].subject;
  const title = getText('configure-label-secure', {
    label: AutomationConfig[message_config_type].title,
  });

  // Calculating day and month
  const day = send_date ? moment(send_date).date().toString() : undefined;
  const month = send_date
    ? (moment(send_date).month() + 1).toString()
    : undefined;

  const containsSendDate = editable_columns.includes(
    AutoMessageConfigDtoEditableColumnsEnum.SendDate,
  );

  const sendTime = send_time
    ? moment(send_time, 'HH:mm:ss').toISOString()
    : moment().toISOString();

  const form = useForm<AutoMessageConfig>({
    defaultValues: {
      subject: subject,
      content: message,
      sendTime: sendTime,
      month: month,
      day: day,
    },
  });

  const onContentChange = (content: string, length: number) => {
    setMessageLength(length);
  };

  useEffect(() => {
    form.reset({
      subject: subject,
      content: message,
      sendTime: sendTime,
      month: month,
      day: day,
    });
  }, [props.autoMessageConfig]);

  const selectedMonth = form.watch('month');
  const currentYear = moment().year();

  const [dayOptions, setDayOptions] = useState(createDayOptions(31));

  useEffect(() => {
    if (selectedMonth) {
      const selectedMonthDate = `${currentYear}-${selectedMonth.padStart(
        2,
        '0',
      )}`;
      const lastDayOfMonth =
        selectedMonth === '2'
          ? 28
          : moment(selectedMonthDate, 'YYYY-MM').endOf('month').date();
      setDayOptions(createDayOptions(lastDayOfMonth));
    }
  }, [selectedMonth]);

  const filteredTemplates = useMemo(() => {
    const sortedTemplates = defaultTemplates.sort(
      (currentTemplate, nextTemplate) =>
        currentTemplate.name.localeCompare(nextTemplate.name),
    );
    if (!templatesFilter) {
      return sortedTemplates;
    }

    const regExp = new RegExp(templatesFilter, 'i');
    return sortedTemplates.filter((template) => regExp.test(template.name));
  }, [templatesFilter]);

  const onEditCopyMessage: SubmitHandler<AutoMessageConfig> = (values) => {
    const currentYear = moment().year(); // Get the current year

    // Construct the send_date in "YYYY-MM-DD" format because it handle one digit month or day
    const sendDate =
      values.month && values.day
        ? moment(`${currentYear}-${values.month}-${values.day}`).format(
            'YYYY-MM-DD',
          )
        : undefined;
    const updateAutoMessageConfigDto: UpdateAutoMessageConfigDto = {
      location_id: locationId,
      message: stripRichTextEditorElements(values.content),
      message_config_type: values.subject as MessageConfigType,
      send_time: formatDateTime(
        form.getValues().sendTime,
        AUTO_CONFIG_TIME_FORMAT,
      ),
      send_date: sendDate,
      is_active: isActive,
    };

    // setIsActive(isActive);

    void (props.onSubmit && props.onSubmit(updateAutoMessageConfigDto));
  };

  const onSelectTemplate = (template: ITemplate) => {
    if (!quillRef) return;
    const content = convertMessageContent(template.body, quillRef);

    if (content) {
      form.setValue('subject', template.subject);
      form.setValue('content', content);
    }
  };

  function convertMessageContent(
    text: string,
    quillRef: ReactQuill,
  ): string | undefined {
    quillRef.getEditor().deleteText(0, quillRef.getEditor().getLength());
    quillRef.getEditor().insertText(0, text);

    return convertVariablesToPills(text, quillRef);
  }

  const handleQuillRef = (quillRef: ReactQuill) => {
    setQuillRef(quillRef);
  };

  const getMessageTip = () => {
    const strippedMessage = stripRichTextEditorElements(
      form.getValues().content,
    );
    if (MessageFields.message in errorFields) {
      return (
        <Text style={styles.errorMessage}>
          {errorFields[MessageFields.message]}
        </Text>
      );
    } else if (messageLength) {
      return (
        <Text style={styles.charactersCounter}>
          {getText('character-count', {
            max: BIG_MESSAGE_LIMIT,
            count: messageLength,
          })}
        </Text>
      );
    } else {
      return <></>;
    }
  };

  function formatLinks(message: string) {
    // Regular expression to match the custom link format and extract URL and text
    const customLinkRegex = /\(a href=(https?:\/\/[^\s]+) text=([^\)]+)\)/g;

    // Replace matched custom links with the desired HTML anchor tag format
    const formattedMessage = message.replace(
      customLinkRegex,
      (match, url, text) => {
        return `<a href="${url}" rel="noopener noreferrer" target="_blank">${text}</a>`;
      },
    );

    return formattedMessage;
  }

  return (
    <WebModal
      ref={sheetRef}
      title={title}
      isScrollable
      size="lg"
      modalProps={{
        scrollViewStyle: { flex: 1 },
        contentContainerStyle: { flex: 1 },
      }}
      showDismissButton
      buttons={[
        {
          onPress: form.handleSubmit(onEditCopyMessage),
          logger: { id: 'ok-edit-auto-config' },
          text: getText('ok'),
          hierarchy: 'primary',
        },
        {
          onPress: () => sheetRef.current?.hide(),
          logger: { id: 'cancel-edit-auto-config' },
          text: getText('close'),
          hierarchy: 'secondary',
        },
      ]}
    >
      <View
        style={{
          flexDirection: 'row',
          width: '100%',
        }}
      >
        <View
          style={{
            width: 225,
            marginRight: theme.getSpacing(2),
          }}
        >
          <ToggleSwitch
            logger={{
              id: 'enable-automation-modal',
            }}
            value={isActive}
            onPress={onTogglePressHandler}
          />
          <TextInput
            placeholder={getText('find-template')}
            autoComplete="off"
            autoCapitalize="none"
            style={messagingStyles.findTemplate}
            underlineColor="transparent"
            outlineColor={theme.palette.white}
            value={templatesFilter}
          />
          <View style={messagingStyles.templatesWrapper}>
            {filteredTemplates.length ? (
              filteredTemplates.map((template) => (
                <Fragment key={template.id}>
                  <TouchableOpacity onPress={() => onSelectTemplate(template)}>
                    <Text style={messagingStyles.template}>
                      {template.name}
                    </Text>
                  </TouchableOpacity>
                </Fragment>
              ))
            ) : (
              <Text style={messagingStyles.template}>
                {getText('no-results-found')}
              </Text>
            )}
          </View>
        </View>
        <View style={{ flex: 1 }}>
          <ErrorsContainer
            errorObject={errorObject}
            excludedErrors={EXCLUDED_ERRORS_MODAL}
          />
          <View style={{ marginBottom: theme.getSpacing(2) }}>
            <FormProvider {...form}>
              <TextField
                label={getText('subject')}
                style={styles.input}
                value={message}
                onChange={(e) => {
                  form.setValue('subject', e.target.value);
                }}
                name={'subject'}
              />
            </FormProvider>
            {MessageFields.subject in errorFields && (
              <Text style={styles.errorMessage}>
                {errorFields[MessageFields.subject]}
              </Text>
            )}
          </View>
          <View style={{ marginBottom: theme.getSpacing(1) }}>
            <AutoConfigMessageBodyEditor
              methods={form}
              setErrorFields={setErrorFields}
              errorFields={errorFields}
              handleQuillRef={handleQuillRef}
              defaultMessage={formatLinks(message)}
              onContentChange={onContentChange}
            />
            {getMessageTip()}
          </View>
          <FormProvider {...form}>
            <View style={{ marginTop: theme.getSpacing(2) }}>
              <Form.Row>
                <Text
                  style={{
                    ...theme.lumistryFonts.text.medium.semiBold,
                  }}
                >
                  {getText('when-to-send-every-year')}
                </Text>
              </Form.Row>
              <Form.Row>
                <>
                  {containsSendDate && (
                    <>
                      <Form.Column>
                        <DropdownSelectField
                          placeholder="Month"
                          labelInlined={true}
                          options={monthOptions}
                          testID={'month-dropdown-select-option'}
                          name="month"
                        />
                      </Form.Column>
                      <Form.Column>
                        <DropdownSelectField
                          placeholder="Day"
                          labelInlined={true}
                          options={dayOptions}
                          testID={'day-dropdown-select-option'}
                          name="day"
                        />
                      </Form.Column>
                    </>
                  )}
                </>
                <Form.Column style={{ width: '40%' }}>
                  <DateTimePickerField name={'sendTime'} type="time" />
                </Form.Column>
              </Form.Row>
            </View>
          </FormProvider>
        </View>
      </View>
    </WebModal>
  );
};
const useStyles = makeStyles((theme) => ({
  input: {
    height: theme.getSpacing(4) + theme.getSpacing(3),
    backgroundColor: theme.palette.white,
    borderColor: theme.palette.gray[300],
    borderWidth: 1,
    borderRadius: 6,
    fontSize: 16,
  },
  message: {
    height: 196,
  },
  note: {
    ...theme.fonts.regular,
    fontSize: 12,
    color: theme.palette.gray[500],
    marginTop: theme.getSpacing(2),
  },
  emojiWrapper: {
    position: 'absolute',
    bottom: 32,
    zIndex: 10,
    backgroundColor: theme.palette.black,
    padding: theme.getSpacing(1),
    borderRadius: 16,
  },
  errorMessage: {
    color: theme.palette.error[600],
    fontSize: 12,
  },
  charactersCounter: {
    alignSelf: 'flex-start',
    marginTop: theme.getSpacing(1),
    fontSize: 12,
  },
  typeahead: {
    textAlign: 'left',
    marginBottom: theme.getSpacing(2),
    fontSize: 16,
  },
}));

interface EditAutoMessageConfigModalProps {
  autoMessageConfig: AutoMessageConfigDto;
  onSubmit?: (data: UpdateAutoMessageConfigDto) => Promise<void>;
  togglePressHandler: (value: boolean) => Promise<void>;
}

export const EditMessageConfigModal = React.forwardRef<
  BaseModalHandler,
  EditAutoMessageConfigModalProps
>(EditAutoMessageConfigModal);
