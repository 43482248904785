import {
  DefaultApi as PMSIntegrationServiceClient,
  PmsDto,
} from '@digitalpharmacist/pms-integration-service-client-axios';
import { BaseApiConfiguration, BaseApiService } from 'assets/core/api';
import { AxiosRequestConfig } from 'axios';
import { PMS_INTEGRATIONS_API_BASE_URL } from '../common/constants';
import { AXIOS_DEFAULT_REQUEST_CONFIG } from '../common/axios-default-request-config';

export interface IPMSIntegrationsService {
  createPMSConfig(
    pharmacyId: string,
    locationId: string,
    data: any,
    overwriteDefaults: boolean,
  ): unknown;
  findPMSConfig(locationId: string): unknown;
  updatePMSConfig(pharmacyId: string, locationId: string, data: any): unknown;
  pmsFindAll(): Promise<PmsDto[]>;
  renewSubscriptionKeys(pharmacyID: string, locationId: string): unknown;
}

export class PMSIntegrationsService
  extends BaseApiService
  implements IPMSIntegrationsService
{
  private pmsIntegrationServiceClient: PMSIntegrationServiceClient;

  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration,
  ) {
    super(baseUrl, config, enableAuth, baseConfig);
    this.pmsIntegrationServiceClient = new PMSIntegrationServiceClient(
      undefined,
      baseUrl,
      this.axiosInstance,
    );
  }

  async createPMSConfig(pharmacyId: string, locationId: string, data: any) {
    const response = await this.pmsIntegrationServiceClient.pmsConfigsCreate(
      pharmacyId,
      locationId,
      data,
    );
    return response;
  }

  async findPMSConfig(locationId: string) {
    const response =
      await this.pmsIntegrationServiceClient.pmsConfigsFindOne(locationId);
    return response;
  }

  async updatePMSConfig(pharmacyId: string, locationId: string, data: any) {
    const response = await this.pmsIntegrationServiceClient.pmsConfigsUpdate(
      pharmacyId,
      locationId,
      data,
    );
    return response;
  }

  async pmsFindAll(): Promise<PmsDto[]> {
    const { data } = await this.pmsIntegrationServiceClient.pmsFindAll();
    return data;
  }

  async renewSubscriptionKeys(pharmacyId: string, locationId: string) {
    const response =
      await this.pmsIntegrationServiceClient.pmsConfigsRenewSubscriptionKeys(
        pharmacyId,
        locationId,
      );
    return response;
  }
}

export default new PMSIntegrationsService(
  PMS_INTEGRATIONS_API_BASE_URL,
  AXIOS_DEFAULT_REQUEST_CONFIG,
  true,
);
