import { IconProps } from 'assets/icons/types';
import React, { FunctionComponent } from 'react';
import Svg, { Path } from 'react-native-svg';

export interface OBMapPinIconProps extends IconProps {
  strokeWidth?: number;
}

export const OBMapPinIcon: FunctionComponent<OBMapPinIconProps> = ({
  color,
  size = 100,
  testID,
  strokeWidth = 5,
}) => {
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      testID={testID}
      viewBox="0 0 100 100"
    >
      <Path
        d="M87.5 41.6665C87.5 70.8332 50 95.8332 50 95.8332C50 95.8332 12.5 70.8332 12.5 41.6665C12.5 31.7209 16.4509 22.1826 23.4835 15.15C30.5161 8.11739 40.0544 4.1665 50 4.1665C59.9456 4.1665 69.4839 8.11739 76.5165 15.15C83.5491 22.1826 87.5 31.7209 87.5 41.6665Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M50 54.1665C56.9036 54.1665 62.5 48.5701 62.5 41.6665C62.5 34.7629 56.9036 29.1665 50 29.1665C43.0964 29.1665 37.5 34.7629 37.5 41.6665C37.5 48.5701 43.0964 54.1665 50 54.1665Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
