import {
  ColDef,
  ColGroupDef,
  GridApi,
  GridReadyEvent,
} from '@ag-grid-community/core';
import { useIsFocused } from '@react-navigation/native';
import { Avatar } from 'assets/components/avatar';
import { DataGrid } from 'assets/components/data-grid';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { Modal } from 'assets/components/modal';

import {
  DetailedUser,
  Role,
} from '@digitalpharmacist/role-service-client-axios';
import { Icon } from 'assets/components/icon';
import { useForm } from 'assets/form';
import {
  EmailIcon,
  PencilIcon,
  ThreedotsVerticalIcon,
  TrashWithStripesIcon,
} from 'assets/icons';
import { PharmacyScreenContainer } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { getSpacing, makeStyles, useTheme } from 'assets/theme';
import React, { useEffect, useMemo, useState } from 'react';
import { Text, View } from 'react-native';
import { useProSidebar } from 'react-pro-sidebar';
import { ContextMenu } from '../../../../../../packages/assets/components/context-menu/ContextMenu';
import RoleService from '../../../api/RoleService';
import { AG_GRID_PAGINATION_PAGE_SIZE } from '../../../common/constants';
import { formatDate } from '../../../common/datetime-utils';
import { handleError } from '../../../common/errors/commonErrorHandler';
import { canFactory } from '../../../common/guards/permission-guard';
import { Actions } from '../../../common/guards/permission-types';
import { useToast } from '../../../common/hooks/useToast';
import { useAppStateStore } from '../../../store/app-store';
import { CreateUserModal } from './CreateUserModal';
import { EditUserModal } from './EditUserModal';
import { resendInvite } from './UserActions';
import UserDetailSidebar from './UserDetailSidebar';
import {
  getGridColumnLabel,
  getHighestRole,
  getRoleObjByRoleValue,
} from './UserHelpers';
import { UserForm } from './UserTypes';
import useViewSidebarStore from './view-user-sidebar-store';
export default function Users() {
  const styles = useStyles();
  const theme = useTheme();
  const { pharmacyId, locationId } = useAppStateStore();
  const {
    showSidebar,
    setShowSidebar,
    setUserDetails,
    userDetails,
    showUserEdit,
    setShowUserEdit,
    showConfirmDeleteModal,
    setShowConfirmDeleteModal,
  } = useViewSidebarStore();
  const { collapseSidebar } = useProSidebar();
  const [gridApi, setGridApi] = useState<GridApi>();
  const [showCreateUserModal, setShowCreateUserModal] =
    useState<boolean>(false);
  const methods = useForm<UserForm>();
  const isFocused = useIsFocused();

  useEffect(() => {
    gridApi?.setFilterModel(null);
    setShowSidebar(false);
    gridApi?.deselectAll();
  }, [isFocused, gridApi, pharmacyId]);

  const onGridReady = (event: GridReadyEvent) => {
    setGridApi(event.api);
  };

  const onPostSubmit = () => {
    gridApi?.refreshServerSideStore();
    methods.reset();
  };

  const onDelete = () => {
    setShowConfirmDeleteModal(false);
    alert('Not implemented yet');
  };

  const columnDefs: (ColDef | ColGroupDef)[] = [
    {
      field: 'firstName',
      cellRenderer: FullNameRenderer,
      headerName: getText('user'),
    },
    {
      field: 'email',
      headerName: getText('email-address'),
    },
    {
      field: 'highestRole',
      headerName: getText('role'),
      valueFormatter: ({ value }) =>
        getGridColumnLabel(getRoleObjByRoleValue(value)),
    },
    {
      field: 'createdDate',
      headerName: getText('signup-date'),
      valueFormatter: ({ value }) => (value ? formatDate(value) : '-'),
      headerClass: 'data-grid-header-right-aligned',
      cellClass: 'data-grid-cell-right-aligned',
    },
    {
      field: 'menu',
      headerName: '',
      cellRenderer: MenuRenderer,
      width: 36,
      cellStyle: { padding: 0, position: 'absolute' },
    },
  ];

  const serverSideDatasource = useMemo(() => {
    return RoleService.getUsersSearchableDatasource(pharmacyId);
  }, [pharmacyId, locationId]);

  return (
    <>
      {!showUserEdit && (
        <CreateUserModal
          show={showCreateUserModal}
          onHide={() => {
            setShowCreateUserModal(false);
          }}
          refreshTable={onPostSubmit}
        />
      )}

      <PharmacyScreenContainer>
        <View style={styles.flex}>
          <DataGrid
            rowHeight={54}
            isRichContent={true}
            gridOptions={{
              columnDefs: columnDefs,
              enableCellTextSelection: true,
              suppressMovableColumns: true,
              suppressContextMenu: true,
              defaultColDef: { sortable: true, menuTabs: [] },
              loadingOverlayComponent: 'loadingIndicator',
              loadingOverlayComponentParams: {
                color: theme.colors.brandedPrimary,
              },
              components: {
                loadingIndicator: LoadingIndicator,
              },
              rowSelection: 'single',
              suppressCellFocus: true,
              colResizeDefault: 'shift',
              onCellClicked: (event) => {
                if (event.colDef.field !== 'menu') {
                  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                  setUserDetails(event.node.data as DetailedUser);
                  collapseSidebar(false);
                  setShowSidebar(true);
                }
              },
              onGridReady,
              rowModelType: 'serverSide',
              pagination: true,
              paginationPageSize: AG_GRID_PAGINATION_PAGE_SIZE,
              cacheBlockSize: AG_GRID_PAGINATION_PAGE_SIZE, // default 100
              maxBlocksInCache: 1, // one page, remove to let ag grid to handle itself
              serverSideDatasource: serverSideDatasource,
              serverSideStoreType: 'partial',
            }}
            gridToolbarProps={{
              inputSearchProps: {
                size: 'lg',
                placeholder: getText('user-email-address'),
              },
              titleProps: {
                title: getText('users'),
              },
              actionButtonsProps: {
                maxActionToShow: 1,
                actionButtons: [
                  {
                    text: getText('new-user'),
                    size: 'medium',
                    hierarchy: 'primary',
                    logger: { id: 'new-user-button' },
                    onPress: () => {
                      setShowSidebar(false);
                      setUserDetails({
                        email: '',
                        lastName: '',
                        firstName: '',
                        userId: '',
                        user_roles: [],
                        highestRole: Role.NoAccess,
                      });
                      setShowCreateUserModal(true);
                    },
                    style: { marginRight: getSpacing(1) },
                  },
                ],
              },
            }}
          />
        </View>

        {showSidebar && <UserDetailSidebar />}

        <Modal
          title=""
          cancelButtonProps={{
            onPress: () => setShowConfirmDeleteModal(false),
            logger: { id: 'user-delete-cancel-button' },
          }}
          okButtonProps={{
            onPress: onDelete,
            logger: { id: 'user-delete-confirm-button' },
            hierarchy: 'destructive',
            text: getText('delete'),
          }}
          show={showConfirmDeleteModal}
          size="sm"
        >
          <Text selectable style={styles.confirmationText}>
            {getText('delete-confirmation')}
          </Text>
          <Text style={styles.confirmationDescription}>
            {getText('cannot-undo-action')}
          </Text>
        </Modal>
        {userDetails && (
          <EditUserModal
            show={showUserEdit}
            onHide={() => {
              setShowUserEdit(false);
            }}
            refreshTable={onPostSubmit}
          />
        )}
      </PharmacyScreenContainer>
    </>
  );
}

const PrimarySecondaryTextRenderer = (props: { primaryText: string }) => {
  const styles = useStyles();
  return (
    <View style={styles.columnFlex}>
      <Text style={[styles.primaryText, styles.textEllipsis]}>
        {props.primaryText}
      </Text>
    </View>
  );
};

const FullNameRenderer = (props: { data: DetailedUser }) => {
  const styles = useStyles();
  const theme = useTheme();
  const user = props.data;
  const fullName = `${user.firstName} ${user.lastName}`;

  return (
    <View style={styles.iconContainer}>
      <View
        style={{ justifyContent: 'center', marginRight: theme.getSpacing(1) }}
      >
        <Avatar firstName={user.firstName} lastName={user.lastName} size={32} />
      </View>
      <PrimarySecondaryTextRenderer primaryText={fullName} />
    </View>
  );
};

export const LastActiveRenderer = (props: { data: DetailedUser }) => {
  const styles = useStyles();
  const user = props.data;

  return (
    <Text style={[styles.primaryText, styles.textEllipsis]}>
      {getText('last-active')}:{' '}
      {user.updatedDate ? formatDate(user.updatedDate) : null}
    </Text>
  );
};

const MenuRenderer = (props: { data: DetailedUser }) => {
  const user = props.data;
  const styles = useStyles();
  const theme = useTheme();
  const { toast } = useToast();
  const { setUserDetails, setShowConfirmDeleteModal, setShowUserEdit } =
    useViewSidebarStore();

  const highestRoleOfSubject = getHighestRole(
    user.user_roles.map((x) => x.role),
  );
  const guardAction: Actions = `manage:role:${highestRoleOfSubject}`;
  const canManageUser = canFactory(guardAction)('pharmacyUserRoles');

  const resendInviteEmail = () => {
    void resendInvite(user.email)
      .catch((e) => {
        throw handleError({
          userMessage: getText('error-sending-invite'),
          showToast: true,
          rethrow: true,
          error: e,
        });
      })
      .then(() => {
        toast(getText('resend-invite'), {
          type: 'success',
          content: `${getText('pharmacy-reset-link-sent')} ${user.email}`,
        });
      });
  };

  const onEdit = () => {
    setShowUserEdit(true);
    setUserDetails(user);
  };

  const onDelete = () => {
    setUserDetails(user);
    setShowConfirmDeleteModal(true);
  };

  const items = [
    canManageUser && {
      itemComponent: (
        <View style={styles.iconContainer}>
          <Icon icon={PencilIcon} size={16} color={theme.palette.gray[500]} />
          <Text style={{ marginLeft: theme.getSpacing(1) }}>
            {getText('edit')}
          </Text>
        </View>
      ),
      onClick: onEdit,
    },
    !user.emailVerified && {
      itemComponent: (
        <View style={styles.iconContainer}>
          <Icon icon={EmailIcon} size={16} color={theme.palette.gray[500]} />
          <Text style={{ marginLeft: theme.getSpacing(1) }}>
            {getText('resend-invite')}
          </Text>
        </View>
      ),
      onClick: resendInviteEmail,
    },
    canManageUser && {
      itemComponent: (
        <View style={styles.iconContainer}>
          <Icon
            icon={TrashWithStripesIcon}
            size={16}
            color={theme.palette.gray[500]}
          />
          <Text style={{ marginLeft: theme.getSpacing(1) }}>{`${getText(
            'remove',
          )}...`}</Text>
        </View>
      ),
      onClick: onDelete,
    },
  ].filter((item): item is Exclude<typeof item, false> => Boolean(item));

  return items.length > 0 ? (
    <View style={{ marginTop: theme.getSpacing(1) }}>
      <ContextMenu leftClick={true} items={items}>
        <Icon icon={ThreedotsVerticalIcon} />
      </ContextMenu>
    </View>
  ) : (
    <></>
  );
};

const useStyles = makeStyles((theme) => ({
  flex: {
    flex: 1,
  },
  columnFlex: {
    display: 'flex',
    flexDirection: 'column',
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.getSpacing(1),
    color: theme.palette.gray[700],
  },
  padding1: {
    padding: theme.getSpacing(1),
  },
  primaryText: {
    ...theme.lumistryFonts.label.small.regular,
  },
  confirmationText: {
    textAlign: 'left',
    fontSize: 18,
    fontWeight: '400',
    color: theme.palette.gray[900],
    lineHeight: 20,
  },
  confirmationDescription: {
    textAlign: 'left',
    fontSize: 16,
    fontWeight: '400',
    color: theme.palette.gray[700],
    lineHeight: 18,
    paddingTop: theme.getSpacing(1),
    paddingBottom: theme.getSpacing(2),
  },
  textEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));
