import { IconProps } from 'assets/icons/types';
import React, { FunctionComponent } from 'react';
import Svg, { Path } from 'react-native-svg';

export interface OBRouterIconProps extends IconProps {
  strokeWidth?: number;
}

export const OBRouterIcon: FunctionComponent<OBRouterIconProps> = ({
  color,
  size = 100,
  testID,
  strokeWidth = 3,
}) => {
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      testID={testID}
      viewBox="0 0 100 55"
    >
      <Path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={strokeWidth}
        d="M82.815 38.502h-65.63c-2.587 0-4.685-2.624-4.685-5.86V18.36c0-3.236 2.098-5.859 4.685-5.859h65.63c2.587 0 4.685 2.623 4.685 5.86v14.283c0 3.235-2.098 5.859-4.685 5.859Z"
      />
      <Path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={strokeWidth}
        d="M25.662 38.502v5.214h-5.713v-5.214M80.052 38.502v5.214H74.34v-5.214M12.5 20.957h75M37.148 29.678h1.863M43.107 29.678h1.863M49.068 29.678h1.863M55.03 29.678h1.862M60.988 29.678h1.863"
      />
    </Svg>
  );
};
