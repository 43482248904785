import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path } from 'react-native-svg';
import { IconProps } from './types';

export const PrescriptionIcon: FunctionComponent<
  PropsWithChildren<IconProps>
> = ({ color, size, testID, strokeWidth }) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
      testID={testID}
    >
      <Path d="M21.494 24h-4.401a.993.993 0 0 1-.863-.505l-1.393-2.473-1.683 2.536a.987.987 0 0 1-.825.442H8.245a.988.988 0 0 1-.818-1.549l3.532-5.178-4.207-6.315c-.063-.027-.2-.06-.502-.075v5.377c0 .546-.445.99-.99.99H1.99a.993.993 0 0 1-.99-.99V.99c0-.545.445-.99.99-.99h6.961c1.583 0 2.773.139 3.64.425.94.31 1.709.893 2.283 1.731.565.825.852 1.831.852 2.988 0 1.008-.219 1.9-.65 2.648A5.112 5.112 0 0 1 13.3 9.603a4.825 4.825 0 0 1-.85.406 38.01 38.01 0 0 1 1.219 1.864c.369.593.724 1.16 1.252 1.902l1.41-2.081a.987.987 0 0 1 .826-.443h4.058c.371 0 .708.205.879.534.17.329.144.722-.069 1.026l-.005.007-3.235 4.49 3.527 5.143a.99.99 0 0 1-.818 1.55Zm-4.104-1.5h3.137l-3.576-5.215 3.268-4.535h-2.793l-2.443 3.608-.632-.832c-.95-1.248-1.461-2.067-1.956-2.86-.458-.734-.931-1.493-1.776-2.627l-.687-.919 1.118-.26c.63-.146 1.123-.326 1.463-.535a3.582 3.582 0 0 0 1.262-1.283c.299-.518.45-1.157.45-1.898 0-.862-.192-1.561-.59-2.14-.39-.569-.885-.947-1.515-1.155-.702-.231-1.769-.349-3.17-.349H2.5v14.25h2.25V9.375h1.173c.551 0 1.577 0 2.044.7l4.801 7.207-3.56 5.218h2.848l2.887-4.349L17.39 22.5ZM7.796 7.75H4.75V3h3.133c1.095 0 1.825.19 2.298.596.34.292.744.833.744 1.756 0 .514-.154.979-.458 1.38-.31.409-.734.68-1.227.782-.913.21-1.252.236-1.444.236ZM6.25 6.25h1.546c.058 0 .308-.014 1.118-.2l.017-.003a.538.538 0 0 0 .34-.22.748.748 0 0 0 .154-.475c0-.384-.126-.537-.22-.618-.046-.039-.323-.234-1.322-.234H6.25v1.75Z" />
    </Svg>
  );
};
