import create from 'zustand';
import { ChatState } from 'assets/types/messageTypes';
import { TAKE } from '../../data';

export const initialState: ChatState = {
  conversationsGroupedByPatient: [],
  selectedPatient: null,
  selectedPatientConversations: [],
  selectedUserNames: {},
  selectedConversation: null,
  viewedConversationsSet: new Set(),
  selectedMessages: [],
  rawConversations: [],
  rawConversationsCount: 0,
  isOpenNewChatModal: false,
  isOpenSidebarNewChatModal: false,
  messagesPagination: {
    skip: 0,
    take: TAKE,
    count: 0,
  },
  showAddPatientModal: false,
  showEditModal: false,
  showTemplatesModal: false,
  patientsWithoutConversationsSet: new Set(),
  selectedTemplate: null,
  disabled: false,
  count: {},

  chatBoxText: '',
  failedMessagesInConversation: {},
  statusInConversation: null,
};

export const useChatState = create<ChatState>(() => initialState);
