import type { StackNavigationProp } from '@react-navigation/stack';
import {
  StackHeaderProps,
  StackScreenProps,
  createStackNavigator,
} from '@react-navigation/stack';
import { getText } from 'assets/localization/localization';
import React, { FunctionComponent, PropsWithChildren, useEffect } from 'react';
import { MessageCount } from '../components/MessageCount';
import { PharmacyHeader } from '../components/pharmacy-header';
import { FormsDrawer } from '../layout/FormsDrawer';
import { MessagesDrawer } from '../layout/MessagesDrawer';
import { ScheduleDrawer } from '../layout/ScheduleDrawer';
import { SettingsDrawer } from '../layout/SettingsDrawer';
import { TasksDrawer } from '../layout/TasksDrawer';
import { VideosDrawer } from '../layout/VideosDrawer';
import { useAppRedirectionContext } from '../providers/app-redirection/hooks';
import { CreatePassword } from '../screens/create-password/CreatePassword';
import { Dashboard } from '../screens/dashboard/Dashboard';
import { ExpiringSoon } from '../screens/expiring-password/ExpiringSoon';
import { UpdatePassword } from '../screens/expiring-password/UpdatePassword';
import { Login } from '../screens/login/Login';
import { Onboarding } from '../screens/onboarding/Onboarding';
import Patients from '../screens/patients/Patients';
import Refills from '../screens/refills/Refills';
import { RefillCount } from '../screens/refills/components/RefillCount';
import { ResetLink } from '../screens/reset-password/ResetLink';
import { ResetLinkSent } from '../screens/reset-password/ResetLinkSent';
import { ResetPassword } from '../screens/reset-password/ResetPassword';
import { SelectLocation } from '../screens/select-location-login/SelectLocation';
import { useAppStateStore } from '../store/app-store';
import {
  isUserAuthorized,
  isUserItAdmin,
  useUserState,
} from '../store/user-store';
import { OnboardingNavigationHeader } from './headers/OnboardingNavigationHeader';

export const PublicStack = createStackNavigator<PublicNavigationParamList>();
const PrivateStack = createStackNavigator<PrivateNavigationParamList>();

export const AppNavigation: FunctionComponent = () => {
  const isAuthorized = useUserState((x) => isUserAuthorized(x.data));

  return isAuthorized ? <PrivateNavigation /> : <PublicNavigation />;
};

export const PublicNavigation: FunctionComponent<
  PropsWithChildren<RootNavigationProps>
> = ({}) => {
  const { setAppRedirectionConfig } = useAppRedirectionContext();

  useEffect(() => {
    setAppRedirectionConfig({
      arePublicRoutesLoaded: true,
      arePrivateRoutesLoaded: false,
    });

    return () => setAppRedirectionConfig({ arePublicRoutesLoaded: false });
  }, []);

  return (
    <PublicStack.Navigator
      screenOptions={{
        headerShown: true,
        header: RootNavigationHeader,
        cardStyle: {
          flex: 1,
        },
      }}
    >
      <PublicStack.Group>
        <PublicStack.Screen
          name="login"
          component={Login}
          options={{ title: getText('login') }}
        />
        <PublicStack.Screen
          name="create-password"
          component={CreatePassword}
          options={{ title: getText('create-password') }}
        />
        <PublicStack.Screen
          name="reset-link"
          component={ResetLink}
          options={{ title: getText('reset-link') }}
        />
        <PublicStack.Screen
          name="reset-link-sent"
          component={ResetLinkSent}
          options={{ title: getText('reset-link-sent') }}
        />
        <PublicStack.Screen
          name="expiring-soon"
          component={ExpiringSoon}
          options={{ title: getText('expiring-soon') }}
        />
        <PublicStack.Screen
          name="update-password"
          component={UpdatePassword}
          options={{ title: getText('update-password') }}
        />
        <PublicStack.Screen
          name="reset-password"
          component={ResetPassword}
          options={{ title: getText('reset-password') }}
        />
      </PublicStack.Group>
    </PublicStack.Navigator>
  );
};

export const PrivateNavigation: FunctionComponent<
  PropsWithChildren<RootNavigationProps>
> = ({}) => {
  const { locationId } = useAppStateStore();
  const { setAppRedirectionConfig } = useAppRedirectionContext();
  const isItAdmin = useUserState((x) => isUserItAdmin(x.data));

  useEffect(() => {
    setAppRedirectionConfig({
      arePublicRoutesLoaded: false,
      arePrivateRoutesLoaded: true,
    });

    return () => setAppRedirectionConfig({ arePrivateRoutesLoaded: false });
  }, []);

  return (
    <PrivateStack.Navigator
      screenOptions={{
        headerShown: true,
        header: RootNavigationHeader,
        cardStyle: {
          flex: 1,
        },
      }}
    >
      {locationId.length > 0 && (
        <PrivateStack.Group>
          <PrivateStack.Screen
            name="dashboard"
            component={Dashboard}
            options={{ title: getText('dashboard') }}
          />
          <PrivateStack.Screen
            name="messages"
            component={MessagesDrawer}
            options={{ title: getText('messages') }}
          />
          <PrivateStack.Screen
            name="tasks"
            component={TasksDrawer}
            options={{ title: getText('tasks') }}
          />
          <PrivateStack.Screen
            name="schedule"
            component={ScheduleDrawer}
            options={{ title: getText('appointments') }}
          />
          <PrivateStack.Screen
            name="refills"
            component={Refills}
            options={{ title: getText('refill-submissions-header') }}
          />
          <PrivateStack.Screen
            name="patients"
            component={Patients}
            options={{ title: getText('patients') }}
          />
          <PrivateStack.Screen
            name="forms"
            component={FormsDrawer}
            options={{ title: getText('forms') }}
          />
          <PrivateStack.Screen
            name="videos"
            component={VideosDrawer}
            options={{ title: getText('videos') }}
          />
          <PrivateStack.Screen
            name="settings"
            component={SettingsDrawer}
            options={{ title: getText('settings') }}
          />
          <PrivateStack.Screen
            name="update-password"
            component={UpdatePassword}
            options={{ title: getText('update-password') }}
          />
        </PrivateStack.Group>
      )}

      {!locationId && (
        <PrivateStack.Screen
          name="store-location"
          component={SelectLocation}
          options={{ title: getText('store-location') }}
        />
      )}

      {isItAdmin && (
        <PrivateStack.Group
          screenOptions={{ header: OnboardingNavigationHeader }}
        >
          <PrivateStack.Screen
            name="onboarding"
            component={Onboarding}
            options={{ title: getText('onboarding') }}
          />
        </PrivateStack.Group>
      )}
    </PrivateStack.Navigator>
  );
};

interface RootNavigationProps {}

export type PublicNavigationParamList = {
  login: undefined;
  'reset-link': undefined;
  'reset-link-sent': undefined;
  'reset-password': ResetPasswordParamsProps;
  'create-password': undefined;
  'update-password': undefined;
  'expiring-soon': undefined;
};

export type PrivateNavigationParamList = {
  onboarding: OnboardingParamsProps | undefined;
  dashboard: undefined;
  messages: undefined;
  forms: undefined;
  schedule: undefined;
  tasks: undefined;
  patients: PatientsParamsProps | undefined;
  'store-location': undefined;
  refills: RefillsParamsProps | undefined;
  settings: undefined;
  videos: undefined;
  'update-password': undefined; // TODO: discuss with Jeton why exist on both private and public
};

export type RootStackNavigationProp = StackNavigationProp<
  PrivateNavigationParamList & PublicNavigationParamList
>;

export type ResetPasswordProps = StackScreenProps<
  PublicNavigationParamList,
  'reset-password'
>;

export type DashboardScreenProps = StackScreenProps<
  PrivateNavigationParamList,
  'dashboard'
>;

export type PatientsScreenProps = StackScreenProps<
  PrivateNavigationParamList,
  'patients'
>;

export type RefillsScreenProps = StackScreenProps<
  PrivateNavigationParamList,
  'refills'
>;

export type OnboardingScreenProps = StackScreenProps<
  PrivateNavigationParamList,
  'onboarding'
>;

const RootNavigationHeader: FunctionComponent<
  PropsWithChildren<StackHeaderProps>
> = (props) => {
  const { data } = useUserState();
  const locationId = useAppStateStore.getState().locationId;

  return (
    <PharmacyHeader
      isUserLoggedIn={!!isUserAuthorized(data) && !!locationId}
      firstName={data?.firstName!}
      lastName={data?.lastName!}
      email={data?.email!}
      navigation={props.navigation}
      screens={screens}
    />
  );
};

export const screens = [
  {
    name: 'messages',
    label: getText('messages'),
    navIcon: <MessageCount />,
  },
  { name: 'tasks', label: getText('tasks') },
  { name: 'schedule', label: getText('schedule') },
  {
    name: 'refills',
    label: getText('refill-submissions'),
    navIcon: <RefillCount />,
  },
  { name: 'patients', label: getText('patients') },
  { name: 'forms', label: getText('forms') },
];

export type ResetPasswordParamsProps = {
  email: string;
  confirmation_code: string;
  reset_password_link_id: string;
};

export type PatientsParamsProps = {
  newPatentModal: 'open';
};

export type RefillsParamsProps = {
  refillItemId: string;
};

export type OnboardingParamsProps = {
  wizardId: string;
  step: string;
};
