import React, { FunctionComponent, PropsWithChildren } from 'react';
import { View, Linking } from 'react-native';

import { Text } from 'assets/components/text';
import { Button } from 'assets/components/button';
import { makeStyles } from 'assets/theme';
import { DownloadIcon } from 'assets/icons';

import { INSTRUCTIONS_PDF_URL } from '../forms-import-stepper.content';
import { getText } from 'assets/localization/localization';

export const InstructionsStep: FunctionComponent = () => {
  const styles = useStyles();
  return (
    <View style={styles.container}>
      <Text style={styles.title}>{getText('instructions')}</Text>

      <Text style={styles.contentText}>{getText('instructions-api')}</Text>

      <Text style={styles.contentText}>{getText('pdf-instructions')}</Text>

      <View style={styles.buttonContainer}>
        <Button
          hierarchy="secondary-gray"
          size="small"
          logger={{ id: 'download-submission-button' }}
          onPress={() => Linking.openURL(INSTRUCTIONS_PDF_URL)}
          icon={DownloadIcon}
          loading={false}
        >
          {getText('open-pdf')}
        </Button>
      </View>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'column',
  },
  title: {
    ...theme.lumistryFonts.text.xLarge.bold,
    paddingBottom: theme.getSpacing(2),
  },
  contentText: {
    ...theme.lumistryFonts.label.small.semiBold,
    paddingBottom: theme.getSpacing(2),
  },
  buttonContainer: {
    flexDirection: 'row',
    paddingTop: theme.getSpacing(2),
  },
}));

export default InstructionsStep;
