import { getText } from 'assets/localization/localization';
import { InfoRow } from './InfoRow';
import { PatientRecords } from '../../screens/patients/patient-types';

export const Notifications = ({
  patientDetails,
}: {
  patientDetails: PatientRecords;
}) => {
  const notifications: string[] = [getText('by-application')];
  if (patientDetails.notify_by_email) {
    notifications.push(getText('by-email'));
  }
  if (patientDetails.notify_by_sms) {
    notifications.push(getText('by-sms'));
  }
  if (patientDetails.notify_by_voice) {
    notifications.push(getText('by-voice'));
  }

  return (
    <InfoRow
      label={getText('notification')}
      value={
        notifications.length > 0
          ? notifications.join(',')
          : getText('not-entered')
      }
      valueStyles={{ textTransform: 'capitalize' }}
    />
  );
};
