import {
  BulkDto,
  ScheduleType,
  BulkStatus,
} from '@digitalpharmacist/unified-communications-service-client-axios';
import { User } from 'react-native-gifted-chat';
import { IBulkMessage } from 'assets/types/messageTypes';

export const convertToBulkMessageType = (
  bulk: BulkDto,
  author: User,
): IBulkMessage => ({
  ...bulk,
  schedule_type:
    bulk.schedule_type === ScheduleType.Immediate
      ? ScheduleType.Immediate
      : ScheduleType.Delayed,
  status: bulk.status,
  author: author,
});
