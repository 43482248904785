import { FC } from 'react';
import { View } from 'react-native';
import theme, { makeStyles } from 'assets/theme';
import { Avatar } from 'assets/components/avatar';
import { RecipientRendererProps } from '../../../types';
import { Text } from 'assets/components/text';
import { getText } from 'assets/localization/localization';
import {
  BulkStatusRecipientDto,
  DeliveryStatus,
} from '@digitalpharmacist/unified-communications-service-client-axios';
import { RecipientsRenderersProps } from './types';
import { AlertTriangleIcon, CheckIcon } from 'assets/icons';
import { Icon } from 'assets/components/icon';

export const StatusRenderer: FC<RecipientsRenderersProps> = ({ data }) => {
  const styles = useStyles();

  if (!data) {
    return null;
  }

  const isReceived = data.status === DeliveryStatus.Received;

  const style = isReceived ? styles.statusReceived : styles.statusNotReceived;

  const statusText =
    data.status === DeliveryStatus.Received ? 'received' : 'not-received';

  return (
    <div style={styles.container}>
      {isReceived ? (
        <Icon size={20} {...styles.statusReceived} icon={CheckIcon} />
      ) : (
        <Icon
          size={20}
          {...styles.statusNotReceived}
          icon={AlertTriangleIcon}
        />
      )}
      <div style={style}>{getText(statusText)}</div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
    gap: theme.getSpacing(1),
  },
  statusReceived: {
    color: theme.palette.success[600],
  },
  statusNotReceived: {
    color: theme.palette.error[500],
  },
}));
