import {
  PaginatedPharmacistLocationResponseDto,
  DefaultApi as PharmacyServiceClient,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { BaseApiConfiguration, BaseApiService } from 'assets/core/api';
import { AxiosRequestConfig } from 'axios';
import { PHARMACY_API_BASE_URL } from '../common/constants';
import { SearchableDatasourceService } from './SearchableDatasourceService';
import { SearchablePaginatedRemoteDatasource } from 'assets/components/data-grid/data-grid-toolkit/datasource/SearchablePaginatedRemoteDatasource';

export interface IPharmacistLocationService {
  pharmacistLocationsFind(params: {
    userId: string;
    search?: string;
    orderBy?: string;
    limit?: number;
    offset?: number;
  }): Promise<PaginatedPharmacistLocationResponseDto>;
  pharmacistLocationsFindAll(
    userId: string,
  ): Promise<PaginatedPharmacistLocationResponseDto>;
  pharmacistLocationsFindMostRecent(
    userId: string,
  ): Promise<PaginatedPharmacistLocationResponseDto>;
  getPharmacistsLocationsDatasource(
    userId: string,
  ): SearchablePaginatedRemoteDatasource;
}

export class PharmacistLocationService
  extends BaseApiService
  implements IPharmacistLocationService
{
  private pharmacyServiceClient: PharmacyServiceClient;
  private searchableDatasourceService: SearchableDatasourceService;

  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration,
  ) {
    super(baseUrl, config, enableAuth, baseConfig);
    this.pharmacyServiceClient = new PharmacyServiceClient(
      undefined,
      baseUrl,
      this.axiosInstance,
    );

    this.searchableDatasourceService = new SearchableDatasourceService(
      baseUrl,
      config,
      enableAuth,
      baseConfig,
    );
  }

  getPharmacistsLocationsDatasource(userId: string) {
    return this.searchableDatasourceService.getSearchableDatasource(
      `/pharmacists/${userId}/locations`,
    );
  }

  async pharmacistLocationsFind(params: {
    userId: string;
    search?: string;
    orderBy?: string;
    limit?: number;
    offset?: number;
  }): Promise<PaginatedPharmacistLocationResponseDto> {
    const { userId, search, orderBy, limit, offset } = params;
    const { data } = await this.pharmacyServiceClient.pharmacistFind(
      userId,
      search,
      orderBy ?? 'location_name',
      limit ?? 25,
      offset ?? 0,
    );

    return data;
  }

  async pharmacistLocationsFindMostRecent(
    userId: string,
  ): Promise<PaginatedPharmacistLocationResponseDto> {
    const { data } = await this.pharmacyServiceClient.pharmacistFind(
      userId,
      undefined,
      'location_name',
      5,
      0,
    );

    return data;
  }

  async pharmacistLocationsFindAll(
    userId: string,
  ): Promise<PaginatedPharmacistLocationResponseDto> {
    const { data } = await this.pharmacyServiceClient.pharmacistFind(
      userId,
      undefined,
      'location_name',
      10000, // TODO: ask Pete how to handle this situation
      0,
    );

    return data;
  }
}

export default new PharmacistLocationService(PHARMACY_API_BASE_URL);
