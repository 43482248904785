import { FC } from 'react';
import theme, { makeStyles } from 'assets/theme';
import { BulkStatus } from '@digitalpharmacist/unified-communications-service-client-axios';
import { IRendererProps } from '../types';

export const RecipientsRenderer: FC<IRendererProps> = ({ data }) => {
  const styles = useStyles();
  const style =
    data.status === BulkStatus.Sent
      ? styles.recipientsBlue
      : styles.recipientsGray;

  return <a style={style}>{data.recipients_number}</a>;
};

const useStyles = makeStyles(() => ({
  recipientsBlue: {
    display: 'flex',
    color: theme.palette.primary[600],
    justifyContent: 'center',
  },
  recipientsGray: {
    display: 'flex',
    color: theme.palette.gray[700],
    justifyContent: 'center',
  },
}));
