import React, { FunctionComponent, PropsWithChildren } from 'react';
import { View } from 'react-native';
import { DropdownSelectField } from 'assets/components/dropdown-select';
import { Text } from 'assets/components/text';
import { makeStyles } from 'assets/theme';
import { Form } from 'assets/layout';
import { useForm } from 'assets/form';
import { FormTestIDs } from '../../../screens/forms/FormTestIDs';

export const DateElement: FunctionComponent = () => {
  const styles = useStyles();

  const methods = useForm({
    defaultValues: {
      month: 'default',
      day: 'default',
      year: 'default',
    },
  });

  return (
    <View style={styles.wrapper} testID={FormTestIDs.dateWidgetInput}>
      <Form methods={methods}>
        <Form.Row>
          <Form.Column>
            <DropdownSelectField
              platform="web"
              name="month"
              options={[
                {
                  label: '',
                  value: 'default',
                },
              ]}
              testID={'month'}
              labelInlined={true}
            />
            <Text style={styles.label} selectable>
              Month
            </Text>
          </Form.Column>
          <Form.Column>
            <DropdownSelectField
              platform="web"
              name="day"
              options={[
                {
                  label: '',
                  value: 'default',
                },
              ]}
              testID={'day'}
              labelInlined={true}
            />
            <Text style={styles.label} selectable>
              Day
            </Text>
          </Form.Column>
          <Form.Column>
            <DropdownSelectField
              platform="web"
              name="year"
              options={[
                {
                  label: '',
                  value: 'default',
                },
              ]}
              testID={'year'}
              labelInlined={true}
            />
            <Text style={styles.label} selectable>
              Year
            </Text>
          </Form.Column>
        </Form.Row>
      </Form>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    flexDirection: 'column',
    flexGrow: 1,
    gap: 5,
    width: '100%',
  },
  label: {
    fontSize: 14,
    color: theme.palette.gray['500'],
    marginTop: theme.getSpacing(1),
  },
}));
