import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { View } from 'react-native';
import { ColDef, ColGroupDef, GridReadyEvent } from '@ag-grid-community/core';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { AppointmentTypeDto } from '@digitalpharmacist/appointment-service-client-axios';
import { Tooltip } from 'assets/components/tooltip/components/tooltip';
import { makeStyles, useTheme } from 'assets/theme';
import { DataGrid } from 'assets/components/data-grid';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { ToggleSwitch } from 'assets/components/toggle-switch';
import { IconButton } from 'assets/components/icon-button';
import { ClockIcon, PencilIcon, TrashIcon } from 'assets/icons';
import { Text } from 'assets/components/text';
import { CopyToClipboard } from 'assets/components/copy-to-clipboard';
import { getText } from 'assets/localization/localization';

import {
  getServices,
  deleteService,
  setServiceStatus,
} from './services-list-actions';
import { useServicesListState } from './services-list-store';
import NoResultsOverlay from '../../components/NoResultsOverlay';
import { useToast } from '../../common/hooks/useToast';
import { EllipsisTextRenderer } from '../../components/EllipsisTextRenderer';
import { ScheduleDrawerNavigationProp } from '../../layout/ScheduleDrawer';
import PharmacyConfirmationModal from '../../components/PharmacyConfirmationModal';
import { getServiceUrl } from './services-list-utils';
import { useAppStateStore } from '../../store/app-store';
import EmptyState from '../../components/EmptyState/EmptyState';
import { BulletPointStyle } from '../../components/EmptyState/BulletPoints/BulletPoint';
import NoServicesImage from './NoServicesImage';
import { PharmacyTooltipWrapper } from '../../common/PharmacyTooltipWrapper';
import { Icon } from 'assets/components/icon';

const ServiceLinkRenderer = (props: { data: AppointmentTypeDto }) => {
  const styles = useStyles();
  const rowData = props.data;
  const { toast } = useToast();
  const locationId = useAppStateStore((x) => x.locationId);
  const pharmacySlug = useAppStateStore((x) => x.pharmacySlug);
  const serviceUrl = getServiceUrl(rowData.id, locationId, pharmacySlug);

  return (
    <View style={styles.cellContainer}>
      <Text style={styles.textEllipsis} selectable>
        {serviceUrl}
      </Text>
      <PharmacyTooltipWrapper
        tooltipId={'copy-link-button-tooltip'}
        containerStyles={{
          overflow: 'initial',
          textOverflow: 'ellipsis',
        }}
        isContentIcon={true}
      >
        <CopyToClipboard
          logger={{ id: `copy-form-url--${rowData.id}` }}
          stringToCopy={serviceUrl}
          fetchFromClipboard={() => toast('Copied to clipboard')}
        />
      </PharmacyTooltipWrapper>
    </View>
  );
};

export const ServicesList: FunctionComponent<ServicesListProps> = (props) => {
  const theme = useTheme();
  const navigation = useNavigation<ScheduleDrawerNavigationProp>();

  const services = useServicesListState((state) => state.services);
  const [servicesCount, setServicesCount] = useState<number | null>(null);

  const setGridApi = (event: GridReadyEvent) => {
    const { api } = event;
    useServicesListState.setState({ gridApi: api });
  };

  const [hideTooltip, setHideTooltip] = useState(false);

  const resetTooltip = () => {
    setHideTooltip(true);
    setTimeout(() => setHideTooltip(false), 50);
  };

  const ActionButtonsRenderer = (props: { data: AppointmentTypeDto }) => {
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const styles = useStyles();
    const rowData = props.data;
    const navigation = useNavigation<ScheduleDrawerNavigationProp>();

    const handleStatusToggle = () => {
      setServiceStatus(!rowData.enabled, rowData.id, rowData);
      resetTooltip();
    };

    return (
      <View style={styles.cellContainer}>
        <PharmacyTooltipWrapper
          tooltipId={
            rowData.enabled
              ? 'service-record-enabled-tooltip'
              : 'service-record-disabled-tooltip'
          }
          isContentIcon={true}
        >
          <ToggleSwitch
            logger={{ id: `change-status-service--${rowData.id}` }}
            value={rowData.enabled}
            onPress={handleStatusToggle}
          ></ToggleSwitch>
        </PharmacyTooltipWrapper>
        <PharmacyTooltipWrapper
          tooltipId="edit-service-record-tooltip"
          isContentIcon={true}
        >
          <IconButton
            icon={PencilIcon}
            logger={{ id: `edit-service--${rowData.id}` }}
            onPress={() =>
              navigation.navigate('edit-service', {
                serviceId: rowData.id,
              })
            }
          />
        </PharmacyTooltipWrapper>
        <PharmacyTooltipWrapper
          tooltipId={
            rowData.has_bookings
              ? 'delete-service-record-disabled-tooltip'
              : 'delete-service-record-tooltip'
          }
          isContentIcon={true}
        >
          <IconButton
            icon={TrashIcon}
            logger={{ id: `delete-service--${rowData.id}` }}
            onPress={() => setShowDeleteConfirmation(true)}
            disabled={rowData.has_bookings}
            strokeWidth={2}
          />
        </PharmacyTooltipWrapper>
        <PharmacyConfirmationModal
          show={showDeleteConfirmation}
          onAccepted={() => {
            setShowDeleteConfirmation(false);
            deleteService(rowData.id);
          }}
          onDismiss={() => setShowDeleteConfirmation(false)}
          message={getText('delete-confirmation-modal', {
            itemType: getText('service').toLowerCase(),
            itemName: `"${rowData.title}"`,
          })}
        />
      </View>
    );
  };

  const [columnDefs] = useState([
    {
      field: 'title',
      headerName: getText('service'),
      cellRenderer: ({ value }: { value: string }) => (
        <EllipsisTextRenderer value={value} />
      ),
    },
    {
      width: 120,
      field: 'length',
      headerName: getText('duration'),
      cellRenderer: ({ value }: { value: string }) => (
        <EllipsisTextRenderer value={`${value} min`} />
      ),
    },
    {
      width: 280,
      cellRenderer: ServiceLinkRenderer,
      headerName: getText('link'),
      cellStyle: { alignContent: 'center' },
    },
    {
      field: 'appointment_group_category_title',
      headerName: getText('category'),
      cellRenderer: ({ value }: { value: string }) => (
        <EllipsisTextRenderer value={value} />
      ),
    },
    {
      field: 'appointment_group_title',
      headerName: getText('service-type'),
      cellRenderer: ({ value }: { value: string }) => (
        <EllipsisTextRenderer value={value} />
      ),
    },
    {
      width: 200,
      maxWidth: 200,
      field: 'actions',
      headerName: getText('actions'),
      cellStyle: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'flex-end',
      },
      headerClass: 'data-grid-header-right-aligned',
      cellRenderer: ActionButtonsRenderer,
    },
  ] as (ColDef | ColGroupDef)[]);

  useFocusEffect(
    useCallback(() => {
      getServices({ withDisabled: true, withoutNextAvailableSlot: true });
    }, []),
  );

  useEffect(() => {
    if (services) {
      setServicesCount(services.length);
    }
  }, [services]);

  return (
    <>
      {servicesCount === null || servicesCount > 0 ? (
        <View style={{ flex: 1 }}>
          <DataGrid
            gridOptions={{
              onGridReady: setGridApi,
              rowData: services,
              columnDefs: columnDefs,
              enableCellTextSelection: true,
              suppressMovableColumns: true,
              suppressContextMenu: true,
              defaultColDef: { sortable: false, menuTabs: [] },
              pagination: false,
              loadingOverlayComponent: 'loadingIndicator',
              loadingOverlayComponentParams: {
                color: theme.colors.brandedPrimary,
              },
              components: {
                loadingIndicator: LoadingIndicator,
              },
              noRowsOverlayComponent: () => (
                <NoResultsOverlay
                  title={getText('no-services-booking')}
                  subtitle={getText('check-later')}
                  icon={
                    <Icon
                      size={100}
                      color={theme.palette.gray[300]}
                      icon={ClockIcon}
                    />
                  }
                  layout="vertical"
                />
              ),
            }}
            gridToolbarProps={{
              titleProps: {
                title: getText('services'),
              },
              actionButtonsProps: {
                maxActionToShow: 2,
                actionButtons: [
                  {
                    text: getText('new-service-button'),
                    hierarchy: 'primary',
                    logger: { id: 'new-service-button' },
                    onPress: () => navigation.navigate('new-service'),
                  },
                ],
              },
            }}
          />
        </View>
      ) : (
        <EmptyState
          image={<NoServicesImage />}
          title={getText('welcome-to-services')}
          subtitles={[getText('setup-bookable-service')]}
          buttonText={getText('new-service-button')}
          onButtonPress={() => navigation.navigate('new-service')}
          buttonLoggerId="new-service"
          bulletPoints={[
            getText('patients-book-via-mobile'),
            getText('pharmacy-staff-can-also-book'),
            getText('use-pharmacy-hours-custom-availability'),
            getText('see-upcoming-appointments'),
          ]}
          bulletPointStyle={BulletPointStyle.Bullet}
        />
      )}
      {!hideTooltip && (
        <Tooltip
          text={getText('service-record-enabled-tooltip')}
          id="service-record-enabled-tooltip"
        />
      )}
      {!hideTooltip && (
        <Tooltip
          text={getText('service-record-disabled-tooltip')}
          id="service-record-disabled-tooltip"
        />
      )}
      <Tooltip
        text={getText('copy-link-button-tooltip')}
        id="copy-link-button-tooltip"
      />
      <Tooltip
        text={getText('edit-service-record-tooltip')}
        id="edit-service-record-tooltip"
      />
      <Tooltip
        text={getText('delete-service-record-tooltip')}
        id="delete-service-record-tooltip"
      />
      <Tooltip
        text={getText('appointments-on-service')}
        id="delete-service-record-disabled-tooltip"
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  subTitle: {
    marginTop: theme.getSpacing(4),
    fontSize: 20,
    marginBottom: theme.getSpacing(2),
  },
  cellContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },
  textEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  statusIcon: {
    display: 'flex',
    paddingTop: 2,
    paddingHorizontal: theme.getSpacing(0.5),
  },
}));

export interface ServicesListProps {}
