import { TextStyle, View, Text } from 'react-native';
import { useStyles } from './styles';

export const InfoRow = ({
  label,
  value,
  valueStyles,
}: {
  label: string;
  value: any;
  valueStyles?: TextStyle;
}) => {
  const styles = useStyles();
  return (
    <View style={[styles.infoRow]}>
      <Text style={[styles.infoRowLabel]}>{label}</Text>
      <Text style={[styles.infoRowValue, valueStyles]}>{value}</Text>
    </View>
  );
};
