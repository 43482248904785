import { BookingDtoLocationPatientRecordData } from '@digitalpharmacist/appointment-service-client-axios';
import { TaskDtoLocationPatientRecordData } from '@digitalpharmacist/tasks-service-client-axios';
import { LocationPatientRecordDtoWithFullName } from './patient-types';
import { LocationPatientRecordSource } from '@digitalpharmacist/patient-service-client-axios';

type PatientInfoForSidebarSection = {
  firstName: string;
  lastName: string;
  birthDate: string;
  phone: string;
  email: string;
  allergies: string[];
};

export const getPatientInfoForSidebarSection = (
  patientRecordData:
    | BookingDtoLocationPatientRecordData
    | TaskDtoLocationPatientRecordData
    | undefined
    | null,
  patientRecordId: string | undefined,
): PatientInfoForSidebarSection | null => {
  return patientRecordId && patientRecordData
    ? {
        firstName: patientRecordData.first_name,
        lastName: patientRecordData.last_name,
        birthDate: patientRecordData.date_of_birth,
        phone: patientRecordData.cell_phone,
        email: patientRecordData.email ?? '',
        allergies: patientRecordData.allergies ?? [],
      }
    : null;
};

export const getLocationPatientRecordWithFullName = (
  patientRecordData:
    | BookingDtoLocationPatientRecordData
    | TaskDtoLocationPatientRecordData,
  patientRecordId: string,
  locationId: string,
): LocationPatientRecordDtoWithFullName => {
  const locationPatientRecord = {
    id: patientRecordId,
    location_id: locationId,
    pharmacy_id: '',
    patient_record_id: '',
    first_name: patientRecordData.first_name,
    last_name: patientRecordData.last_name,
    cell_phone: patientRecordData.cell_phone,
    external_id: null,
    date_of_birth: patientRecordData.date_of_birth,
    created_at: '',
    updated_at: '',
    is_verified: false,
    notes: null,
    replaced_by_id: null,
    source: LocationPatientRecordSource.PmsSync,
  };

  return {
    ...locationPatientRecord,
    full_name: `${patientRecordData.first_name} ${patientRecordData.last_name}`,
  };
};
