import { PublicCategory } from '@digitalpharmacist/file-storage-service-client-axios';
import { CreateMediaDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import * as validate from '@digitalpharmacist/validation-dp';
import { Icon } from 'assets/components/icon';
import { Modal } from 'assets/components/modal';
import { Text } from 'assets/components/text';
import { TextField } from 'assets/components/text-field';
import { ChevronDownIcon, SlashIcon } from 'assets/icons';
import { Form } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { logError } from 'assets/logging/logger';
import { makeStyles, useTheme } from 'assets/theme';
import * as ImagePicker from 'expo-image-picker';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FlatList, Pressable, View } from 'react-native';
import {
  checkAppLogoRequirement,
  createMedia,
  fetchMedia,
  setBrandColor,
  setOpenedModal,
} from '../../../actions/checklist-actions';
import { PUBLIC_FILE_STORAGE_SERVICE_BASE_URL } from '../../../common/constants';
import { ErrorMessage } from '../../../components/checklist/ErrorMessage';
import { UploadImage } from '../../../components/checklist/UploadImage';
import { useAppStateStore } from '../../../store/app-store';
import { useChecklistStore } from '../../../store/checklist-store';
import { ChecklistModalProps } from '../types';
import { SpecsList } from '../utils';
import { uploadPublicPharmacyFile } from '../../../actions/file-storage-actions';

enum LogoType {
  logoLight = 'logo_light',
  logoDark = 'logo_dark',
  iconAndroid = 'icon_android.png',
  iconIos = 'icon_ios.png',
}

export const MediaSettingsModal: FunctionComponent<ChecklistModalProps> = ({
  id,
}) => {
  const { currentOpenedModalId, brandColor } = useChecklistStore();
  const [loading, setLoading] = useState<boolean>(false);
  const { pharmacyId } = useAppStateStore.getState();
  const url = `${PUBLIC_FILE_STORAGE_SERVICE_BASE_URL}/${pharmacyId}/branding`;

  const [logoLight, setLogoLight] = useState('');
  const [logoDark, setLogoDark] = useState('');
  const [icon_android, setIconAndroid] = useState('');
  const [icon_ios, setIconIos] = useState('');
  const [error, setError] = useState<boolean>(false);
  const styles = useStyles();
  const theme = useTheme();

  const methods = useForm<CreateMediaDto>({
    defaultValues: {},
  });

  const handleCloseModal = () => setOpenedModal('checklist-setup');

  const handleSubmit = async () => {
    try {
      if (!brandColor || !logoLight || !icon_ios || !icon_android) {
        setError(true);
        return;
      }
      setLoading(true);
      const { facebook_url, twitter_handle, instagram_handle } =
        methods.getValues();
      const data: CreateMediaDto = {
        brand_color: brandColor,
        facebook_url: facebook_url || undefined,
        twitter_handle: twitter_handle || undefined,
        instagram_handle: instagram_handle || undefined,
      };
      await createMedia(data);
      const uploadPromises = Object.keys(LogoType).map(async (key) => {
        const value = LogoType[key as keyof typeof LogoType];
        let uri = '';
        switch (value) {
          case LogoType.logoLight:
            uri = logoLight;
            break;
          case LogoType.logoDark:
            uri = logoDark;
            break;
          case LogoType.iconAndroid:
            uri = icon_android;
            break;
          case LogoType.iconIos:
            uri = icon_ios;
            break;
          default:
            break;
        }
        if (!uri.includes(url)) {
          await uploadPublicPharmacyFile(value, uri);
        }
      });

      await Promise.all(uploadPromises);
      setLoading(false);
      handleCloseModal();
    } catch (error: any) {
      logError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    void (async () => {
      const data = await fetchMedia();
      if (data) {
        methods.setValue('facebook_url', data.facebook_url || undefined);
        methods.setValue(
          'instagram_handle',
          data.instagram_handle || undefined,
        );
        methods.setValue('twitter_handle', data.twitter_handle || undefined);
        setBrandColor(data.brand_color);
      }
    })();
  }, []);

  return (
    <Modal
      title={getText('media-settings')}
      handleDismissModal={handleCloseModal}
      show={currentOpenedModalId === id}
      size="sm"
      showDismissButton
      isScrollable
      buttons={[
        {
          hierarchy: 'primary',
          text: getText('ok'),
          onPress: methods.handleSubmit(handleSubmit),
          logger: { id: 'close-checklist-checklist-setup' },
          loading,
        },
        {
          text: getText('cancel'),
          hierarchy: 'tertiary-gray',
          onPress: handleCloseModal,
          logger: { id: 'close-media-settings-modal' },
        },
      ]}
    >
      <Text style={styles.brandText}>{getText('brand-color') + ' *'}</Text>
      <Text style={styles.desc}>{getText('white-text-readable')}</Text>
      <View style={styles.colorContainer}>
        <View
          style={[
            styles.colors,
            { backgroundColor: brandColor || theme.palette.gray[200] },
          ]}
        >
          {!brandColor && (
            <Icon color={theme.palette.gray[300]} size={20} icon={SlashIcon} />
          )}
        </View>
        <Pressable
          style={styles.colorPicker}
          onPress={() => setOpenedModal('color-picker')}
        >
          <Icon
            color={theme.palette.gray[700]}
            size={18}
            icon={ChevronDownIcon}
          />
        </Pressable>
      </View>
      {error && !brandColor && (
        <ErrorMessage message={getText('brand-color-required')} />
      )}
      <Text style={styles.title}>{getText('logo-and-icon')}</Text>
      <Text style={styles.desc}>{getText('high-quality-graphics')}</Text>
      <FlatList
        data={SpecsList}
        renderItem={({ item }) => (
          <View style={styles.itemContainer}>
            <Text style={styles.bullet}>•</Text>
            <Text style={styles.item}>{item}</Text>
          </View>
        )}
        keyExtractor={(item, index) => index.toString()}
      />
      <View style={styles.uploadContainer}>
        <UploadImage
          title={getText('logo-light-bg') + ' *'}
          uri={logoLight}
          choosePhoto={(photo) => setLogoLight(photo.uri)}
          required={error && !logoLight ? true : false}
          validateRules={(photo: ImagePicker.ImagePickerSuccessResult) =>
            checkAppLogoRequirement(photo)
          }
        />
        <UploadImage
          title={getText('logo-dark-bg')}
          uri={logoDark}
          isDark
          choosePhoto={(photo) => setLogoDark(photo.uri)}
          validateRules={(photo: ImagePicker.ImagePickerSuccessResult) =>
            checkAppLogoRequirement(photo)
          }
        />
      </View>
      <View style={styles.uploadIcon}>
        <View style={styles.flex}>
          <UploadImage
            title={getText('icon-apple-app') + ' *'}
            uri={icon_ios}
            choosePhoto={(photo) => setIconIos(photo.uri)}
            width={100}
            height={100}
            required={error && !icon_ios}
            validateRules={(photo: ImagePicker.ImagePickerSuccessResult) =>
              checkAppLogoRequirement(photo)
            }
          />
        </View>
        <View style={styles.flex}>
          <UploadImage
            title={getText('icon-android-app') + ' *'}
            uri={icon_android}
            width={100}
            height={100}
            required={error && !icon_android}
            choosePhoto={(photo) => setIconAndroid(photo.uri)}
            validateRules={(photo: ImagePicker.ImagePickerSuccessResult) =>
              checkAppLogoRequirement(photo)
            }
          />
        </View>
      </View>
      <Text style={styles.title}>{getText('social-media')}</Text>
      <Text style={styles.desc}>{getText('links-appear')}</Text>
      <Form methods={methods}>
        <Form.Row>
          <Form.Column>
            <TextField
              name="facebook_url"
              type="text"
              label={getText('fb-url')}
              rules={{
                validate: {
                  value: (val) => {
                    return !val || validate.isURL(val)
                      ? true
                      : getText('valid-url');
                  },
                },
              }}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              name="instagram_handle"
              type="text"
              label={getText('insta-url')}
              rules={{
                validate: {
                  value: (val) => {
                    return !val || validate.isURL(val)
                      ? true
                      : getText('valid-url');
                  },
                },
              }}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              name="twitter_handle"
              type="text"
              label={getText('twitter-url')}
              rules={{
                validate: {
                  value: (val) => {
                    return !val || validate.isURL(val)
                      ? true
                      : getText('valid-url');
                  },
                },
              }}
            />
          </Form.Column>
        </Form.Row>
      </Form>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  brandText: {
    ...theme.lumistryFonts.text.medium.semiBold,
  },
  desc: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.gray[700],
    paddingBottom: theme.getSpacing(2),
  },
  colorContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.getSpacing(1),
    paddingBottom: theme.getSpacing(0.5),
  },
  colors: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 94,
    height: 36,
    borderRadius: theme.roundness,
  },
  colorPicker: {
    borderWidth: 1,
    borderRadius: theme.roundness,
    borderColor: theme.palette.gray[500],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 36,
    height: 36,
  },
  title: {
    ...theme.lumistryFonts.text.medium.semiBold,
    color: theme.palette.gray[900],
    paddingTop: theme.getSpacing(3),
  },
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: theme.getSpacing(0.5),
  },
  bullet: {
    fontSize: theme.getSpacing(2),
    color: theme.palette.gray[700],
  },
  item: {
    ...theme.lumistryFonts.text.small.regular,
    marginLeft: theme.getSpacing(1),
    color: theme.palette.gray[900],
  },
  uploadContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: theme.getSpacing(2),
    paddingTop: theme.getSpacing(2),
  },
  uploadIcon: {
    paddingTop: theme.getSpacing(2),
    flexDirection: 'row',
  },
  flex: {
    flex: 1,
  },
}));
