import create from 'zustand';
import { PatientState, SidebarDetails } from './patient-types';
import {
  LocationPatientRecordDto,
  PatientRecordDto,
} from '@digitalpharmacist/patient-service-client-axios';

// create state store
const usePatientStore = create<PatientState>((set) => ({
  patients: [],
  setPatients: (patients: LocationPatientRecordDto[]) =>
    set((state) => ({
      ...state,
      patients,
    })),
  sidebarDetails: undefined,
  setSidebarDetails: (sidebarDetails: SidebarDetails | undefined) =>
    set((state) => ({
      ...state,
      sidebarDetails,
    })),
  removeSidebarDetails: () =>
    set((state) => ({
      ...state,
      sidebarDetails: undefined,
    })),
  patientRecord: undefined,
  setPatientRecord: (patientRecord: PatientRecordDto) =>
    set((state) => ({
      ...state,
      patientRecord,
    })),
  setLocationPatientRecord: (
    locationPatientRecordDto: LocationPatientRecordDto,
  ) =>
    set((state) => ({
      ...state,
      locationPatientRecordDto,
    })),
  createdPatientCount: 0,
  setCreatedPatientCount: (createdPatientCount: number) =>
    set((state) => ({
      ...state,
      createdPatientCount,
    })),
  updatedPatientCount: 0,
  setUpdatedPatientCount: (updatedPatientCount: number) =>
    set((state) => ({
      ...state,
      updatedPatientCount,
    })),
}));

export default usePatientStore;
