import { FC } from 'react';
import { Icon } from 'assets/components/icon';
import { InfoIcon } from 'assets/icons';
import theme, { makeStyles, useTheme } from 'assets/theme';
import { View } from 'react-native';
import { Tooltip } from 'assets/components/tooltip/components/tooltip';
import { PharmacyTooltipWrapper } from '../common/PharmacyTooltipWrapper';

interface InfoHintProps {
  text: string;
}

const InfoIconWithHint: FC<InfoHintProps> = ({ text }) => {
  const theme = useTheme();
  const styles = useStyles();
  return (
    <View style={styles.infoContainer}>
      <PharmacyTooltipWrapper tooltipId="info-tooltip">
        <Icon icon={InfoIcon} color={theme.palette.gray[500]} size={16} />
      </PharmacyTooltipWrapper>
      <Tooltip id="info-tooltip" text={text} />
    </View>
  );
};

export default InfoIconWithHint;

const useStyles = makeStyles(() => ({
  infoContainer: {
    display: 'flex',
    paddingLeft: 10,
    justifyContent: 'center',
    paddingBottom: 0,
    marginLeft: theme.getSpacing(1),
    marginTop: theme.getSpacing(0.5),
  },
}));
