/** Split from edit user modal, "extends" the manage user modal */
import { EntityType } from '@digitalpharmacist/role-service-client-axios';
import { useForm } from 'assets/form';
import { getText } from 'assets/localization/localization';
import { logError } from 'assets/logging/logger';
import React, { FunctionComponent, useEffect } from 'react';
import RoleService from '../../../api/RoleService';
import { useToast } from '../../../common/hooks/useToast';
import { useAppStateStore } from '../../../store/app-store';
import { ManageUserModalBase } from './ManageUserModal';
import { RoleOption } from './UserHelpers';
import { UserForm } from './UserTypes';
import useViewSidebarStore from './view-user-sidebar-store';

export const CreateUserModal: FunctionComponent<CreateUserProps> = ({
  refreshTable,
  show,
  onHide,
}) => {
  const pharmacyId = useAppStateStore((x) => x.pharmacyId);
  const { setUserRoles } = useViewSidebarStore();
  const { toast } = useToast();

  const methods = useForm<UserForm>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
  });

  useEffect(() => {
    methods.reset();
  }, [show]);

  const createRoles = async (role: RoleOption | undefined) => {
    const userId = methods.getValues('userId');
    if (!userId) {
      return logError(new Error('userId not defined for edit role'));
    }
    if (!role) {
      return logError(new Error('role not defined for edit role'));
    }

    try {
      let entityId: string | undefined;
      switch (role.entityType) {
        case EntityType.Location: {
          throw new Error(
            'Location entities should be handled in the locations modal',
          );
        }
        case EntityType.Pharmacy: {
          entityId = pharmacyId;
          break;
        }
        case EntityType.Global: {
          entityId = '*';
          break;
        }
        default: {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars -- This is a type check just to prevent missing an enum value. If this is red, add a case for the missing enum value
          const neverGetHere: never = role.entityType;
          throw new Error(`Unexpected entity type: ${String(role.entityType)}`);
        }
      }

      const response = await RoleService.userRoleReplaceRoles(userId, {
        roles: [{ role: role.value, entity_id: entityId }],
      });

      const newUserRoles = response.map((x) => ({ ...x, user_id: userId }));

      setUserRoles(newUserRoles);
    } catch (e) {
      logError(new Error(`Edit role failed with error: ${e}`));
      toast('Error', {
        type: 'error',
        content: getText('user-has-role-error'),
      });
    }
  };

  return (
    <ManageUserModalBase
      title={getText('new-user')}
      newUserMode={true}
      onEditRole={createRoles}
      methods={methods}
      refreshTable={refreshTable}
      show={show}
      onHide={onHide}
    />
  );
};

type CreateUserProps = {
  refreshTable: () => void;
  show: boolean;
  onHide: () => void;
};
