import {
  RxStatus,
  WorkflowStatus,
} from '@digitalpharmacist/prescription-service-client-axios';
import { Role } from '@digitalpharmacist/role-service-client-axios';
import * as validate from '@digitalpharmacist/validation-dp';
import { getText, translations } from 'assets/localization/localization';
import { logError } from 'assets/logging/logger';
import { set } from 'date-fns';
import {
  INTERCOM_APP_ID,
  PATIENT_APP_BASE_URL,
} from '../modules/common/constants';

export const getWorkflowStatusText = (status?: WorkflowStatus) => {
  return getGenericStatusText(status, 'workflow-status-');
};

export const getWorkflowStatusMessageText = (status?: WorkflowStatus) => {
  return getGenericStatusText(status, 'workflow-status-', '-message');
};

export const getRxStatusText = (rxStatus?: RxStatus) => {
  return getGenericStatusText(rxStatus, 'rx-status-');
};

export const getRxStatusMessageText = (rxStatus?: RxStatus) => {
  return getGenericStatusText(rxStatus, 'rx-status-', '-message');
};

const getGenericStatusText = (
  status?: string,
  keyPrefix = '',
  keyPostfix = '',
) => {
  if (!status) status = 'undefined';

  const normalizeStatus = status.replaceAll('_', '-');

  const textKey = `${keyPrefix}${normalizeStatus}${keyPostfix}` as translations; // eslint-disable-line @typescript-eslint/consistent-type-assertions

  const rxStatusText = getText(textKey);

  if (!rxStatusText) return textKey;

  return rxStatusText;
};

export function capitalizeAllWords(str: string) {
  return str.replace(/\b\w/g, function (match) {
    return match.toUpperCase();
  });
}

export const formatPhoneNumber = (valueToFormat: string) => {
  const phoneNumber = ('' + valueToFormat).replace('+1', '').replace(/\D/g, '');
  const match = phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return undefined;
};

export const ensureHttpsUrl = (url: string) => {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return `https://${url}`;
  }
  return url;
};

export const getShortVideoUrlById = (videoId: string, locale = 'en-US') => {
  return `${PATIENT_APP_BASE_URL}/app/home?videoId=${videoId}&&locale=${locale}`;
};

export const convertRoleToLocalization: Record<Role, translations> = {
  CORPORATE: 'role-corporate',
  IT_ADMIN: 'role-it-admin',
  BRAND: 'role-brand',
  MANAGER: 'role-manager',
  STAFF: 'role-staff',
  NO_ACCESS: 'role-no-access',
  LUMISTRY_USER: 'role-lumistry-user',
  LUMISTRY_ADMIN: 'role-lumistry-admin',
};

export const initIntercom = () => {
  // Reference: https://developers.intercom.com/installing-intercom/web/installation/#single-page-app

  try {
    const intercomInstallScript = `const APP_ID = '${INTERCOM_APP_ID}'; (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerText = intercomInstallScript;
    document.head.appendChild(script);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- see description below
    // @ts-expect-error we know window.Intercom exists because we have a script that puts it there
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call -- see description above :P
    window.Intercom('boot', {
      app_id: INTERCOM_APP_ID,
      alignment: 'left',
      //Website visitor so may not have any user related info
    });
  } catch (error: unknown) {
    logError(error as Error);
  }
};

export const validateOptionalURL = (url?: string): boolean | string => {
  return !url || validate.isURL(url) ? true : getText('url-is-not-valid');
};

export const validateOptionalNumber = (
  value?: string | number,
): boolean | string => {
  return !value || validate.isNumber(Number(value))
    ? true
    : getText('numeric-only');
};

//this converts the value coming from the backend to normal title ex: no_refills => No refills
export function snakeCaseToTitleCase(str: string) {
  const words = str.split('_');
  const firstWordCapitalized =
    words[0].charAt(0).toUpperCase() + words[0].slice(1);
  const restOfWords = words.slice(1).join(' ');

  return `${firstWordCapitalized} ${restOfWords}`;
}

export const isNumberAndBiggerOrLessThan = (
  value: string,
  lowerLimit = 0,
  upperLimit = 30,
) => {
  const parsedValue = parseInt(value, 10);
  if (
    !isNaN(parsedValue) &&
    parsedValue >= lowerLimit &&
    parsedValue <= upperLimit
  )
    return true;
};

export const isNumber = (value: string) => {
  if (!value) return true;

  const isNumericRegex = new RegExp(/^[0-9]+$/);
  if (isNumericRegex.test(value)) return true;

  return false;
};

export function checkCapitalizeAllWords(str?: string) {
  if (!str) {
    return true;
  }
  const words = str.split(' ');
  const isCapitalizeAllWords = words.every(
    (word) => word && word[0] === word[0].toUpperCase(),
  );
  return isCapitalizeAllWords
    ? true
    : getText('each-word-capital-first-letter');
}

export const timePickerDefaultValue = set(new Date(), {
  hours: 6,
  minutes: 0,
  seconds: 0,
});

export const timePickerCharactersPattern = /^[0-9apm:]*$/;
export const timePickerPattern = /^(\d{1,4})\s*(a|am|p|pm)?$/i;
export const timePickerSpecialKeys = [
  'ArrowLeft',
  'ArrowRight',
  'ArrowUp',
  'ArrowDown',
  'Backspace',
  'Delete',
  'Tab',
  'Enter',
];

export const validateTaxEIN = (value?: string): boolean | string => {
  const taxIdRegex = /^\d{3}-\d{2}-\d{4}$/;
  const einRegex = /^\d{2}-\d{7}$/;

  return value
    ? taxIdRegex.test(value) || einRegex.test(value)
      ? true
      : getText('tax-ein-format-is-invalid')
    : true;
};

export const boolToString = (active: boolean) => {
  return active ? getText('yes') : getText('no');
};
