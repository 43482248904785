import React, { useCallback, useEffect } from 'react';
import { Modal } from 'assets/components/modal';
import { View, ScrollView, useWindowDimensions } from 'react-native';
import { useVaccinationModalState } from './vaccination-modal-store';
import {
  handleResetVaccinationData,
  updateShowModalState,
  updateVaccinationState,
} from './vaccination-modal-actions';
import { getText } from 'assets/localization/localization';
import { Form } from 'assets/layout';
import { useForm } from 'assets/form';
import { Alert } from 'assets/components/alert';
import { TextField } from 'assets/components/text-field';
import { makeStyles } from 'assets/theme';
import { DateTimePickerField } from '../DateTimePickerField';
import { RadioButtonGroupField } from 'assets/components/radio-button-group';
import { RadioButtonInputMode } from 'assets/components/radio-button-group/RadioButton';
import { useWatch } from 'react-hook-form';
import { zIndexAuto } from '../../common/theme';
import {
  handleResetAuthData,
  updateShowModalState as updateUserAuthenticationShowModalState,
} from '../UserAuthenticationModal/user-authentication-modal-actions';
import {
  VaccinationDto,
  VaccinationLocation,
  VaccinationRoute,
  VaccinationSide,
} from '@digitalpharmacist/forms-service-client-axios';

const today = new Date();
today.setHours(0, 0, 0, 0);
const defaultAdministeredDate = today.toISOString();
const defaultFormValues = {
  drug_name: '',
  amt_administered: '',
  manufacturer: '',
  lot: '',
  exp_date: undefined,
  vis_date: undefined,
  administered_date: defaultAdministeredDate,
  location: undefined,
  other_location: '',
  route: undefined,
  other_route: '',
  side: undefined,
};

export const VaccinationModal = () => {
  const { height: viewportHeight } = useWindowDimensions();
  const styles = useStyles();
  const { showModal, resetVaccinationData } = useVaccinationModalState();

  const methods = useForm<VaccinationDto>({
    defaultValues: defaultFormValues,
  });

  const locationFieldVal = useWatch({
    name: 'location',
    control: methods.control,
  });
  const routeFieldVal = useWatch({
    name: 'route',
    control: methods.control,
  });

  useEffect(() => {
    if (showModal && resetVaccinationData) {
      methods.reset();
      handleResetVaccinationData(false);
    }
  }, [methods, showModal, resetVaccinationData]);

  const closeModal = useCallback(() => {
    handleResetVaccinationData(true);
    handleResetAuthData(true);
    updateShowModalState(false);
  }, [methods]);

  const handleSubmit = useCallback(() => {
    updateVaccinationState(methods.getValues());
    updateShowModalState(false);
    updateUserAuthenticationShowModalState(true);
  }, [closeModal, methods]);

  useEffect(() => {
    if (locationFieldVal === VaccinationLocation.Other) {
      return;
    }
    methods.clearErrors('other_location');
    methods.setValue('other_location', '');
  }, [locationFieldVal, methods]);

  useEffect(() => {
    if (routeFieldVal === VaccinationRoute.Other) {
      return;
    }
    methods.clearErrors('other_route');
    methods.setValue('other_route', '');
  }, [routeFieldVal, methods]);

  return (
    <Modal
      title={getText('vaccination-info')}
      size="lg"
      show={showModal}
      dismissButtonProps={{
        onPress: closeModal,
        logger: { id: 'vaccination-info-modal-cancel-button-modal' },
      }}
      cancelButtonProps={{
        onPress: closeModal,
        hierarchy: 'tertiary-gray',
        logger: { id: 'vaccination-info-modal-cancel-button-modal' },
      }}
      okButtonProps={{
        onPress: methods.handleSubmit(handleSubmit),
        logger: { id: 'vaccination-info-modal-ok-button-modal' },
        hierarchy: 'primary',
        text: getText('next'),
      }}
      handleDismissModal={closeModal}
      showDismissButton={true}
    >
      <ScrollView
        style={{
          maxHeight: viewportHeight * 0.75,
        }}
      >
        <View style={styles.alertContainer}>
          <Alert
            intent="warning"
            title={getText('one-time-vaccination-info-warning')}
          />
        </View>
        <Form methods={methods}>
          <Form.Row>
            <Form.Column style={styles.leftCol}>
              <TextField
                placeholder={`${getText('drug-name')}*`}
                name="drug_name"
                rules={{
                  required: getText('drug-name-is-required'),
                }}
              />
            </Form.Column>
            <Form.Column style={styles.rightCol}>
              <TextField
                placeholder={`${getText('amt-administered')}*`}
                name="amt_administered"
                rules={{
                  required: getText('amt-administered-is-required'),
                }}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column style={styles.leftCol}>
              <TextField
                placeholder={`${getText('manufacturer')}*`}
                name="manufacturer"
                rules={{
                  required: getText('manufacturer-is-required'),
                }}
              />
            </Form.Column>
            <Form.Column style={styles.rightCol}>
              <TextField
                placeholder={`${getText('lot-nr')}*`}
                name="lot"
                rules={{
                  required: getText('lot-nr-is-required'),
                }}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row style={[{ zIndex: zIndexAuto }]}>
            <Form.Column style={[styles.leftCol, { zIndex: zIndexAuto }]}>
              <Form.Row style={[styles.noMarginBottom, { zIndex: zIndexAuto }]}>
                <Form.Column style={[styles.rightCol, { zIndex: zIndexAuto }]}>
                  <DateTimePickerField
                    label={`${getText('exp-date')}`}
                    name="exp_date"
                    placeholder={`${getText('exp-date')}*`}
                    rules={{ required: getText('exp-date-required') }}
                    type={'date'}
                  />
                </Form.Column>
                <Form.Column style={[styles.rightCol, { zIndex: zIndexAuto }]}>
                  <DateTimePickerField
                    label={`${getText('date-of-vis')}`}
                    name="vis_date"
                    placeholder={`${getText('date-of-vis')}*`}
                    rules={{ required: getText('date-of-vis-required') }}
                    type={'date'}
                  />
                </Form.Column>
              </Form.Row>
            </Form.Column>
            <Form.Column style={[styles.rightCol, { zIndex: zIndexAuto }]}>
              <DateTimePickerField
                label={`${getText('date-administered')}`}
                name="administered_date"
                placeholder={`${getText('date-administered')}*`}
                rules={{ required: getText('date-administered-required') }}
                type={'date'}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row style={styles.noMarginBottom}>
            <Form.Column style={[styles.leftCol, styles.radioInputsContainer]}>
              <RadioButtonGroupField
                name="location"
                label={`${getText('location')}`}
                values={[
                  { text: getText('arm'), value: VaccinationLocation.Arm },
                  { text: getText('leg'), value: VaccinationLocation.Leg },
                  {
                    text: getText('other-option'),
                    value: VaccinationLocation.Other,
                  },
                ]}
                isHorizontal
                mode={RadioButtonInputMode.FLAT}
                rules={{
                  required: getText('location-required'),
                }}
              ></RadioButtonGroupField>
            </Form.Column>
            <Form.Column style={styles.rightCol}></Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column style={styles.leftCol}>
              <TextField
                placeholder={getText('other-location')}
                name="other_location"
                rules={{
                  required: getText('other-location-required'),
                  validate: () =>
                    locationFieldVal === VaccinationLocation.Other,
                  disabled: locationFieldVal !== VaccinationLocation.Other,
                }}
                disabled={locationFieldVal !== VaccinationLocation.Other}
              />
            </Form.Column>
            <Form.Column style={styles.rightCol}></Form.Column>
          </Form.Row>
          <Form.Row style={styles.noMarginBottom}>
            <Form.Column style={[styles.leftCol, styles.radioInputsContainer]}>
              <RadioButtonGroupField
                name="route"
                label={`${getText('route')}`}
                values={[
                  { text: getText('im'), value: VaccinationRoute.Im },
                  { text: getText('sq'), value: VaccinationRoute.Sq },
                  {
                    text: getText('other-option'),
                    value: VaccinationRoute.Other,
                  },
                ]}
                isHorizontal
                mode={RadioButtonInputMode.FLAT}
                rules={{
                  required: getText('route-required'),
                }}
              ></RadioButtonGroupField>
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column style={styles.leftCol}>
              <TextField
                placeholder={getText('other-route')}
                name="other_route"
                rules={{
                  required: getText('other-route-required'),
                  validate: () => routeFieldVal === VaccinationRoute.Other,
                  disabled: routeFieldVal !== VaccinationRoute.Other,
                }}
                disabled={routeFieldVal !== VaccinationRoute.Other}
              />
            </Form.Column>
            <Form.Column style={styles.rightCol}></Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column style={[styles.leftCol, styles.radioInputsContainer]}>
              <RadioButtonGroupField
                name="side"
                label={`${getText('side')}`}
                values={[
                  { text: getText('left'), value: VaccinationSide.Left },
                  { text: getText('right'), value: VaccinationSide.Right },
                ]}
                isHorizontal
                mode={RadioButtonInputMode.FLAT}
                rules={{
                  required: getText('side-required'),
                }}
              ></RadioButtonGroupField>
            </Form.Column>
          </Form.Row>
        </Form>
      </ScrollView>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  alertContainer: {
    marginBottom: theme.getSpacing(2),
  },
  leftCol: {
    flex: 2,
  },
  rightCol: {
    flex: 1,
  },
  radioInputsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  noMarginBottom: {
    marginBottom: 0,
  },
}));
