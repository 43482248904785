import { FC } from 'react';
import { View } from 'react-native';
import { StatusFilter } from 'assets/types/messageTypes';
import theme, { makeStyles } from 'assets/theme';
import EmptyStateMessage from '../../../../components/EmptyStateMessage/EmptyStateMessage';
import { AlertTriangleIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';

export const NoRowsRenderer: FC<NoRowsRenderProps> = ({ status }) => {
  const styles = useStyles();
  let menuItem = '';

  switch (status) {
    case StatusFilter.All:
      menuItem = 'All';
      break;
    case StatusFilter.Sent:
      menuItem = 'Sent';
      break;
    case StatusFilter.InProgress:
      menuItem = 'Scheduled';
      break;
  }

  return (
    <View style={styles.emptyStateMessageContainer}>
      <EmptyStateMessage
        icon={AlertTriangleIcon}
        message={getText('no-items-to-display-for') + ' ' + menuItem}
      />
    </View>
  );
};

interface NoRowsRenderProps {
  status: StatusFilter;
}

const useStyles = makeStyles(() => ({
  emptyStateMessageContainer: {
    marginTop: theme.getSpacing(2),
    paddingLeft: theme.getSpacing(3),
  },
}));
