import React, {
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';
import { FlatList, Pressable, TouchableOpacity, View } from 'react-native';
import { SingleValue } from 'react-select';
import { makeStyles, useTheme } from 'assets/theme';
import * as validate from '@digitalpharmacist/validation-dp';
import { DataGrid } from 'assets/components/data-grid';
import { getText } from 'assets/localization/localization';
import {
  Employee,
  OB_EMPLOYEE_FIELDS_TO_CHECK,
  useOnboardingStore,
} from '../../../../store/onboarding-store';
import {
  AlertTriangleIcon,
  PlusCircleIcon,
  TrashWithStripesIcon,
} from 'assets/icons';
import {
  save,
  updatePharmacyLocationEmployees,
} from '../../../../actions/onboarding-actions';
import { TextInput } from 'assets/components/text-field/TextInput';
import {
  ICellRendererParams,
  GridReadyEvent,
  GridApi,
  RowNode,
} from '@ag-grid-community/core';
import { IconButton } from 'assets/components/icon-button';
import { AdvancedDropDown } from '../../../../components/advanced-dropdown/advanced-drop-down';
import { Role } from '@digitalpharmacist/role-service-client-axios';
import { Icon } from 'assets/components/icon';
import { Text } from 'assets/components/text';
import { Modal } from 'assets/components/modal';
import { Alert } from 'assets/components/alert';
import '../styles/employees-grid-style.css';
import { FORM_NOT_VALID } from '../../utils';
import { OBFormHandler, OBFormProps } from '.';
import { AG_GRID_PAGINATION_PAGE_SIZE } from '../../../../common/constants';

const validateEmail = (value: string) => {
  return validate.isEmail(value);
};

type DropdownSelectType = { value: string; label: string };

const ROLES: Array<DropdownSelectType> = [
  { value: Role.ItAdmin, label: getText('role-it-admin') },
  { value: Role.Manager, label: getText('role-manager') },
  { value: Role.Staff, label: getText('role-staff') },
];

const rolesModalList = [
  {
    title: getText('role-it-admin'),
    text: getText('handles-organization-permissions'),
  },
  {
    title: getText('role-manager'),
    text: getText('location-level-leader'),
  },
  {
    title: getText('role-staff'),
    text: getText('common-level-of-access'),
  },
];

type InputCellRendererParams = ICellRendererParams & {
  gridContainerWidth: number;
};

const InputCellRenderer = (params: InputCellRendererParams) => {
  const styles = useStyles();
  const theme = useTheme();
  const { data, colDef, node, value, api, gridContainerWidth } = params;
  // 60 is the delete action width
  // 4 are the number of columns
  const cellWidth = (gridContainerWidth - 60) / 4;
  if (!colDef?.field) {
    console.warn('No field provided on [InputCellRenderer].');
    return;
  }

  const isFieldIsValid = (value: string) => {
    if (!value) return true;
    if (colDef.field === 'email') {
      return validateEmail(value);
    } else {
      return value.trim().length > 1;
    }
  };

  const [isValidField, setIsValidField] = useState(
    !value ? true : isFieldIsValid(value),
  );

  const isRequired =
    OB_EMPLOYEE_FIELDS_TO_CHECK.filter((x) => x !== colDef.field).some(
      (x) => data[x],
    ) && !value;

  const cellPos = api.getFocusedCell();
  const isFocused =
    cellPos?.rowIndex === node.rowIndex &&
    cellPos.column.getColDef().field === colDef.field;

  if (isFocused) {
    const element: HTMLElement | null = document.querySelector(
      `[data-testid="${colDef.field}_${node.rowIndex}"]`,
    );
    element?.focus();
  }

  const handleOnBlur = (args: { target: { value: string } }) => {
    const currValue = args.target.value;
    node.setDataValue(colDef.field || colDef.colId || '', currValue);
    setIsValidField(isFieldIsValid(args.target.value));
    if (!currValue) {
      api.redrawRows({ rowNodes: [node] });
    }
  };

  return (
    <View
      style={{
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      {(isRequired || !isValidField) && !isFocused && (
        <View style={{ marginLeft: 8, marginRight: 8 }}>
          <Icon
            icon={AlertTriangleIcon}
            size={16}
            color={theme.palette.error[500]}
          />
        </View>
      )}
      <TextInput
        style={{
          ...styles.inputCellRenderer,
          // 32 is the icon space with margins
          width: !isValidField && !isFocused ? cellWidth - 32 : cellWidth,
        }}
        value={data[colDef.field]}
        label={`${colDef.headerName} *`}
        testID={`${colDef.field}_${node.rowIndex}`}
        disabled={node.rowIndex === 0}
        onBlur={handleOnBlur}
      />
    </View>
  );
};

const SelectCellRenderer = (params: ICellRendererParams) => {
  const styles = useStyles();
  const theme = useTheme();
  const { data, colDef, node, value, api } = params;

  if (!colDef?.field) {
    console.warn('No field provided on [InputCellRenderer].');
    return;
  }

  const handleOnChange = (newVal: SingleValue<DropdownSelectType>) => {
    node.setDataValue(colDef.field || colDef.colId || '', newVal?.value);
  };

  const isRequired =
    OB_EMPLOYEE_FIELDS_TO_CHECK.filter((x) => x !== colDef.field).some(
      (x) => data[x],
    ) && !value;

  const selectedValue = ROLES.filter((x) => x.value === value)[0];

  const cellPos = api.getFocusedCell();
  const isFocused =
    cellPos?.rowIndex === node.rowIndex &&
    cellPos.column.getColDef().field === colDef.field;

  return (
    <View style={{ flexDirection: 'row', flex: 1, alignItems: 'center' }}>
      {isRequired && !selectedValue && !isFocused && (
        <View style={{ marginLeft: 8, marginRight: 8 }}>
          <Icon
            icon={AlertTriangleIcon}
            size={16}
            color={theme.palette.error[500]}
          />
        </View>
      )}
      <View style={{ flex: 1 }}>
        <AdvancedDropDown
          placeholder={`${colDef.headerName} *`}
          options={ROLES}
          defaultValue={selectedValue}
          isSearchable={false}
          onChange={handleOnChange}
          styles={{
            control: (base, state) => ({
              ...base,
              backgroundColor: undefined,
              color: undefined,
              boxShadow: undefined,
              borderColor: undefined,
              ':hover': {
                borderColor: undefined,
              },
              ...styles.selectCellRendererControl,
            }),
            placeholder: (base) => ({
              ...base,
              display: 'flex',
            }),
          }}
          isMulti={false}
          isDisabled={node.rowIndex === 0}
          autoFocus
        />
      </View>
    </View>
  );
};

const RemoveRowCellRenderer = (params: ICellRendererParams) => {
  const theme = useTheme();
  const { node } = params;

  const handleOnRemoveEmployee = () => {
    params.api.updateRowData({ remove: [node] });
  };

  return (
    params.rowIndex !== 0 && (
      <IconButton
        icon={TrashWithStripesIcon}
        onPress={handleOnRemoveEmployee}
        color={theme.palette.gray[500]}
        logger={{
          id: 'ob-remove-employee',
        }}
        size={20}
      />
    )
  );
};

export type OBEmployeesFormProps = OBFormProps & {
  width?: number;
};
export const OBEmployeesForm = forwardRef<OBFormHandler, OBEmployeesFormProps>(
  (
    { location, width = 700 }: OBEmployeesFormProps,
    ref: ForwardedRef<OBFormHandler>,
  ) => {
    const styles = useStyles();
    const theme = useTheme();
    const [gridApi, setGridApi] = useState<GridApi>();
    const [hasErrors, setHasErrors] = useState<boolean | undefined>();
    const [showRolesInfo, setShowRolesInfo] = useState<boolean>(false);

    // TODO: review this part in case of multilocation
    const employees = useOnboardingStore((x) =>
      location.employees.sort((e) =>
        e.email === x.data.wizard_user_email ? -1 : 0,
      ),
    );
    const locationId = useOnboardingStore((x) => location.id);
    const onGridReady = (event: GridReadyEvent) => {
      setGridApi(event.api);
    };

    const handleOnAddNewEmployee = () => {
      gridApi?.updateRowData({ add: [{ id: `new_${Date.now()}` }] });
      const pageSize = gridApi?.paginationGetPageSize();
      const rowCount = gridApi?.paginationGetRowCount();
      const currPage = gridApi?.paginationGetCurrentPage();
      if (rowCount && pageSize && currPage !== undefined) {
        const page = parseInt(((rowCount - 1) / pageSize).toFixed(2));

        if (currPage !== page) gridApi?.paginationGoToPage(page);
      }
    };

    const handleOnSubmit = async () => {
      const empModified: Array<Employee> = [];
      gridApi?.forEachNode((row: RowNode) => {
        empModified.push(row.data as Employee);
      });
      const isInvalidForm = empModified.some((emp: any) => {
        const areEmptyValues = OB_EMPLOYEE_FIELDS_TO_CHECK.every(
          (x) => !emp[x],
        );
        if (areEmptyValues) return false;

        return (
          OB_EMPLOYEE_FIELDS_TO_CHECK.some(
            (x) => !emp[x] || emp[x].trim().length < 1,
          ) || !validateEmail(emp['email'])
        );
      });
      setHasErrors(isInvalidForm);
      gridApi?.redrawRows(); //needed to force rerendering of th cell renderers
      if (isInvalidForm) throw FORM_NOT_VALID;
      updatePharmacyLocationEmployees(
        locationId,
        empModified.map((x) => ({
          ...x,
          id: x.id?.startsWith('new') ? undefined : x.id,
        })),
      );
    };

    useImperativeHandle(ref, () => ({
      handleSubmit: handleOnSubmit,
    }));

    return (
      <View style={styles.root}>
        <Pressable onPress={() => setShowRolesInfo(true)}>
          <Text style={styles.subtitle}>
            {getText('learn-about-employee-roles')}
          </Text>
        </Pressable>

        {hasErrors && (
          <Alert
            title={getText('ob-employees-generic-error')}
            intent="error"
            style={{
              marginTop: theme.getSpacing(4),
            }}
          />
        )}

        <View style={[styles.tableContainer, { width: width }]}>
          <DataGrid
            gridOptions={{
              columnDefs: [
                {
                  field: 'first_name',
                  headerName: getText('first-name'),
                  autoHeight: true,
                  cellRenderer: InputCellRenderer,
                  cellRendererParams: {
                    gridContainerWidth: width,
                  },
                },
                {
                  field: 'last_name',
                  headerName: getText('last-name'),
                  cellRenderer: InputCellRenderer,
                  cellRendererParams: {
                    gridContainerWidth: width,
                  },
                },
                {
                  field: 'email',
                  headerName: getText('email-address'),
                  cellRenderer: InputCellRenderer,
                  cellRendererParams: {
                    gridContainerWidth: width,
                  },
                },
                {
                  field: 'role',
                  headerName: getText('role'),
                  cellRenderer: SelectCellRenderer,
                },
                {
                  width: 58,
                  cellStyle: {
                    padding: 0,
                    alignContent: 'center',
                  },
                  cellRenderer: RemoveRowCellRenderer,
                },
              ],
              rowStyle: { border: 0 },
              rowData: employees,
              headerHeight: 0,
              getRowId: (params) => params.data.id,
              enableCellTextSelection: true,
              suppressMovableColumns: true,
              suppressContextMenu: true,
              suppressRowHoverHighlight: true,
              defaultColDef: { sortable: false, menuTabs: [] },
              pagination: true,
              paginationPageSize: AG_GRID_PAGINATION_PAGE_SIZE,
              rowModelType: 'clientSide',
              onGridReady: onGridReady,
              domLayout: 'autoHeight',
            }}
            className="ob-employee"
          />
          <View style={styles.newButtonContainer}>
            <TouchableOpacity
              onPress={handleOnAddNewEmployee}
              style={styles.pressableContainer}
            >
              <Icon
                icon={PlusCircleIcon}
                size={16}
                color={theme.palette.primary[600]}
              />
              <Text style={styles.addButtonText}>
                {getText('add-employee')}
              </Text>
            </TouchableOpacity>
          </View>
        </View>

        <Modal
          title={getText('lumistry-roles')}
          size="sm"
          show={showRolesInfo}
          showDismissButton
          handleDismissModal={() => setShowRolesInfo(false)}
          buttons={[
            {
              hierarchy: 'tertiary-gray',
              onPress: () => setShowRolesInfo(false),
              logger: { id: 'lumistry-roles-close-button' },
              text: getText('close'),
            },
          ]}
        >
          <Text style={styles.itemText}>
            {getText('employee-role-determines-action')}
          </Text>
          <FlatList
            style={styles.modalList}
            data={rolesModalList}
            renderItem={({ item }) => (
              <View style={styles.itemContainer}>
                <View>•</View>
                <Text>
                  <Text style={styles.itemTitle}>{item.title}</Text>
                  <Text style={styles.itemText}>{` - ${item.text}`}</Text>
                </Text>
              </View>
            )}
            keyExtractor={(item, index) => index.toString()}
          />
        </Modal>
      </View>
    );
  },
);

const useStyles = makeStyles((theme) => ({
  root: {
    alignSelf: 'center',
  },
  tableContainer: {
    flex: 1,
    width: 700,
    minHeight: 441,
    marginVertical: theme.getSpacing(2),
  },
  newButtonContainer: {
    alignItems: 'flex-end',
    marginVertical: theme.getSpacing(2),
  },
  pressableContainer: {
    flexDirection: 'row',
    gap: theme.getSpacing(0.5),
    alignItems: 'center',
  },
  addButtonText: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.primary[600],
  },
  inputCellRenderer: {
    borderColor: 'transparent',
    backgroundColor: 'transparent',
    height: 58,
    width: 160,
  },
  selectCellRendererControl: { border: 0, borderRadius: 0 },
  subtitle: {
    ...theme.lumistryFonts.text.large.regular,
    color: theme.palette.primary[600],
    textAlign: 'center',
  },
  modalList: {
    paddingTop: theme.getSpacing(2),
  },
  itemContainer: {
    flexDirection: 'row',
    gap: theme.getSpacing(0.5),
    color: theme.palette.gray[700],
  },
  itemTitle: {
    ...theme.lumistryFonts.text.small.bold,
  },
  itemText: {
    ...theme.lumistryFonts.text.small.regular,
  },
}));
