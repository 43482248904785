import { InfoCardDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { IconButton } from 'assets/components/icon-button';
import { Text } from 'assets/components/text';
import { PencilIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import { FunctionComponent, PropsWithChildren } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { ensureHttpsUrl } from '../../../../../utils';
import { openUrl } from '../../../../actions/app-actions';
import { RowItemRender } from '../../../../components/Settings/RowItemRender';
import {
  infoCardsFindOne,
  setShowModalLocationInfoCards,
} from '../location-settings-actions';

export const LocationInfoCardItemRenderer: FunctionComponent<
  PropsWithChildren<InfoCardDto>
> = ({ id, title, summary, link_url, link_label }) => {
  const styles = useStyles();
  const theme = useTheme();

  return (
    <RowItemRender title={title} style={{ alignContent: 'flex-start' }}>
      <View style={styles.viewStyle}>
        <View style={styles.weekViewStyle}>
          <Text>{`${getText('summary')}: ${summary}`}</Text>
          {link_url ? (
            <TouchableOpacity
              onPress={() => openUrl(ensureHttpsUrl(link_url), '_blank')}
            >
              <View style={styles.viewLinkStyle}>
                <Text style={styles.linkTextStyle}>{`${getText(
                  'link',
                )}: `}</Text>
                <Text style={styles.linkStyle}>
                  {link_label || getText('none')}
                </Text>
              </View>
            </TouchableOpacity>
          ) : (
            <Text style={styles.linkTextStyle}>{`${getText('link')}: ${getText(
              'none',
            )}`}</Text>
          )}
        </View>
        <View style={styles.buttonView}>
          <IconButton
            icon={PencilIcon}
            onPress={() => {
              infoCardsFindOne(id);
              setShowModalLocationInfoCards(true);
            }}
            size={16}
            color={theme.palette.gray[700]}
            logger={{ id: 'task-type-renderer-action-button-edit' }}
          />
        </View>
      </View>
    </RowItemRender>
  );
};

const useStyles = makeStyles((theme) => ({
  linkTextStyle: {
    ...theme.lumistryFonts.text.small,
    color: theme.palette.gray[700],
  },
  linkStyle: {
    ...theme.lumistryFonts.text.small,
    color: theme.palette.primary[600],
  },
  weekViewStyle: {
    alignItems: 'flex-start',
    textAlign: 'left',
    flex: 4,
  },
  buttonView: {
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  viewStyle: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  viewLinkStyle: {
    display: 'flex',
    flexDirection: 'row',
  },
}));
