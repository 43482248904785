import { RootStackNavigationProp } from '../../navigation/RootNavigation';
import { useResetPasswordState } from './reset-password-store';
import ResetPasswordService from '../../api/ResetPasswordService';
import { AxiosError } from 'axios';
import { ResetPasswordError } from '../../store/types';
import { getText } from 'assets/localization/localization';
import { useUserState } from '../../store/user-store';
import { ApiError } from 'assets/core/api';
import axios from 'axios';
export const resetPassword = async (
  values: ResetPasswordForm,
  navigation: RootStackNavigationProp,
): Promise<void> => {
  useResetPasswordState.setState({ error: undefined, status: 'loading' });
  try {
    await ResetPasswordService.resetPassword({ email: values.email });

    useResetPasswordState.setState({ status: 'success' });
    useUserState.setState({ data: { email: values.email } });
    // useUserState.setState({ data: userResetPassword as any }); // TODO: is this needed?
    navigation.navigate('reset-link-sent');
  } catch (e) {
    //TODO: this seems is already handling the error from axios
    // TODO: should we redirect to the same page on 404??
    if (axios.isAxiosError(e) && e.response?.status === 404) {
      navigation.navigate('reset-link-sent');
    }
    useResetPasswordState.setState({
      error: { message: getText('unable-to-send-reset-link') },
      status: 'error',
    });
  }
};

export const confirmPassword = async (
  values: ResetPasswordModel,
  navigation: RootStackNavigationProp,
): Promise<void> => {
  useResetPasswordState.setState({ error: undefined, status: 'loading' });

  try {
    await ResetPasswordService.confirmPassword({
      email: values.email,
      password: values.password,
      reset_password_link_id: values.resetPasswordLinkId,
      confirmation_code: values.confirmationCode,
    });
    useResetPasswordState.setState({ status: 'success' });
    navigation.navigate('login');
  } catch (e) {
    if (axios.isAxiosError(e) && e.response?.status === 400) {
      useResetPasswordState.setState({
        error: { message: getText('used-password') },
        status: 'error',
      });
    } else if (axios.isAxiosError(e) && e.response?.status === 410) {
      useResetPasswordState.setState({
        error: { message: getText('expired-link') },
        status: 'error',
      });
    } else {
      useResetPasswordState.setState({
        error: { message: getText('something-went-wrong-implicit') },
        status: 'error',
      });
    }
  }
};

export interface ResetPasswordForm {
  email: string;
}

export interface ConfirmPasswordForm {
  password: string;
  passwordConfirm: string;
}

export interface ResetPasswordModel
  extends ResetPasswordForm,
    Omit<ConfirmPasswordForm, 'passwordConfirm'> {
  resetPasswordLinkId: string;
  confirmationCode: string;
}
