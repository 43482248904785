import { getText } from 'assets/localization/localization';
import {
  BulkMessageTime,
  DynamicInputValue,
  ETo,
  EToSpecificFilter,
} from '../common/types/MessageForm';
import { BulkMessageType } from '@digitalpharmacist/unified-communications-service-client-axios';

export const timeTextMap = {
  [BulkMessageTime.IMMEDIATE]: getText('immediate'),
  [BulkMessageTime.SCHEDULED]: getText('scheduled'),
};

export const typeTextMap = {
  [BulkMessageType.Secure]: getText('secure-chat'),
  [BulkMessageType.NotSecure]: getText('non-secure'),
};

export const toListRecipientCriteriaTextMap = {
  [ETo.TO_ALL]: getText('send-to-all'),
  [ETo.TO_SPECIFIC]: getText('send-to-specific-people'),
  [ETo.TO_FILTERED_LIST]: getText('send-to-filtered-list'),
  [DynamicInputValue.AGE_IS]: getText('age-is'),
  [DynamicInputValue.AGE_IS_NOT]: getText('age-is-not'),
  [DynamicInputValue.AGE_IS_OLDER_THAN]: getText('age-is-older-than'),
  [DynamicInputValue.AGE_IS_YOUNGER_THAN]: getText('age-is-younger-than'),
  [DynamicInputValue.GENDER_IS_FEMALE]: getText('gender-is-female'),
  [DynamicInputValue.GENDER_IS_MALE]: getText('gender-is-male'),
  [DynamicInputValue.ZIP_CODE_IS_NOT_ONE_OF]: getText('zip-code-is-not-one-of'),
  [DynamicInputValue.ZIP_CODE_IS_ONE_OF]: getText('zip-code-is-one-of'),
};

export const toSpecificRecipientCriteriaTextMap = {
  [EToSpecificFilter.SPECIFIC]: 'dummy text',
};
