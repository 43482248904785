import { makeStyles } from 'assets/theme';
import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import LocationsList from './components/LocationsList';
import LocationsSearchFilter from './components/LocationsSearchFilter';

export const AllLocationsSettings: FunctionComponent = () => {
  const styles = useStyles();

  return (
    <View style={styles.container}>
      <LocationsSearchFilter />
      <LocationsList />
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    marginLeft: theme.getSpacing(3),
  },
}));
