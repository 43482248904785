import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { ScrollView, View } from 'react-native';
import {
  fetchById,
  setSetupStage,
  submit,
} from '../../actions/onboarding-actions';
import { useToast } from '../../common/hooks/useToast';
import { LoadingOverlay } from '../../components/LoadingOverlay';
import { OnboardingScreenProps } from '../../navigation/RootNavigation';
import { SetupStage, useOnboardingStore } from '../../store/onboarding-store';
import { OBLanding } from './components/OBLanding';
import { OBSuccess } from './components/OBSuccess';
import { OBMultiLocationSteps, OBSingleLocationSteps } from './utils';
import { OBGenericError } from './components/common/OBGenericError';
import { OBMultiLocationWizard } from './components/OBMultiLocationWizard';
import { OBSingleLocationWizard } from './components/OBSingleLocationWizard';

export const Onboarding: FunctionComponent<OnboardingScreenProps> = ({
  route,
  navigation,
}) => {
  const styles = useStyles();
  const { data } = useOnboardingStore();
  const { toast } = useToast();
  const scrollViewRef = useRef<ScrollView>(null);
  const [landingPageSeen, setLandingPageSeen] = useState(false);

  useEffect(() => {
    void (async () => {
      if (route.params?.wizardId) await fetchById(route.params.wizardId);
    })();
  }, [route.params?.wizardId]);

  useEffect(() => {
    if (!route.params?.step) return;
    if (!data) return;
    if (!data.locations) return;
    if (!data.locations.length) return;

    const currStep = parseInt(route.params.step);
    const stepsLength =
      data.locations.length > 1
        ? OBMultiLocationSteps.length
        : OBSingleLocationSteps.length;

    if (currStep >= 0 && currStep < stepsLength)
      setSetupStage(SetupStage.SetupWizard);
    else if (currStep >= stepsLength) setSetupStage(SetupStage.Exit);
  }, [route.params?.step, data]);

  const setupStage = useOnboardingStore((state) => state.setupStage);
  const status = useOnboardingStore((state) => state.status);

  const scrollToTop = () => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTo({ y: 0, animated: false });
    }
  };

  const onFinish = async () => {
    try {
      await submit();

      const currStepParam = parseInt(route.params?.step ?? '0');
      handleOnStepChange(currStepParam + 1);
      scrollToTop();
    } catch (e) {
      toast('Error', {
        content: getText('error-occured-ob-save'),
        type: 'error',
      });
      throw e;
    }
  };

  const handleOnStepChange = (stepIndex: number) => {
    navigation.setParams({ step: `${stepIndex}` });
    scrollToTop();
  };

  const nextButtonHandler = () => {
    setLandingPageSeen(true);
    setSetupStage(SetupStage.SetupWizard);
    handleOnStepChange(0);
  };

  useEffect(() => {
    if (!route.params?.step) return;
    const currStep = parseInt(route.params.step);
    if (currStep === 0 && setupStage === SetupStage.Landing && landingPageSeen)
      navigation.setParams({ step: undefined });
  }, [setupStage]);

  return (
    <View style={styles.root}>
      {(status === 'success' || status === 'HANDLED_ERROR') && (
        <ScrollView
          contentContainerStyle={styles.contentContainer}
          ref={scrollViewRef}
        >
          {setupStage === SetupStage.Landing && (
            <OBLanding onNextPress={nextButtonHandler} />
          )}
          {setupStage === SetupStage.SetupWizard && data.id && (
            <>
              {data.locations.length > 1 ? (
                <OBMultiLocationWizard
                  onFinish={onFinish}
                  startIndex={parseInt(route.params?.step ?? '0')}
                  onStepChange={handleOnStepChange}
                />
              ) : (
                <OBSingleLocationWizard
                  onFinish={onFinish}
                  startIndex={parseInt(route.params?.step ?? '0')}
                  onStepChange={handleOnStepChange}
                />
              )}
            </>
          )}

          {setupStage === SetupStage.Exit && <OBSuccess />}
        </ScrollView>
      )}
      {status === 'loading' && <LoadingOverlay />}

      {/* TODO: add a ux for not allowed / error state */}
      {status === 'NOT_ALLOWED' && <OBGenericError />}
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    gap: theme.getSpacing(2),
    flexDirection: 'row',
    padding: theme.getSpacing(3),
    justifyContent: 'center',
  },
  contentContainer: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'center',
    minWidth: 722,
  },
}));
