import React from 'react';
import { TooltipContext, TooltipContextType } from '../context';
import { TooltipConfig } from '../types';

export function useTooltipContext(): TooltipContextType {
  return React.useContext(TooltipContext);
}

export function useTooltip(): TooltipConfig {
  const { appTooltipConfig } = useTooltipContext();
  return appTooltipConfig;
}
