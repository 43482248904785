import create from 'zustand';
import { ApiError, AsyncStatus } from '../../../store/types';

export const usePMSIntegrationsState = create<PMSIntegrationState>(() => ({
  pmsConfigurationExists: false,
  showPMSFormModal: false,
  pmsConfig: {
    id: '',
    subscribe_to_webhooks: false,
    integration_type: '',
    server_address: '',
    server_port: '',
    timeout: '',
    allowed_concurrency: '',
    auth_url: '',
    auth_user: '',
    auth_secret: '',
    multistore_enable: false,
    multistore_offset: '',
    multistore_length: '',
    integration_location_id: '',
    npi: '',
    pharmacy_phone: '',
    token: '',
    api_host: '',
    subscription_key: '',
    api_key: '',
    is_subscribed_to_webhooks: true,
    is_datafeed_enabled: true,
    override_defaults: false,
    incoming_webhook_api_key: '',
    location_id: '',
  },
  status: 'idle',
}));

interface PMSIntegrationState {
  pmsConfigurationExists: boolean;
  showPMSFormModal: boolean;
  pmsConfig: any;
  status: AsyncStatus;
  error?: ApiError;
}
