import {
  DetailedUser,
  DetailedUserRole,
} from '@digitalpharmacist/role-service-client-axios';
import create from 'zustand';
import { UserInfo, ViewSidebarState } from './user-types';

const useViewSidebarStore = create<ViewSidebarState>((set) => ({
  showSidebar: false,
  setShowSidebar: (showSidebar: boolean) =>
    set((state: ViewSidebarState) => ({
      ...state,
      showSidebar,
    })),
  showConfirmDeleteModal: false,
  setShowConfirmDeleteModal: (showConfirmDeleteModal: boolean) =>
    set((state: ViewSidebarState) => ({
      ...state,
      showConfirmDeleteModal,
    })),
  userDetails: undefined,
  setUserDetails: (userDetails: DetailedUser) =>
    set((state: ViewSidebarState) => ({
      ...state,
      userDetails,
    })),
  setUserRoles: (user_roles: DetailedUserRole[]) =>
    set((state: ViewSidebarState) => ({
      ...state,
      userDetails: state.userDetails
        ? {
            ...state.userDetails,
            user_roles,
          }
        : undefined,
    })),
  setUserInfo: (info: UserInfo) =>
    set((state: ViewSidebarState) => ({
      ...state,
      userDetails: state.userDetails
        ? {
            ...state.userDetails,
            firstName: info.firstName,
            lastName: info.lastName,
            email: info.email,
          }
        : undefined,
    })),
  showUserEdit: false,
  setShowUserEdit: (showUserEdit: boolean) => set({ showUserEdit }),
}));

export default useViewSidebarStore;
