import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { makeStyles } from 'assets/theme';
import { WizardProgress } from 'assets/components/wizard-progress';
import { useWizard } from 'react-use-wizard';

const PharmacyOBWizardHeader: FunctionComponent<{ steps: Array<string> }> = ({
  steps,
}) => {
  const styles = useStyles();
  const { activeStep } = useWizard();
  return (
    <View style={styles.root}>
      <WizardProgress
        currentStep={activeStep + 1}
        steps={steps}
        type="withTitle"
      />
    </View>
  );
};

export default PharmacyOBWizardHeader;

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.getSpacing(3),
    width: '100%',
  },
}));
