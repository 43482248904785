import { FC } from 'react';
import { makeStyles } from 'assets/theme';
import { RecipientRendererProps } from '../types';
import { Text } from 'assets/components/text';
import {
  calculatePatientAge,
  formatDate,
} from '../../../../common/datetime-utils';
import { getText } from 'assets/localization/localization';

export const DateOfBirthRenderer: FC<RecipientRendererProps> = ({ data }) => {
  if (!data) {
    return <></>;
  }

  const { date_of_birth, gender } = data ?? {};
  const age = calculatePatientAge(date_of_birth);
  const styles = useStyles();

  if (!date_of_birth && !gender) {
    return <Text style={[styles.dateOfBirth]}>{getText('no-data')}</Text>;
  }

  return (
    <Text style={[styles.dateOfBirth]}>
      {formatDate(date_of_birth)}
      {', '}
      {age} {gender === 'male' ? ' M' : gender === 'female' ? ' F' : ''}
    </Text>
  );
};

const useStyles = makeStyles((theme) => ({
  dateOfBirth: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.gray[700],
  },
}));
