import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { getText } from 'assets/localization/localization';
import { PrescriptionDto } from '@digitalpharmacist/prescription-service-client-axios';
import { View } from 'react-native';
import { Text } from 'assets/components/text/Text';
import theme, { makeStyles } from 'assets/theme';
import { Divider } from 'react-native-paper';
import { formatPhoneNumber } from 'assets/utils/phoneNumber';
import { formatDate } from '../../common/datetime-utils';
import { AutoFillInfo } from './AutoFillInfo';
import { getRxStatusText } from '../../../utils';
import { LocationPatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';
import { useVideosState } from '../../store/videos-store';
import NewChatButton from '../../screens/messages/components/new-chat-button/NewChatButton';

export const PatientMedsCard: FunctionComponent<
  PropsWithChildren<PatientMedsCardProps>
> = ({ patientPrescription, patient, index }) => {
  const styles = useStyles();
  const { videoInformation } = useVideosState();

  const doctorFullName =
    (patientPrescription.doctor_first_name ||
      patientPrescription.doctor_last_name) &&
    `Dr. ${patientPrescription.doctor_first_name || ''} ${
      patientPrescription.doctor_last_name || ''
    }`.trim();
  const doctorPhone = patientPrescription.doctor_phone
    ? formatPhoneNumber(patientPrescription.doctor_phone.replace(/-/g, '')) ||
      patientPrescription.doctor_phone
    : '';

  const isVideoAvailable =
    patientPrescription.ndc &&
    videoInformation &&
    videoInformation[index]?.short_urls &&
    videoInformation[index]?.short_urls[0]?.length > 0;

  return (
    <View>
      <View style={styles.rowSpaceBetween}>
        <View
          style={
            isVideoAvailable
              ? {}
              : {
                  marginTop: theme.getSpacing(2),
                  marginBottom: theme.getSpacing(1),
                }
          }
        >
          <Text style={styles.textTitle}>{patientPrescription.drug_name}</Text>
        </View>
        {isVideoAvailable && (
          <NewChatButton
            patient={patient}
            defaultSubject={getText('med-info')}
            defaultMessage={getText('quick-link-med-info', {
              link: videoInformation[index].short_urls[0] ?? '',
            })}
            buttonHierarchy="tertiary"
            buttonText={getText('send-video')}
          />
        )}
      </View>
      <View style={styles.rowView}>
        <View>
          <Text style={styles.textDetails}>{getText('rx')} </Text>
        </View>
        <View>
          <Text style={styles.textDetails}>
            {patientPrescription.rx_number}
          </Text>
        </View>
      </View>
      <View style={styles.rowView}>
        <View>
          <Text style={styles.textDetails}>{getText('status')}: </Text>
        </View>
        <View>
          <Text style={styles.textDetails}>
            {patientPrescription.rx_status
              ? getRxStatusText(patientPrescription.rx_status)
              : getText('prescriber-info-not-available')}
          </Text>
        </View>
      </View>
      <View style={styles.rowView}>
        <View>
          <Text style={styles.textDetails}>{getText('date-written')}: </Text>
        </View>
        <View>
          {patientPrescription.written_date ? (
            <Text style={styles.textDetails}>
              {formatDate(patientPrescription.written_date)}
            </Text>
          ) : (
            <Text style={styles.textDetails}>
              {getText('prescriber-info-not-available')}
            </Text>
          )}
        </View>
      </View>
      <View style={styles.rowView}>
        <View>
          <Text style={styles.textDetails}>
            {getText('last-filled-date')}:{' '}
          </Text>
        </View>
        <View>
          {patientPrescription.last_refill_date ? (
            <Text style={styles.textDetails}>
              {formatDate(patientPrescription.last_refill_date)}
            </Text>
          ) : (
            <Text style={styles.textDetails}>
              {getText('prescriber-info-not-available')}
            </Text>
          )}
        </View>
      </View>
      <View style={styles.rowView}>
        <View>
          <Text style={styles.textDetails}>
            {getText('remaining-refills')}:{' '}
          </Text>
        </View>
        <View>
          {patientPrescription.remaining_refills ? (
            <Text style={styles.textDetails}>
              {patientPrescription.remaining_refills}
            </Text>
          ) : (
            <Text style={styles.textDetails}>
              {getText('prescriber-info-not-available')}
            </Text>
          )}
        </View>
      </View>
      <View style={styles.rowView}>
        <View>
          <Text style={styles.textDetails}>{getText('auto-refill')}: </Text>
        </View>
        <View>
          <Text style={styles.textDetails}>
            <AutoFillInfo prescription={patientPrescription} />
          </Text>
        </View>
      </View>
      <View style={styles.rowView}>
        <View>
          <Text style={styles.textDetails}>{getText('prescriber')}: </Text>
        </View>
        <View>
          <Text style={styles.textDetails}>
            {!doctorFullName && !doctorPhone ? (
              <Text style={styles.textDetails}>
                {getText('prescriber-info-not-available')}
              </Text>
            ) : (
              <>
                <Text style={styles.textDetails}>
                  {doctorFullName || getText('prescriber-name-not-available')}
                  {', '}
                </Text>
                <Text style={styles.textDetails}>
                  {doctorPhone || getText('prescriber-phone-not-available')}
                </Text>
              </>
            )}
          </Text>
        </View>
      </View>
      <Divider style={styles.divider} />
    </View>
  );
};

export interface PatientMedsCardProps {
  patientPrescription: PrescriptionDto;
  patient: LocationPatientRecordDto;
  index: number;
}

export default PatientMedsCard;

const useStyles = makeStyles((theme) => ({
  textTitle: {
    ...theme.lumistryFonts.label.xSmall.bold,
    color: theme.palette.gray[900],
    textTransform: 'capitalize',
  },
  textDetails: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.gray[700],
  },
  buttonText: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.primary[600],
  },
  rowView: {
    flexDirection: 'row',
  },
  rowSpaceBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  divider: {
    marginTop: theme.getSpacing(2),
  },
}));
