import { useMessageSettingsState } from './message-settings-store';
import { useToast } from '../../../common/hooks/useToast';
import { convertEmptyStringsToNull } from '../../../common/form-utils';
import { getText } from 'assets/localization/localization';
import { ModifyDirectMessageSettingsDto } from '@digitalpharmacist/unified-communications-service-client-axios';
import UnifiedCommsService from '../../../api/UnifiedCommsService';
import RoleService from '../../../api/RoleService';
import { useUserState } from '../../../store/user-store';
import {
  IsLumistryUser,
  UserHasAccess,
} from '../../../common/guards/no-access-user-guard';

export const updateShowMessageSettingsAutoModalState = (newState: boolean) => {
  useMessageSettingsState.setState((prevState) => ({
    ...prevState,
    showMessageSettingsAutoFormModal: newState,
  }));
};

export const updateShowMessageSettingsMarketingPhoneState = (
  newState: boolean,
) => {
  useMessageSettingsState.setState((prevState) => ({
    ...prevState,
    showMessageSettingsMarketingPhoneModal: newState,
  }));
};

export const updateShowMessageSettingsNonMarketingPhoneState = (
  newState: boolean,
) => {
  useMessageSettingsState.setState((prevState) => ({
    ...prevState,
    showMessageSettingsNonMarketingPhoneModal: newState,
  }));
};

export const modifyMessageSettings = async (
  pharmacyId: string,
  locationID: string,
  modifyDirectMessageSettingsDto: ModifyDirectMessageSettingsDto,
): Promise<void> => {
  const { toast } = useToast();
  if (!modifyDirectMessageSettingsDto.marketing_phone) {
    delete modifyDirectMessageSettingsDto.marketing_phone;
  }

  if (!modifyDirectMessageSettingsDto.non_marketing_phone) {
    delete modifyDirectMessageSettingsDto.non_marketing_phone;
  }

  await UnifiedCommsService.modifyDirectMessageSettings(
    pharmacyId,
    locationID,
    modifyDirectMessageSettingsDto,
  );

  useMessageSettingsState.setState((prevState) => ({
    ...prevState,
    error: undefined,
    status: 'loading',
    messageSettingsConfig: modifyDirectMessageSettingsDto,
  }));
  toast(getText('message-settings-saved'), { type: 'success' });
};

export const setIsLumistryUser = async () => {
  const user = useUserState.getState();
  const roles = await RoleService.userRoleGetRolesByUserId(
    user.data?.id as string,
  );

  useMessageSettingsState.setState((prevState) => ({
    ...prevState,
    hasAccess: UserHasAccess(roles),
  }));
};

export const getDirectMessageSettings = async (
  pharmacyId: string,
  locationId: string,
) => {
  useMessageSettingsState.setState((prevState) => ({
    ...prevState,
    error: undefined,
    status: 'loading',
  }));

  try {
    const response = await UnifiedCommsService.getDirectMessageSettings(
      pharmacyId,
      locationId,
    );
    useMessageSettingsState.setState((prevState) => ({
      ...prevState,
      status: 'success',
      messageSettingsConfig: response,
    }));
  } catch (error: any) {
    return Promise.reject(error);
  }
};
