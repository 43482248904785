import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path, Rect } from 'react-native-svg';
import { IconProps } from './types';

export const RefillsCircleIcon: FunctionComponent<
  PropsWithChildren<IconProps>
> = ({ color, size, testID }) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 41 40"
      fill="none"
      color={color}
      testID={testID}
    >
      <Rect x="0.5" width="40" height="40" rx="20" fill="#9CAFB9" />
      <Path
        d="M14.6663 16.053H26.333M14.6663 16.053V27.3337C14.6663 27.8859 15.1141 28.3337 15.6663 28.3337H25.333C25.8853 28.3337 26.333 27.8859 26.333 27.3337V16.053M14.6663 16.053V16.053C14.2061 16.053 13.833 15.6799 13.833 15.2196V12.667C13.833 12.1147 14.2807 11.667 14.833 11.667H20.4997H26.1663C26.7186 11.667 27.1663 12.1147 27.1663 12.667V15.2196C27.1663 15.6799 26.7932 16.053 26.333 16.053V16.053"
        stroke="white"
        strokeWidth="1.5"
      />
    </Svg>
  );
};
