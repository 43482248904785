import { UserRoleDto } from '@digitalpharmacist/role-service-client-axios';
import create from 'zustand';

export interface ProfileSettingsState {
  userRoles: UserRoleDto[];
}

export const useProfileSettingsState = create<ProfileSettingsState>(() => ({
  userRoles: [],
}));
