import { AutomationType } from '@digitalpharmacist/tasks-service-client-axios';
import { getText } from 'assets/localization/localization';

export enum ConfigurationFieldKeysEnum {
  EasyOpenBottleCaps = 'easy_open_bottle_caps',
  MedSync = 'med_sync',
  MedicalConditions = 'medical_conditions',
  AutoRefills = 'auto_refills',
  InsuranceChanges = 'insurance_changes',
}

export const ConfigurationFieldKeysLocalization = {
  [ConfigurationFieldKeysEnum.EasyOpenBottleCaps]: getText(
    'easy-open-bottle-caps',
  ),
  [ConfigurationFieldKeysEnum.MedSync]: getText('med-sync'),
  [ConfigurationFieldKeysEnum.MedicalConditions]: getText(
    'medical-conditions-non-capitalized',
  ),
  [ConfigurationFieldKeysEnum.AutoRefills]: getText('auto-refills'),
  [ConfigurationFieldKeysEnum.InsuranceChanges]: getText('insurance-changes'),
};

export const AutomationTitleLocalization = {
  [AutomationType.PatientRecordUpdate]: getText('patient-record-updates'),
  [AutomationType.FailedRefills]: getText('failed-refills'),
  [AutomationType.PaperlessPatient]: getText('paperless-patient'),
  [AutomationType.AllergyUpdates]: getText('allergy-updates'),
  [AutomationType.RxTransferRequest]: getText('rx-transfer-request'),
};

export const AutomationDescriptionLocalization = {
  [AutomationType.PatientRecordUpdate]: getText(
    'patient-record-updates-description',
  ),
  [AutomationType.FailedRefills]: getText('failed-refills-description'),
  [AutomationType.PaperlessPatient]: getText('paperless-patient-description'),
  [AutomationType.AllergyUpdates]: getText('allergy-updates-description'),
  [AutomationType.RxTransferRequest]: getText(
    'rx-transfer-request-description',
  ),
};
