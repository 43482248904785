import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { View, Text } from 'react-native';
import { makeStyles, useTheme } from 'assets/theme';
import useRefillsStore from './refills-store';
import { getText } from 'assets/localization/localization';
import { formatTimeDate } from '../../common/datetime-utils';
import {
  PharmacySidebar,
  useStyles as usePharmacySidebarStyles,
} from '../../components/PharmacySidebar';
import { RefillStatus } from '@digitalpharmacist/pms-integration-service-client-axios';
import NewChatButton from '../messages/components/new-chat-button/NewChatButton';
import {
  formatPhoneNumber,
  getRxStatusMessageText,
  getWorkflowStatusMessageText,
} from '../../../utils';
import {
  PrescriptionDto,
  RefillDto,
} from '@digitalpharmacist/prescription-service-client-axios';
import prescriptionService from '../../api/PrescriptionService';
import { useAppStateStore } from '../../store/app-store';
import { PatientDetailsSection } from '../../components/PatientDetailsSection';
import { LocationPatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';
import PatientService from '../../api/PatientService';

export type RefillDetailSidebarProps = {
  onPatientDetailsPress?: () => void;
  onCollapse?: () => void;
};

const RefillDetailSidebar: FunctionComponent<
  PropsWithChildren<RefillDetailSidebarProps>
> = ({ onPatientDetailsPress, onCollapse }) => {
  const styles = useStyles();
  const theme = useTheme();
  const { locationId } = useAppStateStore();
  const pharmacySidebarStyles = usePharmacySidebarStyles();
  const { sidebarDetails, setSidebarDetails } = useRefillsStore();
  const [refill, setRefill] = useState<RefillDto | undefined>();
  const [prescription, setPrescription] = useState<
    PrescriptionDto | undefined
  >();

  if (!sidebarDetails) return <>Loading...</>;
  if (!sidebarDetails.patientDetails || !sidebarDetails.prescriptionDetails)
    throw Error('Error Loading Data');

  const { prescriptionDetails, patientDetails } = sidebarDetails;

  useEffect(() => {
    if (!sidebarDetails.prescriptionDetails) {
      return setPrescription(undefined);
    }

    const { prescription_id, patient_id, refill_id } =
      sidebarDetails.prescriptionDetails;

    void prescriptionService
      .findPrescription(locationId, patient_id, prescription_id)
      .then(setPrescription);

    void prescriptionService
      .findRefill(locationId, patient_id, refill_id)
      .then(setRefill);
  }, [sidebarDetails.prescriptionDetails]);

  function StyledPrescriptionStatus() {
    const workflowStatusMessageText = getWorkflowStatusMessageText(
      prescriptionDetails.workflow_status,
    );
    const rxStatusMessageText = getRxStatusMessageText(
      prescriptionDetails.rx_status,
    );

    if (prescriptionDetails.refill_status === RefillStatus.Accepted) {
      return (
        <View style={{ paddingBottom: theme.getSpacing(2) }}>
          <Text style={styles.label}>{workflowStatusMessageText}</Text>
        </View>
      );
    } else {
      return (
        <View style={styles.prescriptionStatus}>
          <View
            style={{
              justifyContent: 'center',
              marginRight: theme.getSpacing(1),
            }}
          >
            <Text style={{ color: theme.palette.error[500] }}>
              {getText('refill-status-not-passed')}
            </Text>
          </View>
          <View style={{ marginRight: theme.getSpacing(1) }}>
            <Text style={styles.label}>
              {' - '}
              {rxStatusMessageText}
            </Text>
          </View>
        </View>
      );
    }
  }

  async function verifyPatient() {
    try {
      if (!patientDetails) return;

      const verifiedPatient: LocationPatientRecordDto =
        await PatientService.updateLocationPatientRecord(
          patientDetails.location_id,
          patientDetails.id,
          {
            is_verified: true,
          },
        );

      setSidebarDetails({
        ...sidebarDetails,
        patientDetails: {
          ...patientDetails,
          is_verified: verifiedPatient.is_verified,
        },
      });
    } catch (error) {
      console.error('Error verifying a patient: ', error);
    }
  }

  function RefillDetailsContainer() {
    const doctorFullName = `${prescription?.doctor_first_name || ''} ${
      prescription?.doctor_last_name || ''
    }`.trim();

    const doctorPhone = prescription?.doctor_phone
      ? formatPhoneNumber(prescription.doctor_phone) ||
        prescription.doctor_phone
      : '';

    return (
      <View>
        <Text style={styles.refillNumber}>{prescriptionDetails.rx_number}</Text>
        <Text style={pharmacySidebarStyles.subtext}>
          {getText('rx-number')}
        </Text>

        <View style={styles.chatButton}>
          <NewChatButton
            patient={patientDetails}
            isDisabledRecipient={true}
            defaultSubject={getText('refill-order')}
            defaultMessage={getText('refills-default-chat-message', {
              fullName: `${patientDetails.first_name} ${patientDetails.last_name}`,
              rxNumber: prescriptionDetails.rx_number,
              drugName: prescriptionDetails.drug_name,
            })}
            verifyPatient={verifyPatient}
            buttonHierarchy="secondary-gray"
            buttonText={getText('chat')}
            buttonSize="large"
          />
        </View>

        <Text style={pharmacySidebarStyles.fieldLabel}>{getText('name')}</Text>
        <Text style={pharmacySidebarStyles.subtext}>
          {prescriptionDetails.drug_name}
        </Text>

        <Text style={pharmacySidebarStyles.fieldLabel}>
          {getText('status')}
        </Text>
        <StyledPrescriptionStatus />

        <Text style={pharmacySidebarStyles.fieldLabel}>
          {getText('submitted')}
        </Text>
        <Text style={pharmacySidebarStyles.subtext}>
          {formatTimeDate(prescriptionDetails.submitted_at)}
        </Text>

        <View style={pharmacySidebarStyles.rowView}>
          <View style={pharmacySidebarStyles.column}>
            <Text style={pharmacySidebarStyles.boldSubtext}>
              {getText('channel')}
            </Text>
            <Text style={pharmacySidebarStyles.subtext}>
              {prescriptionDetails.ingress_method === 'ivr'
                ? prescriptionDetails.ingress_method.toUpperCase()
                : prescriptionDetails.ingress_method}
            </Text>
          </View>
          <View style={pharmacySidebarStyles.column}>
            <Text style={pharmacySidebarStyles.boldSubtext}>
              {getText('delivery-method')}
            </Text>
            <Text
              style={{
                ...pharmacySidebarStyles.subtext,
                textTransform: 'capitalize',
              }}
            >
              {prescriptionDetails.pickup_method_name}
            </Text>
          </View>
        </View>

        <View style={pharmacySidebarStyles.rowView}>
          <View style={pharmacySidebarStyles.column}>
            <Text style={pharmacySidebarStyles.boldSubtext}>
              {getText('prescriber')}
            </Text>
            {!doctorFullName && !doctorPhone ? (
              <Text style={pharmacySidebarStyles.subtext}>
                {getText('prescriber-info-not-available')}
              </Text>
            ) : (
              <>
                <Text style={styles.subtextSmallPadding}>
                  {doctorFullName || getText('prescriber-name-not-available')}
                </Text>
                <Text style={pharmacySidebarStyles.subtext}>
                  {doctorPhone || getText('prescriber-phone-not-available')}
                </Text>
              </>
            )}
          </View>
        </View>
      </View>
    );
  }

  function PatientDetailsContainer() {
    if (!refill) return <>Loading...</>;

    const patientRecordData = useMemo(
      () => ({
        firstName: refill.patient_first_name,
        lastName: refill.patient_last_name,
        birthDate: refill.patient_date_of_birth,
        phone: refill.patient_phone ?? '',
        email: patientDetails.email ?? '',
        allergies: refill.patient_allergies ?? [],
      }),
      [refill],
    );

    return (
      <PatientDetailsSection
        {...patientRecordData}
        onPatientDetailsPress={onPatientDetailsPress}
      />
    );
  }

  return (
    <PharmacySidebar
      title={getText('refill-details')}
      dataExists={!!sidebarDetails}
      onCollapse={onCollapse}
    >
      <View>
        <RefillDetailsContainer />
        <PatientDetailsContainer />
      </View>
    </PharmacySidebar>
  );
};

const useStyles = makeStyles((theme) => ({
  refillNumber: {
    fontSize: 24,
    color: theme.palette.gray[900],
    fontWeight: '600',
    paddingTop: theme.getSpacing(1),
    paddingBottom: theme.getSpacing(0.5),
  },
  patientNameHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  patientName: {
    paddingTop: theme.getSpacing(2),
    paddingBottom: theme.getSpacing(0.5),
    fontSize: 24,
    fontWeight: '600',
  },
  prescriptionStatus: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'center',
    paddingBottom: theme.getSpacing(2),
  },
  label: {
    fontWeight: '400',
    color: theme.palette.gray[700],
    fontFamily: 'Lato_400Regular',
  },
  chatButton: {
    marginBottom: theme.getSpacing(2),
  },
  subtextSmallPadding: {
    paddingBottom: theme.getSpacing(0.5),
    color: theme.palette.gray[700],
  },
}));

export default RefillDetailSidebar;
