import { FunctionComponent } from 'react';
import { getText } from 'assets/localization/localization';
import { Text } from 'assets/components/text';
import { makeStyles, useTheme } from 'assets/theme';
import { ButtonFilter } from './MessagesButtonFilter';
import { CheckboxFilter } from '../../../../components/CheckboxFilter';
import { Button } from 'assets/components/button';
import { MultiFilterValues, BaseFilterValues } from 'assets/types/messageTypes';
import { ListIcon, EmailIcon, UserXIcon } from 'assets/icons';
import { useChatState } from '../../stores/chat-store/chat-store';
import { useConversationsManipulationState } from '../../stores/data-manipulation-store/data-manipulation-store';
import {
  setBaseFilter,
  setMultiFilters,
} from '../../stores/data-manipulation-store/data-manipulation-actions';

const messagesBaseFilters = [
  {
    name: getText('all'),
    value: BaseFilterValues.ALL,
    icon: ListIcon,
  },
  {
    name: getText('unread'),
    value: BaseFilterValues.UNREAD,
    icon: EmailIcon,
  },
  {
    name: getText('unread-by-patient'),
    value: BaseFilterValues.UNREAD_BY_PATIENT,
    icon: UserXIcon,
  },
  // {
  //   name: "Templates",
  //   value: BaseFilterValues.TEMPLATES,
  //   icon: GridIcon,
  // },
];

const messageMultiFilters = [
  {
    name: getText('includes-me'),
    value: MultiFilterValues.INCLUDES_ME,
  },
  {
    name: getText('created-by-me'),
    value: MultiFilterValues.CREATED_BY_ME,
  },
  {
    name: getText('today'),
    value: MultiFilterValues.TODAY,
  },
  {
    name: getText('last-seven-days'),
    value: MultiFilterValues.LAST_SEVEN_DAYS,
  },
];

export const MessagesFilters: FunctionComponent = () => {
  const styles = useStyles();
  const theme = useTheme();
  const count = useChatState((state) => state.count);
  const disabled = useChatState((state) => state.disabled);
  const baseFilter = useConversationsManipulationState(
    (state) => state.baseFilter,
  );
  const multiFilters = useConversationsManipulationState(
    (state) => state.multiFilters,
  );

  const handleBaseFilterClick = (filter: string | number) => {
    if (disabled || baseFilter === filter) return;
    setBaseFilter(filter as BaseFilterValues);
  };

  const handleMultiFiltersClick = (filter: string | number) => {
    if (disabled) return;
    setMultiFilters(filter as MultiFilterValues);
  };

  const clear = () => {
    setBaseFilter(BaseFilterValues.ALL);
    setMultiFilters(MultiFilterValues.NO_FILTERS);
  };

  return (
    <>
      {messagesBaseFilters.map((filter) => (
        <ButtonFilter
          onPress={handleBaseFilterClick}
          active={baseFilter === filter.value}
          disabled={false}
          key={filter.value}
          count={
            filter.value === BaseFilterValues.UNREAD ? count.unread : undefined
          }
          {...filter}
        />
      ))}
      <Text style={styles.filtersTitle}>{getText('filters')}</Text>
      {messageMultiFilters.map((filter) => (
        <CheckboxFilter
          onPress={handleMultiFiltersClick}
          active={multiFilters.includes(filter.value)}
          disabled={false}
          key={filter.value}
          {...filter}
        />
      ))}
      <Button
        hierarchy="secondary"
        size="small"
        logger={{ id: 'new-task-button' }}
        style={{ margin: theme.getSpacing(2) }}
        onPress={clear}
      >
        {getText('clear')}
      </Button>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  filtersTitle: {
    color: theme.palette.gray[500],
    textTransform: 'uppercase',
    fontSize: 12,
    ...theme.fonts.medium,
    paddingHorizontal: theme.getSpacing(3),
    marginBottom: theme.getSpacing(0.5),
    marginTop: theme.getSpacing(3),
  },
}));
