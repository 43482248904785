import React, { FunctionComponent, useCallback, useState } from 'react';
import { View } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Button } from 'assets/components/button';
import { AvailabilityForm } from '../../../schedule/availability-form/AvailabilityForm';
import { IconButton } from 'assets/components/icon-button';
import { ChevronLeftIcon, TrashIcon } from 'assets/icons';
import { ScheduleDrawerNavigationProp } from '../../../layout/ScheduleDrawer';
import { Text } from 'assets/components/text';
import {
  deleteSchedule,
  submitAvailabilityForm,
} from '../../../schedule/availability-form/availability-form-actions';
import { useAvailabilityFormState } from '../../../schedule/availability-form/availability-form-store';
import { resetAvailabilitiesList } from '../../../schedule/availabilities-list/availabilities-list-actions';
import { makeStyles } from '../../../../../../packages/assets/theme';
import PharmacyConfirmationModal from '../../../components/PharmacyConfirmationModal';
import { getText } from 'assets/localization/localization';
import { Tooltip } from 'assets/components/tooltip/components/tooltip';
import { PharmacyScreenContainer } from 'assets/layout';
import { PharmacyTooltipWrapper } from '../../../common/PharmacyTooltipWrapper';

export const Availability: FunctionComponent<AvailabilityProps> = (props) => {
  const navigation = useNavigation<ScheduleDrawerNavigationProp>();
  const { status, schedule } = useAvailabilityFormState();
  const route = useRoute<any>();
  const availabilityId = route.params?.availabilityId;
  const edit = !!availabilityId;
  const styles = useStyles();
  const [showModal, setShowModal] = useState(false);

  const handleDeleteClick = async () => {
    setShowModal(false);
    await deleteSchedule(availabilityId);
    resetAvailabilitiesList();
    navigation.navigate('availabilities');
  };

  const getDeleteButton = useCallback(() => {
    const button = (
      <Button
        hierarchy="tertiary-gray"
        style={{
          borderColor: 'transparent',
        }}
        mode="outlined"
        size="small"
        logger={{ id: 'delete-availability-button' }}
        icon={TrashIcon}
        loading={status === 'loading'}
        disabled={status === 'loading' || schedule?.in_use}
        onPress={() => setShowModal(true)}
      >
        {getText('delete')}
      </Button>
    );

    if (schedule?.in_use) {
      return (
        <>
          <PharmacyTooltipWrapper tooltipId="delete-availability-in-use-tooltip">
            {button}
          </PharmacyTooltipWrapper>
          <Tooltip
            id="delete-availability-in-use-tooltip"
            text={getText('delete-availability-in-use-tooltip')}
          />
        </>
      );
    } else {
      return button;
    }
  }, [schedule]);

  return (
    <PharmacyScreenContainer
      screenHeader={
        <View style={styles.header}>
          <IconButton
            size={25}
            logger={{ id: 'go-back-availability-form-button' }}
            icon={ChevronLeftIcon}
            onPress={() => navigation.navigate('availabilities')}
            style={styles.backButton}
          />
          <Text style={styles.title} selectable>
            {getText('availability')}
          </Text>
          {edit && getDeleteButton()}
          <Button
            hierarchy="primary"
            size="small"
            logger={{ id: 'save-availability-button' }}
            onPress={() => submitAvailabilityForm()}
            loading={status === 'loading'}
            disabled={status === 'loading'}
          >
            {getText('save')}
          </Button>
        </View>
      }
      disableScrollView={false}
      style={styles.container}
    >
      <PharmacyConfirmationModal
        show={showModal}
        onAccepted={() => handleDeleteClick()}
        onDismiss={() => setShowModal(false)}
        message={getText('confirm-delete-availability-implicit')}
      />
      <AvailabilityForm />
    </PharmacyScreenContainer>
  );
};

export interface AvailabilityProps {}

const useStyles = makeStyles((theme) => ({
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.getSpacing(4),
    marginHorizontal: theme.getSpacing(3),
    gap: theme.getSpacing(2),
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.gray[300],
    paddingBottom: theme.getSpacing(3),
  },
  title: {
    fontSize: 25,
    flexGrow: 1,
  },
  backButton: {
    margin: 0,
    marginLeft: -10,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.getSpacing(4),
    paddingBottom: theme.getSpacing(4),
  },
}));
