import { RootStackNavigationProp } from '../../navigation/RootNavigation';
import { useUpdatePasswordState } from './update-password-store';
import RegisterService from '../../api/UpdatePasswordService';
import { ApiError } from 'assets/core/api';

export const updatePassword = async (
  values: UpdatePasswordForm,
  navigation: RootStackNavigationProp,
): Promise<void> => {
  useUpdatePasswordState.setState({ error: undefined, status: 'loading' });
  try {
    const userId = await RegisterService.updatePassword(values);
    useUpdatePasswordState.setState({ status: 'success' });
    navigation.navigate('login');
  } catch (e) {
    // TODO: [ERROR_HANDLING] please review this
    e = ApiError(e as any);
    useUpdatePasswordState.setState({
      error: {
        message: e as string,
      },
      status: 'error',
    });
  }
};

export interface UpdatePasswordForm {
  email: string;
  password: string;
  passwordConfirm: string;
}
