import {
  AutoMessageConfigDto,
  UpdateAutoMessageConfigDto,
} from '@digitalpharmacist/unified-communications-service-client-axios';
import UnifiedCommsService from '../../../../api/UnifiedCommsService';
import { useAutoMessageState } from './auto-messages-store';
import { handleError } from '../../../../common/errors/commonErrorHandler';

export const getAutoMessage = async (locationId: string) => {
  useAutoMessageState.setState({
    status: 'loading',
  });

  const response = await UnifiedCommsService.autoMessageConfigGet(locationId);
  const automationsData: AutoMessageConfigDto[] = response;
  const automations: Record<string, AutoMessageConfigDto> = {};
  automationsData.forEach((automation: AutoMessageConfigDto) => {
    automations[automation.message_config_type] = automation;
  });

  useAutoMessageState.setState({
    status: 'success',
    automations: automations,
  });
};

export const updateAutoMessageConfig = async (
  locationId: string,
  updateAutoMessageConfigDto: UpdateAutoMessageConfigDto,
) => {
  useAutoMessageState.setState({
    status: 'loading',
  });

  try {
    const data: AutoMessageConfigDto =
      await UnifiedCommsService.autoMessageConfigCreateOrUpdateAutoMessageConfig(
        locationId,
        updateAutoMessageConfigDto,
      );

    const updatedAutomation: AutoMessageConfigDto = data;
    useAutoMessageState.setState({
      status: 'success',
      automations: {
        ...useAutoMessageState.getState().automations,
        [updatedAutomation.message_config_type]: updatedAutomation,
      },
    });
  } catch (error) {
    const e = new Error('Auto message config was not updated correctly');
    throw handleError({
      userMessage: e.message,
      showToast: true,
      error: error,
    });
  }
};
