import create from 'zustand';
import { ApiError, AsyncStatus } from '../../../store/types';
import { ModifyDirectMessageSettingsDto } from '@digitalpharmacist/unified-communications-service-client-axios';

export const useMessageSettingsState = create<MessageSettingsState>(() => ({
  messageSettingsConfig: {
    is_auto_response_after_hours_enabled: false,
    auto_response_after_hours_message: null,
    is_patient_start_chat_available: true,
    marketing_phone: undefined,
    non_marketing_phone: undefined,
  },
  showMessageSettingsAutoFormModal: false,
  showMessageSettingsMarketingPhoneModal: false,
  showMessageSettingsNonMarketingPhoneModal: false,
  hasAccess: false,
  status: 'idle',
}));

interface MessageSettingsState {
  messageSettingsConfig: ModifyDirectMessageSettingsDto;
  showMessageSettingsAutoFormModal: boolean;
  showMessageSettingsMarketingPhoneModal: boolean;
  showMessageSettingsNonMarketingPhoneModal: boolean;
  hasAccess: boolean;
  status: AsyncStatus;
  error?: ApiError;
}
