import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Modal } from 'assets/components/modal';
import { Button } from 'assets/components/button';
import { makeStyles, useTheme } from 'assets/theme';
import { View, useWindowDimensions } from 'react-native';
import { Form } from 'assets/layout';
import { useForm } from 'assets/form';
import { RadioButtonGroupField } from 'assets/components/radio-button-group';
import { TextField } from 'assets/components/text-field';
import { Text } from 'assets/components/text';
import { CheckboxField } from 'assets/components/checkbox';
import { ScrollView } from 'react-native';
import {
  CreateTaskDto,
  TaskPriority,
  TaskStatus,
  TaskTypeDto,
  TaskVisibility,
} from '@digitalpharmacist/tasks-service-client-axios';
import { DateTimePickerField } from '../../components/DateTimePickerField';
import { useTaskModalState } from './task-modal-store';
import {
  assigneeChanged,
  createNewTask,
  deleteTask,
  getModalFormOptions,
  updateShowModalState,
  updateTask,
} from './task-modal-actions';
import { LoadingOverlay } from '../../components/LoadingOverlay';
import {
  WeeklyInterval,
  ReoccurrenceField,
  OccurrenceForm,
  MonthlyInterval,
} from './ReoccurrenceField';
import {
  RecurrenceTypes,
  getRecurrenceRuleString,
  parseRecurrenceRuleString,
} from '../../common/recurrence-rules-utils';
import PharmacyConfirmationModal from '../../components/PharmacyConfirmationModal';
import { AdvancedDropDownField } from '../../components/advanced-dropdown';
import { useUserState } from '../../store/user-store';
import { useAppStateStore } from '../../store/app-store';
import { zIndexAuto } from '../../common/theme';
import { useTaskSettingsState } from '../../screens/settings/tasks/task-settings-store';
import {
  UserOptionTemplate,
  UserSingleValueTemplate,
  AssigneePlaceholderTemplate,
  TypeOptionTemplate,
  TypeSingleValueTemplate,
  TypePlaceholderTemplate,
} from '../../components/advanced-dropdown/templates/advanced-drop-down-field.templates';
import { getTomorrowDate } from './task-modal.utils';
import { Tooltip } from 'assets/components/tooltip/components/tooltip';
import { ampli } from '../../src/ampli';
import { getText } from 'assets/localization/localization';
import { Icon } from 'assets/components/icon';
import { ClipboardIcon } from 'assets/icons';
import TaskMetadata from '../task-metadata/TaskMetadata';
import { useWatch } from 'react-hook-form';
import { getToday } from '../../common/datetime-utils';
import { DetailedUser } from '@digitalpharmacist/role-service-client-axios';

interface TaskForm extends CreateTaskDto {
  weekly_occurrence: OccurrenceForm;
  monthly_occurrence: OccurrenceForm;
  assignee: DetailedUser | undefined | null;
  type: TaskTypeDto | null;
}

export const defaultWeeklyOccurrence = {
  sunday: false,
  monday: false,
  tuesday: false,
  wednesday: false,
  thursday: false,
  friday: false,
  saturday: false,
  endDate: null,
  frequency: WeeklyInterval.EveryWeek,
  showOnGrid: false,
};

export const defaultMonthlyOccurrence = {
  day: undefined,
  endDate: null,
  frequency: MonthlyInterval.EveryMonth,
  showOnGrid: false,
};

const initialFormValue = {
  status: TaskStatus.Unresolved,
  summary: '',
  description: '',
  recurrence_rule: 'oneTime',
  visibility: TaskVisibility.Location,
  weekly_occurrence: defaultWeeklyOccurrence,
  monthly_occurrence: defaultMonthlyOccurrence,
  due_date: undefined,
  priority: TaskPriority.Medium,
  flagged: false,
  notes: '',
};

export const TaskModal: FunctionComponent<
  PropsWithChildren<TaskModalProps>
> = () => {
  const theme = useTheme();
  const styles = useStyles();
  const openModal = () => {
    methods.reset();
    updateShowModalState(true);
  };

  const { height: viewportHeight } = useWindowDimensions();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const { editingTask, editTaskData, showModal, status, assigneeOptions } =
    useTaskModalState();

  const { taskTypes } = useTaskSettingsState();

  const locationId = useAppStateStore.getState().locationId;
  const { availableRoles } = useAppStateStore();
  const isAutomatedTask =
    !!editTaskData?.automation_id && !!editTaskData.automationType;

  const methods = useForm<TaskForm>({
    defaultValues: initialFormValue,
  });

  const [personalVisibilityWasSelected, setPersonalVisibilityWasSelected] =
    useState(methods.getValues('visibility') === TaskVisibility.Personal);

  const { data: userData } = useUserState();

  const isUserCreatedTask =
    !editingTask ||
    userData?.id === editTaskData?.created_by_user_id ||
    isAutomatedTask;

  const handleSubmit = async () => {
    let formValue = methods.getValues();
    const calculatedRecurrenceRule = getRecurrenceRuleString(
      formValue.recurrence_rule as RecurrenceTypes,
      formValue.recurrence_rule == 'monthly'
        ? formValue.monthly_occurrence
        : formValue.weekly_occurrence,
    );

    const assigned_user_id =
      formValue.visibility === TaskVisibility.Personal
        ? userData?.id
        : formValue.assignee?.userId;

    formValue = {
      ...formValue,
      assigned_user_id,
      recurrence_rule: calculatedRecurrenceRule,
      task_type_id: formValue.type?.id || null,
      visibility_entity_id: editTaskData?.visibility_entity_id ?? locationId,
      created_by_user_id: editTaskData?.created_by_user_id ?? userData?.id,
    };

    if (editingTask && editTaskData) {
      updateTask(editTaskData.id, formValue);

      if (
        editTaskData.assigned_user_id !== formValue.assigned_user_id &&
        formValue.assigned_user_id
      ) {
        assigneeChanged(formValue.assigned_user_id);
      }
    } else {
      createNewTask(formValue);

      if (formValue.assigned_user_id) {
        ampli.taskCreated({
          taskAssignee: formValue.assigned_user_id,
          taskStatus: formValue.status,
          taskType: formValue.type ? formValue.type.title : '',
          taskVisibility: formValue.visibility,
          taskCreatedTime: new Date().toISOString(),
        });
      }
    }

    closeModal();
  };

  const closeModal = () => {
    updateShowModalState(false);
    methods.reset();
  };

  useEffect(() => {
    if (
      (!assigneeOptions.length || !taskTypes.length) &&
      availableRoles.length
    ) {
      getModalFormOptions();
    }
  }, [availableRoles]);

  useEffect(() => {
    if (editTaskData) {
      const taskForm: TaskForm = {
        ...editTaskData,
        recurrence_rule: 'oneTime',
        weekly_occurrence: defaultWeeklyOccurrence,
        monthly_occurrence: defaultMonthlyOccurrence,
        assignee: assigneeOptions.find(
          (assignee) => assignee.userId === editTaskData.assigned_user_id,
        ),
      };

      methods.reset({
        ...taskForm,
        ...parseRecurrenceRuleString(editTaskData.recurrence_rule),
      });
    } else {
      methods.reset(initialFormValue);
    }
  }, [editTaskData]);

  const watchRecurrenceRule = methods.watch('recurrence_rule');
  const watchVisibility = methods.watch('visibility');

  useEffect(() => {
    const isPersonal = watchVisibility === TaskVisibility.Personal;
    const assignee = methods.getValues('assignee');

    // If the visibility is set to personal we automatically fill in the assignee input with the current user data
    if (isPersonal) {
      setPersonalVisibilityWasSelected(true);
      if (userData?.id && assignee?.userId !== userData?.id) {
        const assigneeData = userData as DetailedUser;
        assigneeData.userId = userData.id;
        methods.setValue('assignee', assigneeData);
      }
    } else {
      if (personalVisibilityWasSelected) {
        methods.setValue('assignee', null);
      }
    }
  }, [watchVisibility]);

  function NewTaskButton() {
    return (
      <Button
        hierarchy="primary"
        size="large"
        logger={{ id: 'new-task-button' }}
        style={{
          margin: theme.getSpacing(2),
        }}
        onPress={() => openModal()}
      >
        {getText('new-task')}
      </Button>
    );
  }

  const patientFullName = useMemo(() => {
    if (!editTaskData?.location_patient_record_id) return '';

    return [
      editTaskData.locationPatientRecordData?.first_name,
      editTaskData.locationPatientRecordData?.last_name,
    ].join(' ');
  }, [editTaskData?.location_patient_record_id]);

  const assigneeFieldVal = useWatch({
    name: 'assignee',
    control: methods.control,
  });
  const dueDateFieldVal = useWatch({
    name: 'due_date',
    control: methods.control,
  });
  const summaryFieldVal = useWatch({
    name: 'summary',
    control: methods.control,
  });

  useEffect(() => {
    void methods.trigger('assignee');
  }, [assigneeFieldVal]);

  useEffect(() => {
    void methods.trigger('due_date');
  }, [dueDateFieldVal]);

  useEffect(() => {
    void methods.trigger('summary');
  }, [summaryFieldVal]);

  return (
    <>
      <NewTaskButton />
      {editTaskData && (
        <PharmacyConfirmationModal
          show={showDeleteConfirmation}
          onAccepted={() => {
            setShowDeleteConfirmation(false);
            deleteTask(editTaskData.id);
          }}
          onDismiss={() => setShowDeleteConfirmation(false)}
          message={`${getText('are-you-sure-to-delete-task')}: "${
            editTaskData.summary
          }"?`}
        />
      )}

      <Modal
        title={`${editingTask ? getText('edit') : getText('new')} ${getText(
          'task',
        )}`}
        size="lg"
        deleteButtonProps={
          editingTask
            ? {
                hierarchy: 'tertiary-gray',
                onPress: () => setShowDeleteConfirmation(true),
                logger: { id: 'task-delete-button-modal' },
                pinLeft: true,
              }
            : undefined
        }
        cancelButtonProps={{
          onPress: () => closeModal(),
          logger: { id: 'task-cancel-button-modal' },
        }}
        okButtonProps={{
          onPress: methods.handleSubmit(handleSubmit),
          hierarchy: 'primary',
          disabled: status == 'loading' || !methods.formState.isValid,
          logger: { id: 'task-ok-button-modal' },
        }}
        show={showModal}
      >
        <ScrollView style={{ maxHeight: viewportHeight * 0.75 }}>
          {status == 'loading' && <LoadingOverlay></LoadingOverlay>}
          <Form methods={methods}>
            <Form.Row>
              <Form.Column>
                <RadioButtonGroupField
                  name="status"
                  disabled={status === 'loading'}
                  values={[
                    {
                      text: getText('unresolved'),
                      value: TaskStatus.Unresolved,
                    },
                    { text: getText('resolved'), value: TaskStatus.Resolved },
                    {
                      text: getText('in_progress'),
                      value: TaskStatus.InProgress,
                    },
                    { text: getText('on_hold'), value: TaskStatus.OnHold },
                  ]}
                  isHorizontal
                />
              </Form.Column>
            </Form.Row>
            <Form.Row>
              <Form.Column>
                <View style={styles.halfWidth}>
                  <AdvancedDropDownField
                    name="type"
                    label={getText('task-type')}
                    isMulti={false}
                    options={taskTypes}
                    isClearable={true}
                    menuPortalTarget={document.body}
                    getOptionValue={(optionValue) => optionValue.id}
                    getOptionLabel={(optionValue) => optionValue.title}
                    optionTemplate={TypeOptionTemplate}
                    singleValueTemplate={TypeSingleValueTemplate}
                    placeholderTemplate={TypePlaceholderTemplate}
                  />
                </View>
              </Form.Column>
            </Form.Row>
            <Form.Row style={styles.zIndex}>
              <Form.Column>
                <View
                  style={styles.halfWidth}
                  nativeID="task-modal-assignee-disabled-tooltip"
                >
                  <AdvancedDropDownField
                    name="assignee"
                    label={getText('assignee')}
                    isDisabled={watchVisibility === TaskVisibility.Personal}
                    isMulti={false}
                    options={assigneeOptions}
                    menuPortalTarget={document.body}
                    getOptionValue={(optionValue) => optionValue.userId}
                    getOptionLabel={(optionValue) =>
                      `${optionValue.firstName} ${optionValue.lastName}`
                    }
                    isSearchable
                    isClearable={true}
                    optionTemplate={UserOptionTemplate}
                    singleValueTemplate={UserSingleValueTemplate}
                    placeholderTemplate={AssigneePlaceholderTemplate}
                    rules={{
                      required: 'Assignee is required',
                    }}
                    key={`assignee__${JSON.stringify(
                      methods.getValues('assignee'),
                    )}`}
                  />
                </View>
                <Tooltip
                  place="bottom"
                  text={getText('personal-task-assign')}
                  anchorId={
                    watchVisibility === TaskVisibility.Personal
                      ? `task-modal-assignee-disabled-tooltip`
                      : undefined
                  }
                />
              </Form.Column>
            </Form.Row>
            <Form.Row>
              <Form.Column>
                <Text style={styles.label}>{getText('summary')} *</Text>
                <TextField
                  placeholder={getText('summary')}
                  name="summary"
                  rules={{
                    required: getText('summary-is-required'),
                  }}
                  disabled={status === 'loading' || isAutomatedTask}
                  maxLength={120}
                  hintMessage={getText('max-char-allowed-message', {
                    maxChar: 120,
                  })}
                />
              </Form.Column>
            </Form.Row>
            <Form.Row>
              <Form.Column>
                <Text style={styles.label}>{getText('description')}</Text>
                {isAutomatedTask && editTaskData.metadata ? (
                  <TaskMetadata
                    metadata={editTaskData.metadata}
                    patientFullName={patientFullName}
                    patientDateOfBirth={
                      editTaskData.locationPatientRecordData?.date_of_birth
                    }
                  />
                ) : (
                  <TextField
                    placeholder={getText('description')}
                    name="description"
                    type="text"
                    multiline={true}
                    numberOfLines={3}
                  />
                )}
              </Form.Column>
            </Form.Row>
            {editTaskData?.location_patient_record_id && (
              <Form.Row>
                <Form.Column>
                  <View style={styles.patientInfoContainer}>
                    <View style={styles.patientInfoIconContainer}>
                      <Icon
                        icon={ClipboardIcon}
                        color={theme.palette.gray[500]}
                        size={20}
                      />
                    </View>
                    <View>
                      <Text style={styles.patientInfoLabel}>
                        {getText('patient')}
                      </Text>
                      <Text style={styles.patientInfoText}>
                        {patientFullName}
                      </Text>
                    </View>
                  </View>
                </Form.Column>
              </Form.Row>
            )}
            <View style={styles.divider}></View>
            {!isAutomatedTask && (
              <Form.Row>
                <Form.Column>
                  <RadioButtonGroupField
                    label={getText('recurrence')}
                    name="recurrence_rule"
                    values={[
                      {
                        text: getText('one-time'),
                        value: RecurrenceTypes.OneTime,
                      },
                      {
                        text: getText('weekly'),
                        value: RecurrenceTypes.Weekly,
                      },
                      {
                        text: getText('monthly'),
                        value: RecurrenceTypes.Monthly,
                      },
                    ]}
                    isHorizontal
                  ></RadioButtonGroupField>
                </Form.Column>
              </Form.Row>
            )}
            {watchRecurrenceRule == RecurrenceTypes.OneTime && (
              <Form.Row style={{ zIndex: zIndexAuto }}>
                <Form.Column style={{ zIndex: zIndexAuto }}>
                  <DateTimePickerField
                    label={getText('due-date')}
                    timeLabel={getText('time')}
                    name="due_date"
                    placeholder={getText('due-date')}
                    rules={{ required: getText('due-date-required') }}
                    minDate={new Date().toISOString()}
                    disabled={isAutomatedTask}
                  />
                </Form.Column>
              </Form.Row>
            )}
            {watchRecurrenceRule == RecurrenceTypes.Weekly && (
              <Form.Row style={{ zIndex: zIndexAuto }}>
                <Form.Column style={{ zIndex: zIndexAuto }}>
                  <ReoccurrenceField
                    name="weekly_occurrence"
                    type="weekly"
                    dueDateControlName="due_date"
                  ></ReoccurrenceField>
                </Form.Column>
              </Form.Row>
            )}
            {watchRecurrenceRule == RecurrenceTypes.Monthly && (
              <Form.Row style={{ zIndex: zIndexAuto }}>
                <Form.Column style={{ zIndex: zIndexAuto }}>
                  <ReoccurrenceField
                    name="monthly_occurrence"
                    type="monthly"
                    dueDateControlName="due_date"
                  ></ReoccurrenceField>
                </Form.Column>
              </Form.Row>
            )}
            {!isAutomatedTask && (
              <Form.Row>
                <Button
                  hierarchy="tertiary-gray"
                  mode="outlined"
                  style={styles.todayButton}
                  size="small"
                  logger={{ id: 'task-edit--date--today' }}
                  onPress={() => methods.setValue('due_date', getToday())}
                >
                  {getText('today')}
                </Button>
                <>
                  {watchRecurrenceRule == RecurrenceTypes.OneTime && (
                    <Button
                      hierarchy="tertiary-gray"
                      mode="outlined"
                      style={{ borderColor: 'transparent' }}
                      size="small"
                      logger={{ id: 'task-edit--date--tomorrow' }}
                      onPress={() =>
                        methods.setValue(
                          'due_date',
                          getTomorrowDate().toISOString(),
                        )
                      }
                    >
                      {getText('tomorrow')}
                    </Button>
                  )}
                </>
              </Form.Row>
            )}
            <View style={styles.divider}></View>
            <Form.Row>
              <Form.Column>
                <RadioButtonGroupField
                  name="priority"
                  label={getText('task-priority')}
                  values={[
                    { text: getText('high'), value: TaskPriority.High },
                    { text: getText('medium'), value: TaskPriority.Medium },
                    { text: getText('low'), value: TaskPriority.Low },
                  ]}
                  isHorizontal
                ></RadioButtonGroupField>
              </Form.Column>
            </Form.Row>
            <Form.Row>
              <CheckboxField name="flagged" label={getText('mark-flagged')} />
            </Form.Row>
            <View style={styles.divider}></View>
            {isUserCreatedTask && (
              <>
                <Form.Row>
                  <Form.Column>
                    <RadioButtonGroupField
                      name="visibility"
                      label={getText('task-visibility')}
                      disabled={isAutomatedTask}
                      values={[
                        {
                          text: getText('team'),
                          value: TaskVisibility.Location,
                          description: getText('visibility-team-policy'),
                        },
                        {
                          text: getText('personal'),
                          value: TaskVisibility.Personal,
                          description: getText('visibility-personal-policy'),
                        },
                      ]}
                    ></RadioButtonGroupField>
                  </Form.Column>
                </Form.Row>
                {!editTaskData && <View style={styles.divider}></View>}
              </>
            )}
            {!editTaskData && (
              <Form.Row>
                <Form.Column>
                  <Text style={styles.label}>{getText('notes')}</Text>
                  <TextField
                    placeholder={getText('notes')}
                    name="notes"
                    type="text"
                    multiline={true}
                    numberOfLines={3}
                  />
                </Form.Column>
              </Form.Row>
            )}
          </Form>
        </ScrollView>
      </Modal>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    overflow: 'scroll',
  },
  divider: {
    height: 1,
    width: '100%',
    marginVertical: theme.getSpacing(4),
    backgroundColor: theme.palette.gray[200],
  },
  label: {
    color: theme.palette.gray[700],
    fontSize: 14,
    marginBottom: theme.getSpacing(1),
  },
  halfWidth: {
    width: '50%',
  },
  todayButton: {
    borderColor: 'transparent',
    marginLeft: theme.getSpacing(1),
  },
  zIndex: {
    zIndex: 1,
  },
  patientInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.getSpacing(2),
    borderWidth: 1,
    borderColor: theme.palette.gray[300],
    borderRadius: theme.roundness,
    alignItems: 'center',
  },
  patientInfoIconContainer: {
    marginRight: theme.getSpacing(2),
  },
  patientInfoLabel: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.gray[600],
  },
  patientInfoText: {
    ...theme.lumistryFonts.text.medium.regular,
  },
}));

interface TaskModalProps {}

export default TaskModal;
