import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Svg, Path, Rect } from 'react-native-svg';
import { IconProps } from './types';

export const FormIcon: FunctionComponent<PropsWithChildren<IconProps>> = ({
  color,
  size,
  testID,
  strokeWidth,
}) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
      testID={testID}
    >
      <Path
        d="M12.5 3H15a2 2 0 0 1 2 2v13a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h2.5"
        stroke="#000"
        strokeOpacity={0.7}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M5.621 2.515A2 2 0 0 1 7.561 1h2.877a2 2 0 0 1 1.94 1.515L13 5H5l.621-2.485Z"
        stroke="#000"
        strokeOpacity={0.7}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M6 15h6m-6-4h6-6Z"
        stroke="#000"
        strokeOpacity={0.7}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </Svg>
  );
};
