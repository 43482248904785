import React, { FunctionComponent, useRef, useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { makeStyles, useTheme } from 'assets/theme';
import { OBContentHeader } from '../content-header/OBContentHeader';
import OBDivider from '../divider/OBDivider';
import { DataGrid } from 'assets/components/data-grid';
import { getText } from 'assets/localization/localization';
import { useOnboardingStore } from '../../../../store/onboarding-store';
import { PencilIcon } from 'assets/icons';
import {
  getEquipmentErrors,
  save,
} from '../../../../actions/onboarding-actions';
import {
  ICellRendererParams,
  GridReadyEvent,
  GridApi,
} from '@ag-grid-community/core';
import { useWizard } from 'react-use-wizard';
import { IconButton } from 'assets/components/icon-button';
import { Modal } from 'assets/components/modal';
import { Alert } from 'assets/components/alert';
import { Text } from 'assets/components/text';
import '../styles/multi-location-grid-style.css';
import { ObLocationResponseDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { OBAddressForm } from '../forms/OBAddressForm';
import { OBFormHandler } from '../forms';
import { OBHoursForm } from '../forms/OBHoursForm';
import { OBEmployeesForm } from '../forms/OBEmployeesForm';
import { OBEquipmentForm } from '../forms/OBEquipmentForm';
import { OBMapPinIcon } from '../icons/OBMapPinIcon';
import { OBAccountForm } from '../forms/OBAccountForm';

interface MultiLocationCellRendererProps extends ICellRendererParams {
  onPress?: (field: string, location: ObLocationResponseDto) => void;
}

const MODAL_CONFIG_MAP: Record<string, any> = {
  general: {
    title: getText('location-info'),
    desc: getText('please-confirm-or-fill-in'),
    size: 'sm',
  },
  addresses: {
    title: getText('ob-address-title'),
    desc: getText('ob-address-description'),
    size: 'sm',
  },
  hours: {
    title: getText('store-hours'),
    desc: getText('store-general-hours'),
    size: 'lg',
  },
  'all-hours': {
    title: getText('store-hours-all'),
    isAllHours: true,
    desc: getText('ob-all-hours-desc'),
    size: 'lg',
  },
  employees: {
    title: getText('ob-employees-title'),
    desc: getText('ob-employees-description'),
    size: 'lg',
  },
  equipment: {
    title: getText('equipment'),
    desc: getText('pms-requires-opie-desc'),
    size: 'sm',
  },
  no_field: {
    title: 'No field',
    desc: 'No field selected',
    size: 'sm',
  },
};

const MultiLocationCellRenderer: FunctionComponent<
  MultiLocationCellRendererProps
> = ({ onPress, data, colDef, node, value, api }) => {
  const styles = useStyles();
  const theme = useTheme();
  if (!colDef?.field) {
    console.warn('No field provided on [MultiLocationCellRenderer].');
    return;
  }

  return (
    <View
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        height: 42,
      }}
    >
      <IconButton
        icon={PencilIcon}
        size={16}
        logger={{ id: 'tbd' }}
        color={theme.palette.primary[600]}
        onPress={() => onPress?.(colDef.field!, data)}
      />
    </View>
  );
};

const MultiLocationOPIECellRenderer: FunctionComponent<
  MultiLocationCellRendererProps
> = (props) => {
  const styles = useStyles();
  const theme = useTheme();
  if (!props.colDef?.field) {
    console.warn('No field provided on [MultiLocationOPIECellRenderer].');
    return;
  }
  const { location_onsite_required } = props.data as ObLocationResponseDto;

  return location_onsite_required ? (
    <MultiLocationCellRenderer {...props} />
  ) : (
    <View
      style={{ alignItems: 'center', justifyContent: 'center', height: 40 }}
    >
      <Text>N/A</Text>
    </View>
  );
};

export const OBMultiLocationTable: FunctionComponent = () => {
  const styles = useStyles();
  const theme = useTheme();
  const { handleStep } = useWizard();
  const [gridApi, setGridApi] = useState<GridApi>();
  const [hasErrors, setHasErrors] = useState<boolean | undefined>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [currentField, setCurrentField] = useState<string>('no_field');
  const [currentLocation, setCurrentLocation] =
    useState<ObLocationResponseDto>();
  const obMultiLocationModalRef = useRef<OBFormHandler>(null);

  const locations = useOnboardingStore((x) => x.data.locations);
  const corpName = useOnboardingStore((x) => x.data.corp_name);
  const branName = useOnboardingStore((x) => x.data.brand_name);

  const onGridReady = (event: GridReadyEvent) => {
    setGridApi(event.api);
  };

  handleStep(async () => {
    const error = getEquipmentErrors();
    if (error) throw new Error(error);
  });

  const handleOnMultiLocationCellClicked = (
    field: string,
    location: ObLocationResponseDto,
  ) => {
    setCurrentLocation(location);
    setCurrentField(field);
    setShowModal(true);
  };

  const modalSwitchRenderer = (
    field: string,
    location: ObLocationResponseDto,
  ) => {
    switch (field) {
      case 'general': {
        return (
          <OBAccountForm
            location={location}
            corpName={corpName}
            branName={branName}
            isSingleLocation={false}
            ref={obMultiLocationModalRef}
          />
        );
      }
      case 'addresses': {
        return (
          <OBAddressForm location={location} ref={obMultiLocationModalRef} />
        );
      }
      case 'hours': {
        return (
          <View style={{ alignItems: 'center' }}>
            <OBHoursForm location={location} ref={obMultiLocationModalRef} />
          </View>
        );
      }
      case 'all-hours': {
        return (
          <View style={{ alignItems: 'center' }}>
            <OBHoursForm
              location={location}
              ref={obMultiLocationModalRef}
              applyToAllLocations={true}
            />
          </View>
        );
      }
      case 'employees': {
        return (
          <OBEmployeesForm
            location={location}
            ref={obMultiLocationModalRef}
            width={750}
          />
        );
      }
      case 'equipment': {
        return (
          <OBEquipmentForm location={location} ref={obMultiLocationModalRef} />
        );
      }
    }
  };

  const handleOnCloseSuccess = async () => {
    await save();
    setShowModal(false);
  };

  const handleOnSubmit = () => {
    void obMultiLocationModalRef.current
      ?.handleSubmit()
      .then(handleOnCloseSuccess);
  };

  return (
    <>
      <View style={styles.root}>
        <OBContentHeader
          icon={OBMapPinIcon}
          title={getText('ob-multi-location-title')}
          description={getText('ob-multi-location-subtitle')}
        />

        {hasErrors && (
          <Alert
            title={getText('ob-employees-generic-error')}
            intent="error"
            style={{
              marginTop: theme.getSpacing(4),
            }}
          />
        )}

        <View style={styles.tableContainer}>
          <DataGrid
            gridOptions={{
              columnDefs: [
                {
                  field: 'location_name',
                  headerName: getText('location'),
                  width: 270,
                },
                {
                  field: 'general',
                  headerName: getText('general'),
                  cellRenderer: MultiLocationCellRenderer,
                  cellRendererParams: {
                    onPress: handleOnMultiLocationCellClicked,
                  },
                  width: 150,
                },
                {
                  field: 'addresses',
                  headerName: getText('addresses'),
                  cellRenderer: MultiLocationCellRenderer,
                  cellRendererParams: {
                    onPress: handleOnMultiLocationCellClicked,
                  },
                  width: 150,
                },
                {
                  field: 'hours',
                  headerName: getText('hours'),
                  cellRenderer: MultiLocationCellRenderer,
                  cellRendererParams: {
                    onPress: handleOnMultiLocationCellClicked,
                  },
                  headerComponent: () => (
                    <View style={{ flex: 1, alignItems: 'center' }}>
                      <TouchableOpacity
                        onPress={() =>
                          handleOnMultiLocationCellClicked(
                            'all-hours',
                            locations[0],
                          )
                        }
                      >
                        <Text
                          style={{
                            ...theme.lumistryFonts.label.large.semiBold,
                            color: theme.palette.primary[600],
                          }}
                        >
                          Hours
                        </Text>
                      </TouchableOpacity>
                    </View>
                  ),
                  width: 150,
                },
                {
                  field: 'employees',
                  headerName: getText('employees'),
                  cellRenderer: MultiLocationCellRenderer,
                  cellRendererParams: {
                    onPress: handleOnMultiLocationCellClicked,
                  },
                  width: 150,
                },
                {
                  field: 'equipment',
                  headerName: getText('equipment'),
                  cellRenderer: MultiLocationOPIECellRenderer,
                  cellRendererParams: {
                    onPress: handleOnMultiLocationCellClicked,
                  },
                  width: 150,
                },
              ],
              rowStyle: { border: 0 },
              rowData: locations,
              // headerHeight: 0,
              getRowId: (params) => params.data.id,
              enableCellTextSelection: true,
              suppressMovableColumns: true,
              suppressContextMenu: true,
              suppressRowHoverHighlight: true,
              defaultColDef: { sortable: false, menuTabs: [] },
              pagination: true,
              paginationPageSize: 5,
              rowModelType: 'clientSide',
              onGridReady: onGridReady,
            }}
            className="ob-multi-location"
          />
        </View>

        <OBDivider />
      </View>
      <Modal
        size={MODAL_CONFIG_MAP[currentField].size}
        show={showModal}
        showDismissButton
        isScrollable
        handleDismissModal={() => setShowModal(false)}
        buttons={[
          {
            hierarchy: 'primary',
            onPress: handleOnSubmit,
            logger: { id: 'lumistry-roles-close-button' },
            text: getText('ok'),
          },
          {
            hierarchy: 'tertiary-gray',
            onPress: () => setShowModal(false),
            logger: { id: 'lumistry-roles-close-button' },
            text: getText('cancel'),
          },
        ]}
      >
        <OBContentHeader
          title={MODAL_CONFIG_MAP[currentField].title}
          subtitle={
            MODAL_CONFIG_MAP[currentField].isAllHours
              ? branName
              : `${branName} - ${currentLocation?.location_name}`
          }
          description={MODAL_CONFIG_MAP[currentField].desc}
          style={{ width: '100%' }}
        />
        {modalSwitchRenderer(currentField, currentLocation!)}
      </Modal>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    alignContent: 'center',
  },
  tableContainer: {
    flex: 1,
    width: 1020,
    minHeight: 440,
    marginVertical: theme.getSpacing(2),
  },
  pressableContainer: {
    flexDirection: 'row',
    gap: theme.getSpacing(0.5),
    alignItems: 'center',
  },
  addButtonText: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.primary[600],
  },
  inputCellRenderer: {
    borderWidth: 0,
    height: 58,
    width: 175,
  },
  selectCellRendererControl: { border: 0, borderRadius: 0 },
  subtitle: {
    ...theme.lumistryFonts.text.large.regular,
    color: theme.palette.primary[600],
    textAlign: 'center',
  },
  modalList: {
    paddingTop: theme.getSpacing(2),
  },
  itemContainer: {
    flexDirection: 'row',
    gap: theme.getSpacing(0.5),
    color: theme.palette.gray[700],
  },
  itemTitle: {
    ...theme.lumistryFonts.text.small.bold,
  },
  itemText: {
    ...theme.lumistryFonts.text.small.regular,
  },
}));
