import React, { FunctionComponent } from 'react';
import PharmacyOBWizard, {
  PharmacyOBWizardProps,
} from '../../../components/pharmacy-ob-wizard/PharmacyOBWizard';
import { OBMultiLocationTable } from './multi-location/OBMultiLocationTable';
import { OBMultiLocationSteps } from '../utils';
import { OBMultiLocationAccount } from './multi-location/OBMultiLocationAccount';
import { OBMultiLocationReview } from './multi-location/OBMultiLocationReview';

export const OBMultiLocationWizard: FunctionComponent<
  Omit<PharmacyOBWizardProps, 'steps'>
> = ({ onFinish, onStepChange, startIndex }) => {
  return (
    <PharmacyOBWizard
      steps={OBMultiLocationSteps}
      onFinish={onFinish}
      startIndex={startIndex}
      onStepChange={onStepChange}
    >
      <OBMultiLocationAccount />
      <OBMultiLocationTable />
      <OBMultiLocationReview />
    </PharmacyOBWizard>
  );
};
