import { AutomationDto } from '@digitalpharmacist/tasks-service-client-axios';
import { create } from '../../store/store-creator';
import { AsyncStatus } from '../../store/types';

export const useAutomationsState = create<AutomationsState>(() => ({
  status: 'idle',
  automations: {},
}));

interface AutomationsState {
  status: AsyncStatus;
  automations: Record<string, AutomationDto>;
}
