import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path } from 'react-native-svg';
import { IconProps } from './types';

export const ArrowUpRightIcon: FunctionComponent<
  PropsWithChildren<IconProps>
> = ({ color, size, testID, strokeWidth }) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      stroke="currentColor"
      color={color}
      testID={testID}
      strokeWidth={strokeWidth}
    >
      <Path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.67}
        d="m5.833 14.167 8.334-8.334m0 0H5.833m8.334 0v8.334"
      />
    </Svg>
  );
};
