import AsyncStorage from '@react-native-async-storage/async-storage';
import { useUserState } from '../../store/user-store';
import { useCreatePasswordState } from './create-password-store';
import RegisterService from '../../api/RegisterService';
import { ApiError } from 'assets/core/api';
import RoleService from '../../api/RoleService';
import { loadUserInfoMap, persistUserInfo } from '../../actions/user-actions';
import { StorageKeys } from '../../../enums/storage-keys';
import { setAmplitudeUserProperties } from '../../actions/app-actions';
import { ampli } from '../../src/ampli';
import Constants from 'expo-constants';

export const createPassword = async (
  values: CreatePasswordForm,
): Promise<void> => {
  useCreatePasswordState.setState({ error: undefined, status: 'loading' });
  try {
    const { accessToken, refreshToken, userInfo } =
      await RegisterService.createPassword(values);
    const roles = await RoleService.userRoleGetRolesByUserId(userInfo.id);

    // this is the same logic as login
    // consider to refactor
    if (accessToken && refreshToken && userInfo) {
      // we should get all the information needed here
      await persistUserInfo(userInfo);

      await AsyncStorage.setItem(StorageKeys.AccessToken, accessToken);
      await AsyncStorage.setItem(StorageKeys.RefreshToken, refreshToken);

      const pharmacyUsersMap = await loadUserInfoMap();

      // this part is about to be reviewed here and on login
      // TODO: ardibandilli
      // the same code exist on login but at the same time it seems that we are storing id with a map not directly to the storage
      // letting this comment here since dont want to break anything ATM
      const userId = await AsyncStorage.getItem(StorageKeys.UserId);

      if (userId) {
        void setAmplitudeUserProperties(
          userId,
          pharmacyUsersMap[userId].selectedLocation?.pharmacy_name || '',
          pharmacyUsersMap[userId].selectedLocation?.pharmacy_id || '',
          pharmacyUsersMap[userId].selectedLocation?.location_id || '',
        );
      }
    }

    ampli.userLoggedIn({
      loginMethod: 'create-password',
      loginStatus: 'registered',
      loginTime: new Date().toISOString(),
      productVersion: Constants.expoConfig?.version!,
    });

    useUserState.setState({ data: { ...userInfo, roles: roles } });
    useCreatePasswordState.setState({ status: 'success' });
  } catch (e) {
    // TODO: [ERROR_HANDLING] please review this
    e = ApiError(e as any);
    useCreatePasswordState.setState({
      error: {
        message: e as string,
      },
      status: 'error',
    });
  }
};

export interface CreatePasswordForm {
  password: string;
  passwordConfirm: string;
  temporary_user_registration_id: string;
  privacyPolicy: boolean;
  termsAndConditions: boolean;
}
