import { makeStyles } from 'assets/theme';
import { View } from 'react-native';

interface BrandingColorSquareProps {
  color: string;
}
export const BrandingColorSquare = ({ color }: BrandingColorSquareProps) => {
  const styles = useStyles();
  return <View style={[{ backgroundColor: color }, styles.square]}></View>;
};

const useStyles = makeStyles((theme) => ({
  square: {
    width: 24,
    height: 24,
    borderColor: theme.palette.gray[600],
    borderWidth: 1,
  },
}));
