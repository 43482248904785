import React, { FunctionComponent } from 'react';
import { View } from 'react-native';

import { makeStyles } from 'assets/theme';
import { PastAppointmentsList } from '../../../schedule/appointments-past-list/AppointmentsPastList';
import { PharmacyScreenContainer } from 'assets/layout';

export const PastAppointments: FunctionComponent<PastAppointmentsProps> = (
  props,
) => {
  const styles = useStyles();

  return (
    <PharmacyScreenContainer disableScrollView>
      <View style={{ flex: 1 }}>
        <PastAppointmentsList />
      </View>
    </PharmacyScreenContainer>
  );
};

interface PastAppointmentsProps {}

const useStyles = makeStyles((theme) => ({
  cellContainer: {
    marginTop: theme.getSpacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },
}));
