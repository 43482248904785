import { RRule, RRuleSet, rrulestr } from 'rrule';
import {
  defaultMonthlyOccurrence,
  defaultWeeklyOccurrence,
} from '../tasks/task-modal/TaskModal';
import {
  MonthlyInterval,
  WeeklyInterval,
  OccurrenceForm,
} from '../tasks/task-modal/ReoccurrenceField';

export enum RecurrenceTypes {
  OneTime = 'oneTime',
  Weekly = 'weekly',
  Monthly = 'monthly',
}

const determineWeeklyInterval = (weeklyInterval: WeeklyInterval): number => {
  switch (weeklyInterval) {
    case WeeklyInterval.EveryWeek:
      return 1;
    case WeeklyInterval.EveryTwoWeeks:
      return 2;
    case WeeklyInterval.EveryThreeWeeks:
      return 3;
    case WeeklyInterval.EveryFourWeeks:
      return 4;
    default:
      return 1;
  }
};

const determineMonthlyInterval = (monthlyInterval: MonthlyInterval): number => {
  switch (monthlyInterval) {
    case MonthlyInterval.EveryMonth:
      return 1;
    case MonthlyInterval.EveryTwoMonths:
      return 2;
    case MonthlyInterval.EveryThreeMonths:
      return 3;
    case MonthlyInterval.EveryFourMonths:
      return 4;
    case MonthlyInterval.EveryFiveMonths:
      return 5;
    case MonthlyInterval.EverySixMonths:
      return 6;
    default:
      return 1;
  }
};

const determineWeekdays = (config: OccurrenceForm) => {
  const weekdays = [];
  if (config.sunday) {
    weekdays.push(RRule.SU);
  }
  if (config.monday) {
    weekdays.push(RRule.MO);
  }
  if (config.tuesday) {
    weekdays.push(RRule.TU);
  }
  if (config.wednesday) {
    weekdays.push(RRule.WE);
  }
  if (config.thursday) {
    weekdays.push(RRule.TH);
  }
  if (config.friday) {
    weekdays.push(RRule.FR);
  }
  if (config.saturday) {
    weekdays.push(RRule.SA);
  }

  return weekdays;
};

const determineEndDate = (endDate: string | null) => {
  if (!endDate) {
    return null;
  }

  const end = new Date(endDate);
  end.setUTCHours(23, 59, 59, 999);

  return end;
};

const determineDayOfMonthRecurrence = (day: string) => {
  if (day == 'None') {
    return null;
  }

  return +day;
};

export const parseRecurrenceRuleString = (
  recurrenceRuleString?: string | null,
) => {
  if (recurrenceRuleString) {
    const recurrenceRule = rrulestr(recurrenceRuleString);
    const frequency = recurrenceRule.options.freq;
    const interval = recurrenceRule.options.interval;
    const endDate = recurrenceRule.options.until;
    const monthDay =
      recurrenceRule.options.bymonthday.length == 1
        ? recurrenceRule.options.bymonthday[0]
        : recurrenceRule.options.bynmonthday.length == 1
        ? recurrenceRule.options.bynmonthday[0]
        : null;
    const weekdays = recurrenceRule.options.byweekday;

    if (frequency == RRule.MONTHLY) {
      return {
        recurrence_rule: 'monthly',
        weekly_occurrence: {
          ...defaultWeeklyOccurrence,
          endDate: endDate ? endDate.toISOString() : null,
        },
        monthly_occurrence: {
          endDate: endDate ? endDate.toISOString() : null,
          day: monthDay?.toString(),
          frequency: Object.values(MonthlyInterval)[
            interval - 1
          ] as MonthlyInterval,
        } as OccurrenceForm,
      };
    }

    if (frequency == RRule.WEEKLY) {
      return {
        recurrence_rule: 'weekly',
        monthly_occurrence: {
          ...defaultMonthlyOccurrence,
          endDate: endDate ? endDate.toISOString() : null,
        },
        weekly_occurrence: {
          endDate: endDate ? endDate.toISOString() : null,
          monday: weekdays.includes(0),
          tuesday: weekdays.includes(1),
          wednesday: weekdays.includes(2),
          thursday: weekdays.includes(3),
          friday: weekdays.includes(4),
          saturday: weekdays.includes(5),
          sunday: weekdays.includes(6),
          frequency: Object.values(WeeklyInterval)[
            interval - 1
          ] as WeeklyInterval,
        } as OccurrenceForm,
      };
    }
  }

  return { recurrence_rule: 'oneTime' };
};

export const getRecurrenceRuleString = (
  type: RecurrenceTypes,
  configuration: OccurrenceForm,
): string | null => {
  if (type == RecurrenceTypes.Weekly) {
    const rule = new RRule({
      freq: RRule.WEEKLY,
      wkst: RRule.SU,
      interval: determineWeeklyInterval(
        configuration.frequency as WeeklyInterval,
      ),
      byweekday: determineWeekdays(configuration),
      until: determineEndDate(configuration.endDate),
    });

    return rule.toString();
  }

  if (type == RecurrenceTypes.Monthly) {
    const rule = new RRule({
      freq: RRule.MONTHLY,
      wkst: RRule.SU,
      bymonthday: configuration.day
        ? determineDayOfMonthRecurrence(configuration.day)
        : undefined,
      interval: determineMonthlyInterval(
        configuration.frequency as MonthlyInterval,
      ),
      until: determineEndDate(configuration.endDate),
    });

    return rule.toString();
  }

  return null;
};
