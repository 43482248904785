import { FunctionComponent } from 'react';
import { View } from 'react-native';
import { makeStyles } from 'assets/theme';
import { DpEmptyMessageBulkIcon } from 'assets/icons';
import { Text } from 'assets/components/text';
import { Button } from 'assets/components/button';
import { getText } from 'assets/localization/localization';
import { Icon } from 'assets/components/icon';

export const BulkEmptyState: FunctionComponent<BulkEmptyStateProps> = ({
  onClick,
}) => {
  const styles = useStyles();

  return (
    <View style={styles.container}>
      <Icon size={250} icon={DpEmptyMessageBulkIcon} />
      <Text style={styles.header}>{`${getText(
        'bulk-message-your-patients',
      )}!`}</Text>
      <Text style={styles.secondaryText}>{`${getText(
        'target-your-patients-with-clinical-opportunities-and-more',
      )}!`}</Text>
      <Button
        hierarchy="secondary"
        size="small"
        logger={{ id: 'new-bulk-message-button' }}
        style={styles.button}
        onPress={() => onClick()}
      >
        {getText('new-bulk-message')}
      </Button>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: 350,
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header: {
    fontWeight: 'bold',
    fontSize: 20,
  },
  secondaryText: {
    fontSize: 14,
    lineHeight: 20,
    textAlign: 'center',
    marginBottom: theme.getSpacing(2),
    marginTop: theme.getSpacing(0.5),
  },
  button: {
    width: 230,
  },
}));

interface BulkEmptyStateProps {
  onClick: () => void;
}
