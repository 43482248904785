import { FunctionComponent } from 'react';
import { PatientAgreementRadioButtonOptionProps } from '../../types';
import { makeStyles, useTheme } from 'assets/theme';
import { TouchableOpacity, View } from 'react-native';
import { Text } from 'assets/components/text';
import { CheckIcon } from 'assets/icons';

export const PatientAgreementRadioButtonOption: FunctionComponent<
  PatientAgreementRadioButtonOptionProps
> = ({
  title,
  value,
  description,
  selected,
  onPress = (value) => Promise.resolve(value),
}) => {
  const styles = useStyles();
  const theme = useTheme();

  return (
    <TouchableOpacity
      style={[styles.container, selected && styles.selectedBorder]}
      onPress={() => onPress(value)}
    >
      <View style={styles.titleContainer}>
        {selected && <CheckIcon size={20} color={theme.palette.primary[600]} />}
        <Text style={[styles.text, selected && styles.selectedText]}>
          {title}
        </Text>
      </View>
      {description && (
        <Text
          style={[styles.text, selected && styles.selectedText]}
          numberOfLines={1}
        >
          {description}
        </Text>
      )}
    </TouchableOpacity>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.roundness,
    borderWidth: 1,
    borderColor: theme.palette.gray[300],
    height: 60,
  },
  titleContainer: { flexDirection: 'row', gap: theme.getSpacing(0.5) },
  selectedBorder: {
    borderColor: theme.palette.blue[600],
  },
  text: {
    color: theme.palette.gray[700],
    paddingHorizontal: theme.getSpacing(1),
  },
  selectedText: {
    color: theme.palette.blue[600],
  },
}));
