import { useNavigation } from '@react-navigation/native';
import { DataGrid } from 'assets/components/data-grid/DataGrid';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { getText } from 'assets/localization/localization';
import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { LocationsDrawerNavigationProp } from '../LocationsSettingsDrawer';
import { useLocationSettingsState } from '../location-settings-store';

const LocationsList: FunctionComponent = () => {
  const { allLocations, status } = useLocationSettingsState();

  const navigation = useNavigation<LocationsDrawerNavigationProp>();

  return (
    <>
      {status === 'loading' && (
        <View style={{ marginTop: '25%' }}>
          <LoadingIndicator />
        </View>
      )}
      {status === 'success' && (
        <DataGrid
          gridOptions={{
            suppressCellFocus: true,
            colResizeDefault: 'shift',
            rowData: allLocations.locations,
            animateRows: true,
            onRowClicked: (event) =>
              navigation.navigate('location', {
                locationId: event.data.location_id as string,
              }),
            columnDefs: [
              {
                field: 'location_name',
                headerName: getText('locations-name'),
                sortable: false,
                valueFormatter: ({ value }) => value || '-',
              },
              {
                field: 'location_active',
                headerName: getText('locations-status'),
                sortable: false,
                valueFormatter: ({ value }) =>
                  value ? getText('active') : getText('inactive'),
              },
              {
                field: 'location_ncpdp',
                headerName: getText('locations-NCPDP'),
                sortable: false,
                valueFormatter: ({ value }) => value || '-',
              },
              {
                field: 'location_address1',
                headerName: getText('locations-address'),
                sortable: false,
                valueFormatter: ({ value }) => value || '-',
              },
              {
                valueFormatter: ({ data }) =>
                  [data?.location_city, data?.location_state]
                    .filter(Boolean)
                    .join(', '),
                headerName: getText('city'),
                sortable: false,
              },
            ],
            defaultColDef: { sortable: true, menuTabs: [] },
          }}
        />
      )}
    </>
  );
};

export default LocationsList;
