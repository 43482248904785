import React, { FunctionComponent, useEffect, useState } from 'react';
import { prettyFormat } from '@digitalpharmacist/validation-dp';
import { Text, View } from 'react-native';
import { useStyles as usePharmacySidebarStyles } from './PharmacySidebar';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import { formatDate } from '../common/datetime-utils';
import { Avatar } from 'assets/components/avatar';
import { ResourceItem } from '../api/MedicationService';
import {
  allergiesAreExplicitlyEmpty,
  findAllergiesById,
} from '../screens/refills/refills-actions';
import { Alert } from 'assets/components/alert';

export const PatientDetailsSection: FunctionComponent<
  PatientDetailsSectionProps
> = ({
  firstName,
  lastName,
  birthDate,
  phone,
  email,
  allergies,
  onPatientDetailsPress,
}) => {
  const styles = useStyles();
  const pharmacySidebarStyles = usePharmacySidebarStyles();
  const fullName = [firstName, lastName].join(' ');
  const [allergiesDetails, setAllergies] = useState<ResourceItem[]>();

  useEffect(() => {
    void (async () => {
      if (allergies.length > 0) {
        const loadedAllergies = await findAllergiesById(allergies);
        setAllergies(loadedAllergies);
      } else {
        setAllergies([]);
      }
    })();
  }, []);

  const AllergiesAlert = () => {
    const getAllergiesAlert = ():
      | {
          alertType: 'info' | 'warning';
          alertText: string;
        }
      | undefined => {
      const allergiesShowAlert = allergiesAreExplicitlyEmpty(allergies);

      if (allergiesShowAlert) return undefined;

      const allergiesAlertType = allergies.length === 0 ? 'info' : 'warning';

      const allergiesAlertText = !allergiesDetails?.length
        ? getText('allergies-info-not-available')
        : `${getText('allergies-colon')} ${
            allergiesDetails.map((a) => a.text).join(', ') || ''
          }`;

      return {
        alertType: allergiesAlertType,
        alertText: allergiesAlertText,
      };
    };

    const allergiesAlert = getAllergiesAlert();

    if (!allergiesAlert) return <></>;

    return (
      <View>
        <Alert
          intent={allergiesAlert.alertType}
          title={allergiesAlert.alertText}
        />
      </View>
    );
  };

  return (
    <View>
      <View style={pharmacySidebarStyles.sectionHeader}>
        <Text style={pharmacySidebarStyles.sectionHeaderLabel}>
          {getText('patient-info')}
        </Text>
        <Text
          style={pharmacySidebarStyles.link}
          onPress={() => onPatientDetailsPress && onPatientDetailsPress()}
        >
          {getText('details')}
        </Text>
      </View>

      {!allergiesAreExplicitlyEmpty(allergies) && <AllergiesAlert />}
      <View style={styles.patientNameHeader}>
        <View>
          <Text style={styles.patientName}>{fullName}</Text>
          <Text style={pharmacySidebarStyles.subtext}>
            {formatDate(birthDate)}
          </Text>
        </View>
        <Avatar firstName={firstName} lastName={lastName} size={48} />
      </View>

      <Text style={pharmacySidebarStyles.fieldLabel}>{getText('phone')}</Text>
      <Text style={pharmacySidebarStyles.subtext}>
        {phone && prettyFormat(phone)}
      </Text>

      <Text style={pharmacySidebarStyles.fieldLabel}>
        {getText('email-text')}
      </Text>
      <Text style={pharmacySidebarStyles.subtext}>{email}</Text>
      <Text style={pharmacySidebarStyles.fieldLabel}>
        {getText('allergies')}
      </Text>
      <Text style={pharmacySidebarStyles.subtext}>
        {allergiesDetails?.map((a) => a.text).join(', ')}
      </Text>
    </View>
  );
};

export interface PatientDetailsSectionProps {
  firstName: string;
  lastName: string;
  birthDate: string;
  phone: string;
  email: string;
  allergies: string[];
  onPatientDetailsPress?: () => void;
}

const useStyles = makeStyles((theme) => ({
  patientNameHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  patientName: {
    ...theme.lumistryFonts.text.xLarge.bold,
    paddingTop: theme.getSpacing(2),
    paddingBottom: theme.getSpacing(0.5),
  },
}));
