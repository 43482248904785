import { VaccinationDto } from '@digitalpharmacist/forms-service-client-axios';
import { useVaccinationModalState } from './vaccination-modal-store';

export const updateShowModalState = (showModal: boolean) => {
  useVaccinationModalState.setState({
    showModal: showModal,
  });
};

export const updateVaccinationState = (
  vaccination: VaccinationDto | undefined,
) => {
  useVaccinationModalState.setState({
    vaccination: vaccination,
  });
};

export const resetVaccinationModalState = () => {
  useVaccinationModalState.setState({
    vaccination: undefined,
    showModal: false,
  });
};

export const handleResetVaccinationData = (doReset: boolean) => {
  useVaccinationModalState.setState({
    resetVaccinationData: doReset,
  });
};
