import { Button } from 'assets/components/button';
import { Text } from 'assets/components/text';
import { ClockIcon, UserPlusIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import OBDivider from './divider/OBDivider';
import { OBFileIcon } from './icons/OBFileIcon';
import { OBMapPinIcon } from './icons/OBMapPinIcon';
import { OBRouterIcon } from './icons/OBRouterIcon';
import { OBSetupIcon } from './icons/OBSetupIcon';
import { Icon } from 'assets/components/icon';

export const OBLanding: FunctionComponent<OBLandingProps> = ({
  onNextPress,
}) => {
  const styles = useStyles();
  const theme = useTheme();

  return (
    <View style={styles.root}>
      <View style={styles.imageContainer}>
        <Icon icon={OBSetupIcon} />
      </View>
      <View style={styles.container}>
        <Text style={styles.title}>{getText('lumistry-setup')}</Text>
        <Text style={styles.subtitle}>{getText('welcome-lumistry-setup')}</Text>
      </View>
      <View style={styles.list}>
        <View style={styles.item}>
          <Icon
            size={24}
            color={theme.palette.primary[600]}
            icon={OBFileIcon}
          />
          <View>
            <Text style={styles.listTitle}>{getText('account-info')}</Text>
            <Text style={styles.listSubtitle}>
              {getText('confirm-information-collected')}
            </Text>
          </View>
        </View>
        <View style={styles.item}>
          <Icon
            size={24}
            color={theme.palette.primary[600]}
            strokeWidth={8}
            icon={OBMapPinIcon}
          />
          <View>
            <Text style={styles.listTitle}>{getText('addresses')}</Text>
            <Text style={styles.listSubtitle}>
              {getText('location-billing-account')}
            </Text>
          </View>
        </View>
        <View style={styles.item}>
          <Icon
            size={24}
            color={theme.palette.primary[600]}
            strokeWidth={6}
            icon={OBRouterIcon}
          />
          <View>
            <Text style={styles.listTitle}>
              {getText('on-premise-equipment')}
            </Text>
            <Text style={styles.listSubtitle}>
              {getText('require-additional-hardware')}
            </Text>
          </View>
        </View>
        <View style={styles.item}>
          <Icon
            size={24}
            color={theme.palette.primary[600]}
            icon={UserPlusIcon}
            strokeWidth={2}
          />
          <View>
            <Text style={styles.listTitle}>{getText('employees')}</Text>
            <Text style={styles.listSubtitle}>
              {getText('add-your-employees')}
            </Text>
          </View>
        </View>
        <View style={styles.item}>
          <Icon size={24} color={theme.palette.primary[600]} icon={ClockIcon} />
          <View>
            <Text style={styles.listTitle}>{getText('hours')}</Text>
            <Text style={styles.listSubtitle}>
              {getText('set-hours-timezone-holidays')}
            </Text>
          </View>
        </View>
      </View>
      <OBDivider />
      <View style={styles.buttonContainer}>
        <Button
          hierarchy={'primary'}
          logger={{
            id: 'wizard-landing-next',
          }}
          onPress={onNextPress}
          style={styles.button}
        >
          {getText('next')}
        </Button>
      </View>
    </View>
  );
};

interface OBLandingProps {
  onNextPress: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    alignContent: 'center',
    gap: theme.getSpacing(3),
    paddingVertical: 60,
    maxWidth: 450,
  },
  container: {
    gap: theme.getSpacing(1),
  },
  title: {
    ...theme.lumistryFonts.label.xxLarge.regular,
    fontSize: 32,
    color: theme.palette.gray[900],
    textAlign: 'center',
  },
  subtitle: {
    ...theme.lumistryFonts.text.large.regular,
    color: theme.palette.gray[700],
  },
  imageContainer: {
    alignItems: 'center',
  },
  list: {
    gap: theme.getSpacing(2),
    paddingBottom: theme.getSpacing(2),
    paddingLeft: theme.getSpacing(2),
  },
  item: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: theme.getSpacing(2),
  },
  listTitle: {
    ...theme.lumistryFonts.text.medium.semiBold,
    color: theme.palette.gray[900],
  },
  listSubtitle: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.gray[700],
    width: 350,
  },
  buttonContainer: {
    alignItems: 'center',
  },
  button: {
    width: 230,
  },
}));
