import { Modal } from 'assets/components/modal';
import { Text } from 'assets/components/text';
import { ToggleSwitch } from 'assets/components/toggle-switch';
import { getText } from 'assets/localization/localization';
import { logError } from 'assets/logging/logger';
import { makeStyles } from 'assets/theme';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { View } from 'react-native';
import { setOpenedModal } from '../../../actions/checklist-actions';
import UnifiedCommsService from '../../../api/UnifiedCommsService';
import { useAppStateStore } from '../../../store/app-store';
import { useChecklistStore } from '../../../store/checklist-store';
import { ChecklistModalProps } from '../types';

export const MessageSettingsModal: FunctionComponent<ChecklistModalProps> = ({
  id,
}) => {
  const styles = useStyles();
  const { currentOpenedModalId } = useChecklistStore();
  const { locationId, pharmacyId } = useAppStateStore();
  const [loading, setLoading] = useState<boolean>(false);
  const [startNewChat, setStartNewChat] = useState<boolean>(true);
  const [afterHours, setAfterHours] = useState<boolean>(true);
  const [duringHours, setDuringHours] = useState<boolean>(true);

  useEffect(() => {
    void (async () => {
      const data = await UnifiedCommsService.getDirectMessageSettings(
        pharmacyId,
        locationId,
      );
      if (data) {
        setStartNewChat(data.is_patient_start_chat_available);
        setAfterHours(data.is_auto_response_after_hours_enabled);
      }
    })();
  }, [pharmacyId, locationId]);

  const handleSubmit = async () => {
    const dto = {
      is_auto_response_after_hours_enabled: afterHours,
      is_patient_start_chat_available: startNewChat,
    };

    setLoading(true);
    UnifiedCommsService.modifyDirectMessageSettings(pharmacyId, locationId, dto)
      .then(() => setOpenedModal('checklist-setup'))
      .catch((error: Error) => logError(error))
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      title={getText('message-settings-title')}
      show={currentOpenedModalId === id}
      showDismissButton
      handleDismissModal={() => setOpenedModal('checklist-setup')}
      size="sm"
      buttons={[
        {
          text: getText('ok'),
          onPress: handleSubmit,
          hierarchy: 'primary',
          logger: { id: 'ok-checklist-app-settings-modal' },
          loading,
        },
        {
          text: getText('cancel'),
          onPress: () => setOpenedModal('checklist-setup'),
          hierarchy: 'tertiary-gray',
          logger: { id: 'cancel-checklist-app-settings-modal' },
        },
      ]}
      isScrollable
    >
      <View style={styles.container}>
        <View>
          <View style={styles.row}>
            <Text style={styles.text}>
              {getText('patient-can-start-new-chat')}
            </Text>
            <ToggleSwitch
              logger={{ id: `is_patient_start_chat_available` }}
              value={startNewChat}
              onPress={() => {
                setStartNewChat((prev) => !prev);
              }}
            />
          </View>
          <Text style={styles.desc}>{getText('patient-can-always-reply')}</Text>
        </View>
        <View>
          <View style={styles.row}>
            <Text style={styles.text}>
              {getText('auto-response-after-hours')}
            </Text>
            <ToggleSwitch
              logger={{ id: `auto-response-after-hours` }}
              value={afterHours}
              onPress={() => {
                setAfterHours((prev) => !prev);
              }}
            />
          </View>
          <Text style={styles.desc}>{getText('enable-this-setting')}</Text>
        </View>
        {/* <View>
          <View style={styles.row}>
            <Text style={styles.text}>
              {getText('auto-response-during-hours')}
            </Text>
            <ToggleSwitch
              logger={{ id: `auto-response-during-hours` }}
              value={duringHours}
              onPress={() => {
                setDuringHours((prev) => !prev);
              }}
            />
          </View>
          <Text style={styles.desc}>
            {getText('enable-setting-during-hours')}
          </Text>
        </View> */}
      </View>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.getSpacing(3),
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  text: {
    ...theme.lumistryFonts.text.medium.regular,
    color: theme.palette.gray[700],
  },
  desc: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.gray[700],
  },
}));
