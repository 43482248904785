import React, { FunctionComponent, PropsWithChildren } from 'react';
import { IconProps } from './types';
import { Svg, Path } from 'react-native-svg';

export const FourSquaresIcon: FunctionComponent<
  PropsWithChildren<IconProps>
> = ({ color, size, testID }) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      color={color}
      testID={testID}
    >
      <Path
        d="M8.33333 2.5H2.5V8.33333H8.33333V2.5Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M17.5 2.5H11.6667V8.33333H17.5V2.5Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M17.5 11.6667H11.6667V17.5H17.5V11.6667Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M8.33333 11.6667H2.5V17.5H8.33333V11.6667Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
