import {
  BookingStatus,
  UpdateBookingDto,
  FormDto,
} from '@digitalpharmacist/appointment-service-client-axios';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Pressable, TouchableOpacity, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useProSidebar } from 'react-pro-sidebar';
import { Icon } from '../../../../../packages/assets/components/icon';
import { IconButton } from '../../../../../packages/assets/components/icon-button';
import { Text } from '../../../../../packages/assets/components/text';
import {
  DocumentIcon,
  FileOffIcon,
  MessageSquareIcon,
  PencilIcon,
  TrashWithStripesIcon,
} from '../../../../../packages/assets/icons';
import { useFocusEffect } from '@react-navigation/native';
import { getText } from '../../../../../packages/assets/localization/localization';
import { makeStyles, useTheme } from '../../../../../packages/assets/theme';
import {
  calculatePatientAge,
  convertDateTimeFromUtcToPharmacyTimezone,
  DEFAULT_DATE_TIME_FORMAT,
  DEFAULT_PHARMACY_TIMEZONE,
  formatDate,
} from '../../common/datetime-utils';
import { ColoredBadge } from '../../components/ColoredBadge';
import PharmacyConfirmationModal from '../../components/PharmacyConfirmationModal';
import { PharmacySidebar } from '../../components/PharmacySidebar';
import {
  cancelBooking,
  updateBookingNotes,
  updateBookingNotesEdit,
} from './appointments-list-actions';
import { useAppointmentsListState } from './appointments-list-store';
import { ScheduleDrawerNavigationProp } from '../../layout/ScheduleDrawer';
import moment from 'moment';
import { CollapsibleText } from '../../components/CollapsibleText';
import SubmissionModal from './SubmissionModal';
import {
  calculateFormIconTooltip,
  minutesDifference,
  STATUS_STYLE_MAPPING,
} from './appointments-list.utils';
import { Tooltip } from 'assets/components/tooltip/components/tooltip';
import { useForm } from 'assets/form';
import { TouchableRipple } from 'react-native-paper';
import { PatientDetailsSection } from '../../components/PatientDetailsSection';
import PrescriptionService from '../../api/PrescriptionService';
import usePatientStore from '../../screens/patients/patient-store';
import { useAppStateStore } from '../../store/app-store';
import PatientDetailSidebar from '../../screens/patients/PatientDetailSidebar';
import {
  getLocationPatientRecordWithFullName,
  getPatientInfoForSidebarSection,
} from '../../screens/patients/patient-util';
import NotesEditModal from '../../components/NotesEditModal/NotesEditModal';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { PharmacyTooltipWrapper } from '../../common/PharmacyTooltipWrapper';
import { uuid } from 'assets/utils/uuid';

const SectionHeader = ({
  label,
  internalUse,
  tooltipId,
  editing,
  onPress,
}: {
  label: string;
  internalUse?: boolean;
  tooltipId?: string;
  editing?: boolean;
  onPress?: () => void;
}) => {
  const styles = useStyles();

  return (
    <View style={[styles.row, styles.sectionHeader]}>
      <Text style={styles.sectionHeaderLabelText}>{label.toUpperCase()}</Text>
      {internalUse && (
        <Text style={styles.sectionHeaderInternalText}>
          {getText('internal-use-only')}
        </Text>
      )}
      {onPress !== undefined && (
        <TouchableOpacity
          style={[styles.sectionHeaderButton]}
          onPress={onPress}
        >
          {tooltipId ? (
            <PharmacyTooltipWrapper tooltipId={tooltipId}>
              <Text style={styles.sectionHeaderButtonText}>
                {editing ? getText('save') : getText('edit')}
              </Text>
            </PharmacyTooltipWrapper>
          ) : (
            <Text style={styles.sectionHeaderButtonText}>
              {editing ? getText('save') : getText('edit')}
            </Text>
          )}
        </TouchableOpacity>
      )}
    </View>
  );
};

const PropertyRow = ({
  label,
  value,
  textColor,
  collapsible,
  onPress,
}: {
  label: string;
  value: string;
  textColor?: string;
  collapsible?: boolean;
  onPress?: () => void;
}) => {
  const theme = useTheme();
  const styles = useStyles();

  return (
    <View style={styles.propertyRow}>
      <Text style={styles.propertyRowLabel}>{label}</Text>
      {onPress !== undefined ? (
        <TouchableOpacity onPress={onPress}>
          <Text style={styles.propertyRowValuePressable}>{value}</Text>
        </TouchableOpacity>
      ) : (
        <>
          {collapsible ? (
            <CollapsibleText
              text={value}
              color={textColor ? textColor : theme.palette.gray[700]}
            />
          ) : (
            <Text
              style={[styles.propertyRowValue, { color: textColor }]}
              selectable
            >
              {value}
            </Text>
          )}
        </>
      )}
    </View>
  );
};

const InfoRow = ({ label, value }: { label: string; value: any }) => {
  const styles = useStyles();
  return (
    <View style={styles.infoRow}>
      <Text style={styles.infoRowLabel}>{label}</Text>
      <Text style={styles.infoRowValue} selectable>
        {value}
      </Text>
    </View>
  );
};

const FormLink = ({
  text,
  status,
  onPress,
  formId,
  isSubmitted,
}: {
  text: string;
  status?: string;
  formId: string;
  isSubmitted: boolean;
  onPress: () => void;
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const leftIcon = status === 'ENABLED' ? DocumentIcon : FileOffIcon;

  const { icon, iconColor, tooltip } = calculateFormIconTooltip(
    isSubmitted,
    status,
  );
  const tooltipId = useMemo(() => uuid(), []);

  return (
    <TouchableOpacity onPress={onPress}>
      <View style={styles.alignBaseline}>
        <View style={styles.formLink} nativeID={tooltipId}>
          <Icon
            icon={leftIcon}
            color={
              isSubmitted ? theme.palette.primary[600] : theme.palette.gray[500]
            }
            size={16}
          />
          <Text
            style={[
              styles.formLinkText,
              !isSubmitted && styles.unavailableForm,
            ]}
          >
            {text}
          </Text>
          {icon !== undefined && (
            <Icon icon={icon} color={iconColor} size={14} />
          )}
        </View>
      </View>
      <Tooltip place="top" text={tooltip} anchorId={tooltipId} />
    </TouchableOpacity>
  );
};

export const AppointmentDetailsSidebar: FunctionComponent<
  AppointmentDetailsSidebarProps
> = ({ onCancel, onCollapse, isReadOnly }) => {
  const {
    appointmentDetails,
    appointmentType,
    patientRecord,
    appointmentDetailsStatus,
  } = useAppointmentsListState();
  const theme = useTheme();
  const styles = useStyles();
  const [showModal, setShowModal] = useState(false);
  const [modalParams, setModalParams] = useState<{
    submissionId: string;
    formId: string;
  }>();

  const [show, setShow] = useState(false);
  const [showEditNoteModal, setShowEditNoteModal] = useState(false);
  const { collapseSidebar, collapsed } = useProSidebar();
  const navigation = useNavigation<ScheduleDrawerNavigationProp>();
  const isPast =
    appointmentDetails &&
    appointmentDetails.status !== 'CANCELLED' &&
    moment(moment(appointmentDetails.endTime)).isBefore(moment());

  const statusLabels = new Map<BookingStatus, string>([
    [BookingStatus.Accepted, getText('not-started')],
    [BookingStatus.Cancelled, getText('canceled')],
    [BookingStatus.Pending, getText('pending')],
    [BookingStatus.Rejected, getText('rejected')],
  ]);

  const statusColors = new Map<BookingStatus, string>([
    [BookingStatus.Accepted, theme.palette.success[500]],
    [BookingStatus.Rejected, theme.palette.warning[500]],
    [BookingStatus.Cancelled, theme.palette.error[500]],
  ]);

  useEffect(() => {
    if (appointmentDetails?.pharmacy_notes) {
      methods.reset({
        notes: appointmentDetails.pharmacy_notes,
      });
    }

    return () => {
      updateBookingNotesEdit(false);
      methods.setValue('notes', '');
    };
  }, [appointmentDetails]);

  const formatPhone = (phoneNumber: string) => {
    const cleanedNumber = phoneNumber.slice(2);
    const match = cleanedNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  };

  const handleCancelBooking = async () => {
    if (appointmentDetails) {
      setShowModal(false);
      collapseSidebar(true);
      await cancelBooking(appointmentDetails.id);
      onCancel(appointmentDetails.id);
    }
  };

  const getBadge = (date: string, status: BookingStatus) => {
    const dateDiff = moment().diff(moment(date), 'days');

    if (isPast) {
      return (
        <ColoredBadge
          {...STATUS_STYLE_MAPPING.PAST}
          textStyle={styles.badgeText}
        />
      );
    }

    if (status === BookingStatus.Cancelled) {
      return (
        <ColoredBadge
          {...STATUS_STYLE_MAPPING.CANCELLED}
          textStyle={styles.badgeText}
        />
      );
    }

    if (dateDiff === 0) {
      return (
        <ColoredBadge
          label={getText('today')}
          color={theme.palette.gray[900]}
          backgroundColor={theme.palette.warning[300]}
          textStyle={styles.badgeText}
        />
      );
    }

    if (dateDiff === -1) {
      return (
        <ColoredBadge
          label={getText('tomorrow')}
          color={theme.palette.gray[900]}
          backgroundColor={theme.palette.gray[100]}
          textStyle={styles.badgeText}
        />
      );
    }
  };

  const getStatusValue = () => {
    if (isPast) {
      return 'Past';
    }

    if (appointmentDetails) {
      return statusLabels.get(appointmentDetails.status) || '';
    }

    return '';
  };

  const handleOpenSubmissionModal = (formId: string) => {
    const submission = appointmentDetails?.submissions.find(
      (submission) => submission.form_id === formId,
    );

    if (!submission) return;

    setModalParams({
      submissionId: submission.submission_id,
      formId: submission.form_id,
    });
    setShow(true);
  };

  const handleCloseSubmissionModal = () => {
    setModalParams(undefined);
    setShow(false);
  };

  useFocusEffect(
    useCallback(() => {
      return () => {
        collapseSidebar(true);
      };
    }, []),
  );

  useEffect(() => {
    if (appointmentDetails) {
      methods.setValue(
        'notes',
        appointmentDetails.pharmacy_notes
          ? appointmentDetails.pharmacy_notes
          : null,
      );
    }
  }, [appointmentDetails]);

  const handleNotesModalSavePress = async () => {
    if (!appointmentDetails) return;
    const notes = methods.getValues('notes');

    const appointmentData: UpdateBookingDto = {
      pharmacy_notes: notes ? notes : '',
    };

    await updateBookingNotes(appointmentData);

    setShowEditNoteModal(false);
  };

  const renderFormDetail = (form: FormDto) => {
    if (!appointmentType || !appointmentDetails) {
      return;
    }
    const isSubmitted = !!appointmentDetails.submissions.find(
      (submission) => submission.form_id === form.form_id,
    );

    if (appointmentType.forms_optional && !isSubmitted) {
      return null;
    }

    return (
      <FormLink
        key={form.form_id}
        status={form.form_status}
        text={form.form_name}
        onPress={() => handleOpenSubmissionModal(form.form_id)}
        formId={form.form_id}
        isSubmitted={
          !!appointmentDetails.submissions.find(
            (submission) => submission.form_id === form.form_id,
          )
        }
      />
    );
  };

  const methods = useForm({
    defaultValues: {
      notes: appointmentDetails?.pharmacy_notes
        ? appointmentDetails.pharmacy_notes
        : null,
    },
  });

  const { locationId } = useAppStateStore();
  const {
    sidebarDetails: patientSidebarDetails,
    setSidebarDetails: setPatientSidebarDetails,
    removeSidebarDetails: removePatientSidebarDetails,
  } = usePatientStore();

  const fillPatientSideBarDetails = useCallback(async () => {
    if (
      !appointmentDetails?.patient_record_id ||
      !appointmentDetails.location_patient_record_data
    )
      return;

    const prescriptions = await PrescriptionService.findPrescriptions(
      locationId,
      appointmentDetails.patient_record_id,
    );

    const locationPatientRecordWithFullName =
      getLocationPatientRecordWithFullName(
        appointmentDetails.location_patient_record_data,
        appointmentDetails.patient_record_id,
        locationId,
      );

    setPatientSidebarDetails({
      patientNumberInList: -1,
      locationPatientRecord: locationPatientRecordWithFullName,
      patientPrescriptions: prescriptions,
    });
  }, [appointmentDetails]);

  const handlePatientDetailsPress = useCallback(() => {
    void fillPatientSideBarDetails();
  }, [fillPatientSideBarDetails]);

  const handlePatientSidebarCollapse = useCallback(() => {
    removePatientSidebarDetails();
    useAppointmentsListState.setState({ appointmentDetails: undefined });
  }, [removePatientSidebarDetails]);

  const patientRecordData = useMemo(() => {
    return getPatientInfoForSidebarSection(
      appointmentDetails?.location_patient_record_data,
      appointmentDetails?.patient_record_id,
    );
  }, [appointmentDetails]);

  useEffect(() => {
    if (!!appointmentDetails && !patientSidebarDetails) {
      collapseSidebar(false);
    }
  }, [appointmentDetails, patientSidebarDetails]);

  if (patientSidebarDetails) {
    return (
      <PatientDetailSidebar
        backLinkComponent={
          <Text style={styles.link} onPress={removePatientSidebarDetails}>
            {getText('appointment-details')}
          </Text>
        }
        onCollapse={handlePatientSidebarCollapse}
        containerStyles={{ height: 'auto' }}
      />
    );
  }

  if (appointmentDetailsStatus === 'loading') {
    return <LoadingIndicator></LoadingIndicator>;
  }

  return (
    <>
      <PharmacyConfirmationModal
        show={showModal}
        message={getText('cancel-appointment-confirmation')}
        onAccepted={handleCancelBooking}
        onDismiss={() => setShowModal(false)}
      />
      <PharmacySidebar
        title={getText('appointment-panel')}
        dataExists={!!appointmentDetails && !!appointmentType}
        defaultCollapsed={true}
        containerStyles={{
          overflowY: 'scroll',
          overflowX: collapsed ? 'hidden' : 'unset',
          height: '100%',
        }}
        rootStyle={{ borderRight: 0 }}
        onCollapse={onCollapse}
      >
        <>
          {appointmentDetails && (
            <View>
              <View style={styles.row}>
                {getBadge(
                  appointmentDetails.endTime,
                  appointmentDetails.status,
                )}
                {!isReadOnly && !isPast && (
                  <View style={styles.buttonsRow}>
                    <PharmacyTooltipWrapper tooltipId="appointment-details-cancel-tooltip">
                      <IconButton
                        onPress={() => setShowModal(true)}
                        icon={TrashWithStripesIcon}
                        logger={{ id: 'cancel-appointment' }}
                        color={theme.palette.gray[500]}
                        size={18}
                      />
                    </PharmacyTooltipWrapper>
                    <PharmacyTooltipWrapper tooltipId="appointment-details-message-tooltip">
                      <IconButton
                        onPress={() => alert('Not implemented yet')}
                        icon={MessageSquareIcon}
                        logger={{ id: 'message-appointment' }}
                        color={theme.palette.gray[500]}
                        size={18}
                      />
                    </PharmacyTooltipWrapper>
                    <Pressable
                      onPress={() =>
                        navigation.navigate('edit-appointment', {
                          appointmentId: appointmentDetails.id,
                        })
                      }
                    >
                      <PharmacyTooltipWrapper tooltipId="appointment-details-edit-tooltip">
                        <View style={styles.row}>
                          <TouchableRipple
                            style={styles.editTouchableContainer}
                            onPress={() =>
                              navigation.navigate('edit-appointment', {
                                appointmentId: appointmentDetails.id,
                              })
                            }
                          >
                            <View style={styles.editTaskIconContainer}>
                              <PencilIcon
                                size={18}
                                color={theme.palette.gray[500]}
                              />
                              <Text style={styles.editTaskText}>
                                {getText('edit')}
                              </Text>
                            </View>
                          </TouchableRipple>
                        </View>
                      </PharmacyTooltipWrapper>
                    </Pressable>
                  </View>
                )}
              </View>
              <View style={styles.titleContainer}>
                <Text style={styles.title}>{appointmentDetails.title}</Text>
              </View>
              <PropertyRow
                label={getText('time')}
                value={convertDateTimeFromUtcToPharmacyTimezone(
                  appointmentDetails.startTime,
                  DEFAULT_PHARMACY_TIMEZONE,
                  DEFAULT_DATE_TIME_FORMAT,
                )}
              />
              <PropertyRow
                label={getText('duration')}
                value={`${minutesDifference(
                  appointmentDetails.endTime,
                  appointmentDetails.startTime,
                )} ${getText('minutes')}`}
              />
              {/* TODO: Hard-coded for now, as it's not implemented yet on the b/e */}
              <PropertyRow
                label={getText('venue')}
                value={getText('venue-in-person')}
              />
              <PropertyRow
                label={getText('patient')}
                value={`${appointmentDetails.patient_record_first_name} ${
                  appointmentDetails.patient_record_last_name
                } (${getText('age')} ${calculatePatientAge(
                  appointmentDetails.patient_record_date_of_birth,
                )})`}
                onPress={() => alert('Not implemented yet')}
              />
              <PropertyRow
                label={getText('status')}
                value={getStatusValue()}
                textColor={
                  isPast
                    ? theme.palette.gray[700]
                    : statusColors.get(appointmentDetails.status)
                }
              />
              {appointmentDetails.description && (
                <PropertyRow
                  label={getText('service-description')}
                  value={appointmentDetails.description}
                  textColor={theme.palette.gray[500]}
                  collapsible={true}
                />
              )}
              <SectionHeader label={getText('additional-info')} />
              <InfoRow
                label={getText('date-of-birth')}
                value={`${formatDate(
                  appointmentDetails.patient_record_date_of_birth,
                )}, ${getText('age')} ${calculatePatientAge(
                  appointmentDetails.patient_record_date_of_birth,
                )}`}
              />
              {patientRecord?.cell_phone && (
                <InfoRow
                  label={getText('phone')}
                  value={formatPhone(patientRecord.cell_phone)}
                />
              )}
              {patientRecord?.email && (
                <InfoRow label={getText('email')} value={patientRecord.email} />
              )}
              <SectionHeader label={getText('forms')} />
              {(appointmentType?.forms_optional === false &&
                appointmentType.forms.length > 0) ||
              (appointmentType?.forms_optional &&
                appointmentType.forms.length > 0 &&
                appointmentDetails.submissions.length > 0) ? (
                appointmentType.forms.map(renderFormDetail)
              ) : (
                <Text style={styles.disabledValue}>
                  {getText('no-forms-required')}
                </Text>
              )}

              <SectionHeader
                label={getText('notes')}
                internalUse={true}
                tooltipId="appointment-details-edit-tooltip"
                onPress={() => setShowEditNoteModal(true)}
              />
              <View style={styles.notesContent}>
                <Text style={styles.notes} selectable>
                  {appointmentDetails.pharmacy_notes
                    ? appointmentDetails.pharmacy_notes
                    : getText('none')}
                </Text>
              </View>
              <Tooltip
                id="appointment-details-edit-tooltip"
                text={getText('edit-reschedule-this-appointment')}
              />
              <Tooltip
                id="appointment-details-cancel-tooltip"
                text={getText('cancel-this-appointment')}
              />
              <Tooltip
                id="appointment-details-message-tooltip"
                text={getText('message-this-patient')}
              />
              {patientRecordData ? (
                <PatientDetailsSection
                  {...patientRecordData}
                  onPatientDetailsPress={handlePatientDetailsPress}
                />
              ) : null}
              {appointmentDetails.id && (
                <NotesEditModal
                  show={showEditNoteModal}
                  onDismiss={() => setShowEditNoteModal(false)}
                  onSave={handleNotesModalSavePress}
                  form={methods}
                  notes={appointmentDetails.pharmacy_notes || ''}
                />
              )}
            </View>
          )}
        </>
      </PharmacySidebar>

      {modalParams ? (
        <SubmissionModal
          handleClose={handleCloseSubmissionModal}
          show={show}
          submissionId={modalParams.submissionId}
          formId={modalParams.formId}
        />
      ) : null}
    </>
  );
};

export interface AppointmentDetailsSidebarProps {
  onCancel: (bookingId: string) => void;
  onCollapse?: () => void;
  isReadOnly?: boolean;
}

const useStyles = makeStyles((theme) => ({
  row: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  badgeText: {
    fontSize: 12,
    paddingLeft: theme.getSpacing(1),
    paddingRight: theme.getSpacing(1),
  },
  buttonsRow: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: theme.getSpacing(1),
    paddingTop: theme.getSpacing(0.5),
  },
  editText: {
    color: theme.palette.gray[500],
    fontSize: 14,
  },
  titleContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.getSpacing(3),
  },
  title: {
    fontSize: 24,
    color: theme.palette.gray[900],
    fontWeight: '600',
  },
  propertyRow: {
    marginTop: theme.getSpacing(2),
  },
  propertyRowLabel: {
    ...theme.fonts.medium,
    fontSize: 16,
    color: theme.palette.gray[900],
    marginBottom: theme.getSpacing(0.5),
  },
  propertyRowValue: {
    color: theme.palette.gray[700],
  },
  propertyRowValuePressable: {
    color: theme.palette.primary[600],
  },
  sectionHeader: {
    marginTop: theme.getSpacing(4),
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.gray[300],
    borderBottomStyle: 'solid',
    paddingBottom: theme.getSpacing(1),
    marginBottom: theme.getSpacing(2),
    flexDirection: 'row',
  },
  sectionHeaderLabelText: {
    ...theme.lumistryFonts.uppercase,
    color: theme.palette.gray[900],
  },
  sectionHeaderInternalText: {
    color: theme.palette.gray[500],
    ...theme.lumistryFonts.label.xSmall.regular,
    marginLeft: 'auto',
  },
  sectionHeaderButton: {
    marginLeft: theme.getSpacing(1),
  },
  sectionHeaderButtonText: {
    ...theme.lumistryFonts.label.xSmall.regular,
    color: theme.palette.primary[600],
    paddingRight: theme.getSpacing(1),
  },
  infoRow: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    paddingBottom: theme.getSpacing(1),
  },
  infoRowValue: {
    fontWeight: '400',
    fontSize: 14,
    color: theme.palette.gray[700],
    wordBreak: 'break-all',
  },
  infoRowLabel: {
    ...theme.fonts.medium,
    fontSize: 14,
    color: theme.palette.gray[900],
    lineHeight: 20,
    width: 120,
    minWidth: 120,
  },
  disabledValue: {
    fontWeight: '400',
    fontSize: 14,
    color: theme.palette.gray[500],
    wordBreak: 'break-all',
  },
  formLink: {
    flexDirection: 'row',
    marginBottom: theme.getSpacing(1),
    alignItems: 'flex-end',
  },
  formLinkText: {
    color: theme.palette.primary[600],
    marginLeft: theme.getSpacing(0.5),
    marginRight: theme.getSpacing(2),
  },
  notesContent: {
    backgroundColor: theme.palette.gray[50],
    borderRadius: theme.roundness,
    padding: theme.getSpacing(1),
  },
  notes: {
    ...theme.lumistryFonts.text.small.regular,
    backgroundColor: theme.palette.gray[50],
    color: theme.palette.gray[400],
  },
  alignBaseline: {
    alignItems: 'baseline',
  },
  unavailableForm: {
    color: theme.palette.gray[500],
  },
  editTouchableContainer: {
    margin: theme.getSpacing(1),
  },
  editTaskIconContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  editTaskText: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.gray[500],
    marginLeft: theme.getSpacing(0.5),
  },
  link: {
    color: theme.palette.primary[500],
    textDecorationLine: 'none',
  },
}));
