import { View } from 'react-native';
import { PlaceholderProps } from 'react-select';
import { makeStyles } from 'assets/theme';
import { Text } from 'assets/components/text';
import { useMessagingStyles } from '../styles';

export function PlaceholderTemplate<T>(props: TPlaceholderTemplate<T>) {
  const styles = useStyles();
  const messagingStyles = useMessagingStyles();

  return (
    <View style={messagingStyles.dropdownOptionContainer}>
      <Text style={styles.dropdownPlaceholderText}>{props.text}</Text>
    </View>
  );
}

type TPlaceholderTemplate<T> = PlaceholderProps<T> & { text: string };

const useStyles = makeStyles((theme) => ({
  dropdownPlaceholderTitle: {
    ...theme.fonts.regular,
    fontSize: 16,
    lineHeight: 18,
    marginRight: 'auto',
  },
  placeholderTemplateText: {
    ...theme.fonts.regular,
    color: theme.palette.gray[600],
    fontSize: 16,
  },
  dropdownPlaceholderText: {
    ...theme.fonts.regular,
    fontSize: 16,
    lineHeight: 18,
    marginRight: 'auto',
    color: theme.palette.gray[600],
  },
}));
