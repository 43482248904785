import { LinkIcon, PaperClipIcon, SmileIcon } from 'assets/icons';
import { makeStyles, useTheme } from 'assets/theme';
import React, { useEffect, useMemo, useState } from 'react';
import {
  UseFormReturn,
  FieldValues,
  Path,
  PathValue,
  UnpackNestedValue,
  useWatch,
} from 'react-hook-form';
import * as DocumentPicker from 'expo-document-picker';
import { View } from 'react-native';
import { useOutsideClick } from '../hooks/useOutsideClick';
import ReactQuill from 'react-quill';
import { getText } from 'assets/localization/localization';
import { Attachments } from './Attachments';
import EmojiModal from 'react-native-emoji-modal';
import { MessageFields } from '../common/types/MessageFields';
import { ErrorStatus } from '../stores/error-store/error-store';
import { setError } from '../stores/error-store/error-actions';
import {
  EMOJI_CHARS_LENGTH,
  ERROR_MODAL_MAX_FILES_COUNT_TEN,
  ERROR_MODAL_SELECT_FILES,
  BIG_MESSAGE_LIMIT,
  SMS_MESSAGE_LIMIT,
} from '../data';
import { Tooltip } from 'assets/components/tooltip/components/tooltip';
import { DocumentPickerAsset } from 'expo-document-picker';
import { Icon } from 'assets/components/icon';
import { stripRichTextEditorElements } from 'assets/utils/messageUtils';

export const NewMessageBody = <T extends FieldValues>({
  methods,
  setErrorFields,
  errorFields,
  handleQuillRef,
  toolbarId,
  content,
  placeholder,
  toolbarOptions,
  height,
  disabled = false,
}: NewMessageBodyProps<T>) => {
  const theme = useTheme();
  const styles = useStyles();
  let quillRef: ReactQuill | null;
  const [showEmojis, setShowEmojis] = useState<boolean>(false);

  const watchedContent = useWatch({
    control: methods.control,
    name: content as Path<T>,
  });

  const attachments: DocumentPickerAsset[] = useWatch({
    control: methods.control,
    name: 'attachments' as Path<T>,
  });

  const onSmileClick = () => {
    setShowEmojis(!showEmojis);
  };

  const outSideClickRef = useOutsideClick(() => {
    setShowEmojis(false);
  });

  const onSelectAttachments = async () => {
    try {
      const docsResult: DocumentPicker.DocumentPickerResult =
        await DocumentPicker.getDocumentAsync({
          multiple: true,
        });

      if (docsResult.canceled || !docsResult.output) {
        setError(
          ERROR_MODAL_SELECT_FILES,
          ErrorStatus.ERROR,
          getText('selecting-files-wrong'),
        );
        return;
      }

      const files = docsResult.assets ?? [];
      const totalAttachments: DocumentPickerAsset[] = [
        ...attachments,
        ...files,
      ];
      const uniqueNames: string[] = [];
      const uniqueAttachments = totalAttachments.filter((attachment) => {
        const isDuplicate = uniqueNames.includes(attachment.name);

        if (!isDuplicate) {
          uniqueNames.push(attachment.name);
          return true;
        }

        return false;
      });

      if (uniqueAttachments.length > 10) {
        methods.setValue(
          'attachments' as Path<T>,
          attachments as UnpackNestedValue<PathValue<T, Path<T>>>,
        );
        setError(
          ERROR_MODAL_MAX_FILES_COUNT_TEN,
          ErrorStatus.ERROR,
          getText('maxim-count-attachments', {
            count: 10,
          }),
        );
        return;
      }

      methods.setValue(
        'attachments' as Path<T>,
        uniqueAttachments as UnpackNestedValue<PathValue<T, Path<T>>>,
      );
    } catch (error) {
      //TODO: review console.error
      console.error('Documents picking error: ', error);
    }
  };

  const onRemoveFile = (name: string) => {
    const filteredAttachments = attachments.filter(
      (attachment: DocumentPickerAsset) => attachment.name !== name,
    );
    methods.setValue(
      'attachments' as Path<T>,
      filteredAttachments as UnpackNestedValue<PathValue<T, Path<T>>>,
    );
  };

  const onEmojiSelect = (emoji: any) => {
    const strippedContent = stripRichTextEditorElements(watchedContent || '');

    // Calculate the current content length and the emoji length (typically 2 chars)
    const maxLength =
      watchedContent === 'content_sms' ? SMS_MESSAGE_LIMIT : BIG_MESSAGE_LIMIT;

    // Ensure content length plus emoji length does not exceed the max limit
    if (strippedContent.length + EMOJI_CHARS_LENGTH <= maxLength) {
      const editor = quillRef?.getEditor();
      const defaultIndex = (editor?.getText().length || 1) - 1 || 0;

      // Get the current cursor position (selection range)
      const index = editor?.getSelection()?.index || defaultIndex;

      // Insert the emoji at the current cursor position
      editor?.insertText(index, emoji);

      // After inserting the emoji, update the content value
      if (quillRef?.editor?.root.innerHTML) {
        methods.setValue(
          content as Path<T>,
          quillRef.editor.root.innerHTML as UnpackNestedValue<
            PathValue<T, Path<T>>
          >,
        );
      }

      // Hide the emoji modal after selecting the emoji
      setShowEmojis(false);
    }
  };

  const onVariableSelect = (variable: string) => {
    const strippedContent = stripRichTextEditorElements(
      quillRef?.getEditor()?.root.innerText || '',
    );

    // Variable length + 1 to account for the space before the variable
    const variableLength = variable.length + 1;
    const maxLength =
      watchedContent === 'content_sms' ? SMS_MESSAGE_LIMIT : BIG_MESSAGE_LIMIT;

    // Ensure content length plus variable length does not exceed the max limit
    if (strippedContent.length + variableLength <= maxLength) {
      const editor = quillRef?.getEditor();
      const index = editor?.getSelection()?.index;

      if (editor && index !== undefined) {
        // Insert a space followed by the variable (as a pill or embedded content)
        editor.insertText(index, ' '); // Insert a space before the variable
        editor.insertEmbed(
          index + 1, // Insert the variable right after the space
          'pill',
          variable
            .replaceAll('${', '')
            .replaceAll('}', '')
            .replaceAll('_', ' '),
        );

        // After inserting the variable, update the content value
        if (quillRef && quillRef.editor?.root.innerHTML) {
          methods.setValue(
            content as Path<T>,
            quillRef.editor.root.innerHTML as UnpackNestedValue<
              PathValue<T, Path<T>>
            >,
          );
        }
      }
    }
  };

  const handlePaste = (e: ClipboardEvent) => {
    const pastedText = e && e.clipboardData?.getData('text');
    const strippedContent = stripRichTextEditorElements(watchedContent);

    // Check if pasted text would exceed the character limit
    if (
      pastedText &&
      strippedContent.length + pastedText.length > BIG_MESSAGE_LIMIT
    ) {
      e.preventDefault(); // Block paste if limit exceeded
    }
  };

  useEffect(() => {
    const quillEditor = quillRef?.getEditor();
    if (quillEditor) {
      quillEditor.root.addEventListener('paste', handlePaste);
      return () => {
        quillEditor.root.removeEventListener('paste', handlePaste);
      };
    }
  }, [watchedContent]);

  return (
    <>
      <Tooltip text={getText('add-attachment')} anchorId={'add-attachment'} />
      <Tooltip text={getText('insert-emoji')} anchorId={'insert-emoji'} />
      <Tooltip
        text={getText('highlight-text-to-insert-link')}
        anchorId={'insert-link'}
      />
      <div id={toolbarId}>
        {toolbarOptions.includes(ToolBarOptions.Variables) ? (
          <select className="ql-variables" style={styles.variables}>
            <option value="add_variable">{getText('add-variable')}</option>
            <option value="${patient_first_name}" style={styles.variableOption}>
              {getText('patient-first-name-messaging')}
            </option>
            <option value="${patient_last_name}" style={styles.variableOption}>
              {getText('patient-last-name-messaging')}
            </option>
            <option value="${pharmacy_name}">{getText('pharmacy-name')}</option>
            <option
              value="${pharmacy_phone_number}"
              style={styles.variableOption}
            >
              {getText('pharmacy-phone-number-messaging')}
            </option>
          </select>
        ) : null}
        {toolbarOptions.includes(ToolBarOptions.Attachments) ? (
          <button
            className="ql-attachment"
            id="add-attachment"
            onClick={onSelectAttachments}
          >
            <Icon
              size={20}
              color={theme.palette.gray[500]}
              icon={PaperClipIcon}
            />
          </button>
        ) : null}
        {toolbarOptions.includes(ToolBarOptions.Emojis) ? (
          <button className="ql-emoji" onClick={onSmileClick} id="insert-emoji">
            <Icon size={20} color={theme.palette.gray[500]} icon={SmileIcon} />
          </button>
        ) : null}
        {toolbarOptions.includes(ToolBarOptions.Links) ? (
          <button className="ql-link" id="insert-link">
            <Icon size={20} color={theme.palette.gray[500]} icon={LinkIcon} />
          </button>
        ) : null}
      </div>
      <ReactQuill
        ref={(el) => {
          if (el) {
            quillRef = el;
            handleQuillRef(quillRef);
          }
        }}
        theme="snow"
        id={'quill-message-box-' + content}
        placeholder={`${placeholder}`}
        value={watchedContent}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        style={{ ...styles.message, height: height }}
        bounds={'#quill-message-box-' + content}
        readOnly={disabled} //TODO this will be reverted soon
        onKeyPress={(e: KeyboardEvent) => {
          const strippedContent = stripRichTextEditorElements(watchedContent);

          if (
            strippedContent.length >= BIG_MESSAGE_LIMIT &&
            e.key !== 'Backspace'
          ) {
            e.preventDefault();
          }
        }}
        modules={useMemo(() => {
          return {
            toolbar: {
              container: `#${toolbarId}`,
              handlers: {
                variables: onVariableSelect,
              },
            },
            clipboard: {
              matchVisual: false,
            },
          };
        }, [])}
        onChange={(value, delta, source) => {
          const strippedContent = stripRichTextEditorElements(value);
          if (strippedContent.length > BIG_MESSAGE_LIMIT) {
            return;
          } else {
            if (source === 'user') {
              methods.setValue(
                content as Path<T>,
                value as UnpackNestedValue<PathValue<T, Path<T>>>,
              );
            }
            if (
              errorFields &&
              setErrorFields &&
              MessageFields.message in errorFields
            ) {
              const errorFieldsCopy = { ...errorFields };
              delete errorFieldsCopy[MessageFields.message];
              setErrorFields(errorFieldsCopy);
            }
          }
        }}
      />
      {attachments && Boolean(attachments.length) && (
        <View style={styles.attachments}>
          <Attachments files={attachments} onRemoveFile={onRemoveFile} />
        </View>
      )}
      {showEmojis && (
        <View style={styles.emojiWrapper} ref={outSideClickRef as any}>
          <EmojiModal onEmojiSelected={(emoji) => onEmojiSelect(emoji)} />
        </View>
      )}
    </>
  );
};

interface NewMessageBodyProps<T extends FieldValues> {
  methods: UseFormReturn<T, any>;
  setErrorFields?: (errorFields: Record<string, string>) => void;
  errorFields?: Record<string, string>;
  handleQuillRef: (quillRef: ReactQuill) => void;
  toolbarId: string;
  content: 'content' | 'content_email' | 'content_sms' | 'content_chatbox';
  placeholder: string;
  toolbarOptions: ToolBarOptions[];
  height: any;
  disabled?: boolean;
}

export enum ToolBarOptions {
  Variables = 'variables',
  Attachments = 'attachments',
  Emojis = 'emojis',
  Links = 'link',
}

const useStyles = makeStyles((theme) => ({
  message: {
    marginBottom: theme.getSpacing(1),
  },
  emojiWrapper: {
    position: 'absolute',
    bottom: 15,
    zIndex: 10,
    backgroundColor: theme.palette.black,
    padding: theme.getSpacing(1),
    borderRadius: 16,
  },
  attachments: {
    marginBottom: theme.getSpacing(1),
    marginRight: theme.getSpacing(2),
  },
  variables: {
    fontFamily: 'Lato_700Bold',
    textAlign: 'left',
    width: 105,
    marginLeft: theme.getSpacing(1),
    marginRight: theme.getSpacing(1),
  },
  variableOption: {
    width: 32,
    height: 32,
    fontFamily: 'Lato_400Regular',
    size: 14,
  },
}));
