import React from 'react';
import { View } from 'react-native';
import { Text } from 'assets/components/text';
import {
  GroupBase,
  OptionProps,
  SingleValueProps,
  ValueContainerProps,
} from 'react-select';
import theme, { makeStyles } from 'assets/theme';
import { Avatar } from 'assets/components/avatar';
import { getFullName } from 'assets/utils/messageUtils';
import { useMessagingStyles } from '../../screens/messages/common/styles';
import { calculatePatientAge, formatDate } from '../../common/datetime-utils';
import { UserIcon } from 'assets/icons/UserIcon';
import { DropdownSelectTestIDs } from '../advanced-dropdown/advanced-drop-down-test-ids';
import { Icon } from 'assets/components/icon';

export const SingleValueTemplateWithDetails = <
  Option extends PatientUserSelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: SingleValueProps<Option, IsMulti, Group> & { label: string },
) => {
  const { date_of_birth, gender, first_name, last_name } = props.data;
  const age = calculatePatientAge(date_of_birth);
  const styles = useStyles();

  return (
    <View style={styles.dropdownOptionContainer}>
      <Avatar firstName={first_name} lastName={last_name} size={24} />
      <View>
        <View style={styles.smallLabel}>
          <Text
            style={styles.smallLabelStyle}
            testID={DropdownSelectTestIDs.label}
          >
            {props.label}
          </Text>
        </View>
        <View style={styles.textContainer}>
          <Text
            style={styles.dropdownFullName}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {getFullName(props.data)}
          </Text>
          <Text style={[styles.dropdownDetails]}>
            {'- '}
            {formatDate(date_of_birth)}
            {', '}
            {age} {gender === 'male' ? ' M' : gender === 'female' ? ' F' : ''}
          </Text>
        </View>
      </View>
    </View>
  );
};

export const OptionTemplateWithDetails = <
  Option extends PatientUserSelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: OptionProps<Option, IsMulti, Group>,
) => {
  const styles = useStyles();
  const { date_of_birth, gender, first_name, last_name } = props.data;
  const age = calculatePatientAge(date_of_birth);
  return (
    <View style={styles.dropdownOptionContainer}>
      <Avatar firstName={first_name} lastName={last_name} size={24} />
      <View style={styles.textContainer}>
        <Text
          style={[
            styles.dropdownFullName,
            props.isDisabled && styles.disabledOptionText,
          ]}
          numberOfLines={1}
          ellipsizeMode="tail"
        >
          {getFullName(props.data)}
        </Text>
        <Text
          style={[
            styles.dropdownDetailsGray,
            props.isDisabled && styles.disabledOptionText,
          ]}
        >
          {'- '}
          {formatDate(date_of_birth)}
          {', '}
          {age}
          {gender === 'male' ? ' M' : gender === 'female' ? ' F' : ''}
        </Text>
      </View>
    </View>
  );
};

export const EmptyPlaceholderTemplate = <
  Option extends PatientUserSelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: ValueContainerProps<Option, IsMulti, Group> & {
    label: string;
    focused: boolean;
  },
) => {
  const styles = useStyles();
  const messagingStyles = useMessagingStyles();

  return (
    <View style={messagingStyles.dropdownOptionContainer}>
      {props.focused ? (
        <View style={{ paddingRight: theme.getSpacing(1) }}>
          <Icon
            size={24}
            color={theme.palette.gray[500]}
            icon={UserIcon}
            strokeWidth={2}
          />
        </View>
      ) : (
        <View style={messagingStyles.dropdownOptionContainer}>
          <Icon
            size={24}
            color={theme.palette.gray[500]}
            icon={UserIcon}
            strokeWidth={2}
          />
          <Text
            style={styles.dropdownPlaceholderText}
            testID={DropdownSelectTestIDs.label}
          >
            {props.label}
          </Text>
        </View>
      )}
    </View>
  );
};

export type PatientUserSelectOption = {
  id: string;
  first_name: string;
  last_name: string;
  gender?: string;
  date_of_birth: string;
  patient_record_id: string | null;
};

const useStyles = makeStyles((theme) => ({
  inputWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  dropdownPlaceholderText: {
    ...theme.lumistryFonts.label.large,
    fontSize: 18,
    lineHeight: 20,
    marginRight: 'auto',
    color: theme.palette.gray[500],
    paddingLeft: theme.getSpacing(1),
  },
  smallLabel: {
    marginLeft: theme.getSpacing(1),
  },
  dropdownFullName: {
    fontSize: 16,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    flexShrink: 1,
  },
  dropdownDetails: {
    marginLeft: theme.getSpacing(0.5),
    fontSize: 16,
    flex: 1,
    flexShrink: 0,
  },
  dropdownDetailsGray: {
    marginLeft: theme.getSpacing(0.5),
    color: theme.palette.gray[400],
    fontSize: 16,
    flexShrink: 0,
  },
  smallLabelStyle: {
    color: theme.palette.gray[600],
    ...theme.lumistryFonts.label.xxSmall,
  },
  dropdownOptionContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  textContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    marginLeft: theme.getSpacing(1),
  },
  disabledOptionText: {
    color: theme.palette.gray[200],
  },
}));
