import { FC } from 'react';
import { View } from 'react-native';
import BulletPoint, { BulletPointStyle } from './BulletPoint';
import { makeStyles } from 'assets/theme';
import { Text } from 'assets/components/text';

interface BulletPointsProps {
  bulletPointTitle?: string;
  bulletPoints: string[];
  bulletPointStyle: BulletPointStyle;
}

const BulletPoints: FC<BulletPointsProps> = ({
  bulletPoints,
  bulletPointStyle,
  bulletPointTitle,
}) => {
  const styles = useStyles();
  return (
    <>
      <Text style={styles.bulletPointTitle}>{bulletPointTitle}</Text>
      <View style={styles.bulletPointsContainer}>
        {bulletPoints.map((bullet) => (
          <BulletPoint key={bullet} text={bullet} type={bulletPointStyle} />
        ))}
      </View>
    </>
  );
};

export default BulletPoints;

const useStyles = makeStyles((theme) => ({
  bulletPointsContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: 370,
  },
  bulletPointTitle: {
    marginVertical: theme.getSpacing(1),
    ...theme.lumistryFonts.text.medium.regular,
    color: theme.palette.gray[900],
  },
}));
