import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path } from 'react-native-svg';
import { IconProps } from './types';

export const EmptyPmsStateIcon: FunctionComponent<
  PropsWithChildren<IconProps>
> = ({ color, size, testID }) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 294 263" fill="none">
      <Path
        d="M24.268 105.124A23.792 23.792 0 017.445 64.509l53.329-53.328a23.79 23.79 0 1133.645 33.645l-53.328 53.33a23.792 23.792 0 01-16.823 6.968z"
        fill="#EAF1F4"
      />
      <Path
        d="M43.146 152.89a23.79 23.79 0 01-16.823-40.614l105.31-105.31a23.79 23.79 0 1133.646 33.646l-105.31 105.31a23.789 23.789 0 01-16.823 6.968z"
        fill="#EAF1F4"
      />
      <Path
        d="M50.642 212.04a23.79 23.79 0 01-16.823-40.615L190.782 14.462a23.793 23.793 0 0133.646 0 23.79 23.79 0 010 33.646L67.465 205.071a23.79 23.79 0 01-16.823 6.969z"
        fill="#EAF1F4"
      />
      <Path
        d="M66.326 263.002a23.79 23.79 0 01-16.823-40.614L218.65 53.239a23.793 23.793 0 0140.615 16.823c0 6.31-2.507 12.36-6.968 16.823L83.148 256.033a23.79 23.79 0 01-16.822 6.969z"
        fill="#EAF1F4"
      />
      <Path
        d="M134.91 261.062a23.791 23.791 0 01-16.823-40.614L239.804 98.73a23.79 23.79 0 0133.647 33.646L151.733 254.093a23.791 23.791 0 01-16.823 6.969z"
        fill="#EAF1F4"
      />
      <Path
        d="M216.403 246.214A23.792 23.792 0 01199.58 205.6l53.329-53.329a23.79 23.79 0 1133.646 33.646l-53.329 53.329a23.79 23.79 0 01-16.823 6.968z"
        fill="#EAF1F4"
      />
      <Path
        d="M34.975 63.784l1.646-1.646a1.649 1.649 0 00-2.33-2.33l-1.646 1.645-1.646-1.646a1.648 1.648 0 00-2.33 2.33l1.646 1.647-1.646 1.646A1.648 1.648 0 0031 67.76l1.646-1.646 1.646 1.646a1.645 1.645 0 002.33 0 1.649 1.649 0 000-2.33l-1.646-1.646zM213.539 18.618l1.646-1.647a1.647 1.647 0 10-2.33-2.33l-1.645 1.646-1.647-1.646a1.648 1.648 0 00-2.33 2.33l1.646 1.647-1.646 1.646a1.648 1.648 0 002.33 2.33l1.647-1.647 1.645 1.646c.322.322.744.483 1.166.483a1.646 1.646 0 001.164-2.812l-1.646-1.646zM277.537 167.978l1.646-1.646a1.648 1.648 0 00-2.33-2.33l-1.646 1.646-1.646-1.646a1.646 1.646 0 10-2.33 2.33l1.646 1.646-1.646 1.646a1.646 1.646 0 102.33 2.33l1.646-1.646 1.646 1.646a1.643 1.643 0 002.33 0 1.648 1.648 0 000-2.33l-1.646-1.646zM105.692 252.123l1.646-1.647a1.648 1.648 0 00-2.33-2.33l-1.646 1.646-1.647-1.646a1.647 1.647 0 00-2.33 2.33l1.646 1.647-1.645 1.646a1.646 1.646 0 102.329 2.329l1.647-1.646 1.646 1.646a1.644 1.644 0 002.33 0 1.647 1.647 0 000-2.329l-1.646-1.646z"
        fill="#CFB3AE"
      />
      <Path
        d="M110.651 8.892a1.52 1.52 0 00-1.518 1.518 1.52 1.52 0 001.518 1.518 1.52 1.52 0 001.518-1.518 1.52 1.52 0 00-1.518-1.518zm0 6.331a4.819 4.819 0 01-4.813-4.813 4.818 4.818 0 014.813-4.812 4.818 4.818 0 014.812 4.812 4.819 4.819 0 01-4.812 4.813zM19.355 175.681a1.52 1.52 0 00-1.518 1.518 1.52 1.52 0 001.518 1.517 1.52 1.52 0 001.518-1.517 1.52 1.52 0 00-1.518-1.518zm0 6.33a4.819 4.819 0 01-4.813-4.812 4.818 4.818 0 014.813-4.813 4.818 4.818 0 014.813 4.813 4.818 4.818 0 01-4.813 4.812zM226.583 235.132a1.518 1.518 0 10.002 3.036 1.518 1.518 0 00-.002-3.036zm0 6.33a4.817 4.817 0 01-4.812-4.813 4.817 4.817 0 014.812-4.813 4.818 4.818 0 014.813 4.813 4.818 4.818 0 01-4.813 4.813zM268.293 82.954a1.52 1.52 0 00-1.518 1.517 1.52 1.52 0 001.518 1.518 1.52 1.52 0 001.518-1.518 1.52 1.52 0 00-1.518-1.517zm0 6.33a4.818 4.818 0 01-4.813-4.813 4.819 4.819 0 014.813-4.813 4.819 4.819 0 014.813 4.813 4.818 4.818 0 01-4.813 4.813z"
        fill="#6AC0CC"
      />
      <Path
        d="M42.608 162.916l1.18-32.398a23.233 23.233 0 014.396-12.972 23.334 23.334 0 0110.607-8.163l1.827-.698.561 1.874c2.175 7.26 7.76 10.642 17.58 10.642 9.819 0 15.405-3.382 17.58-10.642l.562-1.874 1.827.698a23.541 23.541 0 018.337 5.471 23.499 23.499 0 015.311 8.466l.513 1.431-1.319.757c-8.477 4.862-13.711 13.592-14.001 23.351l-.512 14.057H42.608z"
        fill="#5CC4FF"
      />
      <Path
        d="M61.797 106.29l-3.655 1.397a25.136 25.136 0 00-6.38 3.58 25.28 25.28 0 00-5.051 5.215 25.04 25.04 0 00-4.738 13.976l-1.111 30.509-.137 3.765h58.084l.127-3.5.448-12.295v-.024c.271-9.123 5.165-17.284 13.09-21.83l2.638-1.513-1.026-2.863a25.103 25.103 0 00-2.385-4.899 25.29 25.29 0 00-7.493-7.644 25.162 25.162 0 00-4.832-2.477l-3.654-1.397-1.123 3.748c-1.937 6.464-6.822 9.347-15.84 9.347s-13.904-2.883-15.84-9.347l-1.122-3.748zm36.282 4.79a21.59 21.59 0 0112.588 12.852c-8.838 5.069-14.602 14.391-14.913 24.873l-.448 12.295H44.492l1.11-30.522c.259-8.94 5.94-16.479 13.837-19.498 2.75 9.181 10.361 11.937 19.32 11.937 8.96 0 16.57-2.756 19.32-11.937z"
        fill="#0063B2"
      />
      <Path
        d="M97.02 182.601l1.272-34.965a25.026 25.026 0 014.738-13.98 25.138 25.138 0 0111.428-8.794l1.828-.698.561 1.874c2.38 7.945 8.482 11.645 19.204 11.645 10.722 0 16.825-3.7 19.205-11.645l.561-1.874 1.827.698a25.134 25.134 0 0111.444 8.815 25.041 25.041 0 014.723 13.96l1.272 34.964H97.019z"
        fill="#5CC4FF"
      />
      <Path
        d="M154.639 121.769l-1.123 3.748c-1.331 4.444-4.589 10.351-17.465 10.351-12.875 0-16.134-5.907-17.464-10.351l-1.124-3.748-3.654 1.397a27.078 27.078 0 00-12.252 9.427 26.848 26.848 0 00-5.081 14.986l-1.204 33.074-.137 3.765h81.832l-.137-3.765-.429-11.773-.775-21.303a26.858 26.858 0 00-5.064-14.961 27.047 27.047 0 00-12.269-9.45l-3.654-1.397zm2.357 4.791c8.593 3.285 14.721 11.481 14.999 21.137l1.205 33.088H98.903l1.204-33.088c.279-9.695 6.442-17.866 15-21.137 2.911 9.718 10.88 12.941 20.944 12.941 10.065 0 18.034-3.224 20.945-12.941z"
        fill="#0063B2"
      />
      <Path
        d="M175.045 162.916l-.512-14.057c-.29-9.759-5.524-18.489-14.001-23.351l-1.319-.757.513-1.431a23.499 23.499 0 015.311-8.466 23.56 23.56 0 018.337-5.471l1.828-.698.561 1.874c2.175 7.26 7.761 10.642 17.58 10.642 9.819 0 15.406-3.382 17.58-10.642l.561-1.874 1.828.698a23.34 23.34 0 0110.607 8.163 23.223 23.223 0 014.395 12.972l.22 6.024-1.886-.003-.633-.001c-1.759 0-2.112.007-2.833.224l-.027.008a5.406 5.406 0 00-3.562 3.429 5.387 5.387 0 00-.289 1.746v10.85h-10.85a5.39 5.39 0 00-4.979 3.307 5.356 5.356 0 00-.423 2.094v4.72h-28.007z"
        fill="#5CC4FF"
      />
      <Path
        d="M210.306 106.29l-1.122 3.748c-1.937 6.464-6.822 9.347-15.841 9.347-9.018 0-13.903-2.883-15.84-9.347l-1.122-3.748-3.655 1.397a25.25 25.25 0 00-12.325 10.121 25.042 25.042 0 00-2.384 4.899l-1.026 2.863 2.638 1.513c7.925 4.546 12.818 12.707 13.089 21.83l.001.012v.012l.447 12.295.128 3.5h31.574v-6.536a3.575 3.575 0 011.045-2.528 3.567 3.567 0 012.541-1.057H221.121v-12.666a3.588 3.588 0 01.723-2.161 3.603 3.603 0 011.831-1.274l.028-.008c.454-.137.626-.148 2.312-.148l.631.001 3.772.005-.137-3.77-.151-4.132a25.044 25.044 0 00-4.738-13.976 25.28 25.28 0 00-5.052-5.215 25.156 25.156 0 00-6.379-3.58l-3.655-1.397zm2.358 4.79c7.897 3.019 13.578 10.558 13.836 19.498l.151 4.144h-.636c-1.763 0-2.357 0-3.357.301l-.024.008a7.197 7.197 0 00-3.687 2.563 7.214 7.214 0 00-1.459 4.351v9.034h-9.034c-.977 0-1.927.193-2.823.574a7.175 7.175 0 00-2.293 1.553 7.198 7.198 0 00-2.102 5.09v2.904h-24.44l-.447-12.295c-.316-10.663-6.239-19.897-14.913-24.873a21.59 21.59 0 0112.588-12.852c2.75 9.181 10.36 11.937 19.319 11.937s16.571-2.756 19.321-11.937z"
        fill="#0063B2"
      />
      <Path
        d="M228.058 184.817l-3.352-.001a4.563 4.563 0 01-4.558-4.558v-11.694h-11.694a4.563 4.563 0 01-4.558-4.557v-5.81a4.563 4.563 0 014.558-4.557h11.694v-11.695c0-1 .319-1.949.921-2.746a4.606 4.606 0 012.328-1.62l.028-.008c.59-.177.847-.188 2.59-.188l.801.001h.545c.812.002 1.841.004 3.155.004a4.562 4.562 0 014.557 4.557v11.695h11.694a4.562 4.562 0 014.558 4.557v5.81a4.562 4.562 0 01-4.558 4.557h-11.694v11.694a4.574 4.574 0 01-3.927 4.514l-.02.003c-.281.042-.395.043-1.815.043l-1.253-.001z"
        fill="#FF6A6A"
      />
      <Path
        d="M226.015 135.566c-1.715 0-2.233 0-3.114.266l-.026.008a6.413 6.413 0 00-3.255 2.264 6.317 6.317 0 00-1.288 3.841v9.879h-9.878a6.38 6.38 0 00-6.374 6.373V164.007a6.38 6.38 0 006.374 6.373h9.878v9.878a6.38 6.38 0 006.374 6.374l3.35.001 1.255.001c1.504 0 1.662 0 2.082-.063h.007a6.378 6.378 0 005.489-6.313v-9.878h9.878a6.381 6.381 0 006.374-6.373v-5.81a6.38 6.38 0 00-6.374-6.373h-9.878v-9.879a6.381 6.381 0 00-6.374-6.374c-1.317 0-2.34-.002-3.162-.003h-.535l-.803-.002zm0 3.633l.798.001c.882.001 2.074.004 3.702.004a2.742 2.742 0 012.741 2.741v13.511h13.511a2.74 2.74 0 012.741 2.741v5.81a2.74 2.74 0 01-2.741 2.741h-13.511v13.51a2.74 2.74 0 01-2.361 2.715c-.172.024-.069.029-1.584.029-.875 0-2.289-.002-4.605-.002a2.741 2.741 0 01-2.742-2.742v-13.51h-13.51a2.742 2.742 0 01-2.742-2.741V158.197a2.744 2.744 0 012.742-2.741h13.51v-13.511c0-1.24.824-2.287 1.954-2.626.343-.102.345-.12 2.097-.12z"
        fill="#0063B2"
      />
      <Path
        d="M78.76 122.136c-2.967 0-5.846-.582-8.556-1.729a21.873 21.873 0 01-6.985-4.709 21.899 21.899 0 01-4.709-6.985 21.832 21.832 0 01-1.727-8.554 21.906 21.906 0 016.437-15.54 21.92 21.92 0 0115.54-6.437A21.918 21.918 0 0194.3 84.62a21.917 21.917 0 016.435 15.54 21.908 21.908 0 01-6.435 15.539 21.904 21.904 0 01-6.985 4.709 21.837 21.837 0 01-8.556 1.729z"
        fill="#FFC854"
      />
      <Path
        d="M78.759 76.367c-3.21 0-6.327.63-9.262 1.871a23.711 23.711 0 00-7.562 5.098 23.718 23.718 0 00-6.969 16.824 23.719 23.719 0 006.968 16.823 23.687 23.687 0 007.562 5.098 23.645 23.645 0 009.263 1.872c3.21 0 6.327-.63 9.263-1.872a23.711 23.711 0 007.562-5.098 23.708 23.708 0 006.967-16.823 23.724 23.724 0 00-6.968-16.824 23.716 23.716 0 00-7.562-5.098 23.646 23.646 0 00-9.262-1.871zm0 3.632c11.134 0 20.16 9.027 20.16 20.161 0 11.132-9.025 20.16-20.16 20.16s-20.16-9.028-20.16-20.16c0-11.134 9.026-20.16 20.16-20.16z"
        fill="#0063B2"
      />
      <Path
        d="M193.343 122.136a21.915 21.915 0 01-15.539-6.436 21.923 21.923 0 01-6.437-15.541 21.919 21.919 0 016.437-15.54 21.904 21.904 0 0115.539-6.437 21.92 21.92 0 0115.54 6.437 21.9 21.9 0 014.709 6.986 21.83 21.83 0 011.728 8.554 21.915 21.915 0 01-6.436 15.539 21.897 21.897 0 01-6.985 4.709 21.836 21.836 0 01-8.556 1.729z"
        fill="#FFC854"
      />
      <Path
        d="M193.343 76.367a23.725 23.725 0 00-16.824 6.969 23.733 23.733 0 00-6.968 16.824c0 3.21.63 6.327 1.871 9.263a23.686 23.686 0 005.099 7.561 23.68 23.68 0 007.561 5.097 23.635 23.635 0 009.261 1.872c3.211 0 6.328-.63 9.263-1.872a23.715 23.715 0 007.562-5.098 23.696 23.696 0 005.097-7.562 23.634 23.634 0 001.871-9.261c0-3.21-.629-6.327-1.871-9.263a23.703 23.703 0 00-5.098-7.561 23.699 23.699 0 00-7.561-5.098 23.65 23.65 0 00-9.263-1.871zm0 3.632c11.135 0 20.161 9.027 20.161 20.161 0 11.132-9.025 20.16-20.161 20.16-11.131 0-20.159-9.025-20.159-20.16 0-11.134 9.025-20.16 20.159-20.16z"
        fill="#0063B2"
      />
      <Path
        d="M136.092 137.948a23.86 23.86 0 01-12.022-3.245 23.612 23.612 0 01-8.98-9.417 23.723 23.723 0 01-2.711-11.009 23.605 23.605 0 016.933-16.738 23.584 23.584 0 017.525-5.073 23.515 23.515 0 019.214-1.86 23.59 23.59 0 0116.739 6.933 23.605 23.605 0 016.933 16.738c0 3.334-.681 6.56-2.023 9.589a23.58 23.58 0 01-5.663 7.87 23.558 23.558 0 01-15.945 6.212z"
        fill="#FFC854"
      />
      <Path
        d="M136.051 88.789c-3.439 0-6.777.674-9.922 2.004a25.382 25.382 0 00-8.1 5.461 25.386 25.386 0 00-5.461 8.101 25.331 25.331 0 00-2.005 9.922 25.374 25.374 0 002.92 11.854 25.626 25.626 0 004.225 5.861 25.267 25.267 0 005.447 4.28 25.678 25.678 0 0012.937 3.493 25.31 25.31 0 009.014-1.648 25.37 25.37 0 008.157-5.042 25.473 25.473 0 006.097-8.473 25.325 25.325 0 002.18-10.325c0-3.439-.675-6.778-2.005-9.922a25.386 25.386 0 00-5.461-8.1 25.382 25.382 0 00-8.1-5.462 25.334 25.334 0 00-9.923-2.004zm0 3.632c12.071 0 21.856 9.785 21.856 21.856a21.797 21.797 0 01-7.097 16.119 21.669 21.669 0 01-14.718 5.736c-7.754 0-15.397-4.095-19.395-11.692a21.77 21.77 0 01-2.501-10.163c0-12.071 9.785-21.856 21.855-21.856z"
        fill="#0063B2"
      />
    </Svg>
  );
};
