import { Icon } from 'assets/components/icon';
import { Text } from 'assets/components/text';
import { makeStyles, useTheme } from 'assets/theme';
import { FC, FunctionComponent } from 'react';
import { View } from 'react-native';

const EmptyStateMessage: FC<EmptyStateMessageProps> = ({ icon, message }) => {
  const styles = useStyles();
  const theme = useTheme();

  return (
    <View style={styles.container}>
      <Icon icon={icon} size={16} color={theme.palette.gray[500]} />
      <Text style={styles.text}>{message}</Text>
    </View>
  );
};

export default EmptyStateMessage;

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: theme.getSpacing(1),
  },
  text: {
    fontSize: 14,
    color: theme.palette.gray[700],
  },
}));

interface EmptyStateMessageProps {
  icon: FunctionComponent;
  message: string;
}
