import create from 'zustand';

export interface InternalUseState {
  internalUseBillableUsage: NameValueType[];
  internalUseNonBillableUsage: NameValueType[];
  showInternalUseModal: boolean;
}

export const useInternalUseState = create<InternalUseState>(() => ({
  internalUseBillableUsage: [],
  internalUseNonBillableUsage: [],
  showInternalUseModal: false,
}));

type NameValueType = {
  name: string;
  shortName: string;
  nonBillable?: boolean;
  value: number | undefined;
};
