import { Modal } from 'assets/components/modal';
import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';

import {
  HolidayDto,
  ToggleHolidayDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import {
  CheckboxField,
  CheckboxInput,
  CheckboxInputMode,
} from 'assets/components/checkbox';
import { Form } from 'assets/layout/form/Form';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import { useFieldArray, useForm } from 'react-hook-form';
import { View } from 'react-native';
import {
  setShowModalHolidayClosures,
  updateHolidays,
} from '../pharmacy-settings-actions';
import { usePharmacySettingsState } from '../pharmacy-settings-store';

export const HolidayClosuresModal: FunctionComponent<
  PropsWithChildren<any>
> = () => {
  const { showModalHolidayClosures, holidays } = usePharmacySettingsState();
  const [loadingMethod, setLoadingMethod] = useState(false);
  const styles = useStyles();

  const methods = useForm<HolidayClosuresFormProps>({
    defaultValues: { holidays },
    mode: 'onChange',
  });

  const allEnabled = methods
    .watch()
    .holidays.every((holiday) => holiday.enabled);

  const { control } = methods;
  const { fields } = useFieldArray({
    control,
    name: 'holidays',
  });

  useEffect(() => {
    methods.reset({ holidays });
  }, [holidays]);

  const handleSubmit = async () => {
    setLoadingMethod(true);
    const formValue = methods.getValues();
    const toggleHolidays: ToggleHolidayDto[] = formValue.holidays.map(
      (holiday) => {
        return {
          id: holiday.id,
          enabled: holiday.enabled,
          next_occurrence: holiday.next_occurrence,
        };
      },
    );
    await updateHolidays(toggleHolidays);
    setLoadingMethod(false);
    setShowModalHolidayClosures(false);
  };

  const closeModal = () => {
    setShowModalHolidayClosures(false);
  };

  const handleSelectAll = (value: boolean) => {
    if (value) {
      methods.reset({
        holidays: methods
          .getValues()
          .holidays.map((holiday) => ({ ...holiday, enabled: true })),
      });
    } else {
      methods.reset({ holidays });
    }
  };

  return (
    <Modal
      title={getText('pharmacy-holiday-closures')}
      titleSize="sm"
      dismissButtonProps={{
        onPress: closeModal,
        logger: { id: 'pharmacy-holiday-closures-form-cancel-button-modal' },
      }}
      cancelButtonProps={{
        onPress: closeModal,
        hierarchy: 'tertiary-gray',
        logger: { id: 'pharmacy-holiday-closures-form-cancel-button-modal' },
      }}
      okButtonProps={{
        onPress: methods.handleSubmit(handleSubmit),
        logger: { id: 'pharmacy-holiday-closures-form-ok-button-modal' },
        hierarchy: 'primary',
        text: getText('ok'),
        loading: loadingMethod,
      }}
      show={showModalHolidayClosures}
      isScrollable={true}
      size="lg"
    >
      <Form methods={methods}>
        <Form.Row>
          <Form.Column>
            <View style={styles.viewStyle}>
              <CheckboxInput
                key={'select-all'}
                checked={allEnabled}
                label={getText('select-all')}
                mode={CheckboxInputMode.FLAT}
                onPress={handleSelectAll}
              />
            </View>
            {fields
              .slice(0, Math.ceil(fields.length / 2))
              .map((field, index) => (
                <View style={styles.viewStyle}>
                  <CheckboxField
                    key={`${field.id}.enabled`}
                    name={`holidays.${index}.enabled`}
                    label={field.name}
                    mode={CheckboxInputMode.FLAT}
                  />
                </View>
              ))}
          </Form.Column>
          <Form.Column>
            {fields.slice(Math.ceil(fields.length / 2)).map((field, index) => (
              <View style={styles.viewStyle}>
                <CheckboxField
                  key={`${field.id}.enabled`}
                  name={`holidays.${
                    index + Math.ceil(fields.length / 2)
                  }.enabled`}
                  label={field.name}
                  mode={CheckboxInputMode.FLAT}
                />
              </View>
            ))}
          </Form.Column>
        </Form.Row>
      </Form>
    </Modal>
  );
};

interface HolidayClosuresFormProps {
  holidays: HolidayDto[];
}

const useStyles = makeStyles((theme) => ({
  viewStyle: {
    marginBottom: theme.getSpacing(2),
  },
}));
