import create from 'zustand';
import { AsyncStatus } from '../../../store/types';

export const useAutomationModalState = create<AutomationModalState>((set) => ({
  showModal: false,
  status: 'idle',
  automationId: '',
}));

interface AutomationModalState {
  showModal: boolean;
  status: AsyncStatus;
  automationId: string;
}
