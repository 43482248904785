import { IconProps } from 'assets/icons/types';
import React, { FunctionComponent } from 'react';
import Svg, { Path } from 'react-native-svg';

export const OBLoaderIcon: FunctionComponent<IconProps> = ({
  color,
  size = 100,
  testID,
}) => {
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      testID={testID}
      viewBox="0 0 100 100"
    >
      <Path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={5}
        d="M50 8.334V25m0 50v16.667M20.543 20.542l11.792 11.791m35.333 35.334L79.46 79.458M8.334 50h16.667m50 0h16.666M20.542 79.46l11.792-11.792m35.333-35.334L79.46 20.543"
      />
    </Svg>
  );
};
