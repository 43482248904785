import React, { useMemo, useState } from 'react';
import { View } from 'react-native';
import SubmissionsDataTable from '../../forms/submissions-data-table/SubmissionsDataTable';
import { makeStyles } from 'assets/theme';
import EmptyState from '../../components/EmptyState/EmptyState';
import { getText } from 'assets/localization/localization';
import NewFormModal from '../../forms/new-form-modal/NewFormModal';
import { getFormTemplates } from '../../forms/new-form-modal/new-form-modal-actions';
import NoSubmissionsImage from './NoSubmissionsImage';
import { PharmacyScreenContainer } from 'assets/layout';

export default function Submissions() {
  const styles = useStyles();
  const [submissionDataExists, setSubmissionsDataExists] = useState(true);

  const noSubmissionsDataHandler = () => {
    setSubmissionsDataExists(false);
  };

  const [showModal, setShowModal] = useState(false);

  const handleShowModal = async () => {
    await getFormTemplates();
    setShowModal(true);
  };

  const handleHideModal = () => {
    setShowModal(false);
  };

  const displayDataTable = useMemo(
    () => submissionDataExists,
    [submissionDataExists],
  );

  return (
    <>
      <NewFormModal
        showButton={false}
        showModal={showModal}
        onHideModal={handleHideModal}
      />

      <PharmacyScreenContainer disableScrollView={false}>
        <View style={styles.content}>
          {displayDataTable ? (
            <SubmissionsDataTable
              onNoSubmissionData={noSubmissionsDataHandler}
            />
          ) : (
            <EmptyState
              image={<NoSubmissionsImage />}
              title={getText('welcome-to-submissions')}
              subtitles={[
                getText(
                  'all-forms-submitted-by-your-patients-will-appear-here',
                ),
                getText('create-new-form-or-upload'),
              ]}
              buttonText={getText('new-form')}
              onButtonPress={handleShowModal}
              buttonLoggerId="new-form"
              bulletPoints={[
                getText('anytime-access-to-your-submissions'),
                getText('easy-managment-without-need-to-print'),
                getText('ability-to-download-single-or-multiple-forms'),
                getText('savings-on-paper-and-toner-materials'),
              ]}
            />
          )}
        </View>
      </PharmacyScreenContainer>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  content: {
    flex: 1,
  },
  title: {
    fontSize: 25,
  },
}));
