import { LoadingIndicator } from 'assets/components/loading-indicator';
import { PencilIcon } from 'assets/icons/PencilIcon';
import { getText } from 'assets/localization/localization';
import { FunctionComponent, useEffect } from 'react';
import { View } from 'react-native';
import { canFactory } from '../../../common/guards/permission-guard';
import SettingsPageLayout from '../../../components/Settings/SettingsPageLayout';
import SettingsSection from '../../../components/Settings/SettingsSection';
import { useAppStateStore } from '../../../store/app-store';
import {
  getAgreements,
  getBranding,
  setPharmacyApp,
  setPharmacyWebsite,
  setShowModalApp,
  setShowModalBrandingInfo,
  setShowModalWebsite,
  setShowPatientAgreementModal,
  setStatus,
} from './app-settings-actions';
import { useAppSettingsState } from './app-settings-store';
import { AgreementsModalWrap } from './components/AgreementsModalWrap';
import { AgreementsRender } from './components/AgreementsRender';
import { AppItemRenderer } from './components/AppItemRenderer';
import { AppModal } from './components/AppModal';
import { BrandingInfoItemRenderer } from './components/BrandingInfoItemRenderer';
import { BrandingModal } from './components/BrandingModal';
import { WebsiteItemRenderer } from './components/WebsiteItemRenderer';
import { WebsiteModal } from './components/WebsiteModal';

export const AppSettings: FunctionComponent = () => {
  const { pharmacyId } = useAppStateStore();
  const {
    branded_colors,
    pharmacyApp,
    pharmacyWebsite,
    patientAgreementsDocs,
    status,
  } = useAppSettingsState();
  const canUpdateSettings = canFactory('update')('settings');
  const canLumistryUserUpdateSettings = canFactory('update')(
    'settingsLumistryUser',
  );
  const canEditAgreements = canFactory('update')('appAgreements');

  const getAppData = async () => {
    await Promise.all([
      getBranding(),
      setPharmacyApp(pharmacyId),
      setPharmacyWebsite(pharmacyId),
      getAgreements(pharmacyId),
    ]);
  };

  useEffect(() => {
    setStatus('loading');
    getAppData().finally(() => setStatus('success'));
  }, [pharmacyId]);
  return (
    <>
      {status === 'success' && (
        <SettingsPageLayout title={getText('app-settings')}>
          {pharmacyApp && (
            <SettingsSection
              title={getText('app')}
              contentHeader={{
                title: getText('details'),
                button: canUpdateSettings
                  ? {
                      title: getText('edit'),
                      icon: PencilIcon,
                      onPress: () => setShowModalApp(true),
                    }
                  : undefined,
              }}
              contentItem={AppItemRenderer}
              customTypeModal={AppModal}
              contentData={[pharmacyApp]}
            />
          )}

          {pharmacyWebsite && (
            <SettingsSection
              title={getText('website')}
              contentHeader={{
                title: getText('details'),
                button: canUpdateSettings
                  ? {
                      title: getText('edit'),
                      icon: PencilIcon,
                      onPress: () => setShowModalWebsite(true),
                    }
                  : undefined,
              }}
              contentItem={WebsiteItemRenderer}
              customTypeModal={WebsiteModal}
              contentData={[pharmacyWebsite]}
            />
          )}

          <SettingsSection
            title={getText('agreements')}
            contentHeader={{
              title: getText('details'),
              button: canEditAgreements
                ? {
                    title: getText('edit'),
                    icon: PencilIcon,
                    onPress: () => setShowPatientAgreementModal(true),
                  }
                : undefined,
            }}
            contentItem={AgreementsRender}
            customTypeModal={AgreementsModalWrap}
            contentData={[patientAgreementsDocs]}
          />

          <SettingsSection
            title={getText('branding')}
            contentHeader={{
              title: getText('pharmacy-general-details'),
              button: canLumistryUserUpdateSettings
                ? {
                    title: getText('edit'),
                    icon: PencilIcon,
                    onPress: () => setShowModalBrandingInfo(true),
                  }
                : undefined,
            }}
            contentItem={BrandingInfoItemRenderer}
            customTypeModal={BrandingModal}
            contentData={[branded_colors]}
          />
        </SettingsPageLayout>
      )}
      {status === 'loading' && (
        <View style={{ marginTop: '25%' }}>
          <LoadingIndicator />
        </View>
      )}
    </>
  );
};
