import { Modal } from 'assets/components/modal';
import { TextField } from 'assets/components/text-field';
import { Form } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import React, { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import {
  setCurrentOpenedLocationId,
  setOpenedModal,
  setWebsiteLocations,
} from '../../../../actions/checklist-actions';
import { useChecklistStore } from '../../../../store/checklist-store';
import { ChecklistModalProps, ChecklistWebsiteModalProps } from '../../types';

export interface WebsiteLocationDescription {
  description: string | undefined;
}

export const PatientWebsiteLocationDescriptionModal: FunctionComponent<
  ChecklistModalProps
> = ({ id }) => {
  const {
    currentOpenedModalId,
    websiteLocations,
    currentOpenedLocationId: locationId,
  } = useChecklistStore();
  const methods = useForm<WebsiteLocationDescription>({
    defaultValues: {},
  });

  const handleSubmit = async () => {
    const description = methods.getValues('description');
    const updatedLocations = websiteLocations?.map((location) =>
      location.id === locationId ? { ...location, description } : location,
    );

    setWebsiteLocations(updatedLocations);
    setOpenedModal('patient-website-locations');
    methods.reset();
  };

  const handleBackButton = () => {
    setOpenedModal('patient-website-locations');
    setCurrentOpenedLocationId(undefined);
    methods.reset();
  };

  const handleDismiss = () => {
    setOpenedModal('checklist-setup');
    setCurrentOpenedLocationId(undefined);
    methods.reset();
  };

  return (
    <Modal
      title={getText('add-location-description')}
      show={currentOpenedModalId === id}
      handleDismissModal={handleDismiss}
      showDismissButton
      isScrollable
      size="sm"
      buttons={[
        {
          hierarchy: 'primary',
          text: getText('next'),
          onPress: methods.handleSubmit(handleSubmit),
          logger: { id: 'next-website-services' },
        },
        {
          text: getText('back'),
          hierarchy: 'tertiary-gray',
          onPress: handleBackButton,
          logger: { id: 'back-website-services' },
        },
      ]}
    >
      <Form methods={methods}>
        <Form.Row>
          <Form.Column>
            <TextField
              name="description"
              type="text"
              label={getText('describe-pharmacy-team')}
              numberOfLines={5}
              multiline
            />
          </Form.Column>
        </Form.Row>
      </Form>
    </Modal>
  );
};
