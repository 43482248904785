import {
  BulkAction,
  BulkActionType,
  useTasksDataTableState,
} from './tasks-data-table-store';
import { logError } from 'assets/logging/logger';
import TaskService from '../../api/TaskService';
import {
  TaskDto,
  TaskPageDto,
  TaskPriority,
  TaskStatus,
  TaskUpdateBulkDto,
  TaskVisibility,
  UpdateTaskDto,
} from '@digitalpharmacist/tasks-service-client-axios';
import { GridApi } from '@ag-grid-community/core';
import { useTaskModalState } from '../task-modal/task-modal-store';
import { useToast } from '../../common/hooks/useToast';
import { pluralize } from '../../common/datetime-utils';
import { useUserState } from '../../store/user-store';
import { setReloadTasksGrid } from '../tasks-grid/tasks-grid-actions';
import { useTasksFiltersState } from '../tasks-filters/tasks-filters-store';

const errorOccurred = (error: any, errorMessage?: string) => {
  const { toast } = useToast();
  const message = errorMessage
    ? errorMessage
    : 'An error occurred while trying to load tasks. Please try again.';

  logError(error);
  useTasksDataTableState.setState({
    error: {
      message: message,
    },
    status: 'error',
    taskStatus: 'error',
  });

  toast('Error', { type: 'error', content: message });
};

export const refreshTasksDataTable = () => {
  const { gridApi } = useTasksDataTableState.getState();
  useTasksDataTableState.setState({ error: undefined, status: 'loading' });
  gridApi?.refreshServerSideStore();
  // Using fixed timeout here to control the loading state, since `refreshServerSideStore` doesn't give us a promise to track
  setTimeout(() => {
    useTasksDataTableState.setState({ status: 'idle' });
  }, 1250);
};

export const updateTaskRowDataTable = (updatedTask: TaskDto) => {
  const { gridApi } = useTasksDataTableState.getState();

  // If the update was resolving a task, we need to refresh the whole table, as this might lead to creation and deletion of recurrent tasks
  if (updatedTask.status == TaskStatus.Resolved) {
    gridApi?.refreshServerSideStore();
  } else {
    // We can just update the particular row in case of other types of updates
    gridApi?.forEachNode((rowNode) => {
      if (updatedTask.id == rowNode.data?.id) {
        // directly update data in rowNode
        rowNode.setData(updatedTask);
      }
    });
  }
};

export const showTaskDetails = async (taskId: string) => {
  useTasksDataTableState.setState({
    detailsStatus: 'loading',
    taskDetails: undefined,
  });

  try {
    const response = await TaskService.findTask(taskId);

    const { taskVisible } = useTasksDataTableState.getState();
    if (taskVisible) {
      useTasksDataTableState.setState({
        detailsStatus: 'idle',
        taskDetails: response,
        isTaskUpdate: false,
      });
    }
  } catch (error) {
    errorOccurred(error);
  }

  return null;
};

export const updateTaskDetails = (task: TaskDto) => {
  useTasksDataTableState.setState({
    taskDetails: task,
  });
};

export const resetTaskDetails = () => {
  useTasksDataTableState.setState({
    taskDetails: undefined,
  });
};

export const setTaskVisibility = async (visibility: boolean = true) => {
  useTasksDataTableState.setState({
    taskVisible: visibility,
  });
};

export const hideTaskDetails = () => {
  const { collapseSidebarMethod, gridApi } = useTasksDataTableState.getState();

  collapseSidebarMethod && collapseSidebarMethod(true);
  useTasksDataTableState.setState({
    isTaskUpdate: false,
  });
  setTimeout(() => {
    gridApi && gridApi.sizeColumnsToFit();
  }, 300);
};

export const editTask = async (taskId: string) => {
  useTaskModalState.setState({
    status: 'loading',
    editTaskData: undefined,
    editingTask: true,
  });

  try {
    const response = await TaskService.findTask(taskId);
    useTaskModalState.setState({
      status: 'idle',
      editTaskData: response,
      showModal: true,
    });
  } catch (error) {
    errorOccurred(error);
  }

  return null;
};

export const persistBulkAction = (action: BulkAction) => {
  useTasksDataTableState.setState({ bulkAction: action });
};

export const removeBulkAction = () => {
  useTasksDataTableState.setState({ bulkAction: undefined });
};

export const persistGridApi = (api: GridApi) => {
  useTasksDataTableState.setState({ gridApi: api });
};

export const persistTaskPage = (page: TaskPageDto) => {
  useTasksDataTableState.setState({ taskPage: page });
};

export const persistCollapseSidebarMethod = (
  collapseSidebar: (collapsed?: boolean) => void,
) => {
  useTasksDataTableState.setState({ collapseSidebarMethod: collapseSidebar });
};

export const setContextMenuTask = (task: TaskDto) => {
  useTasksDataTableState.setState({ contextMenuTaskDetails: task });
};

export const updateTask = async (taskId: string, updateTask: UpdateTaskDto) => {
  useTasksDataTableState.setState({ taskStatus: 'loading' });
  const { toast } = useToast();
  const gridApi = useTasksDataTableState.getState().gridApi;

  if (updateTask.status === TaskStatus.Resolved) {
    const { data: userData } = useUserState.getState();

    updateTask.resolved_by_user_id = userData!.id;
  }

  try {
    gridApi?.showLoadingOverlay();
    const response = await TaskService.updateTask(taskId, updateTask);

    gridApi?.refreshServerSideStore();
    useTasksDataTableState.setState({
      taskDetails: response,
      isTaskUpdate: true,
      taskStatus: 'success',
    });
    toast('Task updated', { type: 'success' });
    setReloadTasksGrid(true);
  } catch (error) {
    errorOccurred(error);
  }

  return null;
};

export const getBulkActionHumanName = (action: BulkAction): string => {
  switch (action.type) {
    case BulkActionType.DELETE:
      return 'delete';
    case BulkActionType.STATUS:
      return 'set the status for';
    case BulkActionType.DUE_DATE:
      return 'set the due date for';
    case BulkActionType.ASSIGNEE:
      return 'set the assignee for';
    case BulkActionType.FLAG:
    case BulkActionType.UNFLAG:
      return 'set';
    default:
      return 'action not supported';
  }
};

const buildBulkActionQuery = () => {
  const filters = useTasksFiltersState.getState().filters;

  return {
    assignedUserId: filters.assigned_user_id,
    status: filters.status,
    flagged: filters.flagged,
    recurring: filters.recurring,
    createdByUserId: filters.created_by_user_id,
    priority: filters.priority,
    dueDate: filters.due_date,
    minDueDate: filters.min_due_date,
    maxDueDate: filters.max_due_date,
    nonResolvedOnly: filters.non_resolved_only,
    taskTypeId: filters.task_type_id,
    visibility: filters.visibility,
    searchTerm: filters.search_term,
  };
};

export const executeBulkAction = async (action: BulkAction): Promise<void> => {
  const { toast } = useToast();
  const gridApi = useTasksDataTableState.getState().gridApi;
  const bulkUpdateQuery = action.isGlobalUpdate
    ? buildBulkActionQuery()
    : undefined;

  const updateBody: TaskUpdateBulkDto = {
    taskIds: action.affectedIds,
  };

  const affectedItemsCount =
    action.affectedIds?.length ?? gridApi?.paginationGetRowCount();

  const { taskDetails } = useTasksDataTableState.getState();

  try {
    removeBulkAction();
    gridApi?.showLoadingOverlay();

    switch (action.type) {
      case BulkActionType.DELETE:
        await TaskService.deleteTasks(action.affectedIds, bulkUpdateQuery);
        toast(
          `${affectedItemsCount} task${pluralize(affectedItemsCount)} deleted.`,
          { type: 'success' },
        );
        break;
      case BulkActionType.ASSIGNEE:
        updateBody.assigned_user_id = action.modifier;
        await TaskService.updateTasks(updateBody, bulkUpdateQuery);
        toast(
          `The assignee for ${affectedItemsCount} task${pluralize(
            affectedItemsCount,
          )} updated to ${action.modifierName}.`,
          { type: 'success' },
        );
        break;
      case BulkActionType.STATUS:
        updateBody.status = action.modifier as TaskStatus;
        await TaskService.updateTasks(updateBody, bulkUpdateQuery);
        toast(
          `The status for ${affectedItemsCount} task${pluralize(
            affectedItemsCount,
          )}  updated to ${action.modifierName}.`,
          { type: 'success' },
        );
        break;
      case BulkActionType.DUE_DATE:
        updateBody.due_date = action.modifier;
        await TaskService.updateTasks(updateBody, bulkUpdateQuery);
        toast(
          `The due date for ${affectedItemsCount} task${pluralize(
            affectedItemsCount,
          )} updated to ${action.modifierName}.`,
          { type: 'success' },
        );
        break;
      case BulkActionType.FLAG:
        updateBody.flagged = true;

        await TaskService.updateTasks(updateBody, bulkUpdateQuery);
        toast(
          `${affectedItemsCount} task${pluralize(
            affectedItemsCount,
          )} updated to flagged.`,
          { type: 'success' },
        );
        break;
      case BulkActionType.UNFLAG:
        updateBody.flagged = false;

        await TaskService.updateTasks(updateBody, bulkUpdateQuery);
        toast(
          `${affectedItemsCount} task${pluralize(
            affectedItemsCount,
          )} updated to not flagged.`,
          { type: 'success' },
        );
        break;
      default:
        gridApi?.refreshServerSideStore();
        return;
    }

    gridApi?.deselectAll();
    !taskDetails && gridApi?.clearFocusedCell();

    gridApi?.refreshServerSideStore();
  } catch (error) {
    errorOccurred(error, 'An error occurred. Please try again.');
    gridApi?.hideOverlay();
  }
};

export interface TasksBulkUpdateOptions {
  assignedUserId?: string;
  status?: TaskStatus;
  flagged?: boolean;
  recurring?: boolean;
  createdByUserId?: string;
  priority?: TaskPriority;
  dueDate?: string;
  minDueDate?: string;
  maxDueDate?: string;
  nonResolvedOnly?: boolean;
  taskTypeId?: string;
  visibility?: TaskVisibility;
  searchTerm?: string;
}
