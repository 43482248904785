import {
  FunctionComponent,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { View } from 'react-native';
import { v4 } from 'uuid';
import {
  GiftedChat,
  GiftedChatProps,
  InputToolbarProps,
  Message,
  MessageProps,
  DayProps,
  IMessage,
} from 'react-native-gifted-chat';
import axios from 'axios';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import { Button } from 'assets/components/button';
import {
  IDocumentModalState,
  IImageModalState,
  LoadMoreOptions,
  TypedMessage,
  IMessageExtended,
  UserTyping,
  RetryContent,
} from 'assets/types/messageTypes';
import { useLoadingState } from '../stores/loading-store/loading-store';
import {
  messageMock,
  LOADING_MESSAGES,
  TAKE,
  INPUT_TOOLBAR_MARGIN,
} from '../data';
import Footer from './Footer';
import { useChatState } from '../stores/chat-store/chat-store';
import {
  getChatSizes,
  IMAGE_VALID_PERIOD,
  stripRichTextEditorElements,
} from 'assets/utils/messageUtils';
import { AuthorType } from '@digitalpharmacist/unified-communications-service-client-axios';
import Bubble from './Bubble';
import TimeStampComponent from './TimeStampComponent';
import { NewMessageBody, ToolBarOptions } from './NewMessageBody';
import ReactQuill from 'react-quill';
import { useForm } from 'react-hook-form';
import ChatDocumentViewer from './ChatDocumentViewer';
import { ImageViewer } from '../../../components/ImageViewer';
import FileStorageService from '../../../api/FileStorageService';
import { LocationCategory } from '@digitalpharmacist/file-storage-service-client-axios';
import { downloadFile } from '../../../common/file-utils';
import { useAppStateStore } from '../../../store/app-store';

let UPDATE_IMAGES_LINKS_TIMEOUT: number;

export const ChatBox: FunctionComponent<ChatBoxProps> = (props) => {
  const locationId = useAppStateStore((state) => state.locationId);
  const pharmacyId = useAppStateStore((state) => state.pharmacyId);
  const messagesPagination = useChatState((state) => state.messagesPagination);
  const chatBoxText = useChatState((state) => state.chatBoxText);
  const selectedConversation = useChatState(
    (state) => state.selectedConversation,
  );
  const [isSending, setIsSending] = useState<boolean>(false);
  const [quillRef, setQuillRef] = useState<ReactQuill | null>(null);
  const [imageModalState, setImageModalState] = useState<IImageModalState>({
    show: false,
    uri: '',
    stored_filename: '',
  });
  const [documentModalState, setDocumentModalState] =
    useState<IDocumentModalState>({
      show: false,
      stored_filename: '',
      name: '',
    });
  const [forceUpdateImages, setForceUpdateImages] = useState(0);
  const isMessageLoading = useLoadingState((state) =>
    LOADING_MESSAGES in state.loadingObject
      ? state.loadingObject[LOADING_MESSAGES].isLoading
      : false,
  );

  const styles = useStyles();
  const theme = useTheme();

  const methods = useForm({
    defaultValues: {
      content_chatbox: '',
      attachments: [],
    },
  });

  // TODO: Re-updating text for message in ChatBox only for re-rendering that component and children.
  // This the only way to force re-rendering that component to show sending message with attachment in UI.
  // I did not find another way to fix this.
  useEffect(() => {
    methods.setValue('content_chatbox', chatBoxText);
  }, [chatBoxText]);

  const lastRightMessageId = useMemo(() => {
    const messages = props.messages ?? [];

    return messages.find(
      (message) => message.author_type === AuthorType.Pharmacy,
    )?._id;
  }, [props.messages]);

  const handleQuillRef = (quillRef: ReactQuill) => {
    setQuillRef(quillRef);
  };

  const {
    wholeContainerHeight,
    messagesContainerHeight,
    textInputToolbarHeight,
  } = getChatSizes(
    methods.watch('content_chatbox'),
    methods.watch('attachments').length,
  );

  const getMessages = () => {
    if (isMessageLoading) {
      return [messageMock];
    }

    return props.messages;
  };
  const messages = getMessages();

  const handleOnPress = useCallback(
    async (retryContent?: RetryContent) => {
      const user = props.user;
      const textToSend = stripRichTextEditorElements(
        retryContent?.text || methods.watch('content_chatbox'),
      );
      const attachmentsToSend =
        retryContent?.attachments || methods.watch('attachments');

      // saving values and resetting the state - fixes couples bugs
      // (1. clearing the data right after the sending - this does not allow to send couple messages if you click many times
      //  2. making async sending - this allows to indicate "sending" status of attachments in the chat)
      methods.reset();

      if (user) {
        setIsSending(true);
        await props.onSendMessage({
          text: textToSend,
          user,
          attachments: attachmentsToSend,
          retryMessageId: retryContent?.id,
        });

        setIsSending(false);
      }
    },
    [
      props.user,
      methods.watch('content_chatbox'),
      props.onSendMessage,
      methods.watch('attachments'),
      setIsSending,
    ],
  );

  const renderBubble = useCallback(
    (args: any) => (
      <Bubble
        args={args}
        loading={isMessageLoading}
        lastRightMessageId={lastRightMessageId}
        setDocumentModalState={setDocumentModalState}
        setImageModalState={setImageModalState}
        forceUpdate={forceUpdateImages}
        isSending={isSending}
        handleMessageSend={handleOnPress}
      />
    ),
    [
      isMessageLoading,
      lastRightMessageId,
      forceUpdateImages,
      isSending,
      handleOnPress,
      selectedConversation,
    ],
  );

  const renderSend = useCallback(() => {
    return (
      <Button
        hierarchy="primary"
        size="small"
        logger={{ id: 'send-message' }}
        style={styles.button}
        onPress={handleOnPress}
      >
        {getText('send')}
      </Button>
    );
  }, [handleOnPress]);

  const renderFooter = useCallback(() => {
    return (
      <Footer
        typingMember={props.typingMember}
        text={stripRichTextEditorElements(methods.watch('content_chatbox'))}
        conversationId={props.conversationId}
      />
    );
  }, [
    props.typingMember,
    props.conversationId,
    methods.watch('content_chatbox'),
  ]);

  const renderMessage = useCallback((props: MessageProps<IMessageExtended>) => {
    if (!props.currentMessage) {
      return null;
    }

    return (
      <Message
        {...props}
        position={
          props.currentMessage.author_type === AuthorType.Pharmacy ||
          props.currentMessage.author_type === AuthorType.SystemGenerated
            ? 'right'
            : 'left'
        }
      />
    );
  }, []);

  const getLoadEarlier = (): boolean => {
    if (!messages || !messages.length) {
      return false;
    } else if (messages.length < props.messagesCount && !isMessageLoading) {
      return true;
    }
    return false;
  };

  const onEndReached = async () => {
    if (messagesPagination.take >= messagesPagination.count) {
      return;
    }

    const take = messagesPagination.take + TAKE;
    const skip = 0;
    await props.loadMore({ skip, take: take });
  };

  const renderInputToolbar = useCallback(
    (inputProps: InputToolbarProps<IMessageExtended>) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars -- destructed to get `restProps` without `containerStyle` to pass `ActionsProps` type for `renderActions(...)`
      const { containerStyle, ...restProps } = inputProps;
      return (
        <>
          <View
            style={{
              borderBottomWidth: 1,
              borderBottomColor: theme.palette.gray[200],
            }}
          ></View>
          <View
            style={{
              flexDirection: 'column',
              width: '98%',
              margin: theme.getSpacing(1),
            }}
          >
            <NewMessageBody
              methods={methods}
              handleQuillRef={handleQuillRef}
              toolbarId="toolbar-chatbox"
              content="content_chatbox"
              height={textInputToolbarHeight}
              placeholder={getText('message')}
              toolbarOptions={[
                ToolBarOptions.Variables,
                ToolBarOptions.Attachments,
                ToolBarOptions.Emojis,
                ToolBarOptions.Links,
              ]}
            />
          </View>
        </>
      );
    },
    [methods.watch('content_chatbox')],
  );

  const renderDay = useCallback(
    (args: DayProps<IMessage>) => <TimeStampComponent args={args} />,
    [],
  );

  const onScroll = () => {
    if (!UPDATE_IMAGES_LINKS_TIMEOUT) {
      setForceUpdateImages((prev) => prev + 1);

      UPDATE_IMAGES_LINKS_TIMEOUT = window.setTimeout(() => {
        window.clearTimeout(UPDATE_IMAGES_LINKS_TIMEOUT);
      }, IMAGE_VALID_PERIOD);
    }
  };

  const downloadAttachment = async (storedFilename: string) => {
    try {
      const urlResponse = await FileStorageService.readUrl(
        LocationCategory.DirectMessage,
        locationId,
        storedFilename,
        pharmacyId,
      );
      const url: string = urlResponse.data.url;

      const response = await axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
      });
      downloadFile(response.data, storedFilename);
    } catch (error) {
      console.error('An error occurred downloading file: ', error);
    }
  };

  return (
    <View style={[styles.chatWrapper, { maxHeight: wholeContainerHeight }]}>
      <GiftedChat
        {...props}
        renderAvatarOnTop={true}
        messages={messages}
        text={methods.watch('content_chatbox')}
        renderMessage={renderMessage}
        renderBubble={renderBubble}
        shouldUpdateMessage={(props, nextProps) => {
          return props.currentMessage !== nextProps.currentMessage;
        }}
        messagesContainerStyle={{ height: messagesContainerHeight }}
        renderInputToolbar={renderInputToolbar}
        renderFooter={renderFooter}
        renderDay={renderDay}
        loadEarlier={getLoadEarlier()}
        listViewProps={{
          disableVirtualization: true, // TODO: might be a temporary solution, because it is a deprecated property in FlatList
          onEndReached: onEndReached,
          onEndReachedThreshold: 0.7,
          onScroll: onScroll,
          scrollEventThrottle: 500,
        }}
      />
      <ChatDocumentViewer
        chatDocumentViewerState={documentModalState}
        setChatDocumentViewerState={setDocumentModalState}
        downloadAttachment={downloadAttachment}
      />
      <ImageViewer
        title={getText('message')}
        show={imageModalState.show}
        frontImageUrl={imageModalState.uri}
        okButtonPress={() =>
          setImageModalState({ show: false, uri: '', stored_filename: '' })
        }
      />
      <View
        style={{
          flexDirection: 'row-reverse',
          justifyContent: 'space-between',
          position: 'absolute',
          top: 440,
          right: 10,
          height: 36,
          marginBottom: INPUT_TOOLBAR_MARGIN,
        }}
      >
        {renderSend()}
      </View>
    </View>
  );
};

export interface ChatBoxProps extends GiftedChatProps {
  messages: IMessageExtended[];
  typingMember: UserTyping | null;
  messagesCount: number;
  conversationId: string;
  onTyping: () => void;
  onSendMessage: (typedMessage: TypedMessage) => Promise<void>;
  loadMore: (options: LoadMoreOptions) => Promise<void>;
}

ChatBox.defaultProps = {
  placeholder: getText('type-your-message'),
  alwaysShowSend: true,
  showUserAvatar: true,
  scrollToBottom: false,
  messageIdGenerator: () => v4(),
};

const useStyles = makeStyles((theme) => ({
  image: {
    height: 100,
    width: 100,
    zIndex: 1,
  },
  imagesContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  fileWrapper: {
    color: theme.palette.cyan[200],
  },
  wrapper: {
    margin: theme.getSpacing(1),
  },
  chatWrapper: {
    maxHeight: 480,
    width: '100%',
    height: '100%',
  },
  button: {
    borderColor: 'transparent',
    marginRight: 4,
    width: 60,
  },
}));
