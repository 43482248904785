export const calculateGridHeight = (rowNum: number) => {
  const rowHeight = 48;
  const headerHeight = 57;
  const scrollContainerHeight = 17;
  const spacingAround = 16;

  return (
    rowNum * rowHeight + headerHeight + scrollContainerHeight + spacingAround
  );
};
