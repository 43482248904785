import { FC } from 'react';
import { View } from 'react-native';
import theme, { makeStyles } from 'assets/theme';
import { Avatar } from 'assets/components/avatar';
import { RecipientRendererProps } from '../../../types';
import { Text } from 'assets/components/text';
import { getText } from 'assets/localization/localization';
import { BulkStatusRecipientDto } from '@digitalpharmacist/unified-communications-service-client-axios';
import { RecipientsRenderersProps } from './types';

export const NameRenderer: FC<RecipientsRenderersProps> = ({ data }) => {
  const styles = useStyles();
  if (!data) {
    return <></>;
  }

  const fullName = `${data?.first_name ?? ''} ${data?.last_name ?? ''}`;

  return (
    <View style={styles.container}>
      <Avatar
        firstName={data?.first_name ?? ''}
        lastName={data?.last_name ?? ''}
        color={theme.palette.gray[100]}
        size={32}
      />
      <Text style={styles.text}>
        {fullName.length === 1 ? getText('no-name-data') : fullName}
      </Text>
    </View>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },
  text: {
    marginLeft: theme.getSpacing(1),
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.gray[700],
  },
}));
