import { Modal } from 'assets/components/modal';
import { getText } from 'assets/localization/localization';
import {
  Component,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Text, View } from 'react-native';
import { Form } from 'assets/layout/form/Form';
import { useForm } from 'react-hook-form';
import { ToggleSwitch } from 'assets/components/toggle-switch';
import { makeStyles } from 'assets/theme';
import { CheckboxField, CheckboxInputMode } from 'assets/components/checkbox';
import {
  AutomationDescriptionLocalization,
  AutomationTitleLocalization,
  ConfigurationFieldKeysEnum,
  ConfigurationFieldKeysLocalization,
} from '../utils';
import { LoadingOverlay } from '../../../components/LoadingOverlay';
import { useAutomationsState } from '../automations-store';
import { useAutomationModalState } from './automation-modal-store';
import { updateShowModalState } from './automation-modal-actions';
import { updateAutomation } from '../automations-actions';
import {
  AutomationDto,
  ConfigurationFieldDto,
} from '@digitalpharmacist/tasks-service-client-axios';
import { useRefillsSettingsState } from '../../../screens/settings/refills/refills-settings-store';
import { setServiceOptions } from '../../../screens/settings/refills/refills-settings-actions';
import { useAppStateStore } from '../../../store/app-store';

const AutomationModal: FunctionComponent = () => {
  const styles = useStyles();
  const { showModal, automationId } = useAutomationModalState();
  const { automations, status } = useAutomationsState();
  const automation = useMemo(
    (): AutomationDto | undefined => automations[automationId],
    [automations, automationId],
  );
  const [isEnabled, setIsEnabled] = useState(false);
  const { locationId } = useAppStateStore();
  const { allServiceOptions } = useRefillsSettingsState();
  useEffect(() => {
    setServiceOptions(locationId);
  }, [locationId]);

  const methods = useForm({
    defaultValues: {},
  });

  const defaultFormValues = useMemo(() => {
    if (automation === undefined) {
      return {};
    }

    const values: Record<string, object> = {};
    automation.configuration?.fields.forEach((field) => {
      values[field.key] = field.value;
    });

    return values;
  }, [automation]);

  useEffect(() => {
    methods.reset(defaultFormValues);
  }, [defaultFormValues]);

  useEffect(() => {
    if (automation !== undefined) {
      setIsEnabled(automation.enabled);
    }
  }, [automation, setIsEnabled]);

  const togglePressHandler = useCallback(
    async (value: boolean) => {
      setIsEnabled(value);
    },
    [setIsEnabled],
  );

  const handleSubmitHandler = useCallback(async () => {
    if (automation === undefined) {
      return;
    }

    const newConfigValues: Record<
      string,
      string | number | boolean | undefined
    > = methods.getValues();
    const updatedConfiguration = Object.keys(newConfigValues).map((key) => ({
      key: key,
      type: 'checkbox',
      value: !!newConfigValues[key],
    }));
    await updateAutomation(automation.id, {
      enabled: isEnabled,
      configuration: {
        fields: updatedConfiguration,
      },
    });
    onDismiss();
  }, [automation, isEnabled]);

  const onDismiss = useCallback(() => {
    updateShowModalState(false);
  }, []);

  const displayConfigurationFields = useCallback(() => {
    if (automation === undefined) {
      return <></>;
    }

    const medSyncOptions = allServiceOptions.find(
      (serviceOption) => serviceOption.slug === 'med-sync',
    )?.options;
    const medSyncDisabled = medSyncOptions?.find(
      (option) => option.selected === false,
    );

    const configurationFieldsJsx: React.JSX.Element[] = [];
    automation.configuration?.fields.forEach((field: ConfigurationFieldDto) => {
      if (
        medSyncDisabled?.selected === false &&
        (field.key === ConfigurationFieldKeysEnum.MedSync ||
          field.key === ConfigurationFieldKeysEnum.EasyOpenBottleCaps)
      ) {
        return;
      }

      configurationFieldsJsx.push(
        <Form.Row key={field.key}>
          <Form.Column>
            <CheckboxField
              label={
                ConfigurationFieldKeysLocalization[
                  field.key as ConfigurationFieldKeysEnum
                ]
              }
              name={field.key}
              mode={CheckboxInputMode.FLAT}
            />
          </Form.Column>
        </Form.Row>,
      );
    });

    return configurationFieldsJsx;
  }, [automation, allServiceOptions]);

  return (
    <Modal
      title={`${getText('configure')} ${
        automation !== undefined
          ? AutomationTitleLocalization[automation.type]
          : ''
      }`}
      cancelButtonProps={{
        onPress: onDismiss,
        logger: { id: 'automation-cancel-button' },
        mode: 'text',
      }}
      okButtonProps={{
        onPress: methods.handleSubmit(handleSubmitHandler),
        logger: { id: 'edit-automation-button' },
        text: getText('ok'),
      }}
      show={showModal}
      size="sm"
      isScrollable={true}
    >
      {automation !== undefined ? (
        <View style={styles.container}>
          {status === 'loading' && <LoadingOverlay />}
          <ToggleSwitch
            logger={{
              id: `enable-automation-modal`,
            }}
            value={isEnabled}
            onPress={togglePressHandler}
          />
          <Text style={styles.description}>
            {AutomationDescriptionLocalization[automation.type]}
          </Text>
          <Form methods={methods}>{displayConfigurationFields()}</Form>
        </View>
      ) : (
        <></>
      )}
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: -theme.getSpacing(1),
  },
  description: {
    ...theme.lumistryFonts.text.medium.regular,
    textAlign: 'left',
    color: theme.palette.gray[700],
    marginTop: theme.getSpacing(2),
    marginBottom: theme.getSpacing(4),
  },
}));

export default AutomationModal;
