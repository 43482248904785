import React, { FunctionComponent } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import { makeStyles, useTheme } from 'assets/theme';
import { Icon } from 'assets/components/icon';
import { Text } from 'assets/components/text';

export const OBContentHeader: FunctionComponent<OBHeaderProps> = ({
  icon,
  title,
  subtitle,
  description,
  iconHeight,
  style,
}) => {
  const styles = useStyles();
  const theme = useTheme();

  return (
    <View style={styles.root}>
      <View style={style ? [styles.container, style] : styles.container}>
        {icon && (
          <View style={[styles.iconContainer, { height: iconHeight }]}>
            <Icon icon={icon} size={100} color={theme.colors.brandedPrimary} />
          </View>
        )}
        <View style={styles.titleContainer}>
          <Text style={styles.title}>{title}</Text>
        </View>
        {subtitle && (
          <View style={styles.subtitleContainer}>
            <Text style={styles.subtitle}>{subtitle}</Text>
          </View>
        )}
        <View>
          <Text style={styles.description}>{description}</Text>
        </View>
      </View>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    marginTop: theme.getSpacing(1),
  },
  container: {
    alignItems: 'center',
    width: 350,
    marginBottom: theme.getSpacing(3),
  },
  iconContainer: {
    height: 100,
    width: 100,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.getSpacing(4),
  },
  titleContainer: {
    marginBottom: theme.getSpacing(1),
  },
  subtitleContainer: {
    marginBottom: theme.getSpacing(1),
  },
  title: {
    ...theme.lumistryFonts.display,
    color: theme.palette.gray[900],
  },
  subtitle: {
    ...theme.lumistryFonts.label.medium,
    color: theme.palette.gray[700],
    textAlign: 'center',
  },
  description: {
    ...theme.lumistryFonts.text.large.regular,
    color: theme.palette.gray[700],
    textAlign: 'center',
  },
}));

export interface OBHeaderProps {
  icon?: FunctionComponent;
  title: string;
  description: string;
  iconHeight?: number;
  subtitle?: string;
  style?: StyleProp<ViewStyle>;
}
