import React, { FunctionComponent } from 'react';
import { Svg, Path } from 'react-native-svg';
import { IconProps } from './types';

export const BarChartIcon: FunctionComponent<IconProps> = ({
  color,
  size,
  testID,
}) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 10 14"
      fill="none"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
      testID={testID}
    >
      <Path d="M9 12.3337V5.66699M5 12.3337V1.66699M1 12.3337V8.33366" />
    </Svg>
  );
};
