import {
  AgeFilterDto,
  AgeFilterType,
  BulkMessageType,
  PostalCodeFilterDto,
  PostalCodeFilterType,
} from '@digitalpharmacist/unified-communications-service-client-axios';
import { SortModelItem } from '@ag-grid-community/core';
import {
  Gender,
  LocationPatientRecordSearchSortDto,
} from '@digitalpharmacist/patient-service-client-axios';
import {
  BulkMessageForm,
  DynamicInputValue,
  ETo,
} from '../common/types/MessageForm';
import { stripRichTextEditorElements } from 'assets/utils/messageUtils';
import { getText } from 'assets/localization/localization';
import { BIG_MESSAGE_LIMIT, SMS_MESSAGE_LIMIT } from '../data';
import { getDynamicFieldName } from './components/BulkFilterInput';
import { UseFormReturn } from 'react-hook-form';

interface ValidateFieldsData {
  data: BulkMessageForm;
  methods: UseFormReturn<BulkMessageForm, any>;
  filterInputs: {}[];
  setErrorFields: (data: Record<string, string>) => void;
}
export const validateFields = (args: ValidateFieldsData) => {
  const { data, methods, filterInputs, setErrorFields } = args;
  let invalidFields: Record<string, string> = {};
  const strippedMessage = stripRichTextEditorElements(data.content);
  const { filtersIsOneOf, filtersIsNotOneOf } = getPostalCodeFilter(data);
  methods.clearErrors();

  if (!data.subject) {
    invalidFields = {
      ...invalidFields,
      subject: `${getText('subject-is-required')}.`,
    };
    // TODO: will be uncommented when we fix error styles and behavior for this field. The errors are shown anyway
    // methods.setError('subject', {
    //   type: 'manual',
    //   message: invalidFields.subject,
    // });
  }
  if (data.message_type === BulkMessageType.Secure) {
    if (!strippedMessage) {
      invalidFields = {
        ...invalidFields,
        content: `${getText('message-body-is-required')}.`,
      };
      // TODO: will be uncommented when we fix error styles and behavior for this field. The errors are shown anyway
      // methods.setError('content', {
      //   type: 'manual',
      //   message: invalidFields.content,
      // });
    }
    if (strippedMessage && strippedMessage.length > BIG_MESSAGE_LIMIT) {
      invalidFields = {
        ...invalidFields,
        content: `${getText('message-characters-limit-five-thousand')}.`,
      };
      // TODO: will be uncommented when we fix error styles and behavior for this field. The errors are shown anyway
      // methods.setError('content', {
      //   type: 'manual',
      //   message: invalidFields.content,
      // });
    }
  } else {
    if (data.content_email) {
      const strippedEmailMessage = stripRichTextEditorElements(
        data.content_email,
      );

      if (!strippedEmailMessage) {
        invalidFields = {
          ...invalidFields,
          content_email: getText('message-body-is-required'),
        };
      } else if (strippedEmailMessage.length > BIG_MESSAGE_LIMIT) {
        invalidFields = {
          ...invalidFields,
          content_email: getText('message-characters-limit-five-thousand'),
        };
      }
    } else {
      invalidFields = {
        ...invalidFields,
        content_email: getText('message-body-is-required'),
      };
    }

    if (data.content_sms) {
      const strippedEmailMessage = stripRichTextEditorElements(
        data.content_sms,
      );

      if (!strippedEmailMessage) {
        invalidFields = {
          ...invalidFields,
          content_sms: getText('message-body-is-required'),
        };
      } else if (strippedEmailMessage.length > SMS_MESSAGE_LIMIT) {
        invalidFields = {
          ...invalidFields,
          content_sms: getText('message-characters-limit-160'),
        };
      }
    } else {
      invalidFields = {
        ...invalidFields,
        content_sms: getText('message-body-is-required'),
      };
    }
  }

  // History_of_empty_string_filter: if the user selects one filter from `BulkFilterInput` in the specific input,
  // the filter name appears in `methods` with undefined value (for example, age_is: undefined).
  // After that user can re-select another filter (for example, age_is_one_of: undefined) in the same input,
  // and another filter name appears in the `methods`. Despite the first filter has value `undefined`,
  // the key is still in `method`, and that's the problem,
  // because we need to define here what filter still exists in UI,
  // but without value, and what filter does not exist anymore in UI,
  // but it is present in `methods` with value `undefined`.
  // To distinguish these cases, we track each changes of the filter inputs
  // and assign an empty string to filters that are present in UI,
  // but they do not have value yet. Tracking occurs in useEffect in `BulkFilterInput`.
  if (
    DynamicInputValue.AGE_IS in data &&
    data[DynamicInputValue.AGE_IS] === ''
  ) {
    invalidFields = {
      ...invalidFields,
      [DynamicInputValue.AGE_IS]: getText('age-is-required-field'),
    };
    methods.setError(DynamicInputValue.AGE_IS, {
      type: 'manual',
      message: invalidFields[DynamicInputValue.AGE_IS],
    });
  }
  if (
    DynamicInputValue.AGE_IS in data &&
    !validateAge(data[DynamicInputValue.AGE_IS])
  ) {
    invalidFields = {
      ...invalidFields,
      [DynamicInputValue.AGE_IS]: getText('entered-age-is-invalid'),
    };
    methods.setError(DynamicInputValue.AGE_IS, {
      type: 'manual',
      message: invalidFields[DynamicInputValue.AGE_IS],
    });
  }
  // To find out why here `data[DynamicInputValue.AGE_IS_NOT] === ''`
  // instead of just checking if the value is undefined or not,
  // read comment above by keyword History_of_empty_string_filter
  if (
    DynamicInputValue.AGE_IS_NOT in data &&
    data[DynamicInputValue.AGE_IS_NOT] === ''
  ) {
    invalidFields = {
      ...invalidFields,
      [DynamicInputValue.AGE_IS_NOT]: getText('age-is-required-field'),
    };
    methods.setError(DynamicInputValue.AGE_IS_NOT, {
      type: 'manual',
      message: invalidFields[DynamicInputValue.AGE_IS_NOT],
    });
  }
  if (
    DynamicInputValue.AGE_IS_NOT in data &&
    !validateAge(data[DynamicInputValue.AGE_IS_NOT])
  ) {
    invalidFields = {
      ...invalidFields,
      [DynamicInputValue.AGE_IS_NOT]: getText('entered-age-is-invalid'),
    };
    methods.setError(DynamicInputValue.AGE_IS_NOT, {
      type: 'manual',
      message: invalidFields[DynamicInputValue.AGE_IS_NOT],
    });
  }
  // To find out why here `data[DynamicInputValue.AGE_IS_NOT] === ''`
  // instead of just checking if the value is undefined or not,
  // read comment above by keyword History_of_empty_string_filter
  if (
    DynamicInputValue.AGE_IS_OLDER_THAN in data &&
    data[DynamicInputValue.AGE_IS_OLDER_THAN] === ''
  ) {
    invalidFields = {
      ...invalidFields,
      [DynamicInputValue.AGE_IS_OLDER_THAN]: getText('age-is-required-field'),
    };
    methods.setError(DynamicInputValue.AGE_IS_OLDER_THAN, {
      type: 'manual',
      message: invalidFields[DynamicInputValue.AGE_IS_OLDER_THAN],
    });
  }
  if (
    DynamicInputValue.AGE_IS_OLDER_THAN in data &&
    !validateAge(data[DynamicInputValue.AGE_IS_OLDER_THAN])
  ) {
    invalidFields = {
      ...invalidFields,
      [DynamicInputValue.AGE_IS_OLDER_THAN]: getText('entered-age-is-invalid'),
    };
    methods.setError(DynamicInputValue.AGE_IS_OLDER_THAN, {
      type: 'manual',
      message: invalidFields[DynamicInputValue.AGE_IS_OLDER_THAN],
    });
  }
  // To find out why here `data[DynamicInputValue.AGE_IS_NOT] === ''`
  // instead of just checking if the value is undefined or not,
  // read comment above by keyword History_of_empty_string_filter
  if (
    DynamicInputValue.AGE_IS_YOUNGER_THAN in data &&
    data[DynamicInputValue.AGE_IS_YOUNGER_THAN] === ''
  ) {
    invalidFields = {
      ...invalidFields,
      [DynamicInputValue.AGE_IS_YOUNGER_THAN]: getText('age-is-required-field'),
    };
    methods.setError(DynamicInputValue.AGE_IS_YOUNGER_THAN, {
      type: 'manual',
      message: invalidFields[DynamicInputValue.AGE_IS_YOUNGER_THAN],
    });
  }
  if (
    DynamicInputValue.AGE_IS_YOUNGER_THAN in data &&
    !validateAge(data[DynamicInputValue.AGE_IS_YOUNGER_THAN])
  ) {
    invalidFields = {
      ...invalidFields,
      [DynamicInputValue.AGE_IS_YOUNGER_THAN]: getText(
        'entered-age-is-invalid',
      ),
    };
    methods.setError(DynamicInputValue.AGE_IS_YOUNGER_THAN, {
      type: 'manual',
      message: invalidFields[DynamicInputValue.AGE_IS_YOUNGER_THAN],
    });
  }
  // To find out why here `data[DynamicInputValue.AGE_IS_NOT] === ''`
  // instead of just checking if the value is undefined or not,
  // read comment above by keyword History_of_empty_string_filter
  if (
    DynamicInputValue.ZIP_CODE_IS_NOT_ONE_OF in data &&
    data[DynamicInputValue.ZIP_CODE_IS_NOT_ONE_OF] === ''
  ) {
    invalidFields = {
      ...invalidFields,
      [DynamicInputValue.ZIP_CODE_IS_NOT_ONE_OF]: getText(
        'zip-code-is-required-field',
      ),
    };
    methods.setError(DynamicInputValue.ZIP_CODE_IS_NOT_ONE_OF, {
      type: 'manual',
      message: invalidFields[DynamicInputValue.ZIP_CODE_IS_NOT_ONE_OF],
    });
  }
  if (
    DynamicInputValue.ZIP_CODE_IS_NOT_ONE_OF in data &&
    filtersIsNotOneOf.length
  ) {
    const firstInvalidField = filtersIsNotOneOf.find(
      (filter) => !validatePostalCode(String(filter.value)),
    );

    if (firstInvalidField) {
      invalidFields = {
        ...invalidFields,
        [DynamicInputValue.ZIP_CODE_IS_NOT_ONE_OF]: getText(
          'one-or-more-invalid-zip-codes-entered',
        ),
      };
      methods.setError(DynamicInputValue.ZIP_CODE_IS_NOT_ONE_OF, {
        type: 'manual',
        message: invalidFields[DynamicInputValue.ZIP_CODE_IS_NOT_ONE_OF],
      });
    }
  }
  // To find out why here `data[DynamicInputValue.AGE_IS_NOT] === ''`
  // instead of just checking if the value is undefined or not,
  // read comment above by keyword History_of_empty_string_filter
  if (
    DynamicInputValue.ZIP_CODE_IS_ONE_OF in data &&
    data[DynamicInputValue.ZIP_CODE_IS_ONE_OF] === ''
  ) {
    invalidFields = {
      ...invalidFields,
      [DynamicInputValue.ZIP_CODE_IS_ONE_OF]: getText(
        'zip-code-is-required-field',
      ),
    };
    methods.setError(DynamicInputValue.ZIP_CODE_IS_ONE_OF, {
      type: 'manual',
      message: invalidFields[DynamicInputValue.ZIP_CODE_IS_ONE_OF],
    });
  }
  if (DynamicInputValue.ZIP_CODE_IS_ONE_OF in data && filtersIsOneOf.length) {
    const firstInvalidField = filtersIsOneOf.find(
      (filter) => !validatePostalCode(String(filter.value)),
    );

    if (firstInvalidField) {
      invalidFields = {
        ...invalidFields,
        [DynamicInputValue.ZIP_CODE_IS_ONE_OF]: getText(
          'one-or-more-invalid-zip-codes-entered',
        ),
      };
      methods.setError(DynamicInputValue.ZIP_CODE_IS_ONE_OF, {
        type: 'manual',
        message: invalidFields[DynamicInputValue.ZIP_CODE_IS_ONE_OF],
      });
    }
  }

  // checking of filter inputs
  if (methods.watch('to') === ETo.TO_FILTERED_LIST) {
    const filterOption = getDynamicFieldName(0);
    if (filterOption in data && !data[filterOption]) {
      methods.setError(filterOption, {
        type: 'manual',
        message: getText('filter-is-required-field'),
      });
      invalidFields = {
        ...invalidFields,
        [filterOption]: getText('filter-is-required-field'),
      };
    }
  }

  if (data.to === ETo.TO_SPECIFIC) {
    if (data['specific_recipients'] === undefined) {
      invalidFields = {
        ...invalidFields,
        specific_recipients: getText('at-least-one-recipient-is-required'),
      };
    }
  }

  if (data.time) {
    if (!data['scheduled_time']) {
      methods.setError('scheduled_time', {
        type: 'manual',
        message: getText('date-is-required'),
      });
      invalidFields = {
        ...invalidFields,
        scheduled_time: getText('date-is-required'),
      };
    }
  }

  setErrorFields(invalidFields);
  return Object.keys(invalidFields).length;
};

export function getGenderFilter(bulkData: BulkMessageForm): Gender[] {
  let genderList: Gender[] = [];
  for (const key in bulkData) {
    if (bulkData[key] == DynamicInputValue.GENDER_IS_MALE) {
      genderList.push(Gender.Male);
    }
    if (bulkData[key] == DynamicInputValue.GENDER_IS_FEMALE) {
      genderList.push(Gender.Female);
    }
  }
  return genderList;
}

export function getAgeFilter(
  bulkData: BulkMessageForm,
): AgeFilterDto[] | undefined {
  const filters: AgeFilterDto[] = [];

  if (bulkData[DynamicInputValue.AGE_IS]) {
    filters.push({
      type: AgeFilterType.IsAge,
      value: Number(bulkData[DynamicInputValue.AGE_IS]),
    });
  }
  if (bulkData[DynamicInputValue.AGE_IS_NOT]) {
    filters.push({
      type: AgeFilterType.IsNotAge,
      value: Number(bulkData[DynamicInputValue.AGE_IS_NOT]),
    });
  }
  if (bulkData[DynamicInputValue.AGE_IS_OLDER_THAN]) {
    filters.push({
      type: AgeFilterType.OlderThan,
      value: Number(bulkData[DynamicInputValue.AGE_IS_OLDER_THAN]),
    });
  }
  if (bulkData[DynamicInputValue.AGE_IS_YOUNGER_THAN]) {
    filters.push({
      type: AgeFilterType.YoungerThan,
      value: Number(bulkData[DynamicInputValue.AGE_IS_YOUNGER_THAN]),
    });
  }

  if (filters.length) {
    return filters;
  }

  return undefined;
}

interface GetPostalCodeFilterResult {
  filters: PostalCodeFilterDto[];
  filtersIsOneOf: PostalCodeFilterDto[];
  filtersIsNotOneOf: PostalCodeFilterDto[];
}
export function getPostalCodeFilter(
  bulkData: BulkMessageForm,
): GetPostalCodeFilterResult {
  const filters: PostalCodeFilterDto[] = [];
  const filtersIsOneOf: PostalCodeFilterDto[] = [];
  const filtersIsNotOneOf: PostalCodeFilterDto[] = [];
  const regexAllSpaces = new RegExp('\\s+', 'g');

  if (bulkData[DynamicInputValue.ZIP_CODE_IS_ONE_OF]) {
    const allZipCodesString = bulkData[
      DynamicInputValue.ZIP_CODE_IS_ONE_OF
    ].replaceAll(regexAllSpaces, '');
    const zipCodes = allZipCodesString.split(',');
    for (const zipCode of zipCodes) {
      const code = {
        type: PostalCodeFilterType.OneOf,
        value: zipCode,
      };
      filters.push(code);
      filtersIsOneOf.push(code);
    }
  }
  if (bulkData[DynamicInputValue.ZIP_CODE_IS_NOT_ONE_OF]) {
    const allZipCodesString = bulkData[
      DynamicInputValue.ZIP_CODE_IS_NOT_ONE_OF
    ].replaceAll(regexAllSpaces, '');
    const zipCodes = allZipCodesString.split(',');
    for (const zipCode of zipCodes) {
      const code = {
        type: PostalCodeFilterType.NotOneOf,
        value: zipCode,
      };
      filters.push(code);
      filtersIsNotOneOf.push(code);
    }
  }

  return { filters, filtersIsOneOf, filtersIsNotOneOf };
}

export function validatePostalCode(postalCode: string, countryCode = 'US') {
  const postalCodePatterns: { [countryCode: string]: RegExp } = {
    // US: /^[0-9]{5}(-[0-9]{4})?$/,             // USA: 12345 or 12345-6789
    US: /^[0-9]{5}$/, // USA: 12345
    CA: /^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d$/, // Canada: A1A 1A1 or A1A1A1
  };

  const pattern = postalCodePatterns[countryCode];
  if (!pattern) {
    return false;
  }

  return pattern.test(postalCode);
}

function validateAge(age: string) {
  if (!age) {
    return true;
  }
  const ageRegExp = new RegExp('^[1-9][0-9]{0,2}$', 'i');
  if (ageRegExp.test(age.trim())) {
    return true;
  } else {
    return false;
  }
}

export function buildSort(
  sortModel: SortModelItem[],
): LocationPatientRecordSearchSortDto[] | undefined {
  const sortColumn =
    sortModel && sortModel.length ? sortModel[0].colId : undefined;
  const sortDirection =
    sortModel && sortModel.length ? sortModel[0].sort : undefined;

  let sort: LocationPatientRecordSearchSortDto[] | undefined;

  if (sortColumn === 'first_name') {
    sort = [
      { 'first_name.lowercase': sortDirection },
      { 'last_name.lowercase': sortDirection },
    ];
  } else if (sortColumn === 'date_of_birth') {
    sort = [{ date_of_birth: sortDirection }];
  }

  return sort;
}
