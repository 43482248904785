import React, { FunctionComponent, PropsWithChildren } from 'react';
import { TouchableOpacity, View, ViewStyle, TextStyle } from 'react-native';
import { Text } from 'assets/components/text';
import { makeStyles, useTheme } from 'assets/theme';
import { Icon } from 'assets/components/icon';

export const Tab: FunctionComponent<PropsWithChildren<TabProps>> = ({
  title,
  isActive,
  id,
  icon,
  onPress,
  disable,
}) => {
  const styles = useStyles();
  const theme = useTheme();

  const viewStyles: ViewStyle[] = [styles.container];
  const textStyles: TextStyle[] = [styles.text];
  let iconColor = theme.palette.primary[700];

  if (disable) {
    textStyles.push(styles.textDisabled);
    iconColor = theme.palette.gray[300];
  } else if (isActive) {
    viewStyles.push(styles.containerActive);
    iconColor = theme.palette.primary[600];
  }

  const TabView = () => (
    <View style={viewStyles}>
      {icon && (
        <View style={styles.iconContainer}>
          <Icon icon={icon} size={15} color={iconColor} />
        </View>
      )}
      <Text style={textStyles}>{title}</Text>
    </View>
  );

  const TabViewWithTouchableOpacity = () => {
    return (
      <TouchableOpacity onPress={() => onPress(id)}>
        <TabView />
      </TouchableOpacity>
    );
  };

  return disable ? <TabView /> : <TabViewWithTouchableOpacity />;
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.getSpacing(2),
    borderTopLeftRadius: theme.roundness,
    borderTopRightRadius: theme.roundness,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  containerActive: {
    backgroundColor: theme.palette.gray[50],
    boxShadow: '0px -3px 12px rgba(0, 0, 0, 0.04)',
  },
  iconContainer: {
    marginRight: theme.getSpacing(0.5),
  },
  text: {
    color: theme.palette.primary[700],
  },
  textActive: {
    color: theme.palette.primary[600],
  },
  textDisabled: {
    color: theme.palette.gray[300],
  },
}));

export interface TabProps {
  title: string;
  isActive: boolean;
  id: string;
  icon?: FunctionComponent;
  onPress: (pressedTab: string) => void;
  disable?: boolean;
}
