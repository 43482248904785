import { FunctionComponent, useEffect } from 'react';
import { View } from 'react-native';
import { IDatasource, GridReadyEvent } from '@ag-grid-community/core';
import { Modal } from 'assets/components/modal';
import { getText } from 'assets/localization/localization';
import theme from 'assets/theme';
import { LocationPatientRecordSearchDto } from '@digitalpharmacist/patient-service-client-axios';
import { DataGrid } from 'assets/components/data-grid';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { getRecipientsTableColumns } from '../data';
import { Alert } from 'assets/components/alert';
import { AG_GRID_PAGINATION_PAGE_SIZE } from '../../../../common/constants';

export const BulkRecipientsListModal: FunctionComponent<
  BulkRecipientsListModalProps
> = ({
  title,
  show,
  dataSource,
  removedRecipients,
  onCancel,
  onBack,
  onSubmit,
  onRemoveRecipient,
  onGridReady,
}) => {
  useEffect(() => {
    localStorage.setItem('currentPaginationPage', JSON.stringify(0));
  }, []);

  return (
    <Modal
      size="lg"
      title={title}
      show={show}
      isScrollable
      buttons={[
        {
          onPress: onSubmit,
          logger: { id: 'ok-button-modal-review-bulk-message-recipients' },
          text: getText('save'),
          hierarchy: 'primary',
          disabled: false,
          testID: 'ok-button-modal-review-bulk-message-recipients',
        },
        {
          onPress: onBack,
          text: getText('back'),
          logger: { id: 'back-button-modal-review-bulk-message-recipients' },
          hierarchy: 'secondary',
          testID: 'back-button-modal-review-bulk-message-recipients',
        },
      ]}
      handleDismissModal={onCancel}
    >
      {removedRecipients.length > 0 ? (
        <Alert
          intent="warning"
          title={getText('one-or-more-recipients-have-been-removed')}
          style={{ marginBottom: theme.getSpacing(2) }}
        />
      ) : (
        <></>
      )}
      <View style={{ minHeight: 584 }}>
        <DataGrid
          gridOptions={{
            columnDefs: getRecipientsTableColumns(onRemoveRecipient),
            enableCellTextSelection: true,
            suppressMovableColumns: true,
            suppressContextMenu: true,
            defaultColDef: { sortable: true, menuTabs: [] },
            loadingOverlayComponent: 'loadingIndicator',
            loadingOverlayComponentParams: {
              color: theme.colors.brandedPrimary,
            },
            components: {
              loadingIndicator: LoadingIndicator,
            },
            rowSelection: 'single',
            suppressCellFocus: true,
            colResizeDefault: 'shift',
            paginationPageSize: AG_GRID_PAGINATION_PAGE_SIZE,
            cacheBlockSize: AG_GRID_PAGINATION_PAGE_SIZE,
            maxBlocksInCache: 2,
            pagination: true,
            rowModelType: 'infinite',
            datasource: dataSource,
            onPaginationChanged(e) {
              if (e.newPage) {
                let currentPage = e.api.paginationGetCurrentPage();
                localStorage.setItem(
                  'currentPaginationPage',
                  JSON.stringify(currentPage),
                );
              }
            },
            onGridReady: onGridReady,
          }}
          gridToolbarProps={{
            inputSearchProps: {
              size: 'lg',
              placeholder: getText('name-dob'),
            },
          }}
        />
      </View>
    </Modal>
  );
};

interface BulkRecipientsListModalProps {
  title: string;
  show: boolean;
  dataSource: IDatasource;
  removedRecipients: string[];
  onSubmit: () => void;
  onBack: () => void;
  onCancel: () => void;
  onRemoveRecipient: (recipient: LocationPatientRecordSearchDto) => void;
  onGridReady: (event: GridReadyEvent) => void;
}
