import {
  AutoSaveWebsiteWizardDto,
  FinalizeWebsitePharmacyServiceOptionDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { CheckboxField, CheckboxInputMode } from 'assets/components/checkbox';
import { Modal } from 'assets/components/modal';
import { Text } from 'assets/components/text';
import { Form } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  openNextModalId,
  savePatientWebsite,
  setOpenedModal,
} from '../../../actions/checklist-actions';
import { useChecklistStore } from '../../../store/checklist-store';
import { ChecklistWebsiteModalProps } from '../types';
import { PatientWebsiteServiceDetail } from '../utils';

export interface WebsiteServiceDetail {
  pharmacyServices: FinalizeWebsitePharmacyServiceOptionDto[] | undefined;
}

export const PatientWebsiteServiceDetailsModal: FunctionComponent<
  ChecklistWebsiteModalProps
> = ({ id, data }) => {
  const { currentOpenedModalId } = useChecklistStore();
  const [loading, setLoading] = useState<boolean>(false);
  const styles = useStyles();
  const pharmacyServices =
    data?.website_details?.website_pharmacy_service_options?.sort(
      (a, b) => a.order - b.order,
    );

  const methods = useForm<WebsiteServiceDetail>({
    defaultValues: {
      pharmacyServices,
    },
  });
  const pharmacyServicesFields = methods.watch('pharmacyServices');

  useEffect(() => {
    methods.reset({ pharmacyServices });
  }, [pharmacyServices]);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const dto: AutoSaveWebsiteWizardDto = {
        ...data,
        website_details: {
          ...data.website_details,
          website_pharmacy_service_options: pharmacyServicesFields,
        },
      };
      const services = dto.website_details?.website_service_options;

      await savePatientWebsite(dto);
      services && openNextModalId(services, 1);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={getText('pharmacy-service-details')}
      show={currentOpenedModalId === id}
      handleDismissModal={() => setOpenedModal('checklist-setup')}
      showDismissButton
      isScrollable
      size="sm"
      buttons={[
        {
          hierarchy: 'primary',
          text: getText('next'),
          onPress: methods.handleSubmit(handleSubmit),
          logger: { id: 'next-website-service-details' },
          loading,
        },
        {
          text: getText('back'),
          hierarchy: 'tertiary-gray',
          onPress: () => setOpenedModal('patient-website-services'),
          logger: { id: 'back-website-service-details' },
        },
      ]}
    >
      <Form methods={methods}>
        <Form.Row>
          <Form.Column>
            <Text style={styles.title}>
              {getText('indicate-type-of-compounding')}
            </Text>
          </Form.Column>
        </Form.Row>
        {pharmacyServicesFields?.map((service, index) => (
          <Form.Row key={index}>
            <Form.Column key={service.id}>
              <CheckboxField
                name={`pharmacyServices.${index}.selected`}
                label={PatientWebsiteServiceDetail[service.slug]}
                mode={CheckboxInputMode.FLAT}
              />
            </Form.Column>
          </Form.Row>
        ))}
      </Form>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.lumistryFonts.text.medium.regular,
    color: theme.palette.gray[700],
  },
}));
