import React, { FunctionComponent } from 'react';
import { useChecklistStore } from '../../store/checklist-store';
import { ChecklistAppSettingsModal } from './checklist-app-settings/ChecklistAppSettingsModal';
import { ChecklistSetupModal } from './checklist-setup-modal/ChecklistSetupModal';
import { ColorPickerModal } from './media-settings/ColorPickerModal';
import { MediaSettingsModal } from './media-settings/MediaSettingsModal';
import { PatientWebsiteAboutUsDetailsModal } from './patient-website/PatientWebsiteAboutUsDetailsModal';
import { PatientWebsiteAdditionalInfoModal } from './patient-website/PatientWebsiteAdditionalInfoModal';
import { PatientWebsiteCompletedModal } from './patient-website/PatientWebsiteCompletedModal';
import { PatientWebsiteCompoundingDetailsModal } from './patient-website/PatientWebsiteCompundingDetailsModal';
import { PatientWebsiteDeliveryDetailsModal } from './patient-website/PatientWebsiteDeliveryDetailsModal';
import { PatientWebsiteModal } from './patient-website/PatientWebsiteModal';
import { PatientWebsiteNotificationModal } from './patient-website/PatientWebsiteNotificationModal';
import { PatientWebsiteServiceDetailsModal } from './patient-website/PatientWebsiteServiceDetailsModal';
import { PatientWebsiteServicesModal } from './patient-website/PatientWebsiteServicesModal';
import { PatientWebsiteTemplateModal } from './patient-website/PatientWebsiteTemplateModal';
import { WebsiteAccessCompletedModal } from './patient-website/access-flow/WebsiteAccessCompletedModal';
import { WebsiteAccessPermissionModal } from './patient-website/access-flow/WebsiteAccessPermissionModal';
import { WebsiteAccessSetupModal } from './patient-website/access-flow/WebsiteAccessSetupModal';
import { PatientWebsiteLocationDescriptionModal } from './patient-website/locations/PatientWebsiteLocationDescriptionModal';
import { PatientWebsiteLocationsModal } from './patient-website/locations/PatientWebsiteLocationsModal';
import { MessageSettingsModal } from './message-settings/MessageSettingsModal';
import { ChecklistPatientAgreementsModal } from './checklist-patient-agreements/ChecklistPatientAgreementsModal';

export const ChecklistModalProvider: FunctionComponent = () => {
  const { patientWebsiteData: websiteData, currentOpenedModalId } =
    useChecklistStore();

  if (!currentOpenedModalId) return null;

  return (
    <>
      <ChecklistSetupModal id="checklist-setup" />
      <ChecklistPatientAgreementsModal id="patient-agreements" />
      <MediaSettingsModal id="media-settings" />
      <ColorPickerModal id="color-picker" />
      <PatientWebsiteModal id="patient-website" />
      <ChecklistAppSettingsModal id="patient-app" />
      <MessageSettingsModal id="message-settings" />
      {websiteData && (
        <>
          <PatientWebsiteTemplateModal
            id="patient-website-template"
            data={websiteData}
          />
          <PatientWebsiteServicesModal
            id="patient-website-services"
            data={websiteData}
          />
          <PatientWebsiteServiceDetailsModal
            id="patient-website-service-details"
            data={websiteData}
          />
          <PatientWebsiteCompoundingDetailsModal
            id="patient-website-compounding-details"
            data={websiteData}
          />
          <PatientWebsiteDeliveryDetailsModal
            id="patient-website-delivery-details"
            data={websiteData}
          />
          <PatientWebsiteAboutUsDetailsModal
            id="patient-website-about-us-details"
            data={websiteData}
          />
          <PatientWebsiteAdditionalInfoModal
            id="patient-website-additional-info"
            data={websiteData}
          />
          <PatientWebsiteNotificationModal id="patient-website-notification" />
          <PatientWebsiteLocationsModal
            id="patient-website-locations"
            data={websiteData}
          />
          <PatientWebsiteCompletedModal
            id="patient-website-complete"
            data={websiteData}
          />
          <WebsiteAccessSetupModal
            id="website-access-setup"
            data={websiteData}
          />
          <WebsiteAccessCompletedModal
            id="website-access-completed"
            data={websiteData}
          />
          <WebsiteAccessPermissionModal
            id="website-access-permission"
            data={websiteData}
          />
          <PatientWebsiteLocationDescriptionModal id="patient-website-location-desc" />
        </>
      )}
    </>
  );
};
