import React, { FunctionComponent, PropsWithChildren, useEffect } from 'react';
import { Text } from 'assets/components/text';
import { TextField } from 'assets/components/text-field';
import { useForm } from 'assets/form';
import { TouchableOpacity, View, Platform } from 'react-native';
import { makeStyles, useTheme } from 'assets/theme';
import { Button } from 'assets/components/button';
import { useCreatePasswordState } from './create-password-store';
import { CreatePasswordForm } from './create-password-actions';
import { useNavigation } from '@react-navigation/native';
import { RootStackNavigationProp } from '../../navigation/RootNavigation';
import { createPassword } from './create-password-actions';
import { CreatePasswordTestIDs } from './CreatePasswordTestIDs';
import { PasswordRules } from 'assets/common/PasswordRules';
import { Form, PharmacyScreenContainer } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { CheckboxField } from 'assets/components/checkbox';
import * as Linking from 'expo-linking';
import { isPassword } from '@digitalpharmacist/validation-dp';

export const CreatePassword: FunctionComponent<
  PropsWithChildren<CreatePasswordProps>
> = () => {
  const styles = useStyles();
  const theme = useTheme();
  const methods = useForm<CreatePasswordForm>({
    defaultValues: {
      temporary_user_registration_id: '',
      password: '',
      passwordConfirm: '',
      termsAndConditions: false,
      privacyPolicy: false,
    },
  });

  const [firstName, setFirstName] = React.useState('');
  const navigation = useNavigation<RootStackNavigationProp>();

  useEffect(() => {
    if (Platform.OS === 'web') {
      Linking.getInitialURL().then((url) => {
        if (url) {
          const params = Linking.parse(url).queryParams;
          if (params?.temporaryUserRegistrationId) {
            methods.setValue(
              'temporary_user_registration_id',
              params.temporaryUserRegistrationId as string,
            );
            setFirstName((params.firstname as string) || '');
          } else {
            navigation.navigate('login');
          }
        }
      });
    }
  }, []);

  const passwordValue = methods.watch('password');
  const { status, error } = useCreatePasswordState();

  const handleSubmit = async () => {
    createPassword(methods.getValues());
  };

  return (
    <PharmacyScreenContainer small>
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
        }}
      >
        <View
          style={{
            marginBottom: theme.getSpacing(2),
          }}
        >
          <Text style={styles.firstHeading} selectable>
            {getText('hi', { firstName })}
          </Text>
          <Text style={styles.secondHeading} selectable>
            {getText('create-your-password')}
          </Text>
        </View>
        <Form methods={methods}>
          <Form.Alert title={error?.message} intent="error" visible={!!error} />
          <Form.Row>
            <Form.Column>
              <TextField
                testID={CreatePasswordTestIDs.passwordInput}
                label={getText('new-password')}
                name="password"
                rules={{
                  required: getText('password-is-required'),
                  validate: {
                    value: () => {
                      return isPassword(methods.getValues().password)
                        ? true
                        : getText('password-entry-does-not-meet-criteria');
                    },
                  },
                }}
                onSubmit={methods.handleSubmit(handleSubmit)}
                disabled={status === 'loading'}
                type="password"
              />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column>
              <TextField
                testID={CreatePasswordTestIDs.passwordConfirmInput}
                label={getText('confirm-password')}
                name="passwordConfirm"
                rules={{
                  required: getText('password-is-required'),
                  validate: {
                    value: () => {
                      return methods.getValues().password ===
                        methods.getValues().passwordConfirm
                        ? true
                        : getText('passwords-do-not-match');
                    },
                  },
                }}
                onSubmit={methods.handleSubmit(handleSubmit)}
                disabled={status === 'loading'}
                type="password"
              />
            </Form.Column>
          </Form.Row>
          <Form.Content style={styles.passwordRules}>
            <PasswordRules passwordValue={passwordValue}></PasswordRules>
          </Form.Content>
          <Form.Actions>
            <View
              style={{
                marginTop: theme.getSpacing(3),
                marginBottom: theme.getSpacing(1),
              }}
            >
              <CheckboxField
                testID={CreatePasswordTestIDs.termsAndConditionsCheckbox}
                name={'termsAndConditions'}
                label={
                  (
                    <>
                      <Text selectable>
                        {getText('i-have-read-and-agree')}{' '}
                      </Text>
                      <TouchableOpacity
                        onPress={() => {
                          alert('Will link to Terms and Conditions');
                        }}
                      >
                        <Text style={styles.link} selectable>
                          {getText('pharmacy-terms-and-conditions')}
                        </Text>
                      </TouchableOpacity>
                    </>
                  ) as any
                }
                rules={{
                  required: getText('terms-and-conditions-required'),
                }}
              />
            </View>
            <View style={{ marginBottom: theme.getSpacing(2) }}>
              <CheckboxField
                testID={CreatePasswordTestIDs.privacyPolicyCheckbox}
                name={'privacyPolicy'}
                label={
                  (
                    <>
                      <Text selectable>
                        {getText('i-have-read-and-agree')}{' '}
                      </Text>
                      <TouchableOpacity
                        onPress={() => {
                          alert('Will link to Privacy Policy');
                        }}
                      >
                        <Text style={styles.link} selectable>
                          {getText('privacy-policy')}
                        </Text>
                      </TouchableOpacity>
                    </>
                  ) as any
                }
                rules={{
                  required: getText('privacy-policy-required'),
                }}
              />
            </View>
            <View style={{ marginBottom: theme.getSpacing(2) }}>
              <Button
                onPress={methods.handleSubmit(handleSubmit)}
                hierarchy="primary"
                loading={status === 'loading'}
                logger={{ id: 'pharmacy-create-password-submit-button' }}
                style={styles.submitButton}
                testID={CreatePasswordTestIDs.submitButton}
              >
                {getText('submit')}
              </Button>
            </View>
          </Form.Actions>
        </Form>
      </View>
    </PharmacyScreenContainer>
  );
};

const useStyles = makeStyles((theme) => ({
  firstHeading: {
    color: theme.palette.gray[900],
    fontSize: 30,
    paddingTop: theme.getSpacing(2),
    textAlign: 'left',
  },
  secondHeading: {
    color: theme.palette.gray[900],
    fontSize: 30,
    paddingBottom: theme.getSpacing(1),
    textAlign: 'left',
  },
  link: {
    color: theme.palette.primary[500],
  },
  passwordRules: {
    borderBottomColor: theme.palette.gray[300],
    borderBottomWidth: 1,
    paddingBottom: theme.getSpacing(1),
    marginTop: theme.getSpacing(3),
  },
  submitButton: {
    backgroundColor: theme.palette.primary[600],
    borderColor: theme.palette.primary[600],
    width: 120,
  },
}));

interface CreatePasswordProps {}
