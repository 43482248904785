import { VaccinationDto } from '@digitalpharmacist/forms-service-client-axios';
import { Button } from 'assets/components/button';
import { Icon } from 'assets/components/icon';
import { DocumentIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { FunctionComponent } from 'react';
import { updateShowModalState } from '../../components/vaccination-modal/vaccination-modal-actions';
import { useTheme } from 'assets/theme';

export const VaccinationInfoButton: FunctionComponent<
  VaccinationInfoButtonProps
> = ({ vaccination, isInsideModal = false }) => {
  const theme = useTheme();

  return (
    <Button
      hierarchy="secondary-gray"
      mode="outlined"
      size="medium"
      logger={{ id: 'delete-submission-button' }}
      icon={() => (
        <Icon
          icon={DocumentIcon}
          color={
            vaccination ? theme.palette.gray[300] : theme.palette.gray[700]
          }
          size={20}
        />
      )}
      onPress={() => updateShowModalState(true)}
      disabled={!!vaccination}
    >
      {isInsideModal
        ? getText('add-vaccination-info')
        : getText('vaccination-info')}
    </Button>
  );
};

type VaccinationInfoButtonProps = {
  vaccination: VaccinationDto | undefined | null;
  isInsideModal?: boolean;
};
