import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { makeStyles } from 'assets/theme';
import { useWizard } from 'react-use-wizard';
import { Button } from 'assets/components/button';
import { getText } from 'assets/localization/localization';
import { setSetupStage } from '../../actions/onboarding-actions';
import { SetupStage } from '../../store/onboarding-store';
import { useToast } from '../../common/hooks/useToast';
import { FORM_NOT_VALID } from '../../screens/onboarding/utils';

const PharmacyOBWizardFooter: FunctionComponent<
  PharmacyOBWizardFooterProps
> = ({ onFinish }) => {
  const styles = useStyles();
  const { toast } = useToast();
  const { isFirstStep, activeStep, isLastStep, nextStep, previousStep } =
    useWizard();

  const handleBack = () => {
    if (isFirstStep) {
      setSetupStage(SetupStage.Landing);
    } else previousStep();
  };

  const handleNext = async () => {
    try {
      if (isLastStep) {
        await onFinish();
        setSetupStage(SetupStage.Exit);
      } else {
        await nextStep();
      }
    } catch (e: any) {
      if (e === FORM_NOT_VALID || e.message === FORM_NOT_VALID) return;

      toast('Error', {
        content: e.message,
        type: 'error',
      });
    }
  };

  return (
    <View style={styles.root}>
      <View
        style={
          isLastStep ? styles.buttonsColContainer : styles.buttonsRowContainer
        }
      >
        <View style={styles.buttonContainer}>
          <Button
            hierarchy={isLastStep ? 'tertiary-gray' : 'secondary'}
            logger={{
              id: 'pharmacy-wizard-back',
              data: { currentStep: activeStep },
            }}
            onPress={handleBack}
          >
            {getText('back')}
          </Button>
        </View>
        <View style={styles.buttonContainer}>
          <Button
            hierarchy={'primary'}
            logger={{
              id: 'pharmacy-wizard-next',
              data: { currentStep: activeStep },
            }}
            onPress={handleNext}
          >
            {getText(isLastStep ? 'submit' : 'next')}
          </Button>
        </View>
      </View>
    </View>
  );
};

export default PharmacyOBWizardFooter;

interface PharmacyOBWizardFooterProps {
  onFinish: () => Promise<void>;
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.getSpacing(1),
    marginBottom: theme.getSpacing(4),
    width: 350,
  },
  buttonsRowContainer: {
    flexDirection: 'row',
    gap: theme.getSpacing(2),
    marginVertical: theme.getSpacing(3),
  },
  buttonsColContainer: {
    flexDirection: 'column-reverse',
    gap: theme.getSpacing(2),
    marginVertical: theme.getSpacing(3),
  },
  buttonContainer: {
    flex: 1,
  },
}));
