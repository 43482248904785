import { Text } from 'assets/components/text';
import theme, { makeStyles } from 'assets/theme';
import { FunctionComponent, PropsWithChildren } from 'react';
import { View } from 'react-native';
import React from 'react';
import { Icon } from 'assets/components/icon';
import { AlertCircleIcon } from 'assets/icons';

export const BillingItemRender: FunctionComponent<
  PropsWithChildren<BillingItemRenderProps>
> = ({ quantity, quantity_cap, usedTitle, capTitle, percentage }) => {
  const styles = useStyles();

  return (
    <>
      <View style={styles.container}>
        <View style={styles.firstColumnContent}>
          <Text style={styles.title}>{capTitle}</Text>
        </View>
        <View style={styles.secondColumnContent}>
          <Text style={styles.value}>
            {quantity_cap}
            {'/mo.'}
          </Text>
        </View>
      </View>
      <View style={styles.container}>
        <View style={styles.firstColumnContent}>
          <Text style={styles.title}>{usedTitle}</Text>
        </View>
        <View style={styles.secondColumnContent}>
          <Text style={styles.value}>
            {quantity}
            {` this month (${percentage}%) `}
          </Text>
          {quantity >= quantity_cap && (
            <Icon
              icon={AlertCircleIcon}
              size={16}
              color={theme.palette.warning[500]}
            />
          )}
        </View>
      </View>
    </>
  );
};

interface BillingItemRenderProps {
  start_date: string;
  end_date: string;
  quantity: number;
  quantity_cap: number;
  usedTitle: string;
  capTitle: string;
  percentage: number;
}

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'row',
    gap: theme.getSpacing(2),
    alignItems: 'center',
    borderBottomColor: theme.palette.gray[200],
    borderBottomWidth: 1,
    padding: theme.getSpacing(1),
    marginBottom: theme.getSpacing(1),
  },
  firstColumnContent: {
    flex: 1,
    flexDirection: 'row',
  },
  title: {
    ...theme.fonts.regular,
    fontSize: 14,
    lineHeight: 16,
    color: theme.palette.gray[900],
    fontWeight: '600',
  },
  value: {
    ...theme.fonts.regular,
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[700],
  },
  secondColumnContent: {
    flex: 3,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
}));
