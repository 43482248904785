import { getText } from 'assets/localization/localization';
import React, { FunctionComponent } from 'react';
import { setOpenedModal } from '../../../actions/checklist-actions';
import { PatientAgreementModal } from '../../../components/PatientAgreements/PatientAgreementModal';
import { useChecklistStore } from '../../../store/checklist-store';
import { ChecklistModalProps } from '../types';

export const ChecklistPatientAgreementsModal: FunctionComponent<
  ChecklistModalProps
> = ({ id }) => {
  const { currentOpenedModalId } = useChecklistStore();
  const handleCloseModal = () => setOpenedModal('checklist-setup');

  return (
    <PatientAgreementModal
      show={currentOpenedModalId === id}
      title={getText('patient-agreements')}
      handleCloseModal={handleCloseModal}
    />
  );
};
