import { Modal } from 'assets/components/modal';
import { getText } from 'assets/localization/localization';
import _isEqual from 'lodash/isEqual';
import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { View } from 'react-native';
import { makeStyles, useTheme } from 'assets/theme';
import { Text } from 'assets/components/text';
import { TextField } from 'assets/components/text-field';
import { useForm } from 'react-hook-form';
import { Form } from 'assets/layout';
import { useInternalUseState } from './internal-use-store';
import { useAppStateStore } from '../../../store/app-store';
import { isNumber } from '../../../../utils';
import { updateLocationUsageCaps } from './internal-use-actions';

export const MessagePlansModal: FunctionComponent<
  PropsWithChildren<any>
> = () => {
  const { internalUseBillableUsage: internalUseOptions, showInternalUseModal } =
    useInternalUseState();
  const { locationId } = useAppStateStore();
  const styles = useStyles();
  const [showError, setShowError] = useState<boolean>(false);

  useEffect(() => {
    if (
      !internalUseOptions ||
      internalUseOptions.length < 1 ||
      !internalUseOptions[0]?.value ||
      !internalUseOptions[1]?.value
    )
      return;
    methods.setValue('smsCap', internalUseOptions[0]?.value?.toString());
    methods.setValue('emailCap', internalUseOptions[1]?.value?.toString());
  }, [internalUseOptions, showInternalUseModal]);

  const methods = useForm({
    defaultValues: {
      smsCap: '',
      emailCap: '',
    },
  });

  const handleSubmit = async () => {
    if (
      !isNumber(methods.getValues().smsCap) ||
      !isNumber(methods.getValues().emailCap) ||
      methods.getValues().smsCap === '' ||
      methods.getValues().emailCap === ''
    ) {
      setShowError(true);
    } else {
      setShowError(false);
      updateLocationUsageCaps(
        locationId,
        parseInt(methods.getValues().smsCap, 10),
        parseInt(methods.getValues().emailCap, 10),
      );
      closeModal();
    }
  };

  const closeModal = () => {
    useInternalUseState.setState({ showInternalUseModal: false });
    setShowError(false);
    methods.reset();
  };

  return (
    <Modal
      title={getText('edit-billable-limits')}
      titleSize="sm"
      dismissButtonProps={{
        onPress: closeModal,
        logger: { id: 'refill-due-reminders-cancel-button-modal' },
      }}
      cancelButtonProps={{
        onPress: closeModal,
        hierarchy: 'tertiary-gray',
        logger: { id: 'refill-due-reminders-cancel-button-modal' },
      }}
      okButtonProps={{
        onPress: methods.handleSubmit(handleSubmit),
        logger: { id: 'refill-due-reminders-ok-button-modal' },
        hierarchy: 'primary',
        text: getText('ok'),
      }}
      show={showInternalUseModal}
      isScrollable={false}
      size="sm"
    >
      <Form methods={methods}>
        <Form.Alert
          title={getText('numeric-only')}
          visible={showError}
          intent="error"
        />
        {internalUseOptions.length > 0 &&
          internalUseOptions.map((item, index) => (
            <Form.Row key={index} style={styles.rowView}>
              <Form.Column>
                <Text style={styles.title}>{item.name}</Text>
              </Form.Column>
              <Form.Column>
                <TextField
                  name={item?.shortName}
                  value={item?.value?.toString()}
                  type="numeric"
                />
              </Form.Column>
            </Form.Row>
          ))}
      </Form>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.lumistryFonts.text.medium,
    flex: 1,
  },
  rowView: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  errorMessage: {
    color: theme.palette.error[500],
    textAlign: 'right',
  },
}));
