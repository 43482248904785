import ReactPlayerLoader from '@brightcove/react-player-loader';
import { Icon } from 'assets/components/icon';
import { ShareIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { getSpacing, makeStyles, useTheme } from 'assets/theme';
import { Text, TouchableOpacity, View } from 'react-native';
import { VideoCategoryModel } from '../../api/VideoService';
import { useVideosState } from '../../store/videos-store';

const VideoBrowseTemplate: React.FC<VideoBrowseTemplateProps> = ({
  accountId,
  videoId,
  playerId,
  title,
  category,
  short_url,
}) => {
  const styles = useStyles();
  const { setShowVideoShareModal } = useVideosState();
  const theme = useTheme();

  return (
    <View style={styles.card}>
      <ReactPlayerLoader
        accountId={accountId}
        videoId={videoId}
        playerId={playerId}
        embedOptions={{ responsive: true }}
        options={{
          autoplay: false,
          preload: 'none',
          muted: true,
          click: false,
          fluid: true,
          audioPosterMode: true,
          disablePictureInPicture: true,
          controls: false,
          html5: { preloadTextTracks: false, nativeVideoTracks: false },
          userActions: {
            click: false,
            doubleClick: false,
            hotkeys: false,
          },
          children: ['mediaLoader', 'posterImage', 'errorDisplay'],
        }}
      />
      <Text style={styles.title}>{title}</Text>
      <View style={styles.info}>
        <Text style={styles.category}>{category.label}</Text>
        <TouchableOpacity
          style={styles.share}
          onPress={() => setShowVideoShareModal(true, short_url)}
        >
          <Icon icon={ShareIcon} color={theme.palette.primary[600]} size={14} />
          <Text style={styles.shareText}>{getText('videos-share')}</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default VideoBrowseTemplate;

export interface VideoBrowseTemplateProps {
  id: string;
  accountId: string;
  videoId: string;
  playerId: string;
  title: string;
  category: VideoCategoryModel;
  short_url: string;
}

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.getSpacing(2),
    marginLeft: theme.getSpacing(3),
    width: 244,
  },
  title: {
    marginTop: theme.getSpacing(1),
    fontWeight: '700',
    fontSize: 14,
    lineHeight: 24,
  },
  info: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  category: {
    fontSize: 14,
    lineHeight: 20,
    fontWeight: '400',
    color: theme.palette.gray[700],
  },
  share: {
    marginLeft: getSpacing(2),
    color: theme.palette.primary[600],
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    display: 'flex',
  },
  shareText: {
    color: theme.palette.primary[600],
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 20,
    marginLeft: getSpacing(0.5),
  },
}));
