import { useTaskModalState } from './task-modal-store';
import { logError } from 'assets/logging/logger';

import TaskService from '../../api/TaskService';
import {
  CreateTaskDto,
  TaskStatus,
} from '@digitalpharmacist/tasks-service-client-axios';
import {
  hideTaskDetails,
  updateTaskDetails,
} from '../tasks-data-table/tasks-data-table-actions';
import { useToast } from '../../common/hooks/useToast';
import { useUserState } from '../../store/user-store';
import { setReloadTasksGrid } from '../tasks-grid/tasks-grid-actions';
import { useTaskSettingsState } from '../../screens/settings/tasks/task-settings-store';
import { useTasksDataTableState } from '../tasks-data-table/tasks-data-table-store';
import RoleService from '../../api/RoleService';
import { ampli } from '../../src/ampli';
import { useAppStateStore } from '../../store/app-store';
import {
  getEntityTypeFromRole,
  getHighestRole,
} from '../../screens/settings/Users/UserHelpers';
import {
  DetailedUserDto,
  EntityType,
  Role,
} from '@digitalpharmacist/role-service-client-axios';

// Until we know better, we plan to show all users for the pharmacy
const PHARMACY_USER_ASSIGNEE_LIMIT = 500;

const errorOccurred = (error: any, errorMessage?: string) => {
  const { toast } = useToast();
  const message = errorMessage
    ? errorMessage
    : 'An error occurred while trying to load form templates. Please try again.';

  logError(error);
  useTaskModalState.setState({
    error: {
      message: errorMessage
        ? errorMessage
        : 'An error occurred while trying to load form templates. Please try again.',
    },
    status: 'error',
    taskStatus: 'error',
  });

  toast('Error', { type: 'error', content: message });
};

export const updateShowModalState = (newState: boolean) => {
  useTaskModalState.setState({
    showModal: newState,
    editingTask: false,
    editTaskData: undefined,
  });
};

export const createNewTask = async (taskData: CreateTaskDto): Promise<void> => {
  useTaskModalState.setState({ taskStatus: 'loading' });
  const { toast } = useToast();
  const gridApi = useTasksDataTableState.getState().gridApi;

  if (taskData.status === TaskStatus.Resolved) {
    const { data: userData } = useUserState.getState();

    taskData.resolved_by_user_id = userData!.id;
  }

  try {
    gridApi?.showLoadingOverlay();

    await TaskService.createTask(taskData);

    gridApi?.refreshServerSideStore();
    setReloadTasksGrid(true);
    toast('Task created', { type: 'success' });
    useTaskModalState.setState({ taskStatus: 'success' });
  } catch (error: any) {
    errorOccurred(error, 'Error occurred while trying to save the task.');
    return Promise.reject(error);
  }
};

export const updateTask = async (
  taskId: string,
  taskData: CreateTaskDto,
): Promise<void> => {
  useTaskModalState.setState({ taskStatus: 'loading' });
  const { toast } = useToast();

  const gridApi = useTasksDataTableState.getState().gridApi;
  if (taskData.status === TaskStatus.Resolved) {
    const { data: userData } = useUserState.getState();

    taskData.resolved_by_user_id = userData!.id;
  }

  try {
    gridApi?.showLoadingOverlay();
    const response = await TaskService.updateTask(taskId, taskData);

    gridApi?.refreshServerSideStore();
    // Update the sidepanel task information
    updateTaskDetails(response);
    setReloadTasksGrid(true);
    toast('Task updated', { type: 'success' });
    useTaskModalState.setState({ taskStatus: 'success' });
  } catch (error: any) {
    errorOccurred(error, 'Error occurred while trying to update the task.');
    return Promise.reject(error);
  }
};

export const deleteTask = async (taskId: string): Promise<void> => {
  const { toast } = useToast();
  const gridApi = useTasksDataTableState.getState().gridApi;
  hideTaskDetails();

  try {
    useTaskModalState.setState({ showModal: false });
    gridApi?.showLoadingOverlay();

    await TaskService.deleteTask(taskId);

    gridApi?.refreshServerSideStore();
    setReloadTasksGrid(true);
    toast('Task deleted', { type: 'success' });
  } catch (error: any) {
    errorOccurred(error, 'Error occurred while trying to delete the task.');
    return Promise.reject(error);
  }
};

export const getModalFormOptions = async () => {
  const { pharmacyId, availableRoles, locationId } =
    useAppStateStore.getState();
  useTaskModalState.setState({ error: undefined, status: 'loading' });

  try {
    const highestRole = getHighestRole(availableRoles);

    const entityType = getEntityTypeFromRole(highestRole);

    let assigneeOptionsResponse: DetailedUserDto;

    if (entityType === EntityType.Location) {
      assigneeOptionsResponse = await RoleService.searchLocationUsers(
        locationId,
        undefined,
        PHARMACY_USER_ASSIGNEE_LIMIT,
      );
    } else {
      assigneeOptionsResponse = await RoleService.searchPharmacyUsers(
        pharmacyId,
        undefined,
        PHARMACY_USER_ASSIGNEE_LIMIT,
      );
    }

    const taskTypes = await TaskService.findTaskTypes();

    useTaskModalState.setState({
      assigneeOptions: assigneeOptionsResponse.results,
      status: 'idle',
    });

    useTaskSettingsState.setState({
      taskTypes,
    });
  } catch (error: any) {
    errorOccurred(
      error,
      'Error occurred while trying to get assignee options.',
    );
    return Promise.reject(error);
  }
};

export const assigneeChanged = async (assigneeId: string) => {
  try {
    const { locationId } = useAppStateStore.getState();
    const roles = await RoleService.userRoleGetRolesByUserId(assigneeId);
    const locationRole = roles.find((role) => role.entity_id === locationId);

    ampli.taskAssignedTo({
      taskAssignedTo: assigneeId,
      taskAssigneeRole: locationRole ? locationRole.role : '',
      taksAssignedTime: new Date().toISOString(),
    });
  } catch (error: any) {
    return Promise.reject(error);
  }
};
