import { AxiosRequestConfig } from 'axios';
import { BaseApiConfiguration, BaseApiService } from 'assets/core/api';
import { TASKS_API_BASE_URL } from '../common/constants';
import {
  CreateTaskDto,
  DefaultApi as TaskServiceClient,
  TaskDto,
  TaskPageDto,
  TaskSortOrder,
  UpdateTaskDto,
  TaskUpdateBulkDto,
  TaskGridPageDto,
  TaskTypeDto,
  CreateTaskTypeDto,
  UpdateTaskTypeDto,
  AutomationDto,
  UpdateAutomationDto,
} from '@digitalpharmacist/tasks-service-client-axios';
import { useAppStateStore } from '../store/app-store';
import { useUserState } from '../store/user-store';
import { TaskGridOptions } from '../tasks/tasks-grid/tasks-grid-actions';
import { PaginatedRemoteDatasource } from 'assets/components/data-grid/data-grid-toolkit/datasource/PaginatedRemoteDatasource';
import { TasksBulkUpdateOptions } from '../tasks/tasks-data-table/tasks-data-table-actions';
import { AXIOS_DEFAULT_REQUEST_CONFIG } from '../common/axios-default-request-config';

export interface ITaskService {
  findTasks(): Promise<TaskPageDto>;
}

export class TaskService extends BaseApiService implements ITaskService {
  private taskServiceClient: TaskServiceClient;

  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration,
  ) {
    super(baseUrl, config, enableAuth, baseConfig);

    this.taskServiceClient = new TaskServiceClient(
      undefined,
      baseUrl,
      this.axiosInstance,
    );
  }

  getTasksPaginatedDatasource() {
    const locationId = useAppStateStore.getState().locationId;

    return new PaginatedRemoteDatasource(
      `${TASKS_API_BASE_URL}/locations/${locationId}/tasks`,
      this.axiosInstance,
    );
  }

  getMyTasksForDashboard(locationId: string, userId: string, limit?: number) {
    return this.axiosInstance.get(
      `${TASKS_API_BASE_URL}/locations/${locationId}/tasks`,
      {
        params: {
          limit: limit ?? 5,
          offset: 0,
          sort_by: 'due_date',
          order_by: 'desc',
          non_resolved_only: true,
          current_location_id: locationId,
          current_user_id: userId,
          assigned_user_id: userId,
        },
      },
    );
  }

  getParams() {
    return {
      current_location_id: useAppStateStore.getState().locationId,
      current_user_id: useUserState.getState().data?.id!,
      current_pharmacy_id: useAppStateStore.getState().pharmacyId,
    };
  }
  async createTask(taskData: CreateTaskDto): Promise<TaskDto> {
    const locationId = useAppStateStore.getState().locationId;
    const { data } = await this.taskServiceClient.taskCreate(
      locationId,
      taskData,
    );
    return data;
  }

  async findTask(taskId: string): Promise<TaskDto> {
    const {
      current_user_id: currentUserId,
      current_location_id: currentLocationId,
    } = this.getParams();

    const { data } = await this.taskServiceClient.taskFindOne(
      taskId,
      currentLocationId,
      currentUserId,
    );

    return data;
  }

  async findTasks(): Promise<TaskPageDto> {
    const {
      current_user_id: currentUserId,
      current_location_id: currentLocationId,
    } = this.getParams();

    const { data } = await this.taskServiceClient.taskFind(
      currentLocationId,
      currentUserId,
      0,
      10,
      ['due_date'],
      TaskSortOrder.Asc,
    );

    return data;
  }

  async updateTask(
    taskId: string,
    updateBody: UpdateTaskDto,
  ): Promise<TaskDto> {
    const {
      current_user_id: currentUserId,
      current_location_id: currentLocationId,
    } = this.getParams();

    const { data } = await this.taskServiceClient.taskUpdate(
      taskId,
      currentLocationId,
      currentUserId,
      updateBody,
    );

    return data;
  }

  async updateTasks(
    updateBody: TaskUpdateBulkDto,
    updateQuery?: TasksBulkUpdateOptions,
  ): Promise<TaskDto[]> {
    const {
      current_user_id: currentUserId,
      current_location_id: currentLocationId,
    } = this.getParams();
    const { data } = await this.taskServiceClient.taskUpdateBulk(
      currentLocationId,
      currentUserId,
      updateBody,
      updateQuery?.assignedUserId,
      updateQuery?.status,
      updateQuery?.flagged,
      updateQuery?.recurring,
      updateQuery?.createdByUserId,
      updateQuery?.priority,
      updateQuery?.dueDate,
      updateQuery?.minDueDate,
      updateQuery?.maxDueDate,
      updateQuery?.nonResolvedOnly,
      updateQuery?.taskTypeId,
      updateQuery?.visibility,
      updateQuery?.searchTerm,
    );

    return data;
  }

  async deleteTasks(
    taskIds?: string[],
    deleteQuery?: TasksBulkUpdateOptions,
  ): Promise<TaskDto[]> {
    const {
      current_user_id: currentUserId,
      current_location_id: currentLocationId,
    } = this.getParams();

    const { data } = await this.taskServiceClient.taskRemoveBulk(
      currentLocationId,
      currentUserId,
      {
        taskIds,
      },
      deleteQuery?.assignedUserId,
      deleteQuery?.status,
      deleteQuery?.flagged,
      deleteQuery?.recurring,
      deleteQuery?.createdByUserId,
      deleteQuery?.priority,
      deleteQuery?.dueDate,
      deleteQuery?.minDueDate,
      deleteQuery?.maxDueDate,
      deleteQuery?.nonResolvedOnly,
      deleteQuery?.taskTypeId,
      deleteQuery?.visibility,
      deleteQuery?.searchTerm,
    );

    return data;
  }

  async deleteTask(taskId: string): Promise<TaskDto> {
    const {
      current_user_id: currentUserId,
      current_location_id: currentLocationId,
    } = this.getParams();

    const { data } = await this.taskServiceClient.taskRemove(
      taskId,
      currentLocationId,
      currentUserId,
    );

    return data;
  }

  async findTasksGrid(options: TaskGridOptions): Promise<TaskGridPageDto> {
    const {
      current_user_id: currentUserId,
      current_location_id: currentLocationId,
    } = this.getParams();

    const { data } = await this.taskServiceClient.taskGridFind(
      options.limit as number,
      options.minDueDate,
      options.maxDueDate,
      currentLocationId,
      currentUserId,
      options.offset,
      options.sortBy,
      options.orderBy,
      options.assignedUserId,
      options.status,
      options.flagged,
      options.flaggedFirst,
      options.recurring,
      options.createdByUserId,
      options.priority,
      options.dueDate,
      options.deletedOnly,
      options.nonResolvedOnly,
      options.taskTypeId,
      options.visibility,
      options.searchTerm,
    );

    return data;
  }

  async findTaskTypes(): Promise<TaskTypeDto[]> {
    const {
      current_location_id: currentLocationId,
      current_pharmacy_id: currentPharmacyId,
    } = this.getParams();

    const { data } = await this.taskServiceClient.taskTypeFindByPharmacyId(
      currentLocationId,
      currentPharmacyId,
    );

    return data;
  }

  async createTaskType(taskTypeData: CreateTaskTypeDto): Promise<TaskTypeDto> {
    const { current_location_id: currentLocationId } = this.getParams();

    const { data } = await this.taskServiceClient.taskTypeCreate(
      currentLocationId,
      taskTypeData,
    );

    return data;
  }

  async updateTaskType(
    taskTypeId: string,
    taskTypeData: UpdateTaskTypeDto,
  ): Promise<TaskTypeDto> {
    const { current_location_id: currentLocationId } = this.getParams();

    const { data } = await this.taskServiceClient.taskTypeUpdate(
      currentLocationId,
      taskTypeId,
      taskTypeData,
    );

    return data;
  }

  async deleteTaskType(taskTypeId: string): Promise<TaskTypeDto> {
    const { current_location_id: currentLocationId } = this.getParams();

    const { data } = await this.taskServiceClient.taskTypeRemove(
      currentLocationId,
      taskTypeId,
    );

    return data;
  }

  async getAutomations(): Promise<AutomationDto[]> {
    const { current_pharmacy_id: pharmacyId, current_location_id: locationId } =
      this.getParams();

    const { data } = await this.taskServiceClient.automationFind(
      locationId,
      pharmacyId,
    );

    return data;
  }

  async updateAutomation(
    id: string,
    reqBody: UpdateAutomationDto,
  ): Promise<AutomationDto> {
    const { current_location_id: locationId } = this.getParams();

    const { data } = await this.taskServiceClient.automationUpdate(
      id,
      locationId,
      reqBody,
    );

    return data;
  }
}

export default new TaskService(
  TASKS_API_BASE_URL,
  AXIOS_DEFAULT_REQUEST_CONFIG,
  true,
);
