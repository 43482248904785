import { View } from 'react-native';
import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { filterXSS } from 'xss';
import { makeStyles, useTheme } from 'assets/theme';
import { Button } from 'assets/components/button';
import { ChevronLeftIcon } from 'assets/icons/ChevronLeftIcon';
import { useNavigation } from '@react-navigation/native';
import { FormsDrawerNavigationProp } from '../../layout/FormsDrawer';
import { DownloadIcon, TrashWithStripesIcon } from 'assets/icons';
import { IconButton } from 'assets/components/icon-button';
import {
  getSubmission,
  getSubmissionPDF,
} from '../../screens/forms/view-submission-actions';
import { useViewSubmissionStore } from '../../screens/forms/view-submission-store';
import { LoadingOverlay } from '../../components/LoadingOverlay';
import { formatDate, formatDateTime } from '../../common/datetime-utils';
import { Text } from 'assets/components/text';
import { useToast } from '../../common/hooks/useToast';
import { Lightbox } from '../../common/Lightbox';
import moment from 'moment';
import { downloadFile, getDateTimeFilename } from '../../common/file-utils';
import '../../screens/forms/answer-input-table.css';
import { ampli } from '../../common/ampliPharmacist';
import { getText } from 'assets/localization/localization';
import { VaccinationModal } from '../../components/vaccination-modal/VaccinationModal';
import { UserAuthenticationModal } from '../../components/UserAuthenticationModal/UserAuthenticationModal';
import VaccinationInfoSection from './VaccinationInfoSection';
import { VaccinationInfoButton } from './VaccinationInfoButton';
import FormsService from '../../api/FormsService';
import { Icon } from 'assets/components/icon';
import { SubmissionTestIDs } from '../../screens/forms/FormTestIDs';

export const Submission: FunctionComponent<
  PropsWithChildren<SubmissionProps>
> = ({ submissionId, formId, withDelete = true, isInsideModal = false }) => {
  const styles = useStyles();
  const theme = useTheme();

  const { toast } = useToast();
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const { submission, status } = useViewSubmissionStore();

  useEffect(() => {
    if (submissionId === submission?.submission_id) {
      return;
    }

    void getSubmission(formId, submissionId);
    ampli.formSubmissionViewed({
      formSubmissionViewedTime: new Date().toISOString(),
      activeStatus: true,
      createTime: 'formCreated',
      formId: '',
      formType: '',
      readStatus: true,
      updateTime: '',
      formName: submission?.form_name ?? '',
    });
  }, [formId, submissionId]);

  const navigation = useNavigation<FormsDrawerNavigationProp>();

  const goBack = () => {
    navigation.navigate('submissions-list');
  };

  const displayImage = (
    src: string,
    type: string,
    alt?: string,
    caption?: string,
  ) => {
    if (submission) {
      const blobPromise = FormsService.getSubmissionImage(
        submission.location_id,
        submission.form_id,
        submission.submission_id,
        src.split('/').at(-1) ?? '',
      );
      return (
        <Lightbox
          images={[{ src, useBlob: true, blobPromise, alt, caption }]}
          galleryProps={{
            options: {
              initialZoomLevel: 'fit',
              secondaryZoomLevel: 1,
            },
          }}
          imageStyle={{
            maxWidth: 200,
            maxHeight: 200,
          }}
          imgBgWhite={type === 'Signature'}
        />
      );
    }
  };

  const displayAnswer = (
    answer: string,
    answerType: string,
    answerTitle?: string,
  ) => {
    answer = answerType.includes('datetime') ? formatDate(answer) : answer;

    if (
      (answerType === 'Signature' || answerType === 'ImageUpload') &&
      answer.length
    ) {
      return displayImage(answer.split(',')[0], answerType, answerTitle);
    }

    if (answerType === 'DateOfBirth') {
      return moment(answer).format('MM/DD/YYYY');
    }

    if (answerType === 'InputTable') {
      return (
        <div
          className="answer-input-table"
          dangerouslySetInnerHTML={{ __html: filterXSS(answer.toString()) }}
        ></div>
      );
    }

    return answer.length
      ? answer.toString().replaceAll('<br>', '\n')
      : getText('not-entered');
  };

  const downloadSubmission = async () => {
    toast(getText('preparing-submission'), {
      type: 'info',
      content: getText('download-will-begin'),
    });
    setIsDownloadLoading(true);
    const data = await getSubmissionPDF(formId, submissionId);

    data &&
      downloadFile(
        data,
        getDateTimeFilename(
          `${submission?.form_name} ${submission?.name}`,
          'pdf',
        ),
      );

    setIsDownloadLoading(false);
  };

  return submission && status !== 'loading' ? (
    <View style={[styles.container, !withDelete && { marginHorizontal: 0 }]}>
      {withDelete && (
        <View style={styles.header}>
          <IconButton
            size={25}
            logger={{ id: 'go-back-submissions-button' }}
            icon={ChevronLeftIcon}
            onPress={goBack}
          />
          <Text style={styles.title} selectable>
            {submission.form_name}
          </Text>
          <VaccinationInfoButton vaccination={submission.vaccination} />
          <Button
            hierarchy="primary"
            size="medium"
            logger={{ id: 'download-submission-button' }}
            onPress={() => downloadSubmission()}
            icon={() => (
              <Icon size={20} color={theme.palette.white} icon={DownloadIcon} />
            )}
            loading={isDownloadLoading}
          >
            {getText('download-pdf')}
          </Button>
        </View>
      )}
      {isInsideModal && (
        <View style={[styles.header, styles.vaccinationButtonContainerInModal]}>
          <VaccinationInfoButton
            vaccination={submission.vaccination}
            isInsideModal={true}
          />
        </View>
      )}
      <View
        style={withDelete ? styles.submissionContainer : undefined}
        testID={SubmissionTestIDs.submissionWrapper}
      >
        <View style={styles.field}>
          <Text style={styles.label} selectable>
            {getText('submitted-on')}
          </Text>
          <Text style={styles.answer} selectable>
            {formatDateTime(submission.created_at)}
          </Text>
        </View>

        {submission.answers.map((answer, index) => (
          <View key={index} style={styles.field}>
            <Text style={styles.label} selectable>
              {answer.title}
            </Text>
            <Text
              selectable={true}
              style={[
                styles.answer,
                !answer.answer?.length && styles.greyedOut,
                answer.type === 'InputTable' && styles.inputTableAnswer,
              ]}
            >
              {displayAnswer(answer.answer, answer.type, answer.title)}
            </Text>
          </View>
        ))}
      </View>
      {submission.vaccination && (
        <VaccinationInfoSection vaccination={submission.vaccination} />
      )}
      <VaccinationModal />
      <UserAuthenticationModal />
    </View>
  ) : (
    <LoadingOverlay />
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'column',
    marginHorizontal: theme.getSpacing(4),
    marginBottom: theme.getSpacing(4),
    marginTop: theme.getSpacing(2),
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.getSpacing(2),
    gap: theme.getSpacing(1),
  },
  title: {
    marginLeft: theme.getSpacing(2),
    fontSize: 25,
    flexGrow: 1,
  },
  answer: {
    fontSize: 16,
    lineHeight: 24,
    overflow: 'hidden',
    color: theme.palette.gray[700],
    maxWidth: 600,
  },
  inputTableAnswer: {
    maxWidth: null,
  },
  greyedOut: {
    color: theme.palette.gray['400'],
  },
  field: {
    fontSize: 16,
    flexDirection: 'column',
    flexWrap: 'nowrap',
    gap: theme.getSpacing(1),
    marginBottom: theme.getSpacing(3),
  },
  label: {
    ...theme.fonts.medium,
    fontSize: 16,
    lineHeight: 18,
    color: theme.palette.gray[900],
    maxWidth: 600,
  },
  submissionContainer: {
    borderTopColor: theme.palette.gray[300],
    borderTopWidth: 1,
    paddingTop: theme.getSpacing(4),
  },
  vaccinationButtonContainerInModal: {
    marginTop: -theme.getSpacing(2),
  },
}));

interface SubmissionProps {
  submissionId: string;
  formId: string;
  withDelete?: boolean;
  isInsideModal?: boolean;
}
