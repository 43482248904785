import { EntityType, Role } from '@digitalpharmacist/role-service-client-axios';
import { getText } from 'assets/localization/localization';
import { FunctionComponent, useEffect, useState } from 'react';
import { convertRoleToLocalization } from '../../../../../utils';
import { RowItemRender } from '../../../../components/Settings/RowItemRender';
import { getNameById } from '../profile-settings-action';

export const RoleItemRenderer: FunctionComponent<RoleItemRendererPops> = ({
  role,
  pharmacy_id,
  entity_type,
  entity_id,
}) => {
  const [name, setName] = useState('');

  useEffect(() => {
    void getNameById(pharmacy_id, entity_type, entity_id).then((name) =>
      setName(name),
    );
  }, [entity_type, entity_id]);
  return (
    <RowItemRender
      title={name}
      value={getText(convertRoleToLocalization[role])}
    />
  );
};

interface RoleItemRendererPops {
  role: Role;
  pharmacy_id: string;
  entity_type: EntityType;
  entity_id: string;
}
