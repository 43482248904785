import { useEffect } from 'react';
import { BulkStatus } from '@digitalpharmacist/unified-communications-service-client-axios';

import { useAppStateStore } from '../../../store/app-store';

import {
  addBulk,
  changeStatusOfBulk,
} from '../stores/bulk-messages-store/bulk-messages-actions';
import { socket } from '../../../../socket';

export const useSocketsForBulkMessages = () => {
  const { locationId } = useAppStateStore();

  useEffect(() => {
    const logConnectionError = (error: any) => {
      console.error('Websocket connection error message: ', error.message);
      console.error(
        'Websocket connection error description: ',
        error.description,
      );
    };

    const onBulkSend = (bulk: { id: string; status: BulkStatus }) => {
      changeStatusOfBulk(bulk);
    };

    const onBulkCreated = async (bulk: { id: string; location_id: string }) => {
      await addBulk(bulk.id, locationId);
    };

    const onBulkInProgress = (bulk: { id: string; status: BulkStatus }) => {
      changeStatusOfBulk(bulk);
    };

    const onBulkFailed = (bulk: { id: string; status: BulkStatus }) => {
      changeStatusOfBulk(bulk);
    };

    const onBulkCanceled = (bulk: { id: string; status: BulkStatus }) => {
      changeStatusOfBulk(bulk);
    };

    socket.on('bulk_sent', onBulkSend);
    socket.on('new_bulk', onBulkCreated);
    socket.on('connect_error', logConnectionError);
    socket.on('bulk_in_progress', onBulkInProgress);
    socket.on('bulk_failed', onBulkFailed);
    socket.on('bulk_canceled', onBulkCanceled);

    return () => {
      socket.off('bulk_sent', onBulkSend);
      socket.off('new_bulk', onBulkCreated);
      socket.off('bulk_in_progress', onBulkInProgress);
      socket.off('bulk_failed', onBulkFailed);
      socket.off('bulk_canceled', onBulkCanceled);
      socket.off('connect_error', logConnectionError);
    };
  }, []);

  return null;
};
