import { makeStyles } from 'assets/theme';

export const useMessagingStyles = makeStyles((theme) => ({
  noPatientsWrapper: {
    alignItems: 'center',
    marginTop: '15%',
  },
  dropdownOptionContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: theme.getSpacing(0.5),
    margin: 0,
  },
  dropdownFullName: {
    marginLeft: theme.getSpacing(1),
    fontSize: 16,
  },
  dropdownDetails: {
    marginLeft: theme.getSpacing(1),
    color: theme.palette.gray[400],
    fontSize: 16,
  },
  findTemplate: {
    height: theme.getSpacing(4) + theme.getSpacing(0.5),
    borderWidth: 1,
    borderColor: theme.palette.gray[300],
    backgroundColor: theme.palette.white,
    borderRadius: 6,
  },
  templatesWrapper: {
    height: 308,
    overflowY: 'scroll',
  },
  template: {
    ...theme.fonts.regular,
    color: theme.palette.gray[700],
    fontSize: 13,
    marginTop: theme.getSpacing(1),
  },
}));
