import { FC } from 'react';
import { View } from 'react-native';
import theme, { makeStyles, useTheme } from 'assets/theme';
import { IRendererProps } from '../types';
import { useBulkTableStyles } from '../commonStyles';
import { PharmacyTooltipWrapper } from '../../../../common/PharmacyTooltipWrapper';
import { ChatIcon, NoLockIcon } from 'assets/icons';
import { Icon } from 'assets/components/icon';
import { BulkMessageType } from '@digitalpharmacist/unified-communications-service-client-axios';

export const SubjectRenderer: FC<IRendererProps> = ({ data }) => {
  const theme = useTheme();
  const styles = useStyles();

  const bulkMessageTypeId =
    data.message_type === BulkMessageType.Secure
      ? 'secure-chat-subject-renderer'
      : 'non-secure-subject-renderer';

  return (
    <>
      <div style={styles.container}>
        {/** the tooltip itself lives in <Bulk /> component */}
        <PharmacyTooltipWrapper tooltipId={bulkMessageTypeId}>
          <View style={styles.tooltipIcon}>
            {data.message_type === BulkMessageType.Secure ? (
              <Icon size={20} color={theme.palette.gray[700]} icon={ChatIcon} />
            ) : (
              <Icon
                size={20}
                color={theme.palette.gray[700]}
                icon={NoLockIcon}
              />
            )}
          </View>
        </PharmacyTooltipWrapper>

        <div style={styles.text}>{data.subject}</div>
      </div>
    </>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
    gap: theme.getSpacing(1),
    minWidth: 500,
  },
  text: {
    marginTop: -6,
    color: theme.palette.gray[700],
  },
  tooltipIcon: {
    paddingTop: 12,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },
}));
