// reference: https://github.com/pmndrs/zustand/blob/main/docs/guides/how-to-reset-state.md
import _create, { StoreApi, UseBoundStore } from 'zustand';
import type { StateCreator } from 'zustand';

export type BaseState = object;

const storeResetFns = new Set<() => void>();

export const resetAllStores = () => {
  storeResetFns.forEach((resetFn) => {
    resetFn();
  });
};

export const create = <T extends BaseState>(
  stateCreator: StateCreator<T>,
): UseBoundStore<T, StoreApi<T>> => {
  const store = _create(stateCreator);
  const initialState = store.getState();
  storeResetFns.add(() => {
    store.setState(initialState, true);
  });
  return store;
};
