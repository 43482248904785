/* eslint-disable @typescript-eslint/no-unsafe-assignment -- noisy and not handling today */
import Constants from 'expo-constants';
import { Environment } from '../src/ampli';

export const API_GATEWAY_BASE_URL: string =
  Constants.expoConfig?.extra?.API_GATEWAY_BASE_URL;

export const COGNITO_REGION: string =
  Constants.expoConfig?.extra?.COGNITO_REGION;

export const COGNITO_USER_POOL_ID: string =
  Constants.expoConfig?.extra?.COGNITO_USER_POOL_ID;

export const COGNITO_USER_POOL_WEB_CLIENT_ID: string =
  Constants.expoConfig?.extra?.COGNITO_USER_POOL_WEB_CLIENT_ID;

export const CONFIG_ENGINE_BASE_URL: string =
  Constants.expoConfig?.extra?.CONFIG_ENGINE_BASE_URL;

export const ERROR_LOGGER = {
  dsn: Constants.expoConfig?.extra?.SENTRY_DSN as string,
};

export const ENVIRONMENT: string = Constants.expoConfig?.extra?.ENVIRONMENT;

export const AMPLI_ENVIRONMENT: Environment =
  Constants.expoConfig?.extra?.AMPLI_ENVIRONMENT;

export const USER_API_BASE_URL: string =
  Constants.expoConfig?.extra?.USER_API_BASE_URL;

export const FORMS_API_BASE_URL: string =
  Constants.expoConfig?.extra?.FORMS_API_BASE_URL;

export const TASKS_API_BASE_URL: string =
  Constants.expoConfig?.extra?.TASKS_API_BASE_URL;

export const PATIENT_API_BASE_URL: string =
  Constants.expoConfig?.extra?.PATIENT_API_BASE_URL;

export const PRESCRIPTION_API_BASE_URL: string =
  Constants.expoConfig?.extra?.PRESCRIPTION_API_BASE_URL;

export const ROLE_API_BASE_URL: string =
  Constants.expoConfig?.extra?.ROLE_API_BASE_URL;

export const INBOX_SOCKET_URL: string =
  Constants.expoConfig?.extra?.INBOX_SOCKET_URL;

export const UNIFIED_COMMS_SERVICE_BASE_URL: string =
  Constants.expoConfig?.extra?.UNIFIED_COMMS_SERVICE_BASE_URL;

export const FILE_STORAGE_SERVICE_BASE_URL: string =
  Constants.expoConfig?.extra?.FILE_STORAGE_SERVICE_BASE_URL;

export const PUBLIC_FILE_STORAGE_SERVICE_BASE_URL: string =
  Constants.expoConfig?.extra?.PUBLIC_FILE_STORAGE_SERVICE_BASE_URL;

export const JOTFORM_API_BASE_URL: string =
  Constants.expoConfig?.extra?.JOTFORM_API_BASE_URL;

export const APPOINTMENT_API_BASE_URL: string =
  Constants.expoConfig?.extra?.APPOINTMENT_API_BASE_URL;

export const PHARMACY_API_BASE_URL: string =
  Constants.expoConfig?.extra?.PHARMACY_API_BASE_URL;

export const GOOGLE_WEB_CLIENT_ID: string =
  Constants.expoConfig?.extra?.GOOGLE_WEB_CLIENT_ID;
export const GOOGLE_EXPO_CLIENT_ID: string =
  Constants.expoConfig?.extra?.GOOGLE_EXPO_CLIENT_ID;
export const GOOGLE_IOS_CLIENT_ID: string =
  Constants.expoConfig?.extra?.GOOGLE_IOS_CLIENT_ID;
export const GOOGLE_ANDROID_CLIENT_ID: string =
  Constants.expoConfig?.extra?.GOOGLE_ANDROID_CLIENT_ID;

export const GSTC_LICENSE_KEY: string =
  Constants.expoConfig?.extra?.GSTC_LICENSE_KEY;
export const VUCA_API_BASE_URL: string =
  Constants.expoConfig?.extra?.VUCA_API_BASE_URL;
export const PATIENT_APP_BASE_URL: string =
  Constants.expoConfig?.extra?.PATIENT_APP_BASE_URL;
export const PMS_INTEGRATIONS_API_BASE_URL =
  Constants.expoConfig?.extra?.PMS_INTEGRATIONS_API_BASE_URL;
export const MEDICATION_SERVICE_BASE_URL =
  Constants.expoConfig?.extra?.MEDICATION_SERVICE_BASE_URL;
export const AG_GRID_LICENSE_KEY =
  Constants.expoConfig?.extra?.AG_GRID_LICENSE_KEY;

export const INTERCOM_APP_ID = Constants.expoConfig?.extra?.INTERCOM_APP_ID;
export const INTERCOM_ENABLED = parseBool(
  Constants.expoConfig?.extra?.INTERCOM_ENABLED,
);

export const AG_GRID_PAGINATION_PAGE_SIZE: number =
  Constants.expoConfig?.extra?.AG_GRID_PAGINATION_PAGE_SIZE ?? 25;

export const HELP_JUICE_URL: string =
  Constants.expoConfig?.extra?.HELP_JUICE_URL;

function parseBool(boolString: string): boolean {
  return boolString.toLocaleLowerCase() === 'true';
}

export const USERWAY_ACCOUNT_ID =
  Constants.expoConfig?.extra?.USERWAY_ACCOUNT_ID;

export const imageExtensions: string[] = [
  'image/apng',
  'image/avif',
  'image/gif',
  'image/jpg',
  'image/jpeg',
  'image/jfif',
  'image/pjpeg',
  'image/pjp',
  'image/png',
  'image/svg',
  'image/webp',
];
