import { ErrorStatus, useErrorState } from './error-store';

export const setError = (
  selectedObject: string,
  status: ErrorStatus,
  message: string,
) => {
  const { errorObject } = useErrorState.getState();
  const newErrorObject = {
    ...errorObject,
    [selectedObject]: {
      status: status,
      message: message,
    },
  };
  useErrorState.setState({ errorObject: newErrorObject });
};

export const removeError = (selectedObject: string) => {
  const { errorObject } = useErrorState.getState();
  const newErrorObject = {
    ...errorObject,
  };
  delete newErrorObject[selectedObject];
  useErrorState.setState({ errorObject: newErrorObject });
};
