import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Svg, Path, Rect } from 'react-native-svg';
import { IconProps } from './types';

export const TrayIcon: FunctionComponent<PropsWithChildren<IconProps>> = ({
  color,
  size,
  testID,
  strokeWidth,
}) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
      testID={testID}
    >
      <Path
        d="M21 9h-6l-2 3H9L7 9H1m20 0v6a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9m20 0-3.45-6.89A2 2 0 0 0 15.76 1H6.24a2 2 0 0 0-1.79 1.11L1 9"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
