import {
  BusinessType,
  TimezoneDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { Text } from 'assets/components/text';
import { getText } from 'assets/localization/localization';
import { FunctionComponent, PropsWithChildren } from 'react';
import { TouchableOpacity } from 'react-native';
import { ensureHttpsUrl } from '../../../../../utils';
import { openUrl } from '../../../../actions/app-actions';
import { canFactory } from '../../../../common/guards/permission-guard';
import { RowItemRender } from '../../../../components/Settings/RowItemRender';
import { SettingsTooltipsComponents } from '../../../../components/Settings/SettingsTooltipsComponents';

export const LocationGeneralInfoItemRenderer: FunctionComponent<
  PropsWithChildren<GeneralInfoItemRendererProps>
> = ({
  name,
  internal_name,
  legal_name,
  location_jurisdiction_of_incorporation,
  dba,
  active,
  email,
  pms,
  ncpdp,
  npi,
  duns_number,
  tax_id,
  phone,
  fax,
  business_type,
  store_number,
  timezone,
  languages_spoken,
  additional_offerings,
  google_review_url,
}) => {
  const canLumistryUserUpdateSettings = canFactory('update')(
    'settingsLumistryUser',
  );

  return (
    <>
      <RowItemRender
        title={getText('locations-location-name')}
        icon={
          <SettingsTooltipsComponents
            tooltipId="location-general-info-external-name"
            tooltipText={getText('locations-location-name-tooltip')}
          />
        }
        value={name}
      />

      <RowItemRender
        title={getText('locations-internal-name')}
        icon={
          <SettingsTooltipsComponents
            tooltipId="location-general-info-internal-name"
            tooltipText={getText('locations-internal-name-tooltip')}
          />
        }
        value={internal_name}
      />

      <RowItemRender
        title={getText('legal-entity-name')}
        icon={
          <SettingsTooltipsComponents
            tooltipId="location-general-info-legal-entity-name"
            tooltipText={getText('legal-entity-name-tooltip')}
          />
        }
        value={legal_name}
      />

      <RowItemRender
        title={getText('jurisdiction')}
        value={location_jurisdiction_of_incorporation}
        icon={
          <SettingsTooltipsComponents
            tooltipId="location-jurisdiction"
            tooltipText={getText('jurisdiction-tooltip')}
          />
        }
      />

      <RowItemRender
        title={getText('dba')}
        icon={
          <SettingsTooltipsComponents
            tooltipId="location-dba"
            tooltipText={getText('dba-tooltip')}
          />
        }
        value={dba}
      />

      <RowItemRender
        title={getText('locations-status')}
        value={
          active ? getText('locations-active') : getText('locations-inactive')
        }
      />

      <RowItemRender title={getText('location-email-address')} value={email} />

      <RowItemRender
        title={getText('pharmacy-mgmt-system')}
        icon={
          canLumistryUserUpdateSettings ? (
            <></>
          ) : (
            <SettingsTooltipsComponents
              tooltipId="location-general-info-pharmacy-mgmt-system"
              tooltipText={getText('pharmacy-mgmt-system-tooltip')}
            />
          )
        }
        value={pms}
      />

      <RowItemRender title={getText('locations-NCPDP')} value={ncpdp} />
      <RowItemRender title={getText('locations-NPI')} value={npi} />

      <RowItemRender title={getText('duns-number')} value={duns_number} />
      <RowItemRender title={getText('tax-id')} value={tax_id} />
      <RowItemRender title={getText('locations-phone-number')} value={phone} />
      <RowItemRender title={getText('locations-fax-number')} value={fax} />
      <RowItemRender title={getText('business-type')} value={business_type} />

      <RowItemRender
        title={getText('locations-store-number')}
        value={store_number}
      />
      <RowItemRender
        title={getText('locations-time-zone')}
        value={timezone?.name}
      />
      <RowItemRender
        title={getText('locations-languages-spoken')}
        value={languages_spoken}
      />
      <RowItemRender
        title={getText('offerings')}
        value={additional_offerings}
      />
      <RowItemRender title={getText('google-review')}>
        {google_review_url && (
          <TouchableOpacity
            onPress={() => openUrl(ensureHttpsUrl(google_review_url), '_blank')}
          >
            <Text>{google_review_url}</Text>
          </TouchableOpacity>
        )}
      </RowItemRender>
    </>
  );
};

interface GeneralInfoItemRendererProps {
  name: string;
  internal_name?: string;
  legal_name?: string;
  location_jurisdiction_of_incorporation?: string;
  dba?: string;
  active: boolean;
  email?: string;
  pms?: string;
  ncpdp?: string;
  npi?: string;
  duns_number?: string;
  tax_id?: string;
  phone?: string;
  fax?: string;
  business_type?: BusinessType;
  store_number?: string;
  timezone?: TimezoneDto;
  languages_spoken?: string;
  additional_offerings?: string;
  google_review_url?: string;
}
