import { Icon } from 'assets/components/icon';
import { Text } from 'assets/components/text';
import { AlertCircleIcon, CheckIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import React, { FunctionComponent } from 'react';
import { Item, ItemParams, Menu } from 'react-contexify';
import { View } from 'react-native';
import { ContextMenuRendererProps } from './ContextMenuRenderer';
import { refillMarkAsNotPassed, refillMarkAsPassed } from '../refills-actions';
import { RefillStatus } from '@digitalpharmacist/pms-integration-service-client-axios';

export const RefillsContextMenu: React.FC<RefillsContextMenuProps> = ({
  id,
}) => {
  return (
    <Menu id={id}>
      <Item
        disabled={({ props }: ItemDisableParams) =>
          props?.data.refill_status === RefillStatus.Accepted
        }
        onClick={({ props }: ItemOnClickParams) => {
          if (!props?.data) return;

          void refillMarkAsPassed(props.data, props.api);
        }}
      >
        <MenuOptionItem icon={CheckIcon} label={getText('mark-as-passed')} />
      </Item>
      <Item
        disabled={({ props }: ItemDisableParams) =>
          props?.data.refill_status === RefillStatus.Rejected ||
          props?.data.refill_status === undefined
        }
        onClick={({ props }: ItemOnClickParams) => {
          if (!props?.data) return;

          void refillMarkAsNotPassed(props.data, props.api);
        }}
      >
        <MenuOptionItem
          icon={AlertCircleIcon}
          label={getText('mark-as-not-passed')}
        />
      </Item>
    </Menu>
  );
};

type ItemOnClickParams = ItemParams<ContextMenuRendererProps, any>;
type ItemDisableParams = {
  props?: ContextMenuRendererProps;
};

type RefillsContextMenuProps = {
  id: string;
};

const MenuOptionItem = (props: {
  icon: FunctionComponent;
  label: string;
  color?: string;
  selected?: boolean;
}) => {
  const styles = useStyles();
  const theme = useTheme();

  return (
    <View style={styles.menuOptionContainer}>
      <Icon
        icon={props.icon}
        color={
          props.selected
            ? theme.palette.primary[500]
            : props.color || theme.palette.gray[500]
        }
      ></Icon>
      <Text
        style={[
          styles.menuOptionLabel,
          {
            color: props.selected
              ? theme.palette.primary[500]
              : theme.palette.gray[900],
          },
        ]}
        selectable
      >
        {props.label}
      </Text>
      {props.selected && (
        <View
          style={{
            alignSelf: 'flex-end',
          }}
        >
          <Icon icon={CheckIcon} color={theme.palette.primary[500]}></Icon>
        </View>
      )}
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  menuOptionContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    paddingHorizontal: theme.getSpacing(1),
    alignItems: 'center',
  },
  menuOptionLabel: {
    color: theme.palette.gray[900],
    marginLeft: theme.getSpacing(1),
    flexGrow: 1,
  },
}));
