import React, { PropsWithChildren, useEffect, useState } from 'react';
import * as Linking from 'expo-linking';
import { ParamListBase, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { AppRedirectionConfig, AppRedirectionProviderProp } from '../types';
import { AppRedirectionContext } from '../context';
import { DefaultAppRedirectionConfig } from '../config';

export const AppRedirectionProvider: React.FunctionComponent<
  PropsWithChildren<AppRedirectionProviderProp>
> = ({
  children,
  appRedirectionConfig,
  returnUrlKey = 'returnUrl',
  isAuthorized,
  publicRoutes,
}) => {
  const [initialUrl, setInitialUrl] = useState<string | null>(null);
  const navigation = useNavigation<StackNavigationProp<ParamListBase>>();

  useEffect(() => {
    void (async () => {
      const initialUrl = await Linking.getInitialURL();
      if (initialUrl) {
        const { path } = Linking.parse(initialUrl);
        const firstRoute = path?.split('/')[0] ?? '/';
        if (!publicRoutes?.includes(firstRoute)) setInitialUrl(initialUrl);
      }
    })();
  }, []);

  const [currentAppRedirection, setCurrentAppRedirection] =
    React.useState<AppRedirectionConfig>(
      appRedirectionConfig || DefaultAppRedirectionConfig,
    );

  const setAppRedirectionConfig = (override: Partial<AppRedirectionConfig>) => {
    setCurrentAppRedirection((curr) => ({
      ...curr,
      ...override,
    }));
  };

  const navigateTo = (path: string, queryParams: Record<string, string>) => {
    // considering that each route will be separated by '/'
    // we should never use that character as the name of a screen as
    // <Stack.Screen name="profile/settings" component={} /> as this will be treated as 2 routes
    // instead of the slash we should use dash '-' (as it is implemented till now)
    const routes = path.split('/');
    const rootPath = routes.shift() ?? '/';

    const params: Record<string, any> =
      routes.length > 0
        ? routes.reduceRight(
            // create nested params recursively
            (acc, curr, index) => ({
              screen: curr,
              params: index === routes.length - 1 ? queryParams : acc,
            }),
            {},
          )
        : // otherwise returns actual query parameters
          queryParams;

    navigation.navigate(rootPath, params);
  };

  const handleAuthorizationChange = () => {
    if (!initialUrl) return;

    const { path, queryParams } = Linking.parse(initialUrl);
    if (!path) return;

    if (!isAuthorized) {
      if (!queryParams?.[returnUrlKey]) {
        // take current return key otherwise take url as return key
        const returnUrl: string =
          (queryParams?.[returnUrlKey] as string) ??
          [
            path,
            queryParams &&
              new URLSearchParams(
                queryParams as Record<string, string>,
              ).toString(),
          ]
            .filter((x) => x)
            .join('?');
        const params: any = {
          [returnUrlKey]: returnUrl,
        };

        setInitialUrl(
          ['login', new URLSearchParams(params).toString()].join('?'),
        );

        navigation.setParams(params);
      }
    }

    if (isAuthorized && currentAppRedirection.arePrivateRoutesLoaded) {
      // if the url contains the return key on query params then redirect to that url
      if (!initialUrl) return;
      const { path, queryParams } = Linking.parse(initialUrl);
      if (!path) return;
      if (queryParams?.[returnUrlKey]) {
        const [returnUrlPath, returnUrlQueryParams] = (
          queryParams[returnUrlKey] as string
        ).split('?');

        navigateTo(
          returnUrlPath,
          Object.fromEntries(new URLSearchParams(returnUrlQueryParams)),
        );
      }

      // clear initial url after redirection
      setInitialUrl('');
    }
  };

  // subscribe to authorization state changes
  useEffect(() => {
    if (!initialUrl) return;
    if (!currentAppRedirection.handleAutomaticRedirection) return;

    void handleAuthorizationChange();
  }, [
    isAuthorized,
    currentAppRedirection.handleAutomaticRedirection,
    currentAppRedirection.arePrivateRoutesLoaded,
    currentAppRedirection.arePublicRoutesLoaded,
    initialUrl,
  ]);

  return (
    <AppRedirectionContext.Provider
      value={{
        appRedirectionConfig: currentAppRedirection,
        setAppRedirectionConfig: setAppRedirectionConfig,
      }}
    >
      {children}
    </AppRedirectionContext.Provider>
  );
};
