import {
  NavigationProp,
  useNavigation,
  useNavigationState,
} from '@react-navigation/native';
import { getText } from 'assets/localization/localization';
import theme, { getSpacing, makeStyles } from 'assets/theme';
import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import {
  VideoDetailParamsProp,
  VideosDrawerNavigationParamList,
} from '../../layout/VideosDrawer';
import { useVideosState } from '../../store/videos-store';

const AlphabetFilter: React.FC = () => {
  const styles = useStyles();
  const navigation =
    useNavigation<NavigationProp<VideosDrawerNavigationParamList>>();
  const navigationState = useNavigationState((state) => state);
  const focusedRouteName = navigationState.routes[navigationState.index];
  const alphabetChars = Array.from({ length: 26 }, (_, index) =>
    String.fromCharCode(65 + index),
  );
  const { filter, setFilter } = useVideosState();

  const navigateToTabMenu = () => {
    const focusedRouteMap: VideoDetailParamsProp | undefined =
      focusedRouteName.state?.routes[focusedRouteName.state.index || 0]?.params;
    if (focusedRouteMap?.collection_page) {
      navigation.navigate(focusedRouteMap.collection_page);
    }
  };

  const handleCharSelection = (char: string) => {
    setFilter(char);
    navigateToTabMenu();
  };

  const handleClearSelection = () => {
    setFilter(null);
    navigateToTabMenu();
  };

  return (
    <View style={styles.sidebar}>
      <View style={styles.filterHeader}>
        <Text style={styles.filter}>{getText('videos-filter')}</Text>
        <TouchableOpacity onPress={handleClearSelection}>
          <Text style={styles.clear}>{getText('videos-clear')}</Text>
        </TouchableOpacity>
      </View>
      <View style={styles.charGroup}>
        {alphabetChars.map((char) => (
          <TouchableOpacity
            key={char}
            style={char === filter ? styles.selectedBadge : styles.badge}
            onPress={() => handleCharSelection(char)}
          >
            <Text
              style={
                char === filter ? styles.selectedBadgeText : styles.badgeText
              }
            >
              {char}
            </Text>
          </TouchableOpacity>
        ))}
      </View>
    </View>
  );
};

export default AlphabetFilter;

const useStyles = makeStyles(() => ({
  sidebar: {
    paddingLeft: getSpacing(2),
    margin: getSpacing(3),
    display: 'flex',
  },
  filterHeader: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
    paddingRight: getSpacing(2),
  },
  charGroup: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  badge: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: getSpacing(2),
    width: 30,
    height: 24,
    borderRadius: 100,
  },
  badgeText: {
    color: theme.palette.gray[700],
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 20,
  },
  selectedBadgeText: {
    color: theme.palette.white,
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 20,
  },
  selectedBadge: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: getSpacing(2),
    width: 30,
    height: 24,
    borderRadius: 100,
    backgroundColor: theme.palette.primary[600],
  },
  filter: {
    fontSize: 12,
    lineHeight: 18,
    fontWeight: '600',
    color: theme.palette.gray[500],
  },
  clear: {
    fontSize: 12,
    lineHeight: 18,
    fontWeight: '600',
    color: theme.palette.primary[600],
  },
}));
