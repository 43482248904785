import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path, G, Defs, ClipPath } from 'react-native-svg';
import { IconProps } from './types';

export const LumistryAuthIcon: FunctionComponent<
  PropsWithChildren<IconProps>
> = ({ size, testID }) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      testID={testID}
    >
      <G clip-path="url(#clip0_2295_86550)">
        <Path
          d="M13 14H4.11652C2.39531 14 1 12.6569 1 11C1 9.34315 2.39531 8 4.11652 8H6.82232"
          stroke="#0063B2"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <Path
          d="M14.0069 6.53633C14.0069 4.9668 12.5596 3.69444 10.7743 3.69444C8.98897 3.69444 7.54167 4.9668 7.54167 6.53632V7.53472M11.2361 1V1.38542M7.19531 1.0164L7.88802 2.21621M15.2769 1.0164L14.5842 2.21621"
          stroke="#27A4ED"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_2295_86550">
          <rect width="16" height="16" fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};
