import {
  AutoSaveWebsiteWizardDto,
  FinalizeWebsiteWizardDto,
  YesNoOption,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import * as validate from '@digitalpharmacist/validation-dp';
import { CheckboxField, CheckboxInputMode } from 'assets/components/checkbox';
import { Modal } from 'assets/components/modal';
import { Text } from 'assets/components/text';
import { TextField } from 'assets/components/text-field';
import { Form } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { logError } from 'assets/logging/logger';
import { makeStyles } from 'assets/theme';
import React, { FunctionComponent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { View } from 'react-native';
import {
  savePatientWebsite,
  setOpenedModal,
  submitPatientWebsite,
} from '../../../../actions/checklist-actions';
import { useChecklistStore } from '../../../../store/checklist-store';
import { ChecklistWebsiteModalProps } from '../../types';
import { getAccessButtonText } from '../../utils';
import { isPassword } from '@digitalpharmacist/validation-dp';

interface DomainDetails {
  access_granted: YesNoOption | undefined;
  domain_name: string | undefined;
  domain_host: string | undefined;
  login_name: string | undefined;
  login_password: string | undefined;
}

export const WebsiteAccessPermissionModal: FunctionComponent<
  ChecklistWebsiteModalProps
> = ({ id, data }) => {
  const { currentOpenedModalId } = useChecklistStore();
  const [loading, setLoading] = useState<boolean>(false);
  const styles = useStyles();
  const service = data.website_required;
  const initialData = data.website_access.domain_details;

  const methods = useForm<DomainDetails>({
    defaultValues: {
      access_granted:
        initialData?.access_permission_granted !== 'Unknown'
          ? initialData?.access_permission_granted
          : undefined,
      domain_name: initialData?.domain_name,
      domain_host: initialData?.domain_host,
      login_name: initialData?.login_name,
      login_password: initialData?.login_password,
    },
  });

  const handleAccessNextStep = async (dto: AutoSaveWebsiteWizardDto) => {
    try {
      switch (service) {
        case 'build-do-not-have':
        case 'build-to-replace':
          await savePatientWebsite(dto);
          setOpenedModal('patient-website-complete');
          break;
        case 'do-not-build-already-have':
        case 'do-not-build-do-not-need':
          await submitPatientWebsite(dto as FinalizeWebsiteWizardDto);
          setOpenedModal('checklist-setup');
          break;
        default:
          break;
      }
    } catch (error: any) {
      throw Error(error);
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const dto = {
        ...data,
        website_access: {
          ...data.website_access,
          domain_details: {
            ...data.website_access.domain_details,
            access_permission_granted: (methods.getValues('access_granted')
              ? 'Yes'
              : 'No') as any,
            domain_name: methods.getValues('domain_name'),
            domain_host: methods.getValues('domain_host'),
            login_name: methods.getValues('login_name'),
            login_password: methods.getValues('login_password'),
          },
        },
      };
      await handleAccessNextStep(dto);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={getText('domain-details')}
      show={currentOpenedModalId === id}
      handleDismissModal={() => setOpenedModal('checklist-setup')}
      showDismissButton
      isScrollable
      size="sm"
      buttons={[
        {
          hierarchy: 'primary',
          text: getAccessButtonText(service),
          onPress: methods.handleSubmit(handleSubmit),
          logger: { id: 'next-website-access-permission' },
          loading,
        },
        {
          text: getText('back'),
          hierarchy: 'tertiary-gray',
          onPress: () => setOpenedModal('website-access-setup'),
          logger: { id: 'back-website-access-permission' },
        },
      ]}
    >
      <Form methods={methods}>
        <Form.Row>
          <Form.Column>
            <Text style={styles.title}>
              {getText('publish-website-need-permission')}
            </Text>
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <CheckboxField
              label={getText('give-lumistry-permission')}
              name="access_granted"
              mode={CheckboxInputMode.FLAT}
              testID="give-lumistry-permission"
              rules={{
                required: getText('field-required'),
              }}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <View style={styles.divider} />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <Text style={styles.title}>
              {getText('please-provide-domain-details')}
            </Text>
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              label={getText('domain-name')}
              name="domain_name"
              type="text"
              rules={{
                required: getText('field-required'),
                validate: {
                  value: (value) => {
                    return validate.isName(value)
                      ? true
                      : getText('field-not-valid');
                  },
                },
              }}
              testID="domain-name"
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              label={getText('domain-host')}
              name="domain_host"
              type="text"
              rules={{
                required: getText('field-required'),
                validate: {
                  value: (value) => {
                    return validate.isName(value)
                      ? true
                      : getText('field-not-valid');
                  },
                },
              }}
              testID="domain-host"
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              label={getText('login-user-name')}
              name="login_name"
              type="text"
              rules={{
                required: getText('field-required'),
                validate: {
                  value: (value) => {
                    return validate.isName(value)
                      ? true
                      : getText('field-not-valid');
                  },
                },
              }}
              testID="login-user-name"
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              label={getText('login-password')}
              type="password"
              name="login_password"
              rules={{
                required: getText('field-required'),
                validate: {
                  isPassword: (value) => {
                    return isPassword(value)
                      ? true
                      : getText('password-entry-does-not-meet-criteria');
                  },
                },
              }}
              testID="login-password"
            />
          </Form.Column>
        </Form.Row>
      </Form>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.lumistryFonts.text.medium.regular,
    color: theme.palette.gray[700],
  },
  divider: {
    borderBottomColor: theme.palette.gray[100],
    borderBottomWidth: 1,
    paddingBottom: theme.getSpacing(1),
  },
}));
