import React, { useEffect, useState } from 'react';
import { getText } from 'assets/localization/localization';
import SettingsPageLayout from '../../../components/Settings/SettingsPageLayout';
import SettingsSection from '../../../components/Settings/SettingsSection';
import { BillingItemRender } from './BillingItemRender';
import { useAppStateStore } from '../../../store/app-store';
import { useIsFocused } from '@react-navigation/native';
import { useBillingOptionsSettingsState } from './billing-settings-store';
import {
  loadBillingData,
  setGeneralSettings,
} from './billing-settings-actions';
import { PaymentAndDeliveryCard } from './PaymentAndDeliveryCard';
import { GeneralSettingsItemRender } from './GeneralSettingsItemRender';
import { Text } from 'assets/components/text';
import { LoadingOverlay } from '../../../components/LoadingOverlay';
import { Icon } from 'assets/components/icon';
import { LocationIcon } from 'assets/icons';
import { useTheme } from 'assets/theme';
import { View } from 'react-native';

export default function BillingSettings() {
  const { generalSettings, billingPlans, loading } =
    useBillingOptionsSettingsState();
  const isFocused = useIsFocused();
  const theme = useTheme();

  const { locationId, userMostRecentLocations, hasMoreLocations } =
    useAppStateStore();

  const selectedLocation = userMostRecentLocations.find(
    (x) => x.location_id === locationId,
  );

  useEffect(() => {
    if (!isFocused) return;
    setGeneralSettings(locationId);
    loadBillingData(locationId);
  }, [locationId, isFocused]);

  return (
    <SettingsPageLayout
      title={getText('usage')}
      headerComponent={
        <View
          style={{
            alignItems: 'center',
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <View>
            <Icon
              icon={LocationIcon}
              size={20}
              color={theme.palette.gray[700]}
            />
          </View>
          <View>
            <Text
              style={{
                ...theme.lumistryFonts.text.medium.regular,
                color: theme.palette.gray[700],
              }}
            >
              {selectedLocation?.location_full_display_name}
            </Text>
          </View>
        </View>
      }
    >
      {loading ? (
        <LoadingOverlay size={40} />
      ) : (
        <>
          <SettingsSection
            title={getText('general')}
            contentHeader={{ title: getText('details') }}
            contentData={generalSettings}
            contentItem={GeneralSettingsItemRender}
          />
          <SettingsSection
            title={getText('message-plans')}
            contentHeader={{ title: getText('details') }}
            contentData={billingPlans}
            contentItem={BillingItemRender}
          />
          <PaymentAndDeliveryCard />
        </>
      )}
    </SettingsPageLayout>
  );
}
