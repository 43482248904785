import React, { FunctionComponent, useRef } from 'react';
import { View } from 'react-native';
import { makeStyles } from 'assets/theme';
import { OBContentHeader } from '../content-header/OBContentHeader';
import OBDivider from '../divider/OBDivider';
import { getText } from 'assets/localization/localization';
import { useOnboardingStore } from '../../../../store/onboarding-store';
import { useWizard } from 'react-use-wizard';
import { OBEmployeesIcon } from '../icons/OBEmployeesIcon';
import { OBEmployeesForm } from '../forms/OBEmployeesForm';
import { OBFormHandler } from '../forms';
import { save } from '../../../../actions/onboarding-actions';

export const OBSingleLocationEmployees: FunctionComponent = () => {
  const styles = useStyles();
  const { handleStep } = useWizard();
  const location = useOnboardingStore((x) => x.data.locations[0]);
  const obEmployeesFormRef = useRef<OBFormHandler>(null);

  handleStep(async () => {
    await obEmployeesFormRef.current?.handleSubmit();
    await save();
  });

  return (
    <>
      <View style={styles.root}>
        <OBContentHeader
          icon={OBEmployeesIcon}
          title={getText('ob-employees-title')}
          description={getText('ob-employees-description')}
        />
        <OBEmployeesForm location={location} ref={obEmployeesFormRef} />

        <OBDivider />
      </View>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    alignContent: 'center',
  },
  tableContainer: {
    flex: 1,
    width: 700,
    minHeight: 440,
    marginVertical: theme.getSpacing(2),
  },
  newButtonContainer: {
    alignItems: 'flex-end',
    marginVertical: theme.getSpacing(2),
  },
  pressableContainer: {
    flexDirection: 'row',
    gap: theme.getSpacing(0.5),
    alignItems: 'center',
  },
  addButtonText: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.primary[600],
  },
  inputCellRenderer: {
    borderWidth: 0,
    height: 58,
    width: 175,
  },
  selectCellRendererControl: { border: 0, borderRadius: 0 },
  subtitle: {
    ...theme.lumistryFonts.text.large.regular,
    color: theme.palette.primary[600],
    textAlign: 'center',
  },
  modalList: {
    paddingTop: theme.getSpacing(2),
  },
  itemContainer: {
    flexDirection: 'row',
    gap: theme.getSpacing(0.5),
    color: theme.palette.gray[700],
  },
  itemTitle: {
    ...theme.lumistryFonts.text.small.bold,
  },
  itemText: {
    ...theme.lumistryFonts.text.small.regular,
  },
}));
