import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path } from 'react-native-svg';
import { IconProps } from './types';

export const EmailOpenedIcon: FunctionComponent<
  PropsWithChildren<IconProps>
> = ({ color, size, testID }) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 16 15"
      fill="none"
      color={color}
      testID={testID}
    >
      <Path
        d="M14.6666 4.66667V12C14.6666 12.7333 14.0666 13.3333 13.3333 13.3333H2.66659C1.93325 13.3333 1.33325 12.7333 1.33325 12V4.66667M14.6666 4.66667L7.99992 8.66667L1.33325 4.66667M14.6666 4.66667L7.99992 1L1.33325 4.66667"
        stroke={color || '#748995'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
