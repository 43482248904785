import { FunctionComponent, useMemo, useState } from 'react';
import unifiedCommsService from '../../../../../../api/UnifiedCommsService';
import { Modal } from 'assets/components/modal';
import { getText } from 'assets/localization/localization';
import { View } from 'react-native';
import { DataGrid } from 'assets/components/data-grid';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import theme from 'assets/theme';
import { NameRenderer } from './NameRenderer';
import { StatusRenderer } from './StatusRenderer';
import { DOBRenderer } from './DOBRenderer';
import { ColDef, ColGroupDef } from '@ag-grid-community/core';
import {
  GridReadyEvent,
  IDatasource,
  IGetRowsParams,
} from '@ag-grid-community/core';
import { GLOBAL_SEARCH_FIELD } from 'assets/components/data-grid/constant';
import { GridSearch } from '../../../../../patients/patient-types';
import { AG_GRID_PAGINATION_PAGE_SIZE } from '../../../../../../common/constants';

interface BulkMessageRecipientsModalProps {
  title: string;
  show: boolean;
  onClose: () => void;
  locationId: string;
  bulkId: string;
}

export const BulkMessageRecipientsModal: FunctionComponent<
  BulkMessageRecipientsModalProps
> = ({ title, show, onClose, locationId, bulkId }) => {
  const [gridApi, setGridApi] = useState<any>();

  const dataSource: IDatasource = useMemo(() => {
    return {
      getRows: async (params: IGetRowsParams) => {
        try {
          gridApi?.showLoadingOverlay();
          const { filterModel, sortModel, startRow, endRow, successCallback } =
            params;
          const gridSearch: GridSearch | undefined =
            filterModel && filterModel[GLOBAL_SEARCH_FIELD];

          const loadedRecipientsData = await unifiedCommsService.getRecipients(
            locationId,
            bulkId,
            startRow,
            endRow - startRow,
            gridSearch?.filter,
          );

          successCallback(
            loadedRecipientsData.items,
            loadedRecipientsData.count,
          );
          gridApi?.hideOverlay();
        } catch (error: any) {
          gridApi?.hideOverlay();
          console.error('Error recipients rows', error);
        }
      },
    };
  }, [gridApi, locationId, bulkId]);

  const recipientsColumns: Array<ColDef | ColGroupDef> = [
    {
      field: 'name',
      headerName: getText('name'),
      cellRenderer: NameRenderer,
      sortable: false,
      cellStyle: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
      },
    },
    {
      field: 'DOB',
      headerName: 'DOB',
      cellRenderer: DOBRenderer,
      sortable: false,
      cellStyle: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
      },
    },
    {
      field: 'action',
      headerName: getText('status'),
      cellRenderer: StatusRenderer,
      sortable: false,
    },
  ];

  return (
    <Modal
      size="lg"
      title={title}
      show={show}
      isScrollable
      buttons={[
        {
          onPress: onClose,
          text: getText('close'),
          logger: { id: 'close-button-modal-bulk-message-recipients' },
          hierarchy: 'secondary',
          testID: 'close-button-modal-bulk-message-recipients',
        },
      ]}
      handleDismissModal={onClose}
    >
      <View style={{ minHeight: 584 }}>
        <DataGrid
          gridOptions={{
            columnDefs: recipientsColumns,
            enableCellTextSelection: true,
            suppressMovableColumns: true,
            suppressContextMenu: true,
            defaultColDef: { sortable: true, menuTabs: [] },
            loadingOverlayComponent: 'loadingIndicator',
            loadingOverlayComponentParams: {
              color: theme.colors.brandedPrimary,
            },
            components: {
              loadingIndicator: LoadingIndicator,
            },
            rowSelection: 'single',
            suppressCellFocus: true,
            colResizeDefault: 'shift',
            paginationPageSize: AG_GRID_PAGINATION_PAGE_SIZE,
            cacheBlockSize: AG_GRID_PAGINATION_PAGE_SIZE,
            maxBlocksInCache: 2,
            pagination: true,
            rowModelType: 'infinite',
            datasource: dataSource,
            onGridReady: (event: GridReadyEvent) => {
              setGridApi(event.api);
            },
          }}
          gridToolbarProps={{
            inputSearchProps: {
              size: 'lg',
              placeholder: getText('name-dob'),
            },
          }}
        />
      </View>
    </Modal>
  );
};
