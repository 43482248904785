import { Fragment, FunctionComponent, useMemo, useState } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { TextInput } from 'react-native-paper';
import { Text } from 'assets/components/text';
import { getText } from 'assets/localization/localization';
import { defaultTemplates } from '../common/defaultTemplates';
import theme from 'assets/theme';
import { Modal } from 'assets/components/modal';
import { ITemplate } from 'assets/types/messageTypes';
import { useMessagingStyles } from '../common/styles';

export const TemplatesModal: FunctionComponent<TemplatesModalProps> = ({
  title,
  show,
  onSelectTemplate,
  onCancel,
}) => {
  const [templatesFilter, setTemplatesFilter] = useState('');
  const styles = useMessagingStyles();

  const filteredTemplates = useMemo(() => {
    const sortedTemplates = defaultTemplates.sort(
      (currentTemplate, nextTemplate) =>
        currentTemplate.name.localeCompare(nextTemplate.name),
    );
    if (!templatesFilter) {
      return sortedTemplates;
    }

    const regExp = new RegExp(templatesFilter, 'i');
    return sortedTemplates.filter((template) => regExp.test(template.name));
  }, [templatesFilter]);

  const onFilter = (text: string) => {
    setTemplatesFilter(text);
  };

  return (
    <Modal
      size="sm"
      title={title}
      show={show}
      cancelButtonProps={{
        onPress: onCancel,
        text: getText('close'),
        logger: { id: 'cancel-button-select-template-modal' },
        hierarchy: 'primary',
      }}
      isScrollable
    >
      <View
        style={{
          marginRight: theme.getSpacing(2),
        }}
      >
        <TextInput
          placeholder={getText('find-template')}
          autoComplete="off"
          autoCapitalize="none"
          style={styles.findTemplate}
          underlineColor="transparent"
          outlineColor={theme.palette.white}
          onChangeText={onFilter}
          value={templatesFilter}
        />
        <View style={styles.templatesWrapper}>
          {filteredTemplates.length ? (
            filteredTemplates.map((template) => (
              <Fragment key={template.id}>
                <TouchableOpacity onPress={() => onSelectTemplate(template)}>
                  <Text style={styles.template}>{template.name}</Text>
                </TouchableOpacity>
              </Fragment>
            ))
          ) : (
            <Text style={styles.template}>{getText('no-results-found')}</Text>
          )}
        </View>
      </View>
    </Modal>
  );
};

interface TemplatesModalProps {
  title: string;
  show: boolean;
  onSelectTemplate: (template: ITemplate) => void;
  onCancel: () => void;
}
