import { PharmacistUserLoginRequest } from '@digitalpharmacist/users-service-client-axios';
import UsersService from '../../api/UsersService';
import { useUserAuthenticationModalState } from './user-authentication-modal-store';
import { logError, logEvent } from 'assets/logging/logger';
import { ApiError } from 'assets/core/api';
import { AsyncStatus } from '../../store/types';
import FormsService from '../../api/FormsService';
import { VaccinationDto } from '@digitalpharmacist/forms-service-client-axios';
import { resetVaccinationModalState } from '../vaccination-modal/vaccination-modal-actions';
import { getText } from 'assets/localization/localization';
import { getSubmission } from '../../screens/forms/view-submission-actions';

export const updateShowModalState = (showModal: boolean) => {
  useUserAuthenticationModalState.setState({
    showModal: showModal,
  });
};

export const updateStatusState = (status: AsyncStatus) => {
  useUserAuthenticationModalState.setState({
    status: status,
  });
};

export const login = async (
  values: PharmacistUserLoginRequest,
): Promise<string | undefined> => {
  useUserAuthenticationModalState.setState({
    error: undefined,
    status: 'loading',
  });

  try {
    const { accessToken, refreshToken, userInfo } =
      await UsersService.logIn(values);
    if (accessToken && refreshToken && userInfo) {
      useUserAuthenticationModalState.setState({ status: 'success' });

      return userInfo.id;
    }
  } catch (e) {
    e = ApiError(e as any);
    logEvent('login_failed', { email: values.email });
    useUserAuthenticationModalState.setState({
      error: {
        message: getText('authentication-failed-generic'),
      },
      status: 'error',
    });
  }
};

export const handleSendVaccineInfo = async (
  vaccinationDto: VaccinationDto,
  locationId: string,
  formId: string,
  submissionId: string,
  closeModal: () => void,
) => {
  useUserAuthenticationModalState.setState({
    error: undefined,
    status: 'loading',
  });

  try {
    await FormsService.updateSubmissionWithVaccinationInfo(
      locationId,
      formId,
      submissionId,
      vaccinationDto,
    );

    await getSubmission(formId, submissionId);
    closeModal();
    useUserAuthenticationModalState.setState({
      status: 'idle',
    });
  } catch (error: any) {
    logError(error);
    useUserAuthenticationModalState.setState({
      error: {
        message: getText('add-vaccination-error'),
      },
      status: 'error',
    });
  } finally {
    resetVaccinationModalState();
  }
};

export const resetError = () => {
  useUserAuthenticationModalState.setState({
    error: undefined,
    status: 'idle',
  });
};

export const handleResetAuthData = (doReset: boolean) => {
  resetError();
  useUserAuthenticationModalState.setState({
    resetAuthData: doReset,
  });
};
