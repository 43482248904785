import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
  useRef,
} from 'react';
import { Text } from 'assets/components/text';
import { View, Pressable } from 'react-native';
import { makeStyles, useTheme } from 'assets/theme';
import { getText } from 'assets/localization/localization';
import { useUserState } from '../../store/user-store';
import { useAppStateStore } from '../../store/app-store';
import { Button } from '../../../../../packages/assets/components/button';
import { useNavigation } from '@react-navigation/native';
import { RootStackNavigationProp } from '../../navigation/RootNavigation';
import { LoadingOverlay } from '../../components/LoadingOverlay';
import Constants from 'expo-constants';
import {
  changeLocation,
  getUsersMostRecentLocations,
} from '../../actions/app-actions';
import { PharmacistLocationDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import {
  PharmacyStoreSelectorModal,
  PharmacyStoreSelectorModalHandler,
} from '../../components/select-store/PharmacyStoreSelectorModal';
import { SearchIcon } from 'assets/icons';
import { ampli } from '../../common/ampliPharmacist';
import { Icon } from 'assets/components/icon';
import { NoLocationFound } from './NoLocationFound';
import { logError } from 'assets/logging/logger';

export const SelectLocation: FunctionComponent = () => {
  const styles = useStyles();
  const theme = useTheme();
  const navigation = useNavigation<RootStackNavigationProp>();

  const { data: user } = useUserState();
  const { userMostRecentLocations, hasMoreLocations } = useAppStateStore();
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedStore, setSelectedStore] = useState('');

  const pharmacyStoreSelectorModalRef =
    useRef<PharmacyStoreSelectorModalHandler>(null);

  useEffect(() => {
    void (async () => {
      if (user && userMostRecentLocations.length < 1) {
        getUsersMostRecentLocations()
          .catch((e) => {
            logError(e);
          })
          .finally(() => {
            setLoading(false);
          });
      } else setLoading(false);
    })();
  }, []);

  const handleOnChangeLocation = async (item: PharmacistLocationDto) => {
    setSelectedStore(item.location_id);
    void (await changeLocation(item));
    ampli.userLoggedIn({
      loginMethod: 'email',
      loginStatus: 'Logged In',
      loginTime: new Date().toISOString(),
      productVersion: Constants.expoConfig?.version!,
    });
    navigation.navigate('dashboard'); // TODO: needs to be changed when the other views are ready
  };

  return (
    <View style={{ margin: 'auto' }}>
      {loading ? (
        <LoadingOverlay />
      ) : userMostRecentLocations.length < 1 ? (
        <NoLocationFound />
      ) : (
        userMostRecentLocations.length > 0 && (
          <>
            <Text style={styles.text} selectable>
              {getText('store-location')}
            </Text>
            <View testID="select-location-container">
              {userMostRecentLocations.map((item) => (
                <StoreSelectorComponent
                  selected={selectedStore === item.location_id}
                  onLocationSelected={() => {
                    setSelectedStore(item.location_id);
                  }}
                  onValueChange={() => handleOnChangeLocation(item)}
                  location={item}
                  key={item.location_id}
                />
              ))}
            </View>
            {hasMoreLocations && (
              <>
                <Pressable
                  style={styles.searchLocationContainer}
                  onPress={() => {
                    pharmacyStoreSelectorModalRef.current?.show();
                  }}
                >
                  <Icon
                    size={14}
                    color={theme.palette.blue[600]}
                    icon={SearchIcon}
                  />
                  <Text style={styles.searchLocation}>
                    {getText('search-locations')}
                  </Text>
                </Pressable>
                <PharmacyStoreSelectorModal
                  ref={pharmacyStoreSelectorModalRef}
                  onChange={handleOnChangeLocation}
                />
              </>
            )}
          </>
        )
      )}
    </View>
  );
};

interface StoreSelectorComponentProps {
  location: PharmacistLocationDto;
  selected: boolean;
  onValueChange: () => void;
  onLocationSelected: () => void;
}

const StoreSelectorComponent: FunctionComponent<
  PropsWithChildren<StoreSelectorComponentProps>
> = (props) => {
  const styles = useStyles();

  return (
    <Pressable
      onPress={() => props.onLocationSelected()}
      style={props.selected ? styles.containerSelected : styles.container}
      testID={props.location.location_id}
    >
      <View>
        <Text numberOfLines={1} style={styles.title}>
          {props.location.location_name}
        </Text>
        <Text style={styles.helperTitle}>
          {props.location.location_address1}
        </Text>
      </View>
      <View>
        <Button
          hierarchy={props.selected ? 'secondary' : 'secondary-gray'}
          onPress={() => props.onValueChange()}
          logger={{ id: 'select-location-button' }}
        >
          {getText('select')}
        </Button>
      </View>
    </Pressable>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    borderColor: theme.palette.gray[200],
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
    boxSizing: 'border-box',
    paddingHorizontal: theme.getSpacing(1),
    background: theme.palette.white,
    border: '1px solid #BCC8CE',
    borderRadius: theme.roundness,
    width: 474,
    height: 60,
    marginBottom: theme.getSpacing(1),
  },
  containerSelected: {
    borderColor: theme.palette.primary[300],
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
    boxSizing: 'border-box',
    paddingHorizontal: theme.getSpacing(1),
    backgroundColor: theme.palette.primary[50],
    border: '1px solid #77C7F6',
    borderRadius: theme.roundness,
    width: 474,
    height: 60,
    marginBottom: theme.getSpacing(1),
  },
  searchLocation: {
    color: theme.palette.blue[600],
  },
  searchLocationContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  title: {
    fontWeight: '600',
    fontSize: 16,
    lineHeight: 24,
    maxWidth: 300,
    textAlign: 'left',
    color: theme.palette.gray[700],
  },
  helperTitle: {
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 20,
    textAlign: 'left',
    color: theme.palette.gray[600],
  },
  text: {
    fontSize: 32,
    marginBottom: theme.getSpacing(4),
  },
}));
