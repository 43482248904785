import create from 'zustand';
import { VaccinationDto } from '@digitalpharmacist/forms-service-client-axios';

export const useVaccinationModalState = create<VaccinationModalState>(() => ({
  showModal: false,
  vaccination: undefined,
  resetVaccinationData: false,
}));

interface VaccinationModalState {
  showModal: boolean;
  vaccination: VaccinationDto | undefined;
  resetVaccinationData: boolean;
}
