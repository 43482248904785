import React, { FunctionComponent, PropsWithChildren } from 'react';
import { View } from 'react-native';
import { Text } from 'assets/components/text';
import { ContentPlaceholder } from '../../components/ContentPlaceholder';
import { useTheme, makeStyles } from 'assets/theme';
import { Alert } from 'assets/components/alert';

export const FormBuilderPlaceholder: FunctionComponent<
  PropsWithChildren<FormBuilderPlaceholderProps>
> = ({ title, text }) => {
  const styles = useStyles();
  const theme = useTheme();
  return (
    <View style={styles.container}>
      <Alert
        intent="warning"
        title={title}
        description={text}
        style={styles.formEditWarning}
      />

      <View style={styles.editor}>
        <ContentPlaceholder
          height={50}
          width={500}
          style={{ marginBottom: theme.getSpacing(4) }}
        />
        <ContentPlaceholder
          height={30}
          width={200}
          style={{ marginBottom: theme.getSpacing(1) }}
        />
        <ContentPlaceholder
          height={40}
          width={300}
          style={{ marginBottom: theme.getSpacing(3) }}
        />
        <ContentPlaceholder
          height={30}
          width={200}
          style={{ marginBottom: theme.getSpacing(1) }}
        />
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: theme.getSpacing(3),
          }}
        >
          <ContentPlaceholder
            height={40}
            width="40%"
            style={{ marginRight: theme.getSpacing(2) }}
          />
          <ContentPlaceholder height={40} width="40%" />
        </View>
        <ContentPlaceholder
          height={30}
          width={200}
          style={{ marginBottom: theme.getSpacing(1) }}
        />
        <ContentPlaceholder
          height={40}
          width={300}
          style={{ marginBottom: theme.getSpacing(3) }}
        />
        <ContentPlaceholder
          height={30}
          width={200}
          style={{ marginBottom: theme.getSpacing(1) }}
        />
        <ContentPlaceholder
          height={40}
          width={300}
          style={{ marginBottom: theme.getSpacing(3) }}
        />
        <ContentPlaceholder
          height={30}
          width={200}
          style={{ marginBottom: theme.getSpacing(1) }}
        />
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: theme.getSpacing(3),
          }}
        >
          <ContentPlaceholder
            height={40}
            width="40%"
            style={{ marginRight: theme.getSpacing(2) }}
          />
          <ContentPlaceholder height={40} width="40%" />
        </View>
      </View>
    </View>
  );
};

interface FormBuilderPlaceholderProps {
  title: string;
  text: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    gap: 16,
    alignItems: 'flex-start',
    marginTop: 50,
    position: 'relative',
    maxHeight: '90%',
    overflow: 'hidden',
  },
  editor: {
    width: '75%',
    padding: theme.getSpacing(2),
  },
  toolbar: {
    width: '25%',
    padding: theme.getSpacing(2),
  },
  toolbarElement: {
    marginBottom: theme.getSpacing(3),
  },
  alertContainer: {
    paddingVertical: theme.getSpacing(4) + theme.getSpacing(1),
    paddingHorizontal: theme.getSpacing(3),
    backgroundColor: theme.palette.warning[100],
    borderRadius: theme.roundness,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  alertTextWrapper: {
    flex: 1,
    paddingLeft: theme.getSpacing(2),
  },
  alertTitle: {
    ...theme.fonts.medium,
    marginBottom: theme.getSpacing(1),
  },
  alertText: {
    color: theme.palette.gray[600],
  },
  formEditWarning: {
    marginBottom: theme.getSpacing(2),
    width: '100%',
  },
}));
