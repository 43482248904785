import { FunctionComponent, useState } from 'react';
import ReactQuill from 'react-quill';
import { Modal } from 'assets/components/modal';
import { getText } from 'assets/localization/localization';
import { Text } from 'assets/components/text';
import theme, { makeStyles } from 'assets/theme';
import { Form } from 'assets/layout';
import {
  UsersIcon,
  LockIcon,
  NoLockIcon,
  CalendarIcon,
  UserIcon,
} from 'assets/icons';
import { Alert } from 'assets/components/alert';
import {
  toListRecipientCriteriaTextMap,
  timeTextMap,
  typeTextMap,
} from '../mapper';
import '../../styles/messaging-text-editor-style.css';
import './review-bulk-message-modal.css';
import {
  BulkMessageType,
  SmsType,
} from '@digitalpharmacist/unified-communications-service-client-axios';
import { RadioButtonGroupField } from 'assets/components/radio-button-group';
import { RadioButtonInputMode } from 'assets/components/radio-button-group/RadioButton';
import { useForm } from 'react-hook-form';
import { TouchableOpacity, View } from 'react-native';
import { useUserState } from '../../../../store/user-store';
import { BulkMessageForm, ETo } from '../../common/types/MessageForm';
import { Icon } from 'assets/components/icon';

export const ReviewBulkMessageModal: FunctionComponent<
  ReviewBulkMessageModalProps
> = ({
  title,
  show,
  bulkData,
  recipientsCount,
  isAlertShown,
  alertText = '',
  onCancel,
  onBack,
  onSubmit,
  openRecipients,
}) => {
  const styles = useStyles();
  const user = useUserState((user) => user.data);
  const [errorFields, setErrorFields] = useState<Record<string, string>>({});

  function getFilterCriteriaText() {
    if (bulkData.to === ETo.TO_ALL) {
      return toListRecipientCriteriaTextMap[ETo.TO_ALL];
    } else if (bulkData.to === ETo.TO_SPECIFIC) {
      return toListRecipientCriteriaTextMap[ETo.TO_SPECIFIC];
    } else {
      return toListRecipientCriteriaTextMap[ETo.TO_FILTERED_LIST];
    }
  }

  const methods = useForm<BulkMessageForm>({
    defaultValues: {
      sms_type: null,
    },
  });

  const onSelectSubmit = () => {
    const sms_type = methods.watch('sms_type');
    if (!sms_type && bulkData.message_type === BulkMessageType.NotSecure) {
      const invalidFields = {
        ...errorFields,
        sms_type: getText('required-field'),
      };
      setErrorFields(invalidFields);
    } else {
      setErrorFields({});
      onSubmit({ ...bulkData, sms_type: methods.watch('sms_type') });
    }
  };

  const showErrorFor = (errorField: string) => {
    if (errorField in errorFields) {
      return <Text style={styles.errorMessage}>{errorFields[errorField]}</Text>;
    }
    return <></>;
  };

  return (
    <Modal
      size="lg"
      title={title}
      show={show}
      cancelButtonProps={{
        onPress: onBack,
        text: 'Back',
        logger: { id: 'cancel-button-modal-review-bulk-message' },
        hierarchy: 'secondary',
      }}
      dismissButtonProps={{
        onPress: onCancel,
        text: 'Back',
        logger: { id: 'dismiss-button-modal-review-bulk-message' },
      }}
      resetButtonProps={{
        onPress: onCancel,
        text: getText('cancel'),
        logger: { id: 'reset-button-modal-review-bulk-message' },
        hierarchy: 'tertiary-gray',
      }}
      okButtonProps={{
        onPress: onSelectSubmit,
        logger: { id: 'ok-button-modal-review-bulk-message' },
        text: getText('send'),
        hierarchy: 'primary',
        disabled: false,
      }}
      isScrollable
    >
      {isAlertShown ? (
        <Alert
          intent="warning"
          title={alertText}
          style={{ marginBottom: theme.getSpacing(2) }}
        />
      ) : (
        <></>
      )}
      <Form.Column>
        {bulkData.message_type === BulkMessageType.NotSecure ? (
          <Form methods={methods}>
            <Form.Row>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <RadioButtonGroupField
                  isHorizontal
                  name="sms_type"
                  mode={RadioButtonInputMode.FLAT}
                  values={[
                    {
                      text: getText('marketing'),
                      value: SmsType.Marketing,
                    },
                    {
                      text: getText('non-marketing'),
                      value: SmsType.NonMarketing,
                    },
                  ]}
                />
              </View>
              {showErrorFor('sms_type')}
            </Form.Row>
            <Form.Row>
              <Text style={styles.marketingRow}>
                {getText('marketing-or-non-marketing-bulk-text')}
              </Text>
            </Form.Row>
          </Form>
        ) : null}
        <Form.Row>
          <Form.Column>
            <Text style={styles.title}>{getText('method')}</Text>
            <Form.Row style={styles.columnRow}>
              {bulkData.message_type === BulkMessageType.Secure ? (
                <Icon
                  size={15}
                  color={theme.palette.gray[700]}
                  icon={LockIcon}
                />
              ) : (
                <Icon
                  size={15}
                  color={theme.palette.gray[700]}
                  icon={NoLockIcon}
                />
              )}
              <Text style={[styles.text, styles.marginLeft]}>
                {typeTextMap[bulkData.message_type]}
              </Text>
            </Form.Row>
          </Form.Column>
          <Form.Column>
            <Text style={styles.title}>{getText('recipients')}</Text>
            <TouchableOpacity onPress={openRecipients}>
              <Form.Row style={styles.columnRow}>
                <Icon
                  size={15}
                  color={theme.palette.primary[600]}
                  icon={UsersIcon}
                  strokeWidth={2}
                />
                <Text
                  style={[styles.text, styles.marginLeft, styles.recipientsBtn]}
                >
                  {recipientsCount}
                </Text>
              </Form.Row>
            </TouchableOpacity>
          </Form.Column>
          <Form.Column>
            <Text style={styles.title}>{getText('send-date')}</Text>
            <Form.Row style={styles.columnRow}>
              <Icon
                size={15}
                color={theme.palette.gray[700]}
                icon={CalendarIcon}
              />
              <Text style={[styles.text, styles.marginLeft]}>
                {timeTextMap[bulkData.time]}
              </Text>
            </Form.Row>
          </Form.Column>
          {user?.firstName && user.lastName ? (
            <Form.Column>
              <Text style={styles.title}>{getText('sender')}</Text>
              <Form.Row style={styles.columnRow}>
                <Icon
                  size={15}
                  color={theme.palette.gray[700]}
                  icon={UserIcon}
                  strokeWidth={2}
                />
                <Text style={[styles.text, styles.marginLeft]}>
                  {`${user.firstName} ${user.lastName}`}
                </Text>
              </Form.Row>
            </Form.Column>
          ) : (
            <Form.Column></Form.Column>
          )}
        </Form.Row>

        {bulkData.message_type === BulkMessageType.Secure ? (
          <Form.Row>
            <Form.Column>
              <Text style={styles.title}>{getText('message')}</Text>
              <Text style={styles.subject}>
                {getText('subject')}: {bulkData.subject}
              </Text>
              <ReactQuill
                readOnly
                className="review-bulk-message-modal" // to control styles in ./review-bulk-message-modal.css
                style={{ ...styles.text, ...styles.content }}
                theme="snow"
                value={bulkData.content}
                modules={{ toolbar: false }}
              />
            </Form.Column>
          </Form.Row>
        ) : (
          <>
            <Form.Row>
              <Form.Column>
                <Text style={styles.title}>{getText('sms-message')}</Text>
                <ReactQuill
                  readOnly
                  className="review-bulk-message-modal" // to control styles in ./review-bulk-message-modal.css
                  style={{ ...styles.text, ...styles.content }}
                  theme="snow"
                  value={bulkData.content_sms}
                  modules={{ toolbar: false }}
                />
              </Form.Column>
            </Form.Row>
            <Form.Row>
              <Form.Column>
                <Text style={styles.title}>{getText('email-message')}</Text>
                <Text style={styles.subject}>
                  {getText('subject')}: {bulkData.subject}
                </Text>
                <ReactQuill
                  readOnly
                  className="review-bulk-message-modal" // to control styles in ./review-bulk-message-modal.css
                  style={{ ...styles.text, ...styles.content }}
                  theme="snow"
                  value={bulkData.content_email}
                  modules={{ toolbar: false }}
                />
              </Form.Column>
            </Form.Row>
          </>
        )}

        <Form.Row>
          <Form.Column>
            <Text style={styles.title}>{getText('recipient-criteria')}</Text>
            <Text style={styles.text}>{getFilterCriteriaText()}</Text>
          </Form.Column>
        </Form.Row>
      </Form.Column>
    </Modal>
  );
};

interface ReviewBulkMessageModalProps {
  title: string;
  show: boolean;
  bulkData: BulkMessageForm;
  recipientsCount: number;
  isAlertShown?: boolean;
  alertText?: string;
  onSubmit: (data: BulkMessageForm) => void;
  onBack: () => void;
  onCancel: () => void;
  openRecipients: () => void;
}

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.gray[900],
    fontWeight: '700',
    fontSize: 16,
    lineHeight: 18,
    marginBottom: theme.getSpacing(1.5),
  },
  text: {
    color: theme.palette.gray[700],
    fontSize: 16,
    lineHeight: 24,
  },
  subject: {
    color: theme.palette.gray[700],
    fontSize: 16,
    marginBottom: theme.getSpacing(3),
  },
  marginLeft: {
    marginLeft: theme.getSpacing(1),
  },
  columnRow: {
    marginBottom: 0,
    alignItems: 'center',
  },
  content: {
    borderTopWidth: 0,
    borderBottomWidth: 0,
  },
  marketingRow: {
    color: theme.palette.gray[700],
    paddingBottom: theme.getSpacing(2),
    borderBottomColor: theme.palette.gray[300],
    borderBottomWidth: 1,
  },
  errorMessage: {
    color: theme.palette.error[600],
    fontSize: 12,
  },
  recipientsBtn: {
    color: theme.palette.primary[600],
  },
}));
