import React, { FunctionComponent } from 'react';
import PharmacyOBWizard, {
  PharmacyOBWizardProps,
} from '../../../components/pharmacy-ob-wizard/PharmacyOBWizard';
import { OBSingleLocationAccount } from './single-locations/OBSingleLocationAccount';
import { OBSingleLocationAddresses } from './single-locations/OBSingleLocationAddresses';
import { OBSingleLocationEmployees } from './single-locations/OBSingleLocationEmployees';
import { OBSingleLocationEquipment } from './single-locations/OBSingleLocationEquipment';
import { OBSingleLocationHours } from './single-locations/OBSingleLocationHours';
import { OBSingleLocationSteps } from '../utils';
import { OBSingleLocationReview } from './single-locations/OBSingleLocationReview';

export const OBSingleLocationWizard: FunctionComponent<
  Omit<PharmacyOBWizardProps, 'steps'>
> = ({ onFinish, onStepChange, startIndex }) => {
  return (
    <PharmacyOBWizard
      steps={OBSingleLocationSteps}
      onFinish={onFinish}
      startIndex={startIndex}
      onStepChange={onStepChange}
    >
      <OBSingleLocationAccount />
      <OBSingleLocationAddresses />
      <OBSingleLocationEquipment />
      <OBSingleLocationEmployees />
      <OBSingleLocationHours />
      <OBSingleLocationReview />
    </PharmacyOBWizard>
  );
};
