import React, { FunctionComponent, PropsWithChildren, useMemo } from 'react';
import { TooltipWrapper } from 'react-tooltip';
import { EllipsisTextRenderer } from '../components/EllipsisTextRenderer';

export const PharmacyTooltipWrapper: FunctionComponent<
  PropsWithChildren<PharmacyTooltipWrapperProps>
> = ({
  value,
  rowIndex,
  setTooltipData,
  tooltipId,
  children,
  containerStyles = { overflow: 'hidden', textOverflow: 'ellipsis' },
  isContentIcon = false,
  testId,
}) => {
  const handleMouseEnter = () => {
    if (setTooltipData && value && rowIndex !== undefined) {
      setTooltipData({ value, id: `${value} ${rowIndex}` });
    }
  };

  const handleMouseLeave = () => {
    if (setTooltipData) {
      setTooltipData(undefined);
    }
  };

  const tooltipContent = useMemo(
    () => (
      <>
        {value && <EllipsisTextRenderer value={value} />}
        {children && (
          <div style={{ display: 'inline-block' }} id={tooltipId}>
            {children}
          </div>
        )}
      </>
    ),
    [value, tooltipId, children],
  );

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={containerStyles}
      key={tooltipId || `${value} ${rowIndex}`}
      {...(testId && { 'data-test-id': testId })}
    >
      <TooltipWrapper tooltipId={tooltipId || `${value} ${rowIndex}`}>
        {isContentIcon ? (
          <div style={{ display: 'flex' }}>{tooltipContent}</div>
        ) : (
          tooltipContent
        )}
      </TooltipWrapper>
    </div>
  );
};
interface PharmacyTooltipWrapperProps {
  value?: string;
  rowIndex?: number;
  setTooltipData?: React.Dispatch<
    React.SetStateAction<
      | {
          value: string;
          id: string;
        }
      | undefined
    >
  >;
  tooltipId?: string;
  containerStyles?: React.CSSProperties;
  isContentIcon?: boolean;
  testId?: string;
}
