/**
 * This functions as the eventual back end integration stub
 * For now the permissions list will be built here
 * In the future this permission list will be dynamically
 * served from the backend (role service).
 *
 *
 * If there is a guard
 */

import { Actions, Resources, allActions, Role } from './permission-types';
import { policies } from './role-policies';

/**
 * Returns a list of allowed actions
 */
export const allowedActions = function (
  resource: Resources,
  roles: Role[],
): Actions[] {
  const { rules } = policies[resource];
  const actions = rules.flatMap((rule) => {
    // true if match in the rules-roles / roles
    if (rule.roles.some((r) => roles.includes(r))) {
      return rule.actions.includes('*') ? allActions : rule.actions; // change * to all
    }
    return [];
  });

  return [...new Set(actions)] as Actions[]; // remove duplicates
};
