import {
  BusinessType,
  PharmacyLocationDto,
  UpdatePharmacyLocationDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import * as validate from '@digitalpharmacist/validation-dp';
import { DropdownSelectField } from 'assets/components/dropdown-select';
import { Modal } from 'assets/components/modal';
import { Text } from 'assets/components/text';
import { TextField } from 'assets/components/text-field';
import { Tooltip } from 'assets/components/tooltip/components/tooltip';
import { useForm } from 'assets/form';
import { Form } from 'assets/layout/form/Form';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import _isEqual from 'lodash/isEqual';
import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { View } from 'react-native';
import {
  checkCapitalizeAllWords,
  validateOptionalURL,
  validateTaxEIN,
} from '../../../../../utils';
import { canFactory } from '../../../../common/guards/permission-guard';
import { SettingsTooltipsComponents } from '../../../../components/Settings/SettingsTooltipsComponents';
import {
  setShowModalLocationGeneralInfo,
  updateLocation,
} from '../location-settings-actions';
import { useLocationSettingsState } from '../location-settings-store';

const convertToFormValues = (
  location?: PharmacyLocationDto,
): GeneralInfoFormProps => {
  return {
    name: location?.name || '',
    internal_name: location?.internal_name || '',
    legal_name: location?.legal_name || '',
    location_jurisdiction_of_incorporation:
      location?.location_jurisdiction_of_incorporation || '',
    dba: location?.dba,
    active: location?.active
      ? getText('locations-active')
      : getText('locations-inactive'),
    email: location?.email,
    pms: location?.pms,
    ncpdp: location?.ncpdp,
    npi: location?.npi,
    duns_number: location?.duns_number,
    tax_id: location?.tax_id,
    phone: location?.phone,
    fax: location?.fax,
    business_type: location?.business_type,
    store_number: location?.store_number,
    timezone: (location?.timezone && location.timezone.id) || '',
    languages_spoken: location?.languages_spoken,
    additional_offerings: location?.additional_offerings,
    google_review_url: location?.google_review_url,
  };
};

const convertToUpdatePharmacyLocationDto = (
  formValue: GeneralInfoFormProps,
): UpdatePharmacyLocationDto => {
  return {
    name: formValue.name,
    internal_name: formValue.internal_name,
    legal_name: formValue.legal_name,
    location_jurisdiction_of_incorporation:
      formValue.location_jurisdiction_of_incorporation,
    dba: formValue.dba,
    email: formValue.email,
    pms: formValue.pms,
    duns_number: formValue.duns_number,
    tax_id: formValue.tax_id,
    phone: formValue.phone,
    fax: formValue.fax,
    store_number: formValue.store_number,
    timezone_id: formValue.timezone,
    languages_spoken: formValue.languages_spoken,
    additional_offerings: formValue.additional_offerings,
    google_review_url: formValue.google_review_url,
  };
};

export const LocationGeneralInfoModal: FunctionComponent<
  PropsWithChildren<any>
> = () => {
  const {
    showModalLocationGeneralInfo,
    location,
    locationId,
    allTimezones,
    allPMS,
  } = useLocationSettingsState();
  const styles = useStyles();
  const canUpdate = canFactory('update')('settingsItAdmin');
  const canLumistryUserUpdateSettings = canFactory('update')(
    'settingsLumistryUser',
  );

  const [loadingMethod, setLoadingMethod] = useState(false);

  useEffect(() => {
    methods.reset(convertToFormValues(location));
  }, [location]);

  const methods = useForm<GeneralInfoFormProps>({
    defaultValues: convertToFormValues(location),
    mode: 'onChange',
  });

  const handleSubmit = async () => {
    setLoadingMethod(true);
    const formValue = methods.getValues();
    if (
      !_isEqual(formValue, convertToFormValues(location)) &&
      locationId &&
      location?.pharmacy_id
    ) {
      const updatedLocation: UpdatePharmacyLocationDto =
        convertToUpdatePharmacyLocationDto(formValue);
      await updateLocation(locationId, updatedLocation);
      setShowModalLocationGeneralInfo(false);
    }
    setLoadingMethod(false);
  };

  const closeModal = () => {
    methods.reset(convertToFormValues(location));
    setShowModalLocationGeneralInfo(false);
  };

  const optionsTimezone: SelectionParent[] = [
    ...allTimezones.map((timezone) => ({
      label: timezone.name,
      value: timezone.id,
    })),
  ];

  const businessTypeOptions: SelectionParent[] = Object.values(
    BusinessType,
  ).map((value) => ({
    label: value,
    value,
  }));

  const optionsPMS: SelectionParent[] = [
    ...allPMS.map((pms) => ({
      label: pms.name,
      value: pms.name,
    })),
  ];

  return (
    <Modal
      title={getText('locations-edit-pharmacy-info')}
      titleSize="sm"
      dismissButtonProps={{
        onPress: closeModal,
        logger: { id: 'location-general-info-form-cancel-button-modal' },
      }}
      cancelButtonProps={{
        onPress: closeModal,
        hierarchy: 'tertiary-gray',
        logger: { id: 'location-general-info-form-cancel-button-modal' },
      }}
      okButtonProps={{
        onPress: methods.handleSubmit(handleSubmit),
        logger: { id: 'location-general-info-form-ok-button-modal' },
        hierarchy: 'primary',
        text: getText('ok'),
        loading: loadingMethod,
      }}
      show={showModalLocationGeneralInfo}
      isScrollable={true}
    >
      <Form methods={methods}>
        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>
                {getText('locations-location-name')}
              </Text>
              <SettingsTooltipsComponents
                tooltipId="location-general-info-external-name-modal"
                tooltipText={getText('locations-location-name-tooltip')}
              />
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="name"
              type="text"
              rules={{
                required: getText('locations-required'),
                validate: checkCapitalizeAllWords,
              }}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>
                {getText('locations-internal-name')}
              </Text>
              <SettingsTooltipsComponents tooltipId="location-general-info-internal-name-modal" />
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="internal_name"
              type="text"
              rules={{
                required: getText('locations-required'),
              }}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>
                {getText('legal-entity-name')}
              </Text>
              <SettingsTooltipsComponents tooltipId="location-general-info-legal-entity-name-modal" />
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="legal_name"
              type="text"
              rules={{
                required: getText('locations-required'),
              }}
              disabled={!canUpdate}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>{getText('jurisdiction')}</Text>
              <SettingsTooltipsComponents tooltipId="location-jurisdiction-modal" />
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="location_jurisdiction_of_incorporation"
              type="text"
              rules={{
                required: getText('locations-required'),
              }}
              disabled={!canUpdate}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>{getText('dba')}</Text>
              <SettingsTooltipsComponents tooltipId="location-dba-modal" />
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="dba"
              type="text"
              rules={{
                required: getText('locations-required'),
              }}
              disabled={!canUpdate}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>
                {getText('locations-status')}
              </Text>
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="active"
              type="text"
              disabled
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>
                {getText('location-email-address')}
              </Text>
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="email"
              type="emailAddress"
              rules={{
                validate: (value) => {
                  return !value || validate.isEmail(value)
                    ? true
                    : getText('email-is-not-valid');
                },
              }}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>
                {getText('pharmacy-mgmt-system')}
              </Text>
              {!canLumistryUserUpdateSettings && (
                <SettingsTooltipsComponents tooltipId="location-general-info-pharmacy-mgmt-system-modal" />
              )}
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            {canLumistryUserUpdateSettings ? (
              <DropdownSelectField
                options={optionsPMS}
                name="pms"
                labelInlined={false}
              />
            ) : (
              <TextField
                style={styles.inputStyle}
                name="pms"
                type="text"
                disabled={!canLumistryUserUpdateSettings}
              />
            )}
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>
                {getText('locations-NCPDP')}
              </Text>
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="ncpdp"
              type="text"
              disabled
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>{getText('locations-NPI')}</Text>
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="npi"
              type="text"
              disabled
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>{getText('duns-number')}</Text>
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="duns_number"
              type="duns"
              rules={{
                required: getText('locations-required'),
              }}
              disabled={!canUpdate}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>{getText('tax-id')}</Text>
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="tax_id"
              type="text"
              rules={{
                required: getText('locations-required'),
                validate: validateTaxEIN,
              }}
              disabled={!canUpdate}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>
                {getText('locations-phone-number')}
              </Text>
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField style={styles.inputStyle} name="phone" type="text" />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>
                {getText('locations-fax-number')}
              </Text>
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <TextField style={styles.inputStyle} name="fax" type="text" />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>{getText('business-type')}</Text>
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <DropdownSelectField
              options={businessTypeOptions}
              name="business_type"
              labelInlined={false}
              disabled
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>
                {getText('locations-store-number')}
              </Text>
            </View>
          </Form.Column>
          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="store_number"
              type="text"
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>
                {getText('locations-time-zone')}
              </Text>
            </View>
          </Form.Column>

          <Form.Column style={styles.inputWrapper}>
            <DropdownSelectField
              options={optionsTimezone}
              name="timezone"
              labelInlined={false}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>
                {getText('locations-languages-spoken')}
              </Text>
            </View>
          </Form.Column>
          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="languages_spoken"
              type="text"
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>{getText('offerings')}</Text>
            </View>
          </Form.Column>
          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="additional_offerings"
              type="text"
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column style={styles.textWrapper}>
            <View style={styles.textView}>
              <Text style={styles.textFormat}>{getText('google-review')}</Text>
            </View>
          </Form.Column>
          <Form.Column style={styles.inputWrapper}>
            <TextField
              style={styles.inputStyle}
              name="google_review_url"
              type="text"
              rules={{ validate: validateOptionalURL }}
            />
          </Form.Column>
        </Form.Row>
      </Form>
      {/* <Tooltip
        anchorId="location-general-info-external-name-modal"
        text={getText('locations-location-name-tooltip')}
      /> */}
      <Tooltip
        anchorId="location-general-info-internal-name-modal"
        text={getText('locations-internal-name-tooltip')}
      />
      <Tooltip
        anchorId="location-general-info-legal-entity-name-modal"
        text={getText('legal-entity-name-tooltip')}
      />
      <Tooltip
        anchorId="location-jurisdiction-modal"
        text={getText('jurisdiction-tooltip')}
      />
      <Tooltip anchorId="location-dba-modal" text={getText('dba-tooltip')} />
      <Tooltip
        anchorId="location-general-info-pharmacy-mgmt-system-modal"
        text={getText('pharmacy-mgmt-system-tooltip')}
      />
    </Modal>
  );
};

export interface SelectionParent {
  value: string;
  label: string;
}

interface GeneralInfoFormProps {
  name: string;
  internal_name: string;
  legal_name?: string;
  location_jurisdiction_of_incorporation?: string;
  dba?: string;
  active?: string;
  email?: string;
  pms?: string;
  ncpdp?: string;
  npi?: string;
  duns_number?: string;
  tax_id?: string;
  phone?: string;
  fax?: string;
  business_type?: string;
  store_number?: string;
  languages_spoken?: string;
  additional_offerings?: string;
  timezone?: string;
  google_review_url?: string;
}

const useStyles = makeStyles((theme) => ({
  inputWrapper: {
    display: 'flex',
    gap: theme.getSpacing(1),
    alignSelf: 'stretch',
    flex: 2,
  },
  inputStyle: {
    height: 44,
  },
  textWrapper: {
    flexDirection: 'column',
    flex: 1,
  },
  textView: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: 10,
    height: 44,
    flexDirection: 'row',
    alignContent: 'center',
  },
  textFormat: {
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 24,
  },
  dropDownControlStyles: {
    textAlign: 'left',
  },
}));
