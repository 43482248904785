import { UpdateLocationServiceOptionDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { Modal } from 'assets/components/modal';
import { Form } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { FunctionComponent, PropsWithChildren, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { AdvancedDropDownField } from '../../../../components/advanced-dropdown';
import { useAppStateStore } from '../../../../store/app-store';
import {
  setShowModalTransferMedications,
  updateServiceOptions,
} from '../refills-settings-actions';
import { useRefillsSettingsState } from '../refills-settings-store';

export const RefillsTransferMedicationsModal: FunctionComponent<
  PropsWithChildren<any>
> = () => {
  const { locationId } = useAppStateStore();
  const { showModalTransferMedications, allServiceOptions } =
    useRefillsSettingsState();
  const transferMedications = allServiceOptions.find(
    (service) => service.slug === 'transfer-rx',
  );
  const allTransferMedications: SelectionParent[] = transferMedications
    ? transferMedications.options.map((option) => {
        return { value: option.slug, label: option.name };
      })
    : [];
  const selectedService = transferMedications?.options.find(
    (option) => option.selected === true,
  );

  useEffect(() => {
    methods.reset({
      service: {
        label: selectedService?.name,
        value: selectedService?.slug,
      },
    });
  }, [allServiceOptions]);

  const methods = useForm<RefillsTransferFormProps>({
    defaultValues: {
      service: {
        label: selectedService?.name,
        value: selectedService?.slug,
      },
    },
    mode: 'onChange',
  });

  const handleSubmit = async () => {
    const formValue = methods.getValues();
    if (formValue.service.value !== selectedService?.slug) {
      const updatedLocationService: UpdateLocationServiceOptionDto[] = [
        { slug: formValue.service.value, selected: true },
      ];
      if (selectedService) {
        updatedLocationService.push({
          slug: selectedService.slug,
          selected: false,
        });
      }
      updateServiceOptions(locationId, updatedLocationService);
    }
    setShowModalTransferMedications(false);
  };

  const closeModal = () => {
    methods.reset({
      service: {
        label: selectedService?.name,
        value: selectedService?.slug,
      },
    });
    setShowModalTransferMedications(false);
  };

  return (
    <Modal
      title={getText('rx-transfer-option')}
      titleSize="sm"
      dismissButtonProps={{
        onPress: closeModal,
        logger: { id: 'location-pickup-method-form-cancel-button-modal' },
      }}
      cancelButtonProps={{
        onPress: closeModal,
        hierarchy: 'tertiary-gray',
        logger: { id: 'location-pickup-method-form-cancel-button-modal' },
      }}
      okButtonProps={{
        onPress: methods.handleSubmit(handleSubmit),
        logger: { id: 'location-pickup-method-form-ok-button-modal' },
        hierarchy: 'primary',
        text: getText('ok'),
      }}
      show={showModalTransferMedications}
      isScrollable={true}
    >
      <Form methods={methods}>
        <Form.Row>
          <Form.Column>
            <AdvancedDropDownField
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  textAlign: 'left',
                }),
              }}
              options={allTransferMedications}
              name="service"
              getOptionValue={(option) => option.value}
              getOptionLabel={(option) => option.label}
              isSearchable={true}
            />
          </Form.Column>
        </Form.Row>
      </Form>
    </Modal>
  );
};

export interface SelectionParent {
  value: string;
  label: string;
}

interface RefillsTransferFormProps {
  service: SelectionParent;
}
