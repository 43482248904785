import React, { FunctionComponent } from 'react';
import { Text } from 'assets/components/text';
import { TextField } from 'assets/components/text-field';
import { useForm } from 'assets/form';
import { TouchableOpacity, View } from 'react-native';
import { useTheme } from 'assets/theme';
import { Button } from 'assets/components/button';
import { useResetPasswordState } from './reset-password-store';
import { ResetPasswordForm } from './reset-password-actions';
import { useNavigation } from '@react-navigation/native';
import { RootStackNavigationProp } from '../../navigation/RootNavigation';
import { resetPassword } from './reset-password-actions';
import { ResetPasswordTestIDs } from './ResetPasswordTestIDs';
import { emailRegex } from '../../common/form-utils';
import { Form, PharmacyScreenContainer } from 'assets/layout';
import { getText } from 'assets/localization/localization';

export const ResetLink: FunctionComponent = () => {
  const theme = useTheme();
  const methods = useForm<ResetPasswordForm>({
    defaultValues: {
      email: '',
    },
  });

  const { status, error } = useResetPasswordState();
  const navigation = useNavigation<RootStackNavigationProp>();

  const handleSubmit = () => {
    void resetPassword(methods.getValues(), navigation);
  };

  return (
    <PharmacyScreenContainer small>
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
        }}
      >
        <View
          style={{
            marginBottom: theme.getSpacing(2),
          }}
        >
          <Text
            style={{
              color: theme.palette.gray[900],
              fontSize: 25,
              textAlign: 'left',
            }}
            selectable
          >
            {getText('reset-password')}
          </Text>
        </View>
        <Form methods={methods}>
          <Form.Alert title={error?.message} intent="error" visible={!!error} />
          <Form.Row>
            <Form.Column>
              <TextField
                testID={ResetPasswordTestIDs.emailInput}
                label={getText('email-address')}
                name="email"
                rules={{
                  required: getText('email-is-required'),
                  pattern: {
                    value: emailRegex,
                    message: getText('email-is-invalid'),
                  },
                }}
                onSubmit={methods.handleSubmit(handleSubmit)}
                disabled={status === 'loading'}
              />
            </Form.Column>
          </Form.Row>
          <Form.Actions>
            <View style={{ marginBottom: theme.getSpacing(2) }}>
              <Button
                onPress={methods.handleSubmit(handleSubmit)}
                hierarchy="primary"
                loading={status === 'loading'}
                logger={{ id: 'get-reset-password-link-submit-button' }}
                style={{
                  backgroundColor: theme.palette.primary[600],
                  borderColor: theme.palette.primary[600],
                  width: 160,
                }}
              >
                {getText('send-reset-link')}
              </Button>
            </View>
            <View
              style={{
                marginBottom: theme.getSpacing(2),
                alignItems: 'flex-start',
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  navigation.navigate('login');
                }}
              >
                <Text
                  style={{
                    color: theme.palette.primary[700],
                    fontSize: 16,
                    marginTop: theme.getSpacing(1),
                    marginBottom: theme.getSpacing(3),
                    alignSelf: 'center',
                  }}
                  selectable
                >
                  {getText('back-to-login')}
                </Text>
              </TouchableOpacity>
            </View>
          </Form.Actions>
        </Form>
      </View>
    </PharmacyScreenContainer>
  );
};
