import { FC } from 'react';
import { TouchableOpacity } from 'react-native';
import { makeStyles } from 'assets/theme';
import { RecipientRendererProps } from '../types';
import { Text } from 'assets/components/text';
import { LocationPatientRecordSearchDto } from '@digitalpharmacist/patient-service-client-axios';
import { getText } from 'assets/localization/localization';

export const RecipientsListActionRenderer: FC<RecipientsListActionRenderer> = ({
  data,
  removeHandler,
}) => {
  if (!data) {
    return <></>;
  }

  const styles = useStyles();

  return (
    <TouchableOpacity onPress={() => removeHandler(data)}>
      <Text style={styles.text}>{getText('remove')}</Text>
    </TouchableOpacity>
  );
};

// export const getRecipientsListActionRenderer = (data: LocationPatientRecordSearchDto, removeHandler: (recipient: LocationPatientRecordSearchDto) => void) => <RecipientsListActionRenderer data={data} removeHandler={removeHandler} />;
export const getRecipientsListActionRenderer = (
  props: RecipientsListActionRenderer,
) => (
  <RecipientsListActionRenderer
    data={props.data}
    removeHandler={props.removeHandler}
  />
);

interface RecipientsListActionRenderer extends RecipientRendererProps {
  removeHandler: (recipient: LocationPatientRecordSearchDto) => void;
}

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.primary[600],
  },
}));
