import { FieldValues } from 'react-hook-form';
import { LocationPatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';
import { DocumentPickerAsset } from 'expo-document-picker';
import {
  BulkMessageType,
  SmsType,
} from '@digitalpharmacist/unified-communications-service-client-axios';
import { ITemplate } from 'assets/types/messageTypes';

export interface BulkMessageForm extends MessageForm {
  [DynamicInputValue.AGE_IS_OLDER_THAN]: string;
  [DynamicInputValue.AGE_IS_YOUNGER_THAN]: string;
  [DynamicInputValue.AGE_IS]: string;
  [DynamicInputValue.AGE_IS_NOT]: string;
  [DynamicInputValue.ZIP_CODE_IS_ONE_OF]: string;
  [DynamicInputValue.ZIP_CODE_IS_NOT_ONE_OF]: string;
  [DynamicInputValue.GENDER_IS_MALE]: boolean;
  [DynamicInputValue.GENDER_IS_FEMALE]: boolean;
  [key: string]: any; // for dynamic inputs, which will be used by filters
  specific_recipients?: LocationPatientRecordDto[];
}

export interface MessageForm extends FieldValues {
  message_type: BulkMessageType;
  type: BulkMessageType;
  template?: ITemplate | null;
  template_email?: ITemplate | null;
  subject: string;
  content: string;
  content_email: string;
  content_sms: string;
  to: ETo;
  to_all_filter?: EToALLFilter;
  to_specific_filter?: EToSpecificFilter;
  time: BulkMessageTime;
  attachments: DocumentPickerAsset[];
  patient: LocationPatientRecordDto;
  sms_type: SmsType | null;
}

export enum BulkMessageTime {
  IMMEDIATE = 'immediate',
  SCHEDULED = 'scheduled',
}

export enum ETo {
  TO_ALL = 'to_all',
  TO_SPECIFIC = 'to_specific',
  TO_FILTERED_LIST = 'to_filtered_list',
}

export enum EToALLFilter {
  ALL = 'all',
}

// dummy enum for now, since we do not use to_specific_filter yet
export enum EToSpecificFilter {
  SPECIFIC = 'specific',
}

export enum DynamicInputValue {
  AGE_IS_OLDER_THAN = 'age_is_older_than',
  AGE_IS_YOUNGER_THAN = 'age_is_younger_than',
  AGE_IS = 'age_is',
  AGE_IS_NOT = 'age_is_not',
  GENDER_IS_MALE = 'gender_is_male',
  GENDER_IS_FEMALE = 'gender_is_female',
  ZIP_CODE_IS_ONE_OF = 'zip_code_is_one_of',
  ZIP_CODE_IS_NOT_ONE_OF = 'zip_code_is_not_one_of',
}
