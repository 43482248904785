import { RefillStatus } from '@digitalpharmacist/prescription-service-client-axios';
import { RadioButtonInputMode } from 'assets/components/radio-button-group/RadioButton';
import { RadioButtonGroupInput } from 'assets/components/radio-button-group/RadioButtonGroupInput';
import { getText } from 'assets/localization/localization';
import { FunctionComponent } from 'react';
import { View } from 'react-native';
import { makeStyles } from 'assets/theme';

export type RefillsStatusFilterProps = {
  value: RefillStatus | '';
  onChange: (value: RefillStatus | '') => void;
};

export const RefillsStatusFilter: FunctionComponent<
  RefillsStatusFilterProps
> = ({ value, onChange }) => {
  const styles = useStyles();

  return (
    <View style={styles.container}>
      <RadioButtonGroupInput
        isHorizontal
        mode={RadioButtonInputMode.FLAT}
        value={value}
        values={[
          {
            text: getText('refill-status-filter-all'),
            value: '',
          },
          {
            text: getText('refill-status-passed'),
            value: RefillStatus.Accepted,
          },
          {
            text: getText('refill-status-not-passed'),
            value: RefillStatus.Rejected,
          },
        ]}
        onValueChange={(value: RefillStatus | '') => {
          onChange(value);
        }}
      />
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: theme.getSpacing(3),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));
