import {
  ChecklistOptionDto,
  RequiredOptional,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { Icon } from 'assets/components/icon';
import { Text } from 'assets/components/text';
import { CheckIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Pressable, View } from 'react-native';
import { ChecklistSetupDesc, ChecklistSetupTitle } from '../utils';

export const ChecklistSetupGroupItem: FunctionComponent<
  PropsWithChildren<ChecklistSetupGroupItemrops>
> = ({
  items,
  title,
  subtitle,
  lastCompletedIndex,
  areRequiredItemsComplete,
  firstIncompletedOptionalIndex,
  handleStep,
}) => {
  const styles = useStyles();
  const theme = useTheme();

  const displayStatus = (ch: ChecklistOptionDto) =>
    ch.required === RequiredOptional.Required ||
    (ch.required === RequiredOptional.Optional && ch.completed);

  return (
    <>
      <View style={styles.textContainer}>
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.subtitle}>{subtitle}</Text>
      </View>
      <View style={styles.checklistContainer}>
        {items.map((ch, index) => (
          <Pressable
            key={ch.id}
            style={getItemsStyle(
              index,
              lastCompletedIndex,
              areRequiredItemsComplete,
              ch.required,
            )}
            onPress={() => handleStep(index, lastCompletedIndex, ch)}
          >
            <View
              style={getShadowStyle(
                index,
                lastCompletedIndex,
                areRequiredItemsComplete,
                firstIncompletedOptionalIndex,
                ch,
              )}
            >
              <View
                style={[
                  styles.marker,
                  getMarkerProgressStyle(
                    index,
                    lastCompletedIndex,
                    areRequiredItemsComplete,
                    firstIncompletedOptionalIndex,
                    ch,
                  ),
                ]}
              >
                {ch.completed ? (
                  <View style={[styles.icon]}>
                    <Icon
                      icon={CheckIcon}
                      color={theme.palette.white}
                      size={18}
                    />
                  </View>
                ) : (
                  <View style={[styles.dot]}></View>
                )}
              </View>
            </View>
            <View>
              <Text>
                <Text
                  style={getTitleStyle(
                    index,
                    lastCompletedIndex,
                    areRequiredItemsComplete,
                    firstIncompletedOptionalIndex,
                    ch,
                  )}
                >
                  {ChecklistSetupTitle[ch.slug]}
                  {displayStatus(ch) && ' - '}
                </Text>
                {displayStatus(ch) && (
                  <Text style={getStatusStyle(ch.completed)}>
                    {ch.completed ? getText('completed') : getText('required')}
                  </Text>
                )}
              </Text>
              <Text style={styles.description}>
                {ChecklistSetupDesc[ch.slug]}
              </Text>
            </View>
          </Pressable>
        ))}
      </View>
    </>
  );
};

export interface ChecklistSetupGroupItemrops {
  items: ChecklistOptionDto[];
  title: string;
  subtitle: string;
  lastCompletedIndex: number;
  areRequiredItemsComplete: boolean;
  firstIncompletedOptionalIndex: number;
  handleStep: (
    index: number,
    lastIndex: number,
    ch: ChecklistOptionDto,
  ) => void;
}

const getTitleStyle = (
  index: number,
  lastCompletedIndex: number,
  isRequiredItemsComplete: boolean,
  firstIncompletedOptionalIndex: number,
  ch: ChecklistOptionDto,
) => {
  const styles = useStyles();
  if (ch.required === RequiredOptional.Optional) {
    return isRequiredItemsComplete && index === firstIncompletedOptionalIndex
      ? styles.titleCompleted
      : styles.titleRequired;
  }
  return lastCompletedIndex >= 0 && index === lastCompletedIndex + 1
    ? styles.titleCompleted
    : styles.titleRequired;
};

const getItemsStyle = (
  index: number,
  lastCompletedIndex: number,
  isRequiredItemsComplete: boolean,
  required: RequiredOptional,
) => {
  const styles = useStyles();
  if (required === RequiredOptional.Required) {
    return index > lastCompletedIndex + 1
      ? [styles.checklistItem, styles.itemsIncomplete]
      : styles.checklistItem;
  }

  return isRequiredItemsComplete
    ? styles.checklistItem
    : [styles.checklistItem, styles.itemsIncomplete];
};

const getStatusStyle = (isCompleted: boolean) => {
  const styles = useStyles();
  return isCompleted ? styles.statusCompleted : styles.statusRequired;
};

const getShadowStyle = (
  index: number,
  lastCompletedIndex: number,
  isRequiredItemsComplete: boolean,
  firstIncompletedOptionalIndex: number,
  ch: ChecklistOptionDto,
) => {
  const styles = useStyles();

  if (ch.required === RequiredOptional.Optional) {
    return isRequiredItemsComplete && index === firstIncompletedOptionalIndex
      ? styles.shadow
      : styles.noShadow;
  }

  return lastCompletedIndex >= 0 && index == lastCompletedIndex + 1
    ? styles.shadow
    : styles.noShadow;
};

const getMarkerProgressStyle = (
  index: number,
  lastCompletedIndex: number,
  isRequiredItemsComplete: boolean,
  firstIncompletedOptionalIndex: number,
  ch: ChecklistOptionDto,
) => {
  const styles = useStyles();
  if (ch.completed) {
    return styles.markerComplete;
  }
  if (ch.required === RequiredOptional.Optional) {
    return isRequiredItemsComplete && index === firstIncompletedOptionalIndex
      ? styles.currentMarker
      : styles.markerIncomplete;
  }

  return lastCompletedIndex >= 0 && index === lastCompletedIndex + 1
    ? styles.currentMarker
    : styles.markerIncomplete;
};

const useStyles = makeStyles((theme) => ({
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.getSpacing(0.5),
  },
  title: {
    ...theme.lumistryFonts.text.large.semiBold,
  },
  subtitle: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.gray[500],
  },
  checklistContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.getSpacing(3),
  },
  titleCompleted: {
    ...theme.lumistryFonts.text.small.semiBold,
    color: theme.palette.primary[700],
  },
  titleRequired: {
    ...theme.lumistryFonts.text.small.semiBold,
    color: theme.palette.gray[700],
  },
  statusCompleted: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.success[600],
  },
  statusRequired: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.gray[700],
  },
  description: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.gray[500],
  },
  checklistItem: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.getSpacing(1.5),
  },
  shadow: {
    backgroundColor: theme.palette.primary[100],
    width: 32,
    height: 32,
    borderRadius: 20,
    padding: 4,
  },
  noShadow: {
    backgroundColor: 'transparent',
    width: 32,
    height: 32,
    borderRadius: 20,
    padding: 4,
  },
  dot: {
    backgroundColor: theme.palette.white,
    width: 8,
    height: 8,
    borderRadius: 4,
    margin: 8,
  },
  marker: {
    width: 8,
    height: 8,
    borderRadius: 8,
  },
  icon: {
    marginLeft: 3,
    marginTop: 4,
  },
  markerComplete: {
    backgroundColor: theme.palette.success[600],
    width: 24,
    height: 24,
    borderRadius: 16,
  },
  currentMarker: {
    backgroundColor: theme.palette.primary[600],
    width: 24,
    height: 24,
    borderRadius: 16,
  },
  markerIncomplete: {
    backgroundColor: theme.palette.gray[300],
    width: 24,
    height: 24,
    borderRadius: 16,
  },
  itemsIncomplete: {
    opacity: 0.5,
  },
}));
