import { Text } from 'assets/components/text';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import { FunctionComponent, PropsWithChildren, useState } from 'react';
import { TouchableOpacity } from 'react-native';
import {
  PatientAgreementTemplateModal,
  PatientAgreementTemplateModalType,
} from '../../../../components/PatientAgreements';
import { RowItemRender } from '../../../../components/Settings/RowItemRender';
import { PatientAgreementType } from '../../../../store/checklist-store';

export const AgreementsRender: FunctionComponent<
  PropsWithChildren<PatientAgreementType>
> = ({ terms, privacy }) => {
  const [agreementModalData, setAgreementModalData] =
    useState<AgreementModalDataType>({ showModal: false, type: undefined });
  const styles = useStyles();
  return (
    <>
      <RowItemRender title={getText('settings-terms-of-service')}>
        <TouchableOpacity
          onPress={() =>
            setAgreementModalData({ showModal: true, type: 'terms' })
          }
        >
          <Text style={styles.textStyle}>{getText('view')}</Text>
        </TouchableOpacity>
      </RowItemRender>
      <RowItemRender title={getText('settings-privacy-policy')}>
        <TouchableOpacity
          onPress={() =>
            setAgreementModalData({ showModal: true, type: 'privacy' })
          }
        >
          <Text style={styles.textStyle}>{getText('view')}</Text>
        </TouchableOpacity>
      </RowItemRender>
      <PatientAgreementTemplateModal
        show={agreementModalData.showModal}
        privacyBlobPart={terms}
        termsBlobPart={privacy}
        onClose={() =>
          setAgreementModalData({ showModal: false, type: undefined })
        }
        onDismiss={() =>
          setAgreementModalData({ showModal: false, type: undefined })
        }
        type={agreementModalData.type}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  textStyle: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.primary[600],
  },
}));

export type AgreementModalDataType = {
  showModal: boolean;
  type: PatientAgreementTemplateModalType | undefined;
};
