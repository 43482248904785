import create from 'zustand';

export const useAppointmentFiltersState = create<TasksFiltersState>(() => ({
  showModal: false,
  filters: {},
  disabled: false,
  isCustomFilter: false,
}));

export interface AppointmentFilters {
  appointment_type_id?: string;
  appointment_group_id?: string;
  appointment_group_category_id?: string;
}

interface TasksFiltersState {
  showModal: boolean;
  filters: AppointmentFilters;
  disabled: boolean;
  isCustomFilter: boolean;
}
