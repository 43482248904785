import { LocationPatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';
import { FC, useEffect, useState } from 'react';
import prescriptionService from '../../../../api/PrescriptionService';
import { PrescriptionDto } from '@digitalpharmacist/prescription-service-client-axios';
import { PatientDetailSidebarView } from '../../../../components/PatientDetailSidebarView/PatientDetailSidebarView';

interface ChatUserDetailSidebarProps {
  locationPatientRecordDto: LocationPatientRecordDto;
  setSelectedPatient: (lpr: LocationPatientRecordDto) => void;
  setIsSidebarOpen: (isOpen: boolean) => void;
  locationId: string;
}

export const ChatUserDetailSidebar: FC<ChatUserDetailSidebarProps> = ({
  locationPatientRecordDto,
  setSelectedPatient,
  locationId,
  setIsSidebarOpen,
}) => {
  const [prescriptions, setPrescriptions] = useState<PrescriptionDto[]>([]);

  useEffect(() => {
    void (async () => {
      const patientPrescriptions = await prescriptionService.findPrescriptions(
        locationId,
        locationPatientRecordDto.id,
      );

      setPrescriptions(patientPrescriptions);
    })();
  }, [locationId, locationPatientRecordDto]);

  return (
    <PatientDetailSidebarView
      locationPatientRecordDto={locationPatientRecordDto}
      onCollapse={() => setIsSidebarOpen(false)}
      updateLPRCallback={(lpr) => setSelectedPatient(lpr)}
      patientPrescriptions={prescriptions}
    />
  );
};
