import {
  AutoSaveWebsiteWizardDto,
  ChecklistOptionDto,
  CreateAppSettingsDto,
  CreateMediaDto,
  CreatePatientAgreementDto,
  FinalizeWebsiteLocationDto,
  FinalizeWebsiteServiceOptionDto,
  FinalizeWebsiteWizardDto,
  WebsiteServiceOptionSlug,
  WebsiteWizardResponseDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { getText } from 'assets/localization/localization';
import { logError } from 'assets/logging/logger';
import DocumentService from '../api/DocumentService';
import PharmacyService from '../api/PharmacyService';
import { PHARMACY_API_BASE_URL } from '../common/constants';
import { PhotoInfo } from '../components/InsuranceDetailsModal/PhotoButton';
import { MODAL_MAPPINGS } from '../screens/checklist/utils';
import { useAppStateStore } from '../store/app-store';
import { ChecklistModalIds, useChecklistStore } from '../store/checklist-store';
import { useToast } from '../common/hooks/useToast';

export const setOpenedModal = (id?: ChecklistModalIds) =>
  useChecklistStore.setState({ currentOpenedModalId: id });

export const setBrandColor = (brandColor?: string) =>
  useChecklistStore.setState({ brandColor });

export const setWebsiteLocations = (
  websiteLocations?: FinalizeWebsiteLocationDto[],
) => useChecklistStore.setState({ websiteLocations });

export const setCurrentOpenedLocationId = (currentOpenedLocationId?: string) =>
  useChecklistStore.setState({ currentOpenedLocationId });

export const setPatientWebsiteData = (
  patientWebsiteData?: WebsiteWizardResponseDto,
) => useChecklistStore.setState({ patientWebsiteData });

export const checkAppLogoRequirement = (image: PhotoInfo): string | null => {
  const tenMB = 10 * 1024 * 1024;
  const base64String = image.uri?.split(',')[1] || '';
  const fileSizeInBytes =
    (base64String.length * 3) / 4 -
    (base64String.endsWith('==') ? 2 : base64String.endsWith('=') ? 1 : 0);

  if (fileSizeInBytes > tenMB) {
    return getText('specs-first-item');
  }
  const validImage = image.uri?.substring(
    'data:image/'.length,
    image.uri.indexOf(';base64'),
  );

  return validImage !== 'png' && validImage !== 'svg'
    ? getText('specs-second-item')
    : null;
};

export const fetchChecklist = async () => {
  try {
    useChecklistStore.setState({ status: 'loading' });
    const locationId = useAppStateStore.getState().locationId;
    const data = await PharmacyService.findChecklistById(locationId);
    useChecklistStore.setState({ checklistOptions: data });
    useChecklistStore.setState({ status: 'success' });
  } catch (e: any) {
    logError(e);
    useChecklistStore.setState({ status: 'error', error: { message: e } });
  }
};

export const fetchMediaColors = async () => {
  try {
    const data = await PharmacyService.fetchMediaColors();
    return data;
  } catch (e: any) {
    logError(e);
  }
};

export const fetchMedia = async () => {
  try {
    const pharmacyId = useAppStateStore.getState().pharmacyId;
    const data = await PharmacyService.fetchMedia(pharmacyId);
    return data;
  } catch (e: any) {
    logError(e);
  }
};

export const createMedia = async (media: CreateMediaDto) => {
  try {
    const pharmacyId = useAppStateStore.getState().pharmacyId;
    const data = await PharmacyService.createMedia(pharmacyId, media);
    return data;
  } catch (e: any) {
    logError(e);
  }
};

export const fetchAppSettings = async () => {
  try {
    const pharmacyId = useAppStateStore.getState().pharmacyId;
    const data = await PharmacyService.getAppSettings(pharmacyId);
    useChecklistStore.setState({ appSettings: data });
    return data;
  } catch (e: any) {
    logError(e);
  }
};

export const createAppSettings = async (appSettings: CreateAppSettingsDto) => {
  try {
    const pharmacyId = useAppStateStore.getState().pharmacyId;
    const data = await PharmacyService.createAppSettings(
      pharmacyId,
      appSettings,
    );
    return data;
  } catch (e: any) {
    logError(e);
    throw e;
  }
};

export const fetchDunsList = async () => {
  try {
    const pharmacyId = useAppStateStore.getState().pharmacyId;
    const data = await PharmacyService.getAppSettingsDunsList(pharmacyId);
    useChecklistStore.setState({ dunsList: data });
    return data;
  } catch (e: any) {
    logError(e);
  }
};

export const fetchPatientWebsite = async () => {
  try {
    const data = await PharmacyService.fetchPatientWebsite();
    return data;
  } catch (e: any) {
    logError(e);
  }
};

export const savePatientWebsite = async (dto: AutoSaveWebsiteWizardDto) => {
  const { toast } = useToast();
  try {
    const data = await PharmacyService.savePatientWebsite(dto);
    setPatientWebsiteData(data);
    return data;
  } catch (e: any) {
    logError(e);
    toast('Error', {
      type: 'error',
      content: getText('something-went-wrong-implicit'),
    });
    throw e;
  }
};

export const submitPatientWebsite = async (dto: FinalizeWebsiteWizardDto) => {
  const { toast } = useToast();
  try {
    const data = await PharmacyService.submitPatientWebsite(dto);
    setPatientWebsiteData(data);
    return data;
  } catch (e: any) {
    logError(e);
    toast('Error', {
      type: 'error',
      content: getText('something-went-wrong-implicit'),
    });
    throw e;
  }
};

export const openNextModalId = (
  services: FinalizeWebsiteServiceOptionDto[],
  step: number,
) => {
  const mappings = Object.fromEntries(
    Object.entries(MODAL_MAPPINGS).slice(step),
  );
  const slugOrder = [
    WebsiteServiceOptionSlug.PharmacyServices,
    WebsiteServiceOptionSlug.Compounding,
    WebsiteServiceOptionSlug.Delivery,
    WebsiteServiceOptionSlug.AboutUs,
  ];

  const nextModalId: ChecklistModalIds =
    slugOrder
      .map((slug) =>
        services.find((service) => service.slug === slug && service.selected),
      )
      .map((service) => (service ? mappings[service.slug] : undefined))
      .find((id) => id) || 'patient-website-additional-info';

  setOpenedModal(nextModalId);
};

export const openPreviousModalId = (
  services: FinalizeWebsiteServiceOptionDto[],
  step: number,
) => {
  const mappings = Object.fromEntries(
    Object.entries(MODAL_MAPPINGS).slice(0, step),
  );
  const slugOrder = [
    WebsiteServiceOptionSlug.AboutUs,
    WebsiteServiceOptionSlug.Delivery,
    WebsiteServiceOptionSlug.Compounding,
    WebsiteServiceOptionSlug.PharmacyServices,
  ];

  const nextModalId: ChecklistModalIds =
    slugOrder
      .map((slug) =>
        services.find((service) => service.slug === slug && service.selected),
      )
      .map((service) => (service ? mappings[service.slug] : undefined))
      .find((id) => id) || 'patient-website-services';

  setOpenedModal(nextModalId);
};
