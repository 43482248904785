import { DirectMessagePatientDto } from '@digitalpharmacist/unified-communications-service-client-axios';
import {
  NavigationProp,
  ParamListBase,
  useNavigation,
} from '@react-navigation/native';
import { makeStyles } from 'assets/theme';
import { BaseFilterValues } from 'assets/types/messageTypes';
import React, { FunctionComponent } from 'react';
import { FlatList, View } from 'react-native';
import PatientService from '../../../../api/PatientService';
import { useAppStateStore } from '../../../../store/app-store';
import { PatientConversationWrapper } from '../../../messages/components/PatientConversationWrapper';
import { setSelectedPatient } from '../../../messages/stores/chat-store/chat-actions';
import { setBaseFilter } from '../../../messages/stores/data-manipulation-store/data-manipulation-actions';

export const MessageList: FunctionComponent<MessageListProps> = ({
  messageList,
}) => {
  const navigation = useNavigation<NavigationProp<ParamListBase>>();
  const { locationId } = useAppStateStore();
  const styles = useStyles();

  const onSelectPatient = async (
    patientConversationMeta: DirectMessagePatientDto,
  ) => {
    try {
      const result = await PatientService.getPatientLocationRecord(
        locationId,
        patientConversationMeta.location_patient_id,
      );
      setSelectedPatient(result);
      setBaseFilter(BaseFilterValues.UNREAD);
      navigation.navigate('messages');
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <View style={styles.container}>
      <FlatList
        data={messageList}
        keyExtractor={(patient) => patient.location_patient_id}
        renderItem={(item) => (
          <PatientConversationWrapper
            patient={item.item}
            index={item.index}
            patientsCount={messageList.length}
            selectedPatient={null}
            onSelectPatient={onSelectPatient}
          />
        )}
      />
    </View>
  );
};

export type MessageListProps = {
  messageList: DirectMessagePatientDto[];
};

const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
    marginVertical: 10,
  },
}));
