import { useEffect } from 'react';
import {
  AuthorType,
  BulkDto,
  DirectMessagePatientDto,
} from '@digitalpharmacist/unified-communications-service-client-axios';
import {
  addViewedConversationsToSet,
  removeSelectedConversation,
  removeSelectedPatient,
  removeSelectedPatientConversations,
  setIncomeMessage,
  setMessagesPagination,
  setConversationsGroupedByPatient,
  setPatientsWithoutConversationsSet,
  setRawConversations,
  setRawConversationsCount,
  setSelectedConversation,
  setSelectedMessages,
  setSelectedPatient,
  setSelectedPatientConversations,
  setUpdatedUserStatus,
} from '../stores/chat-store/chat-actions';
import { useUserState } from '../../../store/user-store';
import {
  EmittedMessage,
  EmittedNewConversation,
  EmittedUpdatedUserStatus,
  Order,
} from 'assets/types/messageTypes';
import { useChatState } from '../stores/chat-store/chat-store';
import { useAppStateStore } from '../../../store/app-store';
import UnifiedCommsService from '../../../api/UnifiedCommsService';
import {
  compare,
  groupConversationsByPatient,
  getMessages,
  getPatientsWithoutConversationsSet,
} from 'assets/utils/messageUtils';
import { useConversationsManipulationState } from '../stores/data-manipulation-store/data-manipulation-store';
import { useFilterCriteria } from './useFilterCriteria';
import PatientService from '../../../api/PatientService';
import { TAKE } from '../data';
import FileStorageService from '../../../api/FileStorageService';
import { socket } from '../../../../socket';
import { ampli } from '../../../src/ampli';

export const useSocketsForChat = () => {
  const locationId = useAppStateStore((state) => state.locationId);
  const pharmacyId = useAppStateStore((state) => state.pharmacyId);
  const user = useUserState((state) => state.data);
  const userId = user?.id;
  const selectedPatient = useChatState((state) => state.selectedPatient);
  const selectedConversation = useChatState(
    (state) => state.selectedConversation,
  );
  const rawConversationsCount = useChatState(
    (state) => state.rawConversationsCount,
  );

  const sorting = useConversationsManipulationState((state) => state.sorting);
  const filterCriteria = useFilterCriteria();

  useEffect(() => {
    const onMessagesReceived = async (message: EmittedMessage) => {
      await setIncomeMessage(
        message.pharmacy_id,
        message.location_id,
        message.location_patient_id,
        message.conversation_id,
        filterCriteria,
      );

      const isInSameConversation =
        selectedConversation?.conversation_id === message.conversation_id;

      if (isInSameConversation && message.author_type !== AuthorType.Pharmacy) {
        const data = await markAsRead(
          pharmacyId,
          locationId,
          message.location_patient_id,
          message.conversation_id,
          selectedConversation.patient_viewed_all_messages,
        );
        ampli.messageRead({
          conversationID: message.conversation_id,
          messageID: data.id,
          messageReadTime: new Date().toISOString(),
          messageSubject: data.subject,
        });
      }
    };

    const onNewConversation = async (conversation: EmittedNewConversation) => {
      const messagesData = await Promise.all([
        UnifiedCommsService.getAllFilteredConversations(
          locationId,
          filterCriteria,
        ),
        PatientService.getPatientLocationRecord(
          locationId,
          conversation.location_patient_id,
        ),
      ]);

      const filteredConversations = messagesData[0];
      const newSelectedPatientLocationRecord = messagesData[1];

      const groupedConversations = groupConversationsByPatient(
        filteredConversations,
      ).sort((currentConversation, nextConversation) =>
        compare(
          currentConversation,
          nextConversation,
          sorting.field,
          sorting.order,
          sorting.isDate,
        ),
      );
      const returnedPatientsSet = new Set(
        groupedConversations.map(
          (conversation: DirectMessagePatientDto) =>
            conversation.location_patient_id,
        ),
      );

      // If the returned conversation with selected patient was not returned,
      // that means filters were applied and returned patient does not exist in returned conversations.
      if (!returnedPatientsSet.has(conversation.location_patient_id)) {
        return;
      }

      const viewedConversations = filteredConversations
        .filter((conversation) => conversation.pharmacy_viewed_all_messages)
        .map((conversation) => conversation.conversation_id);

      const { failedMessagesInConversation } = useChatState.getState();

      const messagesResult = await getMessages({
        pharmacyId: pharmacyId,
        locationId: locationId,
        locationPatientId: conversation.location_patient_id,
        conversationId: conversation.conversation_id,
        UnifiedCommsService: UnifiedCommsService,
        FileStorageService: FileStorageService,
        failedMessagesInConversation: failedMessagesInConversation,
        skip: 0,
        take: TAKE,
      });
      const messagesPagination = {
        skip: 0,
        take: TAKE,
        count: messagesResult.count,
      };

      const newSelectedPatientConversations = filteredConversations
        .filter(
          (conversation) =>
            conversation.location_id === locationId &&
            conversation.location_patient_id ===
              newSelectedPatientLocationRecord.id,
        )
        .sort((currentConversation, nextConversation) =>
          compare(
            currentConversation,
            nextConversation,
            'most_recent_qualifying_message_date',
            Order.DESC,
            true,
          ),
        );
      const patientsWithoutConversationsSet =
        getPatientsWithoutConversationsSet(groupedConversations);

      const currentConversation = newSelectedPatientConversations.find(
        (selectedConversation) =>
          selectedConversation.conversation_id === conversation.conversation_id,
      );

      setMessagesPagination(messagesPagination);
      setRawConversations(filteredConversations);
      setConversationsGroupedByPatient(groupedConversations);
      setSelectedPatientConversations(newSelectedPatientConversations);
      addViewedConversationsToSet(viewedConversations);
      if (
        currentConversation &&
        currentConversation.original_author_id === user?.id
      ) {
        setSelectedPatientConversations(newSelectedPatientConversations);
        setSelectedPatient(newSelectedPatientLocationRecord);
        setSelectedConversation(currentConversation);

        setTimeout(() => {
          setSelectedMessages(messagesResult.messages);
        }, 2000);
      }
      setPatientsWithoutConversationsSet(patientsWithoutConversationsSet);
      setInitialConversationCount(filteredConversations.length);
    };

    const onNewBulkMessage = async (_bulk: BulkDto) => {
      const filteredConversations =
        await UnifiedCommsService.getAllFilteredConversations(
          locationId,
          filterCriteria,
        );

      const groupedConversations = groupConversationsByPatient(
        filteredConversations,
      ).sort((currentConversation, nextConversation) =>
        compare(
          currentConversation,
          nextConversation,
          sorting.field,
          sorting.order,
          sorting.isDate,
        ),
      );

      const viewedConversations = filteredConversations
        .filter((conversation) => conversation.pharmacy_viewed_all_messages)
        .map((conversation) => conversation.conversation_id);

      const patientsWithoutConversationsSet =
        getPatientsWithoutConversationsSet(groupedConversations);

      setRawConversations(filteredConversations);
      setConversationsGroupedByPatient(groupedConversations);
      addViewedConversationsToSet(viewedConversations);
      setPatientsWithoutConversationsSet(patientsWithoutConversationsSet);
      setInitialConversationCount(filteredConversations.length);
    };

    const onUpdatedUserStatus = (
      updatedUserStatus: EmittedUpdatedUserStatus,
    ) => {
      setUpdatedUserStatus(updatedUserStatus);
    };

    function setInitialConversationCount(count: number) {
      if (rawConversationsCount === 0 && count !== 0) {
        setRawConversationsCount(count);
      }
    }

    const logConnectionError = (error: any) => {
      console.error('Websocket connection error message: ', error.message);
      console.error(
        'Websocket connection error description: ',
        error.description,
      );
      setTimeout(() => {
        if (socket) {
          socket.connect();
        }
      }, 1000);
    };

    const onLPRMerge = async () => {
      const filteredConversations =
        await UnifiedCommsService.getAllFilteredConversations(
          locationId,
          filterCriteria,
        );

      const groupedConversations = groupConversationsByPatient(
        filteredConversations,
      ).sort((currentConversation, nextConversation) =>
        compare(
          currentConversation,
          nextConversation,
          sorting.field,
          sorting.order,
          sorting.isDate,
        ),
      );

      setRawConversations(filteredConversations);
      setConversationsGroupedByPatient(groupedConversations);
      removeSelectedPatient();
      removeSelectedConversation();
      removeSelectedPatientConversations();
    };

    socket.on('new_conversation', onNewConversation);
    socket.on('message', onMessagesReceived);
    socket.on('updated_viewed_user_status', onUpdatedUserStatus);
    socket.on('lpr_merge', onLPRMerge);
    socket.on('bulk_sent', onNewBulkMessage);
    socket.on('connect_error', logConnectionError);
    return () => {
      socket.off('new_conversation', onNewConversation);
      socket.off('message', onMessagesReceived);
      socket.off('updated_viewed_user_status', onUpdatedUserStatus);
      socket.off('lpr_merge', onLPRMerge);
      socket.off('bulk_sent', onNewBulkMessage);
      socket.off('connect_error', logConnectionError);
    };
  }, [
    socket,
    pharmacyId,
    locationId,
    sorting,
    userId,
    selectedConversation,
    filterCriteria,
    rawConversationsCount,
    selectedPatient,
  ]);
};

export async function markAsRead(
  pharmacyId: string,
  locationId: string,
  locationPatientId: string,
  conversationId: string,
  patientViewedAllMessages: boolean,
) {
  return await UnifiedCommsService.updateUserViewedStatus(
    locationId,
    locationPatientId,
    conversationId,
    {
      patient_viewed_all_messages: patientViewedAllMessages,
      pharmacy_viewed_all_messages: true,
    },
  );
}
