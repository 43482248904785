import { AddressDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { Text } from 'assets/components/text';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import { FunctionComponent, PropsWithChildren } from 'react';
import { View } from 'react-native';
import { RowItemRender } from '../../../../components/Settings/RowItemRender';

export const LocationContactInfoItemRenderer: FunctionComponent<
  PropsWithChildren<LocationContactInfoItemRendererProps>
> = ({ phone, fax, email, address, billing_address }) => {
  return (
    <>
      <RowItemRender title={getText('locations-phone-number')} value={phone} />
      <RowItemRender title={getText('locations-fax-number')} value={fax} />
      <RowItemRender
        title={getText('locations-email-address')}
        value={email ?? ''}
      />
      <RowItemRender title={getText('pharmacy-store-address')}>
        {address && <AddressViewComponent {...address} />}
      </RowItemRender>
      <RowItemRender title={getText('account-billing-address')}>
        {billing_address && <AddressViewComponent {...billing_address} />}
      </RowItemRender>
    </>
  );
};

const AddressViewComponent: FunctionComponent<AddressDto> = ({
  address1,
  address2,
  postal_code,
  city,
  state,
}) => {
  const styles = useStyles();
  return (
    <View>
      <Text style={styles.value}>{address1}</Text>
      <Text style={styles.value}>{address2}</Text>
      <Text style={styles.value}>
        {city}, {state} {postal_code}
      </Text>
    </View>
  );
};

interface LocationContactInfoItemRendererProps {
  phone?: string;
  fax?: string;
  email?: string;
  address?: AddressDto;
  billing_address?: AddressDto;
}

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.fonts.regular,
    fontSize: 14,
    lineHeight: 16,
    color: theme.palette.gray[900],
    fontWeight: '600',
  },
  value: {
    ...theme.fonts.regular,
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[700],
  },
}));
