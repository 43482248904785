import { User } from 'react-native-gifted-chat';
import { Avatar as PaperAvatar } from 'react-native-paper';
import { Avatar } from 'assets/components/avatar';
import { SettingsIcon } from 'assets/icons';
import theme from 'assets/theme';
import { AuthorType } from '@digitalpharmacist/unified-communications-service-client-axios';
import UsersService from '../../../api/UsersService';
import PatientService from '../../../api/PatientService';
import { SYSTEM_GENERATED_ID } from '../data';
import { Icon } from 'assets/components/icon';

export async function getMessageAuthor(
  authorId: string,
  authorType: AuthorType,
  locationId?: string,
  locationPatientId?: string,
): Promise<User> {
  const author = await getAuthor(
    authorId,
    authorType,
    locationId,
    locationPatientId,
  );

  const avatar =
    authorType === AuthorType.SystemGenerated ? (
      <PaperAvatar.Icon
        size={32}
        icon={() => (
          <Icon color={theme.palette.gray[700]} size={22} icon={SettingsIcon} />
        )}
        style={{ backgroundColor: theme.palette.gray[100] }}
      />
    ) : (
      <Avatar
        size={32}
        firstName={author.firstName}
        lastName={author.lastName}
      />
    );

  return {
    _id: authorId,
    name: `${author.firstName} ${author.lastName}`,
    avatar: () => avatar,
  };
}

interface Author {
  id: string;
  firstName: string;
  lastName: string;
}

export async function getAuthor(
  authorId: string,
  authorType: AuthorType,
  locationId?: string,
  locationPatientRecordId?: string,
): Promise<Author> {
  const author: Author = {
    id: authorId,
    firstName: '',
    lastName: '',
  };

  try {
    if (authorType === AuthorType.SystemGenerated) {
      author.id = SYSTEM_GENERATED_ID;
      author.firstName = 'Automated';
      author.lastName = 'Action';
    } else if (authorType === AuthorType.Pharmacy) {
      const pharmacistUser = await UsersService.getPharmacistUser(authorId);
      author.firstName = pharmacistUser.firstName ?? '';
      author.lastName = pharmacistUser.lastName ?? '';
    } else if (
      authorType === AuthorType.Patient &&
      locationId &&
      locationPatientRecordId
    ) {
      const locationPatientRecord =
        await PatientService.getPatientLocationRecord(
          locationId,
          locationPatientRecordId,
        );
      author.firstName = locationPatientRecord.first_name ?? '';
      author.lastName = locationPatientRecord.last_name ?? '';
    }
  } catch (error) {
    console.error('Error getting a message author', error);
  }

  return author;
}
