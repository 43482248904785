import React, { FunctionComponent } from 'react';
import Svg, { G, Path, Mask, Defs, ClipPath } from 'react-native-svg';

const NoFormsImage: FunctionComponent = () => (
  <Svg width={370} height={290} fill="none">
    <G clipPath="url(#a)">
      <Path
        fill="#6AC0CC"
        d="M244.968 65.08c-5.829 0-10.557 4.955-10.557 11.063 0-6.108-4.727-11.062-10.556-11.062 5.829 0 10.556-4.954 10.556-11.062 0 6.108 4.728 11.062 10.557 11.062ZM104.362 146.233c-4.278 0-7.749 3.637-7.749 8.12 0-4.483-3.47-8.12-7.749-8.12 4.278 0 7.749-3.637 7.749-8.12 0 4.483 3.471 8.12 7.749 8.12Z"
      />
      <Path
        fill="#FFC854"
        d="M129.662 87.526c-5.829 0-10.557 4.954-10.557 11.062 0-6.108-4.728-11.062-10.557-11.062 5.829 0 10.557-4.954 10.557-11.063 0 6.109 4.728 11.063 10.557 11.063ZM249.915 123.528c-4.278 0-7.749 3.637-7.749 8.12 0-4.483-3.471-8.12-7.749-8.12 4.278 0 7.749-3.637 7.749-8.12 0 4.483 3.471 8.12 7.749 8.12ZM225.775 37.615c-3.206 0-5.801 2.72-5.801 6.078 0-3.359-2.594-6.078-5.8-6.078 3.206 0 5.8-2.718 5.8-6.077 0 3.359 2.595 6.077 5.801 6.077Z"
      />
      <Path
        stroke="#FF878B"
        strokeMiterlimit={10}
        strokeWidth={1.13}
        d="M165.921 190.083c1.946 0 3.523-1.653 3.523-3.692s-1.577-3.691-3.523-3.691c-1.945 0-3.522 1.652-3.522 3.691 0 2.039 1.577 3.692 3.522 3.692ZM223.497 210.516c1.946 0 3.523-1.653 3.523-3.692 0-2.038-1.577-3.691-3.523-3.691-1.946 0-3.523 1.653-3.523 3.691 0 2.039 1.577 3.692 3.523 3.692Z"
      />
      <Path
        fill="#CFB3AE"
        d="M338.936 289.408c-2.485-12.277-12.88-21.49-25.34-21.49-1.839 0-3.638.199-5.367.586-1.528-8.157-8.926-14.343-17.81-14.343-.07 0-.139 0-.208.006-1.839-15.835-15.752-28.154-32.651-28.154-9.155 0-17.441 3.619-23.397 9.461a39.967 39.967 0 0 0-9.461-1.13c-3.263 0-6.14.357-9.035 1.033.006-.344.012-.683.012-1.033 0-10.766-5.23-20.252-13.186-25.816a18.953 18.953 0 0 0 1.113-6.452c0-3.698-1.044-7.142-2.837-10.024-.969-1.552-1.257-3.437-.888-5.256.202-.997.306-2.03.306-3.087 0-8.483-6.81-15.292-15.02-14.754-6.919.453-12.604 6.277-13.163 13.521a15.639 15.639 0 0 0 .265 4.332 7.289 7.289 0 0 1-.916 5.293c-1.776 2.87-2.808 6.301-2.808 9.975 0 .972.069 1.927.213 2.857.34 2.278-.536 4.562-2.191 6.078-4.653 4.272-8.014 10.053-9.398 16.615-6.388-4.519-14.264-7.184-22.785-7.184-21.333 0-38.619 16.705-38.619 37.307 0 .49.006.979.035 1.463a33.877 33.877 0 0 0-9.877-1.463c-18.11 0-32.794 14.162-32.846 31.653h285.848l.011.006Z"
      />
      <Path
        fill="#FF878B"
        d="M340.897 290c-3.344-13.008-13.382-22.076-27.301-22.076 0 0-3.419.187-5.367.586 5.385-6.924-11.48-21.551-17.81-14.343-3.068-13.521-17.482-23.218-32-23.218-12.77 0-26.913 13.237-19.793 31.151 0 0-9.709-10.851-20.324-10.126-13.785.942-18.421 6.615-24.913 18.282-7.409-11.914-20.111-14.863-34.669-13.95-12.736.803-24.677 12.162-24.677 12.162 15.245-17.968 23.495-37.755-8.821-41.543-16.201-1.903-29.572 13.582-30.073 30.535a34.449 34.449 0 0 0-8.441-1.046c-18.525 0-33.77 14.706-35.678 33.586H340.897Z"
      />
      <Path
        stroke="#FF878B"
        strokeMiterlimit={10}
        strokeWidth={1.13}
        d="M223.07 255.2c0 7.594-5.875 13.751-13.134 13.751-3.707 0-7.057-1.613-9.444-4.205-3.165 3.691-7.76 6.017-12.886 6.017-9.571 0-17.32-8.12-17.32-18.149 0-10.029 7.749-18.155 17.32-18.155 6.486 0 12.131 3.734 15.1 9.256a12.643 12.643 0 0 1 7.23-2.272c7.253 0 13.134 6.157 13.134 13.751v.006ZM289.064 233.758c4.145 8.174 1.193 18.318-6.608 22.662-7.801 4.344-17.481 1.239-21.626-6.936a17.258 17.258 0 0 1-1.869-7.739c-3.211-.556-6.14-2.616-7.783-5.86-2.802-5.522-.807-12.38 4.462-15.316 5.27-2.936 11.814-.84 14.616 4.682.046.097.104.2.144.296 7.144-2.229 15.026 1.046 18.658 8.199l.006.012Z"
      />
      <Path
        stroke="#FF878B"
        strokeMiterlimit={10}
        strokeWidth={0.98}
        d="M89.7 242.694c4.512 0 8.17-3.833 8.17-8.561s-3.658-8.561-8.17-8.561c-4.512 0-8.17 3.833-8.17 8.561s3.658 8.561 8.17 8.561ZM159.989 222.949c3.276 0 5.933-2.783 5.933-6.216 0-3.434-2.657-6.217-5.933-6.217-3.277 0-5.933 2.783-5.933 6.217 0 3.433 2.656 6.216 5.933 6.216Z"
      />
      <Path
        fill="#0063B2"
        d="M208.201 164.406h-45.808V76.905c0-4.7 9.035-40.66 22.042-40.66 13.007 0 23.766 36.165 23.766 40.66v87.501Z"
      />
      <Path
        fill="#5CC4FF"
        d="M194.738 173.445h-18.877c-3.055 0-5.142-2.453-3.995-4.701l2.214-4.338h22.18l2.405 4.211c1.291 2.26-.796 4.828-3.927 4.828ZM141.983 164.406h20.41V97.228l-25.853 60.52c-1.384 3.238 1.412 6.664 5.437 6.664l.006-.006ZM228.617 164.406h-20.41V97.228l25.853 60.52c1.383 3.238-1.413 6.664-5.437 6.664l-.006-.006Z"
      />
      <Mask
        id="b"
        width={47}
        height={129}
        x={162}
        y={36}
        maskUnits="userSpaceOnUse"
      >
        <Path
          fill="#fff"
          d="M208.201 164.406h-45.808V76.905c0-4.7 9.035-40.66 22.042-40.66 13.007 0 23.766 36.165 23.766 40.66v87.501Z"
        />
      </Mask>
      <G
        stroke="#C9DBE6"
        strokeMiterlimit={10}
        strokeWidth={1.13}
        mask="url(#b)"
      >
        <Path d="M162.087 76.608h14.714M193.735 76.608h14.466M162.399 152.54h45.802" />
        <Path
          strokeLinecap="round"
          d="M208.201 106.327h-7.882c-1.799 0-3.263 1.529-3.263 3.42v9.425c0 1.885-1.459 3.419-3.263 3.419h-5.38c-1.81 0-3.275 1.547-3.263 3.444l.121 15.273c.017 1.879 1.47 3.39 3.263 3.39h19.667M162.399 106.327h21.309c1.799 0 3.264 1.529 3.264 3.42v4.356c0 1.885-1.459 3.419-3.264 3.419h-5.765c-1.799 0-3.264 1.529-3.264 3.42v10.494c0 1.885-1.458 3.42-3.263 3.42h-9.023"
        />
      </G>
      <Path
        stroke="#C9DBE6"
        strokeMiterlimit={10}
        strokeWidth={1.44}
        d="M185.3 88.13c6.072 0 10.995-5.158 10.995-11.522 0-6.363-4.923-11.521-10.995-11.521-6.073 0-10.995 5.158-10.995 11.521 0 6.364 4.922 11.522 10.995 11.522Z"
      />
      <Path
        fill="#6AC0CC"
        d="M185.3 86.444c5.184 0 9.386-4.404 9.386-9.836s-4.202-9.836-9.386-9.836c-5.184 0-9.387 4.404-9.387 9.836s4.203 9.836 9.387 9.836Z"
      />
      <Path
        fill="#fff"
        d="M181.61 83.617c-3.725-2.109-5.114-6.984-3.102-10.887 2.012-3.903 6.665-5.36 10.39-3.25"
      />
      <Path
        fill="#804447"
        d="M319.42 272.244c-6.884 0-13.198 2.543-18.139 6.772-2.992-7.461-10.032-12.699-18.248-12.699-7.253 0-13.601 4.09-17.043 10.186a38.509 38.509 0 0 0-17.62-4.259c-8.925 0-17.164 3.051-23.829 8.21-2.485-6.44-8.505-10.972-15.544-10.972-7.64 0-14.086 5.341-16.115 12.652-6.002-8.809-15.839-14.555-26.96-14.555-11.122 0-20.711 5.601-26.73 14.216-3.915-2.906-8.689-4.615-13.849-4.615-6.342 0-12.108 2.585-16.392 6.796-4.831-12.27-16.368-20.904-29.825-20.904-9.467 0-17.977 4.278-23.881 11.081a20.11 20.11 0 0 0-4.203-.441c-9.71 0-17.874 6.905-20.278 16.288h314.82c-4.48-10.464-14.506-17.756-26.17-17.756h.006Z"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M.5 0h369v290H.5z" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default NoFormsImage;
