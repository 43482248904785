import React, { FunctionComponent } from 'react';
import Svg, { Path } from 'react-native-svg';
import { IconProps } from './types';

export const BulkIcon: FunctionComponent<IconProps> = ({
  color,
  size,
  testID,
  strokeWidth,
}) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      color={color}
      testID={testID}
      strokeWidth={strokeWidth}
    >
      <Path
        d="M19.095 2.4248L0.904794 2.4248L7.00376 9.28167M19.095 2.4248L9.46733 18.3714L7.00376 9.28167M19.095 2.4248L7.00376 9.28167M0.904779 8.38604L1.99989 7.84835M1.66143 12.4743L4.3225 11.0675M4.3225 15.5722L5.42392 14.9661"
        stroke="#0093E8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
