import { FunctionComponent } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { DirectMessagePatientDto } from '@digitalpharmacist/unified-communications-service-client-axios';
import { PatientConversation } from './PatientConversation';
import { makeStyles } from 'assets/theme';
import { LocationPatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';

export const PatientConversationWrapper: FunctionComponent<
  PatientConversationWrapperProps
> = ({
  patient,
  selectedPatient,
  index,
  avatarColor,
  patientsCount,
  onSelectPatient,
}) => {
  const styles = useStyles();
  return (
    <TouchableOpacity onPress={() => void onSelectPatient(patient)}>
      <View
        style={[styles.patient, index + 1 === patientsCount && styles.isLast]}
      >
        <PatientConversation
          patient={patient}
          isViewed={patient.pharmacy_viewed_all_messages}
          isSelected={patient.location_patient_id === selectedPatient?.id}
          avatarColor={avatarColor}
        />
      </View>
    </TouchableOpacity>
  );
};

interface PatientConversationWrapperProps {
  patient: DirectMessagePatientDto;
  selectedPatient: LocationPatientRecordDto | null;
  index: number;
  patientsCount: number;
  avatarColor?: string;
  onSelectPatient: (
    patientConversationMeta: DirectMessagePatientDto,
  ) => Promise<void>;
}

const useStyles = makeStyles((theme) => ({
  patient: {
    borderTopWidth: 1,
    borderTopColor: theme.palette.gray[100],
    cursor: 'pointer',
  },
  isLast: {
    borderTopWidth: 1,
    borderTopColor: theme.palette.gray[100],
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.gray[100],
  },
}));
