import create from 'zustand';

export const initialState: MessagingState = {
  pharmacyLocationHasPatientRecords: false,
};

export const useMessagingState = create<MessagingState>(() => initialState);

interface MessagingState {
  pharmacyLocationHasPatientRecords: boolean;
}
