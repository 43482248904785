import {
  FormStatus,
  ListFormDto,
} from '@digitalpharmacist/forms-service-client-axios';
import { GridApi } from '@ag-grid-community/core';
import { useFormsDataTableState } from './forms-data-table-store';
import { logError } from 'assets/logging/logger';
import { useAppStateStore } from '../../store/app-store';
import FormsService from '../../api/FormsService';
import { useToast } from '../../common/hooks/useToast';
import { ampli } from '../../common/ampliPharmacist';
import * as Clipboard from 'expo-clipboard';
import { getText } from 'assets/localization/localization';

const errorOccurred = (error: any, errorMessage?: string) => {
  const { toast } = useToast();
  const message = errorMessage
    ? errorMessage
    : 'An error occurred while trying to load forms. Please try again.';

  logError(error);
  useFormsDataTableState.setState({
    error: {
      message: message,
    },
    status: 'error',
  });

  toast('Error', { content: message, type: 'error' });
};

export const changeFormsStatus = async (
  formId: string,
  newStatus: FormStatus,
) => {
  useFormsDataTableState.setState({ error: undefined, status: 'loading' });

  const locationId = useAppStateStore.getState().locationId;

  try {
    const response = await FormsService.updateForm(locationId, formId, {
      status: newStatus,
    });

    // Refresh forms lists after changing the status
    await getForms(FormStatus.Enabled);
    await getForms(FormStatus.Disabled);
  } catch (error: any) {
    errorOccurred(error);
  }

  return null;
};

export const setContextMenuForm = (form: ListFormDto) => {
  useFormsDataTableState.setState({ contextMenuFormDetails: form });
};

export const deleteForm = async (formId: string) => {
  useFormsDataTableState.setState({ error: undefined, status: 'loading' });

  const { toast } = useToast();
  const locationId = useAppStateStore.getState().locationId;

  try {
    const response = await FormsService.deleteForm(locationId, formId);

    // Refresh forms lists after deleting a form
    await getForms(FormStatus.Enabled);
    await getForms(FormStatus.Disabled);
    toast('Form deleted', { type: 'success' });
  } catch (error: any) {
    errorOccurred(error);
  }

  return null;
};

export const getForms = async (statusFilter: FormStatus) => {
  const formsToUpdate =
    statusFilter == FormStatus.Enabled ? 'activeForms' : 'inactiveForms';

  useFormsDataTableState.setState({
    [formsToUpdate]: undefined,
    error: undefined,
    status: 'loading',
  });

  const { locationId, pharmacyId } = useAppStateStore.getState();

  try {
    const response = await FormsService.getFormsForLocation(
      pharmacyId,
      locationId,
      statusFilter,
    );

    useFormsDataTableState.setState({
      [formsToUpdate]: response,
      status: 'idle',
    });
  } catch (error: any) {
    errorOccurred(error);
  }
};

export const getFormSubmissionsCSV = async (formId: string) => {
  useFormsDataTableState.setState({ downloadLoading: formId });

  const locationId = useAppStateStore.getState().locationId;

  try {
    const response = await FormsService.getFormSubmissionsCSV(
      locationId,
      formId,
    );

    const activeforms = useFormsDataTableState.getState()?.activeForms;
    const inactiveForms = useFormsDataTableState.getState()?.inactiveForms;

    const singleActiveForm = activeforms?.find((form) => form.id === formId);
    const singleInactiveForm = inactiveForms?.find(
      (form) => form.id === formId,
    );

    if (singleActiveForm) {
      ampli.formDownloaded({
        formDownloadedTime: new Date().toISOString(),
        formName: singleActiveForm.title || '',
        formType: 'activeForm',
      });
    }

    if (singleInactiveForm) {
      ampli.formDownloaded({
        formDownloadedTime: new Date().toISOString(),
        formName: singleInactiveForm.title || '',
        formType: 'inactiveForm',
      });
    }

    return response;
  } catch (error: any) {
    errorOccurred(error);
  } finally {
    useFormsDataTableState.setState({ downloadLoading: undefined });
  }
};

export const persistGridApi = (api: GridApi) => {
  useFormsDataTableState.setState({ gridApi: api });
};

export const refreshFormsDataTable = () => {
  const { gridApi } = useFormsDataTableState.getState();
  useFormsDataTableState.setState({ error: undefined, status: 'loading' });
  gridApi?.refreshServerSideStore();
  // Using fixed timeout here to control the loading state, since `refreshServerSideStore` doesn't give us a promise to track
  setTimeout(() => {
    useFormsDataTableState.setState({ status: 'idle' });
  }, 1250);
};

export const copyUrl = async (formId: string) => {
  const { toast } = useToast();
  const locationId = useAppStateStore.getState().locationId;

  try {
    const { url } = await FormsService.getForm(locationId, formId);
    await Clipboard.setStringAsync(url);
    toast(getText('copied-to-clipboard'));
  } catch (error) {
    errorOccurred(error);
  }
};
