import React, {
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';
import { Modal } from 'assets/components/modal';
import { PharmacistLocationDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { PharmacyStoreSelectorGrid } from './PharmacyStoreSelectorGrid';
import { getText } from 'assets/localization/localization';

export const PharmacyStoreSelectorModal = forwardRef<
  PharmacyStoreSelectorModalHandler,
  PharmacyStoreSelectorModalProps
>(
  (
    { onChange }: PharmacyStoreSelectorModalProps,
    ref: ForwardedRef<PharmacyStoreSelectorModalHandler>,
  ) => {
    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => {
      setShowModal(true);
    };

    const handleDismissModal = () => {
      setShowModal(false);
    };

    useImperativeHandle(ref, () => ({
      show: handleShowModal,
      hide: handleDismissModal,
    }));

    const handleOnSelectionChange = (data: PharmacistLocationDto) => {
      onChange?.(data);
      handleDismissModal();
    };

    return (
      <Modal
        size="lg"
        title={getText('find-pharmacy')}
        show={showModal}
        contentContainerStyle={{ flex: 1 }}
        scrollViewStyle={{ flex: 1, marginBottom: 24 }}
        fillContainer
        hideFooter
        dismissButtonProps={{
          onPress: handleDismissModal,
          logger: { id: 'dismiss-button-modal-store-selector' },
        }}
      >
        <PharmacyStoreSelectorGrid onSelect={handleOnSelectionChange} />
      </Modal>
    );
  },
);

export interface PharmacyStoreSelectorModalProps {
  onChange?: (location: PharmacistLocationDto) => void;
}
export interface PharmacyStoreSelectorModalHandler {
  show: () => void;
  hide: () => void;
}
