import { FunctionComponent, PropsWithChildren } from 'react';
import { View } from 'react-native';
import { RowItemRender } from '../../../../components/Settings/RowItemRender';
import {
  modifyMessageSettings,
  updateShowMessageSettingsAutoModalState,
} from '../message-settings-actions';
import { PencilIcon } from 'assets/icons';
import { makeStyles } from 'assets/theme';
import { IconButton } from 'assets/components/icon-button';
import { ToggleSwitch } from 'assets/components/toggle-switch';
import { useMessageSettingsState } from '../message-settings-store';
import { useAppStateStore } from '../../../../store/app-store';
import { getText } from 'assets/localization/localization';

export const MessageSettingsItemRenderer: FunctionComponent<
  PropsWithChildren
> = () => {
  const style = useStyles();
  const { pharmacyId, locationId } = useAppStateStore();
  const { messageSettingsConfig } = useMessageSettingsState();

  return (
    <>
      <RowItemRender
        title={getText('auto-response-after-hours')}
        value={`"${
          messageSettingsConfig.auto_response_after_hours_message || undefined
        }"`}
        style={style.rowStyle}
      >
        <View style={style.actions}>
          <IconButton
            icon={PencilIcon}
            size={16}
            onPress={() => updateShowMessageSettingsAutoModalState(true)}
            logger={{ id: 'edit-auto-response-after-hours' }}
          />
          <ToggleSwitch
            logger={{ id: `is_auto_response_after_hours_enabled` }}
            value={messageSettingsConfig.is_auto_response_after_hours_enabled}
            onPress={() => {
              void modifyMessageSettings(pharmacyId, locationId, {
                ...messageSettingsConfig,
                is_auto_response_after_hours_enabled:
                  !messageSettingsConfig.is_auto_response_after_hours_enabled,
              });
            }}
          ></ToggleSwitch>
        </View>
      </RowItemRender>
    </>
  );
};

const useStyles = makeStyles(() => ({
  rowStyle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actions: {
    alignItems: 'center',
    flexDirection: 'row',
  },
}));
