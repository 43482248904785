import { Modal } from 'assets/components/modal';
import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useEffect,
} from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Form } from 'assets/layout/form/Form';
import { TextField } from 'assets/components/text-field';
import { getText } from 'assets/localization/localization';

export const NotesEditModal: FunctionComponent<
  PropsWithChildren<EditModalProps>
> = ({ show, onDismiss, onSave, form, notes }) => {
  const resetDataInForm = useCallback(() => {
    form.reset({
      notes,
    });
  }, [notes]);

  useEffect(() => {
    resetDataInForm();
  }, [resetDataInForm]);

  return (
    <Modal
      title={getText('edit-notes')}
      cancelButtonProps={{
        onPress: onDismiss,
        logger: { id: 'notes-cancel-button' },
      }}
      okButtonProps={{
        onPress: onSave,
        logger: { id: 'edit-notes-button' },
        hierarchy: 'primary',
        text: getText('save'),
      }}
      show={show}
      size="sm"
    >
      <Form methods={form}>
        <Form.Row>
          <Form.Column>
            <TextField
              name="notes"
              type="text"
              multiline={true}
              numberOfLines={3}
            />
          </Form.Column>
        </Form.Row>
      </Form>
    </Modal>
  );
};

export interface EditModalProps {
  show: boolean;
  onDismiss?: () => void;
  onSave?: () => void;
  form: UseFormReturn<{
    notes: string | null;
  }>;
  notes: string;
}

export default NotesEditModal;
