import medicationService, {
  ResourceItem,
  customValuePrefix,
  noKnownAllergiesOption,
  noKnownAllergiesValue,
} from '../../api/MedicationService';
import { GridApi } from '@ag-grid-community/core';
import prescriptionService from '../../api/PrescriptionService';
import { RefillSubmittedDto } from '@digitalpharmacist/prescription-service-client-axios';
import { RefillStatus } from '@digitalpharmacist/pms-integration-service-client-axios';
import { useAppStateStore } from '../../store/app-store';
import useRefillsStore from './refills-store';
import { handleError } from '../../common/errors/commonErrorHandler';
import { getText } from 'assets/localization/localization';

export const allergiesAreExplicitlyEmpty = (allergyIds: string[]) => {
  return allergyIds.length === 1 && allergyIds[0] === noKnownAllergiesValue;
};

export const findAllergiesById = async (
  allergyIds: string[],
): Promise<ResourceItem[]> => {
  if (allergiesAreExplicitlyEmpty(allergyIds)) {
    return [noKnownAllergiesOption];
  }

  return resolveIds(allergyIds, (ids) =>
    medicationService.allergyFind(ids.join(',')),
  );
};

// below here could move somewhere closer to the typeahead
export const resolveIds = async (
  ids: string[],
  idResolver: (ids: string[]) => Promise<ResourceItem[]>,
) => {
  if (ids.length === 0) return [];

  const resolvedItems = await idResolver(getNotCustomIds(ids));
  const customItems = getCustomResourceItems(ids);
  return [...resolvedItems, ...customItems];
};

export const getCustomResourceItems = (items: string[]): ResourceItem[] => {
  const customItems: ResourceItem[] = items
    .filter((v) => isCustom(v))
    .map((v) => {
      const value: ResourceItem = {
        text: v.split(customValuePrefix)[1],
        value: v,
      };
      return value;
    });
  return customItems;
};

export const isCustom = (text: string): boolean => {
  return text.startsWith(customValuePrefix, 0);
};

export const getNotCustomIds = (items: string[]): string[] => {
  return items.filter((v) => !isCustom(v));
};

export const refillMarkAsPassed = async (
  refill: RefillSubmittedDto,
  gridApi: GridApi,
) => {
  const locationId = useAppStateStore.getState().locationId;

  try {
    await prescriptionService.updateRefillRxStatus(
      locationId,
      refill.refill_item_id,
      RefillStatus.Accepted,
    );
  } catch (e) {
    handleError({
      showToast: true,
      userMessage: `Update Refill Error for Rx:${refill.rx_number}`,
    });
  }

  gridApi.refreshServerSideStore();
  loadRefillCount();
};

export const refillMarkAsNotPassed = async (
  refill: RefillSubmittedDto,
  gridApi: GridApi,
) => {
  const locationId = useAppStateStore.getState().locationId;

  try {
    await prescriptionService.updateRefillRxStatus(
      locationId,
      refill.refill_item_id,
      RefillStatus.Rejected,
    );
  } catch (e) {
    handleError({
      showToast: true,
      userMessage: `Update Refill Error for Rx:${refill.rx_number}`,
    });
  }

  gridApi.refreshServerSideStore();
  loadRefillCount();
};

export const loadRefillCount = () => {
  const locationId = useAppStateStore.getState().locationId;
  const setFailedRefillsCount =
    useRefillsStore.getState().setFailedRefillsCount;

  prescriptionService
    .findFailedRefillsCount(locationId)
    .then((count) => {
      setFailedRefillsCount(count);
    })
    .catch((error) =>
      handleError({
        userMessage: getText('server-error'),
        internalError: 'Error loading failed refills count',
        showToast: false,
        error,
      }),
    );
};
