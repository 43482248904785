import { makeStyles } from 'assets/theme';
import { FC, PropsWithChildren } from 'react';
import { View, Text } from 'react-native';

interface InfoCellProps {
  name: string;
}

const InfoCell: FC<PropsWithChildren<InfoCellProps>> = ({ name, children }) => {
  const styles = useStyles();
  return (
    <View>
      <Text style={styles.name}>{name}</Text>
      {children}
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  name: {
    ...theme.lumistryFonts.label,
    color: theme.palette.black[600],
    fontWeight: '700',
    fontSize: 16,
    marginBottom: theme.getSpacing(1.5),
  },
}));

export default InfoCell;
