import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { makeStyles } from '../../../../../../packages/assets/theme';
import { AvailabilitiesList as AvailabilitiesListComponent } from '../../../schedule/availabilities-list/AvailabilitiesList';
import { PharmacyScreenContainer } from 'assets/layout';

export const AvailabilitiesList: FunctionComponent<AvailabilitiesListProps> = (
  props,
) => {
  const styles = useStyles();

  return (
    <PharmacyScreenContainer>
      <View style={{ flexGrow: 1 }}>
        <AvailabilitiesListComponent />
      </View>
    </PharmacyScreenContainer>
  );
};

interface AvailabilitiesListProps {}

const useStyles = makeStyles((theme) => ({
  cellContainer: {
    marginTop: theme.getSpacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },
}));
