import { Modal } from 'assets/components/modal';
import { Text } from 'assets/components/text';
import { TextField } from 'assets/components/text-field';
import { useForm } from 'assets/form';
import { Form } from 'assets/layout/form/Form';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import _isEqual from 'lodash/isEqual';
import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useMemo,
} from 'react';
import { View } from 'react-native';
import * as validate from '@digitalpharmacist/validation-dp';
import { useAppSettingsState } from '../app-settings-store';
import {
  getBranding,
  setShowModalBrandingInfo,
  updateBrandingColors,
} from '../app-settings-actions';
import { BrandingColorSquare } from './BrandingColorSquare';

export const BrandingModal: FunctionComponent<PropsWithChildren<any>> = () => {
  const { showModalBrandingInfo, branded_colors } = useAppSettingsState();
  const styles = useStyles();

  useMemo(() => {
    getBranding();
  }, []);

  const resetBrandingColorsForm = () => {
    methods.reset({
      ...branded_colors,
      button_primary_color: branded_colors.button_primary_color,
      branded_text_color: branded_colors.branded_text_color,
    });
  };

  useEffect(() => {
    resetBrandingColorsForm();
  }, [branded_colors]);

  const methods = useForm<BrandingColorsProps>({
    defaultValues: {
      button_primary_color: branded_colors.button_primary_color,
      branded_text_color: branded_colors.branded_text_color,
    },
    mode: 'onChange',
  });

  const handleSubmit = async () => {
    const formValue = methods.getValues();
    const updatedBrandingColors = {
      button_primary_color: formValue.button_primary_color,
      branded_text_color: formValue.branded_text_color,
    };
    if (!_isEqual(updatedBrandingColors, branded_colors)) {
      await updateBrandingColors(updatedBrandingColors);
      await getBranding();
    }
    closeModal();
  };

  const closeModal = () => {
    setShowModalBrandingInfo(false);
  };

  const onDismiss = () => {
    resetBrandingColorsForm();
    closeModal();
  };

  return (
    <>
      <Modal
        title={getText('edit-brand-colors')}
        titleSize="sm"
        dismissButtonProps={{
          onPress: onDismiss,
          logger: { id: 'pharmacy-general-info-form-cancel-button-modal' },
        }}
        cancelButtonProps={{
          onPress: onDismiss,
          hierarchy: 'tertiary-gray',
          logger: { id: 'pharmacy-general-info-form-cancel-button-modal' },
        }}
        okButtonProps={{
          onPress: methods.handleSubmit(handleSubmit),
          logger: { id: 'pharmacy-general-info-form-ok-button-modal' },
          hierarchy: 'primary',
          text: getText('ok'),
        }}
        show={showModalBrandingInfo}
        isScrollable={true}
      >
        <Form methods={methods}>
          <Form.Row>
            <Form.Column style={styles.textWrapper}>
              <View style={styles.textView}>
                <Text style={styles.textFormat}>
                  {getText('primary-color')}
                </Text>
              </View>
            </Form.Column>
            <Form.Column style={styles.brandColors}>
              <BrandingColorSquare
                color={methods.watch('button_primary_color')}
              />
            </Form.Column>

            <Form.Column style={styles.inputWrapper}>
              <TextField
                value={branded_colors.button_primary_color}
                style={styles.inputStyle}
                name="button_primary_color"
                type="text"
                rules={{
                  validate: (value) => {
                    return value.includes('#') &&
                      validate.isHexColor(value) &&
                      value.length === 7
                      ? true
                      : value.includes('#') &&
                        validate.isHexColor(value) &&
                        value.length !== 7
                      ? getText('invalid-hex-length-use-6-digits')
                      : getText('invalid-hex-value');
                  },
                }}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column style={styles.textWrapper}>
              <View style={styles.textView}>
                <Text style={styles.textFormat}>{getText('text-color')}</Text>
              </View>
            </Form.Column>
            <Form.Column style={styles.brandColors}>
              <BrandingColorSquare
                color={methods.watch('branded_text_color')}
              />
            </Form.Column>
            <Form.Column style={styles.inputWrapper}>
              <TextField
                value={branded_colors.branded_text_color}
                style={styles.inputStyle}
                name="branded_text_color"
                type="text"
                rules={{
                  validate: (value) => {
                    return value.includes('#') &&
                      validate.isHexColor(value) &&
                      value.length === 7
                      ? true
                      : value.includes('#') &&
                        validate.isHexColor(value) &&
                        value.length !== 7
                      ? getText('invalid-hex-length-use-6-digits')
                      : getText('invalid-hex-value');
                  },
                }}
              />
            </Form.Column>
          </Form.Row>
        </Form>
      </Modal>
    </>
  );
};

export interface BrandingColorsProps {
  button_primary_color: string;
  branded_text_color: string;
}

const useStyles = makeStyles(() => ({
  inputWrapper: {
    display: 'flex',
    gap: 8,
    alignSelf: 'stretch',
    flex: 3,
  },
  inputStyle: {
    height: 44,
  },
  textWrapper: {
    flexDirection: 'column',
    flex: 1,
  },
  textView: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: 10,
    height: 44,
  },
  textFormat: {
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 24,
  },
  brandColors: {
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
}));
