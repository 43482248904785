import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import React, { FunctionComponent, useRef } from 'react';
import { View } from 'react-native';
import { useWizard } from 'react-use-wizard';
import { save } from '../../../../actions/onboarding-actions';
import { useOnboardingStore } from '../../../../store/onboarding-store';
import { OBContentHeader } from '../content-header/OBContentHeader';
import OBDivider from '../divider/OBDivider';
import { OBFileTextIcon } from '../icons/OBFileTextIcon';
import { OBAccountForm } from '../forms/OBAccountForm';
import { OBFormHandler } from '../forms';

export const OBSingleLocationAccount: FunctionComponent = () => {
  const styles = useStyles();
  const { data } = useOnboardingStore();
  const { handleStep } = useWizard();

  const obAAccountRef = useRef<OBFormHandler>(null);

  handleStep(async () => {
    await obAAccountRef.current?.handleSubmit();
    await save();
  });

  return (
    <View style={styles.root}>
      <OBContentHeader
        icon={OBFileTextIcon}
        title={getText('account-information')}
        description={getText('please-confirm-or-fill-in')}
      />

      <OBAccountForm
        location={data.locations[0]}
        branName={data.brand_name}
        corpName={data.corp_name}
        isSingleLocation
        ref={obAAccountRef}
      />
      <OBDivider />
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    alignContent: 'center',
  },
}));
