import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path } from 'react-native-svg';
import { MultiColorIconProps } from './types';

export const CalendarAltIcon: FunctionComponent<
  PropsWithChildren<MultiColorIconProps>
> = ({ color, accentColor, size, testID, strokeWidth }) => (
  <Svg
    width={size}
    height={size}
    fill="none"
    testID={testID}
    viewBox="0 0 64 72"
    strokeWidth={strokeWidth}
  >
    <Path
      fill={color}
      d="M40.783 15.583a2.469 2.469 0 0 0 2.47-2.47V2.495a2.47 2.47 0 0 0-4.94 0v4.211h-6.66l-.027 4.445h6.688v1.964a2.469 2.469 0 0 0 2.47 2.47ZM26.22 15.559a2.47 2.47 0 0 0 2.47-2.47V2.469a2.469 2.469 0 1 0-4.94 0v4.236h-6.675l-.022 4.445h6.697v1.939a2.47 2.47 0 0 0 2.47 2.47Z"
    />
    <Path
      fill={color}
      d="M9.172 6.705H4.497A4.204 4.204 0 0 0 .3 10.903v48.131c0 1.159.472 2.208 1.232 2.969.76.758 1.81 1.23 2.966 1.23h26.931a17.406 17.406 0 0 1-1.138-4.446H4.744V23.251h46.288v16.222a17.41 17.41 0 0 1 4.445 1.499V10.903a4.204 4.204 0 0 0-4.198-4.198h-5.063l-.022 4.445h4.838v9.138H4.744V11.15h4.428v1.964a2.469 2.469 0 1 0 4.94 0V2.494a2.47 2.47 0 0 0-4.94 0v4.211Z"
    />
    <Path
      fill={color}
      fillRule="evenodd"
      d="M47.698 41.377a15.264 15.264 0 0 0-10.827 4.485 15.264 15.264 0 0 0-4.484 10.826c0 4.228 1.713 8.056 4.484 10.827A15.264 15.264 0 0 0 47.698 72c4.228 0 8.056-1.714 10.827-4.485a15.264 15.264 0 0 0 4.485-10.827c0-4.227-1.714-8.055-4.485-10.826a15.264 15.264 0 0 0-10.827-4.485Zm-7.683 7.629a10.833 10.833 0 0 1 7.683-3.184c3 0 5.717 1.218 7.683 3.184a10.823 10.823 0 0 1 3.184 7.682c0 3.001-1.216 5.72-3.184 7.686a10.83 10.83 0 0 1-7.683 3.18c-3 0-5.717-1.214-7.683-3.18a10.839 10.839 0 0 1-3.183-7.685c0-3.001 1.217-5.718 3.183-7.683Z"
      clipRule="evenodd"
    />
    <Path
      fill={accentColor ? accentColor : color}
      d="M30.655 38.66H25.22c-.68 0-1.235.556-1.235 1.235v2.47c0 .679.556 1.234 1.235 1.234h5.435c.68 0 1.235-.555 1.235-1.234v-2.47c0-.68-.555-1.235-1.235-1.235ZM43.006 38.66h-5.43c-.68 0-1.236.556-1.236 1.235v2.47c0 .284.097.543.257.75a17.458 17.458 0 0 1 7.582-3.605 1.24 1.24 0 0 0-1.173-.85ZM18.31 47.563h-5.433c-.68 0-1.235.556-1.235 1.235v2.47c0 .679.556 1.234 1.235 1.234h5.433c.679 0 1.235-.555 1.235-1.235v-2.47c0-.678-.556-1.234-1.235-1.234ZM30.655 47.563H25.22c-.68 0-1.235.556-1.235 1.235v2.47c0 .679.556 1.234 1.235 1.234h5.448a17.504 17.504 0 0 1 1.222-3.405v-.3c0-.678-.555-1.234-1.235-1.234ZM18.191 38.66h-5.196c-.743 0-1.353.61-1.353 1.354v2.232c0 .746.61 1.353 1.353 1.353h5.196c.746 0 1.354-.607 1.354-1.353v-2.233c0-.743-.608-1.353-1.354-1.353ZM46.127 58.262a2.212 2.212 0 0 1-.647-1.697l-3.719-3.717a1.482 1.482 0 1 1 2.094-2.097l3.72 3.72c.61-.035 1.23.18 1.694.647a2.223 2.223 0 0 1-3.141 3.144ZM18.191 30.06h-5.196c-.743 0-1.353.61-1.353 1.354v2.233c0 .745.61 1.353 1.353 1.353h5.196c.746 0 1.354-.608 1.354-1.353v-2.233c0-.743-.608-1.353-1.354-1.353ZM30.5 30.06h-5.196c-.743 0-1.353.61-1.353 1.354v2.233c0 .745.61 1.353 1.353 1.353H30.5c.746 0 1.353-.608 1.353-1.353v-2.233c0-.743-.607-1.353-1.353-1.353ZM42.696 30.06H37.5c-.743 0-1.353.61-1.353 1.354v2.233c0 .745.61 1.353 1.353 1.353h5.196c.746 0 1.354-.608 1.354-1.353v-2.233c0-.743-.608-1.353-1.354-1.353Z"
    />
  </Svg>
);
