import create from 'zustand';
import { BulkMessagesState, StatusFilter } from 'assets/types/messageTypes';

export const useBulkMessagesStore = create<BulkMessagesState>(() => ({
  bulks: [],
  bulksTotalCount: 0,
  isNewBulkMessageModalOpen: false,
  filters: {
    status: StatusFilter.All,
    message_type: {
      secure: false,
      notSecure: false,
    },
  },
  bulkDetails: {
    show: false,
  },
}));
