export const pharmacySoftwares = [
  {
    value: 'TeleManagerXMLv2',
    label: 'PMS Simulator',
    form_fields: ['server_address', 'server_port'],
    overwrite_form_fields: [],
    fields: ['server_port'],
  },
  {
    value: 'LibertyV2',
    label: 'Liberty',
    form_fields: ['api_key', 'npi', 'is_subscribed_to_webhooks'],
    overwrite_form_fields: ['server_address', 'auth_user', 'auth_secret'],
    fields: [
      'api_key',
      'auth_user',
      'npi',
      'auth_secret',
      'is_subscribed_to_webhooks',
    ],
  },
  {
    value: 'TelemanagerComputerRx',
    label: 'ComputerRX',
    form_fields: ['server_address', 'server_port', 'is_datafeed_enabled'],
    overwrite_form_fields: [],
    fields: ['server_port', 'is_datafeed_enabled'],
  },
  {
    value: 'TelemanagerRx30',
    label: 'Rx30',
    form_fields: ['server_address', 'server_port', 'is_datafeed_enabled'],
    overwrite_form_fields: [],
    fields: ['server_port', 'is_datafeed_enabled'],
  },
  {
    value: 'BestRx',
    label: 'BestRx',
    form_fields: ['api_key', 'npi'],
    overwrite_form_fields: ['server_address', 'auth_user'],
    fields: ['api_key', 'npi'],
  },
];

export const defaultServerPort = '6025';
export const defaultServerAddress = '192.168.1.10';

export type PMSConfigForm = {
  integration_type: string;
  server_address: string;
  server_port: string;
  timeout: string;
  allowed_concurrency: string;
  auth_url: string;
  auth_user: string;
  auth_secret: string;
  multistore_enable: boolean;
  multistore_offset: string;
  multistore_length: string;
  npi: string;
  pharmacy_phone: string;
  token: string;
  api_host: string;
  subscription_key: string;
  api_key: string;
  is_subscribed_to_webhooks: boolean;
  is_datafeed_enabled: boolean;
  override_defaults: boolean;
  location_id: string;
  integration_location_id: string;
};

export const initialPMSConfigValue: PMSConfigForm = {
  integration_type: '',
  server_address: '',
  server_port: defaultServerPort,
  timeout: '',
  allowed_concurrency: '',
  auth_url: '',
  auth_user: '',
  auth_secret: '',
  multistore_enable: false,
  multistore_offset: '',
  multistore_length: '',
  npi: '',
  pharmacy_phone: '',
  token: '',
  api_host: '',
  subscription_key: '',
  api_key: '',
  is_subscribed_to_webhooks: false,
  is_datafeed_enabled: false,
  override_defaults: false,
  location_id: '',
  integration_location_id: '',
};
