import React, { FunctionComponent, useEffect } from 'react';
import { View } from 'react-native';
import { makeStyles, useTheme } from 'assets/theme';
import { DashboardScreenProps } from '../../navigation/RootNavigation';
import { UpcomingAppointmentsWidget } from './components/widgets/UpcomingAppointmentsWidget';
import { ActionsWidget } from './components/widgets/ActionsWidget';
import { RefillNotPassedWidget } from './components/widgets/RefillNotPassedWidget';
import { NewFormSubmissionsWidget } from './components/widgets/NewFormSubmissionsWidget';
import { MyTasksWidget } from './components/widgets/MyTasksWidget';
import { UnreadMessagesWidget } from './components/widgets/UnreadMessagesWidget';
import { PharmacyScreenContainer } from 'assets/layout';
import ChecklistCard from '../../components/checklist/checklist-card/checklist-card';
import { setOpenedModal } from '../../actions/checklist-actions';

export const Dashboard: FunctionComponent<DashboardScreenProps> = () => {
  const styles = useStyles();
  const theme = useTheme();

  return (
    <View style={{ height: '100%', flex: 1, overflow: 'scroll' }}>
      <PharmacyScreenContainer
        disableScrollView={false}
        style={styles.root}
        backgroundColor={theme.palette.gray[50]}
      >
        {/* left section */}
        <View style={styles.leftPanel}>
          <ActionsWidget />
          <UpcomingAppointmentsWidget />
          <ChecklistCard
            hierarchy="primary"
            onPress={() => setOpenedModal('checklist-setup')}
          />
        </View>

        {/* right section */}
        <View style={styles.rightPanel}>
          <RefillNotPassedWidget />
          <UnreadMessagesWidget />
          <NewFormSubmissionsWidget />
          <MyTasksWidget />
        </View>
      </PharmacyScreenContainer>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    gap: theme.getSpacing(2),
  },
  leftPanel: {
    width: 335,
    height: '100%',
    gap: theme.getSpacing(3),
  },
  rightPanel: {
    flex: 1,
    gap: theme.getSpacing(3),
  },
}));
