import { useEffect } from 'react';
import { useChatState } from '../screens/messages/stores/chat-store/chat-store';
import theme from 'assets/theme';
import { setCounts } from '../screens/messages/stores/chat-store/chat-actions';
import unifiedCommsService from '../api/UnifiedCommsService';
import { useAppStateStore } from '../store/app-store';
import { CountBadge } from 'assets/components/count-badge/CountBadge';
export const MessageCount = (): JSX.Element | null => {
  const { count, rawConversations, viewedConversationsSet } = useChatState();
  const { locationId, pharmacyId } = useAppStateStore();

  useEffect(() => {
    void (async () => {
      const unreadConversations =
        await unifiedCommsService.getNotViewedChatsCountByPharmacy(
          pharmacyId,
          locationId,
        );

      setCounts({ unread: unreadConversations.count });
    })();
  }, [viewedConversationsSet, rawConversations, pharmacyId, locationId]);

  return (
    <CountBadge
      count={count.unread!}
      backgroundColor="#FFC854"
      color={theme.palette.gray[700]}
    />
  );
};
