import { makeStyles, useTheme } from 'assets/theme';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  Platform,
  TouchableWithoutFeedback,
} from 'react-native';
import {
  IDocumentModalState,
  IImageModalState,
  Image as IImage,
  MessageStatus,
  RetryContent,
} from 'assets/types/messageTypes';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import ParsedText from 'react-native-parsed-text';
import {
  onUrlPress,
  onPhonePress,
  onEmailPress,
} from '../../../common/linking-utils';
import { PHONE_PATTERN } from '../data';
import {
  AttachmentDto,
  AuthorType,
} from '@digitalpharmacist/unified-communications-service-client-axios';
import { useChatState } from '../stores/chat-store/chat-store';
import {
  getAttachmentUrl,
  onHyperLinkPress,
  renderHyperlink,
  renderHyperlinkRegex,
} from 'assets/utils/messageUtils';
import FileStorageService from '../../../api/FileStorageService';
import { useAppStateStore } from '../../../store/app-store';
import { getText } from 'assets/localization/localization';
import { DocumentPickerAsset } from 'expo-document-picker';

type IBubbleProps = {
  args: any;
  loading: boolean;
  setDocumentModalState: (arg: IDocumentModalState) => void;
  setImageModalState: (arg: IImageModalState) => void;
  lastRightMessageId: string | number | undefined;
  forceUpdate: number;
  isSending: boolean;
  handleMessageSend: (retryContent: RetryContent) => Promise<void>;
};

const Bubble: FC<IBubbleProps> = ({
  args,
  loading,
  setImageModalState,
  setDocumentModalState,
  lastRightMessageId,
  forceUpdate,
  isSending,
  handleMessageSend,
}) => {
  const { locationId, pharmacyId } = useAppStateStore();
  const selectedConversation = useChatState(
    (state) => state.selectedConversation,
  );
  const rawConversations = useChatState((state) => state.rawConversations);
  const statusInConversation = useChatState(
    (state) => state.statusInConversation,
  );
  const [imagesUrls, setImagesUrls] = useState<Record<string, string>>({});
  const styles = useStyles();
  const theme = useTheme();

  if (!args.currentMessage || !args.user) {
    return null;
  }

  const images: (IImage | DocumentPickerAsset)[] = useMemo(() => {
    return args.currentMessage?.attachments?.images ?? [];
  }, [args.currentMessage?.attachments?.images]);

  const files: (AttachmentDto | DocumentPickerAsset)[] = useMemo(() => {
    return args.currentMessage?.attachments?.files ?? [];
  }, [args.currentMessage?.attachments?.files]);

  useEffect(() => {
    if (images.length) {
      const newImagesUrls = args.currentMessage.attachments.images.reduce(
        (accumulator: Record<string, string>, currentAttachment: IImage) => {
          return {
            ...accumulator,
            [currentAttachment.id]: currentAttachment.url,
          };
        },
        {},
      );

      setImagesUrls(newImagesUrls);
    }
  }, [images]);

  const refreshImagesUrls = useCallback(async () => {
    if (images && images.length) {
      const readyImagesWithUrl = await Promise.all(
        images
          .filter((image: IImage | DocumentPickerAsset) => {
            return !(image as DocumentPickerAsset).file; // get only images that came from api
          })
          .map((image: IImage | DocumentPickerAsset) =>
            getAttachmentUrl({
              attachment: image as IImage,
              FileStorageService: FileStorageService,
              pharmacyId: pharmacyId,
              locationId: locationId,
            }),
          ),
      );

      const newImagesUrls = readyImagesWithUrl.reduce(
        (accumulator: Record<string, string>, currentAttachment: IImage) => {
          return {
            ...accumulator,
            [currentAttachment.id]: currentAttachment.url,
          };
        },
        {},
      );

      setImagesUrls(newImagesUrls);
    }
  }, [pharmacyId, locationId, images, setImagesUrls, getAttachmentUrl]);

  useEffect(() => {
    refreshImagesUrls();
  }, [forceUpdate, refreshImagesUrls]);

  function getAuthorName() {
    const getText = (text: string) => {
      return (
        <Text style={[styles.messageAdditionalInfo, styles.marginBottom]}>
          {text}
        </Text>
      );
    };

    if (!args.previousMessage?.user?._id) {
      return getText(args.currentMessage.user.name);
    }
    if (!args.currentMessage?.user?._id || !args.currentMessage?.user?.name) {
      return <></>;
    }
    if (args.currentMessage.user._id !== args.previousMessage.user._id) {
      return getText(args.currentMessage.user.name);
    }

    return <></>;
  }

  const messageStatus = useMemo(() => {
    if (lastRightMessageId !== args.currentMessage._id) {
      return null;
    }

    const Error = () => (
      <View style={styles.error}>
        <Text style={styles.errorText}>{getText('failed-to-send')} </Text>
        <Text
          onPress={() =>
            handleMessageSend({
              text: args.currentMessage.text,
              id: args.currentMessage._id,
              attachments: [
                ...args.currentMessage?.attachments?.files,
                ...args.currentMessage?.attachments?.images,
              ],
            })
          }
          style={styles.retry}
        >
          {getText('retry')}
        </Text>
      </View>
    );

    const conversation = rawConversations.find(
      (item) => item.conversation_id === selectedConversation?.conversation_id,
    );

    const status = () => {
      const read = conversation?.patient_viewed_all_messages;

      if (
        statusInConversation &&
        conversation?.conversation_id &&
        statusInConversation[conversation.conversation_id] ===
          MessageStatus.Error
      ) {
        return <Error />;
      }

      if (isSending) {
        return getText('sending');
      } else if (read) {
        return getText('read');
      } else {
        return getText('sent');
      }
    };

    return <Text style={styles.messageAdditionalInfo}>{status()}</Text>;
  }, [
    lastRightMessageId,
    rawConversations,
    statusInConversation,
    args.currentMessage,
  ]);

  const isPharmacist =
    args.currentMessage.author_type === AuthorType.Pharmacy ||
    args.currentMessage.author_type === AuthorType.SystemGenerated;
  const attachmentsPresent = Boolean(images.length || files.length);

  if (loading) {
    return (
      <View style={{ flex: 1, marginBottom: 150, marginRight: 50 }}>
        <LoadingIndicator color={theme.palette.primary[700]} />
      </View>
    );
  }

  function showFile(attachment: AttachmentDto | DocumentPickerAsset) {
    // TODO: bad but fast way to solve. This kind of files is only for temporary shown of files that are sending at the moment
    // if attachment has `file` property, then it has type DocumentPickerAsset
    if ((attachment as DocumentPickerAsset).file) {
      return (
        <View
          style={styles.wrapper}
          key={attachment.name.replaceAll(/\s+/g, '-')}
        >
          <Text style={[styles.fileWrapper, isPharmacist && styles.white]}>
            {attachment.name}
          </Text>
        </View>
      );
    }

    // if attachment does not have `file` property, then it has type AttachmentDto
    const attachmentFile = attachment as AttachmentDto;
    return (
      <View style={styles.wrapper} key={attachmentFile.id}>
        <TouchableOpacity
          onPress={() =>
            setDocumentModalState({
              show: true,
              stored_filename: attachmentFile.stored_filename,
              name: attachment.name,
            })
          }
        >
          <Text style={[styles.fileWrapper, isPharmacist && styles.white]}>
            {attachment.name}
          </Text>
        </TouchableOpacity>
      </View>
    );
  }

  function showImage(attachment: IImage | DocumentPickerAsset) {
    // TODO: bad but fast way to solve. This kind of files is only for temporary shown of files that are sending at the moment
    // if attachment has `file` property, then it has type DocumentPickerAsset
    if ((attachment as DocumentPickerAsset).file) {
      return (
        <View
          style={styles.wrapper}
          key={attachment.name.replaceAll(/\s+/g, '-')}
        >
          <Image
            source={{ uri: (attachment as DocumentPickerAsset).uri }}
            style={styles.image}
          />
        </View>
      );
    }

    // if attachment does not have `file` property, then it has type IImage
    const attachmentImage = attachment as IImage;
    return (
      <View style={styles.wrapper} key={attachmentImage.id}>
        <TouchableOpacity
          onPress={async () => {
            const url = await getAttachmentUrl({
              attachment: attachment as IImage,
              FileStorageService: FileStorageService,
              pharmacyId: pharmacyId,
              locationId: locationId,
            });
            setImageModalState({
              show: true,
              stored_filename: attachmentImage.stored_filename,
              uri: url.url,
            });
          }}
        >
          <Image
            source={
              imagesUrls[attachmentImage.id]
                ? { uri: imagesUrls[attachmentImage.id] }
                : (attachmentImage.noImageUrl as any)
            }
            style={styles.image}
          />
        </TouchableOpacity>
      </View>
    );
  }

  return (
    <View style={[styles.bubble, isPharmacist && styles.pharmacistBubble]}>
      {getAuthorName()}
      <View
        style={[
          styles.bubbleContainer,
          isPharmacist
            ? {
                backgroundColor: theme.palette.primary[700],
                borderTopRightRadius: 0,
              }
            : { marginLeft: 0, marginRight: 60, borderTopLeftRadius: 0 },
        ]}
      >
        <View>
          <Text
            style={[
              styles.bubbleText,
              attachmentsPresent && { paddingBottom: theme.getSpacing(1) },
              isPharmacist && styles.white,
            ]}
            selectable
          >
            <ParsedText
              parse={[
                {
                  pattern: renderHyperlinkRegex,
                  style: { textDecorationLine: 'underline' },
                  onPress: onHyperLinkPress,
                  renderText: renderHyperlink,
                },
                {
                  type: 'url',
                  style: { textDecorationLine: 'underline' },
                  onPress: onUrlPress,
                },
                {
                  pattern: PHONE_PATTERN,
                  style: { textDecorationLine: 'underline' },
                  onPress: onPhonePress,
                },
                {
                  type: 'email',
                  style: { textDecorationLine: 'underline' },
                  onPress: onEmailPress,
                },
              ]}
            >
              {args.currentMessage.text}
            </ParsedText>
          </Text>
          {attachmentsPresent && (
            <View style={{ flexDirection: 'column' }}>
              {files.length > 0 && (
                <View style={{ flexDirection: 'column' }}>
                  {files.map(
                    (attachment: AttachmentDto | DocumentPickerAsset) =>
                      showFile(attachment),
                  )}
                </View>
              )}
              {images.length > 0 && (
                <View style={styles.imagesContainer}>
                  {images.map((attachment: IImage | DocumentPickerAsset) =>
                    showImage(attachment),
                  )}
                </View>
              )}
            </View>
          )}
        </View>
      </View>
      {messageStatus}
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  bubbleContainer: {
    marginLeft: 60,
    backgroundColor: theme.palette.gray[100],
    padding: theme.getSpacing(1),
    borderRadius: 8,
    maxWidth: '90%',
  },
  bubble: {
    flex: 1,
    alignItems: 'flex-start',
  },
  pharmacistBubble: { alignItems: 'flex-end' },
  bubbleText: {
    ...theme.fonts.regular,
    color: theme.palette.black,
    ...(Platform.OS === 'web' && {
      overflowWrap: 'break-word',
    }),
  },
  image: {
    height: 100,
    width: 100,
    zIndex: 1,
  },
  imagesContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  fileWrapper: {
    color: theme.palette.black,
    textDecorationLine: 'underline',
  },
  wrapper: {
    margin: theme.getSpacing(1),
  },
  messageAdditionalInfo: {
    ...theme.fonts.regular,
    color: theme.palette.gray[500],
    fontSize: 12,
  },
  marginBottom: {
    marginBottom: theme.getSpacing(0.5),
  },
  white: {
    color: theme.palette.white,
  },
  error: {
    flexDirection: 'row',
  },
  errorText: {
    color: theme.palette.error[500],
    fontSize: 11,
  },
  retry: {
    fontSize: 11,
    color: theme.palette.primary[500],
  },
}));

export default Bubble;
