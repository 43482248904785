import React, { FunctionComponent, PropsWithChildren } from 'react';
import { View, Text, ViewStyle, Dimensions } from 'react-native';
import { CloseIcon } from 'assets/icons';
import { IconButton } from 'assets/components/icon-button';
import { makeStyles, useTheme } from 'assets/theme';
import {
  CSSObject,
  Sidebar,
  sidebarClasses,
  useProSidebar,
} from 'react-pro-sidebar';
import type { IconSource } from 'react-native-paper/lib/typescript/components/Icon';
import { LoadingOverlay } from './LoadingOverlay';

interface PharmacySidebarProps {
  title: string;
  dataExists: boolean;
  children: React.ReactChild;
  defaultCollapsed?: boolean;
  rootStyle?: CSSObject;
  onCollapse?: () => void;
  containerStyles?: CSSObject;
  collapseIcon?: IconSource;
  backLinkComponent?: JSX.Element;
  parentContainerStyle?: ViewStyle;
}

export const PharmacySidebar: FunctionComponent<
  PropsWithChildren<PharmacySidebarProps>
> = ({
  title,
  dataExists,
  children,
  defaultCollapsed = false,
  rootStyle,
  onCollapse,
  containerStyles,
  collapseIcon,
  backLinkComponent,
  parentContainerStyle = {},
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const { collapseSidebar } = useProSidebar();

  function SidebarHeader() {
    return (
      <View style={styles.header}>
        <View style={{ flex: 1 }}>
          <Text style={styles.headerTitleText} selectable>
            {title}
          </Text>
        </View>
        {backLinkComponent}
        <View>
          <IconButton
            icon={collapseIcon || CloseIcon}
            size={24}
            color={theme.palette.gray[500]}
            logger={{
              id: 'task-details-sidebar--close',
            }}
            onPress={() => {
              collapseSidebar(true);

              onCollapse && onCollapse();
            }}
          />
        </View>
      </View>
    );
  }
  const height = Dimensions.get('window').height;
  return (
    <View style={[styles.parentContainer, parentContainerStyle]}>
      <Sidebar
        rootStyles={{
          [`.${sidebarClasses.container}`]: {
            display: 'flex',
            flexDirection: 'column',
            maxHeight: height * 0.85,
            backgroundColor: theme.palette.white,
            borderRightWidth: 0,
            borderLeftWidth: 1,
            borderLeftColor: theme.palette.gray[200],
            borderLeftStyle: 'solid',
            width: '100%',
            ...containerStyles,
          },
          maxHeight: height * 0.85,
          ...rootStyle,
        }}
        rtl={false}
        collapsedWidth="0"
        width="348px"
        defaultCollapsed={defaultCollapsed}
      >
        <View style={styles.detailsContainer}>
          {dataExists ? (
            <>
              <SidebarHeader />
              {children}
            </>
          ) : (
            <LoadingOverlay />
          )}
        </View>
      </Sidebar>
    </View>
  );
};

export const useStyles = makeStyles((theme) => ({
  rowView: {
    display: 'flex',
    flexDirection: 'row',
  },
  column: {
    flex: 1,
  },
  boldSubtext: {
    fontWeight: '600',
    paddingBottom: theme.getSpacing(0.5),
  },
  comments: {
    color: theme.palette.gray[600],
    backgroundColor: theme.palette.gray[50],
    padding: theme.getSpacing(1),
    borderRadius: 4,
    paddingBottom: theme.getSpacing(4),
  },
  subtext: {
    paddingBottom: theme.getSpacing(2),
    color: theme.palette.gray[700],
    fontFamily: 'Lato_400Regular',
  },
  container: {
    paddingBottom: theme.getSpacing(2),
  },
  sectionHeader: {
    paddingTop: theme.getSpacing(2),
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomColor: theme.palette.gray[200],
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    paddingBottom: theme.getSpacing(1),
    marginBottom: theme.getSpacing(1),
  },

  link: {
    color: theme.palette.primary[500],
    textDecorationLine: 'none',
  },
  detailsContainer: {
    height: '100%',
    paddingLeft: theme.getSpacing(3),
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: theme.getSpacing(1),
    marginBottom: theme.getSpacing(1),
    borderBottomColor: theme.palette.gray[300],
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
  },
  headerTitleText: {
    ...theme.lumistryFonts.text.small.regular,
    fontWeight: '500',
    color: theme.palette.grayCool[400],
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.getSpacing(1),
    marginBottom: theme.getSpacing(2),
    justifyContent: 'center',
    alignItems: 'center',
  },
  fieldLabel: {
    fontSize: 14,
    fontWeight: '600',
    lineHeight: 21,
    color: theme.palette.gray[900],
    minWidth: 100,
  },
  sectionHeaderLabel: {
    fontWeight: '600',
    fontSize: 12,
    textTransform: 'uppercase',
    color: theme.palette.gray[900],
  },
  parentContainer: {
    marginLeft: theme.getSpacing(3),
  },
}));
