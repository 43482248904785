import React from 'react';
import { FunctionComponent, PropsWithChildren } from 'react';
import { Tooltip as ReactTooltip, ITooltip } from 'react-tooltip';
import { Text } from 'assets/components/text';
import { makeStyles } from 'assets/theme';
import { TooltipPortal } from './tooltip-portal';
import { TooltipProps } from '../types';

export const Tooltip: FunctionComponent<TooltipProps> = ({
  text,
  variant = 'dark',
  offset = 15,
  ...props
}) => {
  const tooltipStyles = useTooltipStyles();
  const textStyles = useTextStyles();
  return (
    <TooltipPortal>
      <ReactTooltip
        float
        delayShow={500}
        place="top"
        offset={offset}
        style={{
          ...tooltipStyles.base,
          ...tooltipStyles[variant],

          textAlign: 'left',
        }}
        {...props}
      >
        <Text style={[textStyles.base, textStyles[variant]]}>{text}</Text>
      </ReactTooltip>
    </TooltipPortal>
  );
};

const useTooltipStyles = makeStyles((theme) => ({
  base: {
    borderRadius: theme.roundness,
    paddingTop: theme.getSpacing(1),
    paddingRight: theme.getSpacing(2),
    paddingBottom: theme.getSpacing(1),
    paddingLeft: theme.getSpacing(2),
    opacity: 1,
    maxWidth: 350,
  },
  dark: {
    backgroundColor: theme.palette.gray[900],
  },
  light: {
    backgroundColor: theme.palette.white,
    boxShadow:
      '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
  },
}));

const useTextStyles = makeStyles((theme) => ({
  base: {
    fontSize: 12,
  },
  dark: {
    color: theme.palette.white,
  },
  light: {
    color: theme.palette.gray[700],
  },
}));
