import {
  CreatePatientRecordDto,
  LocationPatientRecordDto,
  PatientRecordDto,
} from '@digitalpharmacist/patient-service-client-axios';
import patientServiceInstance from '../../api/PatientService';

export const initLocationPatientRecord = async (
  patientRecordId: string,
  locationId: string,
): Promise<PatientRecordDto | null> => {
  try {
    const result = await patientServiceInstance.initLocationPatientRecord(
      locationId,
      patientRecordId,
    );

    return result;
  } catch (err) {
    return null;
  }
};

export const addPatientRecord = async (
  patient: CreatePatientRecordDto,
): Promise<PatientRecordDto | null> => {
  return patientServiceInstance.addPatientRecord(patient);
};

export const verifyLocationPatientRecord = async (
  locationId: string,
  id: string,
): Promise<LocationPatientRecordDto> => {
  return patientServiceInstance.updateLocationPatientRecord(locationId, id, {
    is_verified: true,
  });
};
