import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IPatientForm, ModalType } from '../PatientFormModal/types';
import {
  updateLocationPatientRecord,
  updatePatientRecord,
} from './editPatientActions';
import usePatientStore from '../../screens/patients/patient-store';
import { PatientRecords } from '../../screens/patients/patient-types';
import {
  Gender,
  LocationPatientRecordDto,
  UpdateLocationPatientRecordDto,
  UpdatePatientRecordDto,
} from '@digitalpharmacist/patient-service-client-axios';
import PatientFormModal from '../PatientFormModal/PatientFormModal';
import { getText } from 'assets/localization/localization';
import states from 'assets/utils/states';
import { PREFERRED_LANGUAGE_VALUES } from '../PatientFormModal/languages';
import { formatPhoneNumber } from '../../../../../packages/assets/utils/phoneNumber';
import { formatDate } from '../../common/datetime-utils';
import { uploadFile } from '../PatientFormModal/filestoreActions';
import { useAppStateStore } from '../../store/app-store';

export interface EditPatientModalProps {
  locationPatientRecord: LocationPatientRecordDto;
  show: boolean;
  patientDetails: PatientRecords;
  setShowEditModal: (arg: boolean) => void;
  successCB?: (locationPatientRecord: LocationPatientRecordDto | null) => void;
}

enum Countries {
  Canada = 'CA',
  Us = 'US',
}

const EditPatientModal: FunctionComponent<EditPatientModalProps> = ({
  locationPatientRecord,
  show,
  patientDetails,
  setShowEditModal,
  successCB,
}) => {
  const {
    sidebarDetails,
    setSidebarDetails,
    setPatientRecord,
    updatedPatientCount,
    setUpdatedPatientCount,
  } = usePatientStore();
  const methods = useForm<IPatientForm>({ mode: 'onChange' });

  const [photo, setPhoto] = useState<File | undefined>();

  const { locationId, pharmacyId } = useAppStateStore();

  const resetDataInForm = useCallback(() => {
    const country: Countries.Canada | Countries.Us =
      patientDetails.address?.country == Countries.Canada
        ? Countries.Canada
        : Countries.Us;

    const state = states[country].find(
      (item) => item.value == patientDetails.address?.state,
    );

    const preferred_language = PREFERRED_LANGUAGE_VALUES.find(
      (item) => item.value == patientDetails.preferred_language,
    ) ?? { value: '', label: '' };

    methods.reset({
      first_name: patientDetails.first_name,
      last_name: patientDetails.last_name,
      street1: patientDetails.address?.address1,
      street2: patientDetails.address?.address2,
      state,
      email: patientDetails.email,
      gender: patientDetails.gender,
      postal_code: patientDetails.address?.postal_code,
      city: patientDetails.address?.city,
      country,
      date_of_birth: formatDate(patientDetails.date_of_birth),
      phone: formatPhoneNumber(patientDetails.cell_phone),

      preferred_language,
      no_allergies:
        !patientDetails.allergies || patientDetails.allergies.length === 0,
      allergies:
        patientDetails.allergies && patientDetails.allergies.length > 0
          ? patientDetails.allergies.join(',')
          : undefined,
      conditions:
        patientDetails.medical_conditions &&
        patientDetails.medical_conditions.length > 0
          ? patientDetails.medical_conditions.join(',')
          : undefined,
      prefers_med_sync: patientDetails.prefers_med_sync ?? undefined,
      prefers_easy_open_bottle_caps:
        patientDetails.prefers_easy_open_bottle_caps ?? undefined,
      notify_by_sms: patientDetails.notify_by_sms ?? undefined,
      notify_by_voice: patientDetails.notify_by_voice ?? undefined,
      notify_by_email: patientDetails.notify_by_email ?? undefined,
      photo_id_url: patientDetails.photo_id_url ?? undefined,
    });
  }, [patientDetails]);

  useEffect(() => {
    resetDataInForm();
  }, [resetDataInForm]);

  async function onSubmit(data: IPatientForm) {
    const isValid = validateEditPatientFrom(data);
    if (!isValid) {
      return;
    }

    const locationPatientRecordToUpdate = populateLocationPatientRecord(data);

    const updatedLocationPatientRecord = await updateLocationPatientRecord(
      locationPatientRecord.location_id,
      locationPatientRecord.id,
      locationPatientRecordToUpdate,
    );

    if (updatedLocationPatientRecord) {
      if (updatedLocationPatientRecord.patient_record_id) {
        let fileName: string | undefined;
        if (photo?.name) {
          fileName = await uploadFile(locationId, pharmacyId, photo);
        }

        const patientRecordToUpdate = populatePatientRecord({
          locationPatientRecordToUpdate,
          data,
          fileName,
        });

        await updatePatientRecordAndState(
          updatedLocationPatientRecord.patient_record_id,
          patientRecordToUpdate,
        );
      }

      updateLocationPatientInState(updatedLocationPatientRecord);
    }

    setShowEditModal(false);

    if (successCB) {
      successCB(updatedLocationPatientRecord);
    }
  }

  function populateLocationPatientRecord(
    data: IPatientForm,
  ): UpdateLocationPatientRecordDto {
    return {
      first_name: data.first_name.trim(),
      last_name: data.last_name.trim(),
      cell_phone: data.phone,
      date_of_birth: formatDate(data.date_of_birth, 'YYYY-MM-DD'),
      gender:
        data.gender === Gender.Male
          ? Gender.Male
          : data.gender === Gender.Female
          ? Gender.Female
          : undefined,
      email: data.email?.trim(),
      address: data.street1
        ? {
            address1: data.street1.trim(),
            address2: data.street2?.trim() ? data.street2 : undefined,
            city: data.city?.trim(),
            state: data.state ? data.state.value : undefined,
            postal_code: data.postal_code,
            country: data.country,
          }
        : undefined,
    };
  }

  interface PopulatePatientArgs {
    locationPatientRecordToUpdate: UpdateLocationPatientRecordDto;
    data: IPatientForm;
    fileName?: string;
  }

  function populatePatientRecord(
    args: PopulatePatientArgs,
  ): UpdatePatientRecordDto {
    const { data, locationPatientRecordToUpdate, fileName } = args;
    return {
      ...locationPatientRecordToUpdate,
      preferred_language: data.preferred_language
        ? data.preferred_language.value
        : undefined,
      allergies: data.allergies?.length ? data.allergies.split(',') : [],
      medical_conditions: data.conditions ? data.conditions.split(',') : [],
      prefers_med_sync: data.prefers_med_sync,
      prefers_easy_open_bottle_caps: data.prefers_easy_open_bottle_caps,
      notify_by_sms: data.notify_by_sms,
      notify_by_voice: data.notify_by_voice,
      notify_by_email: data.notify_by_email,
      photo_id_url: fileName,
    };
  }

  type FormDataKey = keyof IPatientForm;

  function validateEditPatientFrom(data: IPatientForm) {
    const patient: PatientRecords = {
      ...patientDetails,
    };

    const formKeys = Object.keys(data);
    for (const key of formKeys) {
      const formDataKey = key as FormDataKey;

      const isErased =
        data[formDataKey] != patient[key as keyof PatientRecords] &&
        data[formDataKey] == '';

      const hasAllergies = !data.no_allergies;

      if (typeof data[formDataKey] == 'string' && isErased) {
        if (key == 'allergies' && hasAllergies) {
          continue;
        }

        const isSure = confirm(`Are you sure you want to erase ${key}?`);

        return isSure;
      }
    }

    return true;
  }

  async function updatePatientRecordAndState(
    id: string,
    data: UpdatePatientRecordDto,
  ) {
    const updatedPatientRecord = await updatePatientRecord(id, data);

    if (updatedPatientRecord) {
      setPatientRecord(updatedPatientRecord);
    }
  }

  function updateLocationPatientInState(
    updatedLocationPatientRecord: LocationPatientRecordDto,
  ) {
    setSidebarDetails({
      ...sidebarDetails,
      locationPatientRecord: updatedLocationPatientRecord,
    });

    setUpdatedPatientCount(updatedPatientCount + 1);
  }

  return (
    <PatientFormModal
      title={getText('edit-patient')}
      methods={methods}
      show={show}
      setShowPatientFormModal={setShowEditModal}
      onSubmit={onSubmit}
      modalType={ModalType.EDIT}
      resetDataInForm={resetDataInForm}
      setPhoto={setPhoto}
    />
  );
};

export default EditPatientModal;
