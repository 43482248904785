import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path, Defs, ClipPath, G } from 'react-native-svg';
import { IconProps } from './types';

export const FacebookIcon: FunctionComponent<PropsWithChildren<IconProps>> = ({
  color,
  size,
  testID,
  strokeWidth = 0,
}) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 25 24"
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      color={color}
      testID={testID}
    >
      <Path
        d="M24.5 12C24.5 5.37258 19.1274 0 12.5 0C5.87258 0 0.5 5.37258 0.5 12C0.5 17.9895 4.8882 22.954 10.625 23.8542V15.4688H7.57812V12H10.625V9.35625C10.625 6.34875 12.4166 4.6875 15.1576 4.6875C16.4701 4.6875 17.8438 4.92188 17.8438 4.92188V7.875H16.3306C14.84 7.875 14.375 8.80008 14.375 9.75V12H17.7031L17.1711 15.4688H14.375V23.8542C20.1118 22.954 24.5 17.9895 24.5 12Z"
        fill={color}
      />
    </Svg>
  );
};
