import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path } from 'react-native-svg';
import { MultiColorIconProps } from './types';

export const PatientEduInteractionsIcon: FunctionComponent<
  PropsWithChildren<MultiColorIconProps>
> = ({ color, accentColor, size, testID }) => {
  return (
    <Svg
      width={size}
      height={size}
      testID={testID}
      viewBox="0 0 36 51"
      fill="none"
    >
      <Path
        d="M28.3938 12.5002C27.8562 13.35 27.3154 14.1971 26.7714 15.0418C26.0258 14.5628 25.1538 14.2946 24.2466 14.2946C23.7698 14.2946 23.297 14.369 22.8386 14.5156C20.5482 15.2459 19.1978 17.4299 19.5538 19.8274C19.7522 21.1611 20.561 22.298 21.689 22.9987C20.9586 24.1331 20.229 25.2627 19.4946 26.3874C19.213 26.8187 18.8146 26.869 18.385 26.5931C16.5714 25.4331 14.7562 24.2746 12.9498 23.1018C12.4426 22.7746 12.4026 22.4196 12.7626 21.8596C15.085 18.2315 17.4114 14.6074 19.7346 10.9836C20.3482 10.0259 20.953 9.06115 21.5746 8.10995C21.9642 7.51307 22.2922 7.45083 22.8954 7.83555C24.6242 8.93803 26.3522 10.0452 28.0786 11.154C28.7258 11.5684 28.8026 11.858 28.3938 12.5002Z"
        fill={accentColor}
      />
      <Path
        d="M23.7554 6.58274C24.5282 5.39962 25.3482 4.14739 26.165 2.89243C26.5082 2.36587 26.8378 1.83082 27.1866 1.30738C27.5626 0.745222 27.8522 0.659622 28.4026 1.00426C29.6234 1.76738 30.829 2.55394 32.0394 3.33394C32.4978 3.62898 32.5834 4.02586 32.2938 4.48114C31.1418 6.29306 29.9834 8.10178 28.8442 9.88586C27.1514 8.78738 25.4946 7.71146 23.7554 6.58274Z"
        fill={accentColor}
      />
      <Path
        d="M12.344 24.4617C14.0688 25.5651 15.7208 26.621 17.448 27.7257C16.876 28.5923 16.2952 29.4913 15.692 30.3754C15.4352 30.7513 15.0552 30.8179 14.676 30.581C13.4056 29.7851 12.1408 28.9785 10.8864 28.1569C10.4912 27.8979 10.4024 27.5169 10.664 27.101C11.2248 26.2107 11.7936 25.3257 12.344 24.4617Z"
        fill={accentColor}
      />
      <Path
        d="M24.3009 21.0227C23.1401 21.0402 22.2361 20.198 22.2177 19.0818C22.1913 17.929 23.0153 17.0524 24.1585 17.0259C25.3289 16.9987 26.1537 17.7874 26.1801 18.9755C26.2065 20.0836 25.3561 21.0052 24.3009 21.0227ZM32.3225 48.0299C31.5689 47.3652 30.7537 46.7715 29.8937 46.2396C29.4065 45.938 28.8921 45.6627 28.3777 45.4059C28.2185 45.3259 28.0585 45.2467 27.8905 45.1667C28.9097 44.2811 29.9737 43.4924 30.8153 42.5171C35.2473 37.4115 36.3729 31.6499 33.9617 25.2683C32.8449 22.3346 31.0017 19.9508 28.4577 18.098C28.2009 17.9115 27.9793 17.6108 27.8377 17.318C26.9777 15.5267 24.9481 14.6236 23.0601 15.2171C21.1185 15.8371 19.9841 17.6811 20.2857 19.7202C20.5697 21.6171 22.3241 23.0171 24.3537 23.0083C24.7177 22.9996 25.2049 23.0083 25.4441 23.2211C28.5105 25.9683 29.9201 29.3724 29.0521 33.4667C27.8201 39.2987 22.6257 42.7562 16.7489 41.7724C15.0561 41.4796 13.5233 40.7883 12.2201 39.5211C12.7601 39.5211 13.2921 39.5387 13.8329 39.5124C14.9321 39.4762 15.5793 38.8739 15.5793 37.9171C15.5705 36.9155 14.9057 36.3124 13.7801 36.3124C12.1753 36.3036 10.5713 36.3036 8.96729 36.3036C6.76889 36.3036 4.5705 36.2939 2.3729 36.3124C1.3713 36.3211 0.600098 37.0483 0.600098 37.9339C0.600098 38.829 1.2737 39.4596 2.3105 39.5124C2.8513 39.5387 3.4009 39.4859 3.9321 39.5474C4.1809 39.5739 4.49129 39.7515 4.61529 39.9467C5.78489 41.9139 7.3361 43.509 9.19769 44.8124C9.31289 44.9011 9.4281 44.9811 9.5609 45.0874C9.3657 45.1755 9.17049 45.2731 8.98489 45.3618C8.18729 45.7602 7.4249 46.2036 6.6889 46.7002C6.3345 46.9483 5.98009 47.2059 5.64329 47.4715C5.45689 47.6227 5.26169 47.7996 5.06729 47.9674C4.92489 48.0924 4.73049 48.1899 4.70329 48.3843C4.67689 48.6243 4.71209 48.898 4.70329 49.1467C4.70329 49.6693 4.69449 50.1924 4.69449 50.7252H32.7657V48.4915C32.7569 48.4731 32.7569 48.4555 32.7481 48.4467C32.6505 48.2693 32.4729 48.1715 32.3225 48.0299Z"
        fill={color}
      />
    </Svg>
  );
};
