import { Modal } from 'assets/components/modal';
import { Text } from 'assets/components/text';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import React, { FunctionComponent, useState } from 'react';
import {
  setOpenedModal,
  submitPatientWebsite,
} from '../../../../actions/checklist-actions';
import { useChecklistStore } from '../../../../store/checklist-store';
import { ChecklistWebsiteModalProps } from '../../types';
import { logError } from 'assets/logging/logger';
import { FinalizeWebsiteWizardDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { getAccessButtonText } from '../../utils';

export const WebsiteAccessCompletedModal: FunctionComponent<
  ChecklistWebsiteModalProps
> = ({ id, data }) => {
  const { currentOpenedModalId } = useChecklistStore();
  const [loading, setLoading] = useState<boolean>(false);
  const service = data.website_required;
  const styles = useStyles();

  const handleSubmit = async () => {
    try {
      setLoading(true);
      switch (service) {
        case 'build-do-not-have':
        case 'build-to-replace':
          setOpenedModal('patient-website-complete');
          break;
        case 'do-not-build-already-have':
        case 'do-not-build-do-not-need':
          await submitPatientWebsite(data as FinalizeWebsiteWizardDto);
          setOpenedModal('checklist-setup');
          break;
        default:
          break;
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={getText('website-domain')}
      show={currentOpenedModalId === id}
      handleDismissModal={() => setOpenedModal('checklist-setup')}
      showDismissButton
      isScrollable
      size="sm"
      buttons={[
        {
          hierarchy: 'primary',
          text: getAccessButtonText(service),
          onPress: handleSubmit,
          logger: { id: 'next-website-access-completed' },
          loading,
        },
        {
          text: getText('back'),
          hierarchy: 'tertiary-gray',
          onPress: () => setOpenedModal('website-access-setup'),
          logger: { id: 'back-website-access-completed-modal' },
        },
      ]}
    >
      <Text style={styles.title}>
        {getText('website-completion-gratitude')}
      </Text>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.gray[700],
  },
}));
