import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Svg, Path } from 'react-native-svg';
import { IconProps } from './types';

export const TwitterXIcon: FunctionComponent<PropsWithChildren<IconProps>> = ({
  color,
  size,
  testID,
}) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 25 21"
      fill="none"
      color={color}
      testID={testID}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.49079 11.1721L0.5 0H7.62135L13.1713 6.9051L19.1005 0.0310902H23.0226L15.0676 9.26448L24.5 21H17.3999L11.3905 13.5326L4.97476 20.9793H1.03144L9.49079 11.1721ZM18.4348 18.93L4.86657 2.06999H6.58565L20.1368 18.93H18.4348Z"
        fill={color}
      />
    </Svg>
  );
};
