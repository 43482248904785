import { Avatar } from 'assets/components/avatar';
import { Icon } from 'assets/components/icon';
import { IconButton } from 'assets/components/icon-button';
import { Tooltip } from 'assets/components/tooltip/components/tooltip';
import { EmailIcon, PencilIcon, TrashWithStripesIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import React, { useEffect, useState } from 'react';
import { Pressable, Text, View } from 'react-native';
import usersService from '../../../../../mobile/modules/api/users-service';
import { PharmacyTooltipWrapper } from '../../../common/PharmacyTooltipWrapper';
import { handleError } from '../../../common/errors/commonErrorHandler';
import { canFactory } from '../../../common/guards/permission-guard';
import { Actions } from '../../../common/guards/permission-types';
import { useToast } from '../../../common/hooks/useToast';
import { PharmacySidebar } from '../../../components/PharmacySidebar';
import { resendInvite } from './UserActions';
import { formatRoles, getHighestRole } from './UserHelpers';
import useViewSidebarStore from './view-user-sidebar-store';

const TextEntry = ({ label, value }: { label: string; value: string }) => {
  const styles = useStyles();
  return (
    <View>
      <Text style={styles.labelText}>{label}</Text>
      <Text style={styles.valueText}>{value}</Text>
    </View>
  );
};

export default function UserDetailSidebar() {
  const { toast } = useToast();
  const theme = useTheme();
  const styles = useStyles();
  const { userDetails, setShowConfirmDeleteModal, setShowUserEdit } =
    useViewSidebarStore();

  if (!userDetails) {
    throw new Error('userDetails is undefined');
  }

  const [showResend, setShowResend] = useState(false);

  const setShowResendValue = async () => {
    const allUserDetails = await usersService
      .getPharmacistUser(userDetails.userId)
      .catch((e) => {
        throw handleError({
          userMessage: getText('contact-support'),
          internalError: 'could not load user details',
          showToast: true,
          error: e,
        });
      });
    const { emailVerified } = allUserDetails;
    return !emailVerified;
  };

  useEffect(() => {
    setShowResend(false);
    setShowResendValue()
      .then((showResend) => setShowResend(showResend))
      .catch(() => setShowResend(false));
  }, [userDetails]);

  const resendInviteEmail = () => {
    resendInvite(userDetails.email)
      .catch((e) => {
        throw handleError({
          userMessage: getText('error-sending-invite'),
          showToast: true,
          rethrow: true,
          error: e,
        });
      })
      .then(() => {
        toast(getText('resend-invite'), {
          type: 'success',
          content: `${getText('pharmacy-reset-link-sent')} ${
            userDetails.email
          }`,
        });
      });
  };

  const highestRoleOfSubject = getHighestRole(
    userDetails.user_roles.map((x) => x.role),
  );
  const guardAction: Actions = `manage:role:${highestRoleOfSubject}`;
  const canManageUser = canFactory(guardAction)('pharmacyUserRoles');

  return (
    <PharmacySidebar title={getText('user-details')} dataExists={!!userDetails}>
      <View>
        <View style={styles.buttonsRow}>
          {canManageUser && (
            <View style={styles.buttonsRow}>
              <IconButton
                onPress={() => setShowConfirmDeleteModal(true)}
                icon={TrashWithStripesIcon}
                logger={{ id: 'delete-user' }}
                color={theme.palette.gray[500]}
                size={15}
              />
              <Pressable onPress={() => setShowUserEdit(true)}>
                <View style={styles.row}>
                  <Icon
                    icon={PencilIcon}
                    color={theme.palette.gray[500]}
                    size={18}
                  />
                  <View style={{ marginLeft: theme.getSpacing(0.5) }}>
                    <Text style={styles.editText}>{getText('edit')}</Text>
                  </View>
                </View>
              </Pressable>
            </View>
          )}
          {showResend && (
            <Pressable id="resend-invite">
              <PharmacyTooltipWrapper tooltipId="resend-invite-tooltip">
                <IconButton
                  onPress={resendInviteEmail}
                  icon={EmailIcon}
                  logger={{ id: 'bookmark-user' }}
                  color={theme.palette.gray[500]}
                  size={15}
                />
              </PharmacyTooltipWrapper>
              <Tooltip
                place="top"
                id="resend-invite-tooltip"
                text={getText('resend-invite')}
              />
            </Pressable>
          )}
        </View>
        <View style={[styles.row, styles.paddingTop3]}>
          <Text
            style={styles.userName}
          >{`${userDetails.firstName} ${userDetails.lastName}`}</Text>
          <Avatar
            firstName={userDetails.firstName}
            lastName={userDetails.lastName}
            size={40}
          />
        </View>
        {/* These properties never existed and do not function as intended. See https://rxwiki.atlassian.net/browse/BLUES-3180 */}
        {/* <TextEntry label={getText('title')} value={userDetails.title} /> */}
        <TextEntry label={getText('email-text')} value={userDetails.email} />
        <TextEntry
          label={getText('role')}
          value={formatRoles(userDetails.user_roles)}
        />

        <View style={[styles.row, styles.activityHeader]}>
          <Text style={styles.activityLabelText}>
            {getText('activity').toUpperCase()}
          </Text>
        </View>
      </View>
    </PharmacySidebar>
  );
}

const useStyles = makeStyles((theme) => ({
  row: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  buttonsRow: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  labelText: {
    fontSize: 16,
    fontWeight: '600',
    color: theme.palette.gray[900],
    lineHeight: 18,
    paddingTop: theme.getSpacing(2),
    paddingBottom: theme.getSpacing(1),
  },
  userName: {
    fontSize: 24,
    color: theme.palette.gray[900],
    fontWeight: '600',
  },
  valueText: {
    fontWeight: '400',
    color: theme.palette.gray[700],
  },
  activityHeader: {
    paddingTop: theme.getSpacing(3),
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.gray[300],
    borderBottomStyle: 'solid',
    marginBottom: theme.getSpacing(2),
  },
  activityLabelText: {
    fontSize: 12,
    fontWeight: '600',
    color: theme.palette.gray[900],
  },
  showButtonText: {
    color: theme.palette.primary[600],
    paddingRight: theme.getSpacing(1),
    fontSize: 13,
    fontWeight: '400',
  },
  editText: {
    color: theme.palette.gray[500],
    ...theme.lumistryFonts.label.small,
  },
  paddingTop3: {
    paddingTop: theme.getSpacing(3),
  },
  justifyContentEnd: {
    justifyContent: 'flex-end',
  },
}));
