import create from 'zustand';

export const initialState: INewChatModalState = {
  templatesFilter: '',
  showEmojis: false,
};

export const useNewChatModalState = create<INewChatModalState>(
  () => initialState,
);

interface INewChatModalState {
  templatesFilter: string;
  showEmojis: boolean;
}
