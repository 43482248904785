import { FC } from 'react';
import { View } from 'react-native';
import { makeStyles, useTheme } from 'assets/theme';
import { IRendererProps } from '../types';
import { useBulkTableStyles } from '../commonStyles';

export const SenderRenderer: FC<IRendererProps> = ({ data }) => {
  const styles = useStyles();
  const bulkTableStyles = useBulkTableStyles();
  const theme = useTheme();

  const { author = { _id: '', name: '', avatar: () => <></> } } = data;

  return (
    <View style={styles.cellContainer}>
      <View
        style={{
          justifyContent: 'center',
          marginRight: theme.getSpacing(1),
        }}
      >
        {typeof author.avatar === 'function' ? author.avatar(null) : <></>}
      </View>
      <div style={bulkTableStyles.text}>{author.name}</div>
    </View>
  );
};

const useStyles = makeStyles(() => ({
  cellContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },
}));
