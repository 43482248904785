import { ReactElement, useState } from 'react';
import { allowedActions } from './allowed-actions';
import { Actions, Resources } from './permission-types';
import { useAppStateStore } from '../../store/app-store';

type GuardProps = {
  children: FixedLengthArray<[ReactElement, ReactElement]> | ReactElement;
  resource: Resources;
  action?: Actions;
};

/**
 * First child of read guard is the guarded react element
 * Optional second child is the element that is displayed
 * when the user does not have access to the guarded element.
 *
 * If no second element is passed, then null is returned.
 *
 * Access is determined by comparing the logged in users role,
 * with a permissions set and an action. The passed in action is
 * optional, if no action is passed then it will check for read access
 */
export function ResourceGuard(props: GuardProps): ReactElement | null {
  const { children, resource, action } = props;
  const guarded = Array.isArray(children) ? children[0] : children;
  const altView = Array.isArray(children) ? children[1] : null;
  const canDoThis = canFactory(action || 'read');
  const [access, setAccess] = useState(canDoThis(resource));

  // Now watch for changes
  canDoThis(resource, setAccess);

  if (access) {
    return guarded;
  }

  return altView;
}

/**
 * Factory Pattern Permissions Functions
 *
 * Example usages:
 * 1. use to create new Arbitrator functions
 * `export const canRead: Arbitrator = canFactory('read');`
 *
 * OR
 * 2. use inline to create and use the arbitrator as an anonymous fn
 * canFactory('makePublic')('form', setAllowed);
 * in the format: canFactory(<action>)(<resource>, <StateChangeHandler>): boolean
 *
 * Full usage examples:
 *
 *
 * <Button ... disabled={!createAllowed}>Create Form</Button>
 *
 * END
 *
 *
 * Example # 2
 * Use to hide a component without an alternate view
 *
 * <Forms>
 *  .....
 * <ResourceGuard resource='form' action='create' >
 *   <Text>Im something that should not be shows without create permissions in form</Text>
 * </ResourceGuard>
 *  ....
 * </Forms>
 *
 * * Example # 3
 * Use to hide a component and include an alternate view
 *
 * <Forms>
 *  .....
 * <ResourceGuard resource='form' action='create' >
 *   <Text>Im something that should not be shows without create permissions in form</Text>
 *   <Text>Contact your it admin for access to create a form</Text>
 * </ResourceGuard>
 *  ....
 * </Forms>
 *
 */
/**
 *
 * @param action
 * @returns a function that takes a resource (string) and a subscriber function
 */
export function canFactory(action: Actions): Arbitrator {
  return (
    resource: Resources,
    subscriber?: React.Dispatch<React.SetStateAction<boolean>>,
  ): boolean => {
    let { availableRoles } = useAppStateStore.getState();
    let actions;
    let decision;
    actions = allowedActions(resource, availableRoles);
    decision = actions.includes(action);

    if (subscriber && typeof subscriber === 'function') {
      // Watch changes on available roles
      useAppStateStore.subscribe((p) => {
        if (p.availableRoles !== availableRoles) {
          availableRoles = p.availableRoles;
          actions = allowedActions(resource, availableRoles); // already checked
          decision = actions.includes(action);
          subscriber(decision);
        }
      });
    }

    return decision;
  };
}

type Arbitrator = (
  resource: Resources,
  subscriber?: React.Dispatch<React.SetStateAction<boolean>>,
) => boolean;

/**
 * These are intended to be utility functions for the most common actions.
 *
 * example usage:
 * <Button disabled={!canCreate('form')}>Create Form</Button>
 *
 * possible, but un-preferred usage: canDelete('form') && <Button>DELETE FORM</BUTTON>
 * instead use
 * <ResourceGuard resource="form" action="delete">
 *   <Button>DELETE FORM</BUTTON>
 * </ResourceGuard>
 */
export const canRead: Arbitrator = canFactory('read');
export const canUpdate: Arbitrator = canFactory('update');
export const canCreate: Arbitrator = canFactory('create');
export const canDelete: Arbitrator = canFactory('delete');
