import { getText } from 'assets/localization/localization';
import { useBillingOptionsSettingsState as billingState } from './billing-settings-store';
import { logError } from 'assets/logging/logger';
import UnifiedCommsService from '../../../api/UnifiedCommsService';
import { formatDate } from '../../../common/datetime-utils';
import { NewsletterResponseDto } from '@digitalpharmacist/unified-communications-service-client-axios';
import { useToast } from '../../../common/hooks/useToast';

export const setGeneralSettings = (locationId: string) => {
  UnifiedCommsService.getEmailRecordFindCurrentMonthUsage(locationId).then(
    (generalSettings) => {
      billingState.setState({
        generalSettings: [
          //    { name: 'History', value: 'View' }, //commented this because it will be used in a later moment
          {
            name: 'End of billing cycle',
            value: formatDate(generalSettings.end_date),
          },
        ],
      });
    },
  );
};

const calculatePercentage = (usedValues: number, maxValue: number) => {
  const percentage = (usedValues / maxValue) * 100;
  return Math.floor(percentage);
};
const setLoading = (loading: boolean) => {
  billingState.setState({ loading: loading });
};

export const loadEmailUsage = async (locationId: string) => {
  try {
    setLoading(true);
    const emailUsage =
      await UnifiedCommsService.getEmailRecordFindCurrentMonthUsage(locationId);

    let emailPercentage = calculatePercentage(
      emailUsage.email_quantity,
      emailUsage.email_quantity_cap,
    );
    const emailUsageData = {
      quantity: emailUsage.email_quantity,
      quantity_cap: emailUsage.email_quantity_cap,
      start_date: emailUsage.start_date,
      end_date: emailUsage.end_date,
      usedTitle: getText('outbound-email-used'),
      capTitle: getText('outbound-email-cap'),
      percentage: emailPercentage,
    };
    billingState.setState({ emailUsageData: emailUsageData });
    setLoading(false);
    return emailUsageData;
  } catch (error: any) {
    logError(error);
    return null;
  }
};

export const loadSmsUsage = async (locationId: string) => {
  try {
    setLoading(true);
    const smsUsage =
      await UnifiedCommsService.getSmsRecordFindCurrentMonthUsage(locationId);
    let smsPercentage = calculatePercentage(
      smsUsage.sms_quantity,
      smsUsage.sms_quantity_cap,
    );
    const smsUsageData = {
      quantity: smsUsage.sms_quantity,
      quantity_cap: smsUsage.sms_quantity_cap,
      start_date: smsUsage.start_date,
      end_date: smsUsage.end_date,
      usedTitle: getText('outbound-sms-used'),
      capTitle: getText('outbound-sms-cap'),
      percentage: smsPercentage,
    };
    billingState.setState({ smsUsageData: smsUsageData });
    setLoading(false);
    return smsUsageData;
  } catch (error: any) {
    logError(error);
    return null;
  }
};

export const loadBillingData = async (locationId: string) => {
  try {
    const [emailUsageData, smsUsageData] = await Promise.all([
      loadEmailUsage(locationId),
      loadSmsUsage(locationId),
    ]);
    billingState.setState({
      billingPlans: [smsUsageData, emailUsageData],
    });
  } catch (error: any) {
    logError(error);
  }
};

export const subscribeToPaymentAndDelivery = async (
  pharmacyId: string,
  locationId: string,
  userId: string,
) => {
  const { toast } = useToast();

  void UnifiedCommsService.getUserSubscriptionForPaymentsAndDeliveryCard(
    pharmacyId,
    locationId,
    userId,
  ).then((response: NewsletterResponseDto) => {
    if (!response.already_subscribed) {
      toast(getText('email-was-registered-successfully'), {
        type: 'success',
      });
    } else {
      toast(getText('email-is-already-registered'), {
        type: 'success',
      });
    }
  });
};
