import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { makeStyles } from 'assets/theme';
import { Text } from 'assets/components/text';
import { Avatar } from 'assets/components/avatar';
import { DirectMessagePatientDto } from '@digitalpharmacist/unified-communications-service-client-axios';
import {
  formatMessageDate,
  renderHyperlink,
  renderHyperlinkRegex,
} from 'assets/utils/messageUtils';
import ParsedText from 'react-native-parsed-text';

export const PatientConversation: FunctionComponent<
  PatientConversationProps
> = ({ patient, isSelected = false, isViewed, avatarColor }) => {
  const {
    most_recent_qualifying_message_date,
    most_recent_qualifying_message,
    first_name,
    last_name,
  } = patient;
  const styles = useStyles();
  const lastMessageDate = most_recent_qualifying_message_date
    ? formatMessageDate(most_recent_qualifying_message_date)
    : '';

  return (
    <View style={[styles.container, isSelected && styles.selected]}>
      <View style={styles.avatar}>
        <Avatar
          size={32}
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          firstName={first_name?.location ?? 'no_first_name_location'} // The data is from ElasticSearch and could be in a bad state if repopulation were not run
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          lastName={last_name?.location ?? 'no_last_name_location'} // The data is from ElasticSearch and could be in a bad state if repopulation were not run
          style={{ backgroundColor: avatarColor }}
        />
      </View>
      <View style={styles.text}>
        <View style={styles.nameWrapper}>
          {!isViewed ? <View style={styles.unreadCircle}></View> : <></>}
          <Text
            style={[styles.name, !isViewed && styles.unread && styles.bold]}
          >
            {/** The data is from ElasticSearch and could be in a bad state if repopulation were not run */}

            {
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              `${first_name?.location ?? 'no_first_name_location'} ${
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                last_name?.location ?? 'no_last_name_location'
              }`
            }
          </Text>
        </View>
        <Text style={[styles.preview, !isViewed && styles.unread]}>
          <ParsedText
            parse={[
              { pattern: renderHyperlinkRegex, renderText: renderHyperlink },
            ]}
          >
            {most_recent_qualifying_message}
          </ParsedText>
        </Text>
      </View>
      <Text style={styles.date}>{lastMessageDate}</Text>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'row',
    paddingVertical: theme.getSpacing(1.5),
    paddingHorizontal: theme.getSpacing(0.5),
  },
  selected: {
    backgroundColor: theme.palette.gray[100],
  },
  avatar: {
    borderWidth: 2,
    borderColor: theme.palette.white,
    borderRadius: 16,
    flexBasis: 36,
  },
  text: {
    marginLeft: theme.getSpacing(1),
    marginRight: theme.getSpacing(1),
    flex: 100,
    justifyContent: 'center',
  },
  name: {
    color: theme.palette.gray[600],
    textOverflow: 'ellipsis',
    fontSize: 13,
  },
  nameWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  unreadCircle: {
    width: 8,
    height: 8,
    borderRadius: 8 / 2,
    backgroundColor: theme.palette.primary[600],
    marginRight: theme.getSpacing(1),
  },
  unread: {
    color: theme.palette.black,
  },
  bold: {
    color: theme.palette.black,
    fontWeight: '700',
  },
  preview: {
    color: theme.palette.gray[500],
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: 13,
  },
  date: {
    alignSelf: 'center',
    color: theme.palette.gray[600],
    flex: 1,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

interface PatientConversationProps {
  patient: DirectMessagePatientDto;
  isViewed: boolean;
  isSelected?: boolean;
  avatarColor?: string;
}
