import React, {
  FunctionComponent,
  PropsWithChildren,
  ReactElement,
} from 'react';
import { View } from 'react-native';

import { Text } from 'assets/components/text';
import theme, { makeStyles } from 'assets/theme';
import { PharmacyScreenContainer } from 'assets/layout';

const SettingsPageLayout: FunctionComponent<
  PropsWithChildren<SettingsPageLayoutProps>
> = ({ title, headerComponent, children }) => {
  const styles = useStyles();
  return (
    <PharmacyScreenContainer
      screenHeader={
        <View style={styles.viewTitle}>
          <Text style={styles.title} selectable>
            {title}
          </Text>
          <View
            style={{
              justifyContent: 'flex-end',
              flexDirection: 'row',
              flex: 1,
              alignItems: 'flex-end',
            }}
          >
            {headerComponent}
          </View>
        </View>
      }
      disableScrollView={true}
    >
      <View
        style={{
          flex: 1,
        }}
      >
        {children}
      </View>
    </PharmacyScreenContainer>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.lumistryFonts.label.xxLarge.semiBold,
    color: theme.palette.gray[900],
  },
  viewTitle: {
    paddingVertical: theme.getSpacing(2),
    marginHorizontal: theme.getSpacing(3),
    marginBottom: theme.getSpacing(1),
    backgroundColor: theme.palette.white,
    borderBottomColor: theme.palette.gray[600],
    borderBottomWidth: 1,
    width: '96%',
  },
}));

interface SettingsPageLayoutProps {
  title: string;
  headerComponent?: ReactElement;
}

export default SettingsPageLayout;
