import React, { FunctionComponent, PropsWithChildren } from 'react';
import {
  DimensionValue,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';
import theme, { makeStyles, useTheme } from 'assets/theme';

export const ContentPlaceholder: FunctionComponent<
  PropsWithChildren<ContentPlaceholderProps>
> = ({ height, width, style }) => {
  const styles = useStyles();
  return (
    <View
      style={[
        { width: width ? width : '100%', height: height },
        styles.root,
        style && style,
      ]}
    ></View>
  );
};

export interface ContentPlaceholderProps {
  height: number;
  width?: DimensionValue;
  style?: StyleProp<ViewStyle>;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.gray[200],
    maxWidth: '100%',
  },
}));
