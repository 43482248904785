import { getText } from 'assets/localization/localization';
import { useStyles } from './styles';
import { View, Text } from 'react-native';
import { AddressDto } from '@digitalpharmacist/patient-service-client-axios';

export const Address = ({ dto }: { dto: Partial<AddressDto> | undefined }) => {
  const styles = useStyles();

  if (!dto) {
    return null;
  }

  const dtoCopy = { ...dto };
  delete dtoCopy.id;
  const addressStrings = Object.values(dtoCopy)
    .filter((addressPoint) => Boolean(addressPoint))
    .join(', ');

  return (
    <View style={[styles.infoRow]}>
      <Text style={[styles.infoRowLabel]}>{getText('address')}</Text>
      <Text style={[styles.infoRowValue]}>{addressStrings}</Text>
    </View>
  );
};
