import { PharmacistLocationDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Icon } from 'assets/components/icon';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { ArrowLeftIcon, PencilIcon, PlusInCircleIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import { FunctionComponent, useEffect } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { changeLocation } from '../../../actions/app-actions';
import PharmacistLocationService from '../../../api/PharmacistLocationService';
import { canFactory } from '../../../common/guards/permission-guard';
import { RowItemRender } from '../../../components/Settings/RowItemRender';
import SettingsPageLayout from '../../../components/Settings/SettingsPageLayout';
import SettingsSection from '../../../components/Settings/SettingsSection';
import { useAppStateStore } from '../../../store/app-store';
import { useUserState } from '../../../store/user-store';
import {
  LocationsDrawerNavigationProp,
  LocationsDrawerRouteProp,
} from './LocationsSettingsDrawer';
import { LocationAdditionalClosuresItemRenderer } from './components/LocationAdditionalClosuresItemRenderer';
import { LocationAdditionalClosuresModal } from './components/LocationAdditionalClosuresModal';
import { LocationContactInfoItemRenderer } from './components/LocationContactInfoItemRenderer';
import { LocationContactInfoModal } from './components/LocationContactInfoModal';
import { LocationGeneralInfoItemRenderer } from './components/LocationGeneralInfoItemRenderer';
import { LocationGeneralInfoModal } from './components/LocationGeneralInfoModal';
import { LocationHolidayClosuresItemRenderer } from './components/LocationHolidayClosuresItemRenderer';
import { LocationHolidayClosuresModal } from './components/LocationHolidayClosuresModal';
import { LocationInfoCardItemRenderer } from './components/LocationInfoCardItemRenderer';
import { LocationInfoCardModal } from './components/LocationInfoCardModal';
import { LocationNormalHoursItemRenderer } from './components/LocationNormalHoursItemRenderer';
import { LocationNormalHoursModal } from './components/LocationNormalHoursModal';
import {
  findAllTimezones,
  getAllPMS,
  getDepartments,
  infoCardsFindAll,
  setLocation,
  setLocationClosures,
  setLocationHolidays,
  setLocationId,
  setLocationNormalHours,
  setShowModalLocationAdditionalClosures,
  setShowModalLocationContactInfo,
  setShowModalLocationGeneralInfo,
  setShowModalLocationHolidayClosures,
  setShowModalLocationInfoCards,
  setStatus,
} from './location-settings-actions';
import { useLocationSettingsState } from './location-settings-store';

export const LocationSettings: FunctionComponent = () => {
  const route = useRoute<LocationsDrawerRouteProp>();
  const navLocationId = route.params?.locationId;
  const navigation = useNavigation<LocationsDrawerNavigationProp>();
  const styles = useStyles();
  const theme = useTheme();
  const { isMultiLocation } = useAppStateStore((state) => ({
    isMultiLocation: state.userMostRecentLocations.length > 1,
  }));
  const canLumistryUserUpdateSettings = canFactory('update')(
    'settingsLumistryUser',
  );
  const { pharmacyId, locationId } = useAppStateStore();

  const {
    location,
    allLocations,
    locationNormalHours,
    status,
    locationHolidays,
    locationClosures,
    infoCards,
  } = useLocationSettingsState();

  const changeContextLocation = async (selectedLocationId: string) => {
    const { data: user } = useUserState.getState();
    if (user && user.id) {
      const response =
        await PharmacistLocationService.pharmacistLocationsFindAll(user.id);
      const location: PharmacistLocationDto | undefined =
        response.locations.find(
          (location) => location.location_id === selectedLocationId,
        );
      location && (await changeLocation(location));
    }
  };

  const setData = async (locationId: string) => {
    setLocationId(locationId);
    await Promise.all([
      setLocation(locationId),
      setLocationNormalHours(locationId),
      setLocationHolidays(locationId),
      setLocationClosures(locationId),
      infoCardsFindAll(locationId),
      findAllTimezones(),
      canLumistryUserUpdateSettings && getAllPMS(),
      getDepartments(pharmacyId),
    ]);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!isMultiLocation) {
        if (navLocationId) {
          await setData(navLocationId);
        }
      } else {
        if (navLocationId && allLocations.locations.length > 0) {
          await Promise.all([
            location?.id !== navLocationId && setData(navLocationId),
            navLocationId !== locationId &&
              changeContextLocation(navLocationId),
          ]);
        } else {
          navigation.navigate('locations');
        }
      }
    };

    if (navLocationId) {
      setStatus('loading');
      fetchData().finally(() => setStatus('success'));
    }
  }, [navLocationId, allLocations.locations, route]);

  useEffect(() => {
    navigation.navigate('location', { locationId });
  }, [pharmacyId, locationId]);

  return (
    <>
      <View style={styles.backButton}>
        {isMultiLocation && (
          <TouchableOpacity
            style={styles.topBarButton}
            onPress={() => {
              setStatus('success');
              navigation.navigate('locations');
            }}
          >
            <Icon
              icon={ArrowLeftIcon}
              color={theme.palette.gray[700]}
              size={16}
            />
            <Text style={styles.topBarText}>{getText('locations-all')}</Text>
          </TouchableOpacity>
        )}
      </View>
      {status === 'success' && (
        <SettingsPageLayout title={location?.name || ''}>
          <SettingsSection
            title={getText('locations-pharmacy-info')}
            contentHeader={{
              title: getText('details'),
              button: {
                title: getText('edit'),
                icon: PencilIcon,
                onPress: () => setShowModalLocationGeneralInfo(true),
              },
            }}
            contentData={[location]}
            contentItem={LocationGeneralInfoItemRenderer}
            customTypeModal={LocationGeneralInfoModal}
          />
          <SettingsSection
            title={getText('locations-contact-info')}
            contentHeader={{
              title: getText('details'),
              button: {
                title: getText('edit'),
                icon: PencilIcon,
                onPress: () => setShowModalLocationContactInfo(true),
              },
            }}
            contentData={[location]}
            contentItem={LocationContactInfoItemRenderer}
            customTypeModal={LocationContactInfoModal}
          />
          <SettingsSection
            title={getText('info-cards')}
            contentHeader={{
              title: getText('details'),
              button: {
                title: getText('new'),
                icon: PlusInCircleIcon,
                onPress: () => setShowModalLocationInfoCards(true),
              },
            }}
            contentData={infoCards}
            contentItem={LocationInfoCardItemRenderer}
            customTypeModal={LocationInfoCardModal}
          />
          <SettingsSection
            title={getText('locations-normal-hours')}
            contentHeader={{ title: getText('details') }}
            contentData={locationNormalHours}
            contentItem={LocationNormalHoursItemRenderer}
            customTypeModal={LocationNormalHoursModal}
          />
          <SettingsSection
            title={getText('locations-holiday-closures')}
            subtitle={getText('locations-holiday-closures-description')}
            customContentHeaderComponent={() => (
              <RowItemRender
                isHeader={true}
                title={getText('name')}
                value={getText('when')}
                button={{
                  title: getText('edit'),
                  icon: PlusInCircleIcon,
                  onPress: () => setShowModalLocationHolidayClosures(true),
                }}
              />
            )}
            contentData={locationHolidays.holidays.filter(
              (holiday) => holiday.enabled,
            )}
            contentItem={LocationHolidayClosuresItemRenderer}
            customTypeModal={LocationHolidayClosuresModal}
          />
          <SettingsSection
            title={getText('locations-additional-closures')}
            subtitle={getText('locations-additional-closures-description')}
            customContentHeaderComponent={() => (
              <RowItemRender
                isHeader={true}
                title={getText('name')}
                value={getText('when')}
                button={{
                  title: getText('edit'),
                  icon: PlusInCircleIcon,
                  onPress: () => setShowModalLocationAdditionalClosures(true),
                }}
              />
            )}
            contentData={locationClosures.location.concat(
              locationClosures.pharmacy,
            )}
            contentItem={LocationAdditionalClosuresItemRenderer}
            customTypeModal={LocationAdditionalClosuresModal}
          />
        </SettingsPageLayout>
      )}
      {status === 'loading' && (
        <View style={{ marginTop: '25%' }}>
          <LoadingIndicator />
        </View>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  topBarButton: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    paddingLeft: theme.getSpacing(3),
    paddingRight: theme.getSpacing(3),
    marginRight: theme.getSpacing(3),
  },
  backButton: {
    zIndex: 5,
    marginVertical: theme.getSpacing(2),
  },
  topBarText: {
    color: theme.palette.gray[700],
    fontWeight: '400',
    fontSize: 16,
    lineHeight: 26,
    marginLeft: theme.getSpacing(1),
  },
}));
