import React, { FunctionComponent, PropsWithChildren, useEffect } from 'react';
import * as validate from '@digitalpharmacist/validation-dp';
import { useForm } from 'assets/form';
import { Modal } from 'assets/components/modal';
import { makeStyles } from 'assets/theme';
import { useMessageSettingsState } from '../message-settings-store';
import { Form } from 'assets/layout/form/Form';
import { TextField } from 'assets/components/text-field';
import { getText } from 'assets/localization/localization';
import { prettyFormat } from '@digitalpharmacist/validation-dp';
import {
  modifyMessageSettings,
  updateShowMessageSettingsNonMarketingPhoneState,
} from '../message-settings-actions';
import { useAppStateStore } from '../../../../store/app-store';
import { ModifyDirectMessageSettingsDto } from '@digitalpharmacist/unified-communications-service-client-axios';

export type MessageSettingsForm = ModifyDirectMessageSettingsDto;

export const MessageSettingsNonMarketingPhoneModal: FunctionComponent<
  PropsWithChildren<MessageSettingsNonMarketingPhoneModalProps>
> = () => {
  const styles = useStyles();
  const { messageSettingsConfig, showMessageSettingsNonMarketingPhoneModal } =
    useMessageSettingsState();
  const { pharmacyId, locationId } = useAppStateStore();

  const methods = useForm<MessageSettingsForm>({
    mode: 'onChange',
  });

  useEffect(() => {
    if (showMessageSettingsNonMarketingPhoneModal == true) {
      resetForm();
    }
  }, [messageSettingsConfig, showMessageSettingsNonMarketingPhoneModal]);

  const resetForm = () => {
    methods.reset({
      non_marketing_phone: prettyFormat(
        messageSettingsConfig.non_marketing_phone || '',
      ),
    });
  };

  const handleSubmit = async () => {
    await modifyMessageSettings(pharmacyId, locationId, {
      ...messageSettingsConfig,
      non_marketing_phone: methods.getValues().non_marketing_phone,
    });
    closeModal();
  };

  const closeModal = () => {
    updateShowMessageSettingsNonMarketingPhoneState(false);
  };

  return (
    <>
      <Modal
        title={`${getText('edit')} ${getText('non-marketing-messaging-phone')}`}
        titleSize="sm"
        dismissButtonProps={{
          onPress: closeModal,
          logger: { id: 'after-hours-form-cancel-button-modal' },
        }}
        cancelButtonProps={{
          onPress: closeModal,
          hierarchy: 'tertiary-gray',
          logger: { id: 'after-hours-form-cancel-button-modal' },
        }}
        okButtonProps={{
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onPress: handleSubmit,
          logger: { id: 'after-hours-form-ok-button-modal' },
          hierarchy: 'primary',
          text: getText('ok'),
        }}
        show={showMessageSettingsNonMarketingPhoneModal}
      >
        <Form methods={methods}>
          <Form.Row>
            <Form.Column style={styles.inputWrapper}>
              <TextField
                name="non_marketing_phone"
                type="telephoneNumber2"
                label={getText('non-marketing-messaging-phone')}
                rules={{
                  required: getText('phone-is-not-valid'),
                  validate: {
                    value: (value: string) => {
                      return !value ||
                        validate.isPhoneNumber(value ? '+1' + value : '')
                        ? true
                        : getText('phone-is-not-valid');
                    },
                  },
                }}
              />
            </Form.Column>
          </Form.Row>
        </Form>
      </Modal>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  inputWrapper: {
    flexDirection: 'column',
    flexGrow: 1,
    gap: 10,
  },
  container: {
    flexDirection: 'column',
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '100%',
    width: '50%',
  },
  enable: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  enableText: {
    marginLeft: theme.getSpacing(2),
  },
}));

interface MessageSettingsNonMarketingPhoneModalProps {}

export default MessageSettingsNonMarketingPhoneModal;
