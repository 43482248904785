import { LocationCategory } from '@digitalpharmacist/file-storage-service-client-axios';
import fileStorageService from '../../api/FileStorageService';
import { v4 } from 'uuid';

export const uploadFile = async (
  locationId: string,
  pharmacyId: string,
  file: File,
): Promise<string | undefined> => {
  try {
    const newName = v4();
    const extension = fileStorageService.getFileExtension(file.name);
    const fileName = `${newName}.${extension}`;

    const responseWriteUrl = await fileStorageService.writeUrl(
      LocationCategory.Patients,
      locationId,
      fileName,
      pharmacyId,
    );
    await fileStorageService.uploadFile(file, responseWriteUrl.data.url);

    return fileName;
  } catch (err) {
    //TODO: review console.error
    console.error('Error uploading a file', err);
  }
};
