import { usePMSIntegrationsState } from './pms-integration-settings-store';
import { logError } from 'assets/logging/logger';
import { useToast } from '../../../common/hooks/useToast';
import PMSIntegrationService from '../../../api/PMSIntegrationService';
import { convertEmptyStringsToNull } from '../../../common/form-utils';
import { getText } from 'assets/localization/localization';
import { PMSConfigForm } from '../../../common/integrations-utils';

const errorOccurred = (error: any, errorMessage?: string) => {
  const { toast } = useToast();
  const message = errorMessage
    ? errorMessage
    : getText('error-occurred-integration-load-save');

  logError(error);
  usePMSIntegrationsState.setState({
    error: {
      message: errorMessage
        ? errorMessage
        : getText('error-occurred-integration-load-save'),
    },
    status: 'error',
  });

  toast('Error', { type: 'error', content: message });
};

export const updateShowPMSModalState = (newState: boolean) => {
  usePMSIntegrationsState.setState((prevState) => ({
    ...prevState,
    showPMSFormModal: newState,
  }));
};

export const createOrUpdatePMSConfig = async (
  pharmacyId: string,
  locationID: string,
  pmsConfigData: PMSConfigForm,
): Promise<void> => {
  const { toast } = useToast();
  usePMSIntegrationsState.setState({ error: undefined, status: 'loading' });

  convertEmptyStringsToNull(pmsConfigData);

  try {
    const { pmsConfigurationExists } = usePMSIntegrationsState.getState();
    if (pmsConfigurationExists) {
      const response = await PMSIntegrationService.updatePMSConfig(
        pharmacyId,
        locationID,
        pmsConfigData,
      );

      usePMSIntegrationsState.setState((prevState) => ({
        ...prevState,
        status: 'success',
        pmsConfig: response.data,
      }));
    } else {
      const response = await PMSIntegrationService.createPMSConfig(
        pharmacyId,
        locationID,
        pmsConfigData,
      );

      usePMSIntegrationsState.setState((prevState) => ({
        ...prevState,
        status: 'success',
        pmsConfig: response.data,
        pmsConfigurationExists: true,
      }));
    }
    toast(getText('integration-saved'), { type: 'success' });
  } catch (error: any) {
    errorOccurred(error, getText('error-occurred-integration-save'));
    return Promise.reject(error);
  }
};

export const getPMSConfig = async (locationID: string) => {
  usePMSIntegrationsState.setState({ error: undefined, status: 'loading' });

  await PMSIntegrationService.findPMSConfig(locationID)
  .then((response) => {
    usePMSIntegrationsState.setState((prevState) => ({
      ...prevState,
      pmsConfigurationExists: true,
      status: 'success',
      pmsConfig: response.data,
    }));
  })
  .catch((error) => {
    usePMSIntegrationsState.setState((prevState) => ({
      ...prevState,
      pmsConfigurationExists: false,
      status: 'success',
      pmsConfig: {},
    }));

    if (!(error.response && error.response.data && error.response.data.statusCode === 404)) {
      errorOccurred(error, getText('error-occurred-integration-load'));
    }
    return Promise.reject(error);
  });
};

export const renewSubscriptionKeys = async (
  pharmacyId: string,
  locationID: string,
) => {
  const { toast } = useToast();
  usePMSIntegrationsState.setState({ error: undefined, status: 'loading' });

  try {
    const response = await PMSIntegrationService.renewSubscriptionKeys(
      pharmacyId,
      locationID,
    );
    usePMSIntegrationsState.setState((prevState) => ({
      ...prevState,
      status: 'success',
      pmsConfig: response.data,
    }));
    toast(getText('internal-webhook-keys-renewed'), { type: 'success' });
  } catch (error: any) {
    errorOccurred(error, getText('error-occurred-internal-webhook-keys-renew'));
    return Promise.reject(error);
  }
};
