import PatientFormModal from '../PatientFormModal/PatientFormModal';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IPatientForm } from '../PatientFormModal/types';
import {
  CreateAddressDto,
  CreatePatientRecordDto,
  Gender,
  PatientRecordDto,
} from '@digitalpharmacist/patient-service-client-axios';
import {
  initLocationPatientRecord,
  addPatientRecord,
  verifyLocationPatientRecord,
} from './addPatientModalActions';
import { formatDate } from '../../common/datetime-utils';
import usePatientStore from '../../screens/patients/patient-store';
import { uploadFile } from '../PatientFormModal/filestoreActions';
import axios from 'axios';

interface AddPatientModalProps {
  show: boolean;
  setShowAddPatientModal: (arg: boolean) => void;
  locationId: string;
  pharmacyId: string;
  successCB?: (patientRecord: PatientRecordDto) => void;
}

const AddPatientModal: FC<AddPatientModalProps> = ({
  show,
  setShowAddPatientModal,
  locationId,
  pharmacyId,
  successCB,
}) => {
  const methods = useForm<IPatientForm>();
  const [error, setError] = useState<string>('');
  const { createdPatientCount, setCreatedPatientCount } = usePatientStore();
  const [photo, setPhoto] = useState<File | undefined>();

  useEffect(() => {
    if (show) {
      methods.reset();
      setError('');
    }
  }, [methods, show]);

  const onSubmit = async (data: IPatientForm) => {
    try {
      let fileName;
      if (photo?.name) {
        fileName = await uploadFile(locationId, pharmacyId, photo);
        data.photo_id_url = fileName;
      }

      const patientRecord = populatePatientRecord(data);
      const createdPatientRecord = await addPatientRecord(patientRecord);

      if (createdPatientRecord) {
        const createdPatientRecordFromInit = await initLocationPatientRecord(
          createdPatientRecord.id,
          locationId,
        );

        if (!createdPatientRecordFromInit) {
          alert(
            'The patient record was created and the location patient record was not',
          );
        } else {
          const [minLocationPatientRecord] =
            createdPatientRecordFromInit.location_patient_records;

          await verifyLocationPatientRecord(
            locationId,
            minLocationPatientRecord.id,
          );

          setCreatedPatientCount(createdPatientCount + 1);
        }

        setShowAddPatientModal(false);

        if (successCB) {
          const patientRecord = {
            ...createdPatientRecord,
            location_patient_records:
              createdPatientRecordFromInit?.location_patient_records || [],
          };
          successCB(patientRecord);
        }
      } else {
        alert('Failed to create patient records');
      }
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status === 409) {
        setError('This patient already exists');
      } else {
        setError('Something went wrong!');
      }
    }
  };

  function populatePatientRecord(data: IPatientForm): CreatePatientRecordDto {
    return {
      pharmacy_id: pharmacyId,
      first_name: data.first_name.trim(),
      last_name: data.last_name.trim(),
      cell_phone: data.phone,
      date_of_birth: formatDate(data.date_of_birth, 'YYYY-MM-DD'),
      gender:
        Gender.Male == data.gender
          ? Gender.Male
          : Gender.Female == data.gender
          ? Gender.Female
          : undefined,
      email: data.email?.trim(),
      address: getAddress(data),
      preferred_language: data.preferred_language
        ? data.preferred_language.value
        : undefined,
      allergies: data.allergies?.length ? data.allergies.split(',') : [],
      medical_conditions: data.conditions ? data.conditions.split(',') : [],
      prefers_med_sync: data.prefers_med_sync,
      prefers_easy_open_bottle_caps: data.prefers_easy_open_bottle_caps,
      notify_by_sms: data.notify_by_sms,
      notify_by_voice: data.notify_by_voice,
      notify_by_email: data.notify_by_email,
      photo_id_url: data.photo_id_url,
    };
  }

  function getAddress(data: IPatientForm): CreateAddressDto | undefined {
    const isAllAddressFieldsFilled =
      data.street1 &&
      data.city &&
      data.state?.value &&
      data.postal_code &&
      data.country;

    if (isAllAddressFieldsFilled) {
      return {
        address1: data.street1?.trim() ?? '',
        address2: data.street2?.trim() ? data.street2 : undefined,
        city: data.city?.trim() ?? '',
        state: data.state?.value ?? '',
        postal_code: data.postal_code ?? '',
        country: data.country ?? '',
      };
    }
  }

  return (
    <PatientFormModal
      title="Add patient"
      methods={methods}
      show={show}
      setShowPatientFormModal={setShowAddPatientModal}
      onSubmit={onSubmit}
      error={error}
      setPhoto={setPhoto}
    />
  );
};

export default AddPatientModal;
