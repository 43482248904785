import React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import { Text } from 'assets/components/text';
import { GroupBase } from 'react-select';
import { makeStyles, useTheme } from 'assets/theme';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { AdvancedDropDown, AdvancedDropDownProps } from './advanced-drop-down';
import { DropdownSelectFieldTestIDs } from './advanced-drop-down-test-ids';
import { Icon } from 'assets/components/icon';
import { AlertTriangleIcon } from 'assets/icons';
import { getNestedObjectValue } from 'assets/utils/common';

export interface AdvancedDropDownFieldProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> extends AdvancedDropDownProps<Option, IsMulti, Group> {
  name: string;
  rules?: RegisterOptions;
  label?: string;
  hideRequiredAsterisk?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
}

export const AdvancedDropDownField = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  name,
  rules,
  containerStyle,
  label,
  hideRequiredAsterisk,
  ...dropDownProps
}: AdvancedDropDownFieldProps<Option, IsMulti, Group>) => {
  const styles = useStyles();
  const theme = useTheme();
  const formContext = useFormContext();
  if (!formContext) {
    throw new Error('DropdownSelect must have a parent form context');
  }

  const { control, formState } = formContext;
  const error = getNestedObjectValue(formState.errors, name);

  return (
    <View style={containerStyle}>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { onChange: onChangeField, value } }) => (
          <AdvancedDropDown
            {...dropDownProps}
            value={value}
            onChange={(event, actionMeta) => {
              onChangeField(event);
              dropDownProps.onChange &&
                dropDownProps.onChange(event, actionMeta);
            }}
            label={
              label && rules?.required && !hideRequiredAsterisk
                ? `${label} *`
                : label
            }
          />
        )}
      />

      {rules && error && (
        <View style={styles.container}>
          <View style={styles.icon}>
            <Icon
              icon={AlertTriangleIcon}
              color={theme.palette.error[600]}
              size={16}
            />
          </View>
          <Text
            testID={DropdownSelectFieldTestIDs.error}
            style={styles.errorMessage}
            selectable
          >
            {error.message}
          </Text>
        </View>
      )}
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.getSpacing(0.5),
  },
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.getSpacing(1),
    marginBottom: theme.getSpacing(1),
  },
  errorMessage: {
    color: theme.palette.error[600],
    fontSize: 14,
  },
}));
