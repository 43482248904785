import { getText } from 'assets/localization/localization';
import { FunctionComponent, useEffect } from 'react';
import { RowItemRender } from '../../../components/Settings/RowItemRender';
import SettingsPageLayout from '../../../components/Settings/SettingsPageLayout';
import SettingsSection from '../../../components/Settings/SettingsSection';
import { useUserState } from '../../../store/user-store';
import { InformationItemRenderer } from './components/InformationItemRenderer';
import { RoleItemRenderer } from './components/RoleItemRenderer';
import { getRolesByUserId } from './profile-settings-action';
import { useProfileSettingsState } from './profile-settings-store';

export const ProfileSettings: FunctionComponent = () => {
  const data = useUserState((x) => x.data);
  const { userRoles } = useProfileSettingsState();

  useEffect(() => {
    if (data?.id) {
      getRolesByUserId(data.id);
    }
  }, [data]);

  return (
    <SettingsPageLayout title={getText('profile-settings')}>
      <SettingsSection
        title={getText('information')}
        contentHeader={{ title: getText('details') }}
        contentData={[data]}
        contentItem={InformationItemRenderer}
      />
      <SettingsSection
        title={getText('roles')}
        customContentHeaderComponent={RoleHeader}
        contentData={userRoles}
        contentItem={RoleItemRenderer}
      />
    </SettingsPageLayout>
  );
};

const RoleHeader: FunctionComponent = () => {
  return (
    <RowItemRender
      isHeader={true}
      title={getText('location')}
      value={getText('role')}
    />
  );
};
