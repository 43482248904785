import React, { PropsWithChildren } from 'react';
import { TooltipProvider as ReactTooltipProvider } from 'react-tooltip';
import { TooltipConfig, TooltipProviderProps } from '../types';
import { TooltipContext } from '../context';
import { DefaultTooltipConfig } from '../config';

export const TooltipProvider: React.FunctionComponent<
  PropsWithChildren<TooltipProviderProps>
> = ({ children, ...rest }) => {
  const [currentTooltip, setCurrentTooltip] = React.useState<TooltipConfig>({
    ...DefaultTooltipConfig,
    ...rest,
  });

  const setTooltipConfig = (override: Partial<TooltipConfig>) => {
    setCurrentTooltip((curr) => ({
      ...curr,
      ...override,
    }));
  };

  return (
    <TooltipContext.Provider
      value={{
        appTooltipConfig: currentTooltip,
        setTooltipConfig: setTooltipConfig,
      }}
    >
      <ReactTooltipProvider>{children}</ReactTooltipProvider>
    </TooltipContext.Provider>
  );
};
