import React, { FunctionComponent, useEffect } from 'react';
import { useNavigationState } from '@react-navigation/native';
import { makeStyles } from 'assets/theme';
import { TouchableOpacity, View } from 'react-native';
import { Button } from 'assets/components/button';
import { Text } from 'assets/components/text';
import {
  updateAppointmentFiltersShowModalState,
  setAppointmentFilters,
} from './appointment-filters-actions';
import AppointmentFiltersModal from './AppointmentFiltersModal';
import { useAppointmentFiltersState } from './appointment-filters-store';
import { allowedScreenNames } from './appointment-filters.utils';
import { CheckboxBase } from 'assets/components/checkbox/CheckboxBase';
import { getText } from 'assets/localization/localization';
import { DrawerNavigationState, ParamListBase } from '@react-navigation/native';

export const AppointmentFilters: FunctionComponent<AppointmentFiltersProps> = ({
  state,
}) => {
  const styles = useStyles();

  const isCustomFilter = useAppointmentFiltersState(
    (state) => state.isCustomFilter,
  );

  const screenName = state.routes[state.index].name;

  const handleResetAppointmentFiltersState = () => setAppointmentFilters({});

  useEffect(() => {
    if (screenName && !allowedScreenNames.includes(screenName)) {
      handleResetAppointmentFiltersState();
    }
  }, [screenName]);

  return (
    <>
      {screenName && allowedScreenNames.includes(screenName) ? (
        <>
          <View style={styles.container}>
            <Text style={styles.filtersTitle}>{getText('filters')}</Text>
            <Button
              hierarchy={'tertiary'}
              onPress={() => updateAppointmentFiltersShowModalState(true)}
              labelStyle={styles.buttonLabel}
              style={styles.buttonBorderColor}
              logger={{ id: 'appointment-filters-action-button-more' }}
            >
              {getText('more')}
            </Button>
          </View>

          {isCustomFilter && (
            <View style={styles.container}>
              <TouchableOpacity onPress={handleResetAppointmentFiltersState}>
                <View style={styles.filter}>
                  <CheckboxBase
                    onPress={handleResetAppointmentFiltersState}
                    isChecked={isCustomFilter}
                  />
                  <Text style={styles.filterText}>
                    {getText('custom-filters')}
                  </Text>
                </View>
              </TouchableOpacity>

              <Button
                hierarchy={'tertiary-gray'}
                onPress={() => updateAppointmentFiltersShowModalState(true)}
                labelStyle={styles.buttonLabel}
                style={[styles.buttonBorderColor, { marginTop: 0 }]}
                logger={{ id: 'appointment-filters-action-button-edit' }}
              >
                {getText('edit')}
              </Button>
            </View>
          )}
          <AppointmentFiltersModal />
        </>
      ) : null}
    </>
  );
};

export interface AppointmentFiltersProps {
  state: DrawerNavigationState<ParamListBase>;
}

const useStyles = makeStyles((theme) => ({
  filtersTitle: {
    color: theme.palette.gray[500],
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: '600',
    paddingHorizontal: theme.getSpacing(3),
    marginBottom: theme.getSpacing(0.5),
    marginTop: theme.getSpacing(3),
  },
  buttonLabel: {
    color: theme.palette.primary[500],
    marginTop: theme.getSpacing(0.5),
    marginBottom: theme.getSpacing(0.5),
  },
  buttonBorderColor: {
    borderColor: 'transparent',
    marginHorizontal: 0,
    marginTop: theme.getSpacing(3),
    marginRight: 0,
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  filter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: theme.getSpacing(1),
    paddingHorizontal: theme.getSpacing(3),
    cursor: 'pointer',
  },
  filterText: {
    color: theme.palette.gray[700],
    fontWeight: '500',
    marginLeft: theme.getSpacing(1),
  },
}));
