import { AxiosRequestConfig } from 'axios';
import { BaseApiConfiguration, BaseApiService } from 'assets/core/api';
import { AXIOS_DEFAULT_REQUEST_CONFIG } from '../common/axios-default-request-config';

export interface IDocumentService {
  getBlobParts(src: string): Promise<BlobPart>;
}

export class DocumentService
  extends BaseApiService
  implements IDocumentService
{
  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration,
  ) {
    super(baseUrl, config, enableAuth, baseConfig);
  }

  async getBlobParts(src: string): Promise<BlobPart> {
    const { data } = await this.axiosInstance.get(src, {
      responseType: 'blob', //Force to receive data in a Blob Format
    });
    return data as BlobPart;
  }
}

export default new DocumentService('', AXIOS_DEFAULT_REQUEST_CONFIG);
