import { Text } from 'assets/components/text';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import { FunctionComponent } from 'react';
import { View } from 'react-native';

export const RefillErrorsHeaderRender: FunctionComponent = () => {
  const styles = useStyles();

  return (
    <>
      <View style={styles.container}>
        <View style={styles.firstColumnContent}>
          <Text style={styles.title}>{getText('rx-status')}</Text>
        </View>
        <View style={{ flex: 3 }}>
          <Text style={styles.title}>{getText('message')}</Text>
        </View>
        <View style={{ alignContent: 'flex-end' }}>
          <Text style={styles.title}>{getText('edit')}</Text>
        </View>
      </View>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'row',
    gap: theme.getSpacing(2),
    alignItems: 'center',
    borderBottomColor: theme.palette.gray[200],
    borderBottomWidth: 1,
    padding: theme.getSpacing(1),
    marginBottom: theme.getSpacing(1),
  },
  firstColumnContent: {
    flex: 1,
    flexDirection: 'row',
  },
  title: {
    ...theme.lumistryFonts.label.small.bold,
  },
}));
