import { Icon } from 'assets/components/icon';
import { InfoIcon } from 'assets/icons';
import { makeStyles, useTheme } from 'assets/theme';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { StyleProp, Text, View, ViewStyle } from 'react-native';
import { PharmacyTooltipWrapper } from '../../../../common/PharmacyTooltipWrapper';
import { Tooltip } from 'assets/components/tooltip';

export const InfoTooltip: FunctionComponent<
  PropsWithChildren<InfoTooltipProps>
> = ({ style, tooltipId, text, size, children }) => {
  const theme = useTheme();
  const styles = useStyles();

  return (
    <View style={style}>
      <PharmacyTooltipWrapper tooltipId={tooltipId}>
        <Icon
          icon={InfoIcon}
          size={size ?? 24}
          color={theme.palette.gray[500]}
        />
      </PharmacyTooltipWrapper>
      <Tooltip anchorId={tooltipId} style={styles.base} text={text} />
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  base: {
    borderRadius: theme.roundness,
    paddingVertical: theme.getSpacing(2),
    paddingHorizontal: theme.getSpacing(1),
    opacity: 1,
    maxWidth: 400,
    backgroundColor: theme.palette.gray[900],
  },
  text: {
    fontSize: theme.getSpacing(1.5),
    color: theme.palette.white,
  },
  secondText: {
    fontStyle: 'italic',
  },
}));

export interface InfoTooltipProps {
  tooltipId: string;
  text: string;
  style?: StyleProp<ViewStyle>;
  size?: number;
}
