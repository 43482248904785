import { getText } from 'assets/localization/localization';
import { FunctionComponent, useEffect, useState } from 'react';
import SettingsPageLayout from '../../../components/Settings/SettingsPageLayout';
import SettingsSection from '../../../components/Settings/SettingsSection';
import { useAppStateStore } from '../../../store/app-store';
import { OptionsHeaderRender } from './components/OptionsHeaderRender';
import { OptionsItemRender } from './components/OptionsItemRender';
import { PickupMethodItemRenderer } from './components/PickupMethodItemRenderer';
import { PickupMethodModal } from './components/PickupMethodModal';
import { RefillsTransferMedicationsModal } from './components/RefillsTransferMedicationsModal';
import {
  setPickupMethods,
  setRefillDueReminders,
  setRefillErrors,
  setServiceOptions,
} from './refills-settings-actions';
import { useRefillsSettingsState } from './refills-settings-store';
import { PencilIcon } from 'assets/icons';
import { RefillDueModal } from './components/refill-components/RefillDueRemindersModal';
import { RefillErrorsHeaderRender } from './components/refill-error-components/RefillErrorsHeaderRender';
import { RefillErrorsItemRender } from './components/refill-error-components/RefillErrorsItemRender';
import { RefillDueRemindersItemRender } from './components/refill-components/RefillDueRemindersItemRender';
import { RefillErrorsModal } from './components/refill-error-components/RefillErrorsModal';

export const RefillsSettings: FunctionComponent = () => {
  const { locationId } = useAppStateStore();
  const {
    allServiceOptions,
    allPickupMethods,
    refillErrors,
    refillDueReminders,
  } = useRefillsSettingsState();

  useEffect(() => {
    setServiceOptions(locationId);
    setPickupMethods(locationId);
    setRefillErrors(locationId);
    setRefillDueReminders(locationId);
  }, [locationId]);

  return (
    <SettingsPageLayout title={getText('refills')}>
      <SettingsSection
        title={getText('refill-due-reminders')}
        contentHeader={{
          title: getText('details'),
          button: {
            title: getText('edit'),
            onPress: () =>
              useRefillsSettingsState.setState({
                showRefillDueModal: true,
              }),
            icon: PencilIcon,
          },
        }}
        contentData={refillDueReminders}
        contentItem={RefillDueRemindersItemRender}
        customTypeModal={RefillDueModal}
      />
      <SettingsSection
        title={getText('options')}
        contentData={allServiceOptions}
        contentItem={OptionsItemRender}
        customContentHeaderComponent={OptionsHeaderRender}
        customTypeModal={RefillsTransferMedicationsModal}
      />
      <SettingsSection
        title={getText('pick-up-methods')}
        subtitle={getText('pick-up-methods-description')}
        contentHeader={{ title: getText('details') }}
        contentData={allPickupMethods}
        contentItem={PickupMethodItemRenderer}
        customTypeModal={PickupMethodModal}
      />
      <SettingsSection
        title={getText('refill-errors')}
        subtitle={getText('refill-errors-description')}
        customContentHeaderComponent={RefillErrorsHeaderRender}
        contentData={refillErrors}
        contentItem={RefillErrorsItemRender}
        customTypeModal={RefillErrorsModal}
      />
    </SettingsPageLayout>
  );
};
