import React, { FunctionComponent } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { Icon } from '../../../../../packages/assets/components/icon';
import { Text } from '../../../../../packages/assets/components/text';
import { makeStyles, useTheme } from '../../../../../packages/assets/theme';
import { GridIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';

export const AutomationDrawerButton: FunctionComponent<
  AutomationDrawerButtonType
> = ({ active }: AutomationDrawerButtonType) => {
  const theme = useTheme();
  const styles = useStyles();

  return (
    <TouchableOpacity>
      <View style={[styles.filter, active && styles.filterActive]}>
        <View>
          <Icon
            icon={GridIcon}
            size={20}
            color={
              active ? theme.palette.primary[600] : theme.palette.gray[500]
            }
          />
        </View>
        <Text
          style={[styles.filterText, active && styles.filterTextActive]}
          selectable
        >
          {getText('automations')}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

type AutomationDrawerButtonType = {
  active: boolean;
};

const useStyles = makeStyles((theme) => ({
  filter: {
    display: 'flex',
    flexDirection: 'row',
    paddingVertical: theme.getSpacing(1),
    paddingHorizontal: theme.getSpacing(3),
    alignItems: 'center',
    cursor: 'pointer',
  },
  filterActive: {
    backgroundColor: theme.palette.primary[100],
    color: theme.palette.primary[600],
  },
  filterText: {
    ...theme.lumistryFonts.text.medium.regular,
    marginLeft: theme.getSpacing(1),
    marginRight: 'auto',
    marginVertical: theme.getSpacing(1),
    color: theme.palette.gray[900],
  },
  filterTextActive: {
    ...theme.lumistryFonts.text.medium.semiBold,
    color: theme.palette.primary[600],
  },
}));
