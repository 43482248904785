import {
  CreatePatientAgreementDto,
  PatientAgreementDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { getText } from 'assets/localization/localization';
import DocumentService from '../api/DocumentService';
import PharmacyService from '../api/PharmacyService';
import { PHARMACY_API_BASE_URL } from '../common/constants';
import { handleError } from '../common/errors/commonErrorHandler';
import { PatientAgreementType } from '../components/PatientAgreements';
import { useAppStateStore } from '../store/app-store';

export const createPatientAgreement = async (
  patientAgreement: CreatePatientAgreementDto,
): Promise<PatientAgreementDto | undefined> => {
  try {
    const pharmacyId = useAppStateStore.getState().pharmacyId;
    const data = await PharmacyService.createPatientAgreement(
      pharmacyId,
      patientAgreement,
    );
    return data;
  } catch (error) {
    handleError({
      showToast: true,
      userMessage: getText('general-error-message', {
        message: getText('patient-agreements'),
        error,
      }),
    });
  }
};

export const fetchPharmacyTermsOfService = async (
  pharmacyId: string,
): Promise<BlobPart> => {
  const baseUrl = `${PHARMACY_API_BASE_URL}/pharmacies/${pharmacyId}/patient_agreements/`;
  return await DocumentService.getBlobParts(baseUrl + 'terms');
};

export const fetchPharmacyPrivacyPolicy = async (
  pharmacyId: string,
): Promise<BlobPart> => {
  const baseUrl = `${PHARMACY_API_BASE_URL}/pharmacies/${pharmacyId}/patient_agreements/`;
  return await DocumentService.getBlobParts(baseUrl + 'privacy');
};

export const fetchPatientAgreementDefaultDocs = async (
  pharmacyId: string,
): Promise<PatientAgreementType | undefined> => {
  try {
    const [termsResponse, privacyResponse] = await Promise.all([
      fetchPharmacyTermsOfService(pharmacyId),
      fetchPharmacyPrivacyPolicy(pharmacyId),
    ]);

    return { terms: termsResponse, privacy: privacyResponse };
  } catch (error) {
    handleError({
      showToast: true,
      userMessage: getText('general-error-message', {
        message: getText('patient-agreements'),
        error,
      }),
    });
  }
};
