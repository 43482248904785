import { Modal } from 'assets/components/modal';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import { FunctionComponent } from 'react';
import { Dimensions, View } from 'react-native';
import { PatientAgreementTemplateModalProps } from '../../types';
import { PatientAgreementPDFViewer } from './PatientAgreementPDFViewer';

export const PatientAgreementTemplateModal: FunctionComponent<
  PatientAgreementTemplateModalProps
> = ({ show, privacyBlobPart, termsBlobPart, onClose, onDismiss, type }) => {
  const styles = useStyles();

  return (
    <Modal
      title={
        type === 'privacy'
          ? getText('patient-agreements-privacy-template')
          : getText('patient-agreements-terms-template')
      }
      show={show}
      handleDismissModal={onDismiss}
      size="sm"
      buttons={[
        {
          text: getText('close'),
          onPress: onClose,
          hierarchy: 'tertiary-gray',
          logger: { id: 'close-checklist-patient-agreement-modal' },
        },
      ]}
      showDismissButton
      isScrollable
    >
      <View style={styles.container}>
        {type === 'privacy' && privacyBlobPart && (
          <PatientAgreementPDFViewer src={privacyBlobPart} />
        )}
        {type === 'terms' && termsBlobPart && (
          <PatientAgreementPDFViewer src={termsBlobPart} />
        )}
      </View>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    flexDirection: 'column',
    gap: theme.getSpacing(2),
    minHeight: Dimensions.get('window').height - 250,
  },
  description: {
    ...theme.lumistryFonts.text.medium.regular,
    color: theme.palette.black,
  },
}));
