import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Wizard } from 'react-use-wizard';
import PharmacyOBWizardHeader from './PharmacyOBWizardHeader';
import PharmacyOBWizardFooter from './PharmacyOBWizardFooter';

const PharmacyOBWizard: FunctionComponent<
  PropsWithChildren<PharmacyOBWizardProps>
> = ({ steps, children, onFinish, startIndex = 0, onStepChange }) => {
  return (
    <Wizard
      header={<PharmacyOBWizardHeader steps={steps} />}
      footer={<PharmacyOBWizardFooter onFinish={onFinish} />}
      startIndex={startIndex}
      onStepChange={onStepChange}
    >
      {children}
    </Wizard>
  );
};

export default PharmacyOBWizard;

export interface PharmacyOBWizardProps {
  steps: Array<string>; //Array<StepOptions>;
  onFinish: () => Promise<void>;
  startIndex?: number;
  onStepChange?: (stepIndex: number) => void;
}

interface StepOptions {
  component: FunctionComponent;
  stepHeader: FunctionComponent;
  stepTitle: string;
}
