import { Text } from 'assets/components/text';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { useOnboardingStore } from '../../../../store/onboarding-store';
import { OBContentHeader } from '../content-header/OBContentHeader';
import OBDivider from '../divider/OBDivider';
import { OBLoaderIcon } from '../icons/OBLoaderIcon';

export const OBSingleLocationReview: FunctionComponent = () => {
  const styles = useStyles();
  const { data } = useOnboardingStore();
  const {
    location_contact_name,
    location_phone,
    location_email,
    location_name,
    location_tax_id,
    location_ncpdp,
    location_store_address,
  } = data.locations[0];

  return (
    <View style={styles.root}>
      <OBContentHeader
        icon={OBLoaderIcon}
        title={getText('review')}
        description={getText('click-sections-above')}
      />

      <OBDivider />
      <View style={styles.container}>
        <View style={styles.textContainer}>
          <Text style={styles.title}>{`${getText('pharmacy')}:`}</Text>
          <Text style={styles.subtitle}>{location_name}</Text>
          <Text style={styles.subtitle}>{`${getText(
            'tax-id',
          )}: ${location_tax_id}`}</Text>
          <Text style={styles.subtitle}>{`${getText(
            'pharmacy-ncpdp',
          )}: ${location_ncpdp}`}</Text>
        </View>
        {location_store_address && (
          <View style={styles.textContainer}>
            <Text style={styles.title}>{`${getText('store-address')}:`}</Text>
            <Text style={styles.subtitle}>
              {location_store_address.address1}
            </Text>
            <Text
              style={styles.subtitle}
            >{`${location_store_address.city}, ${location_store_address.state} ${location_store_address.postal_code}`}</Text>
          </View>
        )}
        <View style={styles.textContainer}>
          <Text style={styles.title}>{`${getText('primary-contact')}:`}</Text>
          <Text style={styles.subtitle}>{location_contact_name}</Text>
          <Text style={styles.subtitle}>{location_phone}</Text>
          <Text style={styles.subtitle}>{location_email}</Text>
        </View>
      </View>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    alignContent: 'center',
    marginBottom: theme.getSpacing(4),
  },
  container: {
    alignItems: 'center',
    gap: theme.getSpacing(4),
    marginTop: theme.getSpacing(4),
  },
  title: {
    ...theme.lumistryFonts.text.small.bold,
  },
  subtitle: {
    ...theme.lumistryFonts.text.medium.regular,
    color: theme.palette.gray[700],
  },
  textContainer: {
    alignItems: 'center',
  },
}));
