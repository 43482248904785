import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path } from 'react-native-svg';
import { IconProps, MultiColorIconProps } from './types';

export const NoMessagesIcon: FunctionComponent<
  PropsWithChildren<MultiColorIconProps>
> = ({ color, accentColor, testID }) => {
  return (
    <Svg
      color={color}
      width={83}
      height={68}
      viewBox="0 0 84 70"
      fill="none"
      testID={testID}
    >
      <Path
        fill={accentColor}
        d="m62.057 61.547-.709-5.967.003-.003a2.082 2.082 0 0 1 1.651-2.28c8.282-1.708 13.352-5.926 15.51-10.82a14.3 14.3 0 0 0 1.128-7.43c-.29-2.516-1.239-4.981-2.813-7.171-3.451-4.802-9.917-8.315-19.093-8.315-10.144 0-16.65 4.052-19.748 9.368a15.808 15.808 0 0 0-.833 14.291c2.264 5.17 7.458 9.352 15.444 10.122.475.022.948.21 1.325.565l8.135 7.64Zm3.653-4.61 1.165 9.802a2.088 2.088 0 0 1-1.826 2.318 2.08 2.08 0 0 1-1.75-.63L51.575 57.417c-9.33-1.095-15.473-6.217-18.238-12.532a19.97 19.97 0 0 1 1.044-18.043c3.787-6.5 11.514-11.456 23.353-11.456 10.66 0 18.292 4.25 22.468 10.06 2.002 2.785 3.209 5.924 3.58 9.13a18.414 18.414 0 0 1-1.45 9.58c-2.49 5.646-7.95 10.521-16.622 12.782Zm1.909-21.31a2.735 2.735 0 1 1 .003 5.469 2.735 2.735 0 0 1-.003-5.468Zm-9.834 0a2.735 2.735 0 0 1 0 5.47 2.735 2.735 0 0 1 0-5.47Zm-9.834 0a2.735 2.735 0 1 1-.003 5.47 2.735 2.735 0 0 1 .003-5.47Z"
      />
      <Path
        fill="#374D58"
        d="M50.65 13.818c.07.15.12.306.152.46-1.379.252-2.742.587-4.07 1.011-1.53-3.084-4.036-5.814-7.539-7.726-3.295-1.801-7.49-2.887-12.618-2.887-9.084 0-15.549 3.707-19.048 8.768a17.032 17.032 0 0 0-2.931 7.723 15.898 15.898 0 0 0 1.123 7.989c2.165 5.137 7.251 9.534 15.588 11.252a2.085 2.085 0 0 1 1.65 2.282h.004l-.709 5.968 8.103-7.612c.297 1.53.757 3.01 1.37 4.428L21.01 55.538a2.083 2.083 0 0 1-3.576-1.686l1.165-9.805C9.851 41.752 4.375 36.675 1.89 30.768A20 20 0 0 1 .453 20.695c.387-3.375 1.62-6.683 3.647-9.617C8.32 4.973 15.968.5 26.575.5c5.853 0 10.719 1.284 14.607 3.407 4.448 2.43 7.593 5.945 9.467 9.91Z"
      />
    </Svg>
  );
};
