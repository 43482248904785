import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import theme, { makeStyles, useTheme } from 'assets/theme';

import { Platform, View, ViewStyle } from 'react-native';
import { TouchableRipple } from 'react-native-paper';
import { Text } from 'assets/components/text';
import { useTasksDataTableState } from './tasks-data-table-store';
import { DateTimePickerField } from '../../components/DateTimePickerField';
import { IconButton } from 'assets/components/icon-button';
import { Icon } from 'assets/components/icon';
import {
  AlertCircleIcon,
  ArrowDownCircleIcon,
  ArrowUpCircleIcon,
  BookmarkIcon,
  BookmarkIconFilledIn,
  CheckCircleIcon,
  CheckIcon,
  PauseCircleIcon,
  PencilIcon,
  PlayCircleIcon,
  SettingsIcon,
  TrashWithStripesIcon,
} from 'assets/icons';
import {
  TaskPriority,
  TaskStatus,
} from '@digitalpharmacist/tasks-service-client-axios';
import { Form } from 'assets/layout';
import { useForm } from 'assets/form';
import { formatDateTime } from '../../common/datetime-utils';
import moment from 'moment';
import { Avatar } from 'assets/components/avatar';
import { CollapsibleText } from '../../components/CollapsibleText';
import {
  editTask,
  resetTaskDetails,
  updateTask,
} from './tasks-data-table-actions';
import { deleteTask } from '../task-modal/task-modal-actions';
import PharmacyConfirmationModal from '../../components/PharmacyConfirmationModal';
import { PharmacySidebar } from '../../components/PharmacySidebar';
import { CSSObject, useProSidebar } from 'react-pro-sidebar';
import { visibilityFormatter } from './tasks-data-table.utils';
import { getText } from 'assets/localization/localization';
import { Tag } from 'assets/components/tag';
import { PatientDetailsSection } from '../../components/PatientDetailsSection';
import usePatientStore from '../../screens/patients/patient-store';
import { useAppStateStore } from '../../store/app-store';
import PatientDetailSidebar from '../../screens/patients/PatientDetailSidebar';
import PrescriptionService from '../../api/PrescriptionService';
import { AdvancedDropDownField } from '../../components/advanced-dropdown';
import { OptionProps, SingleValueProps } from 'react-select';
import TaskMetadata from '../task-metadata/TaskMetadata';
import {
  getLocationPatientRecordWithFullName,
  getPatientInfoForSidebarSection,
} from '../../screens/patients/patient-util';
import NotesEditModal from '../../components/NotesEditModal/NotesEditModal';

const formStatusOptions = [
  {
    label: getText('unresolved'),
    value: TaskStatus.Unresolved,
  },
  {
    label: getText('resolved'),
    value: TaskStatus.Resolved,
  },
  {
    label: getText('in_progress'),
    value: TaskStatus.InProgress,
  },
  {
    label: getText('on_hold'),
    value: TaskStatus.OnHold,
  },
];

const taskStatusIcons = {
  [TaskStatus.Unresolved]: AlertCircleIcon,
  [TaskStatus.Resolved]: CheckCircleIcon,
  [TaskStatus.InProgress]: PlayCircleIcon,
  [TaskStatus.OnHold]: PauseCircleIcon,
};

type FormStatusOption = {
  label: string;
  value: TaskStatus;
};

export const FormStatusOptionTemplate = (
  props: OptionProps<FormStatusOption>,
) => {
  const theme = useTheme();
  const styles = useStyles();

  const { isSelected } = props;

  return (
    <View style={styles.dropdownOptionContainer}>
      <View style={styles.dropdownOptionLabelWrapper}>
        <Text style={[styles.dropdownOptionLabel, styles.icon]}>
          <Icon
            icon={taskStatusIcons[props.data.value]}
            size={16}
            color={
              isSelected ? theme.palette.primary[600] : theme.palette.gray[900]
            }
          />
        </Text>
        <Text
          style={[
            styles.dropdownOptionLabel,
            isSelected && { color: theme.palette.primary[600] },
          ]}
        >
          {props.data.label}
        </Text>
      </View>
      {isSelected && (
        <Text style={[styles.icon]}>
          <Icon icon={CheckIcon} size={16} color={theme.palette.primary[600]} />
        </Text>
      )}
    </View>
  );
};

export const FormStatusValueTemplate = (
  props: SingleValueProps<FormStatusOption>,
) => {
  const styles = useStyles();
  let color: string;

  switch (true) {
    case props.data.value === TaskStatus.Resolved:
      color = theme.palette.success[600];
      break;
    case props.data.value === TaskStatus.InProgress:
      color = theme.palette.orangeDark[500];
      break;
    case props.data.value === TaskStatus.OnHold:
      color = theme.palette.blueLight[400];
      break;
    case props.data.value === TaskStatus.Unresolved:
    default:
      color = theme.palette.primary[600];
  }

  return (
    <Text style={[styles.dropDownValue, { color: color }]}>
      {props.data.value !== TaskStatus.Unresolved && (
        <Icon
          icon={taskStatusIcons[props.data.value]}
          size={20}
          color={color}
        />
      )}
      {props.data.label}
    </Text>
  );
};

interface TaskDetailsFormData {
  due_date: string;
  status: FormStatusOption;
}

export const TaskDetailsSidebar: FunctionComponent<TaskDetailsSidebarProps> = ({
  sidebarParentContainerStyles,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const { taskDetails } = useTasksDataTableState();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showResolveConfirmation, setShowResolveConfirmation] = useState(false);
  const [showEditNoteModal, setShowEditNoteModal] = useState(false);
  const { locationId } = useAppStateStore();
  const {
    sidebarDetails: patientSidebarDetails,
    setSidebarDetails: setPatientSidebarDetails,
    removeSidebarDetails: removePatientSidebarDetails,
  } = usePatientStore();
  const { collapseSidebar } = useProSidebar();

  const patientFullName = useMemo(() => {
    if (!taskDetails?.location_patient_record_id) return '';

    return [
      taskDetails.locationPatientRecordData?.first_name,
      taskDetails.locationPatientRecordData?.last_name,
    ].join(' ');
  }, [taskDetails?.location_patient_record_id]);

  const methods = useForm<TaskDetailsFormData>({
    defaultValues: {
      due_date: new Date().toISOString(),
      status: formStatusOptions.find(
        (formStatusOptions) =>
          formStatusOptions.value === TaskStatus.Unresolved,
      ),
    },
  });

  const notesMethods = useForm({
    defaultValues: {
      notes: taskDetails?.notes ? taskDetails.notes : null,
    },
  });

  const isDisabled =
    taskDetails?.status == TaskStatus.Resolved || !!taskDetails?.deleted_at;
  const isAutomatedTask =
    !!taskDetails?.automation_id && !!taskDetails.automationType;

  useEffect(() => {
    methods.reset({
      due_date: taskDetails?.due_date
        ? taskDetails.due_date
        : new Date().toISOString(),
      status: formStatusOptions.find(
        (formStatusOptions) =>
          formStatusOptions.value ===
          (taskDetails?.status ? taskDetails.status : TaskStatus.Unresolved),
      ),
    });
  }, [taskDetails]);

  // Important to unsubscribe here on return, to stop tracking form changes
  useEffect(() => {
    const subscription = methods.watch((value, { name }) => {
      if (name == 'due_date') {
        updateTask(taskDetails?.id as string, {
          due_date: value.due_date,
        });
      }
      if (name == 'status') {
        (value.status as FormStatusOption).value === TaskStatus.Resolved
          ? setShowResolveConfirmation(true)
          : taskDetails?.status !== value.status?.value &&
            updateTask(taskDetails?.id as string, {
              status: (value.status as unknown as FormStatusOption).value,
            });
      }
    });

    return () => subscription.unsubscribe();
  }, [taskDetails, methods.watch]);

  useEffect(() => {
    if (taskDetails) {
      notesMethods.setValue(
        'notes',
        taskDetails.notes ? taskDetails.notes : null,
      );
    }
  }, [taskDetails]);

  const MetaRow = (props: {
    label: string;
    icon?: FunctionComponent | undefined;
    color?: string;
    value: string;
  }) => {
    return (
      <View style={styles.metaRow}>
        <Text style={styles.fieldLabel} selectable>
          {props.label}
        </Text>
        <View style={styles.metaRowContent}>
          {props.icon && (
            <Icon icon={props.icon} color={props.color} size={14}></Icon>
          )}
          <Text
            style={[styles.fieldValue, !!props.color && { color: props.color }]}
            selectable
          >
            {props.value}
          </Text>
        </View>
      </View>
    );
  };

  const patientRecordData = useMemo(() => {
    return getPatientInfoForSidebarSection(
      taskDetails?.locationPatientRecordData,
      taskDetails?.location_patient_record_id,
    );
  }, [taskDetails]);

  const fillPatientSideBarDetails = useCallback(async () => {
    if (
      !taskDetails?.location_patient_record_id ||
      !taskDetails.locationPatientRecordData
    )
      return;

    const prescriptions = await PrescriptionService.findPrescriptions(
      locationId,
      taskDetails.location_patient_record_id,
    );

    const locationPatientRecordWithFullName =
      getLocationPatientRecordWithFullName(
        taskDetails.locationPatientRecordData,
        taskDetails.location_patient_record_id,
        locationId,
      );

    setPatientSidebarDetails({
      patientNumberInList: -1,
      locationPatientRecord: locationPatientRecordWithFullName,
      patientPrescriptions: prescriptions,
    });
  }, [taskDetails]);

  const handlePatientDetailsPress = useCallback(() => {
    void fillPatientSideBarDetails();
  }, [fillPatientSideBarDetails]);

  const handlePatientSidebarCollapse = useCallback(() => {
    removePatientSidebarDetails();
    resetTaskDetails();
    collapseSidebar(true);
  }, [removePatientSidebarDetails, collapseSidebar]);

  useEffect(() => {
    if (!!taskDetails && !patientSidebarDetails) {
      collapseSidebar(false);
    }
  }, [taskDetails, patientSidebarDetails]);

  const handleNotesModalSavePress = async () => {
    if (!taskDetails) return;
    const notes = notesMethods.getValues('notes');
    const notesToUpdate = { notes: notes ? notes : '' };
    await updateTask(taskDetails.id, notesToUpdate);

    setShowEditNoteModal(false);
  };

  const getClickableLink = useCallback((link: string) => {
    if (!link) {
      return;
    }

    const emailRegex =
      /(?:mailto:)?[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\S*/gi;
    const emailLink = link.match(emailRegex);

    if (emailLink) {
      if (!emailLink[0].startsWith('mailto:')) {
        return `mailto:${emailLink[0]}`;
      } else {
        return emailLink[0];
      }
    }

    return link.startsWith('http://') || link.startsWith('https://')
      ? link
      : `https://${link}`;
  }, []);

  const getLinkElement = useCallback(
    (link: string) => {
      return (
        <Text>
          <a
            href={getClickableLink(link)}
            target="_blank"
            style={styles.summaryTitleLink}
          >
            <Text style={styles.summaryTitle}>{link}</Text>
          </a>
        </Text>
      );
    },
    [getClickableLink],
  );

  const titleSummary = useMemo(() => {
    if (!taskDetails?.summary) {
      return '';
    }

    const urlAndEmailRegex =
      /(?:https?:\/\/(?:\S+?)|www\.(?:\S+?)|(?:mailto:)?[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?=[,\s]|$))[^,\s]*/gi;
    const hyperlinks = taskDetails.summary.match(urlAndEmailRegex);

    if (hyperlinks === null) {
      return taskDetails.summary;
    }

    const textWithUrls = taskDetails.summary
      .split(urlAndEmailRegex)
      .map((text, count) => {
        if (text === '' && count === 0) {
          return getLinkElement(hyperlinks[count]);
        }

        if (count === hyperlinks.length) {
          return <Text style={styles.summaryTitle}>{text}</Text>;
        }

        return (
          <Text>
            <Text style={styles.summaryTitle}>{text}</Text>
            {getLinkElement(hyperlinks[count])}
          </Text>
        );
      });

    return <Text style={styles.summaryTitle}>{textWithUrls}</Text>;
  }, [taskDetails?.summary, getClickableLink]);

  if (patientSidebarDetails) {
    return (
      <PatientDetailSidebar
        backLinkComponent={
          <Text style={styles.link} onPress={removePatientSidebarDetails}>
            {getText('task-details')}
          </Text>
        }
        onCollapse={handlePatientSidebarCollapse}
        containerStyles={
          sidebarParentContainerStyles
            ? { height: '100%' }
            : {
                height: 'auto',
              }
        }
        parentContainerStyle={
          sidebarParentContainerStyles
            ? sidebarParentContainerStyles
            : {
                marginLeft: -1,
              }
        }
      />
    );
  }

  return (
    <PharmacySidebar
      title={getText('task-details')}
      dataExists={!!taskDetails}
      defaultCollapsed={true}
      containerStyles={{
        height: '100%',
      }}
      rootStyle={{ borderRight: 0 }}
      parentContainerStyle={
        sidebarParentContainerStyles
          ? sidebarParentContainerStyles
          : {
              marginLeft: -1,
            }
      }
    >
      <View>
        {taskDetails && (
          <View style={styles.sidebarWrapper}>
            <PharmacyConfirmationModal
              show={showDeleteConfirmation}
              onAccepted={() => {
                setShowDeleteConfirmation(false);
                deleteTask(taskDetails.id);
              }}
              onDismiss={() => setShowDeleteConfirmation(false)}
              message={`${getText('are-you-sure-to-delete-task')}: "${
                taskDetails.summary
              }"?`}
            />
            <PharmacyConfirmationModal
              show={showResolveConfirmation}
              onAccepted={() => {
                setShowResolveConfirmation(false);
                updateTask(taskDetails.id, {
                  status: TaskStatus.Resolved,
                });
              }}
              onDismiss={() => {
                setShowResolveConfirmation(false);
                //if cancelling the resolved status change reset the option to the previous selected status
                const prevStatus = formStatusOptions.find(
                  (formStatusOptions) =>
                    formStatusOptions.value === taskDetails.status,
                );
                if (prevStatus) {
                  methods.setValue('status', prevStatus);
                }
              }}
              message={`${getText('are-you-sure-to-resolve-task')}: "${
                taskDetails.summary
              }"?`}
            />

            <View style={styles.quickActionsHeader}>
              <View style={styles.quickActionLabels}>
                {taskDetails.due_date &&
                  moment(taskDetails.due_date).isSame(moment(), 'day') && (
                    <Tag
                      label={getText('today')}
                      labelColor={theme.palette.gray[700]}
                      style={{
                        backgroundColor: theme.palette.warning[300],
                        paddingLeft: theme.getSpacing(0.5),
                        marginTop: 'auto',
                        marginBottom: 'auto',
                      }}
                    />
                  )}
                {taskDetails.type && (
                  <Tag
                    label={taskDetails.type.title}
                    labelColor={theme.palette.gray[100]}
                    style={{
                      backgroundColor: taskDetails.type.color,
                      paddingLeft: theme.getSpacing(0.5),
                    }}
                  />
                )}
              </View>
              <View style={styles.quickActionsButtons}>
                <IconButton
                  icon={TrashWithStripesIcon}
                  disabled={isDisabled}
                  size={18}
                  color={theme.palette.gray[500]}
                  logger={{ id: 'task-details-sidebar--delete' }}
                  onPress={() => setShowDeleteConfirmation(true)}
                  style={[
                    Platform.OS === 'web' && isDisabled
                      ? ({ cursor: 'default' } as any)
                      : {},
                  ]}
                ></IconButton>
                <IconButton
                  icon={
                    taskDetails.flagged ? BookmarkIconFilledIn : BookmarkIcon
                  }
                  disabled={isDisabled}
                  size={18}
                  color={
                    taskDetails.flagged
                      ? theme.palette.error['700']
                      : theme.palette.gray[500]
                  }
                  logger={{ id: 'task-details-sidebar--flag' }}
                  onPress={() =>
                    updateTask(taskDetails.id, {
                      flagged: !taskDetails.flagged,
                    })
                  }
                  style={[
                    Platform.OS === 'web' && isDisabled
                      ? ({ cursor: 'default' } as any)
                      : {},
                  ]}
                ></IconButton>
                <TouchableRipple
                  disabled={isDisabled}
                  style={[
                    styles.editTouchableContainer,
                    Platform.OS === 'web' && isDisabled
                      ? ({ cursor: 'default', opacity: 0.32 } as any)
                      : {},
                  ]}
                  onPress={() => editTask(taskDetails.id)}
                >
                  <View style={styles.editTaskIconContainer}>
                    <Icon
                      size={18}
                      color={theme.palette.gray[500]}
                      icon={PencilIcon}
                    />
                    <Text style={styles.editTaskText}>{getText('edit')}</Text>
                  </View>
                </TouchableRipple>
              </View>
            </View>
            <View style={styles.titleContainer}>
              <Text style={styles.title} selectable>
                {titleSummary}
              </Text>
            </View>
            <View style={styles.principalActionsHeader}>
              <Form methods={methods}>
                <Form.Row>
                  <Form.Column>
                    <DateTimePickerField
                      name="due_date"
                      disabled={isDisabled}
                      type="date-and-time-combo"
                      minDate={new Date().toISOString()}
                    ></DateTimePickerField>
                  </Form.Column>
                  <Form.Column>
                    <AdvancedDropDownField
                      name="status"
                      label=""
                      isDisabled={isDisabled}
                      isSearchable={false}
                      getOptionValue={(optionValue) => optionValue.value}
                      getOptionLabel={(optionValue) => optionValue.label}
                      menuPortalTarget={document.body}
                      isClearable={false}
                      options={formStatusOptions}
                      isMulti={false}
                      optionTemplate={FormStatusOptionTemplate}
                      singleValueTemplate={FormStatusValueTemplate}
                    />
                  </Form.Column>
                </Form.Row>
              </Form>
            </View>
            <View style={styles.assigneeContainer}>
              <View style={styles.assigneeNameContainer}>
                <Text style={styles.fieldLabel} selectable>
                  {getText('assigned-to')}
                </Text>
                {taskDetails.assigned_user_id ? (
                  <Text style={styles.assigneeName} selectable>
                    {taskDetails.assigned_user_first_name}{' '}
                    {taskDetails.assigned_user_last_name}
                  </Text>
                ) : (
                  <Text style={styles.assigneeUnassignedText} selectable>
                    {getText('nobody')}
                  </Text>
                )}
              </View>
              <Avatar
                size={40}
                firstName={
                  taskDetails.assigned_user_id
                    ? taskDetails.assigned_user_first_name!
                    : undefined
                }
                lastName={
                  taskDetails.assigned_user_id
                    ? taskDetails.assigned_user_last_name!
                    : undefined
                }
              />
            </View>
            {taskDetails.description ||
            (!!taskDetails.automation_id && taskDetails.metadata) ? (
              <View style={styles.descriptionContainer}>
                <Text style={styles.fieldLabel} selectable>
                  {getText('description')}
                </Text>
                {taskDetails.metadata ? (
                  <TaskMetadata
                    metadata={taskDetails.metadata}
                    patientFullName={patientFullName}
                    patientDateOfBirth={
                      taskDetails.locationPatientRecordData?.date_of_birth
                    }
                  />
                ) : (
                  <CollapsibleText
                    text={
                      taskDetails.description ? taskDetails.description : ''
                    }
                    color={theme.palette.gray[700]}
                  />
                )}
              </View>
            ) : null}
            <View style={styles.metaContainer}>
              {taskDetails.created_at ? (
                <MetaRow
                  label={getText('created')}
                  value={formatDateTime(taskDetails.created_at)}
                />
              ) : null}
              {taskDetails.updated_at
                ? taskDetails.status != TaskStatus.Resolved && (
                    <MetaRow
                      label={getText('updated-without-date')}
                      value={formatDateTime(taskDetails.updated_at)}
                    />
                  )
                : null}
              {taskDetails.updated_at
                ? taskDetails.status === TaskStatus.Resolved && (
                    <MetaRow
                      label={getText('completed')}
                      value={formatDateTime(taskDetails.updated_at)}
                    />
                  )
                : null}
              {(taskDetails.created_by_user_id || isAutomatedTask) && (
                <View style={styles.metaRow}>
                  <Text style={styles.fieldLabel} selectable>
                    {getText('created-by')}
                  </Text>
                  <View style={styles.metaRowContent}>
                    {!isAutomatedTask ? (
                      <Avatar
                        size={24}
                        firstName={taskDetails.created_by_user_first_name!}
                        lastName={taskDetails.created_by_user_last_name!}
                      />
                    ) : (
                      <View style={styles.settingsIconContainer}>
                        <Icon
                          size={20}
                          icon={SettingsIcon}
                          color={theme.palette.gray[700]}
                        />
                      </View>
                    )}
                    <Text style={styles.fieldValue} selectable>
                      {!isAutomatedTask ? (
                        <>
                          {taskDetails.created_by_user_first_name}{' '}
                          {taskDetails.created_by_user_last_name}
                        </>
                      ) : (
                        <>{getText('automated-workflow')}</>
                      )}
                    </Text>
                  </View>
                </View>
              )}
              {taskDetails.priority ? (
                <MetaRow
                  label={getText('task-priority')}
                  icon={
                    taskDetails.priority === TaskPriority.High
                      ? ArrowUpCircleIcon
                      : taskDetails.priority === TaskPriority.Low
                      ? ArrowDownCircleIcon
                      : undefined
                  }
                  color={
                    taskDetails.priority === TaskPriority.High
                      ? theme.palette.error[600]
                      : theme.palette.gray[700]
                  }
                  value={
                    taskDetails.priority.charAt(0).toUpperCase() +
                    taskDetails.priority.slice(1)
                  }
                />
              ) : null}
              {taskDetails.visibility ? (
                <MetaRow
                  label={getText('task-visibility')}
                  icon={
                    taskDetails.priority === TaskPriority.High
                      ? ArrowUpCircleIcon
                      : taskDetails.priority === TaskPriority.Low
                      ? ArrowDownCircleIcon
                      : undefined
                  }
                  color={theme.palette.gray[700]}
                  value={visibilityFormatter[taskDetails.visibility]}
                />
              ) : null}
            </View>
            <View style={styles.sectionContainer}>
              <View style={styles.sectionHeader}>
                <Text
                  style={[styles.fieldLabel, styles.sectionTitle]}
                  selectable
                >
                  {getText('notes')}
                </Text>
                <Text
                  style={[styles.fieldLabel, styles.sectionNotice]}
                  selectable
                >
                  {getText('internal-use-only')}
                </Text>
                {taskDetails.status !== TaskStatus.Resolved && (
                  <Text
                    onPress={() => setShowEditNoteModal(true)}
                    style={styles.buttonLabel}
                  >
                    {getText('edit')}
                  </Text>
                )}
              </View>
              <View style={[styles.sectionContent, styles.notesContent]}>
                <Text style={[styles.fieldValue, styles.notes]} selectable>
                  {taskDetails.notes ? taskDetails.notes : getText('none')}
                </Text>
              </View>
            </View>
            {patientRecordData ? (
              <PatientDetailsSection
                {...patientRecordData}
                onPatientDetailsPress={handlePatientDetailsPress}
              />
            ) : null}
            {taskDetails.id && (
              <NotesEditModal
                show={showEditNoteModal}
                onDismiss={() => setShowEditNoteModal(false)}
                onSave={handleNotesModalSavePress}
                form={notesMethods}
                notes={taskDetails.notes || ''}
              />
            )}
          </View>
        )}
      </View>
    </PharmacySidebar>
  );
};

interface TaskDetailsSidebarProps {
  sidebarRootStyle?: CSSObject;
  sidebarParentContainerStyles?: ViewStyle;
}

const useStyles = makeStyles((theme) => ({
  detailsContainer: {
    height: '100%',
    padding: theme.getSpacing(1),
  },
  sidebarWrapper: {},
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: theme.getSpacing(1),
    marginBottom: theme.getSpacing(1),
    borderBottomColor: theme.palette.gray[200],
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
  },
  headerTitleText: {
    color: theme.palette.gray[400],
  },
  quickActionsHeader: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.getSpacing(2),
    alignItems: 'center',
  },
  quickActionLabels: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: theme.getSpacing(1),
  },
  quickActionsButtons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.getSpacing(1),
    marginBottom: theme.getSpacing(2),
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    display: 'flex',
    color: theme.palette.gray[900],
    ...theme.lumistryFonts.text.large.semiBold,
    flex: 1,
  },
  principalActionsHeader: {
    paddingBottom: theme.getSpacing(1),
    marginBottom: theme.getSpacing(1),
    borderBottomColor: theme.palette.gray[200],
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    zIndex: 1,
  },
  assigneeContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.getSpacing(1),
    marginBottom: theme.getSpacing(3),
    alignItems: 'center',
  },
  assigneeNameContainer: {
    flex: 1,
  },
  assigneeName: {
    ...theme.lumistryFonts.text.large.semiBold,
    color: theme.palette.gray[900],
  },
  assigneeUnassignedText: {
    color: theme.palette.gray[500],
  },
  descriptionContainer: {
    marginBottom: theme.getSpacing(2),
  },
  fieldLabel: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.gray[900],
    marginBottom: theme.getSpacing(1),
    minWidth: 100,
  },
  fieldValue: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.gray[700],
  },
  metaContainer: {
    marginBottom: theme.getSpacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  metaRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.getSpacing(2),
    alignItems: 'center',
  },
  metaRowContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.getSpacing(1),
    marginBottom: theme.getSpacing(1),
  },
  sectionContainer: {
    marginTop: theme.getSpacing(2),
  },
  sectionHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: theme.getSpacing(1),
    marginBottom: theme.getSpacing(2),
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.gray[200],
    gap: theme.getSpacing(2),
  },
  sectionTitle: {
    ...theme.lumistryFonts.label.xxSmall.semiBold,
    textTransform: 'uppercase',
    flex: 1,
    marginBottom: 0,
  },
  sectionNotice: {
    ...theme.lumistryFonts.label.xSmall.regular,
    color: theme.palette.gray[500],
    marginBottom: 0,
  },
  sectionAction: {
    marginBottom: 0,
    color: theme.palette.primary[500],
  },
  sectionContent: {},
  notesContent: {
    backgroundColor: theme.palette.gray[50],
    borderRadius: theme.roundness,
    padding: theme.getSpacing(1),
  },
  editTaskIconContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  editTaskText: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.gray[500],
    marginLeft: theme.getSpacing(0.5),
  },
  editTouchableContainer: {
    margin: theme.getSpacing(1),
  },
  link: {
    color: theme.palette.primary[500],
    textDecorationLine: 'none',
  },
  dropDownValue: {
    ...theme.lumistryFonts.text.small.regular,
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.getSpacing(0.5),
    textTransform: 'capitalize',
  },
  dropdownOptionContainer: {
    borderRadius: 8,
    flexDirection: 'row',
    paddingVertical: theme.getSpacing(0.5),
    margin: 0,
  },
  dropdownOptionLabelWrapper: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  dropdownOptionLabel: {
    ...theme.lumistryFonts.text.small.regular,
    margin: 0,
    marginRight: theme.getSpacing(1),
  },
  icon: {
    marginTop: theme.getSpacing(0.5),
  },
  insuranceContainer: {
    marginTop: -theme.getSpacing(3),
  },
  settingsIconContainer: {
    backgroundColor: theme.palette.gray[200],
    borderRadius: 20,
    padding: theme.getSpacing(0.5),
  },
  buttonLabel: {
    ...theme.lumistryFonts.label.xSmall.regular,
    color: theme.palette.primary[500],
    marginTop: theme.getSpacing(0.5),
    marginBottom: theme.getSpacing(0.5),
  },
  buttonBorderColor: {
    borderColor: 'transparent',
    marginHorizontal: 0,
    marginRight: 0,
  },
  notes: {
    ...theme.lumistryFonts.text.small.regular,
    backgroundColor: theme.palette.gray[50],
    color: theme.palette.gray[400],
  },
  summaryTitleLink: {
    textDecorationLine: 'none',
  },
  summaryTitle: {
    color: theme.palette.gray[900],
    ...theme.lumistryFonts.text.large.semiBold,
  },
}));

export default TaskDetailsSidebar;
