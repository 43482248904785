import React, { useEffect } from 'react';
import { ViewStyle } from 'react-native';
import usePatientStore from './patient-store';
import patientService from '../../api/PatientService';

import { LocationPatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';

import { CSSObject } from 'react-pro-sidebar';

import { PatientDetailSidebarView } from '../../components/PatientDetailSidebarView/PatientDetailSidebarView';

export default function PatientDetailSidebar({
  onCollapse,
  backLinkComponent,
  rootStyle = {},
  containerStyles = {},
  parentContainerStyle = {},
}: {
  onCollapse?: () => void;
  backLinkComponent?: JSX.Element;
  rootStyle?: CSSObject;
  containerStyles?: CSSObject;
  parentContainerStyle?: ViewStyle;
}) {
  const { sidebarDetails, locationPatientRecordDto, setLocationPatientRecord } =
    usePatientStore();

  const { locationPatientRecord, patientPrescriptions, patientNumberInList } =
    sidebarDetails ?? {};

  if (!locationPatientRecord) {
    return <></>;
  }

  useEffect(() => {
    async function getLocationPatientRecord() {
      try {
        if (locationPatientRecord) {
          const locationPatient = await patientService.getPatientLocationRecord(
            locationPatientRecord.location_id,
            locationPatientRecord.id,
          );
          setLocationPatientRecord(locationPatient);
        }
      } catch (err) {}
    }

    void getLocationPatientRecord();
  }, [locationPatientRecord]);

  const updateLPRInSidebar = (
    locationPatientRecord: LocationPatientRecordDto,
  ) => setLocationPatientRecord(locationPatientRecord);

  if (!locationPatientRecordDto || patientNumberInList === undefined) {
    return <></>;
  }

  return (
    <PatientDetailSidebarView
      locationPatientRecordDto={locationPatientRecordDto}
      onCollapse={onCollapse}
      backLinkComponent={backLinkComponent}
      rootStyle={rootStyle}
      containerStyles={containerStyles}
      parentContainerStyle={parentContainerStyle}
      patientPrescriptions={patientPrescriptions ?? []}
      updateLPRCallback={updateLPRInSidebar}
    />
  );
}
