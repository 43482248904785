import create from 'zustand';
import {
  AppointmentTypeDto,
  BookingDto,
} from '@digitalpharmacist/appointment-service-client-axios';

import { ApiError, AsyncStatus } from '../../store/types';
import { PatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';

export const useAppointmentsListState = create<AppointmentsListState>(() => ({
  status: 'idle',
  appointmentDetailsStatus: 'idle',
  appointmentNotesStatus: 'idle',
  error: undefined,
  appointments: undefined,
  appointmentDetails: undefined,
  appointmentType: undefined,
  patientRecord: undefined,
  editNotes: false,
  appointmentVisible: true,
}));

interface AppointmentsListState {
  status: AsyncStatus;
  appointmentDetailsStatus: AsyncStatus;
  appointmentNotesStatus: AsyncStatus;
  error?: ApiError;
  appointments?: BookingDto[];
  appointmentDetails?: BookingDto;
  appointmentType?: AppointmentTypeDto;
  patientRecord?: PatientRecordDto;
  editNotes?: boolean;
  appointmentVisible?: boolean;
}
