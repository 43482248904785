import { FunctionComponent } from 'react';
import { getText } from 'assets/localization/localization';
import { Text } from 'assets/components/text';
import { makeStyles } from 'assets/theme';
import { CheckboxFilter } from '../../../../components/CheckboxFilter';
import { BulkMessageTypeKeys, StatusFilter } from 'assets/types/messageTypes';

import {
  ListIcon,
  CheckIcon,
  ClockIcon,
  AlertTriangleIcon,
  CancelCircleIcon,
} from 'assets/icons';

import { ButtonFilter } from '../messages-filters/MessagesButtonFilter';
import { useBulkMessagesStore } from '../../stores/bulk-messages-store/bulk-messages-store';
import {
  setBulkDetails,
  setMessageTypeFilters,
  setStatusTypeFilters,
} from '../../stores/bulk-messages-store/bulk-messages-actions';

const bulksBaseFilters = [
  {
    name: getText('all'),
    value: StatusFilter.All,
    icon: ListIcon,
  },
  {
    name: getText('sent'),
    value: StatusFilter.Sent,
    icon: CheckIcon,
  },
  {
    name: getText('scheduled'),
    value: StatusFilter.Scheduled,
    icon: ClockIcon,
  },
  {
    name: getText('canceled'),
    value: StatusFilter.Canceled,
    icon: CancelCircleIcon,
  },
  {
    name: getText('failed'),
    value: StatusFilter.Failed,
    icon: AlertTriangleIcon,
  },
];

const checkboxFilters: Record<string, string>[] = [
  {
    name: getText('secure-chat'),
    value: BulkMessageTypeKeys.Secure,
  },
  {
    name: getText('non-secure-message'),
    value: BulkMessageTypeKeys.NotSecure,
  },
];

export const BulkSideBar: FunctionComponent = () => {
  const styles = useStyles();

  const { message_type, status } = useBulkMessagesStore(
    (state) => state.filters,
  );
  const handleBaseFilterClick = (filter: string | number) => {
    setBulkDetails({ show: false, data: undefined });
    if (typeof filter === 'string') {
      switch (filter) {
        case 'all':
          setStatusTypeFilters(StatusFilter.All);
          break;
        case 'sent':
          setStatusTypeFilters(StatusFilter.Sent);
          break;
        case 'scheduled':
          setStatusTypeFilters(StatusFilter.Scheduled);
          break;
        case 'canceled':
          setStatusTypeFilters(StatusFilter.Canceled);
          break;
        case 'failed':
          setStatusTypeFilters(StatusFilter.Failed);
          break;
      }
    }
  };

  const handleCheckboxFiltersClick = (bulkMessageType: string | number) => {
    setBulkDetails({ show: false, data: undefined });
    if (typeof bulkMessageType === 'string') {
      setMessageTypeFilters(bulkMessageType);
    }
  };

  return (
    <>
      {bulksBaseFilters.map((filter) => (
        <ButtonFilter
          onPress={handleBaseFilterClick}
          active={filter.value === status}
          disabled={false}
          key={`filter-${filter.value}`}
          {...filter}
        />
      ))}
      <Text style={styles.filtersTitle}>{getText('filters')}</Text>
      {checkboxFilters.map((filter) => (
        <CheckboxFilter
          name={filter.name}
          value={filter.value}
          onPress={handleCheckboxFiltersClick}
          disabled={false}
          active={message_type[filter.value]}
          key={filter.value}
          {...filter}
        />
      ))}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  filtersTitle: {
    color: theme.palette.gray[500],
    textTransform: 'uppercase',
    fontSize: 12,
    ...theme.fonts.medium,
    paddingHorizontal: theme.getSpacing(3),
    marginBottom: theme.getSpacing(0.5),
    marginTop: theme.getSpacing(3),
  },
}));
