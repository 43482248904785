import React, { PropsWithChildren, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useTooltip } from '../hooks';

export const TooltipPortal: React.FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const tooltipConfig = useTooltip();

  const getPortalElement = () => {
    const element = document.getElementById(tooltipConfig.id);
    if (element) return element;
    const el = document.createElement('div');
    el.setAttribute('id', tooltipConfig.id);
    return el;
  };

  return createPortal(
    children,
    // get outer DOM element
    document.body.appendChild(getPortalElement()),
  );
};
