import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path } from 'react-native-svg';
import { IconProps } from './types';

export const AttachmentIcon: FunctionComponent<
  PropsWithChildren<IconProps>
> = ({ color, size, testID }) => {
  return (
    <Svg
      width={size}
      height={size}
      stroke="currentColor"
      color={color}
      testID={testID}
      viewBox="0 0 18 20"
      fill="none"
    >
      <Path
        d="M17.1166 9.20835L9.45831 16.8667C8.52011 17.8049 7.24763 18.332 5.92081 18.332C4.59399 18.332 3.32151 17.8049 2.38331 16.8667C1.44511 15.9285 0.91803 14.656 0.91803 13.3292C0.91803 12.0024 1.44511 10.7299 2.38331 9.79168L10.0416 2.13335C10.6671 1.50788 11.5154 1.15649 12.4 1.15649C13.2845 1.15649 14.1328 1.50788 14.7583 2.13335C15.3838 2.75882 15.7352 3.60713 15.7352 4.49168C15.7352 5.37623 15.3838 6.22455 14.7583 6.85002L7.09164 14.5083C6.77891 14.8211 6.35475 14.9968 5.91248 14.9968C5.4702 14.9968 5.04604 14.8211 4.73331 14.5083C4.42058 14.1956 4.24488 13.7715 4.24488 13.3292C4.24488 12.8869 4.42058 12.4627 4.73331 12.15L11.8083 5.08335"
        stroke="#0093E8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
