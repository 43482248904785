import React, { FC, FunctionComponent, PropsWithChildren, useRef } from 'react';
import { GradientView } from 'assets/components/gradient-view';
import { TouchableOpacity, View } from 'react-native';
import { NavigationProp, ParamListBase } from '@react-navigation/native';
import { makeStyles, useTheme } from 'assets/theme';
import { Text } from 'assets/components/text';
import { uuid } from 'assets/utils/uuid';
import {
  SettingsIcon,
  MenuIcon,
  LumistryIcon,
  ExternalLinkIcon,
  UserIcon,
} from 'assets/icons';
import { IconButton } from 'assets/components/icon-button';
import { Avatar } from 'assets/components/avatar';
import { NavigationItems } from './NavigationItems';
import { getText } from 'assets/localization/localization';
import { Icon } from 'assets/components/icon';
import { logout } from '../../screens/login/login-actions';
import { MenuOptionType, MenuWrapper } from './MenuWrapper';
import { PharmacyStoreSelector } from '../select-store/PharmacyStoreSelector';
import { openUrl } from '../../actions/app-actions';
import { HELP_JUICE_URL } from '../../common/constants';
import { canFactory } from '../../common/guards/permission-guard';
import UsersService from '../../api/UsersService';
import { handleError } from '../../common/errors/commonErrorHandler';
import * as Linking from 'expo-linking';
import { CommonActions } from '@react-navigation/native';

export interface PharmacyHeaderProps {
  /**
   * GradientId, should *only* be necessary for snapshot tests
   */
  gradientId?: string;
  isUserLoggedIn: boolean;
  firstName: string;
  lastName: string;
  email: string;
  navigation?: any;
  screens?: {
    name: string;
    label: string;
  }[];
}

export const PharmacyHeader: FunctionComponent<
  PropsWithChildren<PharmacyHeaderProps>
> = ({
  gradientId = uuid(),
  isUserLoggedIn = false,
  firstName,
  lastName,
  email,
  navigation,
  screens,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const gradientIdRef = useRef(gradientId);
  const userName = firstName + ' ' + lastName;

  const handleNeedHelpPress = async () => {
    let helpjuicePath = (await UsersService.getSignedHelpjuiceLink(email))
      .redirect_url;

    if (helpjuicePath === undefined) {
      handleError({
        showToast: false,
        userMessage: `Could not get a helpjuice link. email used: ${email}`,
      });
      helpjuicePath = HELP_JUICE_URL;
    }

    openUrl(helpjuicePath, '_blank');
  };

  // TODO: verify with product how to persist this number configuration
  const lumistryPhone = '(888) 699-9803';

  const handleGoHome = () => {
    if (navigation) {
      navigation.navigate('dashboard');
    }
  };

  const handleOnPhoneNumberPress = () => {
    Linking.openURL(`tel:${lumistryPhone}`);
  };

  return (
    <>
      <GradientView
        contentHeight={72}
        fillGradientStops={[
          { stopColor: theme.colors.brandedSecondary, offset: 0 },
          { stopColor: theme.colors.brandedSecondary, offset: 100 },
        ]}
        gradientId={gradientIdRef.current}
      >
        <View style={styles.headerContainer}>
          <View style={styles.leftContent}>
            <TouchableOpacity style={styles.logo} onPress={handleGoHome}>
              <Icon size={50} color={theme.palette.white} icon={LumistryIcon} />
            </TouchableOpacity>
            {isUserLoggedIn && navigation && screens && (
              <NavigationItems navigation={navigation} screens={screens} />
            )}
          </View>
          <View style={styles.rightContent}>
            {isUserLoggedIn && userName ? (
              <View style={{ flexDirection: 'row' }}>
                <PharmacyStoreSelector />
                <TopNavRightIcons
                  navigation={navigation}
                  firstName={firstName}
                  lastName={lastName}
                  email={email}
                />
              </View>
            ) : (
              <View style={styles.helpAndSupportContainer}>
                <TouchableOpacity onPress={handleNeedHelpPress}>
                  <Text
                    style={{
                      color: theme.palette.white,
                    }}
                  >
                    {getText('need-help')}
                  </Text>
                </TouchableOpacity>

                <TouchableOpacity onPress={handleOnPhoneNumberPress}>
                  <Text
                    style={{
                      color: theme.palette.white,
                    }}
                  >
                    {lumistryPhone}
                  </Text>
                </TouchableOpacity>
              </View>
            )}
          </View>
        </View>
      </GradientView>
    </>
  );
};

const HamburgerMenu: FC<{
  navigation: NavigationProp<ParamListBase>;
  email: string;
}> = ({ navigation, email }) => {
  const theme = useTheme();
  const styles = useStyles();
  const helpjuicePathPlaceholder = 'HELPJUICE';

  const handleMenuItemClick = async (item: HamburgerMenuOptionType) => {
    // Helpjuice requires a signed link. Fetch and open
    if (item.path === helpjuicePathPlaceholder) {
      let helpjuicePath = (await UsersService.getSignedHelpjuiceLink(email))
        .redirect_url;

      if (helpjuicePath === undefined) {
        handleError({
          showToast: false,
          userMessage: `Could not get a helpjuice link. email used: ${email}`,
        });
        helpjuicePath = HELP_JUICE_URL;
      }

      openUrl(helpjuicePath, '_blank');
    }

    if (item.isExternalPath) {
      openUrl(item.path, '_blank');
    } else {
      navigation.navigate(item.path);
    }
  };

  const menuItems: MenuOptionType<HamburgerMenuOptionType>[] = [
    {
      path: 'videos',
      text: getText('video-header'),
    },
    {
      path: helpjuicePathPlaceholder,
      text: getText('help-and-support'),
      isExternalPath: true,
    },
  ];

  return (
    <MenuWrapper
      onPress={handleMenuItemClick}
      icon={MenuIcon}
      iconProps={{
        color: theme.palette.white,
        testID: PharmacyHeaderTestIDs.menuButton,
      }}
      items={menuItems}
      optionTemplate={(item, index) => {
        return <Text key={`${item.text}-${index}`}>{item.text}</Text>;
      }}
      menuItemStyle={{ height: 32 }}
    />
  );
};

const UserMenu: FC<{
  navigation: NavigationProp<ParamListBase>;
  firstName: string;
  lastName: string;
}> = ({ navigation, firstName, lastName }) => {
  const theme = useTheme();
  const styles = useStyles();

  const handleMenuItemClick = async (item: UserMenuItemType) => {
    if (item.path === 'sign-out') {
      await logout();
      navigation.dispatch(
        CommonActions.reset({
          index: 0,
          routes: [{ name: 'login' }],
        }),
      );
    } else {
      navigation.navigate(item.path, { screen: item.screen });
    }
  };

  // commented the lines below because they will be used when those routes will be implemented
  const menuItems: UserMenuItemType[] = [
    {
      path: 'settings',
      screen: 'profile',
      icon: UserIcon,
      text: getText('profile-settings'),
      disabled: false,
    },
    // {
    //   path: 'switch-user',
    //   icon: RepeatIcon,
    //   text: getText('switch-user'),
    //   disabled: true,
    // },
    {
      path: 'sign-out',
      icon: ExternalLinkIcon,
      text: getText('sign-out'),
      disabled: false,
    },
  ];

  return (
    <MenuWrapper
      items={menuItems}
      optionTemplate={(item) => (
        <View style={styles.userMenuOptionContainer}>
          <Icon
            icon={item.icon}
            size={16}
            color={theme.palette.gray[500]}
            strokeWidth={2}
          />
          <Text style={styles.userMenuOptionText}>{item.text}</Text>
        </View>
      )}
      anchor={
        <View style={{ paddingTop: theme.getSpacing(0.5) }}>
          <Avatar firstName={firstName} lastName={lastName} size={40} />
        </View>
      }
      onPress={handleMenuItemClick}
      menuItemStyle={{ height: 32 }}
      menuStyle={{ marginTop: 50 }}
    />
  );
};

const TopNavRightIcons: FC<{
  navigation: NavigationProp<ParamListBase>;
  firstName: string;
  lastName: string;
  email: string;
}> = ({ navigation, firstName, lastName, email }) => {
  const theme = useTheme();
  const styles = useStyles();

  return (
    <View style={styles.rightIconsContainer}>
      {canFactory('read')('settings') && (
        <IconButton
          icon={SettingsIcon}
          logger={{ id: 'pharmacy-settings-icon-button' }}
          onPress={() => navigation.navigate('settings')}
          color={theme.palette.white}
          testID={PharmacyHeaderTestIDs.settingsButton}
          style={{ margin: 0 }}
        />
      )}
      <View style={{ marginRight: 6 }}>
        <HamburgerMenu navigation={navigation} email={email} />
      </View>

      <UserMenu
        navigation={navigation}
        firstName={firstName}
        lastName={lastName}
      />
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    position: 'relative',
    height: '100%',
    flexDirection: 'row',
    boxShadow: 'inset 0px 2px 8px rgba(0, 0, 0, 0.45)',
  },
  leftContent: {
    flex: 2,
    alignSelf: 'center',
    alignItems: 'center',
    paddingLeft: theme.getSpacing(4),
    flexDirection: 'row',
  },
  rightContent: {
    flex: 1,
    alignSelf: 'center',
    alignItems: 'flex-end',
    paddingRight: theme.getSpacing(4),
  },
  navItem: {
    color: theme.palette.white,
    fontWeight: '700',
    fontSize: 16,
    lineHeight: 24,
  },
  logo: {
    marginRight: theme.getSpacing(3),
  },
  rightIconsContainer: {
    flexDirection: 'row',
    marginLeft: 20,
    paddingLeft: 20,
    borderLeftWidth: 1,
    borderLeftColor: 'rgba(255,255,255,0.5)',
    gap: theme.getSpacing(2),
    alignItems: 'center',
  },
  userMenuOptionContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    gap: theme.getSpacing(1),
  },
  userMenuOptionText: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.palette.gray[900],
  },
  helpAndSupportContainer: {
    flexDirection: 'row',
    gap: theme.getSpacing(0.5),
  },
}));

type HamburgerMenuOptionType = {
  path: string;
  text: string;
  isExternalPath?: boolean;
};

type UserMenuItemType = {
  path: string;
  screen?: string;
  icon: React.FunctionComponent;
  text: string;
  disabled: boolean;
};

export const PharmacyHeaderTestIDs = {
  settingsButton: 'pharmacy-settings-icon-button',
  menuButton: 'pharmacy-menu-icon-button',
  accountButton: 'pharmacy-account-icon-button',
};
