import React, { FunctionComponent } from 'react';
import { useNavigation } from '@react-navigation/native';
import { View } from 'react-native';
import { ActionBox, ActionBoxProps } from '../action-box/ActionBox';
import { TaskActionIcon } from '../icons/TaskActionIcon';
import { ScheduleActionIcon } from '../icons/ScheduleActionIcon';
import { ChatActionIcon } from '../icons/ChatActionIcon';
import { BulkActionIcon } from '../icons/BulkActionIcon';
import { PatientActionIcon } from '../icons/PatientActionIcon';
import { RootStackNavigationProp } from '../../../../navigation/RootNavigation';
import { getText } from 'assets/localization/localization';

export const ActionsWidget: FunctionComponent<ActionsWidgetProps> = () => {
  const navigation = useNavigation<RootStackNavigationProp>();

  const actions: Array<ActionBoxProps> = [
    {
      icon: TaskActionIcon,
      onPress: () =>
        navigation.navigate('tasks', {
          screen: 'list',
          params: {
            newTaskModal: 'open',
          },
        } as any),
      title: getText('dashboard-task'),
      tooltip: getText('create-a-new-task'),
    },
    {
      icon: ScheduleActionIcon,
      onPress: () =>
        navigation.navigate('schedule', { screen: 'new-appointment' } as any),
      title: getText('appt'),
      tooltip: getText('create-a-new-appt'),
    },
    {
      icon: ChatActionIcon,
      onPress: () =>
        navigation.navigate('messages', {
          screen: 'dashboard-chat',
          params: { newMessageModal: 'open' },
        } as any),
      title: getText('dashboard-chat'),
      tooltip: getText('create-a-new-chat'),
    },
    {
      icon: BulkActionIcon,
      onPress: () => alert('Not implemented yet'),
      title: getText('dashboard-bulk'),
      tooltip: getText('create-a-new-message'),
    },
    // In accordance with Jira issue BLUES-4662, we need to hide functionalities that have not yet been implemented.
    // {
    //   icon: PatientActionIcon,
    //   onPress: () =>
    //     navigation.navigate('patients', {
    //       newPatentModal: 'open',
    //     } as any),
    //   title: getText('patient'),
    // },
  ];

  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      {actions.map((x) => (
        <ActionBox {...x} key={x.title} />
      ))}
    </View>
  );
};

export interface ActionsWidgetProps {}
