import { makeStyles } from 'assets/theme';
import React from 'react';
import { View } from 'react-native';
import AlphabetFilter from './AlphabetFilter';
import CategoryFilter from './CategoryFilter';

const VideosFilter: React.FC = () => {
  const styles = useStyles();

  return (
    <View style={styles.sidebar}>
      <CategoryFilter />
      <AlphabetFilter />
    </View>
  );
};

export default VideosFilter;

const useStyles = makeStyles((theme) => ({
  sidebar: {
    backgroundColor: theme.palette.gray[50],
    margin: 0,
  },
}));
