import { IconProps, MultiColorIconProps } from 'assets/icons/types';
import { useTheme } from 'assets/theme';
import React, { FunctionComponent } from 'react';
import Svg, { Path } from 'react-native-svg';

export const MyTasksEmptyStateIcon: FunctionComponent<MultiColorIconProps> = ({
  color,
  accentColor,
  size,
  testID,
}) => {
  const theme = useTheme();
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 60 77"
      fill="none"
      testID={testID}
    >
      <Path
        fill={color ?? theme.palette.primary[600]}
        d="M32.304 26.504h-7.551a1.65 1.65 0 0 0 0 3.298h7.551a1.65 1.65 0 0 0 0-3.298ZM41.458 26.504h-3.471c-.91 0-1.65.74-1.65 1.649 0 .91.74 1.649 1.65 1.649h3.47c.91 0 1.65-.74 1.65-1.649 0-.91-.74-1.649-1.65-1.649ZM41.458 33.7H24.753a1.65 1.65 0 0 0 0 3.297h16.705c.91 0 1.65-.74 1.65-1.648 0-.91-.74-1.649-1.65-1.649ZM32.304 43.768h-7.551a1.65 1.65 0 0 0 0 3.298h7.551a1.65 1.65 0 0 0 0-3.298ZM24.75 50.968h7.48l-3.289 3.288h-4.192a1.644 1.644 0 1 1 0-3.288Z"
      />
      <Path
        fill={accentColor ?? theme.palette.gray[700]}
        d="M48.057 14.072h-9.212l.507 2.683a4.108 4.108 0 0 1 1.546 2.423h5.922v17.187l5.106-5.107V17.943c0-2.14-1.73-3.87-3.87-3.87ZM46.82 71.555H5.794V19.178h5.935a4.061 4.061 0 0 1 1.509-2.41l.507-2.696H4.558a3.868 3.868 0 0 0-3.87 3.87v54.85c0 2.139 1.73 3.87 3.87 3.87h43.499c2.139 0 3.87-1.731 3.87-3.87V53.217l-5.107 5.12v13.217Z"
      />
      <Path
        fill={accentColor ?? theme.palette.gray[700]}
        d="M24.6 2.894a2.498 2.498 0 0 1 1.707-.655c.112 0 .198.012.31.024a2.597 2.597 0 0 1 2.225 2.176 2.517 2.517 0 0 1-.717 2.176c-.927.965-1.224 2.362-.804 3.648l.247.767h-2.547l.272-.767c.408-1.236.062-2.658-.877-3.697a2.588 2.588 0 0 1-.693-1.755c0-.743.334-1.46.878-1.917Zm-9.36 18.732c.063.025.409.1.421.1h21.28c.012 0 .37-.075.432-.1a1.697 1.697 0 0 0 1.138-1.582c0-.94-.767-1.682-1.681-1.682h-.334l-1.027-5.391a2.375 2.375 0 0 0-2.349-1.941h-3.314l-.519-1.583c-.16-.544-.05-1.15.334-1.533 1.026-1.039 1.484-2.473 1.298-3.92-.272-2.04-1.99-3.71-4.043-3.957a4.661 4.661 0 0 0-3.673 1.138 4.608 4.608 0 0 0-1.57 3.475c0 1.186.445 2.312 1.261 3.165.433.47.582 1.088.396 1.632l-.507 1.583h-3.35a2.393 2.393 0 0 0-2.337 1.941l-1.014 5.39h-.297a1.675 1.675 0 0 0-.544 3.265ZM37.67 63.11l-.037.037-4.34 1.818-4.08-4.08 1.817-4.34.037-.038c.668.507 1.484.767 2.3.68a3.332 3.332 0 0 0 .965 2.659 3.331 3.331 0 0 0 2.658.964c-.086.816.173 1.632.68 2.3ZM26.247 67.929l5.53-2.323-3.208-3.208-2.322 5.531ZM65.095 35.684c.54-.54.54-1.415 0-1.955l-4.649-4.649a1.383 1.383 0 0 0-1.955 0l-3.851 3.851 6.604 6.604 3.85-3.85ZM59.605 41.175 38.758 62.022a1.781 1.781 0 0 1-.111-1.62.95.95 0 0 0-.21-1.051.926.926 0 0 0-1.051-.198 1.785 1.785 0 0 1-1.966-.383 1.791 1.791 0 0 1-.396-1.979.926.926 0 0 0-.197-1.05.942.942 0 0 0-1.064-.199 1.797 1.797 0 0 1-1.607-.123l20.847-20.86 6.602 6.616Z"
      />
      <Path
        fill={color ?? theme.palette.primary[600]}
        d="M20.165 45.333v7.35c0 .872-.706 1.577-1.566 1.577h-7.35c-.86 0-1.566-.705-1.566-1.577v-7.35c0-.86.706-1.566 1.566-1.566h7.35c.86 0 1.566.705 1.566 1.566ZM20.165 28.072v7.35c0 .872-.706 1.577-1.566 1.577h-7.35c-.86 0-1.566-.705-1.566-1.577v-7.35c0-.86.706-1.566 1.566-1.566h7.35c.86 0 1.566.705 1.566 1.566Z"
      />
    </Svg>
  );
};
