import { BookingDto } from '@digitalpharmacist/appointment-service-client-axios';
import {
  NavigationProp,
  ParamListBase,
  useNavigation,
} from '@react-navigation/native';
import { Text } from 'assets/components/text';
import { makeStyles } from 'assets/theme';
import React, { FunctionComponent, PropsWithChildren, useState } from 'react';
import { Pressable, View } from 'react-native';
import {
  DEFAULT_DATE_TIME_API_FORMAT,
  DEFAULT_PHARMACY_TIMEZONE,
  convertDateTimeFromUtcToPharmacyTimezone,
  formatUTCToRelative,
} from '../../../../common/datetime-utils';
import { UpcomingAppointmentsTestIDs } from '../../DashboardTestIDs';

export const AppointmentList: FunctionComponent<
  PropsWithChildren<AppointmentListProps>
> = ({ upcomingApp }) => {
  const navigation = useNavigation<NavigationProp<ParamListBase>>();
  const otherAppointments = upcomingApp.slice(1);
  const styles = useStyles();
  const [hoveredIndex, setHoveredIndex] = useState<string>('');

  const getStartTime = (time: string) => {
    return formatUTCToRelative(
      convertDateTimeFromUtcToPharmacyTimezone(
        time,
        DEFAULT_PHARMACY_TIMEZONE,
        DEFAULT_DATE_TIME_API_FORMAT,
      ),
      undefined,
      true,
      'US',
      undefined,
      undefined,
      true,
    );
  };

  const handleAppointmentItemClick = (id: string) => {
    navigation.navigate('schedule', {
      screen: 'appointments',
      params: { id },
    });
  };

  return (
    <>
      <View style={styles.listContainer}>
        {otherAppointments.map((appointment) => (
          <Pressable
            style={[
              styles.listItem,
              hoveredIndex === appointment.id && styles.hoveredItem,
            ]}
            key={appointment.id}
            onPress={() => handleAppointmentItemClick(appointment.id)}
            onHoverIn={() => setHoveredIndex(appointment.id)}
            onHoverOut={() => setHoveredIndex('')}
          >
            <View style={styles.title}>
              <Text
                style={[styles.listTitle, styles.groupType]}
                testID={UpcomingAppointmentsTestIDs.groupType}
              >
                {appointment.appointment_group_type}
              </Text>
              <Text
                style={[styles.listTitle, styles.time]}
                testID={UpcomingAppointmentsTestIDs.startTime}
              >
                {getStartTime(appointment.startTime)}
              </Text>
            </View>
            <Text
              style={styles.listSubtitle}
              testID={UpcomingAppointmentsTestIDs.patientName}
            >
              {appointment.patient_record_first_name}{' '}
              {appointment.patient_record_last_name}
            </Text>
          </Pressable>
        ))}
      </View>
    </>
  );
};

export type AppointmentListProps = {
  upcomingApp: BookingDto[];
};

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    gap: theme.getSpacing(0.5),
  },
  listContainer: {
    gap: theme.getSpacing(1.5),
  },
  listItem: {
    padding: theme.getSpacing(0.5),
  },
  hoveredItem: {
    backgroundColor: theme.palette.grayNeutral[100],
  },
  time: {
    paddingRight: theme.getSpacing(0.5),
  },
  listTitle: {
    fontSize: 14,
    lineHeight: 20,
    fontFamily: theme.fonts.regular.fontFamily,
    color: theme.palette.gray[700],
  },
  groupType: {
    maxWidth: '60%',
  },
  listSubtitle: {
    fontSize: 12,
    lineHeight: 18,
    fontFamily: theme.fonts.regular.fontFamily,
    color: theme.palette.gray[500],
  },
  upcomingContainer: {
    borderRadius: theme.roundness,
    backgroundColor: theme.palette.primary[600],
    padding: theme.getSpacing(1.5),
  },
}));
