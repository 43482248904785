import {
  ChangePasswordRequestDto,
  ConfirmPasswordDto,
  Ok,
  PatientUserDto,
  PharmacistUserLoginRequest,
  PharmacistUserLoginResponse,
  PharmacyUserDto,
  PharmacyUserInfo,
  RefreshTokenRequestDto,
  RegisterPharmacistUserRequest,
  RegisterPharmacistUserResponse,
  ResendPhoneNumberConfimationCodeRequestDto,
  ResetPasswordRequestDto,
  TermsAndConditionResponse,
  TokenResponseDto,
  UpdateUserRequest,
  UserLogin,
  UserLoginResponse,
  UserRegisterDto,
  UserVerificationRequestDto,
  GeneralApi as UsersServiceClient,
  VerifyPhoneNumberRequest,
  UpdatePharmacyUserRequestDto,
  HelpJuiceLinkDto,
} from '@digitalpharmacist/users-service-client-axios';
import { BaseApiConfiguration, BaseApiService } from 'assets/core/api';
import { AxiosRequestConfig } from 'axios';
import { AXIOS_DEFAULT_REQUEST_CONFIG } from '../common/axios-default-request-config';
import { USER_API_BASE_URL } from '../common/constants';

export interface IUsersService {
  logIn(
    pharmacistUserLogin: PharmacistUserLoginRequest,
  ): Promise<PharmacistUserLoginResponse>;
  getUser(userId: string): Promise<PatientUserDto>;
  getPharmacistUser(userId: string): Promise<PharmacyUserDto>;
  verifyPhoneNumber(
    verifyPhoneNumberRequest: VerifyPhoneNumberRequest,
  ): Promise<PatientUserDto>;
  resetPassword(values: ResetPasswordRequestDto): Promise<Ok>;
  confirmPassword(values: ConfirmPasswordDto): Promise<Ok>;
  registerPatient(values: UserLogin): Promise<PatientUserDto>;
  updatePatient(
    userId: string,
    updateUserRequest: UpdateUserRequest,
  ): Promise<PatientUserDto>;
  userPatientIdPrivacyAndPolicyPatch(
    id: string,
  ): Promise<TermsAndConditionResponse>;
  userPatientIdTermsAndConditionPatch(
    id: string,
  ): Promise<TermsAndConditionResponse>;
  userPatientAccountVerificationPost(body: object): Promise<UserLoginResponse>;
  userPatientResendConfirmationCodePost(
    userDetails?: UserVerificationRequestDto,
  ): Promise<void>;
  userPatientResendPhoneNumberConfirmationCodePost(
    patientId: string,
    resendPhoneNumberConfimationCodeRequestDto?: ResendPhoneNumberConfimationCodeRequestDto,
  ): Promise<void>;
  changePharmacistUserPassword(
    changePasswordRequestDto?: ChangePasswordRequestDto,
  ): Promise<void>;
  getPharmacistUsersByIds(ids: string[]): Promise<PharmacyUserDto[]>;
  resendPharmacistInviteEmail(email: string): Promise<void>;
}

export class UsersService extends BaseApiService implements IUsersService {
  private usersServiceClient: UsersServiceClient;

  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration,
  ) {
    super(baseUrl, config, enableAuth, baseConfig);
    this.usersServiceClient = new UsersServiceClient(
      undefined,
      baseUrl,
      this.axiosInstance,
    );
  }

  async resendPharmacistInviteEmail(email: string): Promise<void> {
    await this.usersServiceClient.userPharmacistEmailEmailResendPost(email);
  }

  async logIn(
    pharmacistUserLogin: PharmacistUserLoginRequest,
  ): Promise<PharmacistUserLoginResponse> {
    return (
      await this.usersServiceClient.userPharmacistLoginPost(
        pharmacistUserLogin,
        {
          disableAuth: true,
        },
      )
    ).data;
  }

  async verifyPhoneNumber(
    verifyPhoneNumberRequest: VerifyPhoneNumberRequest,
  ): Promise<PatientUserDto> {
    const { data } =
      await this.usersServiceClient.userPatientPhoneNumberVerificationPost(
        verifyPhoneNumberRequest,
      );
    return data;
  }

  async getUser(userId: string): Promise<PatientUserDto> {
    return (await this.usersServiceClient.userPatientUserIdGet(userId)).data;
  }

  async getPharmacistUser(userId: string): Promise<PharmacyUserDto> {
    return (await this.usersServiceClient.userPharmacistIdGet(userId)).data;
  }

  async resetPassword(values: ResetPasswordRequestDto): Promise<Ok> {
    const { data } =
      await this.usersServiceClient.userPatientResetPasswordPost(values);
    return data;
  }

  async confirmPassword(values: ConfirmPasswordDto): Promise<Ok> {
    const { data } =
      await this.usersServiceClient.userPatientConfirmPasswordPost(values);
    return data;
  }

  async registerPatient(values: UserRegisterDto): Promise<PatientUserDto> {
    return (await this.usersServiceClient.userPatientRegisterPost(values)).data;
  }

  async updatePatient(
    userId: string,
    updateUserRequest: UpdateUserRequest,
  ): Promise<PatientUserDto> {
    return (
      await this.usersServiceClient.userPatientUserIdPatch(
        userId,
        updateUserRequest,
      )
    ).data;
  }

  async updatePharmacyUser(
    userId: string,
    updateUserRequest: UpdatePharmacyUserRequestDto,
  ): Promise<UpdatePharmacyUserRequestDto> {
    return (
      await this.usersServiceClient.userPharmacistIdPatch(
        userId,
        updateUserRequest,
      )
    ).data;
  }

  async userPatientIdPrivacyAndPolicyPatch(
    id: string,
  ): Promise<TermsAndConditionResponse> {
    return (
      await this.usersServiceClient.userPatientIdPrivacyAndPolicyPatch(id)
    ).data;
  }

  async userPatientIdTermsAndConditionPatch(
    id: string,
  ): Promise<TermsAndConditionResponse> {
    return (
      await this.usersServiceClient.userPatientIdTermsAndConditionPatch(id)
    ).data;
  }

  async userPatientAccountVerificationPost(
    body: object,
  ): Promise<UserLoginResponse> {
    return (
      await this.usersServiceClient.userPatientAccountVerificationPost(body)
    ).data;
  }

  async userPatientResendConfirmationCodePost(
    userDetails?: UserVerificationRequestDto,
  ): Promise<void> {
    await this.usersServiceClient.userPatientResendConfirmationCodePost(
      userDetails,
    );
  }

  async userPatientResendPhoneNumberConfirmationCodePost(
    patientId: string,
    resendPhoneNumberConfimationCodeRequestDto?: ResendPhoneNumberConfimationCodeRequestDto,
  ): Promise<void> {
    await this.usersServiceClient.userPatientPatientIdResendPhoneNumberConfirmationCodePost(
      patientId,
      resendPhoneNumberConfimationCodeRequestDto,
    );
  }

  async userPharmacistFindById(userId: string): Promise<PharmacyUserInfo> {
    return (await this.usersServiceClient.userPharmacistIdGet(userId)).data;
  }

  async userPharmacistRegisterPost(
    registerPharmacistUserRequest: RegisterPharmacistUserRequest,
  ): Promise<RegisterPharmacistUserResponse> {
    return (
      await this.usersServiceClient.userPharmacistRegisterPost(
        true,
        registerPharmacistUserRequest,
      )
    ).data;
  }

  async refreshToken(
    refreshTokenRequest: RefreshTokenRequestDto,
  ): Promise<TokenResponseDto> {
    const { data } =
      await this.usersServiceClient.userPharmacistTokenRefreshPost(
        refreshTokenRequest,
        { disableAuth: true },
      );
    return data;
  }

  async changePharmacistUserPassword(
    changePasswordRequestDto?: ChangePasswordRequestDto,
  ): Promise<void> {
    await this.usersServiceClient.userPharmacistPasswordChangePost(
      changePasswordRequestDto,
    );
  }

  async getPharmacistUsersByIds(ids: string[]): Promise<PharmacyUserDto[]> {
    return (
      await this.usersServiceClient.userPharmacistIdListPost({
        userIdList: ids,
      })
    ).data;
  }

  async getSignedHelpjuiceLink(email: string): Promise<HelpJuiceLinkDto> {
    return (
      await this.usersServiceClient.userPharmacistSsoHelpjuiceRedirectUrlEmailGet(
        email,
      )
    ).data;
  }
}

export default new UsersService(
  USER_API_BASE_URL,
  AXIOS_DEFAULT_REQUEST_CONFIG,
  true,
);
