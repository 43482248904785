import { Text } from 'assets/components/text';
import { makeStyles } from 'assets/theme';

// `props: any` because @ag-grid-community/core specifies `any` type for argument for headerComponent
export const RecipientsListActionHeaderRenderer = (props: any) => {
  const styles = useStyles();

  return <Text style={styles.text}>{props?.displayName}</Text>;
};

const useStyles = makeStyles((theme) => ({
  text: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    weight: 700,
    ...theme.lumistryFonts.label.medium.bold,
  },
}));
