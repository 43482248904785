import { Alert } from 'assets/components/alert';
import { View } from 'react-native';
import { allergiesAreExplicitlyEmpty } from '../../screens/refill-submissions/medication-actions';
import { LocationPatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';
import { FC } from 'react';
import { getText } from 'assets/localization/localization';
import { ResourceItem } from '../../api/MedicationService';

interface AllergiesAlertProps {
  locationPatientRecordDto: LocationPatientRecordDto;
  allergies?: ResourceItem[];
}

export const AllergiesAlert: FC<AllergiesAlertProps> = ({
  locationPatientRecordDto,
  allergies,
}) => {
  if (!allergies) {
    return;
  }

  const getAllergiesAlert = ():
    | {
        alertType: 'info' | 'warning';
        alertText: string;
      }
    | undefined => {
    const allergiesShowAlert = allergiesAreExplicitlyEmpty(
      locationPatientRecordDto.allergies || [],
    );

    if (allergiesShowAlert) return undefined;

    const allergiesAlertType = !locationPatientRecordDto.allergies?.length
      ? 'info'
      : 'warning';
    const allergiesAlertText = !locationPatientRecordDto.allergies?.length
      ? getText('allergies-info-not-available')
      : `${getText('allergies-colon')} ${
          allergies.map((a) => a.text).join(', ') || ''
        }`;

    return {
      alertType: allergiesAlertType,
      alertText: allergiesAlertText,
    };
  };

  const allergiesAlert = getAllergiesAlert();

  if (!allergiesAlert) return <></>;

  return (
    <View>
      <Alert
        intent={allergiesAlert.alertType}
        title={allergiesAlert.alertText}
      />
    </View>
  );
};
