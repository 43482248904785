import { getText } from 'assets/localization/localization';
import { FunctionComponent } from 'react';
import { PatientAgreementModal } from '../../../../components/PatientAgreements/PatientAgreementModal';
import { setShowPatientAgreementModal } from '../app-settings-actions';
import { useAppSettingsState } from '../app-settings-store';

export const AgreementsModalWrap: FunctionComponent = () => {
  const { showPatientAgreementModal } = useAppSettingsState();

  return (
    <PatientAgreementModal
      show={showPatientAgreementModal}
      title={getText('edit-agreements')}
      handleCloseModal={() => setShowPatientAgreementModal(false)}
    />
  );
};
