import { IconProps } from 'assets/icons/types';
import React, { FunctionComponent } from 'react';
import Svg, { Path } from 'react-native-svg';

export const OBFileTextIcon: FunctionComponent<IconProps> = ({
  color,
  size = 100,
  testID,
}) => {
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      testID={testID}
      viewBox="0 0 100 100"
    >
      <Path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={5}
        d="M58.333 8.334H25a8.333 8.333 0 0 0-8.334 8.333v66.666A8.333 8.333 0 0 0 25 91.668h50a8.333 8.333 0 0 0 8.333-8.334v-50m-25-25 25 25m-25-25v25h25M66.667 54.168H33.332m33.334 16.666H33.332M41.667 37.5h-8.334"
      />
    </Svg>
  );
};
