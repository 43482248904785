import React, { FunctionComponent, useCallback, useState } from 'react';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { View } from 'react-native';
import { DataGrid } from 'assets/components/data-grid';
import { WidgetContainer } from '../widget-container/WidgetContainer';
import { useAppStateStore } from '../../../../store/app-store';
import PrescriptionService from '../../../../api/PrescriptionService';
import { RefillSubmittedDto } from '@digitalpharmacist/prescription-service-client-axios';
import { Icon } from 'assets/components/icon';
import { AlertCircleIcon } from 'assets/icons';
import { useTheme } from 'assets/theme';
import { getText } from 'assets/localization/localization';
import {
  FullNameRenderer,
  DateOfBirthRenderer,
  StatusFieldRenderer,
  DateFieldRenderer,
} from '../../../refills/Refills';
import { RootStackNavigationProp } from '../../../../navigation/RootNavigation';
import { calculateGridHeight } from '../../utils';

export const RefillNotPassedWidget: FunctionComponent<
  RefillNotPassedWidgetProps
> = () => {
  const MAX_ROW_NUMBERS = 5;
  const navigation = useNavigation<RootStackNavigationProp>();
  const theme = useTheme();
  const locationId = useAppStateStore((x) => x.locationId);
  const [data, setData] = useState<RefillSubmittedDto[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [moreItems, setMoreItem] = useState<number | undefined>();
  const [height, setHeight] = useState<number>(200);

  useFocusEffect(
    useCallback(() => {
      void getNotPassedRefills();
    }, [locationId]),
  );

  const getNotPassedRefills = async () => {
    // resetting state on location change
    try {
      setLoading(true);
      setMoreItem(undefined);
      setData([]);

      const { data } =
        await PrescriptionService.getLastNotPassedRefillForDashboard(
          locationId,
          MAX_ROW_NUMBERS,
        );
      setData(data.results);
      if (data.total > MAX_ROW_NUMBERS)
        setMoreItem(data.total - MAX_ROW_NUMBERS);

      if (data.results.length > 0)
        setHeight(calculateGridHeight(data.results.length));
      setLoading(false);
    } catch {
      // for the moment the logic is changed to show this card only when there are some data to be shown
      // show empty state in case of error
      // setLoading(false);
    }
  };

  // show only if there ara some data
  return data.length > 0 ? (
    <WidgetContainer
      icon={
        <Icon
          icon={AlertCircleIcon}
          size={24}
          color={theme.palette.error[500]}
        />
      }
      title={getText('refill-not-passed')}
      onNavigate={() => navigation.navigate('refills')}
      moreItems={moreItems}
      loading={loading}
    >
      <View
        style={{
          height: height,
        }}
      >
        <DataGrid
          gridOptions={{
            columnDefs: [
              {
                field: 'first_name',
                headerName: getText('patient'),
                cellRenderer: FullNameRenderer,
              },
              {
                field: 'patient_date_of_birth',
                headerName: getText('date-of-birth-short'),
                cellRenderer: DateOfBirthRenderer,
                cellStyle: { textTransform: 'capitalize' },
              },
              {
                field: 'rx_number',
                headerName: getText('rx-number'),
              },
              {
                field: 'pickup_method_name',
                headerName: getText('method'),
                cellStyle: { textTransform: 'capitalize' },
              },
              {
                field: 'refill_status',
                headerName: getText('status'),
                cellRenderer: StatusFieldRenderer,
              },
              {
                field: 'submitted_at',
                headerName: getText('date-submitted'),
                headerClass: 'date-submitted-header',
                cellRenderer: DateFieldRenderer,
                cellStyle: {
                  display: 'flex',
                  justifyContent: 'flex-end',
                },
              },
            ],
            rowData: data,
            enableCellTextSelection: true,
            suppressMovableColumns: true,
            suppressContextMenu: true,
            defaultColDef: { sortable: false, menuTabs: [] },
            rowSelection: 'single',
            suppressCellFocus: true,
            suppressRowClickSelection: true,
            colResizeDefault: 'shift',
            onRowClicked(event) {
              navigation.navigate('refills', {
                refillItemId: event.data.refill_item_id,
              } as any);
            },
            // onGridReady(event) {
            //   setGridApi(event.api);
            //   event.api.setDomLayout('autoHeight');
            // },
            rowModelType: 'clientSide',
          }}
        />
      </View>
    </WidgetContainer>
  ) : (
    <></>
  );
};

export interface RefillNotPassedWidgetProps {}
