import { useTheme } from 'assets/theme';
import { useStyles } from './styles';
import { View, Text, Pressable } from 'react-native';
import { Icon } from 'assets/components/icon';
import { PlusCircleIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';

export const SectionHeader = ({
  label,
  showPlusIcon,
  buttonText,
  showButton,
  onPressText,
}: {
  label: string;
  showPlusIcon: boolean;
  buttonText?: string;
  showButton?: boolean;
  onPressText?: () => void;
}) => {
  const styles = useStyles();
  const theme = useTheme();
  return (
    <View style={[styles.row, styles.sectionHeader]}>
      <Text style={styles.sectionLabelText}>{label.toUpperCase()}</Text>
      <Pressable style={[styles.sectionHeaderButton]} onPress={onPressText}>
        {buttonText && showButton && (
          <Text style={styles.sectionButtonText}>{getText('list')}</Text>
        )}
        {showPlusIcon && (
          <Icon
            icon={PlusCircleIcon}
            color={theme.palette.primary[600]}
            size={16}
          />
        )}
      </Pressable>
    </View>
  );
};
