import { LocationServiceOptionDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { Icon } from 'assets/components/icon';
import { Text } from 'assets/components/text';
import { ToggleSwitch } from 'assets/components/toggle-switch';
import { PencilIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { TouchableOpacity, View } from 'react-native';
import { boolToString } from '../../../../../utils';
import { useAppStateStore } from '../../../../store/app-store';
import {
  setShowModalTransferMedications,
  updateServiceOptions,
} from '../refills-settings-actions';

export const OptionsItemRender: FunctionComponent<
  PropsWithChildren<OptionsItemRenderProps>
> = ({ id, name, slug, type, options }) => {
  const styles = useStyles();
  const theme = useTheme();
  const { locationId } = useAppStateStore();
  const [toggle, setToggle] = useState(options[0].selected);

  const translatedName =
    slug === 'bottle-caps' ? getText('bottle-cap-preference') : name;

  useEffect(() => {
    setToggle(options[0].selected);
  }, [options]);

  const toggleService = () => {
    updateServiceOptions(locationId, [
      { slug: options[0].slug, selected: !toggle },
    ]);
    setToggle(!toggle);
  };

  return (
    <>
      <View style={styles.container}>
        <View style={styles.firstColumnContent}>
          <Text style={styles.title}>{translatedName}</Text>
        </View>
        {type === 'boolean' ? (
          <>
            <View style={{ flex: 3 }}>
              <Text style={styles.value}>{boolToString(toggle)}</Text>
            </View>
            <View style={{ alignContent: 'flex-end' }}>
              <ToggleSwitch
                logger={{
                  id: `locations-service-option-${id}`,
                }}
                value={toggle}
                onPress={toggleService}
              />
            </View>
          </>
        ) : (
          <>
            <View style={{ flex: 3 }}>
              <Text style={styles.value}>
                {options.find((option) => option.selected)?.name}
              </Text>
            </View>
            <View style={{ alignContent: 'flex-end' }}>
              <TouchableOpacity
                onPress={() => setShowModalTransferMedications(true)}
              >
                <Icon
                  icon={PencilIcon}
                  color={theme.palette.gray[700]}
                  size={16}
                />
              </TouchableOpacity>
            </View>
          </>
        )}
      </View>
    </>
  );
};

interface OptionsItemRenderProps {
  id: string;
  name: string;
  slug: string;
  type: string;
  options: LocationServiceOptionDto[];
}

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'row',
    gap: theme.getSpacing(2),
    alignItems: 'center',
    borderBottomColor: theme.palette.gray[200],
    borderBottomWidth: 1,
    padding: theme.getSpacing(1),
    marginBottom: theme.getSpacing(1),
  },
  firstColumnContent: {
    flex: 1,
    flexDirection: 'row',
  },
  title: {
    ...theme.fonts.regular,
    fontSize: 14,
    lineHeight: 16,
    color: theme.palette.gray[900],
    fontWeight: '600',
  },
  value: {
    ...theme.fonts.regular,
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[700],
  },
}));
