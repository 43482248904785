import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path, Polyline, Rect, Line } from 'react-native-svg';
import { IconProps } from './types';

export const BookmarkIcon: FunctionComponent<PropsWithChildren<IconProps>> = ({
  color,
  size,
  testID,
  strokeWidth,
}) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
      testID={testID}
    >
      <Path
        d="m19 21-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v16Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
