import { FunctionComponent, useState } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { makeStyles } from 'assets/theme';
import { ToggleSwitch } from 'assets/components/toggle-switch';
import { PencilIcon } from 'assets/icons';
import { LoadingOverlay } from '../../../../components/LoadingOverlay';
import {
  AutomationConfigurationDto,
  AutomationDto,
} from '@digitalpharmacist/tasks-service-client-axios';
import { AutoMessageConfigDto } from '@digitalpharmacist/unified-communications-service-client-axios';
import { PharmacyTooltipWrapper } from '../../../../common/PharmacyTooltipWrapper';

const AutoMessageConfig: FunctionComponent<AutoMessageType> = ({
  isLoading,
  title,
  image,
  description,
  id,
  isEditable,
  togglePressHandler,
  onPressHandler,
  showEditPen,
}) => {
  const styles = useStyles();

  const [isMessagingActive, setIsMessagingActive] =
    useState<boolean>(isEditable);

  const onTogglePressHandler = async (value: boolean) => {
    setIsMessagingActive(value);
    await togglePressHandler(value);
  };

  return (
    <View style={styles.container}>
      {isLoading && <LoadingOverlay />}
      <View style={styles.imageContainer}>{image}</View>
      <View style={styles.containerRight}>
        <View style={styles.titleContainer}>
          <PharmacyTooltipWrapper tooltipId={`automation-title-${id}`}>
            <Text
              style={styles.title}
              data-tooltip-id={`automation-title-${id}`}
            >
              {title}
            </Text>
          </PharmacyTooltipWrapper>

          <View style={styles.editButtonsContainer}>
            <View>
              <TouchableOpacity
                disabled={!showEditPen}
                onPress={onPressHandler}
                style={styles.editButton}
              >
                <PencilIcon size={16} />
              </TouchableOpacity>
            </View>
            <ToggleSwitch
              logger={{
                id: 'enable-automation-modal',
              }}
              value={isEditable}
              onPress={onTogglePressHandler}
            />
          </View>
        </View>
        <PharmacyTooltipWrapper tooltipId={`automation-description-${id}`}>
          <Text
            style={styles.description}
            data-tooltip-id={`automation-description-${id}`}
          >
            {/* We need to use{' '}
            <div dangerouslySetInnerHTML={{ __html: description }} /> here
            because the auto configuration message // is now being stored as
            HTML. This allows us to safely insert the HTML content into the DOM. */}
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </Text>
        </PharmacyTooltipWrapper>
      </View>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexBasis: '50%',
    width: '100%',
    flexDirection: 'row',
    marginBottom: theme.getSpacing(2),
    paddingRight: theme.getSpacing(2),
    height: 124,
  },
  imageContainer: {
    overflow: 'hidden',
    borderTopLeftRadius: theme.roundness,
    borderBottomLeftRadius: theme.roundness,
  },
  containerRight: {
    display: 'flex',
    flex: 1,
    padding: theme.getSpacing(2),
    borderWidth: 1,
    borderLeftWidth: 0,
    borderColor: theme.palette.gray[300],
    borderTopRightRadius: theme.roundness,
    borderBottomRightRadius: theme.roundness,
  },
  title: {
    ...theme.lumistryFonts.text.small.semiBold,
    color: theme.palette.gray[700],
    // We need this for the line-clamp functionality and we get a type error without this casting.
    display: '-webkit-box' as any as undefined,
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '2',
  },
  description: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.gray[400],
    display: '-webkit-box' as any as undefined,
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '2',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.getSpacing(1),
  },
  editButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: theme.getSpacing(1),
  },
  editButton: {
    marginRight: theme.getSpacing(1),
  },
}));

export type AutoMessageType = {
  automation: AutoMessageConfigDto | AutomationDto;
  isLoading: boolean;
  title: string;
  image: JSX.Element;
  description: string;
  configuration?: AutomationConfigurationDto;
  id: string;
  isEditable: boolean;
  togglePressHandler: (value: boolean) => Promise<void>;
  onPressHandler?: () => void;
  showToggle: boolean;
  showEditPen: boolean;
};

export default AutoMessageConfig;
