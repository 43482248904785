import { Text } from 'assets/components/text';
import { makeStyles } from 'assets/theme';
import { FunctionComponent, PropsWithChildren } from 'react';
import { View } from 'react-native';
import React from 'react';

export const MessagePlansItemRender: FunctionComponent<
  PropsWithChildren<BillingItemRenderProps>
> = ({ name, value, nonBillable }) => {
  const styles = useStyles();

  return (
    <View style={styles.container}>
      <View style={styles.firstColumnContent}>
        <Text style={styles.title}>{name}</Text>
      </View>
      <View style={{ flex: 3 }}>
        <Text style={styles.value}>
          {value}
          {nonBillable ? ' this month' : '/mo.'}
        </Text>
      </View>
    </View>
  );
};

interface BillingItemRenderProps {
  name: string;
  value: number;
  nonBillable: boolean;
}

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'row',
    gap: theme.getSpacing(2),
    alignItems: 'center',
    borderBottomColor: theme.palette.gray[200],
    borderBottomWidth: 1,
    padding: theme.getSpacing(1),
    marginBottom: theme.getSpacing(1),
  },
  firstColumnContent: {
    flex: 1,
    flexDirection: 'row',
  },
  title: {
    ...theme.fonts.regular,
    fontSize: 14,
    lineHeight: 16,
    color: theme.palette.gray[900],
    fontWeight: '600',
  },
  value: {
    ...theme.fonts.regular,
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[700],
  },
}));
