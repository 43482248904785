import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Svg, Path, Rect } from 'react-native-svg';
import Group from 'react-native-svg';
import { IconProps } from 'assets/icons/types';

export const FormPreviewTemplateIcon: FunctionComponent<
  PropsWithChildren<IconProps>
> = ({ color, size, testID, strokeWidth }) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 300 388"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
      testID={testID}
    >
      <Group fill="none" fillRule="evenodd">
        <Rect
          fill="#fff"
          fillRule="nonzero"
          height={16.657}
          rx={6}
          stroke="#9cafb9"
          width={109.314}
          x={20.588}
          y={130.313}
        />
        <Rect
          fill="#fff"
          fillRule="nonzero"
          height={16.657}
          rx={6}
          stroke="#9cafb9"
          width={109.314}
          x={139.706}
          y={130.313}
        />
        <Path d="M21.568 166.565h35.784v7.348H21.568z" fill="#bcc8ce" />
        <Rect
          fill="#fff"
          fillRule="nonzero"
          height={16.657}
          rx={6}
          stroke="#9cafb9"
          width={257.353}
          x={21.078}
          y={181.752}
        />
        <Path d="M21.568 218.005h122.059v7.348H21.568z" fill="#bcc8ce" />
        <Rect
          fill="#fff"
          fillRule="nonzero"
          height={51.439}
          rx={6}
          stroke="#9cafb9"
          width={257.843}
          x={21.078}
          y={233.192}
        />
        <Rect
          fill="#0093e8"
          fillRule="nonzero"
          height={19.596}
          rx={6}
          width={72.059}
          x={206.862}
          y={344.154}
        />
        <Path
          d="m21.323 324.558 257.598-.245"
          stroke="#000"
          strokeLinecap="round"
          strokeOpacity={0.2}
          strokeWidth={strokeWidth}
        />
      </Group>
    </Svg>
  );
};
