import React from 'react';
import { DefaultTooltipConfig } from '../config';
import { TooltipConfig } from '../types';

export type TooltipContextType = {
  appTooltipConfig: TooltipConfig;
  setTooltipConfig: (override: Partial<TooltipConfig>) => void;
};

export const TooltipContext = React.createContext<TooltipContextType>({
  appTooltipConfig: DefaultTooltipConfig,
  setTooltipConfig: () => {},
});

export const TooltipConsumer = TooltipContext.Consumer;
