import { Alert } from 'assets/components/alert';
import {
  CheckboxField,
  CheckboxInput,
  CheckboxInputMode,
} from 'assets/components/checkbox';
import { Text } from 'assets/components/text';
import { MinusCircleIcon, PlusCircleIcon } from 'assets/icons';
import { Form } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import React, {
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { Pressable, View } from 'react-native';
import {
  checkHoursFormValidity,
  chunkArray,
  mapToHoursFormType,
  updatePharmacyStoreHours,
} from '../../../../actions/onboarding-actions';
import { zIndexAuto } from '../../../../common/theme';
import { TimeRangeInput } from '../../../../schedule/time-range-input/TimeRangeInput';
import {
  HourType,
  LocationHolidayType,
  LocationHourType,
} from '../../../../store/onboarding-store';
import {
  FORM_NOT_VALID,
  getDayAsStr,
  newBreakDefaultData,
  selectAllData,
} from '../../utils';
import { OBFormHandler, OBFormProps } from '.';
import { Icon } from 'assets/components/icon';

export type HoursFormType = {
  hours: LocationHourType[];
  holidays: LocationHolidayType[];
  location_use_default_hours?: boolean;
  location_use_default_holidays?: boolean;
};

export interface OBHoursFormProps extends OBFormProps {
  applyToAllLocations?: boolean;
}

export const OBHoursForm = forwardRef<OBFormHandler, OBHoursFormProps>(
  (
    { location, applyToAllLocations }: OBHoursFormProps,
    ref: ForwardedRef<OBFormHandler>,
  ) => {
    const styles = useStyles();
    const theme = useTheme();
    const [hasError, setHasError] = useState<boolean>(false);
    const {
      location_holidays,
      location_hours,
      id,
      location_use_default_hours,
      location_use_default_holidays,
    } = location;
    const initialHours = mapToHoursFormType(location_hours);

    const methods = useForm<HoursFormType>({
      defaultValues: {
        location_use_default_hours,
        location_use_default_holidays,
        hours: initialHours,
        holidays: location_holidays,
      },
    });

    const holidayFields = methods.watch('holidays');
    const defaultHours = methods.watch('location_use_default_hours');
    const defaultHolidays = methods.watch('location_use_default_holidays');
    const hours = methods.watch('hours');
    const {
      fields: hourFields,
      remove,
      insert,
      update,
    } = useFieldArray({
      control: methods.control,
      name: 'hours',
    });

    const shouldAddOrRemoveBreaks = (field: LocationHourType) => {
      const totalBreaks = field.totalBreaks ?? 0;
      return !(field.type === HourType.OPEN && totalBreaks >= 5);
    };

    const addOrRemoveBreak = (field: LocationHourType, index: number) => {
      const isBreak = field.type === HourType.BREAK;
      const breakAdjustment = isBreak ? -1 : 1;
      const openHourIndex = hours.findIndex(
        (x) => x.day === field.day && x.type === HourType.OPEN,
      );

      if (isBreak) {
        remove(index);
      } else {
        const newIndex = hours.reduce(
          (lastIndex, item, index) =>
            item.day === field.day ? index : lastIndex,
          -1,
        );
        insert(newIndex + 1, { ...field, ...newBreakDefaultData });
      }

      if (openHourIndex > -1) {
        const openHour = hours[openHourIndex];
        update(openHourIndex, {
          ...openHour,
          totalBreaks: (openHour.totalBreaks ?? 0) + breakAdjustment,
        });
      }
    };

    const updateDay = (
      field: LocationHourType,
      index: number,
      isChecked: boolean,
    ) => {
      // remove all breaks for the closed day
      if (!isChecked) {
        const indexesToRemove: number[] = [];
        hourFields.map((h, index) => {
          if (h.day === field.day && h.type === HourType.BREAK) {
            indexesToRemove.push(index);
          }
        });
        remove(indexesToRemove);
      }

      // close or open the day
      update(index, {
        ...field,
        type: isChecked ? HourType.OPEN : HourType.CLOSED,
        text: isChecked
          ? getText('locations-departments-add-hours')
          : getText('closed'),
        totalBreaks: 0,
      });
    };

    const handleSelectAllHolidays = (checked: boolean) => {
      methods.setValue(
        'holidays',
        holidayFields.map((h) => ({
          ...h,
          enabled: checked,
        })),
      );
    };

    const handleOnSubmit = async () => {
      await methods.handleSubmit(
        async (values) => {
          const isInvalidForm = checkHoursFormValidity(values);
          setHasError(isInvalidForm);
          if (isInvalidForm) throw FORM_NOT_VALID;
          updatePharmacyStoreHours(id, values, applyToAllLocations);
        },
        () => {
          // throw error to prevent go next
          throw FORM_NOT_VALID;
        },
      )();
    };

    useImperativeHandle(ref, () => ({
      handleSubmit: handleOnSubmit,
    }));
    return (
      <Form methods={methods}>
        {hasError && (
          <Form.Row>
            <Form.Column>
              <Alert title={getText('time-overlapped')} intent="error" />
            </Form.Column>
          </Form.Row>
        )}
        <Form.Row>
          <Form.Column>
            <CheckboxField
              name="location_use_default_hours"
              label={getText('use-defaults')}
              mode={CheckboxInputMode.FLAT}
              onPress={(ch) => {
                if (!ch) methods.setValue('hours', hours);
              }}
            />
          </Form.Column>
        </Form.Row>
        {hourFields.map((field, index) => (
          <Form.Row key={field.id} style={{ zIndex: zIndexAuto }}>
            <Form.Column style={{ zIndex: zIndexAuto }}>
              <View style={styles.timeContainer}>
                <View style={styles.checkboxContainer}>
                  {field.type !== HourType.BREAK && (
                    <CheckboxInput
                      disabled={defaultHours}
                      checked={field.type === HourType.OPEN}
                      onPress={(isChecked) => {
                        updateDay(field, index, isChecked);
                      }}
                      label={getDayAsStr(field.day)}
                    />
                  )}
                </View>
                <View style={styles.actionContainer}>
                  <View style={styles.timeRange}>
                    <TimeRangeInput
                      name={`hours.${index}.time`}
                      disabled={field.type === HourType.CLOSED || defaultHours}
                      startTimeRules={{
                        required: getText('start-time-required'),
                      }}
                      endTimeRules={{
                        required: getText('end-time-required'),
                      }}
                    />
                  </View>
                  <View>
                    {field.type !== HourType.CLOSED ? (
                      <>
                        {shouldAddOrRemoveBreaks(field) && (
                          <Pressable
                            disabled={defaultHours}
                            style={styles.text}
                            onPress={() => addOrRemoveBreak(field, index)}
                          >
                            {field.type === HourType.BREAK ? (
                              <Icon
                                size={16}
                                color={theme.palette.primary[600]}
                                icon={MinusCircleIcon}
                              />
                            ) : (
                              <Icon
                                size={16}
                                color={theme.palette.primary[600]}
                                icon={PlusCircleIcon}
                              />
                            )}
                            <Text style={styles.breakText}>{field.text}</Text>
                          </Pressable>
                        )}
                      </>
                    ) : (
                      <Text style={styles.closedText}>{field.text}</Text>
                    )}
                  </View>
                </View>
              </View>
            </Form.Column>
          </Form.Row>
        ))}
        <Form.Row>
          <Form.Column>
            <Text style={styles.holidayTitle}>{getText('holidays')}</Text>
            <Text style={styles.holidaySubtitle}>
              {getText('additional-closures-may-be-added')}
            </Text>
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <CheckboxField
              name="location_use_default_holidays"
              label={getText('use-defaults')}
              mode={CheckboxInputMode.FLAT}
            />
          </Form.Column>
        </Form.Row>
        <View style={styles.holidays}>
          {chunkArray([selectAllData, ...holidayFields]).map((group, index) => (
            <Form.Row key={index}>
              {group.map((holiday, i) => (
                <Form.Column key={holiday.id}>
                  {holiday.isSelect ? (
                    <CheckboxInput
                      disabled={defaultHolidays}
                      label={getText('select-all')}
                      onPress={handleSelectAllHolidays}
                      checked={holidayFields.every((i) => i.enabled)}
                      mode={CheckboxInputMode.FLAT}
                    />
                  ) : (
                    <CheckboxField
                      disabled={defaultHolidays}
                      name={`holidays.${
                        Math.floor(holidayFields.length / 2) * i + index - 1 + i
                      }.enabled`}
                      label={holiday.name}
                      mode={CheckboxInputMode.FLAT}
                    />
                  )}
                </Form.Column>
              ))}
            </Form.Row>
          ))}
        </View>
      </Form>
    );
  },
);

const useStyles = makeStyles((theme) => ({
  timeContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 150,
    zIndex: zIndexAuto,
  },
  checkboxContainer: { width: 30 },
  actionContainer: {
    flex: 1,
    zIndex: zIndexAuto,
    flexDirection: 'row',
    gap: theme.getSpacing(2),
    alignItems: 'center',
  },
  timeRange: {
    zIndex: zIndexAuto,
    width: 300,
  },
  text: {
    flexDirection: 'row',
    gap: theme.getSpacing(1),
    alignItems: 'center',
  },
  breakText: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.primary[600],
  },
  closedText: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.gray[500],
  },
  holidays: {
    marginTop: theme.getSpacing(4),
  },
  holidayTitle: {
    ...theme.lumistryFonts.text.medium.semiBold,
    color: theme.palette.gray[900],
  },
  holidaySubtitle: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.gray[700],
  },
}));
