import { Text } from 'assets/components/text';
import { makeStyles } from 'assets/theme';
import { set } from 'date-fns';
import React, {
  ChangeEvent,
  FocusEvent,
  InputHTMLAttributes,
  KeyboardEvent,
  forwardRef,
  useEffect,
  useState,
} from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { ControllerRenderProps, FieldValues } from 'react-hook-form';
import {
  timePickerCharactersPattern,
  timePickerPattern,
  timePickerSpecialKeys,
} from '../../utils';

interface CustomTimeInputProps extends InputHTMLAttributes<HTMLInputElement> {
  field: ControllerRenderProps<FieldValues, string>;
  setKey: React.Dispatch<React.SetStateAction<number>>;
}

export const CustomTimeInput = forwardRef<
  HTMLInputElement,
  CustomTimeInputProps
>(
  (
    { field, placeholder, setKey, onKeyDown, onFocus, onChange, ...props },
    ref,
  ) => {
    const [inputValue, setInputValue] = useState(field.value);
    const styles = useStyles();

    useEffect(() => {
      const scrollToSelectedTime = () => {
        const dropdown = document.querySelector('.react-datepicker__time-list');
        if (dropdown) {
          const selectedTime = dropdown.querySelector(
            '.react-datepicker__time-list-item--selected',
          );
          selectedTime?.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
          });
        }
      };

      scrollToSelectedTime();
    }, [inputValue]);

    const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
      // Select all when focus starts
      event.target.select();
      onFocus?.(event);
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
      if (
        !timePickerCharactersPattern.test(event.key) &&
        !timePickerSpecialKeys.includes(event.key)
      ) {
        event.preventDefault();
        return;
      }
      // Up and down arrows will change the selection.
      onKeyDown?.(event);
      const step = 15;
      const selectedDate = new Date(field.value);
      setInputValue(selectedDate);
      const minutes = selectedDate.getMinutes();

      switch (event.key) {
        case 'ArrowUp':
          selectedDate.setMinutes(minutes - step);
          field.onChange(selectedDate);
          break;
        case 'ArrowDown':
          selectedDate.setMinutes(minutes + step);
          field.onChange(selectedDate);
          break;
        case 'Tab':
        case 'Enter':
          event.preventDefault();
          event.currentTarget.blur();
          setKey((prev) => prev + 1);
          break;
      }
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
      onChange?.(e);
      const value = e.target.value.trim();
      const match = value.match(timePickerPattern);
      setInputValue(value);

      if (match) {
        const digits = match[1];
        const period = match[2];
        let hours: number;
        let minutes: number;
        let isValidTime = false;
        let newDate = new Date(field.value) || new Date();

        switch (digits.length) {
          case 1: // e.g., '5' -> '5:00'
            hours = parseInt(digits, 10);
            minutes = 0;
            isValidTime = true;
            break;
          case 2: // e.g., '11' -> '11:00' or '71' -> '7:15'
            hours = parseInt(digits, 10);
            minutes = 0;
            isValidTime = true;
            if (hours > 12) {
              hours = parseInt(digits[0], 10);
              minutes = parseInt(digits[1], 10) * 10;
              const validIntervals = [0, 15, 30, 45];
              if (validIntervals.includes(minutes + 5)) {
                minutes += 5;
              }
              isValidTime = minutes >= 0 && minutes < 60;
            }
            break;
          case 3: // e.g., '512' -> '5:12'
            hours = parseInt(digits[0], 10);
            minutes = parseInt(digits.slice(1), 10);
            isValidTime = minutes >= 0 && minutes < 60;
            break;
          case 4: // e.g., '1234' -> '12:34'
            hours = parseInt(digits.slice(0, 2), 10);
            minutes = parseInt(digits.slice(2), 10);
            isValidTime = minutes >= 0 && minutes < 60;
            break;
          default:
            return;
        }

        if (hours >= 1 && hours <= 12 && isValidTime) {
          newDate = set(newDate, { hours, minutes });
          if (period) {
            newDate = setAMPM(newDate, period);
          }
          field.onChange(newDate);
        }
      }
    };

    const setAMPM = (date: Date, period: string) => {
      const hours = date.getHours();
      const newPeriod = period.toLocaleLowerCase();
      if (newPeriod.includes('p') && hours < 12) {
        return set(date, { hours: hours + 12 });
      }
      if (newPeriod.includes('a') && hours === 12) {
        return set(date, { hours: 0 });
      }
      return date;
    };

    return (
      <>
        <input
          ref={ref}
          type="text"
          {...props}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
        />
        <Text style={styles.timepickerPlaceholder}>{placeholder}</Text>
      </>
    );
  },
);

const useStyles = makeStyles((theme) => ({
  timepickerPlaceholder: {
    ...theme.lumistryFonts.label.xxSmall,
    color: theme.palette.gray[600],
    position: 'absolute',
    left: 50,
    paddingTop: 8,
  },
}));
