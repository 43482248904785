import { makeStyles } from 'assets/theme';
import React, { ReactNode } from 'react';
import { View, ViewStyle } from 'react-native';
import { Text } from 'assets/components/text';

export default function NoResultsOverlay({
  title,
  subtitle,
  icon,
  addMargin,
  additionalContent,
  layout = 'horizontal',
  containerCustomStyles,
}: NoResultsOverlayProps) {
  const styles = useStyles();

  return (
    <View
      style={[
        styles.container,
        layout === 'vertical' && styles.containerVertical,
        containerCustomStyles ? containerCustomStyles : {},
      ]}
    >
      <View
        style={[
          layout === 'horizontal'
            ? styles.iconContainerHorizontal
            : styles.iconContainerVertical,
          addMargin && styles.iconMargin,
        ]}
      >
        {icon}
      </View>
      <View style={styles.textContainer}>
        <Text style={styles.title} selectable>
          {title}
        </Text>
        {subtitle && (
          <Text style={styles.subTitle} selectable>
            {subtitle}
          </Text>
        )}
        {additionalContent}
      </View>
    </View>
  );
}

interface NoResultsOverlayProps {
  title: string;
  subtitle?: string;
  icon: ReactNode;
  addMargin?: boolean;
  layout?: 'horizontal' | 'vertical';
  additionalContent?: ReactNode;
  containerCustomStyles?: ViewStyle;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  containerVertical: {
    flexDirection: 'column',
  },
  textContainer: {
    maxWidth: 500,
  },
  title: {
    fontSize: 25,
    fontWeight: '700',
    paddingBottom: theme.getSpacing(2),
  },
  subTitle: {
    color: theme.palette.gray[500],
    fontSize: 16,
    fontWeight: '400',
  },
  iconContainerHorizontal: {
    marginRight: theme.getSpacing(1),
  },
  iconContainerVertical: {
    marginBottom: theme.getSpacing(1),
  },
  iconMargin: {
    marginRight: theme.getSpacing(4),
  },
}));
