import React, { FunctionComponent, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { DataGrid } from 'assets/components/data-grid';
import { WidgetContainer } from '../widget-container/WidgetContainer';
import { useAppStateStore } from '../../../../store/app-store';
import { RefillSubmittedDto } from '@digitalpharmacist/prescription-service-client-axios';
import { Icon } from 'assets/components/icon';
import { CheckCircleIcon } from 'assets/icons';
import { useTheme } from 'assets/theme';
import { getText } from 'assets/localization/localization';
import { RootStackNavigationProp } from '../../../../navigation/RootNavigation';
import {
  ActionButtonsRenderer,
  AssignedRenderer,
  STATUS_MENU_ID,
  SummaryRenderer,
  TimeRenderer,
} from '../../../../tasks/tasks-data-table/TasksDataTable';
import TaskService from '../../../../api/TaskService';
import { useUserState } from '../../../../store/user-store';
import { TaskStatusContextMenu } from '../../../../tasks/tasks-data-table/TaskStatusContextMenu';
import { useTasksGridState } from '../../../../tasks/tasks-grid/tasks-grid-store';
import { setReloadTasksGrid } from '../../../../tasks/tasks-grid/tasks-grid-actions';
import { calculateGridHeight } from '../../utils';
import { MyTasksEmptyStateIcon } from '../icons/MyTasksEmptyStateIcon';
import { EmptyState } from '../empty-state/EmptyState';

export const MyTasksWidget: FunctionComponent<MyTasksWidgetProps> = () => {
  const MAX_ROW_NUMBERS = 10;
  const navigation = useNavigation<RootStackNavigationProp>();
  const theme = useTheme();
  const locationId = useAppStateStore((x) => x.locationId);
  const userId = useUserState((x) => x.data?.id);
  const [data, setData] = useState<RefillSubmittedDto[] | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const [moreItems, setMoreItem] = useState<number | undefined>();
  const [height, setHeight] = useState<number>(200);
  const reload = useTasksGridState((x) => x.reload);

  const loadData = async () => {
    try {
      // resetting state on location change
      setLoading(true);
      setMoreItem(undefined);
      setData(undefined);

      const { data } = await TaskService.getMyTasksForDashboard(
        locationId,
        userId!,
        MAX_ROW_NUMBERS,
      );
      setData(data.results);
      if (data.total > MAX_ROW_NUMBERS)
        setMoreItem(data.total - MAX_ROW_NUMBERS);
      if (data.results.length > 0)
        setHeight(calculateGridHeight(data.results.length));
      setLoading(false);
      setReloadTasksGrid(false);
    } catch {
      setLoading(false);
    }
  };

  useEffect(() => {
    void loadData();
  }, [locationId, userId]);

  useEffect(() => {
    if (reload) void loadData();
  }, [reload]);

  return (
    <WidgetContainer
      icon={
        <Icon
          icon={CheckCircleIcon}
          size={24}
          color={theme.palette.gray[700]}
        />
      }
      title={getText('my-tasks')}
      onNavigate={() =>
        navigation.navigate('tasks', {
          screen: 'list',
          params: {
            tasksFilter: 'my-tasks',
          },
        } as any)
      }
      moreItems={moreItems}
      loading={loading}
    >
      {data && data.length > 0 ? (
        <View
          style={{
            height: height,
          }}
        >
          <TaskStatusContextMenu menuId={STATUS_MENU_ID} />
          <DataGrid
            isRichContent={true}
            gridOptions={{
              columnDefs: [
                {
                  width: 180,
                  maxWidth: 180,
                  colId: getText('status'),
                  headerName: getText('status'),
                  cellRenderer: ActionButtonsRenderer,
                  cellRendererParams: {
                    hideCheckbox: true,
                  },
                  cellStyle: {
                    paddingLeft: 12,
                    display: 'flex',
                    alignItems: 'center',
                  },
                },
                {
                  width: 200,
                  maxWidth: 200,
                  colId: 'assigned_user_first_name,assigned_user_last_name',
                  headerName: getText('assigned-to'),
                  cellRenderer: AssignedRenderer,
                  cellStyle: {
                    display: 'flex',
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  },
                  cellClass: 'data-grid-cell-wrapper-overflow',
                },
                {
                  headerName: getText('summary'),
                  cellRenderer: SummaryRenderer,
                  cellStyle: {
                    display: 'flex',
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  },
                  cellClass: 'data-grid-cell-wrapper-overflow',
                },
                {
                  width: 200,
                  maxWidth: 200,
                  headerName: getText('due-date'),
                  cellStyle: {
                    display: 'flex',
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  },
                  colId: 'due_date',
                  headerClass: 'data-grid-header-right-aligned',
                  cellClass: 'data-grid-cell-wrapper-overflow',
                  cellRenderer: TimeRenderer,
                  cellRendererParams: {
                    hideContextMenu: true,
                  },
                },
              ],
              rowData: data,
              enableCellTextSelection: true,
              suppressRowClickSelection: true,
              suppressMovableColumns: true,
              suppressContextMenu: true,
              defaultColDef: { sortable: false, menuTabs: [] },
              rowModelType: 'clientSide',
              getRowId(params) {
                return params.data?.id;
              },
              onCellClicked(event) {
                if (event.colDef.colId === getText('status')) return;
                navigation.navigate('tasks', {
                  screen: 'list',
                  params: {
                    tasksFilter: 'my-tasks',
                    taskId: event.data.id,
                  },
                } as any);
              },
              onGridSizeChanged(event) {
                event.api.sizeColumnsToFit();
              },
              rowClassRules: {
                'task-row': () => true,
              },
            }}
          />
        </View>
      ) : (
        <EmptyState
          Icon={MyTasksEmptyStateIcon}
          iconProps={{ size: 75, color: theme.palette.primary[600] }}
          text={getText('tasks-completed')}
        />
      )}
    </WidgetContainer>
  );
};

export interface MyTasksWidgetProps {}
