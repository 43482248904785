import { DepartmentTimeRangeDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { useRoute } from '@react-navigation/native';
import { IconButton } from 'assets/components/icon-button';
import { Modal } from 'assets/components/modal';
import { Text } from 'assets/components/text';
import { PencilIcon, TrashWithStripesIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import { FunctionComponent, PropsWithChildren, useState } from 'react';
import { View } from 'react-native';
import { convertTimeRangeToStringRange } from '../../../../common/datetime-utils';
import { SettingsTooltipsComponents } from '../../../../components/Settings/SettingsTooltipsComponents';
import { LocationsDrawerRouteProp } from '../LocationsSettingsDrawer';
import {
  getDepartmentsLabel,
  isPharmacyDepartment,
  setEditingLocationNormalHours,
  setShowModalLocationNormalHours,
  updateLocationNormalHours,
} from '../location-settings-actions';
import { DaysOfWeekEnum } from '../location-settings-store';

export const LocationNormalHoursItemRenderer: FunctionComponent<
  PropsWithChildren<DepartmentTimeRangeDto>
> = ({ department_id, department_name, use_default_hours, time_ranges }) => {
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);
  const [loadingMethod, setLoadingMethod] = useState(false);
  const styles = useStyles();
  const theme = useTheme();
  const route = useRoute<LocationsDrawerRouteProp>();
  const locationId = route.params?.locationId;

  const handleDelete = async (id: string) => {
    setLoadingMethod(true);
    await updateLocationNormalHours(locationId || '', id, {
      use_default_hours: true,
    });
    setLoadingMethod(false);
    setShowCloseConfirmation(false);
  };

  return (
    <>
      <View style={styles.viewStyle}>
        <View style={styles.textView}>
          <Text style={styles.textStyle}>
            {getDepartmentsLabel(department_name)}
          </Text>
          {isPharmacyDepartment(department_id) && (
            <SettingsTooltipsComponents
              tooltipId="location-pharmacy-department"
              tooltipText={getText('location-pharmacy-department-tooltip')}
            />
          )}
        </View>
        <View style={styles.weekViewStyle}>
          {Array.from({ length: 7 }).map((_, index) => {
            const dayData = time_ranges.filter(
              (item) => item.day === index + 1,
            );
            const dayOfWeek = DaysOfWeekEnum[index + 1];

            if (dayData.length === 0) {
              return (
                <View style={styles.dayViewStyle} key={+index}>
                  <View style={{ flex: 1 }}>
                    <Text style={styles.dayTextStyle}>{dayOfWeek}</Text>
                  </View>
                  <View style={{ flex: 3 }}>
                    <Text style={styles.dayTextStyle}>{getText('closed')}</Text>
                  </View>
                </View>
              );
            }

            return (
              <View style={styles.dayViewStyle} key={+index}>
                <View style={{ flex: 1 }}>
                  <Text style={styles.dayTextStyle}>{dayOfWeek}</Text>
                </View>
                <View style={{ flex: 3 }}>
                  {dayData.map((timeRange) => {
                    return (
                      <Text
                        style={styles.dayTextStyle}
                        key={+index + timeRange.id}
                      >
                        {convertTimeRangeToStringRange(timeRange)}
                      </Text>
                    );
                  })}
                </View>
              </View>
            );
          })}
        </View>
        <View style={styles.buttonView}>
          <IconButton
            icon={TrashWithStripesIcon}
            onPress={() => {
              setShowCloseConfirmation(true);
            }}
            size={16}
            color={theme.palette.gray[700]}
            logger={{ id: 'task-type-renderer-action-button-delete' }}
          />
          <IconButton
            icon={PencilIcon}
            onPress={() => {
              setEditingLocationNormalHours(department_id);
              setShowModalLocationNormalHours(true);
            }}
            size={16}
            color={theme.palette.gray[700]}
            logger={{ id: 'task-type-renderer-action-button-edit' }}
          />
          <Text style={styles.topBarText}>
            {use_default_hours && getText('locations-default')}
          </Text>
        </View>
        <Modal
          title={getText('pharmacy-delete-department')}
          cancelButtonProps={{
            onPress: () => {
              setShowCloseConfirmation(false);
            },
            logger: { id: 'confirmation-cancel-button' },
          }}
          okButtonProps={{
            onPress: () => handleDelete(department_id),
            logger: { id: 'confirmation-accept-button' },
            hierarchy: 'primary',
            loading: loadingMethod,
          }}
          show={showCloseConfirmation}
          size="sm"
        ></Modal>
      </View>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  viewStyle: {
    flexDirection: 'row',
    gap: theme.getSpacing(2),
    alignItems: 'flex-start',
    borderBottomColor: theme.palette.gray[200],
    borderBottomWidth: 1,
    padding: theme.getSpacing(1),
    marginBottom: theme.getSpacing(1),
  },
  textView: {
    flex: 1,
    flexDirection: 'row',
  },
  buttonView: {
    width: 120,
    flexDirection: 'row-reverse',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  textStyle: {
    ...theme.fonts.regular,
    fontSize: 14,
    lineHeight: 16,
    color: theme.palette.gray[900],
    fontWeight: '600',
  },
  weekViewStyle: {
    alignItems: 'flex-start',
    textAlign: 'left',
    flex: 4,
  },
  dayTextStyle: {
    flexDirection: 'row',
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.palette.gray[700],
  },
  dayViewStyle: {
    flexDirection: 'row',
    width: '100%',
  },
  topBarText: {
    color: theme.palette.gray[700],
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 20,
  },
}));
