import create from 'zustand';
import {
  CONVERSATION_PATIENTS_PAGINATION_STEP,
  sortingOptions,
  textFilters,
} from '../../data';
import {
  BaseFilterValues,
  ConversationsManipulationState,
} from 'assets/types/messageTypes';

export const initialState: ConversationsManipulationState = {
  pagination: {
    limit: CONVERSATION_PATIENTS_PAGINATION_STEP,
  },
  baseFilter: BaseFilterValues.ALL,
  multiFilters: [],
  textFilters: textFilters,
  sorting: sortingOptions[0],
};

export const useConversationsManipulationState =
  create<ConversationsManipulationState>(() => initialState);
