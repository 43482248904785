import React from 'react';
import { View } from 'react-native';
import { GroupBase } from 'react-select';
import { Controller, useFormContext } from 'react-hook-form';
import { getNestedObjectValue } from 'assets/utils/common';
import { PatientLookupSearchDropdown } from './patient-lookup-search-dropdown';
import { Alert } from 'assets/components/alert';
import { AdvancedDropDownFieldProps } from '../advanced-dropdown/advanced-drop-down-field';
import {
  PatientUserSelectOption,
  OptionTemplateWithDetails,
  SingleValueTemplateWithDetails,
} from './patient-user-select-templates';

export const PatientLookupUserSelectField = <
  Option extends PatientUserSelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  name,
  rules,
  containerStyle,
  label,
  hideRequiredAsterisk,
  ...dropDownProps
}: AdvancedDropDownFieldProps<Option, IsMulti, Group>) => {
  const formContext = useFormContext();
  if (!formContext) {
    throw new Error('DropdownSelect must have a parent form context');
  }

  const { control, formState } = formContext;
  const error = getNestedObjectValue(formState.errors, name);

  return (
    <View style={containerStyle}>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { onChange: onChangeField, value } }) => (
          <PatientLookupSearchDropdown
            menuPortalTarget={document.body}
            value={value}
            onChange={(event, actionMeta) => {
              onChangeField(event);
              dropDownProps.onChange &&
                dropDownProps.onChange(event, actionMeta);
            }}
            label={
              label && rules?.required && !hideRequiredAsterisk
                ? `${label} *`
                : label
            }
            singleValueTemplate={(props) => (
              <SingleValueTemplateWithDetails {...props} label={label ?? ''} />
            )}
            optionTemplate={OptionTemplateWithDetails}
            {...dropDownProps}
          />
        )}
      />
      {rules && error && <Alert intent="error" title={error.message} />}
    </View>
  );
};
