import { create } from './store-creator';
import {
  ObWizardResponseDto,
  ObEmployeeResponseDto,
  ObLocationHolidayResponseDto,
  AutoSaveObEmployeeDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { AsyncStatus } from './types';

export enum PreferredDaysForInstall {
  MONDAY = 'Monday',
  TUESDAY = 'Tuesday',
  WEDNESDAY = 'Wednesday',
  THURSDAY = 'Thursday',
  FRIDAY = 'Friday',
}

export enum SetupStage {
  Landing = 'Landing',
  SetupWizard = 'SetupWizard',
  Exit = 'Exit',
}

export enum YesNoOption {
  Yes = 'Yes',
  No = 'No',
  Unknown = 'Unknown',
}

export interface OnboardingState {
  data: ObWizardResponseDto;
  setupStage: SetupStage;
  status: OBStatus;
}

export type OBStatus = AsyncStatus | 'NOT_ALLOWED' | 'HANDLED_ERROR';

export const useOnboardingStore = create<OnboardingState>(() => ({
  data: {} as ObWizardResponseDto,
  setupStage: SetupStage.Landing,
  status: 'idle',
}));

export type LocationHoliday = ObLocationHolidayResponseDto;

export interface LocationHour {
  start_hour: number;
  start_minute: number;
  end_hour: number;
  end_minute: number;
  day: number;
}

export type Employee = ObEmployeeResponseDto | AutoSaveObEmployeeDto;

export interface LocationTime {
  start: string;
  end: string;
}

export interface LocationHourType {
  time: LocationTime;
  type: HourType;
  day: number;
  text: string;
  totalBreaks?: number;
}

export interface LocationHolidayType extends LocationHoliday {
  isSelect?: boolean;
}

export interface DropdownOptions {
  label: string;
  value: string;
}

export enum HourType {
  OPEN = 'OPEN',
  BREAK = 'BREAK',
  CLOSED = 'CLOSED',
}

export const OB_EMPLOYEE_FIELDS_TO_CHECK = [
  'first_name',
  'last_name',
  'email',
  'role',
];

export const OB_EMPLOYEE_MIN_ROW_TO_SHOW = 5;
