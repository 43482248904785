import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Svg, Path, Defs } from 'react-native-svg';
import { IconProps } from './types';
import Group from 'react-native-svg';
import ClipPath from 'react-native-svg';

export const CloudUpload: FunctionComponent<PropsWithChildren<IconProps>> = ({
  color,
  size,
  testID,
  strokeWidth,
}) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
      testID={testID}
    >
      <Group clipPath="url(#a)">
        <Path
          d="m16 16-4-4m0 0-4 4m4-4v9m8.39-2.61A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Group>
      <Defs>
        <ClipPath id="a">
          <Path fill="#fff" d="M0 0h24v24H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};
