import * as React from 'react';
import Svg, { Path, Mask, SvgProps } from 'react-native-svg';
const NoTasksImage = () => (
  <Svg width={302} height={208} fill="none">
    <Path
      fill="#E1EAF3"
      d="M113.861 50.759v11.185H94.818V42.901h19.043v7.858Z"
    />
    <Path
      fill="#FFC854"
      fillRule="evenodd"
      d="M39.472 50.026h-4.649v-4.662c0-1.39-1.114-2.547-2.521-2.547-1.375 0-2.489 1.157-2.489 2.547v4.662h-4.65c-1.375 0-2.488 1.157-2.488 2.546 0 1.39 1.113 2.548 2.488 2.548h4.65v4.663c0 1.389 1.114 2.545 2.489 2.545 1.407 0 2.521-1.157 2.521-2.545V55.12h4.65c1.375 0 2.488-1.158 2.488-2.548 0-1.389-1.113-2.546-2.489-2.546ZM298.686 173.069h-4.65v-4.696a2.528 2.528 0 0 0-2.521-2.514c-1.375 0-2.489 1.125-2.489 2.514v4.696h-4.65a2.528 2.528 0 0 0-2.521 2.514 2.535 2.535 0 0 0 2.521 2.546h4.65v4.696c0 1.389 1.114 2.513 2.489 2.513a2.527 2.527 0 0 0 2.521-2.513v-4.696h4.65c1.375 0 2.488-1.125 2.488-2.546 0-1.39-1.113-2.514-2.488-2.514ZM277.205 66.432a4.662 4.662 0 0 0 4.65-4.663c0-2.546-2.095-4.663-4.65-4.663-2.521 0-4.616 2.117-4.616 4.663 0 2.58 2.095 4.663 4.616 4.663ZM4.617 180.262c2.52 0 4.617-2.083 4.617-4.663s-2.096-4.664-4.617-4.664c-2.554 0-4.617 2.084-4.617 4.664 0 2.58 2.063 4.663 4.617 4.663Z"
      clipRule="evenodd"
    />
    <Path
      fill="#E1EAF3"
      fillRule="evenodd"
      d="M214.346 43.84c65.265 14.937 84.216 96.67 28.471 141.331-14.639 11.702-34.73 22.075-92.23 22.075h-32c-76 2.254-81.174-29.786-80.456-49.53 2.005-29.12 18.083-52.826 36.285-74.567 33.65-40.217 61.342-30.298 104.932-39.732 13.113-2.843 21.945-2.329 34.998.423Z"
      clipRule="evenodd"
    />
    <Path
      fill="#E9F6FF"
      d="M67.856 170.51h-9.3V2.216h135.077v20.815H79.813V170.51H67.855Z"
    />
    <Path
      fill="#5CC4FF"
      d="M113.914 62.005V42.962H94.871v19.043h19.043Zm0 72.634v-19.046H94.871v19.046h19.043ZM214.89 179.81v11.073H79.813V23.032H214.89V90.35h-7.528v6.641h3.986v8.558a37.436 37.436 0 0 0-14.318 5.771c-.036.027-.076.052-.111.075-8.777 5.806-14.889 15.363-16.272 26.413-.04.315-.08.632-.104.947-.033.266-.055.536-.073.803-.008.087-.019.18-.022.269a9.102 9.102 0 0 0-.03.496c-.015.16-.023.319-.027.479-.014.278-.026.552-.03.833-.011.323-.015.645-.015.973 0 .325.004.651.015.975.004.278.016.563.03.841.008.2.019.404.034.602.023.425.059.847.103 1.263.022.275.053.549.089.824a36.635 36.635 0 0 0 3.816 12.493c.068.137.142.274.215.411.078.153.157.298.238.443.142.262.289.528.442.785 5.98 10.394 16.825 17.697 29.562 18.564ZM113.914 87.136v-7.858H94.871v19.043h19.043V87.136Zm0 70.416v-5.644H94.871v19.046h19.043v-13.402Z"
    />
    <Path
      fill="#101828"
      d="M214.89 192.21H79.812a1.327 1.327 0 0 1-1.328-1.327V170.51a1.33 1.33 0 0 1 2.659 0v19.044h132.419v-8.522c-12.251-1.236-23.168-8.317-29.385-19.124a19.737 19.737 0 0 1-.46-.814l-.247-.456-.219-.426a38.547 38.547 0 0 1-3.954-12.939c-.032-.293-.065-.576-.093-.856a1.332 1.332 0 0 1 1.197-1.451 1.333 1.333 0 0 1 1.449 1.196 35.827 35.827 0 0 0 3.766 12.84l.211.405c.066.127.138.26.211.391.148.276.284.521.428.762 5.997 10.424 16.651 17.116 28.515 17.925a1.328 1.328 0 0 1 1.238 1.325v11.073c0 .734-.594 1.327-1.329 1.327Z"
    />
    <Path
      fill="#101828"
      d="M214.873 179.511s-1.311 2.732-1.311 2V24.341h-19.929a1.33 1.33 0 1 1 0-2.658h21.257c.734 0 1.329.595 1.329 1.328v77.147c0 79.353.654 85.853-1.346 79.353ZM96.199 60.677h16.388V44.29H96.199v16.387Zm17.715 2.658H94.871a1.328 1.328 0 0 1-1.328-1.33V42.962c0-.735.593-1.33 1.328-1.33h19.043c.735 0 1.33.595 1.33 1.33v19.043a1.33 1.33 0 0 1-1.33 1.33ZM96.199 133.308h16.388v-16.387H96.199v16.387Zm17.715 2.658H94.871a1.328 1.328 0 0 1-1.328-1.327v-19.046c0-.733.593-1.327 1.328-1.327h19.043c.735 0 1.33.594 1.33 1.327v19.046a1.33 1.33 0 0 1-1.33 1.327ZM96.199 169.625h16.388v-16.387H96.199v16.387Zm17.715 2.656H94.871a1.327 1.327 0 0 1-1.328-1.327v-19.046c0-.732.593-1.327 1.328-1.327h19.043c.735 0 1.33.595 1.33 1.327v19.046c0 .733-.595 1.327-1.33 1.327ZM172.816 50.49H122.33a1.329 1.329 0 1 1 0-2.657h50.486a1.33 1.33 0 0 1 1.331 1.329 1.33 1.33 0 0 1-1.331 1.328ZM154.216 57.135H122.33a1.329 1.329 0 1 1 0-2.658h31.886a1.33 1.33 0 1 1 0 2.658ZM172.816 86.806H122.33a1.327 1.327 0 1 1 0-2.656h50.486a1.329 1.329 0 1 1 0 2.656ZM154.216 93.451H122.33a1.33 1.33 0 0 1 0-2.658h31.886a1.33 1.33 0 1 1 0 2.658ZM172.816 123.122H122.33a1.327 1.327 0 1 1 0-2.656h50.486a1.329 1.329 0 1 1 0 2.656ZM154.216 129.766H122.33a1.328 1.328 0 1 1 0-2.657h31.886a1.33 1.33 0 1 1 0 2.657ZM172.816 159.439H122.33a1.329 1.329 0 1 1 0-2.658h50.486a1.33 1.33 0 0 1 1.331 1.328 1.33 1.33 0 0 1-1.331 1.33ZM154.216 166.081H122.33a1.327 1.327 0 1 1 0-2.657h31.886a1.33 1.33 0 1 1 0 2.657Z"
    />
    <Path
      fill="#101828"
      d="M59.884 169.181h18.6V23.031c0-.732.596-1.327 1.329-1.327h112.49V3.547H59.883V169.18Zm19.929 2.659H58.556a1.33 1.33 0 0 1-1.33-1.33V2.216a1.33 1.33 0 0 1 1.33-1.328h135.077a1.33 1.33 0 0 1 1.329 1.328v20.816a1.33 1.33 0 0 1-1.329 1.33H81.143V170.51a1.33 1.33 0 0 1-1.33 1.33ZM105.503 163.867c-.415 0-.819-.192-1.076-.546l-3.545-4.872a1.329 1.329 0 0 1 2.149-1.562l2.832 3.892 10.918-6.471a1.33 1.33 0 0 1 1.82.467 1.327 1.327 0 0 1-.467 1.82l-11.956 7.086a1.318 1.318 0 0 1-.675.186Z"
    />
    <Path
      fill="#101828"
      d="M105.503 93.451c-.415 0-.819-.192-1.076-.547l-3.545-4.873a1.33 1.33 0 0 1 .294-1.856 1.326 1.326 0 0 1 1.855.292l2.832 3.896 10.918-6.471a1.328 1.328 0 1 1 1.353 2.287l-11.956 7.086a1.328 1.328 0 0 1-.675.186Z"
    />
    <Path
      fill="#101828"
      d="M96.199 96.992h16.388V80.606H96.199v16.386Zm17.715 2.658H94.871a1.328 1.328 0 0 1-1.328-1.33V79.279c0-.734.593-1.328 1.328-1.328h19.043c.735 0 1.33.594 1.33 1.328v19.043a1.33 1.33 0 0 1-1.33 1.33Z"
    />
    <Mask
      id="a"
      width={35}
      height={95}
      x={178.873}
      y={87.011}
      fill="#000"
      maskUnits="userSpaceOnUse"
    >
      <Path fill="#fff" d="M178.873 87.011h35v95h-35z" />
      <Path d="M178.873 87.011h34v95h-34v-95Z" />
    </Mask>
    <Path fill="#5CC4FF" d="M178.873 87.011h34v95h-34v-95Z" />
    <Path fill="#5CC4FF" d="M212.173 87.011v95h1.4v-95h-1.4Z" mask="url(#a)" />
  </Svg>
);

export default NoTasksImage;
