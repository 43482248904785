import React, { FunctionComponent, PropsWithChildren } from 'react';

import { TaskMetadata as TaskMetadataDto } from '@digitalpharmacist/tasks-service-client-axios';
import TaskMetadataForPatientRecordUpdate from './TaskMetadataForPatientRecordUpdate';
import TaskMetadataForRxTransferRequest from './TaskMetadataForRxTransferRequest';

export const TaskMetadata: FunctionComponent<
  PropsWithChildren<TaskMetadataProps>
> = ({ metadata, patientFullName, patientDateOfBirth }) => {
  const { transfer_to_location_name } = metadata;

  if (transfer_to_location_name) {
    return <TaskMetadataForRxTransferRequest metadata={metadata} />;
  }

  return (
    <TaskMetadataForPatientRecordUpdate
      metadata={metadata}
      patientFullName={patientFullName}
      patientDateOfBirth={patientDateOfBirth}
    />
  );
};
interface TaskMetadataProps {
  metadata: TaskMetadataDto;
  patientFullName: string;
  patientDateOfBirth: string | undefined;
}

export default TaskMetadata;
