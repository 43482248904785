import { FinalizeWebsiteWizardDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { Modal } from 'assets/components/modal';
import { Text } from 'assets/components/text';
import { getText } from 'assets/localization/localization';
import { logError } from 'assets/logging/logger';
import { makeStyles } from 'assets/theme';
import React, { FunctionComponent, useState } from 'react';
import { FlatList, View } from 'react-native';
import {
  setOpenedModal,
  submitPatientWebsite,
} from '../../../actions/checklist-actions';
import { useChecklistStore } from '../../../store/checklist-store';
import { ChecklistWebsiteModalProps } from '../types';

export const PatientWebsiteCompletedModal: FunctionComponent<
  ChecklistWebsiteModalProps
> = ({ id, data }) => {
  const { currentOpenedModalId } = useChecklistStore();
  const [loading, setLoading] = useState<boolean>(false);
  const styles = useStyles();

  const stepsList = [
    <Text style={styles.item}>{getText('website-takes-7-days')}</Text>,
    <Text style={styles.item}>{getText('once-completed-receive-email')}</Text>,
    <Text style={styles.item}>
      <Text>{getText('edit-round-first')}</Text>
      <Text style={styles.bold}>{getText('one')}</Text>
      <Text>{getText('edit-round-second')}</Text>
      <Text style={styles.underline}>{getText('in-full')}</Text>
      <Text>{getText('edit-round-third')}</Text>
    </Text>,
    <Text style={styles.item}>{getText('team-will-make-edits')}</Text>,
    <Text style={styles.item}>{getText('sites-take-hours')}</Text>,
  ];

  const handleBack = async () => {
    const hasDomain = data.website_access.existing_domain === ('Yes' as any);
    const hasAccess = data.website_access.accessible === ('Yes' as any);

    setOpenedModal(
      hasDomain && hasAccess
        ? 'website-access-permission'
        : 'website-access-completed',
    );
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await submitPatientWebsite(data as FinalizeWebsiteWizardDto);
      setOpenedModal('checklist-setup');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={getText('website-setup-complete')}
      show={currentOpenedModalId === id}
      handleDismissModal={() => setOpenedModal('checklist-setup')}
      showDismissButton
      isScrollable
      size="sm"
      buttons={[
        {
          hierarchy: 'primary',
          text: getText('ok'),
          onPress: handleSubmit,
          loading,
          logger: { id: 'next-website-notification' },
        },
        {
          text: getText('back'),
          hierarchy: 'tertiary-gray',
          onPress: handleBack,
          logger: { id: 'back-website-notification' },
        },
      ]}
    >
      <Text style={styles.title}>{getText('team-build-website')}</Text>
      <View style={styles.container}>
        <Text style={styles.stepsTitle}>{getText('next-steps-website')}</Text>
        <FlatList
          data={stepsList}
          renderItem={({ item }) => (
            <View style={styles.itemContainer}>
              <Text style={styles.bullet}>•</Text>
              {item}
            </View>
          )}
          keyExtractor={(item, index) => index.toString()}
        />
      </View>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.lumistryFonts.text.large.regular,
    color: theme.palette.gray[900],
  },
  stepsTitle: {
    ...theme.lumistryFonts.text.medium.semiBold,
    color: theme.palette.gray[900],
  },
  container: {
    padding: theme.getSpacing(2),
    marginTop: theme.getSpacing(2),
    backgroundColor: theme.palette.warning[50],
    borderColor: theme.palette.yellow[300],
    borderWidth: 1,
    borderRadius: theme.roundness,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.getSpacing(1),
  },
  itemContainer: {
    flexDirection: 'row',
    paddingLeft: theme.getSpacing(1),
  },
  bullet: {
    fontSize: theme.getSpacing(2),
    color: theme.palette.gray[700],
  },
  item: {
    ...theme.lumistryFonts.text.medium.regular,
    marginLeft: theme.getSpacing(1),
    color: theme.palette.gray[700],
  },
  bold: {
    ...theme.lumistryFonts.text.medium.bold,
    color: theme.palette.gray[900],
  },
  underline: {
    ...theme.lumistryFonts.text.medium.bold,
    color: theme.palette.gray[900],
    textDecorationLine: 'underline',
  },
}));
