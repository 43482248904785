import {
  ObEquipmentResponseDto,
  WeekdaysEnum,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import { CheckboxGroupItem } from 'assets/components/checkbox-group';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import React, { FunctionComponent, useRef } from 'react';
import { View } from 'react-native';
import { useWizard } from 'react-use-wizard';
import {
  PreferredDaysForInstall,
  useOnboardingStore,
} from '../../../../store/onboarding-store';
import { OBContentHeader } from '../content-header/OBContentHeader';
import OBDivider from '../divider/OBDivider';
import { OBRouterIcon } from '../icons/OBRouterIcon';
import { OBEquipmentForm } from '../forms/OBEquipmentForm';
import { OBFormHandler } from '../forms';
import { save } from '../../../../actions/onboarding-actions';

export interface PreferredDaysForInstallType {
  label: PreferredDaysForInstall;
  enabled: boolean;
}

export interface EquipmentType extends ObEquipmentResponseDto {
  preferred_days_for_install_form?: CheckboxGroupItem<WeekdaysEnum>[];
}

export const OBSingleLocationEquipment: FunctionComponent = () => {
  const styles = useStyles();
  const { handleStep } = useWizard();
  const location = useOnboardingStore((x) => x.data.locations[0]);

  const { location_onsite_required } = location;

  const obEquipmentFormRef = useRef<OBFormHandler>(null);

  handleStep(async () => {
    await obEquipmentFormRef.current?.handleSubmit();
    await save();
  });

  return (
    <View style={styles.root}>
      <OBContentHeader
        icon={OBRouterIcon}
        title={getText('on-premise-equipment')}
        iconHeight={50}
        description={getText(
          location_onsite_required
            ? 'pms-requires-opie-desc'
            : 'pms-does-not-require-opie-desc',
        )}
      />
      {location_onsite_required && (
        <OBEquipmentForm location={location} ref={obEquipmentFormRef} />
      )}

      <OBDivider />
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    alignContent: 'center',
    maxWidth: 350,
  },
  link: {
    color: theme.palette.primary[500],
  },
  image: {
    height: 270,
  },
  body: {
    gap: theme.getSpacing(3),
  },
  title: {
    ...theme.lumistryFonts.text.medium.semiBold,
    color: theme.palette.gray[900],
  },
  subtitle: {
    ...theme.lumistryFonts.text.small.regular,
    color: theme.palette.gray[700],
  },
  mediumText: {
    ...theme.lumistryFonts.text.medium.regular,
    color: theme.palette.gray[700],
  },
  smallLabel: {
    ...theme.lumistryFonts.label.small.regular,
    color: theme.palette.gray[700],
  },
  address: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: theme.getSpacing(2),
  },
  dropDownControlStyle: {
    height: 65.5,
    width: 350,
  },
  multiselectCheckboxes: {
    gap: theme.getSpacing(2),
    paddingTop: theme.getSpacing(2),
  },
}));
