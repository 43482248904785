import { useEffect } from 'react';
import theme from 'assets/theme';
import { useAppStateStore } from '../../../store/app-store';
import useRefillsStore from '../refills-store';
import { loadRefillCount } from '../refills-actions';
import { CountBadge } from 'assets/components/count-badge/CountBadge';

export const RefillCount = (): JSX.Element | null => {
  const locationId = useAppStateStore((s) => s.locationId);
  const failedRefillsCount = useRefillsStore((s) => s.failedRefillsCount);

  useEffect(() => {
    loadRefillCount();
  }, [locationId]);

  return (
    <CountBadge
      count={failedRefillsCount ? failedRefillsCount : 0}
      backgroundColor="#FFC854"
      color={theme.palette.gray[700]}
    />
  );
};
