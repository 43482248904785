import { IconProps } from 'assets/icons/types';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path } from 'react-native-svg';

export const OBFileIcon: FunctionComponent<PropsWithChildren<IconProps>> = ({
  color,
  size,
  testID,
  strokeWidth,
}) => {
  return (
    <Svg width={size} height={size} fill="none" color={color} testID={testID}>
      <Path
        stroke="#0093E8"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9m-7-7 7 7m-7-7v7h7"
      />
    </Svg>
  );
};
