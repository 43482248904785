import {
  ArrowLeftIcon,
  CancelCircleIcon,
  ChatIcon,
  NoLockIcon,
  UsersIcon,
} from 'assets/icons';
import { makeStyles } from 'assets/theme';
import { View, Text } from 'react-native';
import TextCard from './TextCard';
import InfoCell from './InfoCell';
import { getText } from 'assets/localization/localization';
import { FC, useCallback, useState } from 'react';
import {
  BulkMessageType,
  BulkStatus,
} from '@digitalpharmacist/unified-communications-service-client-axios';
import { IBulkMessage } from 'assets/types/messageTypes';
import { BulkMessageRecipientsModal } from './BulkMessageRecipientsModal/BulkMessageRecipientsModal';
import {
  TIME_DATE_FORMAT_WITH_LONGER_SPACE,
  formatDate,
} from '../../../../../common/datetime-utils';
import { StatusRenderer as Status } from '../StatusRenderer';
import unifiedCommsService from '../../../../../api/UnifiedCommsService';
import PharmacyConfirmationModal from '../../../../../components/PharmacyConfirmationModal';
import ReactQuill from 'react-quill';
import { convertVariablesToPills } from '../../../Quill/utils';
import { Icon } from 'assets/components/icon';

interface BulkMessageProps {
  bulkDetails?: IBulkMessage;
  hideBulkDetails: () => void;
}

const BulkMessage: FC<BulkMessageProps> = ({
  bulkDetails,
  hideBulkDetails,
}) => {
  const styles = useStyles();

  const [showRecipients, setShowRecipients] = useState(false);
  const [showConfirmCancelModal, setShowConfirmCancelModal] = useState(false);
  const [quillRef, setQuillRef] = useState<ReactQuill | null>();

  const cancelScheduledBulk = useCallback(() => {
    if (bulkDetails) {
      void (async () => {
        try {
          const result = await unifiedCommsService.cancelScheduledBulk(
            bulkDetails.id,
          );

          if (result.status === BulkStatus.Canceled) {
            hideBulkDetails();
          }
        } catch (err) {}
      })();
    }
  }, [bulkDetails]);

  if (!bulkDetails) {
    return;
  }

  const isSecure = bulkDetails.message_type === BulkMessageType.Secure;
  return (
    <>
      <View style={styles.container}>
        <View style={styles.topButtonsPanel}>
          <a onClick={hideBulkDetails} style={styles.bulkButton}>
            <Icon
              size={18}
              color={styles.bulkTopText.color}
              icon={ArrowLeftIcon}
            />
            <Text style={styles.bulkTopText}>{getText('bulk-messages')}</Text>
          </a>
          {bulkDetails.status === BulkStatus.Scheduled ? (
            <>
              <div style={styles.dividerTopButtons} />
              <a
                onClick={() => setShowConfirmCancelModal(true)}
                style={styles.cancelMessageButton}
              >
                <Icon
                  size={18}
                  color={styles.bulkTopText.color}
                  icon={CancelCircleIcon}
                />
                <Text style={styles.bulkTopText}>
                  {getText('cancel-message')}
                </Text>
              </a>
            </>
          ) : null}
        </View>
        <View style={styles.tabHeader}>
          <Text style={styles.headerText}>{bulkDetails.subject}</Text>
        </View>
        <View style={styles.body}>
          <View style={styles.topBodyPanel}>
            <InfoCell name={getText('method')}>
              <View style={styles.messageType}>
                {isSecure ? (
                  <Icon size={18} {...styles.iconColor} icon={ChatIcon} />
                ) : (
                  <Icon size={18} {...styles.iconColor} icon={NoLockIcon} />
                )}
                <Text style={styles.messageTypeText}>
                  {isSecure ? getText('secure-chat') : getText('non-secure')}
                </Text>
              </View>
            </InfoCell>
            <InfoCell name={getText('recipients')}>
              <a onClick={() => setShowRecipients(true)}>
                <View style={styles.recipients}>
                  <Icon
                    size={18}
                    icon={UsersIcon}
                    color={styles.recipients.color}
                  />
                  <Text style={styles.recipientsNumber}>
                    {bulkDetails.recipients_number}
                  </Text>
                </View>
              </a>
            </InfoCell>
            <InfoCell name={getText('status')}>
              <Status
                data={bulkDetails}
                iconSize={18}
                customStyle={styles.statusText}
              />
            </InfoCell>
            <InfoCell name={getText('send-date')}>
              <Text style={styles.sendDateText}>
                {formatDate(
                  bulkDetails.sent_time ?? bulkDetails.scheduled_time,
                  TIME_DATE_FORMAT_WITH_LONGER_SPACE,
                )}
              </Text>
            </InfoCell>
          </View>
          <View>
            <TextCard
              title={getText('sender')}
              text={bulkDetails.author.name ?? ''}
            />
            <TextCard title={getText('subject')} text={bulkDetails.subject} />
            <View style={styles.quillBody}>
              <Text style={styles.title}>{getText('message')}</Text>
              <ReactQuill
                // readOnly
                ref={(el) => {
                  if (el) {
                    setQuillRef(el);
                  }
                }}
                style={styles.quillText}
                className="review-bulk-message-modal" // to control styles in ./review-bulk-message-modal.css
                // style={{ ...styles.text, ...styles.content }}
                theme="snow"
                value={convertVariablesToPills(
                  bulkDetails.content ?? bulkDetails.content_email ?? '',
                  quillRef || null,
                )}
                modules={{ toolbar: false }}
              />
            </View>
          </View>
        </View>
      </View>
      <BulkMessageRecipientsModal
        show={showRecipients}
        title={getText('recipients-list')}
        onClose={() => setShowRecipients(false)}
        locationId={bulkDetails.location_id}
        bulkId={bulkDetails.id}
      />
      <PharmacyConfirmationModal
        show={showConfirmCancelModal}
        title={getText('are-you-sure-you-want-to-cancel-this-message')}
        message={getText('you-cannot-undo-this-action')}
        onAccepted={cancelScheduledBulk}
        onDismiss={() => setShowConfirmCancelModal(false)}
        cancelButtonText={getText('no')}
        okButtonText={getText('yes')}
      />
    </>
  );
};

export default BulkMessage;

const useStyles = makeStyles((theme) => ({
  container: {
    width: '85%',
    minWidth: 500,
  },
  bulkTopText: {
    ...theme.lumistryFonts.display,
    marginLeft: theme.getSpacing(1),
    color: theme.palette.gray[700],
    fontWeight: '400',
    fontSize: 16,
  },
  topButtonsPanel: {
    flexDirection: 'row',
    height: theme.getSpacing(4),
  },
  bulkButton: {
    display: 'flex',
    alignItems: 'center',
  },
  dividerTopButtons: {
    height: '100%',
    width: 1,
    backgroundColor: theme.palette.gray[300],
    marginRight: theme.getSpacing(3),
    marginLeft: theme.getSpacing(3),
  },
  cancelMessageButton: {
    display: 'flex',
    alignItems: 'center',
  },
  tabHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: theme.getSpacing(2),
    paddingBottom: theme.getSpacing(2),
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.gray[300],
  },
  headerText: {
    ...theme.lumistryFonts.text.medium,
    fontSize: 26,
    marginRight: theme.getSpacing(4),
  },
  messageType: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  messageTypeText: {
    ...theme.lumistryFonts.text.medium.regular,
    marginLeft: theme.getSpacing(1),
    color: theme.palette.gray[700],
    fontSize: 16,
  },
  statusText: {
    fontFamily: theme.lumistryFonts.text.medium.regular.fontFamily,
    fontSize: 16,
  },
  sendDateText: {
    ...theme.lumistryFonts.text.medium.regular,
    color: theme.palette.gray[700],
    fontSize: 16,
  },
  body: {
    width: '70%',
  },
  topBodyPanel: {
    marginTop: theme.getSpacing(2),
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  recipients: {
    alignItems: 'center',
    flexDirection: 'row',
    color: theme.palette.blueLight[500],
  },
  recipientsNumber: {
    ...theme.lumistryFonts.text.medium.regular,
    fontSize: 16,
    color: theme.palette.blueLight[500],
    marginLeft: theme.getSpacing(1),
  },
  iconColor: {
    color: theme.palette.gray[700],
  },
  quillBody: {
    display: 'flex',
    marginTop: theme.getSpacing(3),
    overflow: 'hidden',
  },
  title: {
    ...theme.lumistryFonts.label,
    color: theme.palette.black[600],
    fontWeight: '700',
    fontSize: 16,
  },
  quillText: {
    marginTop: theme.getSpacing(1),
    color: theme.palette.gray[700],
    overflow: 'hidden',
  },
}));
