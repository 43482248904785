import medicationService, {
  ResourceItem,
  customValuePrefix,
  noKnownAllergiesOption,
  noKnownAllergiesValue,
} from '../../api/MedicationService';
import vucaService from '../../api/VucaService';

export const allergiesAreExplicitlyEmpty = (allergyIds: string[]) => {
  return allergyIds.length === 1 && allergyIds[0] === noKnownAllergiesValue;
};

export const findAllergiesById = async (
  allergyIds: string[],
): Promise<ResourceItem[]> => {
  if (allergiesAreExplicitlyEmpty(allergyIds)) {
    return [noKnownAllergiesOption];
  }

  return resolveIds(allergyIds, (ids) =>
    medicationService.allergyFind(ids.join(',')),
  );
};

export const findConditionsById = async (
  conditionIds: string[],
): Promise<ResourceItem[]> => {
  return resolveIds(conditionIds, (ids) =>
    vucaService.conditionFind(ids.join(',')),
  );
};

// below here could move somewhere closer to the typeahead
export const resolveIds = async (
  ids: string[],
  idResolver: (ids: string[]) => Promise<ResourceItem[]>,
) => {
  if (ids.length === 0) return [];
  const customItems = getCustomResourceItems(ids);
  if (getNotCustomIds(ids).length > 0) {
    const resolvedItems = await idResolver(getNotCustomIds(ids));
    return [...resolvedItems, ...customItems];
  }
  return [...customItems];
};

export const getCustomResourceItems = (items: string[]): ResourceItem[] => {
  const customItems: ResourceItem[] = items
    .filter((v) => isCustom(v))
    .map((v) => {
      const value: ResourceItem = {
        text: v.split(customValuePrefix)[1],
        value: v,
      };
      return value;
    });
  return customItems;
};

export const isCustom = (text: string): boolean => {
  return text.startsWith(customValuePrefix, 0);
};

export const getNotCustomIds = (items: string[]): string[] => {
  return items.filter((v) => !isCustom(v));
};
