import React, { FunctionComponent, PropsWithChildren, useMemo } from 'react';
import { TaskMetadata as TaskMetadataDto } from '@digitalpharmacist/tasks-service-client-axios';
import { View } from 'react-native';
import { Text } from 'assets/components/text';
import { getText } from 'assets/localization/localization';
import { useStyles as useMetadataStyles } from './TaskMetadataForPatientRecordUpdate';
import { makeStyles } from 'assets/theme';
import { prettyFormat } from '@digitalpharmacist/validation-dp';

export const TaskMetadataForRxTransferRequest: FunctionComponent<
  PropsWithChildren<TaskMetadataProps>
> = ({ metadata }) => {
  const styles = useStyles();
  const metadataStyles = useMetadataStyles();
  const {
    transfer_to_location_name,
    transfer_to_location_phone,
    prescriptions,
    all_prescriptions,
    refill_after_transfer,
  } = metadata;

  const transferredPrescriptions = useMemo(() => {
    if (all_prescriptions) {
      return <Text>{getText('all-medications')}</Text>;
    }

    if (!Array.isArray(prescriptions) || !prescriptions.length) {
      return null;
    }

    return prescriptions.map((prescription, count) => (
      <View
        style={
          count !== prescriptions.length - 1 ? metadataStyles.medicationRow : {}
        }
      >
        <Text>{prescription.drug_name}</Text>
      </View>
    ));
  }, [prescriptions, all_prescriptions]);

  return (
    <View style={metadataStyles.metadataTable}>
      <>
        <View style={[metadataStyles.metadataTableRow, { borderTopWidth: 0 }]}>
          <View style={[metadataStyles.metadataCell, styles.metadataCell]}>
            {getText('pharmacy-name')}
          </View>
          <View
            style={[
              metadataStyles.metadataCell,
              styles.metadataCell,
              metadataStyles.metadataValueCell,
            ]}
          >
            {transfer_to_location_name ? transfer_to_location_name : ''}
          </View>
        </View>
        <View style={metadataStyles.metadataTableRow}>
          <View style={[metadataStyles.metadataCell, styles.metadataCell]}>
            {getText('pharmacy-phone')}
          </View>
          <View
            style={[
              metadataStyles.metadataCell,
              styles.metadataCell,
              metadataStyles.metadataValueCell,
            ]}
          >
            {transfer_to_location_phone
              ? prettyFormat(transfer_to_location_phone)
              : ''}
          </View>
        </View>
        <View style={metadataStyles.metadataTableRow}>
          <View style={[metadataStyles.metadataCell, styles.metadataCell]}>
            {getText('patient-requesting-transfer')}
          </View>
          <View
            style={[
              metadataStyles.metadataCell,
              styles.metadataCell,
              metadataStyles.metadataValueCell,
            ]}
          >
            {transferredPrescriptions}
          </View>
        </View>
        <View style={metadataStyles.metadataTableRow}>
          <View style={[metadataStyles.metadataCell, styles.metadataCell]}>
            {getText('patient-wants-refill')}
          </View>
          <View
            style={[
              metadataStyles.metadataCell,
              styles.metadataCell,
              metadataStyles.metadataValueCell,
            ]}
          >
            {refill_after_transfer ? getText('yes') : getText('no')}
          </View>
        </View>
      </>
    </View>
  );
};
interface TaskMetadataProps {
  metadata: TaskMetadataDto;
}

const useStyles = makeStyles((theme) => ({
  metadataCell: {
    padding: theme.getSpacing(1),
    textTransform: 'none',
  },
}));

export default TaskMetadataForRxTransferRequest;
