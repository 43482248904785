import {
  BaseFilterValues,
  IConversationsSorting,
  MultiFilterValues,
  TextFilters,
} from 'assets/types/messageTypes';
import {
  initialState,
  useConversationsManipulationState,
} from './data-manipulation-store';

export const setBaseFilter = (filter: BaseFilterValues) => {
  useConversationsManipulationState.setState({
    baseFilter: filter,
  });
};

export const setMultiFilters = (filter: MultiFilterValues) => {
  const { multiFilters } = useConversationsManipulationState.getState();

  if (filter === MultiFilterValues.NO_FILTERS) {
    useConversationsManipulationState.setState({
      multiFilters: [],
    });
  } else if (multiFilters.includes(filter)) {
    useConversationsManipulationState.setState({
      multiFilters: multiFilters.filter(
        (filterName: MultiFilterValues) => filter !== filterName,
      ),
    });
  } else {
    useConversationsManipulationState.setState({
      multiFilters: [...multiFilters, filter],
    });
  }
};

export const setTextFilters = (textFilters: TextFilters) => {
  useConversationsManipulationState.setState({ textFilters: textFilters });
};

export const setPagination = (limit: number) => {
  useConversationsManipulationState.setState({ pagination: { limit } });
};

export const setConversationsSorting = (sorting: IConversationsSorting) => {
  useConversationsManipulationState.setState({ sorting: sorting });
};

export const resetConversationsManipulationState = () => {
  useConversationsManipulationState.setState(initialState);
};
