import { LoadingIndicator } from 'assets/components/loading-indicator';
import { FunctionComponent, useEffect, useState } from 'react';
import { View } from 'react-native';

export const PatientAgreementPDFViewer: FunctionComponent<{
  src: BlobPart;
}> = ({ src }) => {
  const [source, setSource] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    if (typeof src === 'string') setSource(src);
    else {
      const fileInMemory = new Blob([src], { type: 'application/pdf' });
      // web and ios only
      const tempBlobUrl = URL.createObjectURL(fileInMemory);
      setSource(tempBlobUrl);
    }
    setLoading(true);
  }, [src]);

  // fallback to default browser behavior
  return (
    <View style={{ flex: 1 }}>
      {!loading ? (
        <LoadingIndicator />
      ) : (
        <object
          data={source}
          type="application/pdf"
          width={'100%'}
          height={'100%'}
        >
          {/* // fallback to iframe if object is not supported */}
          <iframe src={source} width={'100%'} height={'100%'} />
        </object>
      )}
    </View>
  );
};
